import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  value: {
    margin: theme.spacing(1),
  },
}));

export default function PlotCard({ title, data = [] }) {
  const classes = useStyles();
  // global.logger.debug({ data });
  return (
    <Paper className={classes.container}>
      <Typography variant="subtitle2" component="p">
        {title}
      </Typography>
      <Grid container align="center" justifyContent="center">
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveScatterPlot
            data={data}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            xScale={{ type: 'linear', min: 0, max: 'auto' }}
            // xFormat={function(e) {
            //   return e+' kg';
            // }}
            yScale={{ type: 'linear', min: 0, max: 'auto' }}
            // yFormat={function(e) {
            //   return e+' cm';
            // }}
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '距離 (公里)',
              legendPosition: 'middle',
              legendOffset: 46,
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '時間 (分鐘)',
              legendPosition: 'middle',
              legendOffset: -60,
            }}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 130,
                translateY: 0,
                itemWidth: 100,
                itemHeight: 12,
                itemsSpacing: 5,
                itemDirection: 'left-to-right',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Grid>
    </Paper>);
}

PlotCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};
