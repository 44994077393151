import i18n from 'i18next';

const schema = {
  'title': '基本資料',
  'type': 'object',
  'required': [
    'status', 'clientId', 'name', 'address', 'isDisadvantaged', 'isAllowedToEnterHome',
  ],
  'properties': {
    'status': {
      'type': 'string',
      'title': '狀態',
      'enum': ['使用中', '已停用'],
      'default': '使用中',
    },
    'clientId': {
      'type': 'string',
      'title': '子女(客戶)或機構',
    },
    'relationshipToClient': {
      'type': ['string', 'null'],
      'title': '與客戶關係',
    },
    'name': {
      'type': 'string',
      'title': '姓名',
    },
    'nickname': {
      'type': ['string', 'null'],
      'title': '暱稱',
    },
    'username': {
      'type': 'string',
      'title': '帳號',
      'default': 'N/A',
    },
    'phoneNumber': {
      'type': ['string', 'null'],
      'title': '電話號碼',
    },
    'phoneNumber2': {
      'type': ['string', 'null'],
      'title': '電話號碼2',
    },
    'phoneNumber3': {
      'type': ['string', 'null'],
      'title': '電話號碼3',
    },
    'identificationCardId': {
      'type': ['string', 'null'],
      'title': '身分證字號',
    },
    'birthday': {
      'type': ['string', 'null'],
      'title': '生日',
      'format': 'date',
    },
    'gender': {
      'type': ['string', 'null'],
      'title': '性別',
      'enum': ['male', 'female', 'other', null],
      'enumNames': ['男', '女', '其他', '不回答'],
    },
    'isDisadvantaged': {
      'type': 'number',
      'title': '弱勢長輩(非自費戶)',
      'enum': [1, 0],
      'enumNames': ['是', '否'],
      'default': 0,
    },
    'isAllowedToEnterHome': {
      'type': 'number',
      'title': '是否同意進入長輩家中',
      'enum': [1, 0],
      'enumNames': ['是', '否'],
      'default': 0,
    },
    'onTimeDelivery': {
      'type': ['number', 'null'],
      'title': '餐點需準時送達',
      'enum': [1, null], // 會變成寫入1或移除key, 有點tricky, 但是比較容易向前相容
      'enumNames': ['是', '否'],
      'default': null,
      'description': '若餐點需準時送達，大使端app時間會顯示訂單送餐時間, 否則會顯示訂單送餐時間前後半小時的時間區間',
    },
    'dementiaQuestionnaire': {
      'type': ['boolean', 'null'],
      'title': '參加失智防治推廣問卷調查',
      'enum': [null, false], // 預設沒有key是有加入活動
      'enumNames': ['是', '否'],
      'default': null,
      'description': '機構須先申請加入參加失智防治推廣問卷調查',
    },
    'disabilityLevel': {
      'type': ['string', 'null'],
      'title': '身障等級',
      'enum': [null, '輕度', '中度', '重度', '極重度'],
      'enumNames': ['無', '輕度', '中度', '重度', '極重度'],
      'default': null,
    },
    'cmsLevel': {
      'type': ['string', 'null'],
      'title': '失能等級',
      'enum': [null, '1級', '1a級', '1b級', '2級', '3級', '4級', '5級', '6級', '7級', '8級'],
      'enumNames': ['無', '1級', '1a級', '1b級', '2級', '3級', '4級', '5級', '6級', '7級', '8級'],
      'default': null,
    },
    'serviceCode': {
      'type': ['string', 'null'],
      'title': '服務代碼',
      'examples': ['OT01', '脆家'],
    },
    'linkGroupName': {
      'type': ['string', 'null'],
      'title': '家庭代碼（連結同戶其他送餐對象）',
    },
    'caseNumber': {
      'type': ['string', 'null'],
      'title': '案號',
    },
    'govQualifiedCount': {
      'type': ['number', 'null'],
      'title': '政府核定量',
    },
    'supervisorId': {
      'type': ['string', 'null'],
      'title': '督導ID',
    },
    'fixedDeliveryStaffFee': {
      'type': ['number', 'null'],
      'title': '固定送餐費用',
    },
    'disadvantagedTypesHistory': {
      'type': ['array', 'null'],
      'title': '弱勢身份別',
      'items': {
        'required': [
          'disadvantagedTypes',
          'approvedDate',
        ],
        'type': 'object',
        'properties': {
          'disadvantagedTypes': {
            'type': 'string',
            'title': '身份別',
            'examples': ['一般', '中低收', '低收', '中低2.5倍', '中低1.5倍'],
          },
          'approvedDate': {
            'type': 'string',
            'title': '生效日期',
            'format': 'date',
          },
        },
      },
      'default': [],
    },
    'otherSocialServices': {
      'type': ['string', 'null'],
      'title': '其它社會資源協助',
    },
    'lunchRepeatOn': {
      'type': ['array', 'null'],
      'title': '午餐送餐週期',
      'minItems': 0,
      'items': {
        'type': 'integer',
        'enum': [1, 2, 3, 4, 5, 6, 0],
        'enumNames': ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
      },
      'uniqueItems': true,
      'default': [],
      'description': '搭配週期性訂單的送餐週期設定使用',
    },
    'dinnerRepeatOn': {
      'type': ['array', 'null'],
      'title': '晚餐送餐週期',
      'minItems': 0,
      'items': {
        'type': 'integer',
        'enum': [1, 2, 3, 4, 5, 6, 0],
        'enumNames': ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
      },
      'uniqueItems': true,
      'default': [],
      'description': '搭配週期性訂單的送餐週期設定使用',
    },
    'noteForDelivery': {
      'type': ['string', 'null'],
      'title': `${i18n.t('送餐')}備註`,
    },
    'noteForMeal': {
      'type': ['string', 'null'],
      'title': `${i18n.t('餐點')}備註`,
    },
    'noteForHealth': {
      'type': ['string', 'null'],
      'title': '健康備註',
    },
    'note': {
      'type': ['string', 'null'],
      'title': '其他備註',
    },
    'emergencyContact': {
      'title': '緊急聯絡人',
      'type': 'object',
    },
    'address': {
      'title': '地址',
      'type': 'object',
    },
  },
};

export default schema;
