import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';

import { request, asyncListAll } from 'utilities/graph';
import {
  getIssueCommentsByIssueByCreatedAt,
} from 'graphql/queries';
import {
  createIssueComment,
  updateIssue,
} from 'graphql/mutations';
import Order from 'components/Order/Order';
import IssueStatusProgress from './IssueStatusProgress';
import IssueTimeline from './IssueTimeline';
import cache from 'utilities/cache';

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    position: 'relative',
    overflow: 'auto',
    height: 'calc(100vh - 64px)',
  },
  actionContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    overflow: 'auto',
    height: 'calc(100vh - 64px)',
  },
  selectFormControl: {
    minWidth: 100,
  },
  timeline: {
    flex: 1,
  },
}));

export default function Issue({ issue, admins = [], onUpdate }) {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const updateIssueStatus = async (newStatus) => {
    global.logger.debug(newStatus);
    if (newStatus === issue.status) {
      return;
    }

    setIsLoading(true);

    const username = cache.get('app:username');
    const now = new Date().toISOString();
    const updatedIssue = {
      id: issue.id,
      status: newStatus,
      updatedAt: now,
      updatedBy: username,
    };
    await request(updateIssue, { input: updatedIssue });

    setIsLoading(false);

    issue = Object.assign(issue, updatedIssue);

    onUpdate && onUpdate(issue);
  };

  // const updateIssueAssignee = async (newAssignee) => {
  //   global.logger.debug(newAssignee);
  //   if (newAssignee === issue.assignee) {
  //     return;
  //   }

  //   setIsLoading(true);

  //   const username = cache.get('app:username');
  //   const now = new Date().toISOString();
  //   const updatedIssue = {
  //     id: issue.id,
  //     assignee: newAssignee,
  //     updatedAt: now,
  //     updatedBy: username,
  //   };
  //   await request(updateIssue, { input: updatedIssue });

  //   setIsLoading(false);

  //   Object.assign(issue, updatedIssue);

  //   onUpdate && onUpdate();
  // };

  const submitComment = async () => {
    setIsLoading(true);

    const username = cache.get('app:username');
    const userName = cache.get('app:userName');
    const now = new Date().toISOString();

    const comment = {
      issueId: issue.id,
      username: userName,
      content: commentText,
      expirationUnixTime: Math.round(Date.now() / 1000) + 180 * 86400,
      createdAt: now,
      createdBy: username,
      updatedAt: now,
      updatedBy: username,
    };

    await request(createIssueComment, { input: comment });

    setComments([...comments, comment]);
    setCommentText('');

    setIsLoading(false);
    onUpdate && onUpdate();
  };

  useEffect(() => {
    if (!issue || !issue.id) return;
    (async () => {
      setIsLoading(true);

      const data = await asyncListAll(getIssueCommentsByIssueByCreatedAt, { issueId: issue.id });
      setComments(data);

      setIsLoading(false);
    })();
  }, [issue]);

  if (!issue) return null;

  return (
    <Grid container>
      <Grid item md={6} lg={7} xl={8} className={classes.orderContainer}>
        {issue.orderId && <Order id={issue.orderId} />}
      </Grid>
      <Grid item md={6} lg={5} xl={4} className={classes.actionContainer}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <IssueStatusProgress
            status={issue.status}
            onChange={updateIssueStatus}
          />
          {/* <Grid item>
            <FormControl className={classes.selectFormControl}>
              <InputLabel>指派處理</InputLabel>
              <Select
                value={issue.assignee || ''}
                onChange={(event) => updateIssueAssignee(event.target.value)}
              >
                <MenuItem value="" disabled>無</MenuItem>
                {admins.map(({ username, name }) => (
                  <MenuItem key={username} value={username} disabled={username === issue.assignee}>
                    {name} ({username})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <div className={classes.timeline}>
          <IssueTimeline issue={issue} comments={comments} />
        </div>
        <Grid container direction="column" justifyContent="center">
          <TextField
            label="處理筆記"
            multiline
            minRows={2}
            variant="outlined"
            fullWidth={true}
            value={commentText}
            onChange={(event)=>setCommentText(event.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Button variant="contained" color="primary" disabled={!commentText || isLoading} onClick={submitComment}>
            送出
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

Issue.propTypes = {
  issue: PropTypes.object,
  admins: PropTypes.array,
  onUpdate: PropTypes.func,
};
