import React from 'react';
import i18n from 'i18next';
import EditButton from '../components/EditButton';
import RestaurantOwnerForm from './RestaurantOwnerForm';

export default function RestaurantOwnerEditButton({ ...props }) {
  return (
    <EditButton
      title={`${i18n.t('餐廳')}負責人`}
      form={RestaurantOwnerForm}
      {...props}
    />);
}
