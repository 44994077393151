import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table/Table';
import { getDeliveryStaffMonthlyStatementsByDeliveryStaffIdByMonth } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const title = '結算紀錄';
const description = '';

export default function DeliveryStaffMonthlyStatementTable({ deliveryStaffId }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const options = {};

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'month',
      label: '月份',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'earnedSToken',
      label: '賺的 S Token',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'completedOrderCount',
      label: '完成的訂單數',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'completedOrderDistanceInMeters',
      label: '完成的路線總長',
      options: {
        filter: false,
        sort: true,
        customBodyRender(value) {
          if (value === 0) return '';
          return `${(value/1000).toFixed(2)}公里`;
        },
      },
    },
    {
      name: 'completedOrderDurationInSeconds',
      label: '完成的路線時間',
      options: {
        filter: false,
        sort: true,
        customBodyRender(value) {
          if (value === 0) return '';
          return `${(value/3600).toFixed(2)}小時`;
        },
      },
    },
    {
      name: 'incompleteOrderCount',
      label: '未完成的訂單數',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'abandonedOrderCount',
      label: '棄單數',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        // TODO: use the data table with query generator
        let records;
        if (deliveryStaffId) {
          records = await asyncListAll(getDeliveryStaffMonthlyStatementsByDeliveryStaffIdByMonth, {
            deliveryStaffId,
            sortDirection: 'DESC',
            limit: 1000,
          });
        } else {
          records = [];
        }

        setData(records.sort(sortBy('date', true)));
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [deliveryStaffId, lastUpdatedAt]);

  return (
    <Table
      title={title}
      isLoading={isLoading}
      description={description}
      data={data}
      columns={columns}
      options={options}
      // editButton={DeliveryStaffTransactionEditButton}
      onRefresh={() => setLastUpdatedAt(Date.now())}
    />
  );
}

DeliveryStaffMonthlyStatementTable.propTypes = {
  deliveryStaffId: PropTypes.string,
};
