export const getDeliveryStaff = /* GraphQL */ `
  query GetDeliveryStaff($id: ID!) {
    getDeliveryStaff(id: $id) {
      id
      username
      isActive
      county
      clientId
      name
      nickname
      phoneNumber
    }
  }
`;

const orderItems = /* GraphQL */ `
  items {
    id
    clientId
    restaurantId
    elderId
    date
    mealSlot
    deliveryBy
    status
    category
    deliveryGroupId
    deliveryGroupSortOrder
    deliveryStaffId
    deliveryStaffOrderId
    deliveryStaffFee
    tier
    tierExpiredAt
    noteForDelivery
    noteForMeal
    note
    mealItems {
      name
      quantity
      price
      cost
      note
    }
    paymentMethod
    isAudited
    nextStatusCheckAt
    issuePriority
    issueSummary
    # direction {
    #   startAddress
    #   startLocation {
    #     lat
    #     lng
    #   }
    #   endAddress
    #   endLocation {
    #     lat
    #     lng
    #   }
    #   distance
    #   duration
    #   distanceInMeters
    #   durationInSeconds
    #   overviewPolyline
    #   bounds {
    #     northeast {
    #       lat
    #       lng
    #     }
    #     southwest {
    #       lat
    #       lng
    #     }
    #   }
    # }
    client {
      id
      type
      isActive
      county
      username
      name
      phoneNumber
    }
    elder {
      id
      username
      status
      clientId
      county
      deliveryGroupId
      sortOrder
      name
      nickname
      birthday
      gender
      phoneNumber
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
    }
    restaurant {
      id
      owner
      restaurantOwnerId
      isActive
      county
      clientId
      category
      name
      description
      phoneNumber
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
    }
  }
`;

export const getOrdersByClientByDate = /* GraphQL */ `
  query GetOrdersByClientByDate(
    $clientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${orderItems}
      nextToken
    }
  }
`;

export const getOrdersByCountyByDate = /* GraphQL */ `
  query GetOrdersByCountyByDate(
    $county: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCountyByDate(
      county: $county
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${orderItems}
      nextToken
    }
  }
`;
