import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

import { request } from 'utilities/graph';
import { linePayGetPaymentUrl, linePayConfirmTransaction } from 'graphql/mutations';
import cache from 'utilities/cache';

export default function LinePayButton({
  order = {},
  redirectPath,
  onComplete,
  disabled,
  reportLoading,
  textButton = false,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = async () => {
    setIsLoading(true);
    reportLoading(true);
    cache.set('public:menu-payment-service', 'linepay');
    try {
      const clientId = cache.get('app:clientId');
      const {
        subTotal,
        discount,
        discountRule,
        amount,
        description,
        category,
        items,
        deliveryStaffFee,
      } = order;

      const { data: { linePayGetPaymentUrl: { data: linePayGetPaymentUrlResult } } } = await request(linePayGetPaymentUrl, {
        input: {
          clientId,
          subTotal,
          discount,
          discountRule,
          amount,
          description,
          category,
          items,
          redirectPath,
          deliveryStaffFee,
        },
      });

      const {
        transactionId,
        orderId,
        linePayTransactionId,
        linePayPaymentUrlWeb,
        linePayPaymentUrlApp,
      } = JSON.parse(linePayGetPaymentUrlResult);

      global.logger.debug({
        transactionId,
        orderId,
        linePayTransactionId,
        linePayPaymentUrlWeb,
        linePayPaymentUrlApp,
      });

      cache.set('linepay:pending-transactionId', transactionId);

      // open payment url in web
      if (window.mobileCheck()) {
        window.open(linePayPaymentUrlApp, '_self');
      } else {
        window.open(linePayPaymentUrlWeb, '_self');
      }
    } catch (e) {
      global.logger.error(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        reportLoading(false);
      }, 1000);
    }
  };

  const handleConfirm = async (linePayTransactionId, orderId) => {
    setIsLoading(true);
    reportLoading(true);
    try {
      const transactionId = cache.get('linepay:pending-transactionId');

      const { data: { linePayConfirmTransaction: { data: linePayConfirmTransactionResult } } } = await request(linePayConfirmTransaction, {
        input: {
          transactionId,
          linePayTransactionId,
        },
      });

      const {
        status,
      } = JSON.parse(linePayConfirmTransactionResult);

      global.logger.debug({
        status,
      });
      if (status === 'approved') {
        // cache.set('linepay:pending-transactionId', '');
        onComplete({ id: transactionId });
      }
    } catch (e) {
      global.logger.error(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        reportLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const { transactionId: linePayTransactionId, orderId } = querystring.parse(window.location.search);
    const paymentService = cache.get('public:menu-payment-service');
    if (paymentService === 'linepay' && linePayTransactionId && orderId) {
      return handleConfirm(linePayTransactionId, orderId);
    } else {
      setIsLoading(false);
      reportLoading(false);
    }
  }, []);

  return (
    <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {textButton ?
        <Button
          variant='contained'
          onClick={handleClick}
          disabled={disabled || isLoading}
          style={{
            width: '100%',
            borderRadius: '20px',
            color: 'white',
          }}
          color="primary"
        >
          確認付款（LINE Pay）
          {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100, borderRadius: '20px' }}>
            <CircularProgress color="primary" size="1.5rem" />
          </Backdrop>}
        </Button> :
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClick}
          disabled={disabled || isLoading}
          style={{
            position: 'relative',
            minWidth: 350,
            borderRadius: 5,
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
            height: 60,
          }}
        >
          <img src={'/images/linepay_v5.png'} style={{ height: 40 }}/>
          {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100 }}>
            <CircularProgress color="primary" />
          </Backdrop>}
        </Button>}
    </div>
  );
}

LinePayButton.propTypes = {
  disabled: PropTypes.bool,
  order: PropTypes.object,
  onComplete: PropTypes.func,
  redirectPath: PropTypes.string,
  reportLoading: PropTypes.func,
  textButton: PropTypes.bool,
};
