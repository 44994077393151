import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { resetFormCache } from 'forms/formCache';
import { request } from 'utilities/graph';
import moment from 'moment';
import {
  getOrganization,
} from 'graphql/queries';
import {
  createOrganization,
  updateOrganization,
  createClientOrganization,
  updateClientOrganization,
  deleteClientOrganization,
} from 'graphql/mutations';
import uiSchema from './uiSchema';
import cache from 'utilities/cache';
import { toastr } from 'react-redux-toastr';

export default function OrganizationForm({ formData: inFormData, ...props }) {
  const [formData, setFormData] = useState();
  const [originClients, setOriginClients] = useState([]);
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);

  useEffect(() => {
    const newFormData = JSON.parse(JSON.stringify(inFormData || {}));
    if (newFormData.id && newFormData.clients && newFormData.clients.items) {
      newFormData.clients = newFormData.clients.items;
      delete newFormData.clients.items;
    }
    setOriginClients(newFormData.clients || []);
    setFormData(newFormData);
  }, [inFormData]);

  const handleClientPermissions = async (newClients, organizationId) => {
    const now = moment().toISOString();
    const username = cache.get('app:username');

    const toCreateClients = [];
    const toUpdateClients = [];
    const toRemoveClients = [];
    let showVisitorModeWarning = false;
    newClients.forEach((client) => {
      if (client.visitorMode && (client.clientMode || client.elderSetting)) {
        showVisitorModeWarning = true;
        client.clientMode = false;
        client.elderSetting = false;
      }
    });
    if (showVisitorModeWarning) {
      toastr.warning('勾選訪客模式將無法切換至機構模式與編輯送餐對象');
    }
    originClients.forEach(({ id: settingId }) => {
      const matched = newClients.find(({ id }) => id === settingId);
      if (!matched) {
        toRemoveClients.push({ id: settingId });
      } else {
        toUpdateClients.push(matched);
      }
    });
    newClients.forEach((newClient) => {
      const matched = originClients.find(({ id }) => id === newClient.id);
      if (!matched) {
        toCreateClients.push(newClient);
      }
    });

    await Promise.all([
      ...toCreateClients.map(async (item) => {
        const data = {
          organizationId,
          clientId: item.clientId,
          visitorMode: item.visitorMode,
          clientMode: item.clientMode,
          elderSetting: item.elderSetting,
          createdAt: now,
          createdBy: username,
          updatedAt: now,
          updatedBy: username,
        };
        await request(createClientOrganization, { input: data });
      }),
      ...toUpdateClients.map(async (item) => {
        const data = {
          id: item.id,
          organizationId,
          visitorMode: item.visitorMode,
          clientId: item.clientId,
          clientMode: item.clientMode,
          elderSetting: item.elderSetting,
          updatedAt: now,
          updatedBy: username,
        };
        await request(updateClientOrganization, { input: data });
      }),
      ...toRemoveClients.map(async (item) => {
        const data = {
          id: item.id,
        };
        await request(deleteClientOrganization, { input: data });
      }),
    ]);
  };

  const hasDuplicateClients = (clients) => {
    const idSet = new Set();
    for (const item of clients) {
      if (idSet.has(item.clientId)) {
        return true;
      }
      idSet.add(item.clientId);
    }
    return false;
  };

  const createFunc = async (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    const clients = newData.clients;
    delete newData.clients;

    if (hasDuplicateClients(clients)) {
      toastr.error('有重複設定的機構');
      throw new Error('clients duplicated');
    }

    const { data: { createOrganization: createdOrganization } } = await request(createOrganization, { input: newData });
    await handleClientPermissions(clients, createdOrganization.id);
    const { data: { getOrganization: organization } } = await request(getOrganization, { id: createdOrganization.id });
    resetFormCache('allOrganizations');
    return organization;
  };

  const updateFunc = async (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    const clients = newData.clients;
    delete newData.clients;

    if (hasDuplicateClients(clients)) {
      toastr.error('有重複設定的機構');
      throw new Error('clients duplicated');
    }

    await Promise.all([
      request(updateOrganization, { input: newData }),
      handleClientPermissions(clients, newData.id),
    ]);
    const { data: { getOrganization: organization } } = await request(getOrganization, { id: newData.id });
    resetFormCache('allOrganizations');
    return organization;
  };

  if (!formData) {
    return null;
  }


  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      formData={formData}
      {...props}
    />
  );
}

OrganizationForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
