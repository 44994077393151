import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Table from 'components/Table/Table';

import {
  listUsersInGroup,
  removeUserFromGroup,
  addUserToGroup,
  disableUser,
  enableUser,
} from 'utilities/cognito';
import { sortBy } from 'utilities/sorting';
import { cognitoGroups } from '@silvergatedelivery/constants';
// import { userOperation } from 'graphql/mutations';
// import { request } from 'utilities/graph';

const title = '軟體用戶列表';
const description = '';

export default function UserTable() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();
  const [selectedCognitoGroupIndex, setSelectedCognitoGroupIndex] = useState(0);
  const options = {};

  const columns = [
    {
      name: 'enabled',
      label: '登入權限',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'group:Admins',
      label: '管理員',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'group:OrgAdmins',
      label: '組織負責人',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'group:FacilityAdmins',
      label: '機構管理員',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'group:DeliveryStaffs',
      label: t('送餐大使'),
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'group:RestaurantOwners',
      label: `${t('餐廳')}負責人`,
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'group:Clients',
      label: '客戶',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: '名字',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordBreak: 'break-all' }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email_verified',
      label: 'Email認證',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const onUpate = async (item, dataIndex) => {
    const input = {};
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    const {
      enabled: newEnabled,
    } = input;
    const sourceData = data[dataIndex];
    const {
      username,
      enabled,
    } = sourceData;

    const updateGroupPromises = cognitoGroups.map(async ({ value }) => {
      const key = `group:${value}`;

      if (!input[key] && sourceData[key]) {
        await removeUserFromGroup(username, value);
      } else
      if (input[key] && !sourceData[key]) {
        await addUserToGroup(username, value);
      }
    });

    await Promise.all(updateGroupPromises);

    // if (cognitoGroup !== newCognitoGroup || facilityId !== newOrgId) {
    //   // await removeUserFromGroup(username, role);
    //   // await addUserToGroup(username, input.role);
    //   const user = {
    //     username,
    //     facilityId: newOrgId,
    //     name,
    //     email,
    //     role: newCognitoGroup,
    //     idNumber: 'N/A',
    //   };

    //   await request(userOperation, {
    //     input: {
    //       force: true,
    //       users: [user],
    //     },
    //   });
    // }

    if (enabled !== newEnabled) {
      if (newEnabled === true) {
        await enableUser(username);
      } else {
        await disableUser(username);
      }
    }

    setLastUpdatedAt(Date.now());
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const groups = [cognitoGroups[selectedCognitoGroupIndex]].map(({ value }) => value);
        const results = await Promise.all(groups.map((group) => listUsersInGroup(group, true)));

        const allUsers = [];
        results.forEach(({ data: groupUsers }, index) => {
          const groupName = groups[index];
          groupUsers.forEach((user) => {
            const matched = allUsers.find(({ username }) => username === user.username);
            if (matched) {
              matched[`group:${groupName}`] = true;
            } else {
              user[`group:${groupName}`] = true;
              allUsers.push(user);
            }
          });
        });

        setData(allUsers
          .sort(sortBy('name'))
          .sort(sortBy('username')),
        );
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lastUpdatedAt, selectedCognitoGroupIndex]);

  return (
    <Grid>
      <FormControl fullWidth variant="outlined" style={{ marginBottom: 16 }}>
        <Select
          labelId={`群組`}
          id={'群組'}
          value={selectedCognitoGroupIndex}
          onChange={(e) => {
            setSelectedCognitoGroupIndex(e.target.value);
          }}
        >
          {cognitoGroups.map((item, index) => {
            return (<MenuItem key={index} value={index}>{t(item.label)}</MenuItem>);
          })}
        </Select>
      </FormControl>
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        onUpdateItem={onUpate}
        isLoading={isLoading}
        onRefresh={() => setLastUpdatedAt(Date.now())}
      />
    </Grid>
  );
}
