import moment from 'moment-timezone';

import {
  getIssuesByClientByCreatedAt,
} from 'graphql/queries';
import {
  getOrdersByClientByDate,
} from './queries';
import { asyncListAll, asyncListAllAndUpdateLastItem } from 'utilities/graph';
import { TIME_ZONE } from '@silvergatedelivery/constants';

export function getStartEndByPeriodName(inPeriod) {
  const [period, startBaseline, endBaseline] = inPeriod.split('/');
  const startBaseNumber = parseInt(startBaseline || '0');
  const endBaseNumber = parseInt(endBaseline || '0');
  const start = moment().add(startBaseNumber, `${period}s`).tz(TIME_ZONE).startOf(period);
  const end = moment().add(endBaseNumber, `${period}s`).tz(TIME_ZONE).endOf(period);
  return {
    start: start.toISOString(),
    end: end.toISOString(),
    prevStart: moment().tz(TIME_ZONE).add(-1 + startBaseNumber, `${period}s`).startOf(period).toISOString(),
    prevEnd: moment().tz(TIME_ZONE).add(-1 + endBaseNumber, `${period}s`).endOf(period).toISOString(),
    startFormat: start.format('YYYY-MM-DD'),
    endFormat: end.format('YYYY-MM-DD'),
  };
}

export function getDatesBetween(startISO, endISO, format='YYYY-MM-DD') {
  const startDate = moment(startISO);
  const endDate = moment(endISO);
  const dates = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    dates.push(startDate.format(format));
    startDate.add(1, 'days');
  }

  return dates;
}

export const getOrders = async (clientIds, start, end, onUpdateLastItem) => {
  let results = [];
  await Promise.all(clientIds.map(async (clientId) => {
    const data = await asyncListAllAndUpdateLastItem(getOrdersByClientByDate, {
      clientId,
      date: {
        between: [start, end],
      },
      limit: 1000,
    }, onUpdateLastItem);
    results = [...results, ...data];
  }));

  return results;
};
export const getIssues = async (clientIds, start, end) => {
  let results = [];
  await Promise.all(clientIds.map(async (clientId) => {
    const data = await asyncListAll(getIssuesByClientByCreatedAt, {
      clientId,
      createdAt: {
        between: [start, end],
      },
    });
    results = [...results, ...data];
  }));

  return results;
};
