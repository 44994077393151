import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';

import { request } from 'utilities/graph';
import { newebPayGetTradePostData, newebPayConfirmTransaction } from 'graphql/mutations';
import cache from 'utilities/cache';

export default function NewebPayButton({
  order = {},
  redirectPath,
  clientBackPath,
  email,
  onComplete,
  disabled,
  reportLoading,
  textButton = false,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = async () => {
    setIsLoading(true);
    reportLoading(true);
    cache.set('public:menu-payment-service', 'newebpay');
    try {
      const clientId = cache.get('app:clientId');
      const {
        subTotal,
        discount,
        discountRule,
        amount,
        description,
        category,
        items,
      } = order;

      const input = {
        clientId,
        subTotal,
        discount,
        discountRule,
        amount,
        description,
        category,
        items,
        redirectPath,
        clientBackPath,
        email,
      };

      const {
        data: { newebPayGetTradePostData: { data: newebPayGetTradePostDataResult } },
      } = await request(newebPayGetTradePostData, { input });
      const postData = JSON.parse(newebPayGetTradePostDataResult);
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = postData.url;

      Object.keys(postData.data).forEach((key) => {
        const inputNewebPay = document.createElement('input');
        inputNewebPay.type = 'hidden';
        inputNewebPay.name = key;
        inputNewebPay.value = postData.data[key];
        form.appendChild(inputNewebPay);
      });
      document.body.appendChild(form);
      form.submit();
    } catch (e) {
      global.logger.error(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        reportLoading(false);
      }, 1000);
    }
  };

  const handleConfirm = async (transactionId) => {
    setIsLoading(true);
    reportLoading(true);
    try {
      const { data: { newebPayConfirmTransaction: { data: newebPayConfirmTransactionResult } } } = await request(newebPayConfirmTransaction, {
        input: {
          transactionId,
        },
      });

      const {
        status,
      } = JSON.parse(newebPayConfirmTransactionResult);

      global.logger.debug({
        status,
      });
      if (status === 'approved') {
        onComplete({ id: transactionId });
      }
    } catch (e) {
      global.logger.error(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        reportLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const { transactionId } = querystring.parse(window.location.search);
    const paymentService = cache.get('public:menu-payment-service');
    if (paymentService === 'newebpay' && transactionId) {
      return handleConfirm(transactionId);
    } else {
      setIsLoading(false);
      reportLoading(false);
    }
  }, []);

  return (
    <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {textButton ?
        <Button
          variant='contained'
          onClick={handleClick}
          disabled={disabled || isLoading}
          style={{
            width: '100%',
            borderRadius: '20px',
            color: 'white',
          }}
          color="primary"
        >
          確認付款（信用卡或網路ATM）
          {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100, borderRadius: '20px' }}>
            <CircularProgress color="primary" size="1.5rem" />
          </Backdrop>}
        </Button> :
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClick}
          disabled={disabled || isLoading}
          style={{
            position: 'relative',
            minWidth: 350,
            borderRadius: 5,
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
            height: 60,
          }}
        >
          <Typography variant="body1" >信用卡或網路ATM (使用藍新金流)</Typography>
          {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100 }}>
            <CircularProgress color="primary" />
          </Backdrop>}
        </Button>
      }
    </div>
  );
}

NewebPayButton.propTypes = {
  disabled: PropTypes.bool,
  order: PropTypes.object,
  onComplete: PropTypes.func,
  redirectPath: PropTypes.string,
  clientBackPath: PropTypes.string,
  email: PropTypes.string,
  reportLoading: PropTypes.func,
  textButton: PropTypes.bool,
};
