import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { clientStatus } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 22,
    margin: 2,
    fontWeight: 'bold',
  },
}));

export default function ClientChip({ status }) {
  const classes = useStyles();
  const matchedOrderStatus = clientStatus.find((item) => item.value === status);
  const label = matchedOrderStatus ? matchedOrderStatus.label : 'N/A';
  const backgroundColor = matchedOrderStatus ? matchedOrderStatus.color : undefined;
  const color = backgroundColor ? '#fff' : undefined;

  return (<Chip
    label={label}
    className={classes.chip}
    style={{ color, backgroundColor }}
  />);
}

ClientChip.propTypes = {
  status: PropTypes.number,
};
