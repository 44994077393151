import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { request } from 'utilities/graph';
import {
  adminUpdateOrder,
} from 'graphql/mutations';
import cache from 'utilities/cache';
import { Semaphore } from 'async-mutex';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import { toastr } from 'react-redux-toastr';

export default function UpdateOrderToCompletedButton({
  onUpdate,
  ordersSelected,
}) {
  const { t } = useTranslation();
  const username = cache.get('app:username');
  const defaultDiary = `${username}將訂單標示為已完成`;
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [diary, setDiary] = useState(defaultDiary);
  const [progress, setProgress] = useState(0);

  const handleSubmit = async () => {
    setIsLoading(true);
    const ordersIncompleted = ordersSelected.current.filter((order) => order.status !== 'completed');
    let orderProcessed = 0;
    const toUpdateData = [];
    const s = new Semaphore(10);
    await Promise.all(ordersIncompleted.map((order) =>
      s.runExclusive(async () => {
        const data = {
          id: order.id,
          restaurantId: order.restaurantId,
          deliveryBy: order.deliveryBy,
          status: 'completed',
          tier: order.tier,
          diary,
        };
        try {
          await request(adminUpdateOrder, { input: { orders: [data] } });
          toUpdateData.push(data);
        } catch (e) {
          console.log(e);
          if (e.errors && e.errors[0] && e.errors[0].message) {
            toastr.error(e.errors[0].message);
          }
        }
        orderProcessed += 1;
        setProgress(orderProcessed * 100 / ordersIncompleted.length);
      }),
    ));

    onUpdate && onUpdate(toUpdateData);

    setIsLoading(false);
    handleClose();
  };

  const onSetOrdersAsCompleted = async () => {
    const ordersIncompleted = ordersSelected.current.filter((order) => order.status !== 'completed')
      .sort((a, b) => a.date > b.date ? 1 : -1);
    if (ordersIncompleted.some(({ orderGroupId }) => orderGroupId)) {
      toastr.error('無法變更多點配送訂單，請先取消該訂單');
      return;
    }

    if (ordersIncompleted.length !== 0) {
      let orderDuration = `修改訂單的送餐日期範圍：${ordersIncompleted[0].date} 至 ${ordersIncompleted[ordersIncompleted.length - 1].date}`;
      if (ordersIncompleted[0].date === ordersIncompleted[ordersIncompleted.length - 1].date) {
        orderDuration = `修改訂單的送餐日期：${ordersIncompleted[0].date}`;
      }
      if (window.confirm(`共${ordersIncompleted.length}筆未完成的訂單, 確定要修改這${ordersIncompleted.length}筆訂單?\n${orderDuration}`)) {
        setOpen(true);
      }
    } else {
      toastr.error(`選擇的訂單中沒有未完成的訂單`);
    }
  };

  const handleClose = () => {
    setDiary(defaultDiary);
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        style={ { marginLeft: 10 } }
        variant="contained"
        color="primary"
        onClick={async () => {
          await onSetOrdersAsCompleted(onUpdate);
        }}
      >
        標示為訂單已完成
      </Button>
      <Dialog
        maxWidth='sm'
        fullWidth
        open={open}
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!isLoading ? t('送餐關懷') : '更新訂單中'}
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          {!isLoading ?
            <TextField
              margin="dense"
              id="diary"
              label="日誌"
              type="text"
              value={diary}
              fullWidth
              required
              multiline={true}
              minRows={5}
              onChange={(e) => setDiary(e.target.value) }
              style={{ width: 360 }}
            /> :
            <LinearProgressWithLabel value={progress}/>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isLoading || !diary}>
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

UpdateOrderToCompletedButton.propTypes = {
  onUpdate: PropTypes.func,
  ordersSelected: PropTypes.object,
};
