import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import { getTranslatedOrderStatus } from 'utilities/translate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export default function OrderStatusProgress({ status, onClick = () => {} }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const filteredOrderStatus = getTranslatedOrderStatus()
    .filter(({ step }) => step !== void 0)
    .sort((a, b) => a.step > b.step ? 1 : -1);

  useEffect(() => {
    const completed = filteredOrderStatus.map(({ step }) => {
      return 0 <= step && step <= activeStep;
    });
    setCompleted(completed);
  }, [activeStep]);

  useEffect(() => {
    if (status) {
      const matched = filteredOrderStatus.find(({ value }) => value === status);
      if (matched) {
        setActiveStep(matched.step);
      } else {
        setActiveStep(-1);
      }
    }
  }, [status]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} nonLinear alternativeLabel>
        {filteredOrderStatus.map(({ label, color, icon }, index) => (
          <Step key={label}>
            <StepButton
              onClick={onClick}
              // onClick={()=>setActiveStep(index)}
              completed={completed[index]}
            >
              <StepLabel
                // StepIconProps={{
                //   // classes: { root: { color } },
                //   // active: activeStep === index,
                //   // completed: completed[index],
                //   // icon,
                // }}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

OrderStatusProgress.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
};
