/* eslint-disable quotes, comma-dangle */
import i18n from 'i18next';
import {
  deliveryStaffTransactionStatus,
  deliveryStaffTransactionTypes,
} from '@silvergatedelivery/constants';

const schema = {
  "title": "",
  "type": "object",
  "required": [
    "deliveryStaffId",
    "status", "type", "amount"
  ],
  "properties": {
    // "username": {
    //   "type": "string",
    //   "title": "用戶編號"
    // },
    "deliveryStaffId": {
      "type": "string",
      "title": i18n.t('送餐大使'),
    },
    "type": {
      "type": "string",
      "title": "類別",
      'enum': deliveryStaffTransactionTypes.map(({ value }) => value),
      'enumNames': deliveryStaffTransactionTypes.map(({ label }) => label),
      'default': deliveryStaffTransactionTypes[0].value,
    },
    "amount": {
      "type": "number",
      "title": "S Token 數量"
    },
    "status": {
      "type": "string",
      "title": "狀態",
      'enum': deliveryStaffTransactionStatus.map(({ value }) => value),
      'enumNames': deliveryStaffTransactionStatus.map(({ label }) => label),
      'default': deliveryStaffTransactionStatus[0].value,
    },
    "summary": {
      "type": ["string", "null"],
      "title": "簡述"
    },
    "note": {
      "type": ["string", "null"],
      "title": "備註"
    }
  }
};

export default schema;
