import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { createSponsorship, updateSponsorship } from 'graphql/mutations';
import schema from './schema.js';
import uiSchema from './uiSchema';

export default function Form({ ...props }) {
  const createFunc = async (data) => {
    const { data: { getClient: client } } = await request( /* GraphQL */ `
    query GetClient($id: ID!) {
      getClient(id: $id) {
        id
        username
      }
    }
  `, { id: data.clientId });

    data = Object.assign(data, {
      fulfilledCount: 0,
      fulfilledValue: 0,
      username: client.username || 'N/A',
    });

    await request(createSponsorship, { input: data });
  };

  const updateFunc = async (data) => {
    delete data.client;
    delete data.sponsorProgram;
    delete data.fulfillments;

    await request(updateSponsorship, { input: data });
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

Form.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
