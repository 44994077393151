import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsiveBar } from '@nivo/bar';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  value: {
    margin: theme.spacing(1),
  },
}));

export default function BarCard({ title, data = [], dataKeys = [], mode = 'grouped', colors }) {
  const classes = useStyles();
  // global.logger.debug({ data, dataKeys });
  return (
    <Paper className={classes.container}>
      <Typography variant="subtitle2" component="p">
        {title}
      </Typography>
      <Grid container align="center" justifyContent="center">
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveBar
            data={data}
            keys={dataKeys}
            indexBy="name"
            groupMode={mode}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            colors={colors || { scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            enableTotals={true}
            // axisBottom={{
            //   tickSize: 5,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   legend: 'country',
            //   legendPosition: 'middle',
            //   legendOffset: 32,
            // }}
            // axisLeft={{
            //   tickSize: 5,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   legend: '數量',
            //   legendPosition: 'middle',
            //   legendOffset: -40,
            // }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['brighter', 2]] }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      </Grid>
    </Paper>);
}


BarCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  dataKeys: PropTypes.array,
  mode: PropTypes.string,
  colors: PropTypes.any,
};
