import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { request } from 'utilities/graph';
import { getOrder } from 'graphql/queries';
import { adminUpdateOrder } from 'graphql/mutations';
import { Semaphore } from 'async-mutex';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import { toastr } from 'react-redux-toastr';
import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';

export default function UpdateOrderTimeButton({
  onUpdate,
  ordersSelected,
  noSubmit=false,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newDeliveryTime, setNewDeliverTime] = useState('12:00');

  const handleSubmit = async () => {
    if (noSubmit) {
      onUpdate && onUpdate(newDeliveryTime);
      handleClose();
      return;
    }

    setIsLoading(true);
    const ordersUndelivered = ordersSelected.current.filter((order) => !['cancelled', 'delivered', 'completed'].includes(order.status));

    let orderProcessed = 0;
    const toUpdateData = [];
    const s = new Semaphore(10);
    const [newHour, newMinute] = newDeliveryTime.split(':');
    await Promise.all(ordersUndelivered.map((order) =>
      s.runExclusive(async () => {
        const newDeliveryBy = moment.tz(order.deliveryBy, TIME_ZONE).hour(newHour).minute(newMinute).toISOString();
        const data = {
          id: order.id,
          restaurantId: order.restaurantId,
          deliveryBy: newDeliveryBy,
          status: order.status,
          tier: order.tier,
        };
        try {
          await request(adminUpdateOrder, { input: { orders: [data] } });
          const { data: { getOrder: updatedOrder } } = await request(getOrder, { id: order.id });
          toUpdateData.push(updatedOrder);
        } catch (e) {
          console.log(e);
          if (e.errors && e.errors[0] && e.errors[0].message) {
            toastr.error(e.errors[0].message);
          }
        }
        orderProcessed += 1;
        setProgress(orderProcessed * 100 / ordersUndelivered.length);
      }),
    ));
    onUpdate && onUpdate(toUpdateData);

    setIsLoading(false);
    handleClose();
  };

  const onUpdateDeliveryTime = async () => {
    const ordersUndelivered = ordersSelected.current.filter((order) => !['cancelled', 'delivered', 'completed'].includes(order.status))
      .sort((a, b) => a.date > b.date ? 1 : -1);
    if (ordersUndelivered.some(({ orderGroupId }) => orderGroupId)) {
      toastr.error('無法變更多點配送訂單，請先取消該訂單');
      return;
    }
    if (ordersUndelivered.length !== 0) {
      let orderDuration = `修改訂單的送餐日期範圍：${ordersUndelivered[0].date} 至 ${ordersUndelivered[ordersUndelivered.length - 1].date}`;
      if (ordersUndelivered[0].date === ordersUndelivered[ordersUndelivered.length - 1].date) {
        orderDuration = `修改訂單的送餐日期：${ordersUndelivered[0].date}`;
      }
      if (noSubmit || window.confirm(
        `共${ordersUndelivered.length}筆未送達的訂單, 確定要修改這${ordersUndelivered.length}筆訂單?\n${orderDuration}`)) {
        setOpen(true);
      }
    } else {
      toastr.error('選擇的訂單中沒有未送達的訂單');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setNewDeliverTime('12:00');
  };

  return (
    <Fragment>
      <Button
        style={ { marginLeft: 10 } }
        variant="contained"
        color="primary"
        onClick={async () => {
          await onUpdateDeliveryTime();
        }}
      >
        {`修改${t('送餐')}時間`}
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!isLoading ? `修改${t('送餐')}時間` : '更新訂單中'}
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          {!isLoading ?
            <TextField
              id="deliveryBy"
              label={`${t('送餐')}時間`}
              type="time"
              value={newDeliveryTime}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setNewDeliverTime(e.target.value);
              }}
            /> :
            <LinearProgressWithLabel value={progress}/>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isLoading || !newDeliveryTime}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

UpdateOrderTimeButton.propTypes = {
  onUpdate: PropTypes.func,
  ordersSelected: PropTypes.object,
  noSubmit: PropTypes.bool,
};
