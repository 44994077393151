export default [
  {
    label: '完成回答',
    value: 'completed',
  },
  {
    label: '暫無法回答',
    value: 'unableToAnswer',
  },
  {
    label: '不回答',
    value: 'noAnswer',
  },
];
