import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import { getDeliveryStaff } from 'graphql/queries';
import { request } from 'utilities/graph';

import ImageAvatar from 'components/ImageAvatar';

export default function DeliveryStaffDocumentTable({
  title = '資料檔案',
  description,
  deliveryStaffId,
  ...props
}) {
  const options = {};
  const columns = [
    {
      name: 'name',
      label: '檔案名稱',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 's3Key',
      label: '縮圖',
      options: {
        filter: false,
        sort: false,
        customBodyRender(value) {
          return (
            <ImageAvatar
              s3KeyPrefix={value}
            />
          );
        },
      },
    },
    {
      name: 'uploadedAt',
      label: '上傳於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const queryFunc = async () => {
    if (deliveryStaffId) {
      const { data: { getDeliveryStaff: data } } = await request(getDeliveryStaff, { id: deliveryStaffId });

      return data.documents || [];
    }

    return [];
  };

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        queryFunc={queryFunc}
        {...props}
      />
    </React.Fragment>
  );
}

DeliveryStaffDocumentTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  deliveryStaffId: PropTypes.string,
};
