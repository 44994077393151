import Colors from './Colors';

export default [
  {
    label: '處理中',
    value: 'pending',
    color: Colors.primary,
  },
  {
    label: '完成',
    value: 'approved',
    color: Colors.dark,
  },
  {
    label: '取消',
    value: 'voided',
    color: Colors.accent,
  },
  {
    label: '失敗',
    value: 'rejected',
    color: Colors.raised,
  },
];
