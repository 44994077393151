import React, { useEffect, useState } from 'react';
import querystring from 'query-string';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';
import { Auth } from 'aws-amplify';

import ProcessStatusChip from 'components/ProcessStatusChip';
import { listDeleteMyAccountRequestByUsername } from 'graphql/queries';
import { createDeleteMyAccountRequest } from 'graphql/mutations';
import { asyncListAll, request } from 'utilities/graph';

import { apps } from '../Download';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    padding: theme.spacing(4),
  },
}));

// This link will be shown on your Google Play store listing, and should:
// - refer to your app or developer name that is shown on your store listing
// - prominently feature the steps that users should take to request that their account is deleted
// - specify the types of data that are deleted or kept, and any additional retention period

export default function DeleteMyAccount() {
  const classes = useStyles();

  const [selectedApp, setSeletedApps] = useState(apps.client);
  const [user, setUser] = useState();
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [prevRequests, setPrevRequests] = useState([]);

  const submitDeletion = async () => {
    setIsLoading(true);
    console.log('submitDeletion');

    const { username } = user;
    const now = new Date().toISOString();

    const { data: { createDeleteMyAccountRequest: data } } = await request(createDeleteMyAccountRequest, {
      input: {
        username,
        appName: selectedApp.name,
        requestedAt: now,
        status: 'processing',
        reason,
        createdBy: username,
        updatedBy: username,
      },
    });

    setPrevRequests([data]);
    setIsLoading(false);
  };

  useEffect(() => {
    const { app: appName } = querystring.parse(window.location.search);
    const matched = apps[appName];

    if (matched) {
      setSeletedApps(matched);
    }

    (async () => {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      if (user) {
        setUser(user);
        setReason('');
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;

    (async () => {
      const { username } = user;
      const prevRequests = await asyncListAll(listDeleteMyAccountRequestByUsername, {
        username,
        filter: {
          appName: {
            eq: selectedApp.name,
          },
        },
      });
      setPrevRequests(prevRequests);
    })();
  }, [user, selectedApp]);

  return (
    <Container maxWidth="sm">
      <Grid className={classes.content} container alignItems="center" justifyContent="center" direction="column">
        <Box component="div" p={4}>
          <img src={selectedApp.logo || '/logos/logo_256.png'} alt="Logo" width="256" />
        </Box>
        <div>
          <Typography variant="h5">
            {selectedApp.name}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            銀色大門會將以下資料在30個工作天後刪除，並寄送通知至註冊的Email。
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>登入資訊 帳號/密碼</li>
              <li>個人資訊 地址/電話</li>
              <li>GPS資訊 (如果有提供)</li>
              <li>金融資訊 (如果有提供)</li>
            </ul>
          </Typography>
        </div>

        <Grid container justifyContent="space-around" alignItems="center">
          {!user &&
          <CustomAuthDialog
            mode= 'signIn'
            withButton={false}
            button={(props) => (
              <Button
                variant="contained"
                color="primary"
                size="large"
                {...props}
              >
                登入確認
              </Button>
            )}
          />}
          {user && prevRequests.length === 0 &&
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={isLoading}
              onClick={submitDeletion}
            >
              刪除我的帳號
            </Button>
          }
          {user && prevRequests.length > 0 &&
          prevRequests.map((req)=>(
            <div key={req.id}>
              <Typography variant="body2" >
                已申請刪除{selectedApp.name}帳號 ({moment(req.requestedAt).format('YYYY-MM-DD HH:mm:ss')})
              </Typography>
              <Typography variant="body2" >
                狀態：<ProcessStatusChip status={req.status} />
              </Typography>
            </div>
          ))
          }
        </Grid>
      </Grid>
    </Container>
  );
}
