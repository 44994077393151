import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function CustomLaneHeader({ label, title }) {
  const { t } = useTranslation();
  return (
    <div draggable={false}>
      <header
        style={{
          borderBottom: '2px solid #c5c5c5',
          paddingBottom: 6,
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>{title}</div>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>{label}</div>
      </header>
      <span style={{ fontSize: 14 }}>
        {`拖曳複製至右方${t('送餐群組')}。`}
      </span>
    </div>
  );
}

CustomLaneHeader.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
};
