import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Order from 'components/Order/Order';
// import OrderDialog from 'components/OrderDialog';

export default function OrderPage({ id: inId, computedMatch }) {
  const [id, setId] = useState();
  // const [open, setOpen] = useState(true);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);
  // <OrderDialog id={id} open={open} onClose={()=> setOpen(false)} />
  return (<Order id={id} />);
}

OrderPage.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
};
