import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import PandagoOrderStatusChip from 'components/PandagoOrderStatusChip';

export default function PandagoOrderLogTable({
  title = '訂單更新紀錄',
  description,
  data,
  ...props
}) {
  const options = {
    rowsPerPageOptions: [5, 10, 20, 50],
    rowsPerPage: 5,
  };

  const columns = [
    {
      name: 'client_order_id',
      label: '訂單編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'order_id',
      label: '編號',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <PandagoOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'timeline.estimated_pickup_time',
      label: '預計取餐時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'timeline.estimated_delivery_time',
      label: '預計送達時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'driver.id',
      label: '送餐員 ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'driver.name',
      label: '送餐員 名字',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'driver.phone_number',
      label: '送餐員 電話',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'created_at',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updated_at',
      label: '更新於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        {...props}
      />
    </React.Fragment>
  );
}

PandagoOrderLogTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};
