import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { asyncListAll } from 'utilities/graph';
import DataTable from 'components/Table/DataTable';
import Loading from 'components/Loading';

import { getEldersByClientByStatus, getDeliveryStaffsByClientIdByIsActive, getElderDeliveryGroupsByClientByStatus } from 'graphql/queries';
import cache from 'utilities/cache';

export default function AdminRecurringOrderPreviewTable({
  description,
  data: inData,
  ...props
}) {
  const [data, setData] = useState([]);
  const [elders, setElders] = useState([]);
  const [deliveryStaffs, setDeliveryStaffs] = useState([]);
  const [elderDeliveryGrops, setElderDeliveryGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    {
      name: 'id',
      label: '週期性排單編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffName',
      label: '送餐大使',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lunchMonday',
      label: ' 週一(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerMonday',
      label: '週一(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lunchTuesday',
      label: '週二(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerTuesday',
      label: '週二(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lunchWednesday',
      label: '週三(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerWednesday',
      label: '週三(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lunchThursday',
      label: '週四(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerThursday',
      label: '週四(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lunchFriday',
      label: '週五(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerFriday',
      label: '週五(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lunchSaturday',
      label: '週六(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerSaturday',
      label: '週六(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lunchSunday',
      label: '週日(午)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dinnerSunday',
      label: '週日(晚)',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    expandableRows: true,
    isRowExpandable: () => false,
  };

  useEffect(() => {
    const clientId = cache.get('app:facilityId');
    (async () => {
      const [deliveryStaffs, elders, elderDeliveryGroups] = await Promise.all([
        asyncListAll(getDeliveryStaffsByClientIdByIsActive, {
          clientId,
          isActive: { eq: 1 },
        }),
        asyncListAll(getEldersByClientByStatus, { clientId, status: { eq: '使用中' }, limit: 1000 }),
        asyncListAll(getElderDeliveryGroupsByClientByStatus, {
          clientId,
          status: { eq: '使用中' },
          limit: 1000,
        }),
      ]);
      setElders(elders);
      setDeliveryStaffs(deliveryStaffs);
      setElderDeliveryGroups(elderDeliveryGroups);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    const newData = [];
    if (elders.length === 0) {
      return;
    }
    if (deliveryStaffs.length === 0) {
      return;
    }
    inData.forEach(({ id, elderId, deliveryGroupId, elder, repeatOn, mealSlot: recurringOrderMealSlot, deliveryStaffId }) => {
      const deliveryStaff = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
      const elderIncluded = [];
      if (deliveryGroupId) {
        const filteredElderDeliveryGroups = elderDeliveryGrops.filter(({ deliveryGroupId: id }) => id === deliveryGroupId);
        filteredElderDeliveryGroups.forEach(({ elderId }) => {
          const elder = elders.find(({ id }) => id === elderId);
          if (elder) {
            elderIncluded.push(elder);
          }
        });
      }
      if (elderId && elder) {
        elderIncluded.push(elder);
      }
      elderIncluded.forEach((elder) => {
        const { name: deliveryStaffName = 'N/A' } = deliveryStaff || {};

        const getDelvieryStaffName = (week, mealSlot) => {
          if (recurringOrderMealSlot !== mealSlot) {
            return '';
          }
          if (repeatOn.includes(week)) {
            if ((mealSlot === 'lunch' && elder.lunchRepeatOn && elder.lunchRepeatOn.includes(week)) ||
              (mealSlot === 'dinner' && elder.dinnerRepeatOn && elder.dinnerRepeatOn.includes(week))) {
              return elder.name;
            }
          }
          return '';
        };

        newData.push({
          id,
          deliveryStaffName,
          lunchMonday: getDelvieryStaffName(1, 'lunch'),
          dinnerMonday: getDelvieryStaffName(1, 'dinner'),
          lunchTuesday: getDelvieryStaffName(2, 'lunch'),
          dinnerTuesday: getDelvieryStaffName(2, 'dinner'),
          lunchWednesday: getDelvieryStaffName(3, 'lunch'),
          dinnerWednesday: getDelvieryStaffName(3, 'dinner'),
          lunchThursday: getDelvieryStaffName(4, 'lunch'),
          dinnerThursday: getDelvieryStaffName(4, 'dinner'),
          lunchFriday: getDelvieryStaffName(5, 'lunch'),
          dinnerFriday: getDelvieryStaffName(5, 'dinner'),
          lunchSaturday: getDelvieryStaffName(6, 'lunch'),
          dinnerSaturday: getDelvieryStaffName(6, 'dinner'),
          lunchSunday: getDelvieryStaffName(0, 'lunch'),
          dinnerSunday: getDelvieryStaffName(0, 'dinner'),
        });
      });
    });
    setData(newData);
  }, [inData, elders, deliveryStaffs, elderDeliveryGrops]);

  if (isLoading) {
    return (<Loading fullScreen={false} />);
  }

  return (
    <DataTable
      data={data}
      title={''}
      description={description}
      columns={columns}
      options={options}
      defaultIndex={0}
      indexes={[]}
      queryDefaultParams={{}}
      {...props}
    />
  );
}

AdminRecurringOrderPreviewTable.propTypes = {
  description: PropTypes.string,
  data: PropTypes.array,
};
