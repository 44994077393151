import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getElderDementiaSurveyByClientByDate, listElderDementiaSurveys } from 'graphql/queries';
import DataTable from 'components/Table/DataTable';
import { TIME_ZONE, surveyResponse } from '@silvergatedelivery/constants';
import { getClientIdSchema } from 'forms/schemas';

const title = '失智防治成效';
const description = '';

const pastThreeDays = moment().tz(TIME_ZONE).add(-3, 'days').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function EventTable({ clientId }) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  const columns = [
    {
      name: 'createdAt',
      label: '紀錄時間',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elder.name',
      label: '姓名',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dementiaQuestionnaire',
      label: '問題',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(dementiaQuestionnaire) {
          return dementiaQuestionnaire.items[0].question;
        },
      },
    },
    {
      name: 'response',
      label: '回應',
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender(response) {
          return (surveyResponse.find(({ value }) => value === response) || {}).label || '';
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const clientOptions = await getClientIdSchema(null, '所屬機構', true);

      setIndexes([
        {
          name: 'getElderDementiaSurveyByClientByDate',
          label: '按機構查詢',
          partitionKey: 'clientId',
          sortKey: 'date',
          fields: [{
            label: '機構',
            key: 'clientId',
            type: 'select',
            options: clientOptions.map(({ id, name }) => ({ value: id, label: name })),
          }, {
            label: '問卷時間',
            key: 'date',
            type: 'date',
          }],
          operation: getElderDementiaSurveyByClientByDate,
        },
        {
          name: 'scan',
          label: '掃描全部資料',
          key: 'date',
          fields: [{
            label: `問卷時間`,
            key: 'date',
            type: 'date',
          }],
          operation: listElderDementiaSurveys,
        },
      ]);
      setQueryParams({
        clientId: clientOptions[0] && clientOptions[0].id,
        date: {
          from: pastThreeDays,
          to: today,
        },
      });
    })();
  }, []);

  if (!indexes || !queryParams) return <></>;

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        themeProps={{
          cell: {
            minWidth: 48,
            maxWidth: 480,
          },
        }}
        columns={columns}
        options={options}
        defaultIndex={0}
        indexes={indexes}
        queryDefaultParams={queryParams}
      />
    </React.Fragment>
  );
}

EventTable.propTypes = {
  clientId: PropTypes.string,
};
