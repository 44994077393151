import React from 'react';

import EditButton from '../components/EditButton';
import Form from './MembershipForm';

export default function MembershipFormEditButton({ ...props }) {
  return (
    <EditButton
      title="付費會員"
      form={Form}
      {...props}
    />);
}
