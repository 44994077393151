import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Table from 'components/Table/Table';

import { sortBy } from 'utilities/sorting';
import {
  listUsers,
  getUsersByClientIdByIsActive,
  getUsersByOrganizationIdByIsActive,
  // getClient,
  getOrganization,
  getClientsByUsernameByType,
  // getRestaurant,
} from 'graphql/queries';
import { asyncListAll, request } from 'utilities/graph';
import UserFormEditButton from 'forms/UserForm/UserFormEditButton';
import { useCache } from 'CacheProvider';
import { getUserOrgAssigedFacilities } from 'components/Auth/helpers';

const title = '用戶列表';
const description = '';

export default function UserTable({
  organizationId,
  clientId,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();
  const { appGroup } = useCache();
  const options = {};

  const isAdmin = appGroup === 'Admins';

  const columns = [
    {
      name: 'isActive',
      label: '登入權限',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    (isAdmin) ? {
      name: 'isAdmin',
      label: '管理員',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    } : undefined,
    {
      name: 'isOrgAdmin',
      label: '組織負責人',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'orgName',
      label: '組織名稱',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isFacilityAdmin',
      label: '機構管理員',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'assignedFacilityName',
      label: '組織附屬機構',
      options: {
        filter: false,
        sort: true,
        customBodyRender(items) {
          if (items && items.length !== 0) {
            return <>
              {
                items.map((name) => {
                  return <div style={{ display: 'flex', alignItems: 'center' }} key={name}>
                    <Typography variant="body2" component="p" >{name}</Typography>
                  </div>;
                })
              }
            </>;
          }
          return '';
        },
      },
    },
    {
      name: 'ownedFacilityName',
      label: '私有機構',
      options: {
        filter: false,
        sort: true,
        customBodyRender(items) {
          if (items && items.length !== 0) {
            return <>
              {
                items.map((name) => {
                  return <div style={{ display: 'flex', alignItems: 'center' }} key={name}>
                    <Typography variant="body2" component="p" >{name}</Typography>
                  </div>;
                })
              }
            </>;
          }
          return '';
        },
      },
    },
    {
      name: 'isRestaurantOwner',
      label: `${t('餐廳')}負責人`,
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'restaurantName',
      label: `${t('餐廳')}名稱`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isDeliveryStaff',
      label: t('送餐大使'),
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'isClient',
      label: '客戶',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'username',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordWrap: 'break-word' }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: 'notificationToken',
      label: '通知Token',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'device.appVersion',
      label: 'APP版本',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'device',
      label: '作業系統',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (device) => {
          if (!device) return '';
          return `${device.osName} ${device.osVersion}`.substr(0, 15);
        },
      },
    },
    {
      name: 'device',
      label: '設備',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (device) => {
          if (!device) return '';
          return `${device.brand} ${device.modelName || ''}`;
        },
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
  ].filter((x) => x);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        let result = [];
        if (organizationId) {
          result = await asyncListAll(getUsersByOrganizationIdByIsActive, { organizationId, isActive: { eq: 1 } });
        } else
        if (clientId) {
          result = await asyncListAll(getUsersByClientIdByIsActive, { clientId, isActive: { eq: 1 } });
        } else {
          result = await asyncListAll(listUsers, { limit: 1000 });
        }

        const setUserData = async (user) => {
          const setFacilityName = async () => {
            user.assignedFacilityName = [];
            user.ownedFacilityName = [];
            if (user.facilityIds && user.facilityIds.length > 0) {
              const facilities = await getUserOrgAssigedFacilities(user, false);

              user.assignedFacilityName = facilities.map(({ name }) => name);
            }
            if (user.groups.items.find(({ group }) => group === 'FacilityAdmins')) {
              const facilities = await asyncListAll(getClientsByUsernameByType, {
                username: user.username,
                type: {
                  eq: 'facility',
                },
              });
              if (!facilities.length === 0) {
                global.logger.debug(user);
              } else {
                user.ownedFacilityName = facilities.map(({ name }) => name);
              }
            }
          };

          const setOrgName = async () => {
            if (user.organizationId && user.organizationId !== 'N/A') {
              const { data: { getOrganization: orgData } } = await request(getOrganization, { id: user.organizationId });
              if (!orgData) {
                global.logger.debug(user);
              } else {
                user.orgName = orgData.name;
              }
            }
          };

          const setRestaurantName = async () => {
            if (user.restaurantOwnerId) {
              const data = await asyncListAll( /* GraphQL */ `
                query GetRestaurantsByRestaurantOwnerIdByIsActive(
                  $restaurantOwnerId: ID
                  $isActive: ModelIntKeyConditionInput
                  $sortDirection: ModelSortDirection
                  $filter: ModelRestaurantFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  getRestaurantsByRestaurantOwnerIdByIsActive(
                    restaurantOwnerId: $restaurantOwnerId
                    isActive: $isActive
                    sortDirection: $sortDirection
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                      restaurantOwnerId
                      name
                    }
                    nextToken
                  }
                }
              `, { restaurantOwnerId: user.restaurantOwnerId });
              if (!data[0]) {
                global.logger.debug(user);
              } else {
                user.restaurantName = data[0].name;
              }
            }
          };

          await Promise.all([
            setFacilityName(),
            setOrgName(),
            setRestaurantName(),
          ]);


          user.roles = user.roles || '';
          if (user.groups.items.find(({ group }) => group === 'Admins')) {
            user.isAdmin = true;
          }
          if (user.groups.items.find(({ group }) => group === 'OrgAdmins')) {
            user.isOrgAdmin = true;
          }
          if (user.groups.items.find(({ group }) => group === 'FacilityAdmins')) {
            user.isFacilityAdmin = true;
          }
          if (user.groups.items.find(({ group }) => group === 'DeliveryStaffs')) {
            user.isDeliveryStaff = true;
          }
          if (user.groups.items.find(({ group }) => group === 'Clients')) {
            user.isClient = true;
          }
          if (user.groups.items.find(({ group }) => group === 'RestaurantOwners')) {
            user.isRestaurantOwner = true;
          }

          return user;
        };

        result = await Promise.all(result
          .sort(sortBy('name'))
          .sort(sortBy('username'))
          .map(setUserData));

        setData(result);
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lastUpdatedAt, organizationId, clientId]);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      editButton={isAdmin ? UserFormEditButton : undefined}
      isLoading={isLoading}
      onRefresh={() => setLastUpdatedAt(Date.now())}
    />
  );
}

UserTable.propTypes = {
  organizationId: PropTypes.string,
  clientId: PropTypes.string,
};
