import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import VerticalTabs from 'components/Tab/VerticalTabs';
import RestaurantTable from 'components/RestaurantTable';
import RestaurantOwnerCard from 'components/Card/RestaurantOwnerCard';
import NotificationTable from 'components/NotificationTable';

export default function RestaurantOwnerDetails({ id: inId, computedMatch, isNested }) {
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    if (!id) return;

    const tabs = [
      {
        label: '基本資料',
        component: <RestaurantOwnerCard title={`${t('餐廳')}負責人`} id={id} hideExpand={true} showMap={true}/>,
      },
      {
        label: t('餐廳'),
        component: <RestaurantTable nested={true} restaurantOwnerId={id} />,
      },
      {
        label: '通知紀錄',
        component: <NotificationTable nested={true} restaurantOwnerId={id} padding={0} />,
      },
    ];
    setTabs(tabs);
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={isNested}
    />
  );
}

RestaurantOwnerDetails.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  isNested: PropTypes.bool,
};
