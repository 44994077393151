export default [
  {
    label: '訂單設定',
    value: 'input',
  },
  {
    label: '送餐對象固定運費',
    value: 'fixed',
  },
  {
    label: '餐廳免運',
    value: 'free',
  },
  {
    label: '第三方平台',
    value: 'platform',
  },
  {
    label: '依距離計算',
    value: 'distance',
  },
];
