/* eslint-disable comma-dangle */
// for CJS
// module.exports = [
export default [
  {
    'zipCode': '100',
    'county': '臺北市',
    'districts': ['中正區']
  },
  {
    'zipCode': '103',
    'county': '臺北市',
    'districts': ['大同區']
  },
  {
    'zipCode': '104',
    'county': '臺北市',
    'districts': ['中山區']
  },
  {
    'zipCode': '105',
    'county': '臺北市',
    'districts': ['松山區']
  },
  {
    'zipCode': '106',
    'county': '臺北市',
    'districts': ['大安區']
  },
  {
    'zipCode': '108',
    'county': '臺北市',
    'districts': ['萬華區']
  },
  {
    'zipCode': '110',
    'county': '臺北市',
    'districts': ['信義區']
  },
  {
    'zipCode': '111',
    'county': '臺北市',
    'districts': ['士林區']
  },
  {
    'zipCode': '112',
    'county': '臺北市',
    'districts': ['北投區']
  },
  {
    'zipCode': '114',
    'county': '臺北市',
    'districts': ['內湖區']
  },
  {
    'zipCode': '115',
    'county': '臺北市',
    'districts': ['南港區']
  },
  {
    'zipCode': '116',
    'county': '臺北市',
    'districts': ['文山區']
  },
  {
    'zipCode': '200',
    'county': '基隆市',
    'districts': ['仁愛區']
  },
  {
    'zipCode': '201',
    'county': '基隆市',
    'districts': ['信義區']
  },
  {
    'zipCode': '202',
    'county': '基隆市',
    'districts': ['中正區']
  },
  {
    'zipCode': '203',
    'county': '基隆市',
    'districts': ['中山區']
  },
  {
    'zipCode': '204',
    'county': '基隆市',
    'districts': ['安樂區']
  },
  {
    'zipCode': '205',
    'county': '基隆市',
    'districts': ['暖暖區']
  },
  {
    'zipCode': '206',
    'county': '基隆市',
    'districts': ['七堵區']
  },
  {
    'zipCode': '207',
    'county': '新北市',
    'districts': ['萬里區']
  },
  {
    'zipCode': '208',
    'county': '新北市',
    'districts': ['金山區']
  },
  {
    'zipCode': '209',
    'county': '連江縣',
    'districts': ['南竿鄉']
  },
  {
    'zipCode': '210',
    'county': '連江縣',
    'districts': ['北竿鄉']
  },
  {
    'zipCode': '211',
    'county': '連江縣',
    'districts': ['莒光鄉']
  },
  {
    'zipCode': '212',
    'county': '連江縣',
    'districts': ['東引鄉']
  },
  {
    'zipCode': '220',
    'county': '新北市',
    'districts': ['板橋區']
  },
  {
    'zipCode': '221',
    'county': '新北市',
    'districts': ['汐止區']
  },
  {
    'zipCode': '222',
    'county': '新北市',
    'districts': ['深坑區']
  },
  {
    'zipCode': '223',
    'county': '新北市',
    'districts': ['石碇區']
  },
  {
    'zipCode': '224',
    'county': '新北市',
    'districts': ['瑞芳區']
  },
  {
    'zipCode': '226',
    'county': '新北市',
    'districts': ['平溪區']
  },
  {
    'zipCode': '227',
    'county': '新北市',
    'districts': ['雙溪區']
  },
  {
    'zipCode': '228',
    'county': '新北市',
    'districts': ['貢寮區']
  },
  {
    'zipCode': '231',
    'county': '新北市',
    'districts': ['新店區']
  },
  {
    'zipCode': '232',
    'county': '新北市',
    'districts': ['坪林區']
  },
  {
    'zipCode': '233',
    'county': '新北市',
    'districts': ['烏來區']
  },
  {
    'zipCode': '234',
    'county': '新北市',
    'districts': ['永和區']
  },
  {
    'zipCode': '235',
    'county': '新北市',
    'districts': ['中和區']
  },
  {
    'zipCode': '236',
    'county': '新北市',
    'districts': ['土城區']
  },
  {
    'zipCode': '237',
    'county': '新北市',
    'districts': ['三峽區']
  },
  {
    'zipCode': '238',
    'county': '新北市',
    'districts': ['樹林區']
  },
  {
    'zipCode': '239',
    'county': '新北市',
    'districts': ['鶯歌區']
  },
  {
    'zipCode': '241',
    'county': '新北市',
    'districts': ['三重區']
  },
  {
    'zipCode': '242',
    'county': '新北市',
    'districts': ['新莊區']
  },
  {
    'zipCode': '243',
    'county': '新北市',
    'districts': ['泰山區']
  },
  {
    'zipCode': '244',
    'county': '新北市',
    'districts': ['林口區']
  },
  {
    'zipCode': '247',
    'county': '新北市',
    'districts': ['蘆洲區']
  },
  {
    'zipCode': '248',
    'county': '新北市',
    'districts': ['五股區']
  },
  {
    'zipCode': '249',
    'county': '新北市',
    'districts': ['八里區']
  },
  {
    'zipCode': '251',
    'county': '新北市',
    'districts': ['淡水區']
  },
  {
    'zipCode': '252',
    'county': '新北市',
    'districts': ['三芝區']
  },
  {
    'zipCode': '253',
    'county': '新北市',
    'districts': ['石門區']
  },
  {
    'zipCode': '260',
    'county': '宜蘭縣',
    'districts': ['宜蘭市']
  },
  {
    'zipCode': '261',
    'county': '宜蘭縣',
    'districts': ['頭城鎮']
  },
  {
    'zipCode': '262',
    'county': '宜蘭縣',
    'districts': ['礁溪鄉']
  },
  {
    'zipCode': '263',
    'county': '宜蘭縣',
    'districts': ['壯圍鄉']
  },
  {
    'zipCode': '264',
    'county': '宜蘭縣',
    'districts': ['員山鄉']
  },
  {
    'zipCode': '265',
    'county': '宜蘭縣',
    'districts': ['羅東鎮']
  },
  {
    'zipCode': '266',
    'county': '宜蘭縣',
    'districts': ['三星鄉']
  },
  {
    'zipCode': '267',
    'county': '宜蘭縣',
    'districts': ['大同鄉']
  },
  {
    'zipCode': '268',
    'county': '宜蘭縣',
    'districts': ['五結鄉']
  },
  {
    'zipCode': '269',
    'county': '宜蘭縣',
    'districts': ['冬山鄉']
  },
  {
    'zipCode': '270',
    'county': '宜蘭縣',
    'districts': ['蘇澳鎮']
  },
  {
    'zipCode': '272',
    'county': '宜蘭縣',
    'districts': ['南澳鄉']
  },
  {
    'zipCode': '290',
    'county': '釣魚台',
    'districts': ['']
  },
  {
    'zipCode': '300',
    'county': '新竹市',
    'districts': ['東區', '北區', '香山區']
  },
  {
    'zipCode': '302',
    'county': '新竹縣',
    'districts': ['竹北市']
  },
  {
    'zipCode': '303',
    'county': '新竹縣',
    'districts': ['湖口鄉']
  },
  {
    'zipCode': '304',
    'county': '新竹縣',
    'districts': ['新豐鄉']
  },
  {
    'zipCode': '305',
    'county': '新竹縣',
    'districts': ['新埔鎮']
  },
  {
    'zipCode': '306',
    'county': '新竹縣',
    'districts': ['關西鎮']
  },
  {
    'zipCode': '307',
    'county': '新竹縣',
    'districts': ['芎林鄉']
  },
  {
    'zipCode': '308',
    'county': '新竹縣',
    'districts': ['寶山鄉']
  },
  {
    'zipCode': '310',
    'county': '新竹縣',
    'districts': ['竹東鎮']
  },
  {
    'zipCode': '311',
    'county': '新竹縣',
    'districts': ['五峰鄉']
  },
  {
    'zipCode': '312',
    'county': '新竹縣',
    'districts': ['橫山鄉']
  },
  {
    'zipCode': '313',
    'county': '新竹縣',
    'districts': ['尖石鄉']
  },
  {
    'zipCode': '314',
    'county': '新竹縣',
    'districts': ['北埔鄉']
  },
  {
    'zipCode': '315',
    'county': '新竹縣',
    'districts': ['峨眉鄉']
  },
  {
    'zipCode': '320',
    'county': '桃園市',
    'districts': ['中壢區']
  },
  {
    'zipCode': '324',
    'county': '桃園市',
    'districts': ['平鎮區']
  },
  {
    'zipCode': '325',
    'county': '桃園市',
    'districts': ['龍潭區']
  },
  {
    'zipCode': '326',
    'county': '桃園市',
    'districts': ['楊梅區']
  },
  {
    'zipCode': '327',
    'county': '桃園市',
    'districts': ['新屋區']
  },
  {
    'zipCode': '328',
    'county': '桃園市',
    'districts': ['觀音區']
  },
  {
    'zipCode': '330',
    'county': '桃園市',
    'districts': ['桃園區']
  },
  {
    'zipCode': '333',
    'county': '桃園市',
    'districts': ['龜山區']
  },
  {
    'zipCode': '334',
    'county': '桃園市',
    'districts': ['八德區']
  },
  {
    'zipCode': '335',
    'county': '桃園市',
    'districts': ['大溪區']
  },
  {
    'zipCode': '336',
    'county': '桃園市',
    'districts': ['復興區']
  },
  {
    'zipCode': '337',
    'county': '桃園市',
    'districts': ['大園區']
  },
  {
    'zipCode': '338',
    'county': '桃園市',
    'districts': ['蘆竹區']
  },
  {
    'zipCode': '350',
    'county': '苗栗縣',
    'districts': ['竹南鎮']
  },
  {
    'zipCode': '351',
    'county': '苗栗縣',
    'districts': ['頭份市']
  },
  {
    'zipCode': '352',
    'county': '苗栗縣',
    'districts': ['三灣鄉']
  },
  {
    'zipCode': '353',
    'county': '苗栗縣',
    'districts': ['南庄鄉']
  },
  {
    'zipCode': '354',
    'county': '苗栗縣',
    'districts': ['獅潭鄉']
  },
  {
    'zipCode': '356',
    'county': '苗栗縣',
    'districts': ['後龍鎮']
  },
  {
    'zipCode': '357',
    'county': '苗栗縣',
    'districts': ['通霄鎮']
  },
  {
    'zipCode': '358',
    'county': '苗栗縣',
    'districts': ['苑裡鎮']
  },
  {
    'zipCode': '360',
    'county': '苗栗縣',
    'districts': ['苗栗市']
  },
  {
    'zipCode': '361',
    'county': '苗栗縣',
    'districts': ['造橋鄉']
  },
  {
    'zipCode': '362',
    'county': '苗栗縣',
    'districts': ['頭屋鄉']
  },
  {
    'zipCode': '363',
    'county': '苗栗縣',
    'districts': ['公館鄉']
  },
  {
    'zipCode': '364',
    'county': '苗栗縣',
    'districts': ['大湖鄉']
  },
  {
    'zipCode': '365',
    'county': '苗栗縣',
    'districts': ['泰安鄉']
  },
  {
    'zipCode': '366',
    'county': '苗栗縣',
    'districts': ['銅鑼鄉']
  },
  {
    'zipCode': '367',
    'county': '苗栗縣',
    'districts': ['三義鄉']
  },
  {
    'zipCode': '368',
    'county': '苗栗縣',
    'districts': ['西湖鄉']
  },
  {
    'zipCode': '369',
    'county': '苗栗縣',
    'districts': ['卓蘭鎮']
  },
  {
    'zipCode': '400',
    'county': '臺中市',
    'districts': ['中區']
  },
  {
    'zipCode': '401',
    'county': '臺中市',
    'districts': ['東區']
  },
  {
    'zipCode': '402',
    'county': '臺中市',
    'districts': ['南區']
  },
  {
    'zipCode': '403',
    'county': '臺中市',
    'districts': ['西區']
  },
  {
    'zipCode': '404',
    'county': '臺中市',
    'districts': ['北區']
  },
  {
    'zipCode': '406',
    'county': '臺中市',
    'districts': ['北屯區']
  },
  {
    'zipCode': '407',
    'county': '臺中市',
    'districts': ['西屯區']
  },
  {
    'zipCode': '408',
    'county': '臺中市',
    'districts': ['南屯區']
  },
  {
    'zipCode': '411',
    'county': '臺中市',
    'districts': ['太平區']
  },
  {
    'zipCode': '412',
    'county': '臺中市',
    'districts': ['大里區']
  },
  {
    'zipCode': '413',
    'county': '臺中市',
    'districts': ['霧峰區']
  },
  {
    'zipCode': '414',
    'county': '臺中市',
    'districts': ['烏日區']
  },
  {
    'zipCode': '420',
    'county': '臺中市',
    'districts': ['豐原區']
  },
  {
    'zipCode': '421',
    'county': '臺中市',
    'districts': ['后里區']
  },
  {
    'zipCode': '422',
    'county': '臺中市',
    'districts': ['石岡區']
  },
  {
    'zipCode': '423',
    'county': '臺中市',
    'districts': ['東勢區']
  },
  {
    'zipCode': '424',
    'county': '臺中市',
    'districts': ['和平區']
  },
  {
    'zipCode': '426',
    'county': '臺中市',
    'districts': ['新社區']
  },
  {
    'zipCode': '427',
    'county': '臺中市',
    'districts': ['潭子區']
  },
  {
    'zipCode': '428',
    'county': '臺中市',
    'districts': ['大雅區']
  },
  {
    'zipCode': '429',
    'county': '臺中市',
    'districts': ['神岡區']
  },
  {
    'zipCode': '432',
    'county': '臺中市',
    'districts': ['大肚區']
  },
  {
    'zipCode': '433',
    'county': '臺中市',
    'districts': ['沙鹿區']
  },
  {
    'zipCode': '434',
    'county': '臺中市',
    'districts': ['龍井區']
  },
  {
    'zipCode': '435',
    'county': '臺中市',
    'districts': ['梧棲區']
  },
  {
    'zipCode': '436',
    'county': '臺中市',
    'districts': ['清水區']
  },
  {
    'zipCode': '437',
    'county': '臺中市',
    'districts': ['大甲區']
  },
  {
    'zipCode': '438',
    'county': '臺中市',
    'districts': ['外埔區']
  },
  {
    'zipCode': '439',
    'county': '臺中市',
    'districts': ['大安區']
  },
  {
    'zipCode': '500',
    'county': '彰化縣',
    'districts': ['彰化市']
  },
  {
    'zipCode': '502',
    'county': '彰化縣',
    'districts': ['芬園鄉']
  },
  {
    'zipCode': '503',
    'county': '彰化縣',
    'districts': ['花壇鄉']
  },
  {
    'zipCode': '504',
    'county': '彰化縣',
    'districts': ['秀水鄉']
  },
  {
    'zipCode': '505',
    'county': '彰化縣',
    'districts': ['鹿港鎮']
  },
  {
    'zipCode': '506',
    'county': '彰化縣',
    'districts': ['福興鄉']
  },
  {
    'zipCode': '507',
    'county': '彰化縣',
    'districts': ['線西鄉']
  },
  {
    'zipCode': '508',
    'county': '彰化縣',
    'districts': ['和美鎮']
  },
  {
    'zipCode': '509',
    'county': '彰化縣',
    'districts': ['伸港鄉']
  },
  {
    'zipCode': '510',
    'county': '彰化縣',
    'districts': ['員林市']
  },
  {
    'zipCode': '511',
    'county': '彰化縣',
    'districts': ['社頭鄉']
  },
  {
    'zipCode': '512',
    'county': '彰化縣',
    'districts': ['永靖鄉']
  },
  {
    'zipCode': '513',
    'county': '彰化縣',
    'districts': ['埔心鄉']
  },
  {
    'zipCode': '514',
    'county': '彰化縣',
    'districts': ['溪湖鎮']
  },
  {
    'zipCode': '515',
    'county': '彰化縣',
    'districts': ['大村鄉']
  },
  {
    'zipCode': '516',
    'county': '彰化縣',
    'districts': ['埔鹽鄉']
  },
  {
    'zipCode': '520',
    'county': '彰化縣',
    'districts': ['田中鎮']
  },
  {
    'zipCode': '521',
    'county': '彰化縣',
    'districts': ['北斗鎮']
  },
  {
    'zipCode': '522',
    'county': '彰化縣',
    'districts': ['田尾鄉']
  },
  {
    'zipCode': '523',
    'county': '彰化縣',
    'districts': ['埤頭鄉']
  },
  {
    'zipCode': '524',
    'county': '彰化縣',
    'districts': ['溪州鄉']
  },
  {
    'zipCode': '525',
    'county': '彰化縣',
    'districts': ['竹塘鄉']
  },
  {
    'zipCode': '526',
    'county': '彰化縣',
    'districts': ['二林鎮']
  },
  {
    'zipCode': '527',
    'county': '彰化縣',
    'districts': ['大城鄉']
  },
  {
    'zipCode': '528',
    'county': '彰化縣',
    'districts': ['芳苑鄉']
  },
  {
    'zipCode': '530',
    'county': '彰化縣',
    'districts': ['二水鄉']
  },
  {
    'zipCode': '540',
    'county': '南投縣',
    'districts': ['南投市']
  },
  {
    'zipCode': '541',
    'county': '南投縣',
    'districts': ['中寮鄉']
  },
  {
    'zipCode': '542',
    'county': '南投縣',
    'districts': ['草屯鎮']
  },
  {
    'zipCode': '544',
    'county': '南投縣',
    'districts': ['國姓鄉']
  },
  {
    'zipCode': '545',
    'county': '南投縣',
    'districts': ['埔里鎮']
  },
  {
    'zipCode': '546',
    'county': '南投縣',
    'districts': ['仁愛鄉']
  },
  {
    'zipCode': '551',
    'county': '南投縣',
    'districts': ['名間鄉']
  },
  {
    'zipCode': '552',
    'county': '南投縣',
    'districts': ['集集鎮']
  },
  {
    'zipCode': '553',
    'county': '南投縣',
    'districts': ['水里鄉']
  },
  {
    'zipCode': '555',
    'county': '南投縣',
    'districts': ['魚池鄉']
  },
  {
    'zipCode': '556',
    'county': '南投縣',
    'districts': ['信義鄉']
  },
  {
    'zipCode': '557',
    'county': '南投縣',
    'districts': ['竹山鎮']
  },
  {
    'zipCode': '558',
    'county': '南投縣',
    'districts': ['鹿谷鄉']
  },
  {
    'zipCode': '600',
    'county': '嘉義市',
    'districts': ['東區', '西區']
  },
  {
    'zipCode': '602',
    'county': '嘉義縣',
    'districts': ['番路鄉']
  },
  {
    'zipCode': '603',
    'county': '嘉義縣',
    'districts': ['梅山鄉']
  },
  {
    'zipCode': '604',
    'county': '嘉義縣',
    'districts': ['竹崎鄉']
  },
  {
    'zipCode': '605',
    'county': '嘉義縣',
    'districts': ['阿里山鄉']
  },
  {
    'zipCode': '606',
    'county': '嘉義縣',
    'districts': ['中埔鄉']
  },
  {
    'zipCode': '607',
    'county': '嘉義縣',
    'districts': ['大埔鄉']
  },
  {
    'zipCode': '608',
    'county': '嘉義縣',
    'districts': ['水上鄉']
  },
  {
    'zipCode': '611',
    'county': '嘉義縣',
    'districts': ['鹿草鄉']
  },
  {
    'zipCode': '612',
    'county': '嘉義縣',
    'districts': ['太保市']
  },
  {
    'zipCode': '613',
    'county': '嘉義縣',
    'districts': ['朴子市']
  },
  {
    'zipCode': '614',
    'county': '嘉義縣',
    'districts': ['東石鄉']
  },
  {
    'zipCode': '615',
    'county': '嘉義縣',
    'districts': ['六腳鄉']
  },
  {
    'zipCode': '616',
    'county': '嘉義縣',
    'districts': ['新港鄉']
  },
  {
    'zipCode': '621',
    'county': '嘉義縣',
    'districts': ['民雄鄉']
  },
  {
    'zipCode': '622',
    'county': '嘉義縣',
    'districts': ['大林鎮']
  },
  {
    'zipCode': '623',
    'county': '嘉義縣',
    'districts': ['溪口鄉']
  },
  {
    'zipCode': '624',
    'county': '嘉義縣',
    'districts': ['義竹鄉']
  },
  {
    'zipCode': '625',
    'county': '嘉義縣',
    'districts': ['布袋鎮']
  },
  {
    'zipCode': '630',
    'county': '雲林縣',
    'districts': ['斗南鎮']
  },
  {
    'zipCode': '631',
    'county': '雲林縣',
    'districts': ['大埤鄉']
  },
  {
    'zipCode': '632',
    'county': '雲林縣',
    'districts': ['虎尾鎮']
  },
  {
    'zipCode': '633',
    'county': '雲林縣',
    'districts': ['土庫鎮']
  },
  {
    'zipCode': '634',
    'county': '雲林縣',
    'districts': ['褒忠鄉']
  },
  {
    'zipCode': '635',
    'county': '雲林縣',
    'districts': ['東勢鄉']
  },
  {
    'zipCode': '636',
    'county': '雲林縣',
    'districts': ['臺西鄉']
  },
  {
    'zipCode': '637',
    'county': '雲林縣',
    'districts': ['崙背鄉']
  },
  {
    'zipCode': '638',
    'county': '雲林縣',
    'districts': ['麥寮鄉']
  },
  {
    'zipCode': '640',
    'county': '雲林縣',
    'districts': ['斗六市']
  },
  {
    'zipCode': '643',
    'county': '雲林縣',
    'districts': ['林內鄉']
  },
  {
    'zipCode': '646',
    'county': '雲林縣',
    'districts': ['古坑鄉']
  },
  {
    'zipCode': '647',
    'county': '雲林縣',
    'districts': ['莿桐鄉']
  },
  {
    'zipCode': '648',
    'county': '雲林縣',
    'districts': ['西螺鎮']
  },
  {
    'zipCode': '649',
    'county': '雲林縣',
    'districts': ['二崙鄉']
  },
  {
    'zipCode': '651',
    'county': '雲林縣',
    'districts': ['北港鎮']
  },
  {
    'zipCode': '652',
    'county': '雲林縣',
    'districts': ['水林鄉']
  },
  {
    'zipCode': '653',
    'county': '雲林縣',
    'districts': ['口湖鄉']
  },
  {
    'zipCode': '654',
    'county': '雲林縣',
    'districts': ['四湖鄉']
  },
  {
    'zipCode': '655',
    'county': '雲林縣',
    'districts': ['元長鄉']
  },
  {
    'zipCode': '700',
    'county': '臺南市',
    'districts': ['中西區']
  },
  {
    'zipCode': '701',
    'county': '臺南市',
    'districts': ['東區']
  },
  {
    'zipCode': '702',
    'county': '臺南市',
    'districts': ['南區']
  },
  {
    'zipCode': '704',
    'county': '臺南市',
    'districts': ['北區']
  },
  {
    'zipCode': '708',
    'county': '臺南市',
    'districts': ['安平區']
  },
  {
    'zipCode': '709',
    'county': '臺南市',
    'districts': ['安南區']
  },
  {
    'zipCode': '710',
    'county': '臺南市',
    'districts': ['永康區']
  },
  {
    'zipCode': '711',
    'county': '臺南市',
    'districts': ['歸仁區']
  },
  {
    'zipCode': '712',
    'county': '臺南市',
    'districts': ['新化區']
  },
  {
    'zipCode': '713',
    'county': '臺南市',
    'districts': ['左鎮區']
  },
  {
    'zipCode': '714',
    'county': '臺南市',
    'districts': ['玉井區']
  },
  {
    'zipCode': '715',
    'county': '臺南市',
    'districts': ['楠西區']
  },
  {
    'zipCode': '716',
    'county': '臺南市',
    'districts': ['南化區']
  },
  {
    'zipCode': '717',
    'county': '臺南市',
    'districts': ['仁德區']
  },
  {
    'zipCode': '718',
    'county': '臺南市',
    'districts': ['關廟區']
  },
  {
    'zipCode': '719',
    'county': '臺南市',
    'districts': ['龍崎區']
  },
  {
    'zipCode': '720',
    'county': '臺南市',
    'districts': ['官田區']
  },
  {
    'zipCode': '721',
    'county': '臺南市',
    'districts': ['麻豆區']
  },
  {
    'zipCode': '722',
    'county': '臺南市',
    'districts': ['佳里區']
  },
  {
    'zipCode': '723',
    'county': '臺南市',
    'districts': ['西港區']
  },
  {
    'zipCode': '724',
    'county': '臺南市',
    'districts': ['七股區']
  },
  {
    'zipCode': '725',
    'county': '臺南市',
    'districts': ['將軍區']
  },
  {
    'zipCode': '726',
    'county': '臺南市',
    'districts': ['學甲區']
  },
  {
    'zipCode': '727',
    'county': '臺南市',
    'districts': ['北門區']
  },
  {
    'zipCode': '730',
    'county': '臺南市',
    'districts': ['新營區']
  },
  {
    'zipCode': '731',
    'county': '臺南市',
    'districts': ['後壁區']
  },
  {
    'zipCode': '732',
    'county': '臺南市',
    'districts': ['白河區']
  },
  {
    'zipCode': '733',
    'county': '臺南市',
    'districts': ['東山區']
  },
  {
    'zipCode': '734',
    'county': '臺南市',
    'districts': ['六甲區']
  },
  {
    'zipCode': '735',
    'county': '臺南市',
    'districts': ['下營區']
  },
  {
    'zipCode': '736',
    'county': '臺南市',
    'districts': ['柳營區']
  },
  {
    'zipCode': '737',
    'county': '臺南市',
    'districts': ['鹽水區']
  },
  {
    'zipCode': '741',
    'county': '臺南市',
    'districts': ['善化區']
  },
  {
    'zipCode': '742',
    'county': '臺南市',
    'districts': ['大內區']
  },
  {
    'zipCode': '743',
    'county': '臺南市',
    'districts': ['山上區']
  },
  {
    'zipCode': '744',
    'county': '臺南市',
    'districts': ['新市區']
  },
  {
    'zipCode': '745',
    'county': '臺南市',
    'districts': ['安定區']
  },
  {
    'zipCode': '800',
    'county': '高雄市',
    'districts': ['新興區']
  },
  {
    'zipCode': '801',
    'county': '高雄市',
    'districts': ['前金區']
  },
  {
    'zipCode': '802',
    'county': '高雄市',
    'districts': ['苓雅區']
  },
  {
    'zipCode': '803',
    'county': '高雄市',
    'districts': ['鹽埕區']
  },
  {
    'zipCode': '804',
    'county': '高雄市',
    'districts': ['鼓山區']
  },
  {
    'zipCode': '805',
    'county': '高雄市',
    'districts': ['旗津區']
  },
  {
    'zipCode': '806',
    'county': '高雄市',
    'districts': ['前鎮區']
  },
  {
    'zipCode': '807',
    'county': '高雄市',
    'districts': ['三民區']
  },
  {
    'zipCode': '811',
    'county': '高雄市',
    'districts': ['楠梓區']
  },
  {
    'zipCode': '812',
    'county': '高雄市',
    'districts': ['小港區']
  },
  {
    'zipCode': '813',
    'county': '高雄市',
    'districts': ['左營區']
  },
  {
    'zipCode': '814',
    'county': '高雄市',
    'districts': ['仁武區']
  },
  {
    'zipCode': '815',
    'county': '高雄市',
    'districts': ['大社區']
  },
  {
    'zipCode': '817',
    'county': '高雄市',
    'districts': ['東沙群島']
  },
  {
    'zipCode': '819',
    'county': '高雄市',
    'districts': ['南沙群島']
  },
  {
    'zipCode': '820',
    'county': '高雄市',
    'districts': ['岡山區']
  },
  {
    'zipCode': '821',
    'county': '高雄市',
    'districts': ['路竹區']
  },
  {
    'zipCode': '822',
    'county': '高雄市',
    'districts': ['阿蓮區']
  },
  {
    'zipCode': '823',
    'county': '高雄市',
    'districts': ['田寮區']
  },
  {
    'zipCode': '824',
    'county': '高雄市',
    'districts': ['燕巢區']
  },
  {
    'zipCode': '825',
    'county': '高雄市',
    'districts': ['橋頭區']
  },
  {
    'zipCode': '826',
    'county': '高雄市',
    'districts': ['梓官區']
  },
  {
    'zipCode': '827',
    'county': '高雄市',
    'districts': ['彌陀區']
  },
  {
    'zipCode': '828',
    'county': '高雄市',
    'districts': ['永安區']
  },
  {
    'zipCode': '829',
    'county': '高雄市',
    'districts': ['湖內區']
  },
  {
    'zipCode': '830',
    'county': '高雄市',
    'districts': ['鳳山區']
  },
  {
    'zipCode': '831',
    'county': '高雄市',
    'districts': ['大寮區']
  },
  {
    'zipCode': '832',
    'county': '高雄市',
    'districts': ['林園區']
  },
  {
    'zipCode': '833',
    'county': '高雄市',
    'districts': ['鳥松區']
  },
  {
    'zipCode': '840',
    'county': '高雄市',
    'districts': ['大樹區']
  },
  {
    'zipCode': '842',
    'county': '高雄市',
    'districts': ['旗山區']
  },
  {
    'zipCode': '843',
    'county': '高雄市',
    'districts': ['美濃區']
  },
  {
    'zipCode': '844',
    'county': '高雄市',
    'districts': ['六龜區']
  },
  {
    'zipCode': '845',
    'county': '高雄市',
    'districts': ['內門區']
  },
  {
    'zipCode': '846',
    'county': '高雄市',
    'districts': ['杉林區']
  },
  {
    'zipCode': '847',
    'county': '高雄市',
    'districts': ['甲仙區']
  },
  {
    'zipCode': '848',
    'county': '高雄市',
    'districts': ['桃源區']
  },
  {
    'zipCode': '849',
    'county': '高雄市',
    'districts': ['那瑪夏區']
  },
  {
    'zipCode': '851',
    'county': '高雄市',
    'districts': ['茂林區']
  },
  {
    'zipCode': '852',
    'county': '高雄市',
    'districts': ['茄萣區']
  },
  {
    'zipCode': '880',
    'county': '澎湖縣',
    'districts': ['馬公市']
  },
  {
    'zipCode': '881',
    'county': '澎湖縣',
    'districts': ['西嶼鄉']
  },
  {
    'zipCode': '882',
    'county': '澎湖縣',
    'districts': ['望安鄉']
  },
  {
    'zipCode': '883',
    'county': '澎湖縣',
    'districts': ['七美鄉']
  },
  {
    'zipCode': '884',
    'county': '澎湖縣',
    'districts': ['白沙鄉']
  },
  {
    'zipCode': '885',
    'county': '澎湖縣',
    'districts': ['湖西鄉']
  },
  {
    'zipCode': '890',
    'county': '金門縣',
    'districts': ['金沙鎮']
  },
  {
    'zipCode': '891',
    'county': '金門縣',
    'districts': ['金湖鎮']
  },
  {
    'zipCode': '892',
    'county': '金門縣',
    'districts': ['金寧鄉']
  },
  {
    'zipCode': '893',
    'county': '金門縣',
    'districts': ['金城鎮']
  },
  {
    'zipCode': '894',
    'county': '金門縣',
    'districts': ['烈嶼鄉']
  },
  {
    'zipCode': '896',
    'county': '金門縣',
    'districts': ['烏坵鄉']
  },
  {
    'zipCode': '900',
    'county': '屏東縣',
    'districts': ['屏東市']
  },
  {
    'zipCode': '901',
    'county': '屏東縣',
    'districts': ['三地門鄉']
  },
  {
    'zipCode': '902',
    'county': '屏東縣',
    'districts': ['霧臺鄉']
  },
  {
    'zipCode': '903',
    'county': '屏東縣',
    'districts': ['瑪家鄉']
  },
  {
    'zipCode': '904',
    'county': '屏東縣',
    'districts': ['九如鄉']
  },
  {
    'zipCode': '905',
    'county': '屏東縣',
    'districts': ['里港鄉']
  },
  {
    'zipCode': '906',
    'county': '屏東縣',
    'districts': ['高樹鄉']
  },
  {
    'zipCode': '907',
    'county': '屏東縣',
    'districts': ['鹽埔鄉']
  },
  {
    'zipCode': '908',
    'county': '屏東縣',
    'districts': ['長治鄉']
  },
  {
    'zipCode': '909',
    'county': '屏東縣',
    'districts': ['麟洛鄉']
  },
  {
    'zipCode': '911',
    'county': '屏東縣',
    'districts': ['竹田鄉']
  },
  {
    'zipCode': '912',
    'county': '屏東縣',
    'districts': ['內埔鄉']
  },
  {
    'zipCode': '913',
    'county': '屏東縣',
    'districts': ['萬丹鄉']
  },
  {
    'zipCode': '920',
    'county': '屏東縣',
    'districts': ['潮州鎮']
  },
  {
    'zipCode': '921',
    'county': '屏東縣',
    'districts': ['泰武鄉']
  },
  {
    'zipCode': '922',
    'county': '屏東縣',
    'districts': ['來義鄉']
  },
  {
    'zipCode': '923',
    'county': '屏東縣',
    'districts': ['萬巒鄉']
  },
  {
    'zipCode': '924',
    'county': '屏東縣',
    'districts': ['崁頂鄉']
  },
  {
    'zipCode': '925',
    'county': '屏東縣',
    'districts': ['新埤鄉']
  },
  {
    'zipCode': '926',
    'county': '屏東縣',
    'districts': ['南州鄉']
  },
  {
    'zipCode': '927',
    'county': '屏東縣',
    'districts': ['林邊鄉']
  },
  {
    'zipCode': '928',
    'county': '屏東縣',
    'districts': ['東港鎮']
  },
  {
    'zipCode': '929',
    'county': '屏東縣',
    'districts': ['琉球鄉']
  },
  {
    'zipCode': '931',
    'county': '屏東縣',
    'districts': ['佳冬鄉']
  },
  {
    'zipCode': '932',
    'county': '屏東縣',
    'districts': ['新園鄉']
  },
  {
    'zipCode': '940',
    'county': '屏東縣',
    'districts': ['枋寮鄉']
  },
  {
    'zipCode': '941',
    'county': '屏東縣',
    'districts': ['枋山鄉']
  },
  {
    'zipCode': '942',
    'county': '屏東縣',
    'districts': ['春日鄉']
  },
  {
    'zipCode': '943',
    'county': '屏東縣',
    'districts': ['獅子鄉']
  },
  {
    'zipCode': '944',
    'county': '屏東縣',
    'districts': ['車城鄉']
  },
  {
    'zipCode': '945',
    'county': '屏東縣',
    'districts': ['牡丹鄉']
  },
  {
    'zipCode': '946',
    'county': '屏東縣',
    'districts': ['恆春鎮']
  },
  {
    'zipCode': '947',
    'county': '屏東縣',
    'districts': ['滿州鄉']
  },
  {
    'zipCode': '950',
    'county': '臺東縣',
    'districts': ['臺東市']
  },
  {
    'zipCode': '951',
    'county': '臺東縣',
    'districts': ['綠島鄉']
  },
  {
    'zipCode': '952',
    'county': '臺東縣',
    'districts': ['蘭嶼鄉']
  },
  {
    'zipCode': '953',
    'county': '臺東縣',
    'districts': ['延平鄉']
  },
  {
    'zipCode': '954',
    'county': '臺東縣',
    'districts': ['卑南鄉']
  },
  {
    'zipCode': '955',
    'county': '臺東縣',
    'districts': ['鹿野鄉']
  },
  {
    'zipCode': '956',
    'county': '臺東縣',
    'districts': ['關山鎮']
  },
  {
    'zipCode': '957',
    'county': '臺東縣',
    'districts': ['海端鄉']
  },
  {
    'zipCode': '958',
    'county': '臺東縣',
    'districts': ['池上鄉']
  },
  {
    'zipCode': '959',
    'county': '臺東縣',
    'districts': ['東河鄉']
  },
  {
    'zipCode': '961',
    'county': '臺東縣',
    'districts': ['成功鎮']
  },
  {
    'zipCode': '962',
    'county': '臺東縣',
    'districts': ['長濱鄉']
  },
  {
    'zipCode': '963',
    'county': '臺東縣',
    'districts': ['太麻里鄉']
  },
  {
    'zipCode': '964',
    'county': '臺東縣',
    'districts': ['金峰鄉']
  },
  {
    'zipCode': '965',
    'county': '臺東縣',
    'districts': ['大武鄉']
  },
  {
    'zipCode': '966',
    'county': '臺東縣',
    'districts': ['達仁鄉']
  },
  {
    'zipCode': '970',
    'county': '花蓮縣',
    'districts': ['花蓮市']
  },
  {
    'zipCode': '971',
    'county': '花蓮縣',
    'districts': ['新城鄉']
  },
  {
    'zipCode': '972',
    'county': '花蓮縣',
    'districts': ['秀林鄉']
  },
  {
    'zipCode': '973',
    'county': '花蓮縣',
    'districts': ['吉安鄉']
  },
  {
    'zipCode': '974',
    'county': '花蓮縣',
    'districts': ['壽豐鄉']
  },
  {
    'zipCode': '975',
    'county': '花蓮縣',
    'districts': ['鳳林鎮']
  },
  {
    'zipCode': '976',
    'county': '花蓮縣',
    'districts': ['光復鄉']
  },
  {
    'zipCode': '977',
    'county': '花蓮縣',
    'districts': ['豐濱鄉']
  },
  {
    'zipCode': '978',
    'county': '花蓮縣',
    'districts': ['瑞穗鄉']
  },
  {
    'zipCode': '979',
    'county': '花蓮縣',
    'districts': ['萬榮鄉']
  },
  {
    'zipCode': '981',
    'county': '花蓮縣',
    'districts': ['玉里鎮']
  },
  {
    'zipCode': '982',
    'county': '花蓮縣',
    'districts': ['卓溪鄉']
  },
  {
    'zipCode': '983',
    'county': '花蓮縣',
    'districts': ['富里鄉']
  }
];
