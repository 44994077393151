
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import shortuuid from 'short-uuid';

import DataTable from 'components/Table/DataTable';
import {
  listLalamoveOrders,
  getLalamoveOrdersByStatusByShouldBeSentToLalamove,
  getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove,
} from 'graphql/queries';
import Order from 'components/Order/Order';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import {
  TIME_ZONE,
  lalamoveOrderStatus,
  processStatus,
} from '@silvergatedelivery/constants';
import ProcessStatusChip from 'components/ProcessStatusChip';
import LalamoveOrderStatusChip from 'components/LalamoveOrderStatusChip';
import LalamoveOrderLogTable from 'components/LalamoveOrderLogTable.js';

const past = moment().add(-3, 'days').tz(TIME_ZONE).format('YYYY-MM-DD');
const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function LalamoveOrderTable({
  title = 'Lalamove 訂單',
  description,
  data,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [showQueryGenerator, setShowQueryGenerator] = useState(true);
  const [showNestedOrder, setShowNestedOrder] = useState(true);

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const [id, lalamoveDeliveryId, quotationId, orderIds, pickupIds, processlogs] = rowData; // eslint-disable-line no-unused-vars
      const orderIdsArray = orderIds.split(', ');
      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <LalamoveOrderLogTable data={processlogs.map(JSON.parse).reverse()} />
          {showNestedOrder && orderIdsArray.length == 1 && <Order id={orderIdsArray[0]} />}
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lalamoveDeliveryId',
      label: 'Lalamove編號',
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'quotationId',
      label: '報價單編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'orderIds',
      label: '訂單編號',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender(orderIds) {
          return orderIds && orderIds.join(', ');
        },
      },
    },
    {
      name: 'orderIds',
      label: '取餐編號',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender(orderIds) {
          return orderIds && orderIds.map((orderId) => shortuuid().fromUUID(orderId).slice(-6)).join(', ');
        },
      },
    },
    {
      name: 'processlogs',
      label: '紀錄',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'driverId',
      label: '送餐員編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'driver',
      label: '送餐員',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(driver) {
          const { name, phone } = driver || {};
          if (!name) return '';

          return `${name} ${phone || ''}`;
        },
      },
    },
    {
      name: 'processStatus',
      label: '處理狀況',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <ProcessStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'processStatusMessage',
      label: '處理資訊',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <LalamoveOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'shareLink',
      label: '追蹤網頁',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(shareLink) {
          if (!shareLink) {
            return;
          }
          return (
            <LinkButton
              path={shareLink}
              label="追蹤網頁"
            />
          );
        },
      },
    },
    {
      name: 'cancelledReason',
      label: '取消原因',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: '描述',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'fee',
      label: '送餐費用',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'shouldBeSentToLalamoveAt',
      label: '預計送單時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: 'lalamoveOrderPayload',
    //   label: 'Payload',
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: false,
    //     customBodyRender(data) {
    //       if (!data) return '';

    //       return <ReactJson
    //         src={JSON.parse(JSON.parse(data))}
    //         name={null}
    //         displayDataTypes={false}
    //       />;
    //     },
    //   },
    // },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderIds',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(orderIds) {
          return (
            orderIds && orderIds.length === 1 &&
            <LinkButton
              path={`/order/${orderIds[0]}`}
              label="前往訂單頁面"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setShowQueryGenerator(false);
      setShowNestedOrder(false);
      return;
    }
    setIndexes([
      {
        name: 'getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove',
        label: '按處理狀況查詢',
        partitionKey: 'processStatus',
        sortKey: 'shouldBeSentToLalamoveAt',
        fields: [{
          label: '處理狀況',
          key: 'processStatus',
          type: 'select',
          options: processStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToLalamoveAt',
          type: 'datetime',
        }],
        operation: getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove,
      },
      {
        name: 'getLalamoveOrdersByStatusByShouldBeSentToLalamove',
        label: '按Lalamove狀態查詢',
        partitionKey: 'status',
        sortKey: 'shouldBeSentToLalamoveAt',
        fields: [{
          label: '狀態',
          key: 'status',
          type: 'select',
          options: lalamoveOrderStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToLalamoveAt',
          type: 'datetime',
        }],
        operation: getLalamoveOrdersByStatusByShouldBeSentToLalamove,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '預計送單時間',
          key: 'shouldBeSentToLalamoveAt',
          type: 'datetime',
        }],
        operation: listLalamoveOrders,
      },
    ]);
    setQueryParams({
      processStatus: 'processing',
      shouldBeSentToLalamoveAt: {
        from: past,
        to: todayDate,
      },
    });
  }, [data]);

  return (
    <React.Fragment>
      <DataTable
        data={data}
        title={title}
        description={description}
        themeProps={{
          cell: {
            maxWidth: undefined,
          },
        }}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        showQueryGenerator={showQueryGenerator}
        {...props}
      />
    </React.Fragment>
  );
}

LalamoveOrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};
