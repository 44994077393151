import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from '@react-google-maps/api';
// import { Storage } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';

import {
  formatAddress,
  formatPolyline,
  getStartEndForDeliveryTime,
  formatDeliveryPeriod,
} from 'utilities/format';
import InfoCard from '../Card/InfoCard';
import { GOOGLE_KEY } from 'credentials.js';
import Directions from 'components/Map/Directions';
import MealItems from './MealItems';

export default function OrderDetails({ order, mode = 'static' }) {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [info, setInfo] = useState();
  // const [uri, setUri] = useState();
  // const [s3Key, setS3Key] = useState();

  // useEffect(() => {
  //   (async () => {
  //     setUri(await Storage.get(s3Key));
  //   })();
  // }, [s3Key]);

  // useEffect(() => {
  //   setS3Key(`orders/${orderId}/direction.png`);
  // }, [orderId]);

  const {
    direction,
    deliveryBy,
    mealItems,
    noteForMeal,
    noteForDelivery,
    note,
    restaurant,
    elder,
  } = order;

  global.logger.debug(order);

  const {
    bounds,
    startAddress: googleStartAddress,
    startLocation,
    endAddress: googleEndAddress,
    endLocation,
    overviewPolyline,
  } = direction;

  const addressFormatOptions = {
    ignoreCountry: true,
    includeZipCode: false,
  };

  const startAddress = formatAddress(restaurant.address, addressFormatOptions) +
    `\n(${restaurant.address.lat},${restaurant.address.lng})` +
    `\n - 導航: ${googleStartAddress}` +
    `\n(${startLocation.lat},${startLocation.lng})`;

  const endAddress = formatAddress(elder.address, addressFormatOptions) +
    `\n(${elder.address.lat},${elder.address.lng})` +
    `\n - 導航: ${googleEndAddress}` +
    `\n(${endLocation.lat},${endLocation.lng})`;

  const onLoad = React.useCallback(function callback(map) {
    if (!bounds) return;
    map.fitBounds({
      north: bounds.northeast.lat,
      south: bounds.southwest.lat,
      east: bounds.northeast.lng,
      west: bounds.southwest.lng,
    });
    setMap(map);
  }, [bounds]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (!startLocation || !endLocation) return null;

  const mappings = [
    {
      key: 'mealItems',
      label: t('餐點'),
      format: (items) => {
        return <MealItems items={items} />;
      },
    },
    { key: 'noteForMeal', label: `${t('餐點')}備註` },
    { key: 'noteForDelivery', label: `${t('送餐')}備註` },
    { key: 'note', label: '其他備註' },
    { key: 'deliveryPeriod', label: `${t('送餐')}區間` },
    { key: 'startAddress', label: t('取餐地址') },
    { key: 'endAddress', label: `${t('送餐')}地址` },
    { key: 'distance', label: '距離' },
    { key: 'duration', label: '車程' },
  ];
  const { start, end } = getStartEndForDeliveryTime(deliveryBy);

  const data = Object.assign({
    start,
    end,
    deliveryPeriod: formatDeliveryPeriod(start, end),
    mealItems,
    noteForMeal,
    noteForDelivery,
    note,
  }, direction, {
    startAddress,
    endAddress,
  });

  // TODO: Switch mode
  // use static map to reduce google api calls
  return (
    <InfoCard title={`${t('送餐')}資訊`} mappings={mappings} data={data}>
      {
        mode === 'static' ?
          <Grid container justifyContent="center">
            {/* <img src={uri} alt="地圖" width="100%" height="100%"/> */}
            <Directions startAddress={startLocation} endAddress={endLocation} />
          </Grid>:
          <LoadScript
            googleMapsApiKey={GOOGLE_KEY}
            region="tw"
            language="zh-TW"
          >
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '450px' }}
              zoom={13}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {map && <React.Fragment>
                <Polyline
                  path={formatPolyline(overviewPolyline)}
                  options={{
                    strokeColor: '#0079c9',
                  }}
                />

                <Marker
                  position={startLocation}
                  // label={restaurant.name}
                  onClick={() => setInfo({ position: startLocation, content: startAddress })}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
                    scaledSize: new window.google.maps.Size(32, 32),
                  }}
                />
                <Marker
                  position={endLocation}
                  // label={elder.name}
                  onClick={() => setInfo({ position: endLocation, content: endAddress })}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png',
                    scaledSize: new window.google.maps.Size(32, 32),
                  }}
                />
                {info &&
                  <InfoWindow
                    position={info.position}
                    onCloseClick={() => setInfo(null)}
                    options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                  >
                    <div>
                      {info.content}
                    </div>
                  </InfoWindow>}
              </React.Fragment>}
            </GoogleMap>
          </LoadScript>
      }
    </InfoCard>);
}

OrderDetails.propTypes = {
  order: PropTypes.object,
  mode: PropTypes.string,
};
