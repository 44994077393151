import moment from 'moment';
import {
  countiesDistricts,
} from '@silvergatedelivery/constants';
import { formatAge, formatGender } from 'utilities/format';
import { utils, writeFile, write } from 'sheetjs-style';

import { getMonthDates } from './helpers';
import { unifyCellStyle } from './helpers';

const COUNTY = '苗栗縣';
const deliverySubsidy = 125;
const mealCentralGovSubsidyLow = 100;
const mealCentralGovSubsidyMidLow = 90;
const selfPaidMidLow = 10;

export default function writeMiaoli({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  pandagoOrders,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const [year, month] = selectedMonth.split('-');
  const yearMonthTitle = `${parseInt(year)-1911}年${month}月`;

  const workbook = utils.book_new();

  // 移除沒有弱勢身份的訂單
  const ordersForDisvantaged = orders.filter((order) => {
    const { disadvantagedTypesSlot } = elders.find((elder) => elder.id === order.elderId) || {};
    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      const slot = disadvantagedTypesSlot.find(({ start, end }) => order.date >= start && order.date < end);
      if (slot && slot.type === '一般') {
        return false;
      }
    }
    return true;
  });

  const params = {
    elders,
    orders: ordersForDisvantaged,
    pandagoOrders,
    deliveryStaffs,
    yearMonthTitle,
    clientName,
    selectedMonth,
  };

  utils.book_append_sheet(workbook, getWorksheetElders(params), `個案`);
  utils.book_append_sheet(workbook, getWorksheetStatistics(params), `餐費費用總表`);
  utils.book_append_sheet(workbook, getWorksheetApplication(params), `經費需求表`);
  utils.book_append_sheet(workbook, getWorksheetDeliveryFee(params), `志工交通費用印領清冊（送餐大使）`);
  utils.book_append_sheet(workbook, getWorksheetDeliveryFeePandago(params), `志工交通費用印領清冊（Pandago）`);

  if (process.env.JEST_WORKER_ID) {
    return write(workbook, { type: 'buffer', bookType: 'xlsx' });
  }

  writeFile(workbook, `${clientName}__${COUNTY}核銷報表__${selectedMonth}.xlsx`);
}

function getWorksheetElders({ elders = [], orders = [] }) {
  const elderRows = [];
  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      identificationCardId,
      gender,
      birthday,
      disadvantagedTypes,
      disadvantagedTypesSlot,
      linkGroupName,
      address: {
        district,
      },
    }) => {
      const elderOrders = orders
        .filter(({ elderId }) => elderId === id);

      if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
        disadvantagedTypesSlot.forEach(({ start, end, type }) => {
          if (type === '一般') {
            return;
          }
          const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
          if (filteredOrders.length === 0) {
            return;
          }
          const existingRow = elderRows.find(({ 身分證字號, 身份別 }) => 身分證字號 === identificationCardId && 身份別 === type);
          if (existingRow) {
            existingRow.服務量 += filteredOrders.length;
            existingRow.午餐量 += filteredOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length;
            existingRow.晚餐量 += filteredOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length;
          } else {
            elderRows.push({
              姓名: name,
              身分證字號: identificationCardId,
              性別: formatGender(gender),
              年齡: formatAge(birthday),
              身份別: type,
              服務量: filteredOrders.length,
              午餐量: filteredOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length,
              晚餐量: filteredOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length,
              地址: district,
              家庭代碼: linkGroupName,
              // 核增: '',
            });
          }
        });
      } else {
        if (elderOrders.length === 0) {
          return;
        }
        elderRows.push({
          姓名: name,
          身分證字號: identificationCardId,
          性別: formatGender(gender),
          年齡: formatAge(birthday),
          身份別: disadvantagedTypes,
          服務量: elderOrders.length,
          午餐量: elderOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length,
          晚餐量: elderOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length,
          地址: district,
          家庭代碼: linkGroupName,
          // 核增: '',
        });
      }
    });
  const worksheet = utils.json_to_sheet(elderRows);

  worksheet['!cols'] = [
    { wch: 13 }, { wch: 13 },
    { wch: 5 }, { wch: 6 },
    { wch: 10 }, { wch: 8 },
    { wch: 8 }, { wch: 8 },
    { wch: 8 }, { wch: 10 },
  ];

  return worksheet;
}

function getWorksheetStatistics({ elders, orders, yearMonthTitle, clientName, selectedMonth }) {
  const districs = countiesDistricts.filter(({ county }) => county === COUNTY).reduce((all, { districts: items }) => {
    return [...all, ...items];
  }, []);

  const yearTitle = yearMonthTitle.split('年')[0];
  const fromMonth = moment(selectedMonth).startOf('month').format('MM/DD');
  const toMonth = moment(selectedMonth).endOf('month').format('MM/DD');

  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`單位名稱：${clientName}`]], { origin: 'A1' });

  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`補助${yearTitle}年度長期照顧營養餐飲服務 餐費費用總表`]], { origin: 'A2' });

  worksheet['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`送餐期間：${yearTitle}/${fromMonth}-${yearTitle}/${toMonth}`]], { origin: 'A3' });

  const mappingA = [...districs, '金額合計'].reduce((obj, key) => {
    obj[key] = obj[key] || {
      '鄉鎮市': key,
      '餐費金額': 0,
      '案家自付金額': 0, // TODO: what's this
      '補助金額': 0,
    };

    return obj;
  }, {});

  const mappingB = [...districs, '金額合計'].reduce((obj, key) => {
    obj[key] = obj[key] || {
      '送餐鄉鎮別': key,
      '補助低收入戶金額': 0,
      '補助中低收入戶金額': 0,
      '案家自費': 0,
      '小計': 0,
      '補助金額': 0,
      '人數': 0,
      '人次': 0,
    };

    return obj;
  }, {});

  const countedElderIds = [];
  orders.forEach(({ elderId, date }) => {
    const {
      disadvantagedTypes,
      disadvantagedTypesSlot,
      address: { district },
    } = elders.find(({ id }) => id === elderId);

    let type = disadvantagedTypes;
    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      const slot = disadvantagedTypesSlot.find(({ start, end }) => date >= start && date < end);
      type = slot.type;
    }

    const defaultPaidFee = mealCentralGovSubsidyLow;
    const defaultSelfPaidFee = 0;

    let paidFee = defaultPaidFee;
    let selfPaidFee = defaultSelfPaidFee;
    switch (type) {
      case '低收':
        paidFee = mealCentralGovSubsidyLow;
        selfPaidFee = 0;

        mappingB[district]['補助低收入戶金額'] += paidFee;
        mappingB['金額合計']['補助低收入戶金額'] += paidFee;
        break;
      case '中低收':
        paidFee = mealCentralGovSubsidyMidLow;
        selfPaidFee = selfPaidMidLow;

        mappingB[district]['補助中低收入戶金額'] += paidFee;
        mappingB['金額合計']['補助中低收入戶金額'] += paidFee;
        break;
      default:
    }

    mappingA[district]['餐費金額'] += defaultPaidFee;
    mappingA[district]['案家自付金額'] += selfPaidFee;
    mappingA[district]['補助金額'] += paidFee;

    mappingA['金額合計']['餐費金額'] += defaultPaidFee;
    mappingA['金額合計']['案家自付金額'] += selfPaidFee;
    mappingA['金額合計']['補助金額'] += paidFee;


    mappingB[district]['案家自費'] += selfPaidFee;
    mappingB['金額合計']['案家自費'] += selfPaidFee;

    mappingB[district]['小計'] += (paidFee + selfPaidFee);
    mappingB['金額合計']['小計'] += (paidFee + selfPaidFee);

    mappingB[district]['補助金額'] += paidFee;
    mappingB['金額合計']['補助金額'] += paidFee;

    if (!countedElderIds.includes(elderId)) {
      mappingB[district]['人數'] += 1;
      mappingB['金額合計']['人數'] += 1;
      countedElderIds.push(elderId);
    }

    mappingB[district]['人次']++;
    mappingB['金額合計']['人次']++;
  });

  const districtsFeesA = Object.keys(mappingA).map((key) => {
    if (mappingA[key]['補助金額'] === 0) {
      return null;
    } else {
      return mappingA[key];
    }
  }).filter((x) => x);
  const districtsFeesB = Object.keys(mappingB).map((key) => {
    if (mappingB[key]['小計'] === 0) {
      return null;
    } else {
      return mappingB[key];
    }
  }).filter((x) => x);

  utils.sheet_add_json(worksheet, districtsFeesA, { origin: 'A5', skipHeader: false });

  const offset = districtsFeesA.length + 8;
  utils.sheet_add_json(worksheet, districtsFeesB, { origin: `A${offset}`, skipHeader: false });

  const offset2 = offset + districtsFeesB.length + 2;
  utils.sheet_add_aoa(worksheet, [[`承辦人簽章：`]], { origin: `A${offset2}` });
  utils.sheet_add_aoa(worksheet, [[`主管簽章：`]], { origin: `F${offset2}` });

  return worksheet;
}

function getWorksheetApplication({ elders, orders, pandagoOrders, deliveryStaffs, yearMonthTitle, clientName, selectedMonth }) {
  const [yearTitle, monthTitle] = yearMonthTitle.split('年');
  const fromMonth = moment(selectedMonth).startOf('month').format('MM/DD');
  const toMonth = moment(selectedMonth).endOf('month').format('MM/DD');

  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  let totalPaid = 0;

  const disadvantagedTypeList = ['低收', '中低收'];
  const mappings = {
    低收: {
      單位: '人次',
      數量: 0,
      單價: mealCentralGovSubsidyLow,
      金額: 0,
      說明: '低收入戶',
    },
    中低收: {
      單位: '人次',
      數量: 0,
      單價: mealCentralGovSubsidyMidLow,
      金額: 0,
      說明: '中低收入戶',
    },
    大使: {
      單位: '趟',
      數量: 0,
      單價: deliverySubsidy,
      金額: 0,
      說明: '大使',
      dates: [],
    },
    熊貓: {
      單位: '趟',
      數量: 0,
      單價: deliverySubsidy,
      金額: 0,
      說明: '熊貓',
      dates: [],
    },
  };

  orders.forEach(({ id: orderId, elderId, deliveryStaffId, date, mealSlot }) => {
    const {
      disadvantagedTypes,
      disadvantagedTypesSlot,
    } = elders.find(({ id }) => id === elderId);
    const { name: deliveryStaffName } = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
    let type = disadvantagedTypes;
    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      const slot = disadvantagedTypesSlot.find(({ start, end }) => date >= start && date < end);
      type = slot.type;
    }

    if (disadvantagedTypeList.includes(type)) {
      mappings[type]['數量']++;
      mappings[type]['金額'] += mappings[type]['單價'];

      totalPaid += mappings[type]['單價'];
    }

    const pandagoOrder = pandagoOrders.find((x) => x.orderId === orderId);
    const padnagoDeliveryStaffName = pandagoOrder ? `${pandagoOrder.driver.name}(pandago)` : undefined;

    const dateKey = `${date}__${deliveryStaffId}__${mealSlot}__${padnagoDeliveryStaffName || deliveryStaffName}`;
    if (pandagoOrder) {
      if (!mappings['熊貓'].dates.includes(dateKey)) {
        mappings['熊貓']['數量']++;
        mappings['熊貓']['金額'] += mappings['熊貓']['單價'];
        totalPaid += mappings['熊貓']['單價'];
        mappings['熊貓'].dates.push(dateKey);
      }
    } else if (!mappings['大使'].dates.includes(dateKey)) {
      mappings['大使']['數量']++;
      mappings['大使']['金額'] += mappings['大使']['單價'];
      totalPaid += mappings['大使']['單價'];
      mappings['大使'].dates.push(dateKey);
    }
  });

  const data = Object.keys(mappings).map((key) => {
    delete mappings[key].dates;
    return mappings[key];
  });

  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`年度期間：${yearTitle}/${fromMonth}-${yearTitle}/${toMonth}`]], { origin: 'A1' });

  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`單位：${clientName}`]], { origin: 'A2' });

  worksheet['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`計畫名稱：${yearTitle}年度苗栗縣政府特約長期照顧-營養餐飲`]], { origin: 'A3' });

  worksheet['!merges'].push({ s: { r: 6, c: 0 }, e: { r: 6, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`經費需求表`]], { origin: 'A7' });

  utils.sheet_add_aoa(worksheet, [['項次', '項目', '月份']], { origin: 'A9' });

  worksheet['!merges'].push({ s: { r: 9, c: 0 }, e: { r: 10, c: 0 } });
  worksheet['!merges'].push({ s: { r: 9, c: 1 }, e: { r: 10, c: 1 } });
  worksheet['!merges'].push({ s: { r: 9, c: 2 }, e: { r: 10, c: 2 } });
  utils.sheet_add_aoa(worksheet, [['1', '業務費', monthTitle]], { origin: 'A10' });

  worksheet['!merges'].push({ s: { r: 11, c: 0 }, e: { r: 12, c: 0 } });
  worksheet['!merges'].push({ s: { r: 11, c: 1 }, e: { r: 12, c: 1 } });
  worksheet['!merges'].push({ s: { r: 11, c: 2 }, e: { r: 12, c: 2 } });
  utils.sheet_add_aoa(worksheet, [['2', '志工交通費', monthTitle]], { origin: 'A12' });

  worksheet['!merges'].push({ s: { r: 13, c: 0 }, e: { r: 13, c: 4 } });
  utils.sheet_add_aoa(worksheet, [[`合計`]], { origin: 'A14' });
  utils.sheet_add_aoa(worksheet, [['申請補助金額', totalPaid]], { origin: 'F14' });

  utils.sheet_add_json(worksheet, data, { origin: 'D9', skipHeader: false });

  worksheet['!merges'].push({ s: { r: 16, c: 0 }, e: { r: 16, c: 2 } });
  utils.sheet_add_aoa(worksheet, [[`製表人：`]], { origin: `A17` });

  return worksheet;
}

function getWorksheetDeliveryFee({ elders, orders, deliveryStaffs, yearMonthTitle, clientName, selectedMonth }) {
  const mappings = {};

  let totalCount = 0;

  orders.forEach(({ id: orderId, elderId, mealSlot, deliveryStaffId, date: fullDate }) => {
    const { name, address: { district } } = elders.find(({ id }) => id === elderId);
    // exclude pandago
    const deliveryStaff = deliveryStaffs.find(({ id, username }) => id === deliveryStaffId && username !== 'pandago');

    if (!deliveryStaff) {
      return;
    }

    const elderName = name.replace(/ /g, '').split('(')[0];

    // const key = `${district}__${mealSlot}__${deliveryStaffId}`;
    const key = `${deliveryStaff.name}__${deliveryStaffId}__${mealSlot}`;
    mappings[key] = mappings[key] || {
      '鄉鎮': [],
      '編號': '', // fill later
      '志工姓名': deliveryStaff.name,
      '午餐/晚餐': mealSlot === 'lunch' ? '午餐' : '晚餐',
      '單價(元)': deliverySubsidy,
      '送餐天數(天)': 0,
      '合計(元)': 0,
      '服務個案姓名': [],
      '送餐日期': {},
      'dateMappings': getMonthDates(selectedMonth).reduce((obj, item) => {
        obj[item.date] = {
          label: item.label,
          elders: [],
        };
        return obj;
      }, {}),
    };

    if (!mappings[key]['鄉鎮'].includes(district)) {
      mappings[key]['鄉鎮'].push(district);
    }

    if (!mappings[key]['服務個案姓名'].includes(elderName)) {
      mappings[key]['服務個案姓名'].push(elderName);
    }

    const date = fullDate.split('-').pop();

    if (!mappings[key]['送餐日期'][date]) {
      mappings[key]['送餐天數(天)']++;
      mappings[key]['合計(元)'] += deliverySubsidy;
      mappings[key]['送餐日期'][date] = 0;
      totalCount++;
    }

    mappings[key]['送餐日期'][date] = 1;

    if (!mappings[key].dateMappings[fullDate].elders.includes(elderName)) {
      mappings[key].dateMappings[fullDate].elders.push(elderName);
    }
  });

  const data = Object.keys(mappings)
    .sort((a, b) => a < b ? 1 : -1)
    .map((key, index) => {
      const item = mappings[key];

      const data = Object.assign(item, {
        鄉鎮: item['鄉鎮'].join('、\n'),
        編號: index + 1,
        服務個案姓名: item['服務個案姓名'].reduce((groups, item) => {
          const lastGroup = groups[groups.length - 1];
          if (lastGroup && lastGroup.length < 3) {
            lastGroup.push(item);
          } else {
            groups.push([item]);
          }
          return groups;
        }, []).map((group) => `${group.join('、')}`).join('\n'),
        送餐日期: Object.keys(item['送餐日期'])
          .sort((a, b) => a > b ? 1 : -1)
          .reduce((array, dateKey) => {
            const previousItem = array[array.length - 1];

            if (previousItem && previousItem.dateKey) {
              const [from, to] = previousItem.dateKey.split('-');
              const lastDate = to || from;
              if (parseInt(lastDate) + 1 === parseInt(dateKey)) {
                previousItem.dateKey = `${from}-${dateKey}`;
                previousItem.value++;
                return array;
              }
            }

            array.push({
              dateKey,
              value: item['送餐日期'][dateKey],
            });

            return array;
          }, [])
          .map(({ dateKey, value }) => `${dateKey}(${value})`)
          .join('、\n'),
        ...Object.keys(item.dateMappings).reduce((obj, date) => {
          const { label, elders } = item.dateMappings[date];
          obj[label] = elders.join('、\n');
          return obj;
        }, {}),
      });

      delete data.dateMappings;

      return data;
    });

  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
  ];
  utils.sheet_add_aoa(worksheet, [[`補助長期照顧營養餐飲服務 ${yearMonthTitle} 志工交通費清冊`]], { origin: 'A1' });
  utils.sheet_add_aoa(worksheet, [[`服務單位: ${clientName}`]], { origin: 'A2' });
  const dateLabels = getMonthDates(selectedMonth).map(({ label }) => label);
  utils.sheet_add_aoa(worksheet, [[
    '鄉鎮', '編號', '志工姓名', '午餐/晚餐', '單價(元)', '送餐天數(天)', '合計(元)', '服務個案姓名', '送餐日期', ...dateLabels,
  ]], { origin: 'A3' });
  utils.sheet_add_json(worksheet, data, { origin: 'A4', skipHeader: true });

  const offset = 3 + data.length;
  worksheet['!merges'].push({ s: { r: offset, c: 0 }, e: { r: offset, c: 4 } });
  utils.sheet_add_aoa(worksheet, [[` 合計`]], { origin: `A${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount]], { origin: `F${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount * deliverySubsidy]], { origin: `G${offset+1}` });

  utils.sheet_add_aoa(worksheet, [[`承辦人簽章：`]], { origin: `A${offset+3}` });
  utils.sheet_add_aoa(worksheet, [[`主管簽章：`]], { origin: `F${offset+3}` });

  unifyCellStyle(worksheet, offset + 5);

  worksheet['!cols'] = [
    { wch: 13 },
    { wch: 5 },
    { wch: 20 },
    { wch: 13 },
    { wch: 8 },
    { wch: 13 },
    { wch: 10 },
    { wch: 45 },
    { wch: 40 },
    ...dateLabels.map(() => ({ wch: 10 })),
  ];

  return worksheet;
}

function getWorksheetDeliveryFeePandago({ elders, orders, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName, selectedMonth }) {
  const [yearTitle] = yearMonthTitle.split('年');

  const mappings = {};
  let totalCount = 0;

  orders.forEach(({ id: orderId, elderId, mealSlot, deliveryStaffId, date: fullDate }) => {
    const { name: elderNameOriginal, address: { district } } = elders.find(({ id }) => id === elderId);
    const deliveryStaff = deliveryStaffs.find(({ id, username }) => id === deliveryStaffId && username === 'pandago');

    // only include pandago orders
    if (!deliveryStaff) return;
    const { driver: { name: deliveryStaffNameOriginal, phoneNumber } } = pandagoOrders.find((x) => x.orderId === orderId);

    const elderName = elderNameOriginal.replace(/ /g, '').split('(')[0];
    const deliveryStaffName = `${deliveryStaffNameOriginal} ${phoneNumber}`;
    const key = `${mealSlot}__${deliveryStaffId}__${deliveryStaffName}`;
    mappings[key] = mappings[key] || {
      '鄉鎮': [],
      '編號': '', // fill later
      '志工姓名': deliveryStaffName,
      '午餐/晚餐': mealSlot === 'lunch' ? '午餐' : '晚餐',
      '單價(元)': deliverySubsidy,
      '送餐天數(天)': 0,
      '合計(元)': 0,
      '服務個案姓名': [],
      '送餐日期': [],
      'dateMappings': getMonthDates(selectedMonth).reduce((obj, item) => {
        obj[item.date] = {
          label: item.label,
          elders: [],
        };
        return obj;
      }, {}),
    };

    if (!mappings[key]['鄉鎮'].includes(district)) {
      mappings[key]['鄉鎮'].push(district);
    }

    if (!mappings[key]['服務個案姓名'].includes(elderName)) {
      mappings[key]['服務個案姓名'].push(elderName);
    }

    const date = moment(fullDate).format('MM/DD');

    if (!mappings[key]['送餐日期'].includes(date)) {
      mappings[key]['送餐天數(天)']++;
      mappings[key]['合計(元)'] += deliverySubsidy;
      mappings[key]['送餐日期'].push(date);
      totalCount++;
    }

    mappings[key]['送餐日期'][date] = 1;

    if (!mappings[key].dateMappings[fullDate].elders.includes(elderName)) {
      mappings[key].dateMappings[fullDate].elders.push(elderName);
    }
  });
  const data = Object.keys(mappings)
    .map((key, index) => {
      const item = mappings[key];

      const data = Object.assign(item, {
        鄉鎮: item['鄉鎮'].join('、\n'),
        服務個案姓名: item['服務個案姓名'].join('、\n'),
        送餐日期: item['送餐日期'].join('、\n'),
        ...Object.keys(item.dateMappings).reduce((obj, date) => {
          const { label, elders } = item.dateMappings[date];
          obj[label] = elders.join('、\n');
          return obj;
        }, {}),
      });

      delete data.dateMappings;

      return data;
    });
  data.sort((a, b) => a['鄉鎮'] < b['鄉鎮'] ? 1 : -1);
  data.forEach((item, index) => {
    item['編號'] = index + 1;
  });
  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
  ];

  utils.sheet_add_aoa(worksheet, [[`補助${yearTitle}年度苗栗縣老人送餐服務計畫 ${yearMonthTitle} 志工交通費清冊`]], { origin: 'A1' });
  utils.sheet_add_aoa(worksheet, [[`服務單位: ${clientName}`]], { origin: 'A2' });
  utils.sheet_add_aoa(worksheet, [[
    '鄉鎮', '編號', '志工姓名', '午餐/晚餐', '單價(元)', '送餐天數(天)', '合計(元)', '服務個案姓名', '送餐日期', ...getMonthDates(selectedMonth).map(({ label }) => label),
  ]], { origin: 'A3' });
  utils.sheet_add_json(worksheet, data, { origin: 'A4', skipHeader: true });

  const offset = 3 + data.length;
  worksheet['!merges'].push({ s: { r: offset, c: 0 }, e: { r: offset, c: 4 } });
  utils.sheet_add_aoa(worksheet, [[`合計`]], { origin: `A${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount]], { origin: `F${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount * deliverySubsidy]], { origin: `G${offset+1}` });

  utils.sheet_add_aoa(worksheet, [[`承辦人簽章：`]], { origin: `A${offset+3}` });
  utils.sheet_add_aoa(worksheet, [[`主管簽章：`]], { origin: `F${offset+3}` });

  unifyCellStyle(worksheet, offset + 5);

  worksheet['!cols'] = [
    { wch: 13 },
    { wch: 5 },
    { wch: 20 },
    { wch: 10 },
    { wch: 8 },
    { wch: 13 },
    { wch: 10 },
    { wch: 45 },
    { wch: 40 },
  ];

  return worksheet;
}
