import moment from 'moment-timezone';

import {
  getIssuesByCountyByCreatedAt,
  getDeliveryStaffsByCountyByCreatedAt,
  listClients,
  getClientsByCountyByCreatedAt,
  getEldersByCountyByCreatedAt,
} from 'graphql/queries';
import {
  getOrdersByCountyByDate,
} from './queries';
import { asyncListAll, asyncListAllAndUpdateLastItem } from 'utilities/graph';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { counties } from '@silvergatedelivery/constants';

export function getStartEndByPeriodName(inPeriod) {
  const [period, startBaseline, endBaseline] = inPeriod.split('/');
  const startBaseNumber = parseInt(startBaseline || '0');
  const endBaseNumber = parseInt(endBaseline || '0');
  const start = moment().add(startBaseNumber, `${period}s`).tz(TIME_ZONE).startOf(period);
  const end = moment().add(endBaseNumber, `${period}s`).tz(TIME_ZONE).endOf(period);
  return {
    start: start.toISOString(),
    end: end.toISOString(),
    prevStart: moment().tz(TIME_ZONE).add(-1 + startBaseNumber, `${period}s`).startOf(period).toISOString(),
    prevEnd: moment().tz(TIME_ZONE).add(-1 + endBaseNumber, `${period}s`).endOf(period).toISOString(),
    startFormat: start.format('YYYY-MM-DD'),
    endFormat: end.format('YYYY-MM-DD'),
    prevStartFormat: moment().tz(TIME_ZONE).add(-1 + startBaseNumber, `${period}s`).startOf(period).format('YYYY-MM-DD'),
    prevEndFormat: moment().tz(TIME_ZONE).add(-1 + endBaseNumber, `${period}s`).endOf(period).format('YYYY-MM-DD'),
  };
}

export const getOrders = async (location, start, end, onUpdateLastItem) => {
  if (location) {
    return await asyncListAllAndUpdateLastItem(getOrdersByCountyByDate, {
      county: location,
      date: {
        between: [start, end],
      },
      limit: 1000,
    }, onUpdateLastItem);
  } else {
    const result = await Promise.all(counties.map((county) => {
      return getOrders(county, start, end, onUpdateLastItem);
    }));
    return result.flat();
  }
};

export const getNewOrders = async (location, start, end) => {
  // TODO: scan table太慢，等order.graphql可以新增getOrdersByCountyByCreated時再新增
  return [];
  // if (location) {
  //   return asyncListAll(getOrdersByCountyByDate, {
  //     county: location,
  //     filter: {
  //       createdAt: {
  //         between: [start, end],
  //       },
  //     },
  //   });
  // } else {
  //   return asyncListAll(listOrders, {
  //     filter: {
  //       createdAt: {
  //         between: [start, end],
  //       },
  //     },
  //   });
  // }
};

export const getNewClients = async (location, start, end) => {
  // TODO: useless now
  if (location) {
    return asyncListAll(getClientsByCountyByCreatedAt, {
      county: location,
      createdAt: {
        between: [start, end],
      },
    });
  } else {
    return asyncListAll(listClients, {
      filter: {
        createdAt: {
          between: [start, end],
        },
      },
    });
  }
};

export const getNewElders = async (location, start, end) => {
  if (location) {
    return asyncListAll(getEldersByCountyByCreatedAt, {
      county: location,
      createdAt: {
        between: [start, end],
      },
    });
  } else {
    const result = await Promise.all(counties.map((county) => {
      return getNewElders(county, start, end);
    }));
    return result.flat();
  }
};

export const getNewDeliveryStaffs = async (location, start, end) => {
  if (location) {
    return asyncListAll(getDeliveryStaffsByCountyByCreatedAt, {
      county: location,
      createdAt: {
        between: [start, end],
      },
    });
  } else {
    const result = await Promise.all(counties.map((county) => {
      return getNewDeliveryStaffs(county, start, end);
    }));
    return result.flat();
  }
};

export const getIssues = async (location, start, end) => {
  if (location) {
    return asyncListAll(getIssuesByCountyByCreatedAt, {
      county: location,
      createdAt: {
        between: [start, end],
      },
    });
  } else {
    const result = await Promise.all(counties.map((county) => {
      return getIssues(county, start, end);
    }));
    return result.flat();
  }
};
