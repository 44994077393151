import React from 'react';
import i18n from 'i18next';
import EditButton from '../components/EditButton';
import RestaurantForm from './RestaurantForm';

export default function RestaurantEditButton({ ...props }) {
  return (
    <EditButton
      title={i18n.t('餐廳')}
      form={RestaurantForm}
      {...props}
    />);
}
