import React from 'react';
import Container from '@material-ui/core/Container';

// import MealDateSelector from 'components/Date/MealDateSelector';
// import OrderStatusProgress from 'components/Order/OrderStatusProgress';
// import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
// import RestaurantEditButton from 'forms/RestaurantForm/RestaurantEditButton';
// import RestaurantMealDialog from 'components/RestaurantMealDialog';
// import ElderEditButton from 'forms/ElderForm/ElderEditButton';
// import Card from 'components/Payment/Card';
// import LinePayButton from 'components/Payment/LinePayButton';
import UserFormEditButton from 'forms/UserForm/UserFormEditButton';

export default function Tests() {
  return (
    <Container>
      {/* <MealDateSelector
        mealSlot="lunch"
        onChange={global.logger.debug} />
      <MealDateSelector
        mealSlot="dinner"
        onChange={global.logger.debug} />
      <OrderStatusProgress /> */}
      <UserFormEditButton open={true} />
      {/* <AdminOrderEditButton mode="create" open={true} /> */}
      {/* <RestaurantMealDialog
        mode={'add'}
        restaurantId={'abc'}
        onUpdate={() => {}}
        open={true}
      /> */}

      {/* <ElderEditButton open={true}/> */}
      {/* <Card /> */}

      {/* <LinePayButton order={{
        subTotal: 90,
        // discount,
        // discountRule,
        amount: 90,
        description: 'local test',
        category: '送餐',
        items: [{
          name: '測試餐點',
          qty: 3,
          unit: '份',
          unitValue: 30,
          subTotal: 90,
        }],
      }}/> */}
    </Container>);
}
