const schema = {
  'title': '基本資料',
  'type': 'object',
  'required': [
    'isActive', 'category', 'type', 'question',
  ],
  'properties': {
    'isActive': {
      'type': 'number',
      'title': '狀態',
      'enum': [0, 1],
      'enumNames': ['已停用', '使用中'],
      'default': 1,
    },
    'category': {
      'type': 'string',
      'title': '題目類別',
      'enum': ['時間感', '方向感', '生活認知', '記憶'],
      'enumNames': ['時間感', '方向感', '生活認知', '記憶'],
    },
    'type': {
      'type': 'string',
      'title': '答題類型',
      'enum': ['是非題', '開放式問題', '互動題'],
      'enumNames': ['是非題', '開放式問題', '互動題'],
    },
    'question': {
      'type': 'string',
      'title': '題目',
    },
  },
};

export default schema;
