import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FacilitySettingsForm from 'forms/FacilitySettingsForm/FacilitySettingsForm';
import { request } from 'utilities/graph';
import { getClient } from 'graphql/queries';
import cache from 'utilities/cache';
import Loading from 'components/Loading';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function FacilitySettings() {
  const classes = useStyles();
  const [client, setClient] = useState();
  const facilityId = cache.get('app:facilityId');

  useEffect(() => {
    (async () => {
      if (facilityId) {
        const { data: { getClient: data } } = await request(getClient, { id: facilityId });
        setClient(data);
      }
    })();
  }, []);

  if (!client) {
    return (<Loading fullScreen={false} />);
  }


  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <Paper className={classes.container}>
          <FacilitySettingsForm
            formData={client}
            onComplete={(data) => {
              toastr.info('帳號設定已更新');
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
