import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { iLinkOrderStatus } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    flex: 1,
  },
  actionContainer: {
    padding: theme.spacing(2),
  },
  chip: {
    height: 22,
    margin: 2,
    fontWeight: 'bold',
  },
}));

export default function PandagoOrderStatusChip({ status }) {
  const classes = useStyles();

  const matchedOrderStatus = iLinkOrderStatus.find((item) => item.value === status);
  const label = matchedOrderStatus ? matchedOrderStatus.label : status;
  const backgroundColor = matchedOrderStatus ? matchedOrderStatus.color : undefined;
  const color = backgroundColor ? '#fff' : undefined;

  return (<Chip
    label={label}
    className={classes.chip}
    style={{ color, backgroundColor }}
  />);
}

PandagoOrderStatusChip.propTypes = {
  status: PropTypes.string,
};
