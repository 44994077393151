import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import shortuuid from 'short-uuid';
import {
  TIME_ZONE,
  orderStatus,
  cancellationReasons,
} from '@silvergatedelivery/constants';
import Toolbar from '@material-ui/core/Toolbar';
import DataTable from 'components/Table/DataTable';
import OrderDialog from 'components/Order/OrderDialog';
import {
  getOrdersByClientByDate,
  getOrdersByClientByStatus,
} from 'graphql/queries';
import OrderStatusChip from 'components/OrderStatusChip';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Order from 'components/Order/Order';
import LinkButton from 'components/Table/LinkButton';
import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
import cache from 'utilities/cache';
import UpdateOrderToCompletedButton from 'components/Order/UpdateOrderToCompletedButton';
import UpdateOrderToCancelledButton from 'components/Order/UpdateOrderToCancelledButton';
import UpdateOrderDeliveryStaffButton from 'components/Order/UpdateOrderDeliveryStaffButton';
import UpdateOrderRestaurantButton from 'components/Order/UpdateOrderRestaurantButton';
import UpdateOrderTimeButton from 'components/Order/UpdateOrderTimeButton';
import UpdateOrderLinkGroupEldersButton from 'components/Order/UpdateOrderLinkGroupEldersButton';
import ResetCancelledOrderButton from 'components/Order/ResetCancelledOrderButton';
import { getTranslatedOrderStatus, getTranslatedMealSlots } from 'utilities/translate';
import { formatAddress, formatAddressLatLng } from 'utilities/format';
import deliveryStaffFeeCalculationMethod from '@silvergatedelivery/constants/src/deliveryStaffFeeCalculationMethod';
import { getDeliveryStaffIdSchema } from 'forms/schemas';

// const lastMonth = moment().tz(TIME_ZONE).add(-30, 'days').format('YYYY-MM-DD');
const lastWeek = moment().tz(TIME_ZONE).add(-7, 'days').format('YYYY-MM-DD');
// const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');
const nextWeek = moment().tz(TIME_ZONE).add(7, 'days').format('YYYY-MM-DD');

export default function OrderTable({
  title = '訂單',
  description,
  queryStatus,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState();
  const ordersSelected = useRef([]);

  const clientName = cache.get('app:name');
  const clientId = cache.get('app:facilityId');

  const customToolbarSelect = ({ onUpdate }) =>
    <Toolbar>
      <UpdateOrderToCompletedButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderToCancelledButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <ResetCancelledOrderButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderDeliveryStaffButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderRestaurantButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderTimeButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderLinkGroupEldersButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
    </Toolbar>;

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <Order id={id} />
        </NestedTableContainer>
      );
    },
    selectableRows: 'multiple',
    isRowSelectable: () => true,
  };
  const translatedMealSlots = getTranslatedMealSlots();
  const columns = [
    {
      name: 'id',
      label: '編號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: t('取餐編號'),
      options: {
        filter: false,
        sort: true,
        customBodyRender(value) {
          return shortuuid().fromUUID(value).slice(-6);
        },
      },
    },
    {
      name: 'referenceId',
      label: '整合編號',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(value) {
          return (value || '').split('-')[1] || '';
        },
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        // filterList: usePreFilter ? [cache.get('app:location')].filter((x) => x) : [],
        filter: true,
        sort: true,
      },
    },
    {
      name: 'tier',
      label: '目前層級',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'elderId',
      label: `${t('送餐對象')}編號`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elder.name',
      label: t('送餐對象'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elder.address',
      label: '送餐地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(value) {
          return formatAddress(value);
        },
        sortCompare(order) {
          return (obj1, obj2) => {
            const addr1 = formatAddress(obj1.data);
            const addr2 = formatAddress(obj2.data);
            if (addr1 === addr2) {
              return (obj1.rowData[0] + obj1.rowData[1] > obj2.rowData[0] + obj2.rowData[1] ? 1 : -1) * (order === 'asc' ? 1 : -1);
            }
            return (addr1 > addr2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: 'elder.address',
      label: '送餐地址經緯度',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddressLatLng,
      },
    },
    {
      name: 'restaurant.name',
      label: t('餐廳'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffId',
      label: t('送餐大使'),
      options: {
        filter: false,
        sort: true,
        customBodyRender(deliveryStaffId) {
          if (deliveryStaffOptions) {
            const index = deliveryStaffOptions.enum.findIndex((id) => id === deliveryStaffId);
            if (index !== -1) {
              return deliveryStaffOptions.enumNames[index];
            }
          }
          return '';
        },
      },
    },
    {
      name: 'date',
      label: '日期',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'deliveryBy',
      label: '時間',
      type: 'time',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mealSlot',
      label: '時段',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return translatedMealSlots.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'status',
      label: '狀態',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <OrderStatusChip status={value} />
          );
        },
        filterOptions: {
          renderValue: (v) => (orderStatus.find(({ value }) => value === v) || {}).label,
        },
        customFilterListOptions: {
          render: (v) => (orderStatus.find(({ value }) => value === v) || {}).label,
        },
      },
    },
    {
      name: 'cancellationReason',
      label: '訂單取消原因',
      options: {
        display: false,
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const reason = cancellationReasons.find((reason) => reason.value === value);
          if (reason) {
            return reason.label;
          }
          return '';
        },
      },
    },
    {
      name: 'cancellationNote',
      label: '訂單取消備註',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'direction.distance',
      label: '距離',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'noteForDelivery',
      label: `${t('送餐')}備註`,
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'noteForMeal',
      label: `${t('餐點')}備註`,
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'note',
      label: '備註',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'total',
      label: `${t('餐點')}總金額`,
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffFee',
      label: t('送餐費用'),
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffFeeCalculationMethod',
      label: '送餐費用計算方法',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (method) => {
          return (deliveryStaffFeeCalculationMethod.find(({ value }) => value === method) || {}).label;
        },
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <OrderDialog id={id} />
          );
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/order/${id}`}
              label="前往訂單頁面"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    setIndexes([
      {
        name: 'getOrdersByClientByDate',
        label: `按${t('送餐')}時間查詢`,
        partitionKey: 'clientId',
        sortKey: 'date',
        fields: [{
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: clientId, label: clientName }],
        }, {
          label: `${t('送餐')}時間`,
          key: 'date',
          type: 'date',
        }],
        operation: getOrdersByClientByDate,
      },
      {
        name: 'getOrdersByClientByStatus',
        label: '按狀態查詢',
        partitionKey: 'clientId',
        sortKey: 'status',
        fields: [{
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: clientId, label: clientName }],
        }, {
          label: '狀態',
          key: 'status',
          type: 'select',
          options: [{ value: undefined, label: '全部' }, ...getTranslatedOrderStatus()],
        }],
        operation: getOrdersByClientByStatus,
      },
    ]);
    setQueryParams({
      date: {
        from: lastWeek,
        to: nextWeek,
      },
      clientId,
      status: queryStatus,
    });
  }, []);

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:facilityId');
      const deliveryStaffOptions = await getDeliveryStaffIdSchema(null, null, clientId, true, true);
      setDeliveryStaffOptions(deliveryStaffOptions);
    })();
  }, []);

  const onDataSelectionChange = (dataSelected) => {
    ordersSelected.current = dataSelected;
  };

  if (!indexes || !queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        options={options}
        // queryFunc={queryFunc}
        editButton={AdminOrderEditButton}
        selectableRows='multiple'
        onDataSelectionChange={onDataSelectionChange}
        customToolbarSelect={customToolbarSelect}
        {...props}
      />
    </React.Fragment>);
}

OrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
  elderId: PropTypes.string,
  deliveryStaffId: PropTypes.string,
  restaurantId: PropTypes.string,
  defaultIndex: PropTypes.number,
  queryStatus: PropTypes.string,
};
