import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import ReactJson from 'react-json-view';

import {
  pandagoAddOutlets,
} from 'graphql/mutations';
import { request } from 'utilities/graph';
import ConfigurationTable from './ConfigurationTable';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function System() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({});

  const triggerPandagoAddOutlets = async () => {
    try {
      setMessage({});
      setIsLoading(true);
      const result = await request(pandagoAddOutlets, { input: {} });
      global.logger.debug(result);
      setMessage(result);
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.container}>
            <h3>Pandago 餐廳同步</h3>
            <p>未來如果銀色大門有新增門市都要填到這張表上，通知我們開通，總公司帳號才看得到各門市的定單喔</p>
            <p><a href="https://docs.google.com/spreadsheets/d/1XFbRhbW5dfwqYXg7wwIGtMjClUbMgKpRZ95pZCKRndI/edit#gid=1995729169">
              https://docs.google.com/spreadsheets/d/1XFbRhbW5dfwqYXg7wwIGtMjClUbMgKpRZ95pZCKRndI/edit#gid=1995729169
            </a></p>
            <Button
              variant="outlined"
              onClick={triggerPandagoAddOutlets}
              disabled={isLoading}
            >
              點此將餐廳新增至Pandogo
              {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
            </Button>
            <p></p>
            <ReactJson
              src={message}
              name={null}
              displayDataTypes={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ConfigurationTable />
        </Grid>
      </Grid>
    </Container>
  );
}
