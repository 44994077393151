import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { createDeliveryStaffTransaction, updateDeliveryStaffTransaction } from 'graphql/mutations';
import schema from './DeliveryStaffTransactionForm.schema.js';
import uiSchema from './DeliveryStaffTransactionUpdateForm.uiSchema';

export default function DeliveryStaffTransactionForm({ ...props }) {
  const createFunc = async (data) => {
    await request(createDeliveryStaffTransaction, { input: data });
  };

  const updateFunc = async (data) => {
    const toUpdateData = {
      id: data.id,
      status: data.status,
      type: data.type,
      amount: data.amount,
      summary: data.summary,
      note: data.note,
    };
    await request(updateDeliveryStaffTransaction, { input: toUpdateData });
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

DeliveryStaffTransactionForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
