import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '1px solid rgba(255,255,255,0.3)',
    cursor: 'pointer',
    // width: '100%',
    // height: '100%',
  },
}));

export default function ImageAvatar({ s3KeyPrefix, alt = '檔案' }) {
  const classes = useStyles();

  const [uri, setUri] = useState();
  const [imageUri, setImageUri] = useState();
  const [s3Key, setS3Key] = useState();

  const handleClick = async () => {
    const uri = imageUri || await Storage.get(`${s3KeyPrefix}/image.jpeg`);
    setImageUri(uri);
    window.open(uri, '_blank');
  };

  useEffect(() => {
    (async () => {
      setUri(await Storage.get(s3Key));
    })();
  }, [s3Key]);

  useEffect(() => {
    if (s3KeyPrefix) {
      setS3Key(`${s3KeyPrefix}/avatar.jpeg`);
    }
  }, [s3KeyPrefix]);

  return (
    <Avatar
      alt={alt}
      src={uri}
      variant="square"
      className={classes.avatar}
      onClick={handleClick}
    />
  );
}

ImageAvatar.propTypes = {
  s3KeyPrefix: PropTypes.string,
  alt: PropTypes.string,
};
