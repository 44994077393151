import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import {
  listSponsorshipPrograms,
  getSponsorshipProgramsByIsActiveByCreatedAt,
} from 'graphql/queries';
import SponsorshipProgramEditButton from 'forms/SponsorshipProgramForm/SponsorshipProgramEditButton';
import { sortBy } from 'utilities/sorting';
import ImagePreviewAvatar from 'components/ImagePreviewAvatar';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'isActive',
    label: '狀態',
    type: 'checkbox',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'imageS3Keys',
    label: '圖片',
    options: {
      filter: false,
      sort: false,
      customBodyRender(imageS3Keys) {
        return (
          <ImagePreviewAvatar
            imageS3Keys={imageS3Keys}
          />
        );
      },
    },
  },
  {
    name: 'name',
    label: '名稱',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'summary',
    label: '簡述',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'description',
    label: '詳細描述',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'count',
    label: '數量',
    type: 'number',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unit',
    label: '單位',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unitValue',
    label: '單位價格',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'totalValue',
    label: '總金額',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'sponsors',
    label: '贊助廠商',
    options: {
      filter: false,
      sort: false,
      customBodyRender(sponsors) {
        return (<React.Fragment>
          {(sponsors || []).map(({ name, logoUrl }, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <img src={logoUrl} style={{ height: 24, width: 24, borderRadius: 12 }}/>
              <span>{name}</span>
            </div>
          ))}
        </React.Fragment>);
      },
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function SponsorshipProgramTable({
  title = '捐贈項目',
  description,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  useEffect(() => {
    setIndexes([
      {
        name: 'getSponsorshipProgramsByIsActiveByCreatedAt',
        label: '按狀態查詢',
        partitionKey: 'isActive',
        sortKey: 'createdAt',
        fields: [{
          label: '狀態',
          key: 'isActive',
          type: 'select',
          options: [
            { value: 1, label: '使用中' },
            { value: 0, label: '停用中' },
          ],
        }],
        operation: getSponsorshipProgramsByIsActiveByCreatedAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listSponsorshipPrograms,
      },
    ]);
    setQueryParams({
      isActive: 1,
    });
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={SponsorshipProgramEditButton}
      dataSortFunc={sortBy('name')}
    />
  );
}

SponsorshipProgramTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
