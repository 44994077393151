import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  counties, countiesDistricts,
} from '@silvergatedelivery/constants';
import { getDistrictsInCounty, getZipCode } from 'utilities/address';

export default function AddressInput({ data, onChange }) {
  const [county, setCounty] = useState(data?.county || counties[0]);
  const [district, setDistrict] = useState(data?.district || countiesDistricts[0].districts[0]);
  const [districts, setDistricts] = useState(getDistrictsInCounty(data?.county || counties[0]));
  const [street, setStreet] = useState(data?.street || '');
  const [note, setNote] = useState(data?.note || '');

  const onCountyChange = (newCounty) => {
    if (newCounty !== county) {
      setCounty(newCounty);
      const newDistricts = getDistrictsInCounty(newCounty);
      setDistricts(newDistricts);
      setDistrict(newDistricts[0]);
      const zipCode = getZipCode(newCounty, newDistricts[0]);
      onChange && onChange({
        county: newCounty,
        district: newDistricts[0],
        street,
        note,
        zipCode,
      });
    }
  };

  const updateAddress = (toUpdateProps) => {
    const updatedAddress = Object.assign({ county, district, street, note }, toUpdateProps);
    const zipCode = getZipCode(updatedAddress.county, updatedAddress.district);

    onChange && onChange({ ...updatedAddress, zipCode });
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ border: '2px solid #00913A', borderRadius: '15px', marginTop: 16 }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={6}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="select-county-label">縣市</InputLabel>
              <Select
                id="select-county"
                value={county}
                onChange={(e) => {
                  onCountyChange(e.target.value);
                }}
                label="縣市"
              >
                {counties.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="select-district-label">鄉鎮市區</InputLabel>
              <Select
                id="select-district"
                value={district}
                onChange={(e) => {
                  setDistrict(e.target.value);
                  updateAddress({ district: e.target.value });
                }}
                label="鄉鎮市區"
              >
                {districts.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              placeholder='道路或街名或村里名稱'
              value={street}
              style={{ width: '100%' }}
              onChange={(e) => {
                setStreet(e.target.value);
                updateAddress({ street: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              placeholder='備註'
              value={note}
              style={{ width: '100%' }}
              onChange={(e) => {
                setNote(e.target.value);
                updateAddress({ note: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

AddressInput.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};
