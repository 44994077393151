import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/Table/DataTable';
import {
  getDeliveryStaffsByClientIdByIsActive,
} from 'graphql/queries';
import { formatAddress } from 'utilities/format';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import UserAvatar from 'components/UserAvatar';
import { deliveryStaffStatus } from '@silvergatedelivery/constants';
import DeliverStaffEditButton from 'forms/DeliverStaffForm/DeliverStaffEditButton';
import DeliveryStaffDetails from './DeliveryStaffDetails';
import cache from 'utilities/cache';

export default function DeliveryStaffTable({
  title = i18n.t('送餐大使'),
  description,
  clientId: inClientId,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const clientId = inClientId || cache.get('app:facilityId');

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns}>
          <DeliveryStaffDetails id={id} isNested={true} />
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '帳號',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名字',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '頭像',
      options: {
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <UserAvatar
              username={username}
            />
          );
        },
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordBreak: 'break-all' }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: 'identificationCardId',
      label: '身分證字號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'address',
      label: '地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'documents',
      label: '資料檔案',
      options: {
        filter: true,
        sort: true,
        customBodyRender(documents) {
          return !documents || documents.length === 0 ? '未上傳' : documents.length < 6 ? '部分上傳' : '已上傳';
        },
      },
      adminsOnly: true,
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/deliveryStaff/${id}`}
              label={`前往${t('送餐大使')}頁面`}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    console.log('clientId', clientId);
    setIndexes([
      {
        name: 'getDeliveryStaffsByClientIdByIsActive',
        label: '按狀態查詢',
        partitionKey: 'clientId',
        sortKey: 'isActive',
        fields: [{
          label: '使用狀態',
          key: 'isActive',
          type: 'select',
          options: deliveryStaffStatus,
        }],
        operation: getDeliveryStaffsByClientIdByIsActive,
      },
    ]);
    setQueryParams({
      clientId,
      isActive: 1,
    });
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        editButton={DeliverStaffEditButton}
        {...props}
      />
    </React.Fragment>
  );
}

DeliveryStaffTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
};
