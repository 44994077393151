const deliveryStaffOrderItems = /* GraphQL */ `
  items {
    id
    deliveryStaffId
    orderId
    clientId
    elderId
    status
    county
    category
    deliveryBy
    mealSlot
    distanceInKilometer
    timeInMilliseconds
    deliveryAcceptedAt
    deliveryStartedAt
    deliveryDeliveredAt
    deliveryCompletedAt
    deliveryAbandonedAt
    deliveryAbandonedReason
    deliveryPositionUpdatedAt
    deliveryStaffFee
    diary
    checkListIsComplete
    deliveryResult
    checkListReminder
    checkListTransfer
    checkListChat
    photoS3Keys
    notes
    favorite
    isPublished
    publishedUrl
    blogId
    deliveryStaff {
      id
      username
      isActive
      county
      clientId
      name
    }
    elderDementiaSurvey {
      items {
        id
        questionId
        response
        date
        dementiaQuestionnaire {
          items {
            id
            category
            type
            question
          }
          nextToken
        }
      }
      nextToken
    }
    issue {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        orderId
        createdAt
      }
      nextToken
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;

export const getDeliveryStaffOrdersByClientByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByClientByDate(
    $clientId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByClientByDate(
      clientId: $clientId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${deliveryStaffOrderItems}
      nextToken
    }
  }
`;
