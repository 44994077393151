const deliveryStaffOrderItems = /* GraphQL */ `
  items {
    id
    deliveryStaffId
    orderId
    clientId
    elderId
    status
    county
    category
    deliveryBy
    mealSlot
    distanceInKilometer
    timeInMilliseconds
    deliveryAcceptedAt
    deliveryStartedAt
    deliveryDeliveredAt
    deliveryCompletedAt
    deliveryAbandonedAt
    deliveryAbandonedReason
    deliveryPositionUpdatedAt
    deliveryStaffFee
    diary
    checkListIsComplete
    deliveryResult
    checkListReminder
    checkListTransfer
    checkListChat
    photoS3Keys
    notes
    favorite
    hasIssue
    isPublished
    publishedUrl
    blogId
    deliveryStaff {
      id
      username
      isActive
      county
      clientId
      name
    }
    elderDementiaSurvey {
      items {
        id
        questionId
        response
        date
        dementiaQuestionnaire {
          items {
            id
            category
            type
            question
          }
          nextToken
        }
      }
      nextToken
    }
    issue {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        orderId
        createdAt
      }
      nextToken
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;

export const getDeliveryStaffOrdersByStaffByStatus = /* GraphQL */ `
  query GetDeliveryStaffOrdersByStaffByStatus(
    $deliveryStaffId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByStaffByStatus(
      deliveryStaffId: $deliveryStaffId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${deliveryStaffOrderItems}
      nextToken
    }
  }
`;

export const getDeliveryStaffOrdersByStatusByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByStatusByDate(
    $status: DeliveryStaffOrderStatus
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByStatusByDate(
      status: $status
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          totalEarnedSToken
          completedOrderCount
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;

export const getDeliveryStaffOrdersByCountyByDeliveryBy = /* GraphQL */ `
  query GetDeliveryStaffOrdersByCountyByDeliveryBy(
    $county: String
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByCountyByDeliveryBy(
      county: $county
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${deliveryStaffOrderItems}
      nextToken
    }
  }
`;

export const listDeliveryStaffOrders = /* GraphQL */ `
  query ListDeliveryStaffOrders(
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryStaffOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${deliveryStaffOrderItems}
      nextToken
    }
  }
`;

export const getDeliveryStaffOrdersByElderByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByElderByDate(
    $elderId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByElderByDate(
      elderId: $elderId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${deliveryStaffOrderItems}
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByClientByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByClientByDate(
    $clientId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByClientByDate(
      clientId: $clientId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${deliveryStaffOrderItems}
      nextToken
    }
  }
`;
