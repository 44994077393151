const schema = {
  'type': 'object',
  'required': [],
  'properties': {
    'facilitySettings': {
      'required': [],
      'type': ['object'],
      'title': '機構設定',
      'properties': {
        'dba': {
          'type': ['string', 'null'],
          'title': '別名/登記名稱',
        },
        'govQualifiedPrice': {
          'type': ['number', 'null'],
          'title': '政府核定單價',
        },
        'lunchStartTime': {
          'type': ['string', 'null'],
          'title': '午餐服務開始時間',
          'format': 'time',
          'default': '10:00',
        },
        'lunchEndTime': {
          'type': ['string', 'null'],
          'title': '午餐服務結束時間',
          'format': 'time',
          'default': '12:00',
        },
        'dinnerStartTime': {
          'type': ['string', 'null'],
          'title': '晚餐服務開始時間',
          'format': 'time',
          'default': '16:00',
        },
        'dinnerEndTime': {
          'type': ['string', 'null'],
          'title': '晚餐服務結束時間',
          'format': 'time',
          'default': '18:00',
        },
        'deliverySetting': {
          'required': [],
          'type': ['object', 'null'],
          'title': '送餐設定',
          'properties': {
            'canStartDeliveryBefore': {
              'type': ['number', 'null'],
              'title': '提早送餐',
              'default': 2.5,
              'description': '送餐大使可以提早幾個小時開始送餐',
            },
            'canStartDeliveryAfter': {
              'type': ['number', 'null'],
              'title': '延後送餐',
              'default': 2.5,
              'description': '送餐大使可以延後幾個小時開始送餐',
            },
            'canCompleteOrderAfter': {
              'type': ['number', 'null'],
              'title': '完成日誌',
              'default': 24,
              'description': '送餐大使送達餐點後幾個小時內可以完成日誌',
            },
            'canModifyDiaryAfter': {
              'type': ['number', 'null'],
              'title': '修改日誌',
              'default': 9,
              'description': '送餐大使完成日誌後幾個小時內可以修改日誌',
            },
          },
        },
        'receiptInfo': {
          'required': [],
          'type': ['object', 'null'],
          'title': '收據資訊',
          'properties': {
            'receiptType': {
              'type': ['string', 'null'],
              'title': `發票格式`,
              'enum': ['duplicate', 'triplicate'],
              'enumNames': ['一式二聯', '一式三聯'],
              'default': 'triplicate',
            },
            'taxIdNumber': {
              'type': ['string', 'null'],
              'title': `統一編號`,
              'default': '',
            },
            'faxNumber': {
              'type': ['string', 'null'],
              'title': `傳真`,
              'default': '',
            },
            'bankName': {
              'type': ['string', 'null'],
              'title': `銀行名稱`,
              'default': '',
            },
            'accountNumber': {
              'type': ['string', 'null'],
              'title': `匯款帳號`,
              'default': '',
            },
            'accountName': {
              'type': ['string', 'null'],
              'title': `帳戶名`,
              'default': '',
            },
            'managerTitle': {
              'type': ['string', 'null'],
              'title': `主管職稱`,
              'default': '',
              'description': '收據上用印主管職稱',
            },
            'reverseCopyType': {
              'type': ['boolean', 'null'],
              'title': '第一聯為個案收執聯',
              'default': false,
            },
            'note': {
              'type': ['string', 'null'],
              'title': `備註`,
              'default': '',
            },
          },
        },
      },
    },
  },
};

export default schema;
