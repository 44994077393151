import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Location from './Location';
import Cart from './Cart';
import Order from './Order';
import Diary from './Diary';
import Member from './Member';
import RestaurantMealList from './RestaurantMealList';
import cache from 'utilities/cache';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useCache } from 'CacheProvider';
import { asyncListAll } from 'utilities/graph';
import { getEldersByClientByStatus } from 'graphql/queries';
import querystring from 'query-string';
import { sortBy } from 'utilities/sorting';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    overflowX: 'hidden',
    backgroundColor: 'white',
    width: '100%',
    height: 'calc(100vh - 116px)',
  },
}));

const pages = [
  {
    key: 'address',
    label: '地址',
    component: Location,
    showNavigtion: false,
    nextPage: 'meals',
  },
  {
    key: 'meals',
    label: '首頁',
    component: RestaurantMealList,
    showNavigtion: true,
    icon: <HomeIcon />,
  },
  {
    key: 'cart',
    label: '購物車',
    component: Cart,
    showNavigtion: true,
    icon: <ShoppingCartOutlinedIcon />,
  },
  {
    key: 'order',
    label: '所有訂單',
    component: Order,
    showNavigtion: true,
    icon: <ListAltIcon />,
  },
  {
    key: 'diary',
    label: '關懷日誌',
    component: Diary,
    showNavigtion: true,
    icon: <FavoriteBorderIcon />,
  },
  {
    key: 'member',
    label: '會員中心',
    component: Member,
    showNavigtion: true,
    icon: <PersonOutlineOutlinedIcon />,
  },
];

export default function Menu2() {
  const classes = useStyles();
  const [activePage, setActivePage] = useState(pages[0]);
  const { appGroup, userGroupOptions } = useCache();

  const goPage = (pageKey) => {
    const page = pages.find(({ key }) => key === pageKey);
    if (page) {
      setActivePage(page);
    }
  };

  useEffect(() => {
    let clientOption;
    if (userGroupOptions?.length > 0) {
      clientOption = userGroupOptions.find(({ userGroupName }) => userGroupName === 'Clients');
    }
    const address = cache.get('public:menu2-address');
    const receiver = cache.get('public:menu2-receiver');
    const { transactionId } = querystring.parse(window.location.search);
    if (transactionId) {
      goPage('cart');
      return;
    }
    const defaultPage = cache.get('public:menu2-active-page') || 'meals';
    if (!address || !receiver) {
      if (clientOption?.clientId) {
        const { clientId } = clientOption;
        (async () => {
          const existingElders = (await asyncListAll(getEldersByClientByStatus, {
            clientId,
            status: { eq: '使用中' },
          })).sort(sortBy('updatedAt'));
          if (existingElders.length !== 0) {
            const { address: elderAddress, name, phoneNumber, phoneNumber2, phoneNumber3, noteForDelivery } = existingElders[existingElders.length - 1];
            if (!address) {
              cache.set('public:menu2-address', elderAddress);
            }
            if (!receiver) {
              cache.set('public:menu2-receiver', {
                name,
                phoneNumber,
                phoneNumber2,
                phoneNumber3,
                noteForDelivery,
              });
            }
            goPage(defaultPage);
          }
        })();
      } if (address) {
        goPage(defaultPage);
      }
    } else {
      goPage(defaultPage);
    }
  }, [appGroup, userGroupOptions]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activePage]);

  const cacheKey = `public:menu2-${activePage.key}`;
  const formData = cache.get(cacheKey) || {};

  return (
    <div className={classes.root} data-test-id="container">
      <Grid container direction='column' style={{ height: '100%', position: 'relative' }}>
        <Grid item style={{ maxHeight: 'calc(100vh - 116px)' }} >
          <activePage.component
            formData={formData}
            onGoPage={(page) => goPage(page)}
          />
        </Grid>
        <Grid item style={{ position: 'fixed', bottom: 0, width: '100%', left: 0, right: 0 }}>
          {activePage.showNavigtion ?
            <BottomNavigation
              value={activePage.key}
              onChange={(_, newValue) => {
                const newActivePage = pages.find(({ key }) => key === newValue);
                if (newActivePage) {
                  setActivePage(newActivePage);
                  cache.set('public:menu2-active-page', newValue);
                }
              }}
              showLabels
            >
              {pages.filter(({ showNavigtion }) => showNavigtion).map(({ label, key, icon }) => (
                <BottomNavigationAction key={key} label={label} value={key} icon={icon} />
              ))}
            </BottomNavigation> :
            <div style={{ backgroundColor: 'white', height: '100px' }} />}
        </Grid>
      </Grid>
    </div>
  );
}
