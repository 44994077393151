import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const defaultLng = 'silvergate';
// const defaultLng = 'medical';

const i18nInit = async () => {
  await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: defaultLng,
      backend: {
        loadPath: '/i18n/{{ns}}/{{lng}}.json',
      },
      fallbackLng: defaultLng,
      debug: process.env.NODE_ENV === 'development',
      ns: ['translations'],
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
};

export default i18nInit;
