import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Storage } from 'aws-amplify';

import { request } from 'utilities/graph';
import {
  getRestaurantMealsByRestaurantByIsActive,
} from './RestaurantMealGrids.graph';

import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import TagChip from 'components/TagChip';
import {
  deleteRestaurantMeal,
  deleteRestaurantMealTag,
} from 'graphql/mutations';
import RestaurantMealDialog from './RestaurantMealDialog';

const useStyles = makeStyles({
  mediaContainer: {
    position: 'relative',
  },
  media: {
    height: 150,
    opacity: 0.1,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  card: {
    height: '100%',
  },
});

export default function RestaurantMealGrids({ restaurantId }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [meals, setMeals] = useState([]);

  const handleDelete = async (id, name, tags) => {
    const confirmResult = window.confirm(`確定刪除${t('餐點')} ${name} 嗎？`);
    if (!confirmResult) return;

    setIsLoading(true);
    await Promise.all([
      ...tags.items.map((item) => {
        return request(deleteRestaurantMealTag, { input: { id: item.id } });
      }),
    ]);

    await Promise.all([
      request(deleteRestaurantMeal, { input: { id } }),
    ]);

    const matchedIndex = meals.findIndex((meal) => meal.id === id);
    meals.splice(matchedIndex, 1);

    setMeals([...meals]);
    setIsLoading(false);
  };

  const onUpdate = (data) => {
    const matchedIndex = meals.findIndex(({ id }) => id === data.id);
    global.logger.debug(data, matchedIndex);

    if (matchedIndex >= 0) {
      meals.splice(matchedIndex, 1, data);
    } else {
      meals.unshift(data);
    }
    setMeals([...meals]);
  };

  useEffect(() => {
    (async () => {
      const meals = await asyncListAll(getRestaurantMealsByRestaurantByIsActive, { restaurantId });

      await Promise.all(meals.map(async (meal) => {
        if (meal.imageS3Keys && meal.imageS3Keys[0]) {
          meal.imageUrl = await Storage.get(meal.imageS3Keys[0]);
        }
      }));

      setMeals(meals.sort(sortBy('name')));
    })();
  }, [restaurantId]);

  return (
    <div style={{ position: 'relative' }}>
      <RestaurantMealDialog
        mode={'add'}
        buttonStyle={{ position: 'absolute', right: 8, top: -42 }}
        restaurantId={restaurantId}
        onUpdate={onUpdate}
      />
      <Grid container spacing={2}>
        {meals.length === 0 && <Grid item xs={12}>{`尚無${t('餐點')}`}</Grid>}
        {meals.map(({ id, name, isPublic, description, price, cost, tags, imageS3Keys, imageUrl }) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={id}>
            <Card className={classes.card}>
              <div className={classes.mediaContainer} >
                <CardMedia
                  className={classes.media}
                  image={imageUrl || '/images/meal.webp'}
                  title="照片"
                  component="img"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/meal.webp';
                  }}
                />
                <div className={classes.imageContainer}>
                  <img className={classes.img}
                    src={imageUrl || '/images/meal.webp'}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/images/meal.webp';
                    }}
                  />
                </div>
              </div>
              <CardContent style={{ position: 'relative' }}>
                <RestaurantMealDialog
                  mode={'edit'}
                  buttonStyle={{ position: 'absolute', right: 36, top: 12 }}
                  buttonSize={'small'}
                  mealItem={{ id, name, isPublic, description, price, cost, tags, imageS3Keys, imageUrl }}
                  restaurantId={restaurantId}
                  onUpdate={onUpdate}
                />
                <Tooltip title={`刪除${t('餐點')}`} style={{ position: 'absolute', right: 8, top: 12 }}>
                  <IconButton
                    onClick={() => handleDelete(id, name, tags)}
                    size={'small'}
                    disabled={isLoading}
                  >
                    <DeleteForeverIcon fontSize={'inherit'} />
                  </IconButton>
                </Tooltip>
                <Typography gutterBottom variant="h5" component="h2">
                  {name} {isPublic ? '*' : ''}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{ marginBottom: 8 }}>
                  {description}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{ marginBottom: 8 }}>
                  單價 ${price} 進價 ${cost || 0}
                </Typography>
                {tags.items.map(({ tag }, index)=>(
                  <TagChip
                    key={index}
                    tag={tag}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>);
}

RestaurantMealGrids.propTypes = {
  restaurantId: PropTypes.string,
};
