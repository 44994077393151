export default [
  {
    label: '午餐',
    value: 'lunch',
  },
  {
    label: '晚餐',
    value: 'dinner',
  },
];
