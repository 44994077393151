import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';

import DataTable from 'components/Table/DataTable';
import {
  listPandagoOrders,
  getPandagoOrdersByStatusByShouldBeSentToPandago,
  getPandagoOrdersByProcessStatusByShouldBeSentToPandago,
  getPandagoOrdersByOrderByCreatedAt,
} from 'graphql/queries';
import Order from 'components/Order/Order';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import {
  // LAUNCH_DATE,
  TIME_ZONE,
  pandagoOrderStatus,
  processStatus,
} from '@silvergatedelivery/constants';
import ProcessStatusChip from 'components/ProcessStatusChip';
import PandagoOrderStatusChip from 'components/PandagoOrderStatusChip';
import PandagoOrderLogTable from './PandagoOrderLogTable';
import ImagePreviewAvatar from 'components/ImagePreviewAvatar';

// const past = moment(LAUNCH_DATE).tz(TIME_ZONE).format('YYYY-MM-DD');
const past = moment().add(-3, 'days').tz(TIME_ZONE).format('YYYY-MM-DD');
const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function PandagoOrderTable({
  title = 'Pandago 訂單',
  description,
  orderId,
  data,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [showQueryGenerator, setShowQueryGenerator] = useState(true);
  const [showNestedOrder, setShowNestedOrder] = useState(true);

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const [x, orderId, processlogs] = rowData; // eslint-disable-line no-unused-vars

      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <PandagoOrderLogTable data={processlogs.map(JSON.parse).reverse()} />
          {showNestedOrder && <Order id={orderId} />}
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'proofOfDeliveryS3Key',
      label: '送達證明',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(proofOfDeliveryS3Key) {
          return (
            <ImagePreviewAvatar
              imageS3Keys={[proofOfDeliveryS3Key]}
              alt={'無'}
            />
          );
        },
      },
    },
    {
      name: 'orderId',
      label: '訂單編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'processlogs',
      label: '紀錄',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'id',
      label: '編號',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'processStatus',
      label: '處理狀況',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <ProcessStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'processStatusMessage',
      label: '處理資訊',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <PandagoOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'trackingLink',
      label: '追蹤網頁',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(trackingLink) {
          if (!trackingLink) {
            return;
          }
          return (
            <LinkButton
              path={trackingLink}
              label="追蹤網頁"
            />
          );
        },
      },
    },
    {
      name: 'cancelledReason',
      label: '取消原因',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: '描述',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'paymentMethod',
      label: '付款方式',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'amount',
      label: '訂單金額',
      type: 'currency',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fee',
      label: '送餐費用',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'shouldBeSentToPandagoAt',
      label: '預計送單時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'estimatedPickupTime',
      label: '預計取餐時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'estimatedDeliveryTime',
      label: '預計送達時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'driver',
      label: '送餐員',
      options: {
        filter: true,
        sort: true,
        customBodyRender({ id, name, phoneNumber }) {
          if (id === 'N/A') return '';

          return `${name} ${phoneNumber}`;
        },
      },
    },
    {
      name: 'pandagoOrderPayload',
      label: 'Payload',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender(data) {
          if (!data) return '';

          return <ReactJson
            src={JSON.parse(JSON.parse(data))}
            name={null}
            displayDataTypes={false}
          />;
        },
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderId',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/order/${id}`}
              label="前往訂單頁面"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setShowQueryGenerator(false);
      setShowNestedOrder(false);
      return;
    }
    if (orderId) {
      setShowQueryGenerator(false);
      setShowNestedOrder(false);
      setIndexes([
        {
          name: 'getPandagoOrdersByOrderByCreatedAt',
          label: '按訂單查詢',
          partitionKey: 'orderId',
          sortKey: 'createdAt',
          fields: [{
            label: '訂單編號',
            key: 'orderId',
          }],
          operation: getPandagoOrdersByOrderByCreatedAt,
        },
      ]);
      setQueryParams({
        orderId,
        createdAt: {
          from: past,
          to: todayDate,
        },
      });
      return;
    }
    setIndexes([
      {
        name: 'getPandagoOrdersByProcessStatusByShouldBeSentToPandago',
        label: '按處理狀況查詢',
        partitionKey: 'processStatus',
        sortKey: 'shouldBeSentToPandagoAt',
        fields: [{
          label: '處理狀況',
          key: 'processStatus',
          type: 'select',
          options: processStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToPandagoAt',
          type: 'datetime',
        }],
        operation: getPandagoOrdersByProcessStatusByShouldBeSentToPandago,
      },
      {
        name: 'getPandagoOrdersByStatusByShouldBeSentToPandago',
        label: '按Pandago狀態查詢',
        partitionKey: 'status',
        sortKey: 'shouldBeSentToPandagoAt',
        fields: [{
          label: '狀態',
          key: 'status',
          type: 'select',
          options: pandagoOrderStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToPandagoAt',
          type: 'datetime',
        }],
        operation: getPandagoOrdersByStatusByShouldBeSentToPandago,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '預計送單時間',
          key: 'shouldBeSentToPandagoAt',
          type: 'datetime',
        }],
        operation: listPandagoOrders,
      },
    ]);
    setQueryParams({
      processStatus: 'processing',
      shouldBeSentToPandagoAt: {
        from: past,
        to: todayDate,
      },
    });
  }, [data, orderId]);

  return (
    <React.Fragment>
      <DataTable
        data={data}
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        showQueryGenerator={showQueryGenerator}
        {...props}
      />
    </React.Fragment>
  );
}

PandagoOrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  orderId: PropTypes.string,
  data: PropTypes.array,
};
