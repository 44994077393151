import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
// import { Hub } from 'aws-amplify';

import { request } from 'utilities/graph';
import { getDeliveryStaffTransactionByStatusByCreatedAt } from 'graphql/queries';
import { AUTO_UPDATE_INTERVAL_IN_MILLISECONDS } from '@silvergatedelivery/constants';

export default function STokenBadge({ children }) {
  const [badgeContent, setBadgeContent] = useState(0);

  const update = async () => {
    const params = {
      status: 'pending',
      limit: 10,
      sortDirection: 'DESC',
    };
    const {
      data: {
        getDeliveryStaffTransactionByStatusByCreatedAt: { items: records },
      },
    } = await request(getDeliveryStaffTransactionByStatusByCreatedAt, params);

    setBadgeContent(records.length);
  };

  useEffect(() => {
    update();

    const interval = setInterval(update, AUTO_UPDATE_INTERVAL_IN_MILLISECONDS);

    return () => {
      // Hub.remove('app', appListener);
      clearInterval(interval);
    };
  }, []);

  return (
    <Tooltip
      title={`尚未處理的交易`}
      placement="right"
    >
      <Badge
        badgeContent={badgeContent}
        max={9}
        color="secondary"
      >
        {children}
      </Badge>
    </Tooltip>);
}

STokenBadge.propTypes = {
  children: PropTypes.element,
};
