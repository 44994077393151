import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import JSZip from 'jszip';
import { download } from 'utilities/file';
import { formatAddress } from 'utilities/format';
import { numberToChineseUpper } from '../CountyPrsReport/workbooks/helpers';
import ExcelJS from 'exceljs';

async function downloadImage(url) {
  try {
    const response = await fetch(url);
    const contentType = response.headers.get('Content-Type');
    if (!response.ok || !contentType.startsWith('image/')) {
      return;
    }
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  } catch (e) {
    console.error(e);
    return;
  }
}

// TODO: put it in client setting?
const copaymentSetting = {
  桃園市: {
    originalPrice: 100,
    低收: {
      value: 0,
      label: '低收（全額補助100%）',
    },
    中低收: {
      value: 10,
      label: '中低收（自行負擔10%）',
    },
    一般: {
      value: 100,
      label: '一般戶',
    },
  },
  雲林縣: {
    originalPrice: 100,
    低收: {
      value: 0,
      label: '低收（全額補助100%）',
    },
    中低收: {
      value: 10,
      label: '中低收（自行負擔10%）',
    },
    一般: {
      value: 30,
      label: '一般戶（自行負擔30%）',
    },
  },
  苗栗縣: {
    originalPrice: 100,
    // 低收: {
    //   value: 0,
    //   label: '低收',
    // },
    中低收: {
      value: 10,
      label: '中低收',
    },
    一般: {
      value: 100,
      label: '一般',
    },
  },
};

const stampSetting = {
  'e281a800-8ae3-11ec-823f-bbd2f0442830': { // 銀色大門-苗栗
    company: {
      file: '/images/receipt/e281a800-8ae3-11ec-823f-bbd2f0442830/company.png',
      width: 96 * 1.5,
      height: 96 * 1.5,
    },
    chairman: {
      file: '/images/receipt/e281a800-8ae3-11ec-823f-bbd2f0442830/chairman.png',
      width: 96 / 2,
      height: 96 / 2,
    },
    accountant: {
      file: '/images/receipt/e281a800-8ae3-11ec-823f-bbd2f0442830/accountant.png',
      width: 507 / 12,
      height: 500 / 12,
    },
    handler: {
      file: '/images/receipt/e281a800-8ae3-11ec-823f-bbd2f0442830/handler.png',
      width: 82 / 2,
      height: 82 / 2,
    },
  },
};

const getSanitizedElderName = (name) => {
  const index = name.indexOf('(');
  const result = index !== -1 ? name.substring(0, index).trim() : name;
  return result
    .replace(/\\/g, '')
    .replace(/\//g, '')
    .replace(/\?/g, '')
    .replace(/\*/g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, '');
};

export default async function issueReceipts({
  county: inCounty,
  elders: allElders,
  orders: allOrders,
  selectedMonth = 'YYYY-MM',
  client,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const { name, facilitySettings: { dba } } = client;
  const clientName = dba || name;
  const elders = allElders.filter(({ address }) => address.county === inCounty);
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }
  const zip = new JSZip();
  const [year, month, day] = moment().tz(TIME_ZONE).format('YYYY-MM-DD').split('-');
  const yearMonthDayTitle = `中華民國${parseInt(year)-1911}年${month}月${day}日`;

  const stamp = stampSetting[client.id];
  if (stamp) {
    await Promise.all(Object.keys(stamp).map(async (key) => {
      if (stamp[key] && stamp[key].file) {
        stamp[key].imageBuffer = await downloadImage(stamp[key].file);
      }
    }));
  }
  // TODO
  // // 移除沒有弱勢身份的訂單
  // const ordersForDisvantaged = orders.filter((order) => {
  //   const { disadvantagedTypesSlot } = elders.find((elder) => elder.id === order.elderId) || {};
  //   if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
  //     const slot = disadvantagedTypesSlot.find(({ start, end }) => order.date >= start && order.date < end);
  //     if (slot && slot.type === '一般') {
  //       return false;
  //     }
  //   }
  //   return true;
  // });

  const eldersGroupByDistrict = elders.reduce((acc, elder) => {
    const key = elder.address.district;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(elder);
    return acc;
  }, {});
  const bufferData = [];
  let receiptNo = 0;
  await Object.keys(eldersGroupByDistrict).sort().reduce(async (chain, district) => {
    await chain;

    const elders = eldersGroupByDistrict[district];
    const workbook = new ExcelJS.Workbook();
    const worksheetName = [];
    const stampImages = {};

    if (stamp) {
      Object.keys(stamp).forEach((key) => {
        if (stamp[key].imageBuffer) {
          const imageId = workbook.addImage({
            buffer: stamp[key].imageBuffer,
            extension: 'png',
          });
          stampImages[key] = {};
          stampImages[key].width = stamp[key].width;
          stampImages[key].height = stamp[key].height;
          stampImages[key].imageId = imageId;
        }
      });
    }
    elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
      const { id, name } = elder;
      const elderOrders = orders.filter(({ elderId }) => elderId === id);
      if (elderOrders.length === 0) {
        return;
      }
      let sanitizedElderName = getSanitizedElderName(name);
      let duplicatedNameCount = 0;
      if (worksheetName.includes(sanitizedElderName)) {
        duplicatedNameCount += 1;
        sanitizedElderName = sanitizedElderName + `(${duplicatedNameCount})`;
      } else {
        duplicatedNameCount = 0;
      }
      const worksheet = workbook.addWorksheet(sanitizedElderName, {
        pageSetup: {
          paperSize: 9,
          fitToPage: true,
          fitToWidth: 1,
          horizontalCentered: true,
          verticalCentered: true,
          orientation: 'portrait',
        },
      });
      const result = getWorksheetElder(worksheet, client, elder, elderOrders, yearMonthDayTitle, inCounty, selectedMonth, receiptNo, stampImages);
      worksheet.pageSetup.margins = {
        left: 0.236, right: 0.236,
        top: 0.748, bottom: 0.748,
        header: 0.3, footer: 0.3,
      };
      if (!result) {
        workbook.removeWorksheet(worksheet.id);
      } else {
        receiptNo += 1;
        worksheetName.push(sanitizedElderName);
      }
    });
    try {
      if (workbook && workbook.worksheets && workbook.worksheets.length > 0) {
        const bufferDataByDistrict = await workbook.xlsx.writeBuffer();
        if (process.env.JEST_WORKER_ID) {
          bufferData.push(bufferDataByDistrict);
          return;
        }
        zip.file(`${inCounty}${district}餐飲服務費收據__${selectedMonth}.xlsx`, bufferDataByDistrict, { binary: true });
      }
    } catch (e) {
      console.error(e);
    }
  }, Promise.resolve());

  if (process.env.JEST_WORKER_ID) {
    return bufferData;
  }
  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedMonth}餐飲服務費收據.zip`, zipContent);
  }
}

function getWorksheetElder(worksheet, client, elder, elderOrders = [], yearMonthDayTitle, county, selectedMonth, receiptNo, stampImages) {
  const {
    name, facilitySettings: { dba, receiptInfo }, address: clientAddress, phoneNumber,
  } = client;
  const { receiptType = 'triplicate', taxIdNumber, faxNumber, bankName, accountNumber, accountName, note, managerTitle, reverseCopyType } = receiptInfo || {};
  const clientName = dba || name;
  const elderRows = [];
  const {
    name: elderName,
    identificationCardId,
    disadvantagedTypes,
    disadvantagedTypesSlot,
    cmsLevel,
    caseNumber,
  } = elder;

  let total = 0;
  if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
    disadvantagedTypesSlot.forEach(({ start, end, type }) => {
      const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
      if (filteredOrders.length === 0) {
        return;
      }
      if (type === '長照中低收') { // workaround for 展橙
        type = '中低收';
      }
      if (!copaymentSetting[county] || copaymentSetting[county][type] === undefined) {
        return;
      }
      const copayment = copaymentSetting[county][type].value || 0;
      elderRows.push({
        服務編號: 'OT01[營養餐飲]',
        福利身份別: copaymentSetting[county][type].label,
        原價: copaymentSetting[county].originalPrice,
        部分負擔: copayment,
        使用次數: filteredOrders.length,
        小計: copayment * filteredOrders.length,
      });
      total += copayment * filteredOrders.length;
    });
  } else {
    if (elderOrders.length === 0) {
      return;
    }
    if (!copaymentSetting[county] || copaymentSetting[county][disadvantagedTypes] === undefined) {
      return;
    }
    const copayment = copaymentSetting[county][disadvantagedTypes].value || 0;
    elderRows.push({
      服務編號: 'OT01[營養餐飲]',
      福利身份別: copaymentSetting[county][disadvantagedTypes].label,
      原價: copaymentSetting[county].originalPrice,
      部分負擔: copayment,
      使用次數: elderOrders.length,
      小計: copayment * elderOrders.length,
    });
    total += copayment * elderOrders.length;
  }
  const serviceDates = elderOrders.map(({ date, mealSlot }) => {
    const formattedDate = moment(date).format('MM/DD');
    return {
      date: formattedDate,
      slot: mealSlot === 'lunch'? '午' : '晚',
    };
  }).sort((a, b) => {
    if (a.date > b.date) return 1;
    if (a.date < b.date) return -1;
    if (a.slot === '午') return -1;
  });
  let serviceDatesString = '';
  let previousDate = '';
  let serviceDays = 0;
  serviceDates.forEach(({ date, slot }, index) => {
    if (previousDate !== date) {
      if (index !== 0) {
        serviceDatesString += '、';
      }
      // if (serviceDays !== 0 && serviceDays % 7 === 0) {
      //   serviceDatesString += '\n';
      // }
      serviceDatesString += date + ' ' + slot;
      serviceDays += 1;
    } else {
      serviceDatesString += slot;
    }
    previousDate = date;
  });

  const elderRowsCount = elderRows.length;

  if (elderRowsCount === 0) {
    return;
  }

  let copyType = {
    duplicate: [
      '一\n式\n二\n聯\n \n \n第\n一\n聯\n：\n存\n根\n聯',
      '一\n式\n二\n聯\n \n \n第\n二\n聯\n：\n個\n案\n收\n執\n聯',
    ],
    triplicate: [
      '一\n式\n三\n聯\n \n \n第\n一\n聯\n：\n存\n根\n聯',
      '一\n式\n三\n聯\n \n \n第\n二\n聯\n：\n會\n計\n聯',
      '一\n式\n三\n聯\n \n \n第\n三\n聯\n：\n個\n案\n收\n執\n聯',
    ],
  };
  if (reverseCopyType) {
    copyType = {
      duplicate: [
        '一\n式\n二\n聯\n \n \n第\n一\n聯\n：\n個\n案\n收\n執\n聯',
        '一\n式\n二\n聯\n \n \n第\n二\n聯\n：\n存\n根\n聯',
      ],
      triplicate: [
        '一\n式\n三\n聯\n \n \n第\n一\n聯\n：\n個\n案\n收\n執\n聯',
        '一\n式\n三\n聯\n \n \n第\n二\n聯\n：\n會\n計\n聯',
        '一\n式\n三\n聯\n \n \n第\n三\n聯\n：\n存\n根\n聯',
      ],
    };
  }
  worksheet.columns = [
    { header: '', key: 'A', width: 6.15 },
    { header: '', key: 'B', width: 5.78 },
    { header: '', key: 'C', width: 4.70 },
    { header: '', key: 'D', width: 6.15 },
    { header: '', key: 'E', width: 6.15 },
    { header: '', key: 'F', width: 9.68 },
    { header: '', key: 'G', width: 6.03 },
    { header: '', key: 'H', width: 6.03 },
    { header: '', key: 'I', width: 6.03 },
    { header: '', key: 'J', width: 6.03 },
    { header: '', key: 'K', width: 6.03 },
    { header: '', key: 'L', width: 6.03 },
    { header: '', key: 'M', width: 6.03 },
    { header: '', key: 'N', width: 6.03 },
    { header: '', key: 'O', width: 6.03 },
    { header: '', key: 'P', width: 6.03 },
    { header: '', key: 'Q', width: 6.03 },
    { header: '', key: 'R', width: 6.03 },
    { header: '', key: 'S', width: 2.75 },
  ];

  let rowIndex = 1;
  let copyCount = 0;
  const defaultRowHeight = 15.75;
  const fontName = 'Microsoft YaHei';
  while (copyCount < copyType[receiptType].length) {
    worksheet.mergeCells(`A${rowIndex}:S${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).value = clientName;
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).font = { bold: true, size: 16, name: fontName };
    worksheet.getRow(rowIndex++).height = 23.25;
    worksheet.getRow(rowIndex++).height = 17.25;

    worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).value = `流水號編號：${selectedMonth.replace('-', '')}${receiptNo.toString().padStart(3, '0')}`;
    worksheet.getCell(`A${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.mergeCells(`F${rowIndex}:M${rowIndex}`);
    worksheet.getCell(`F${rowIndex}`).value = '收據明細';
    worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`F${rowIndex}`).font = { size: 10, name: fontName };
    worksheet.mergeCells(`N${rowIndex}:R${rowIndex}`);
    worksheet.getCell(`N${rowIndex}`).value = `列表日：${yearMonthDayTitle}`;
    worksheet.getCell(`N${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`N${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
    worksheet.getRow(rowIndex++).height = defaultRowHeight;

    worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).value = `身分證字號：${identificationCardId || ''}`;
    worksheet.getCell(`A${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.mergeCells(`F${rowIndex}:M${rowIndex + 1}`);
    worksheet.getCell(`F${rowIndex}`).value = `${selectedMonth.replace('-', '年')}月 長期照顧營養餐飲服務費`;
    worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`F${rowIndex}`).font = { bold: true, size: 11, name: fontName };

    worksheet.mergeCells(`N${rowIndex}:R${rowIndex}`);
    const caseNumberString = caseNumber ? `案號：${caseNumber}` : '';
    worksheet.getCell(`N${rowIndex}`).value = caseNumberString;
    worksheet.getCell(`N${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`N${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getRow(rowIndex++).height = 18;

    worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).value = `此致 ${getSanitizedElderName(elderName)} 台照`;
    worksheet.getCell(`A${rowIndex}`).font = { bold: true, size: 10, name: fontName };
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.mergeCells(`N${rowIndex}:R${rowIndex}`);
    const cmsLevelString = cmsLevel ? `失能等級：${cmsLevel}` : '';
    worksheet.getCell(`N${rowIndex}`).value = cmsLevelString;
    worksheet.getCell(`N${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`N${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getRow(rowIndex++).height = 18;

    worksheet.mergeCells(`A${rowIndex}:C${rowIndex}`);
    worksheet.getRow(rowIndex).font = { size: 9, name: fontName };
    worksheet.getRow(rowIndex).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).value = '服務編號';
    worksheet.mergeCells(`D${rowIndex}:F${rowIndex}`);
    worksheet.getCell(`D${rowIndex}`).value = '福利身份別';
    worksheet.mergeCells(`G${rowIndex}:H${rowIndex}`);
    worksheet.getCell(`G${rowIndex}`).value = '原價';
    worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`);
    worksheet.getCell(`I${rowIndex}`).value = '部分負擔';
    worksheet.mergeCells(`K${rowIndex}:L${rowIndex}`);
    worksheet.getCell(`K${rowIndex}`).value = '使用次數';
    worksheet.mergeCells(`M${rowIndex}:N${rowIndex}`);
    worksheet.getCell(`M${rowIndex}`).value = '自費單價';
    worksheet.mergeCells(`O${rowIndex}:P${rowIndex}`);
    worksheet.getCell(`O${rowIndex}`).value = '自費次數';
    worksheet.mergeCells(`Q${rowIndex}:R${rowIndex}`);
    worksheet.getCell(`Q${rowIndex}`).value = '小計';
    worksheet.getCell(`A${rowIndex}`).border = { top: { style: 'thick' }, right: { style: 'thin' }, left: { style: 'thick' } };
    ['D', 'G', 'I', 'K', 'M', 'O'].forEach((col) => {
      worksheet.getCell(`${col}${rowIndex}`).border = { top: { style: 'thick' }, right: { style: 'thin' } };
    });
    worksheet.getCell(`Q${rowIndex}`).border = { top: { style: 'thick' }, right: { style: 'thick' } };
    worksheet.getRow(rowIndex++).height = defaultRowHeight;

    elderRows.forEach((elderRow) => {
      worksheet.mergeCells(`A${rowIndex}:C${rowIndex}`);
      worksheet.getRow(rowIndex).font = { size: 9, name: fontName };
      worksheet.getRow(rowIndex).alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getCell(`A${rowIndex}`).value = elderRow['服務編號'];
      worksheet.mergeCells(`D${rowIndex}:F${rowIndex}`);
      worksheet.getCell(`D${rowIndex}`).value = elderRow['福利身份別'];
      worksheet.mergeCells(`G${rowIndex}:H${rowIndex}`);
      worksheet.getCell(`G${rowIndex}`).value = elderRow['原價'];
      worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`);
      worksheet.getCell(`I${rowIndex}`).value = elderRow['部分負擔'];
      worksheet.mergeCells(`K${rowIndex}:L${rowIndex}`);
      worksheet.getCell(`K${rowIndex}`).value = elderRow['使用次數'];
      worksheet.mergeCells(`M${rowIndex}:N${rowIndex}`);
      worksheet.mergeCells(`O${rowIndex}:P${rowIndex}`);
      worksheet.mergeCells(`Q${rowIndex}:R${rowIndex}`);
      worksheet.getCell(`Q${rowIndex}`).value = elderRow['小計'];

      worksheet.getCell(`A${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thick' } };
      ['D', 'G', 'I', 'K', 'M', 'O'].forEach((col) => {
        worksheet.getCell(`${col}${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thin' } };
      });
      worksheet.getCell(`Q${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thick' } };
      worksheet.getRow(rowIndex++).height = defaultRowHeight;
    });

    worksheet.mergeCells(`A${rowIndex}:C${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).value = `服務天數：${serviceDays}`;
    worksheet.getCell(`A${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thick' } };
    worksheet.mergeCells(`D${rowIndex}:R${rowIndex}`);
    worksheet.getCell(`D${rowIndex}`).value = serviceDatesString;
    worksheet.getCell(`D${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };
    worksheet.getCell(`D${rowIndex}`).border = {
      top: { style: 'thin' }, right: { style: 'thick' }, left: { style: 'thin' }, bottom: { style: 'thin' },
    };
    worksheet.getRow(rowIndex++).height = defaultRowHeight * 5;

    const clientInfo = [`地址：${formatAddress(clientAddress, { includeZipCode: false })}`];
    if (taxIdNumber) {
      clientInfo.push(`統一編號： ${taxIdNumber}`);
    }
    if (phoneNumber) {
      clientInfo.push(`電話：${phoneNumber}`);
    }
    if (faxNumber) {
      clientInfo.push(`傳真：${faxNumber}`);
    }
    if (bankName) {
      clientInfo.push(`銀行名稱：${bankName}`);
    }
    if (accountNumber) {
      clientInfo.push(`匯款帳號：${accountNumber}`);
    }
    if (accountName) {
      clientInfo.push(`帳戶名：${accountName}`);
    }
    if (note) {
      clientInfo.push(`備註：${note}`);
    }

    for (let i = 0; i < 8; i += 1) {
      worksheet.mergeCells(`A${rowIndex + i}:F${rowIndex + i}`);
      if (clientInfo[i]) {
        worksheet.getCell(`A${rowIndex + i}`).value = clientInfo[i];
        worksheet.getCell(`A${rowIndex + i}`).font = { size: 9, name: fontName };
        worksheet.getCell(`A${rowIndex + i}`).alignment = { horizontal: 'left', vertical: 'middle' };
      }
      if (i === 0) {
        worksheet.getCell(`A${rowIndex + i}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thick' } };
      } else {
        worksheet.getCell(`A${rowIndex + i}`).border = { right: { style: 'thin' }, left: { style: 'thick' } };
      }
    }
    worksheet.mergeCells(`G${rowIndex}:N${rowIndex + 3}`);
    worksheet.getCell(`G${rowIndex}`).value = `費用總計：$ ${total}`;
    worksheet.getCell(`G${rowIndex}`).font = { bold: true, size: 9.5, name: fontName };
    worksheet.getCell(`G${rowIndex}`).alignment = { vertical: 'middle' };
    worksheet.getCell(`G${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
    worksheet.mergeCells(`O${rowIndex}:R${rowIndex + 7}`);
    worksheet.getCell(`O${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thick' }, left: { style: 'thin' } };
    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;

    worksheet.mergeCells(`G${rowIndex}:N${rowIndex + 3}`);
    worksheet.getCell(`G${rowIndex}`).value = `茲收到新台幣 ${numberToChineseUpper(total)} 元整`;
    worksheet.getCell(`G${rowIndex}`).font = { size: 9, name: fontName };
    worksheet.getCell(`G${rowIndex}`).alignment = { vertical: 'middle' };
    worksheet.getCell(`G${rowIndex}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;

    worksheet.mergeCells(`S${rowIndex - 10 - elderRowsCount}:S${rowIndex - 1}`);
    worksheet.getCell(`S${rowIndex - 10 - elderRowsCount}`).value = copyType[receiptType][copyCount];
    worksheet.getCell(`S${rowIndex - 10 - elderRowsCount}`).font = { size: 6, name: fontName };
    worksheet.getCell(`S${rowIndex - 10 - elderRowsCount}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    worksheet.mergeCells(`A${rowIndex}:B${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).value = managerTitle ? `${managerTitle}：` : '理事長：';
    worksheet.mergeCells(`G${rowIndex}:H${rowIndex}`);
    worksheet.getCell(`G${rowIndex}`).value = '會計：';
    worksheet.mergeCells(`M${rowIndex}:N${rowIndex}`);
    worksheet.getCell(`M${rowIndex}`).value = '經手人：';
    worksheet.getRow(rowIndex).font = { size: 9, name: fontName };
    worksheet.getRow(rowIndex).alignment = { horizontal: 'left', vertical: 'middle' };
    ['A', 'C', 'D', 'E', 'F', 'G', 'I', 'J', 'K', 'L', 'M', 'O', 'P', 'Q', 'R'].forEach((col) => {
      worksheet.getCell(`${col}${rowIndex}`).border = { top: { style: 'thick' } };
    });
    if (stampImages.company && stampImages.company.imageId !== undefined) {
      worksheet.addImage(stampImages.company.imageId, {
        tl: { col: 14 + 0.3, row: rowIndex - 9 + 0.3 },
        ext: { width: stampImages.company.width, height: stampImages.company.height },
      });
    }
    if (stampImages.chairman && stampImages.chairman.imageId !== undefined) {
      worksheet.addImage(stampImages.chairman.imageId, {
        tl: { col: 1 + 0.3, row: rowIndex - 1 + 0.3 },
        ext: { width: stampImages.chairman.width, height: stampImages.chairman.height },
      });
    }
    if (stampImages.accountant && stampImages.accountant.imageId !== undefined) {
      worksheet.addImage(stampImages.accountant.imageId, {
        tl: { col: 7 + 0.3, row: rowIndex - 1 + 0.3 },
        ext: { width: stampImages.accountant.width, height: stampImages.accountant.height },
      });
    }
    if (stampImages.handler && stampImages.handler.imageId !== undefined) {
      worksheet.addImage(stampImages.handler.imageId, {
        tl: { col: 13 + 0.3, row: rowIndex - 1 + 0.3 },
        ext: { width: stampImages.handler.width, height: stampImages.handler.height },
      });
    }
    worksheet.getRow(rowIndex++).height = defaultRowHeight;

    worksheet.getRow(rowIndex++).height = defaultRowHeight;
    worksheet.getRow(rowIndex++).height = defaultRowHeight;

    copyCount++;

    if (copyCount < copyType[receiptType].length) {
      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S'].forEach((col) => {
        worksheet.getCell(`${col}${rowIndex}`).border = { bottom: { style: 'dashDot' } };
      });
      worksheet.getRow(rowIndex++).height = defaultRowHeight;
      worksheet.getRow(rowIndex++).height = defaultRowHeight;
      worksheet.getRow(rowIndex++).height = defaultRowHeight;
    }
  }

  worksheet.pageSetup.printArea = `A1:S${rowIndex}`;

  return worksheet;
}
