import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -40,
    paddingTop: 40,
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pdfDocumentContainer: {
    marginTop: 50,
    marginLeft: -20,
  },
  // pdfDocumentPage: {
  //   marginTop: -150,
  //   marginBottom: -150,
  //   marginLeft: -20,
  //   clipPath: 'inset(7% 0% 7% 0%)',
  // },
}));

let container = {};
export default function Terms({ filename }) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="md" ref={(el) => container= el}>
        <CssBaseline />
        <div className={classes.pdfDocumentContainer}>
          <Document
            className={classes.pdfDocument}
            file={`/documents/${filename}`}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
              verbosity: pdfjs.VerbosityLevel.ERRORS,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {
              container && Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    className={classes.pdfDocumentPage}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    customTextRenderer={() => false}
                    width={container.offsetWidth}
                    // https://github.com/wojtekmaj/react-pdf/issues/695#issuecomment-768922757
                    renderMode={'svg'}
                    scale={1}
                  />
                ),
              )
            }
          </Document>
        </div>
      </Container>
    </div>
  );
}

Terms.propTypes = {
  filename: PropTypes.string.isRequired,
};
