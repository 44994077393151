import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

export default function ReceiverInput({ data, onChange }) {
  const [receiver, setReceiver] = useState(JSON.parse(JSON.stringify(data)));

  const updateReceiver = (toUpdateProps = {}) => {
    const updatedReceiver = Object.assign(receiver, toUpdateProps);
    setReceiver({ ...updatedReceiver });
    onChange && onChange(updatedReceiver);
  };

  return (
    <Grid container spacing={0} style={{ paddingTop: 16 }}>
      <Grid item xs={12} container justifyContent="flex-start">
        <FormControl>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label='取餐人姓名*'
                type="text"
                value={receiver.name}
                onChange={(e) => updateReceiver({ name: e.target.value })}
                fullWidth
                error={receiver.name.length === 0}
                helperText={ receiver.name.length === 0 ? '必填' : '' }
                placeholder='請填寫取餐人姓名'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label='聯絡電話*'
                type="text"
                value={receiver.phoneNumber}
                onChange={(e) => updateReceiver({ phoneNumber: e.target.value })}
                fullWidth
                error={receiver.phoneNumber.length === 0}
                helperText={ receiver.phoneNumber.length === 0 ? '必填' : '' }
                placeholder='請填寫聯絡電話'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label='備用電話1'
                type="text"
                value={receiver.phoneNumber2}
                onChange={(e) => updateReceiver({ phoneNumber2: e.target.value })}
                fullWidth
                placeholder='請填寫備用電話'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label='備用電話2'
                type="text"
                value={receiver.phoneNumber3}
                onChange={(e) => updateReceiver({ phoneNumber3: e.target.value })}
                fullWidth
                placeholder='請填寫備用電話'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label='備註'
                type="text"
                value={receiver.noteForDelivery}
                onChange={(e) => updateReceiver({ noteForDelivery: e.target.value })}
                fullWidth
                placeholder='例如：講話請大聲'
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}

ReceiverInput.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};
