import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Link as RouteLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import cache from 'utilities/cache';

function LinkButton({
  path,
  label,
  size = 'small',
  newTab = true,
  disabled = false,
  text,
  icon,
  buttonStyle = {},
}) {
  const linkProps = newTab ? {
    target: '_blank',
    rel: 'noopener',
  } : {};
  let url = path;
  if (!path.startsWith('https://')) {
    url = `/${cache.get('app:pathPrefix')}${path}`;
  }
  const getLinkComponent = () => {
    if (text && icon) {
      return (
        <Button
          variant="outlined"
          style={buttonStyle}
          startIcon={icon}
        >
          {text}
        </Button>
      );
    }

    if (text) {
      return text;
    }

    return (
      <IconButton
        aria-label={label}
        size={size}
        disabled={disabled}
      >
        {icon ? icon : <LaunchIcon />}
      </IconButton>
    );
  };

  return (
    <Tooltip
      title={disabled ? '' : label}
      placement="bottom"
    >
      <Link
        to={{ pathname: url }}
        component={RouteLink}
        onClick={disabled ? (e) => e.preventDefault() : undefined}
        {...linkProps}
        style={{ color: 'white' }}
      >
        {getLinkComponent()}
      </Link>
    </Tooltip>
  );
}

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  text: PropTypes.string,
  size: PropTypes.string,
  newTab: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  buttonStyle: PropTypes.object,
};

export default LinkButton;
