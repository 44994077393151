import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const TimeWidget = (props) => {
  const { id, required, value: inValue, onChange, onBlur, onFocus, label } = props;
  const [value, setValue] = useState(inValue);

  const formatValue = (value) => {
    if (value && value.split(':').length === 2) {
      return value + ':00';
    }
    return value;
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange(formatValue(newValue));
  };

  return (
    <TextField
      id={id}
      label={label}
      type="time"
      required={required}
      value={value}
      onChange={handleChange}
      onBlur={(event) => onBlur(id, formatValue(event.target.value))}
      onFocus={(event) => onFocus(id, formatValue(event.target.value))}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 60,
      }}
      fullWidth
    />
  );
};

TimeWidget.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
};

export default TimeWidget;
