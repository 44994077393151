import React from 'react';

import EditButton from '../components/EditButton';
import Form from './SponsorshipProgramForm';

export default function SponsorshipProgramEditButton({ ...props }) {
  return (
    <EditButton
      title="捐贈項目"
      form={Form}
      {...props}
    />);
}
