import {
  getTagsByCategoryBySubcategory,
  // listTags,
} from 'graphql/queries';
import {
  createTag,
  createRestaurantTag,
  deleteRestaurantTag,
  createRestaurantMealTag,
  deleteRestaurantMealTag,
  createElderTag,
  deleteElderTag,
} from 'graphql/mutations';
import TagChip from './TagChip';

export const getPropsByMode = (mode) => {
  let listOptionsQueryName;
  let listOptionsQueryOptions = {};
  let createQueryName;
  let key;
  let linkKey;
  let createJoinDataQueryName;
  let deleteJoinDataQueryName;
  let chip;
  let freeSolo = false;
  const [category, subcategory] = mode.split('-');
  switch (category) {
    case '餐廳':
      key = 'tagId';
      linkKey = 'restaurantId';
      listOptionsQueryName = getTagsByCategoryBySubcategory;
      listOptionsQueryOptions = {
        category,
        subcategory: { eq: subcategory },
      };
      createQueryName = createTag;
      createJoinDataQueryName = createRestaurantTag;
      deleteJoinDataQueryName = deleteRestaurantTag;
      chip = TagChip;
      break;
    case '餐點':
      key = 'tagId';
      linkKey = 'restaurantMealId';
      listOptionsQueryName = getTagsByCategoryBySubcategory;
      listOptionsQueryOptions = {
        category,
        subcategory: { eq: subcategory },
      };
      createQueryName = createTag;
      createJoinDataQueryName = createRestaurantMealTag;
      deleteJoinDataQueryName = deleteRestaurantMealTag;
      chip = TagChip;
      break;
    case '送餐對象':
      key = 'tagId';
      linkKey = 'elderId';
      listOptionsQueryName = getTagsByCategoryBySubcategory;
      listOptionsQueryOptions = {
        category,
        subcategory: { eq: subcategory },
      };
      createQueryName = createTag;
      createJoinDataQueryName = createElderTag;
      deleteJoinDataQueryName = deleteElderTag;
      chip = TagChip;
      freeSolo = true;
      break;
    default:
  }
  return {
    key,
    chip,
    linkKey,
    listOptionsQueryName,
    listOptionsQueryOptions,
    createQueryName,
    createJoinDataQueryName,
    deleteJoinDataQueryName,
    freeSolo,
  };
};
