import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DataForm from 'forms/DataForm';
import { queryAddress } from 'graphql/mutations';
import { request } from 'utilities/graph';
import QueryAddressResultTable from '../QueryAddressResultTable';
// import { formatAddress } from 'utilities/format';
import schema from './schema';
import uiSchema from './uiSchema';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function QueryAddress() {
  const classes = useStyles();

  const [data, setData] = useState(undefined);

  const handleSubmit = async ({ addressString }) => {
    const queryAddressMethods = ['tomtom', 'google', 'tgos'];
    // const addressString = formatAddress(address);

    const result = await Promise.all(queryAddressMethods.map(async (queryAddressMethod) => {
      const params = {
        input: {
          queryAddressMethod,
          address: addressString,
        },
      };
      const {
        data: {
          queryAddress: {
            data,
          },
        },
      } = await request(queryAddress, params);

      return {
        originalAddress: addressString,
        coordinates: `${data.lat},${data.lng}`,
        ...data,
      };
    }));

    setData(result);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.container}>
            <DataForm
              schema={schema}
              uiSchema={uiSchema}
              createFunc={handleSubmit}
              updateFunc={handleSubmit}
              // onComplete={getQuote}
              dirty={true}
            >
            </DataForm>
          </Paper>
        </Grid>

        {data &&
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <QueryAddressResultTable
            title={'地址查詢結果'}
            data={data}
            padding={0}
            showQueryGenerator={false}
            indexes={[]}
            queryDefaultParams={{}}
          />
        </Grid>}
      </Grid>
    </Container>
  );
}

QueryAddress.propTypes = {};
