import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cache from 'utilities/cache';

const CacheContext = createContext();

export const useCache = () => useContext(CacheContext);

export const CacheProvider = ({ children }) => {
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [appGroup, setAppGroup] = useState();
  const [selectedUserGroupParams, setSelectedUserGroupParams] = useState(cache.get('app:selectedUserGroupParams'));
  const [facilityIds, setFacilityIds] = useState([]);
  const [logoS3Keys, setLogoS3Keys] = useState();

  useEffect(() => {
    if (userGroupOptions) {
      setIsAdmin(userGroupOptions.some(({ userGroupName }) => userGroupName === 'Admins'));
    }

    const newFacilityIds = [];
    userGroupOptions.forEach((userGroupOption) => {
      if (userGroupOption.userGroupName === 'FacilityAdmins' && userGroupOption.isActive === 1) {
        newFacilityIds.push(userGroupOption.clientId);
      }
    });
    setFacilityIds(newFacilityIds);
  }, [userGroupOptions]);

  return (
    <CacheContext.Provider value={{
      userGroupOptions, setUserGroupOptions,
      isAdmin,
      appGroup, setAppGroup,
      selectedUserGroupParams, setSelectedUserGroupParams,
      facilityIds,
      logoS3Keys, setLogoS3Keys,
    }}>
      {children}
    </CacheContext.Provider>
  );
};

CacheProvider.propTypes = {
  children: PropTypes.element,
};
