export const getOrdersByCountyByDate = /* GraphQL */ `
  query GetOrdersByCountyByDate(
    $county: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCountyByDate(
      county: $county
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        mealSlot
        deliveryBy
        status
        county
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        createdAt
        elder {
          id
          status
          isDisadvantaged
        }
      }
      nextToken
    }
  }
`;
