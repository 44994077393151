import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { processStatus } from '@silvergatedelivery/constants';

import DataTable from 'components/Table/DataTable';
import {
  listSponsorshipFulfillments,
  getSponsorshipFulfillmentsBySponsorshipIdByStatus,
} from 'graphql/queries';
// import EditButton from 'forms/SponsorshipForm/SponsorshipEditButton';
import { sortBy } from 'utilities/sorting';
import ProcessStatusChip from 'components/ProcessStatusChip';
import LinkButton from 'components/Table/LinkButton';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'status',
    label: '狀態',
    options: {
      filter: true,
      sort: true,
      customBodyRender(value) {
        return (
          <ProcessStatusChip status={value} />
        );
      },
    },
  },
  {
    name: 'unit',
    label: '單位',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unitValue',
    label: '單位價格',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fulfilledAt',
    label: '完成於',
    type: 'datetime',
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'orderId',
    label: '訂單',
    options: {
      filter: false,
      sort: true,
      customBodyRender(id) {
        if (!id) return null;
        return (
          <LinkButton
            path={`/order/${id}`}
            text={'訂單'}
            label="前往訂單頁面"
            newTab={true}
          />
        );
      },
    },
  },
  {
    name: 'elderId',
    label: '長輩',
    options: {
      filter: false,
      sort: true,
      customBodyRender(id) {
        if (!id) return null;
        return (
          <LinkButton
            path={`/elder/${id}`}
            text={'長輩'}
            label="前往長輩頁面"
            newTab={true}
          />
        );
      },
    },
  },
  {
    name: 'restaurantId',
    label: '餐廳',
    options: {
      filter: false,
      sort: true,
      customBodyRender(id) {
        if (!id) return null;
        return (
          <LinkButton
            path={`/restaurant/${id}`}
            text={'餐廳'}
            label="前往餐廳頁面"
            newTab={true}
          />
        );
      },
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function SponsorshipFulfillmentTable({
  title = '捐贈細項進度紀錄',
  description,
  sponsorshipId,
  showQueryGenerator,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  useEffect(() => {
    if (sponsorshipId) {
      setIndexes([
        {
          name: 'getSponsorshipFulfillmentsBySponsorshipIdByStatus',
          label: '按贊助紀錄狀態查詢',
          partitionKey: 'sponsorshipId',
          sortKey: 'status',
          fields: [{
            label: '狀態',
            key: 'status',
            type: 'select',
            options: processStatus,
          }],
          operation: getSponsorshipFulfillmentsBySponsorshipIdByStatus,
        },
      ]);
      setQueryParams({
        sponsorshipId,
        status: processStatus[0].value,
      });
    } else {
      setIndexes([
        {
          name: 'scan',
          label: '掃描全部資料',
          fields: [],
          operation: listSponsorshipFulfillments,
        },
      ]);
      setQueryParams({
        status: processStatus[0].value,
      });
    }
  }, [sponsorshipId]);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      // editButton={EditButton}
      dataSortFunc={sortBy('status')}
      showQueryGenerator={showQueryGenerator}
    />
  );
}

SponsorshipFulfillmentTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  sponsorshipId: PropTypes.string,
  showQueryGenerator: PropTypes.bool,
};
