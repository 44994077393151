import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Order from 'components/Order/Order';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDialog({
  id,
  mode = 'button',
  open: inOpen,
  onClose,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  const handleOpenOrderPage = () => {
    window.open(`/order/${id}`);
  };

  useEffect(() => {
    if (inOpen !== void(0)) {
      setOpen(inOpen);
    }
  }, [inOpen]);

  return (
    <React.Fragment>
      {!onClose && mode === 'button' &&
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          查看
        </Button>}
      {!onClose && mode === 'icon' &&
        <IconButton
          edge="end"
          size="small"
          aria-label="open"
          onClick={handleClickOpen}
        >
          <VisibilityIcon />
        </IconButton>
      }
      {id && open &&
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar} color="default" elevation={0}>
            <Toolbar>
              <IconButton component="span" onClick={handleOpenOrderPage}>
                <OpenInNewIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                訂單
              </Typography>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button> */}
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Order id={id} />
        </Dialog>}
    </React.Fragment>
  );
}

OrderDialog.propTypes = {
  id: PropTypes.string,
  mode: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
