import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import { listClientAppBanners } from 'graphql/queries';
import EditButton from 'forms/ClientAppBannerForm/ClientAppBannerEditButton';
import { sortBy } from 'utilities/sorting';
import DeliveryStaffChip from 'components/DeliveryStaffChip';
import LinkButton from 'components/Table/LinkButton';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'imageUrl',
    label: '圖片',
    options: {
      filter: false,
      sort: false,
      customBodyRender(imageUrl) {
        return (<React.Fragment>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <img src={imageUrl} style={{ height: 100, width: 'auto', borderRadius: 12 }}/>
          </div>
        </React.Fragment>);
      },
    },
  },
  {
    name: 'isActive',
    label: '狀態',
    options: {
      display: true,
      filter: true,
      sort: true,
      customBodyRender(value) {
        return (
          <DeliveryStaffChip status={value} />
        );
      },
    },
  },
  {
    name: 'sortOrder',
    label: '排序',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'title',
    label: '標題',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'content',
    label: '描述',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'url',
    label: '連結',
    options: {
      filter: false,
      sort: false,
      customBodyRender(url) {
        return (
          <LinkButton
            path={url}
            label="開啟連結"
            newTab={true}
          />
        );
      },
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function ClientAppBannerTable({
  title = 'Banners',
  description,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  useEffect(() => {
    setIndexes([
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listClientAppBanners,
      },
    ]);
    setQueryParams({});
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={EditButton}
      dataSortFunc={sortBy('sortOrder', true)}
    />
  );
}

ClientAppBannerTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
