export default [
  {
    label: '個人',
    value: 'individual',
  },
  {
    label: '企業',
    value: 'enterprise',
  },
  {
    label: '捐贈給社團法人銀色大門老人福利組織',
    value: 'donation',
  },
];
