import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import AutoSizeDialog from 'components/AutoSizeDialog';
import { formatAddress } from 'utilities/format';
import { request } from 'utilities/graph';
import { getTransaction } from 'graphql/queries';
import OrderDetail from './OrderDetail';
import Loading from 'components/Loading';

// const formatInvoice = (invoice) => {
//   if (!invoice) {
//     return '';
//   }
//   switch (invoice.type) {
//     case 'individual':
//       return '個人';
//     case 'enterprise':
//       return '企業';
//     case 'donation':
//       return '捐贈給社團法人銀色大門老人福利組織';
//     default:
//       return '';
//   }
// };

const formatReceiver = (receiver) => {
  if (!receiver) {
    return '';
  }
  let receiverDisplay = `${receiver.name}\n電話：${receiver.phoneNumber}`;
  if (receiver.noteForDelivery) {
    receiverDisplay += `\n備註：${receiver.noteForDelivery}`;
  }
  return receiverDisplay;
};

const formatPayment = (payment) => {
  if (!payment) {
    return '';
  }
  switch (payment) {
    case 'linepay':
      return 'LINE Pay';
    case 'newebpay':
      return '信用卡或網路ATM（藍新金流）';
    default:
      return '';
  }
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  settingTitle: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  settingBlock: {
    border: '1px solid #cecece',
    borderRadius: '10px',
  },
  price: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

export default function CompleteOrderDialog({
  onClose,
  hideCloseButton = false,
  fullScreen = false,
  maxWidth = 'md',
  open,
  transactionId,
}) {
  const classes = useStyles();
  const [mealTotalPrice, setMealTotalPrice] = useState(0);
  const [totalDeliveryStaffFee, setTotalDeliveryStaffFee] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);

  useEffect(() => {
    if (transactionId) {
      (async () => {
        setIsLoading(true);
        const { data: { getTransaction: transaction } } = await request(getTransaction, { id: transactionId });
        setIsLoading(false);
        if (transaction?.orders?.items) {
          const orders = transaction?.orders?.items;
          setOrders(orders);
          if (orders[0]) {
            const { elder } = orders[0];
            setOrderInfo([
              {
                label: '外送地址',
                value: elder ? formatAddress(elder.address) : '',
              },
              {
                label: '取餐人資訊',
                value: elder ? formatReceiver(elder) : '',
              },
              // {
              //   label: '發票類型',
              //   value: invoice?.type ? formatInvoice(invoice) : '',
              // },
              {
                label: '付款方式',
                value: transaction.serviceProvider ? formatPayment(transaction.serviceProvider) : '',
              },
            ]);
          }
        }
      })();
    }
  }, [transactionId]);

  useEffect(() => {
    const { mealTotalPrice, totalDeliveryStaffFee } = orders.reduce((acc, order) => {
      const { mealItems, deliveryStaffFee, extraFee, platformFee } = order;
      acc.mealTotalPrice += mealItems.reduce(( acc, mealItem ) => {
        acc += mealItem.price * mealItem.quantity;
        return acc;
      }, 0);
      acc.totalDeliveryStaffFee += (deliveryStaffFee || 0) + (extraFee || 0) + (platformFee || 0);
      return acc;
    }, { mealTotalPrice: 0, totalDeliveryStaffFee: 0 });
    setMealTotalPrice(mealTotalPrice);
    setTotalDeliveryStaffFee(totalDeliveryStaffFee);
  }, [orders]);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose && onClose();
  };

  return (
    <React.Fragment>
      <AutoSizeDialog
        open={open}
        title='訂單資料'
        onClose={handleClose}
      >
        <DialogContent className={classes.content} dividers>
          {isLoading ?
            <Loading fullScreen={false} /> :
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                  訂單列表
                </Typography>
              </Grid>
              {
                orders.map((order, index) => {
                  return <Grid item xs={12} md={4} key={`order-detail-${index}`}>
                    <OrderDetail
                      order={order}
                    />
                  </Grid>;
                })
              }
              {orderInfo.map(({ label, value, hightlight=false }) => {
                return <Grid container key={label}>
                  <Grid item xs={12}>
                    <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.settingBlock}>
                    <Typography variant="body2" color={hightlight ? 'primary': 'textPrimary'} style={{ whiteSpace: 'pre-line', padding: 4 }}>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>;
              })}
              <Grid item xs={12}>
                <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                  費用詳情
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.price}>
                  <Grid container justifyContent='space-between' style={{ padding: 10 }}>
                    <Typography variant='body1' color='textPrimary'>
                      餐點總金額
                    </Typography>
                    <Typography variant='body1' color='textPrimary'>
                      { mealTotalPrice ? `$${mealTotalPrice}` : '計算中'}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='space-between' style={{ padding: 10 }}>
                    <Typography variant='body1' color='textPrimary'>
                      運費總金額
                    </Typography>
                    <Typography variant='body1' color='textPrimary'>
                      { totalDeliveryStaffFee ? `$${totalDeliveryStaffFee}` : '計算中'}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container justifyContent='space-between' style={{ padding: 10 }}>
                    <Typography variant='body1' color='textPrimary'>
                      總付款金額
                    </Typography>
                    <Typography variant='body1' color='textPrimary' >
                      { mealTotalPrice && totalDeliveryStaffFee ? `$${mealTotalPrice + totalDeliveryStaffFee}` : '計算中'}
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>}
        </DialogContent>
        <Divider />
      </AutoSizeDialog>
    </React.Fragment>
  );
}

CompleteOrderDialog.propTypes = {
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool,
  transactionId: PropTypes.string.isRequired,
};
