import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MealItems({ items }) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {items.map((item, index)=>(
        <ListItem key={index} dense disableGutters divider>
          <ListItemText
            style={{ width: '46%' }}
            primary={item.name}
            secondary={item.note || ''}
          />
          <ListItemText
            style={{ width: '18%' }}
            primary={`數量：${item.quantity}`}
          />
          <ListItemText
            style={{ width: '18%' }}
            primary={`進價：$${item.cost || 0}`}
          />
          <ListItemText
            style={{ width: '18%' }}
            primary={`單價：$${item.price || 0}`}
          />
        </ListItem>
      ))}
    </List>
  );
}

MealItems.propTypes = {
  items: PropTypes.array,
};
