import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatAddress, formatGender } from 'utilities/format';
import InfoCard from './InfoCard';
import { request } from 'utilities/graph';
import { getClient } from 'graphql/queries';
import IndividualClientEditButton from 'forms/IndividualClientForm/IndividualClientEditButton';
import FacilityClientEditButton from 'forms/FacilityClientForm/FacilityClientEditButton';

export default function ClientCard({ id, title = '客戶資料', data: inData, showMap, ...props }) {
  const [data, setData] = useState({});

  const mappings = [
    { key: 'name', label: '姓名' },
    { key: 'email', label: 'Email' },
    { key: 'phoneNumber', label: '電話' },
    { key: 'phoneNumber2', label: '電話2' },
    { key: 'phoneNumber3', label: '電話3' },
    { key: 'address', label: '地址', format: formatAddress, showMap },
  ];

  if (data.type === 'individual') {
    mappings.push({ key: 'gender', label: '性別', format: formatGender, expand: true });
    mappings.push({ key: 'ageGroup', label: '年齡範圍', expand: true });
    mappings.push({ key: 'birthday', label: '生日', expand: true });
    mappings.push({ key: 'jobOccupation', label: '職稱', expand: true });
    mappings.push({ key: 'howDoYouKnowUs', label: '如何得知銀色大門', expand: true });
  }

  useEffect(() => {
    (async () => {
      if (inData) {
        setData(inData);
      } else
      if (id) {
        const { data: { getClient: data } } = await request(getClient, { id });
        setData(data);
      }
    })();
  }, [inData, id]);

  return (
    <InfoCard
      title={title}
      mappings={mappings}
      data={data}
      link={data.id ? `/client/${data.id}` : null}
      editButton={data.type === 'individual' ? IndividualClientEditButton : FacilityClientEditButton}
      onUpdate={setData}
      {...props}
    />
  );
}

ClientCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showMap: PropTypes.bool,
  data: PropTypes.object,
};
