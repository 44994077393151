import React from 'react';

import ILinkOrderTable from 'components/ILinkOrderTable';

export default function ILinkOrders() {
  return (
    <React.Fragment>
      <ILinkOrderTable />
    </React.Fragment>);
}
