import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import FormDialog from 'components/FormDialog';

export default function EditButton({
  title,
  mode = 'edit',
  type = 'icon',
  size = 'small',
  variant = 'text',
  item,
  onUpdate,
  fullScreen = false,
  open: inOpen = false,
  color = 'inherit',
  exitConfirm = false,
  tooltip,
  ...props // form
}) {
  const [open, setOpen] = useState(inOpen);
  const [editItem, setEditItem] = useState();

  const tooltipLabel = tooltip || (mode === 'edit' ? '修改資料' : '新增資料');

  return (
    <React.Fragment>
      {type === 'icon' &&
        <Tooltip title={tooltipLabel}>
          <IconButton
            aria-label={mode}
            size={size}
            onClick={() => {
              if (!open) {
                setOpen(true);
                setEditItem(item);
              }
            }}
          >
            {props.icon ? <props.icon /> :
              (mode ==='edit' ? <EditIcon /> : <AddIcon />)}
          </IconButton>
        </Tooltip>}
      {type !== 'icon' &&
        <Button
          aria-label={mode}
          size={size}
          variant={variant}
          color={color}
          onClick={() => {
            if (!open) {
              setOpen(true);
              setEditItem(item);
            }
          }}
        >
          {mode ==='edit' ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
          新增{title}
        </Button>}
      {open &&
        <FormDialog
          title={title}
          openOnInit={true}
          fullScreen={fullScreen}
          onClose={() => {
            setOpen(false);
            setEditItem();
          }}
          exitConfirm={exitConfirm}
        >
          <props.form
            formData={editItem}
            onComplete={(data) => {
              setOpen(false);
              setEditItem();
              onUpdate && onUpdate(data);
            }}
          />
        </FormDialog>}
    </React.Fragment>
  );
}

EditButton.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  item: PropTypes.object,
  onUpdate: PropTypes.func,
  fullScreen: PropTypes.bool,
  open: PropTypes.bool,
  color: PropTypes.string,
  exitConfirm: PropTypes.bool,
  icon: PropTypes.object,
  tooltip: PropTypes.string,
};
