import React from 'react';

import PandagoOrderTable from 'components/PandagoOrderTable';

export default function PandagoOrders() {
  return (
    <React.Fragment>
      <PandagoOrderTable />
    </React.Fragment>);
}
