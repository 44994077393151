import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { createTag, updateTag } from 'graphql/mutations';
import schema from './schema.js';
import uiSchema from './uiSchema';

export default function TagForm({ ...props }) {
  const createFunc = async (data) => {
    await request(createTag, { input: data });
  };

  const updateFunc = async (data) => {
    await request(updateTag, { input: data });
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

TagForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
