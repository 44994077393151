import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import { Hub } from 'aws-amplify';

import {
  listRestaurants,
  getRestaurant,
  getRestaurantsByCountyByIsActive,
  getRestaurantsByClientByIsActive,
} from 'graphql/queries';

import LinkButton from 'components/Table/LinkButton';
import RestaurantMealGrids from 'components/RestaurantMealGrids';
import { request, asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import cache from 'utilities/cache';

export default function RestaurantMeals({ restaurantId }) {
  const [restaurants, setRestaurants] = useState([]);

  // const openRestaurantWindow = (restaurantId) => {
  //   Hub.dispatch('winbox', { event: 'restaurant', data: { id: restaurantId } });
  // };

  useEffect(() => {
    (async () => {
      if (restaurantId) {
        const { data: { getRestaurant: restaurant } } = await request(getRestaurant, { id: restaurantId });
        setRestaurants([restaurant]);

        return;
      }

      const clientId = cache.get('app:facilityId');

      if (clientId) {
        const restaurants = await asyncListAll(getRestaurantsByClientByIsActive, { clientId });
        setRestaurants(restaurants);
        return;
      }

      const location = cache.get('app:location');

      const restaurants = location ?
        await asyncListAll(getRestaurantsByCountyByIsActive, { county: location }) :
        await asyncListAll(listRestaurants);

      setRestaurants(restaurants.sort(sortBy('name')));
    })();
  }, [restaurantId]);

  return (
    <Grid container spacing={4} style={{ padding: 16, width: '100%' }}>
      {restaurants.map(({ id, name })=>(
        <Grid item xs={12} key={id} >
          {/* <Button onClick={() => openRestaurantWindow(id)}>
          </Button> */}
          {name}
          <LinkButton
            path={`/restaurant/${id}`}
            label={name}
            newTab={true}
          />
          <hr />
          <RestaurantMealGrids restaurantId={id} />
        </Grid>
      ))}
    </Grid>);
}

RestaurantMeals.propTypes = {
  restaurantId: PropTypes.string,
};
