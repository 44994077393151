import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import cache from 'utilities/cache';
import { request } from 'utilities/graph';
import { quoteOrder } from 'graphql/mutations';
import MealItem from './MealItem';
import CompleteOrderDialog from './CompleteOrderDialog';
import querystring from 'query-string';
import { useCache } from 'CacheProvider';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';
import moment from 'moment';
import { TIME_ZONE } from '@silvergatedelivery/constants';

const dayMapping = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday',
];

const TIME_OPTIONS_LUNCH = [
  '10:00-11:00',
  '11:00-12:00',
  '12:00-13:00',
  '13:00-14:00',
];

const TIME_OPTIONS_DINNER = [
  '16:00-17:00',
  '17:00-18:00',
  '18:00-19:00',
];

const Cart = ({ onGoPage }) => {
  const [showCompleteOrderDialog, setShowCompleteOrderDialog] = useState(false);
  const [allDateTime, setAllDateTime] = useState([]);
  const [orders, setOrders] = useState([]);
  const [mealTotalPrice, setMealTotalPrice] = useState(0);
  const [totalDeliveryStaffFee, setTotalDeliveryStaffFee] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [solution, setSolution] = useState();
  const [buttonText, setButtonText] = useState();
  const [init, setInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { appGroup } = useCache();
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [allRestaurantsAvailable, setAllRestaurantsAvailable] = useState(false);

  const quoteOrders = async (orders) => {
    setIsLoading(true);
    const address = cache.get('public:menu2-address');
    const restaurantIds = [];
    orders.forEach(({ restaurantId }) => {
      if (!restaurantIds.includes(restaurantId)) {
        restaurantIds.push(restaurantId);
      }
    });
    const orderQuotation = await Promise.all(restaurantIds.map(async (restaurantId) => {
      const params = {
        input: {
          restaurantId,
          addressCounty: address.county,
          addressZipCode: address.zipCode,
          addressDistrict: address.district,
          addressStreet: address.street,
        },
      };
      const { data: { quoteOrder: { data } } }= await request(quoteOrder, params);
      return {
        restaurantId,
        quoteResult: data,
      };
    }));
    let totalDeliveryStaffFee = 0;
    orders.forEach((order) => {
      const { quoteResult } = orderQuotation.find(({ restaurantId }) => restaurantId === order.restaurantId);
      order.quotation = quoteResult;
      totalDeliveryStaffFee += quoteResult?.deliveryStaffFee || 0;
    });
    setTotalDeliveryStaffFee(totalDeliveryStaffFee);
    setOrders([...orders]);
    cache.set('public:menu2-orders', orders);
    setIsLoading(false);
  };

  const restaurantClosedOnDate = (restaurant, dateToCheck) => {
    const { holidays: holidaysSetting, openingHours = [] } = restaurant;
    const dateItem = moment(dateToCheck).tz(TIME_ZONE);
    const date = dateItem.format('YYYY-MM-DD');
    const day = dateItem.day();

    if (holidaysSetting && holidaysSetting.length !== 0) {
      const year = dateItem.year();
      const holidays = holidaysSetting.find(
        (restaurantHoliday) => restaurantHoliday.year === year,
      );
      if (holidays && holidays.closedDays.length !== 0) {
        if (holidays.closedDays.includes(date)) {
          return true;
        }
      }
    }
    if (openingHours) {
      if (Object.keys(openingHours).every((key) => openingHours[key].length === 0)) {
        return false;
      }
      if (openingHours[dayMapping[day]].length === 0) {
        return true;
      }
    }
    return false;
  };

  const timeInSlot = (startTime, endTime, toCheck) => {
    const toMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };
    return toMinutes(toCheck) >= toMinutes(startTime) && toMinutes(toCheck) <= toMinutes(endTime);
  };

  const getTimeSlotOptions = (restaurant, date, defaultTimeSlotOptions) => {
    let filteredOptions = defaultTimeSlotOptions;
    if (!restaurant || !restaurant.openingHours ||
      Object.keys(restaurant.openingHours).every((key) => restaurant.openingHours[key].length === 0)) {
      return filteredOptions;
    }
    const day = moment(date).tz(TIME_ZONE).day();

    if (restaurant.openingHours[dayMapping[day]].length === 0) {
      return [];
    } else {
      filteredOptions = defaultTimeSlotOptions.filter((option) => {
        const [slotStart, slotEnd] = option.split('-');
        if (restaurant.openingHours[dayMapping[day]].some((openingHours)=> {
          if (timeInSlot(openingHours.startTime, openingHours.endTime, slotStart) ||
            timeInSlot(openingHours.startTime, openingHours.endTime, slotEnd)) {
            return true;
          }
          return false;
        })) {
          return true;
        }
        return false;
      });
    }

    return filteredOptions;
  };

  const checkRestaurantClosed = (dateTime, restaurant) => {
    const restaurantClosedOnDateSelected = restaurantClosedOnDate(restaurant, dateTime.date);
    const lunchTimeSlotOptions = getTimeSlotOptions(restaurant, dateTime.date, TIME_OPTIONS_LUNCH);
    const dinnerTimeSlotOptions = getTimeSlotOptions(restaurant, dateTime.date, TIME_OPTIONS_DINNER);
    const restaurantClosedAtTimeSlotSelected = !lunchTimeSlotOptions.includes(dateTime.time) && !dinnerTimeSlotOptions.includes(dateTime.time);
    return restaurantClosedOnDateSelected || restaurantClosedAtTimeSlotSelected;
  };

  const rearrangeMeals = (addedMeals, allDateTime) => {
    // 讓鄰近兩餐點儘量不要一樣
    const result = [];
    let index = 0;

    while (addedMeals.some((addedMeal) => addedMeal.quantity > 0)) {
      const addedMeal = addedMeals[index % addedMeals.length];
      const restaurantClosed = checkRestaurantClosed(allDateTime[index], addedMeal.meal.restaurant);
      if (addedMeal.quantity > 0) {
        result.push({
          ...addedMeal.meal,
          restaurantClosed,
          dateTime: allDateTime[index],
        });
        addedMeal.quantity -= 1;
      }
      index++;
    }

    return result;
  };

  const buildOrders = () => {
    const addedMeals = cache.get('public:menu2-meals');
    if (addedMeals?.length) {
      const { days = [] } = cache.get('public:menu2-date') || {};
      const time = cache.get('public:menu2-time');
      const allDateTime = [];
      days.forEach((date) => {
        if (time.lunch !== '不需要午餐') {
          allDateTime.push({ date, time: time.lunch, mealSlot: 'lunch' });
        }
        if (time.dinner !== '不需要晚餐') {
          allDateTime.push({ date, time: time.dinner, mealSlot: 'dinner' });
        }
      });
      setAllDateTime(allDateTime);

      const mealCount = addedMeals.reduce((sum, meal) => sum + meal.quantity, 0);
      // TODO: 先假設一餐一餐點
      if (allDateTime.length !== mealCount) {
        setErrorMsg('餐點數量需要調整');
        setSolution(`您點了${mealCount}份餐點，但是總共需要${allDateTime.length}份餐點，請點擊下方綠色按鈕，回到首頁調整餐點數量或送餐日期與時間`);
        setButtonText('回首頁設定');
        return;
      }

      const arrangedMeals = rearrangeMeals(addedMeals, allDateTime);
      const orders = cache.get('public:menu2-orders') || [];
      if (!orders.length) {
        arrangedMeals.forEach((meal, index) => {
          const { restaurantId, dateTime, restaurantClosed } = meal;
          orders.push({
            restaurantId,
            dateTime,
            mealItems: [{ ...meal, quantity: 1 }],
            restaurantClosed,
          });
        });
      }
      setOrders(orders);
      cache.set('public:menu2-orders', orders);
      setAllRestaurantsAvailable(orders.every(({ restaurantClosed }) => !restaurantClosed));
      // quote order 很慢，不要await
      quoteOrders(orders);

      const mealTotalPrice = orders.reduce((acc, order) => {
        return acc + order.mealItems.reduce((acc, { price, quantity }) => {
          return acc + price * quantity;
        }, 0);
      }, 0);
      setMealTotalPrice(mealTotalPrice);
    }
  };

  const orderDataValidator = () => {
    const addedMeals = cache.get('public:menu2-meals');
    if (!addedMeals?.length) {
      setErrorMsg('購物車空空的');
      setSolution('快點擊下方綠色按鈕，探索健康營養的美食！我們會將餐點外送到您的大門口');
      setButtonText('立即前往訂餐');
      return false;
    }
    const { days = [] } = cache.get('public:menu2-date') || {};
    if (days.length === 0) {
      setErrorMsg('尚未設定送餐日期');
      setSolution('請點擊下方綠色按鈕，回到首頁上方設定送餐日期');
      setButtonText('回首頁設定');
      return false;
    }
    const time = cache.get('public:menu2-time');
    if (!time || (time.lunch === '不需要午餐' && time.dinner === '不需要晚餐')) {
      setErrorMsg('尚未設定送餐時間');
      setSolution('請點擊下方綠色按鈕，回到首頁上方設定送餐時間');
      setButtonText('回首頁設定');
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!orderDataValidator()) {
      setInit(true);
      return;
    }

    const { transactionId } = querystring.parse(window.location.search);
    const orders = cache.get('public:menu2-orders');
    if (transactionId && orders) {
      setOrders(orders);
      setAllRestaurantsAvailable(true);
      setShowCompleteOrderDialog(true);
    } else {
      buildOrders();
    }

    setInit(true);
  }, []);

  useEffect(() => {
    // 關閉 completeOrderDialog 有可能是完成訂單了
    if (!showCompleteOrderDialog) {
      orderDataValidator();
    }
  }, [showCompleteOrderDialog]);

  if (!init) {
    return <></>;
  }

  if (errorMsg) {
    return (
      <Grid container spacing={2} style={{ padding: 16 }} justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            {errorMsg}
          </Typography>
          <Typography variant='subtitle1' color='textPrimary' align='center'>
            {solution}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} style={{ margin: 16, padding: 16 }} container justifyContent='center' alignItems='center'>
          <Button variant='contained'
            onClick={() => {
              onGoPage && onGoPage('meals');
            }}
            style={{
              width: '80%',
              borderRadius: '20px',
              backgroundColor: '#00913A',
              color: 'white',
            }}>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    );
  }

  const onOrderChecked = (index, value) => {
    if (value && ordersSelected.length >= 2) {
      return;
    }
    let newOrdersSelected = [...ordersSelected];
    if (value) {
      newOrdersSelected.push(index);
    } else {
      newOrdersSelected = newOrdersSelected.filter((orderIndex) => orderIndex !== index);
    }
    setOrdersSelected(newOrdersSelected);
  };

  const swapMeals = () => {
    if (ordersSelected.length !== 2) {
      return;
    }
    const tempOrder = orders[ordersSelected[0]];
    orders[ordersSelected[0]] = orders[ordersSelected[1]];
    orders[ordersSelected[1]] = tempOrder;
    const tempDateTime = orders[ordersSelected[0]].dateTime;
    orders[ordersSelected[0]].dateTime = orders[ordersSelected[1]].dateTime;
    orders[ordersSelected[1]].dateTime = tempDateTime;

    orders[ordersSelected[0]].restaurantClosed =
      checkRestaurantClosed(orders[ordersSelected[0]].dateTime, orders[ordersSelected[0]].mealItems[0].restaurant);
    orders[ordersSelected[1]].restaurantClosed =
      checkRestaurantClosed(orders[ordersSelected[1]].dateTime, orders[ordersSelected[1]].mealItems[0].restaurant);
    setOrders([...orders]);
    setAllRestaurantsAvailable(orders.every(({ restaurantClosed }) => !restaurantClosed));
    setOrdersSelected([]);
    cache.set('public:menu2-orders', orders);
  };

  const onOrderUpdate = (index, order) => {
    Object.assign(orders[index], order);
    setOrders([...orders]);
    cache.set('public:menu2-orders', orders);
  };

  return (
    <>
      <Grid container spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            購物車
          </Typography>
          {ordersSelected.length >= 2 &&
          <Button
            variant='contained'
            style={{
              borderRadius: '20px',
              color: 'white',
            }}
            color="primary"
            onClick={swapMeals}
          >
            交換餐點
          </Button>}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {
            allDateTime.map(({ date, time, mealSlot }, index) => {
              return orders && orders[index] && <Grid item xs={12} md={4} key={`${date}-${time}-${index}`}>
                <MealItem
                  date={date}
                  time={time}
                  mealSlot={mealSlot}
                  order={orders[index]}
                  onChecked={(value) => onOrderChecked(index, value)}
                  disableCheck={ordersSelected.length >= 2 && !ordersSelected.includes(index)}
                  checked={ordersSelected.includes(index)}
                  onOrderUpdate={(value) => onOrderUpdate(index, value)}
                />
              </Grid>;
            })
          }
        </Grid>
        <Grid item xs = {6} container justifyContent="space-between">
          <Grid item xs={8}>
            <Typography gutterBottom variant="body1" color="textPrimary">
              餐點總金額
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography gutterBottom variant="body2" color="textSecondary">
              ${mealTotalPrice}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom variant="body1" color="textPrimary">
              運費總金額
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography gutterBottom variant="body2" color="textSecondary">
              {totalDeliveryStaffFee !== undefined ? `$${totalDeliveryStaffFee}` : '計算中'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {appGroup !== undefined ?
            <Button
              variant='contained'
              type='submit'
              style={{
                width: '80%',
                borderRadius: '20px',
                color: 'white',
              }}
              color="primary"
              onClick={() => {
                setShowCompleteOrderDialog(true);
              }}
              disabled={isLoading || totalDeliveryStaffFee === undefined || !allRestaurantsAvailable}
            >
              前往結賬
            </Button> :
            <CustomAuthDialog
              mode= 'signIn'
              withButton={false}
              button={(props) => (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: '80%',
                    borderRadius: '20px',
                    color: 'white',
                  }}
                  disabled={isLoading || totalDeliveryStaffFee === undefined || !allRestaurantsAvailable}
                  {...props}
                >
                  登入後結帳
                </Button>
              )}
            />}
        </Grid>
      </Grid>
      {orders && appGroup !== undefined &&
      <CompleteOrderDialog
        open={showCompleteOrderDialog}
        orders={orders}
        fullScreen
        onClose={() => {
          setShowCompleteOrderDialog(false);
        }}
      />}
    </>
  );
};

export default Cart;

Cart.propTypes = {
  onGoPage: PropTypes.func,
};
