import React, { useEffect, useState } from 'react';
import querystring from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

export const apps = {
  client: {
    name: '銀色大門',
    ios: 'https://apps.apple.com/app/id1574458514',
    android: 'https://play.google.com/store/apps/details?id=com.silvergatedelivery.client',
    logo: '/logos/client_logo_256.png',
  },
  deliveryStaff: {
    name: '銀色大門 - 送餐大使端',
    ios: 'https://apps.apple.com/app/id1559972257',
    android: 'https://play.google.com/store/apps/details?id=com.silvergatedelivery.driver',
    logo: '/logos/deliveryStaff_logo_256.png',
  },
  restaurant: {
    name: '銀色大門 - 餐廳端',
    ios: 'https://apps.apple.com/app/id1574537816',
    android: 'https://play.google.com/store/apps/details?id=com.silvergatedelivery.restaurant',
    logo: '/logos/restaurant_logo_256.png',
  },
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    backgroundColor: '#fff',
  },
}));

export default function Download() {
  const classes = useStyles();
  const [selectedApps, setSeletedApps] = useState([]);

  useEffect(() => {
    const { app: appName } = querystring.parse(window.location.search);
    const matched = apps[appName];

    global.logger.debug('navigator.userAgent', navigator.userAgent, apps, matched);

    if (!matched) {
      setSeletedApps(Object.values(apps));
      return;
    }

    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      window.open(matched.ios, '_self');
    } else
    if (navigator.userAgent.match(/android|Android/i)) {
      window.open(matched.android, '_self');
    } else {
      setSeletedApps([matched]);
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Grid container alignItems="center" justifyContent="center" direction="column">
        {selectedApps.map((app, index)=>(
          <Grid item key={index} xs={12} sm={6} className={classes.content}>
            <Box component="div" p={4}>
              <img src={app.logo || '/logos/logo_256.png'} alt="Logo" width="256" />
            </Box>

            <Grid container justifyContent="space-around" alignItems="center">
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                startIcon={<AndroidIcon />}
                href={app.android}
              >
                安卓版本下載
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                startIcon={<AppleIcon />}
                href={app.ios}
              >
                蘋果版本下載
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
