import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { queryPublicOrder } from 'graphql/mutations';
import { request } from 'utilities/graph';
import { Colors } from '@silvergatedelivery/constants';

import PublicOrderCard from './PublicOrderCard';
import PublicDeliveryStaffOrderCard from './PublicDeliveryStaffOrderCard';
import OrderStatusProgress from './OrderStatusProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    flex: 1,
    backgroundColor: Colors.background.light,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  space: {
    height: theme.spacing(2),
  },
}));

export default function PublicOrder({ id }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [invalidOrder, setInvalidOrder] = useState(false);

  useEffect(() => {
    const getInfo = async () => {
      const { data: { queryPublicOrder: result } } = await request(queryPublicOrder, {
        input: { orderId: id },
      }, 'API_KEY');

      if (result.message === 'success') {
        setData(JSON.parse(result.data));
      } else {
        setInvalidOrder(true);
      }
    };
    getInfo();
  }, [id, lastUpdatedAt]);

  if (invalidOrder) {
    return (
      <Typography variant="h6" className={classes.title}>
        訂單不存在
      </Typography>);
  }

  if (!data) return null;

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container>
          <OrderStatusProgress
            status={data.status}
            onClick={() => {
              setLastUpdatedAt(Date.now());
            }}
          />
        </Grid>
        { data.deliveryStaffOrder && (
          <React.Fragment>
            <div className={classes.space} />
            <Grid item xs={12}>
              <PublicDeliveryStaffOrderCard data={data.deliveryStaffOrder} />
            </Grid>
          </React.Fragment>)}
        <div className={classes.space} />
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <PublicOrderCard
              data={data}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

PublicOrder.propTypes = {
  id: PropTypes.string.isRequired,
};
