import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { asyncListAll, request } from 'utilities/graph';
import { Storage } from 'aws-amplify';
import DeliveryStaffOrderDiaryCard from './DeliveryStaffOrderDiaryCard';
import cache from 'utilities/cache';
import { Semaphore } from 'async-mutex';
import Loading from 'components/Loading';
import { useCache } from 'CacheProvider';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';
import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import {
  getDeliveryStaffOrdersByClientByDate,
} from './queries';

const DEFAULT_IMAGE_URL = '/images/default_delivery_diary.png';

const Diary = ({ onGoPage }) => {
  const [deliveryStaffOrders, setDeliveryStaffOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { appGroup } = useCache();

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:clientId');
      if (!clientId) {
        return;
      }
      const from = moment().tz(TIME_ZONE).add(-60, 'days').toISOString();
      const to = moment().toISOString();
      const deliveryStaffOrders = await asyncListAll(getDeliveryStaffOrdersByClientByDate, {
        clientId,
        deliveryBy: {
          between: [from, to],
        },
        filter: {
          status: { eq: 'completed' },
        },
      });

      const s = new Semaphore(10);
      await Promise.all(deliveryStaffOrders.map((deliveryStaffOrder) =>
        s.runExclusive(async () => {
          if (!deliveryStaffOrder.order) {
            try {
              const { data: { getOrder: order } } = await request( /* GraphQL */ `
                query GetOrder($id: ID!) {
                  getOrder(id: $id) {
                    county
                    category
                    elder {
                      name
                      nickname
                    }
                  }
                }`, { id: deliveryStaffOrder.orderId });
              deliveryStaffOrder.order = order;
            } catch (e) {
              console.log(e);
            }
          }

          deliveryStaffOrder.photoS3Keys = deliveryStaffOrder.photoS3Keys || [];

          if (deliveryStaffOrder.photoS3Keys[0] && deliveryStaffOrder.thumbnail !== DEFAULT_IMAGE_URL) {
            deliveryStaffOrder.thumbnail = await Storage.get(deliveryStaffOrder.photoS3Keys[0]);
          } else {
            deliveryStaffOrder.thumbnail = DEFAULT_IMAGE_URL;
          }
          return deliveryStaffOrder;
        }),
      ));

      global.logger.debug(deliveryStaffOrders);
      setDeliveryStaffOrders(deliveryStaffOrders.reverse());
      setIsLoading(false);
    })();
  }, []);

  if (!appGroup) {
    return (
      <Grid container spacing={2} style={{ padding: 16 }} justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            請先登入或註冊帳號
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} container justifyContent="center" style={{ paddingTop: 32 }}>
          <CustomAuthDialog
            mode= 'signIn'
            withButton={false}
            button={(props) => (
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '80%',
                  borderRadius: '20px',
                  color: 'white',
                }}
                {...props}
              >
                登入
              </Button>
            )}
          />
          &nbsp;
          <CustomAuthDialog
            mode= 'signUp'
            withButton={false}
            button={(props) => (
              <Button
                variant="text"
                color="primary"
                size="large"
                {...props}
              >
                還不是會員？點此註冊
              </Button>
            )}
          />
        </Grid>
      </Grid>
    );
  }

  if (isLoading) {
    return <Loading fullScreen={false} />;
  }

  if (deliveryStaffOrders.length === 0) {
    return (
      <Grid container spacing={2} style={{ padding: 16 }} justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            尚無關懷日誌
          </Typography>
          <Typography variant='subtitle1' color='textPrimary' align='center'>
            快點擊下方綠色按鈕，探索健康營養的美食！我們會將餐點外送到您的大門口
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} style={{ margin: 16, padding: 16 }} container justifyContent='center' alignItems='center'>
          <Button variant='contained'
            onClick={() => {
              onGoPage && onGoPage('meals');
            }}
            style={{
              width: '80%',
              borderRadius: '20px',
              backgroundColor: '#00913A',
              color: 'white',
            }}>
            立即前往訂餐
          </Button>
        </Grid>
      </Grid>
    );
  }


  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            關懷日誌
          </Typography>
        </Grid>
        {deliveryStaffOrders.map((deliveryStaffOrder, index)=> (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <DeliveryStaffOrderDiaryCard
              deliveryStaffOrder={deliveryStaffOrder}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Diary;

Diary.propTypes = {
  onGoPage: PropTypes.func,
};
