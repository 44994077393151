import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';
import { tag as allTags } from '@silvergatedelivery/constants';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import {
  getRestaurant,
  getRestaurantOwner,
  getRestaurantHolidayByRestaurantByYear,
} from 'graphql/queries';
import {
  createRestaurant,
  updateRestaurant,
  createTag,
  createRestaurantTag,
  deleteRestaurantTag,
  createRestaurantHoliday,
  updateRestaurantHoliday,
} from 'graphql/mutations';
import uiSchema from './uiSchema';
import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';
import ImageGalleryUploader from 'components/ImageGalleryUploader';
import cache from 'utilities/cache';
import { asyncListAll } from 'utilities/graph';
import { TIME_ZONE, MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from '@silvergatedelivery/constants';
import AreaSelection from '../AreaSelection';
import { getClientIdSchema } from 'forms/schemas';
import { resetFormCache } from 'forms/formCache';
import { useCache } from 'CacheProvider';

function arraysContainSameElements(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}

export default function RestaurantForm({ formData: inFormData, ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const [restaurantId, setRestaurantId] = useState();
  const [tags, setTags] = useState({ items: [] });
  const [newTagMappings, setNewTagMappings] = useState({});
  const [currentImageS3Keys, setCurrentImageS3Keys] = useState([]);
  const [newImageS3Keys, setNewImageS3Keys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [serviceAreas, setServiceAreas] = useState([]);
  const [restaurantHolidays, setRestaurantHolidays] = useState([]);
  const { appGroup } = useCache();

  useEffect(() => {
    if (restaurantId) {
      (async () => {
        const start = moment().tz(TIME_ZONE).year();
        const end = start + 2;
        const holidays = await asyncListAll(getRestaurantHolidayByRestaurantByYear, {
          restaurantId,
          year: {
            between: [start, end],
          },
        });
        const newSelectedDays = [];
        holidays.forEach(({ year, closedDays }) => {
          closedDays.forEach((closedDay) => {
            const dayItem = moment(closedDay);
            newSelectedDays.push(dayItem.toDate());
          });
        });
        setRestaurantHolidays(holidays);
        setSelectedDays(newSelectedDays);
      })();
    }
  }, [restaurantId]);

  const onChangeTags = (key, tags) => {
    newTagMappings[key] = tags;
    setNewTagMappings({ ...newTagMappings });
  };

  const handleTags = async (restaurantId) => {
    const now = moment().toISOString();
    const username = cache.get('app:username');

    const toRemoveJoinData = [];
    tags.items.forEach(({ id, tagId, tag }) => {
      const { subcategory } = tag;
      const matched = newTagMappings[subcategory].find(({ id }) => id === tagId);
      if (!matched) {
        toRemoveJoinData.push({ id });
      }
    });

    await Promise.all([
      ...Object.keys(newTagMappings).map((subcategory) => {
        return Promise.all(newTagMappings[subcategory].map(async (item) => {
          let tag = item;

          if (!tag.id) {
            const tagData = {
              category: '餐廳',
              subcategory,
              label: tag.label,
              createdAt: now,
              createdBy: username,
              updatedAt: now,
              updatedBy: username,
            };
            const { data: { createTag: createdTag } } = await request(createTag, { input: tagData });
            tag = createdTag;
          }

          const matched = tags.items.find(({ tagId }) => tagId === tag.id);
          if (!matched) {
            const joinData = {
              tagId: tag.id,
              restaurantId,
              createdBy: username,
              updatedBy: username,
            };

            await request(createRestaurantTag, { input: joinData });
          }
        }));
      }),
      ...toRemoveJoinData.map((item) => {
        return request(deleteRestaurantTag, { input: item });
      }),
    ]);
  };

  const handleHolidays = async (restaurantId) => {
    const now = moment().toISOString();
    const username = cache.get('app:username');

    const newRestaurantHolidays = [];
    selectedDays.forEach((day) => {
      const dayItem = moment(day);
      const year = dayItem.year();
      const date = dayItem.format('YYYY-MM-DD');
      let item = newRestaurantHolidays.find((holiday) => holiday.year === year);
      if (!item) {
        const length = newRestaurantHolidays.push({
          year,
          closedDays: [],
        });
        item = newRestaurantHolidays[length - 1];
      }
      item.closedDays.push(date);
    });

    const toCreateHolidays = [];
    const toUpdateHolidays = [];
    newRestaurantHolidays.forEach(async (holiday) => {
      const existingHoliday = restaurantHolidays.find(
        (restaurantHoliday) => restaurantHoliday.year === holiday.year);
      if (existingHoliday) {
        if (!arraysContainSameElements(existingHoliday.closedDays, holiday.closedDays)) {
          const input = Object.assign({}, existingHoliday, {
            updatedAt: now,
            updatedBy: username,
            ...holiday,
          });
          toUpdateHolidays.push(await request(updateRestaurantHoliday, { input }));
        }
      } else {
        const input = {
          id: uuidv1(),
          restaurantId,
          createdAt: now,
          createdBy: username,
          updatedAt: now,
          updatedBy: username,
          expirationUnixTime: Math.round(Date.now() / 1000) + 365 * 86400,
          ...holiday,
        };
        toCreateHolidays.push(await request(createRestaurantHoliday, { input }));
      }
    });
    await Promise.all([...toCreateHolidays, ...toUpdateHolidays]);
  };

  const getRestaurantOwnerUsername = async (inRestaurantOwnerId) => {
    if (!inRestaurantOwnerId) return 'N/A';

    const { data: { getRestaurantOwner: data } } = await request(getRestaurantOwner, { id: inRestaurantOwnerId });

    return data ? data.username : 'N/A';
  };

  const onComplete = async (restaurantId) => {
    if (props.onComplete) {
      const { data: { getRestaurant: data } } = await request(getRestaurant, { id: restaurantId });

      props.onComplete(data);
    }
  };

  const createFunc = async (data) => {
    setIsLoading(true);

    data.id = restaurantId;
    data.county = data.address.county;
    data.imageS3Keys = newImageS3Keys;
    data.owner = await getRestaurantOwnerUsername(data.restaurantOwnerId);
    data.serviceAreas = serviceAreas;

    const clientId = cache.get('app:facilityId');
    if (clientId) {
      data.clientId = clientId;
    }

    if (!data.restaurantOwnerId) {
      delete data.restaurantOwnerId;
    }

    delete data.tags;

    await request(createRestaurant, { input: data });

    await handleTags(restaurantId);

    await handleHolidays(restaurantId);

    await onComplete(restaurantId);

    setIsLoading(false);
    resetFormCache('allRestaurants');
  };

  const updateFunc = async (data) => {
    setIsLoading(true);

    data.county = data.address.county;
    data.imageS3Keys = newImageS3Keys;
    data.owner = await getRestaurantOwnerUsername(data.restaurantOwnerId);
    data.serviceAreas = serviceAreas;

    delete data.tags;

    await request(updateRestaurant, { input: data });

    await handleTags(restaurantId);

    await handleHolidays(restaurantId);

    await onComplete(restaurantId);

    setIsLoading(false);
    resetFormCache('allRestaurants');
  };

  useEffect(() => {
    console.log(inFormData);
    if (inFormData && inFormData.id && inFormData.tags) {
      setTags(inFormData.tags);
      setNewTagMappings(inFormData.tags.items.reduce((obj, { tag }) => {
        obj[tag.subcategory] = obj[tag.subcategory] || [];
        obj[tag.subcategory].push(tag);
        return obj;
      }, {}));
      setCurrentImageS3Keys(inFormData.imageS3Keys || []);
      setNewImageS3Keys(inFormData.imageS3Keys || []);
      setRestaurantId(inFormData.id);
      setServiceAreas(inFormData.serviceAreas || []);
    } else {
      setRestaurantId(uuidv1());
    }
  }, [inFormData]);

  const handleDayClick = (day, { selected }) => {
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    setSelectedDays([...selectedDays]);
  };

  const serviceAreaChanged = (newAreas = []) => {
    setServiceAreas(newAreas);
  };

  global.logger.debug('re-render');

  return (
    <div>
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        formData={inFormData}
        dirty={true}
        extMappings={[{
          key: 'clientId',
          func: (clientId) => {
            const adminMode = appGroup === 'Admins';
            return getClientIdSchema(clientId, '所屬機構', false, adminMode, false);
          },
        }]}
        {...props}
      >
        <Typography variant="h5" style={{ marginTop: 16 }}>
          公休日
        </Typography>
        <Divider />
        <Typography variant="body2" style={{ marginTop: 16 }}>
          在上述設定營業時間中如有休假，請設定公休日
        </Typography>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <DayPicker
            selectedDays={selectedDays}
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            onDayClick={handleDayClick}
            showWeekNumbers
            showOutsideDays
            disabledDays={{ before: new Date() }}
            style={{ width: '100%' }}
          />
        </Grid>
        <Typography variant="h5" style={{ marginTop: 16 }}>
          服務範圍
        </Typography>
        <Divider />
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <AreaSelection
            areas={serviceAreas}
            onChange={serviceAreaChanged}
            description='請選擇服務縣市，然後點選服務區域與向右按鈕移動到右方框格裏'
          />
        </Grid>

        <Typography variant="h5" style={{ marginTop: 16 }}>
          標籤
        </Typography>
        <Divider />
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          {
            Object.keys(allTags['餐廳']).map((key)=>(
              <Grid item xs={12} key={key}>
                <DataJoinEditorInput
                  title={`${key}標籤`}
                  mode={`餐廳-${key}`}
                  joinData={tags ? tags.items.filter(({ tag }) => tag.subcategory === key) : []}
                  defaultValues={tags ? tags.items.filter(({ tag }) => tag.subcategory === key).map(({ tag }) => tag.label) : []}
                  onChange={(newTags) => onChangeTags(key, newTags)}
                  onUpdateOptions={() => {}}
                  disabled={isLoading}
                  showHelperText={false}
                />
              </Grid>
            ))
          }
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <ImageGalleryUploader
            s3Prefix={`restaurants/${restaurantId}/images`}
            s3Keys={currentImageS3Keys}
            onUpdateS3Keys={setNewImageS3Keys}
          />
        </Grid>
      </DataForm>
    </div>
  );
}

RestaurantForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
