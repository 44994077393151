import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/Table/DataTable';
import {
  getDeliveryStaffsByClientIdByIsActive,
} from 'graphql/queries';
import { formatAddress } from 'utilities/format';
import UserAvatar from 'components/UserAvatar';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';
import { getClientIdSchema } from 'forms/schemas';

export default function DeliveryStaffTable({
  title = i18n.t('送餐大使'),
  description,
  organizationId: inOrganizationId,
  clientId: inClientId,
  ...props
}) {
  const { t } = useTranslation();
  const [listQueries, setListQueries] = useState();
  const { selectedUserGroupParams } = useCache();
  const [clientOptions, setClientOptions] = useState();

  const organizationId = inOrganizationId || cache.get('app:organizationId');
  const clientId = inClientId || cache.get('app:facilityId');

  useEffect(() => {
    (async () => {
      const [
        clientOptions,
      ] = await Promise.all([
        getClientIdSchema(null, '所屬機構', true),
      ]);
      setClientOptions(clientOptions);
    })();
  }, []);

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'clientId',
      label: '機構',
      options: {
        filter: true,
        sort: true,
        customBodyRender(clientId) {
          if (clientOptions) {
            return (clientOptions.find(({ id }) => id === clientId) || {}).name || '';
          }
          return '';
        },
      },
    },
    {
      name: 'name',
      label: '名字',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(name, tableMeta) {
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              return name[0] + `姓${t('送餐大使')}`;
            }
          }
          return name;
        },
      },
    },
    {
      name: 'username',
      label: '頭像',
      options: {
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <UserAvatar
              username={username}
            />
          );
        },
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        filter: false,
        sort: true,
        customBodyRender(phone, tableMeta) {
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              const prefix = phone.slice(0, 3);
              const maskedPart = phone.slice(3).replace(/./g, '*');
              return prefix + maskedPart;
            }
          }
          return phone;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (email, tableMeta) => {
          let value = email;
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              const prefix = email.slice(0, 3);
              const maskedPart = email.slice(3).replace(/./g, '*');
              value = prefix + maskedPart;
            }
          }
          return (
            <div style={{ wordBreak: 'break-all' }}>
              {value}
            </div>
          );
        },
      },
    },
    {
      name: 'address',
      label: '地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (address, tableMeta) => {
          let value = formatAddress(address);
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              const prefix = value.slice(0, 7);
              const maskedPart = value.slice(7).replace(/./g, '*');
              value = prefix + maskedPart;
            }
          }
          return value;
        },
      },
    },
  ];

  useEffect(() => {
    if (organizationId) {
      const { organizationClientIds } = selectedUserGroupParams;

      setListQueries(organizationClientIds.map((clientId) => {
        return {
          name: getDeliveryStaffsByClientIdByIsActive,
          params: {
            clientId,
            isActive: { eq: 1 },
          },
        };
      }));
    } else
    if (clientId) {
      setListQueries([{
        name: getDeliveryStaffsByClientIdByIsActive,
        params: {
          clientId,
          isActive: { eq: 1 },
        },
      }]);
    }
  }, [organizationId, clientId]);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        listQueries={listQueries}
        editButton={undefined}
        {...props}
      />
    </React.Fragment>
  );
}

DeliveryStaffTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  organizationId: PropTypes.string,
  clientId: PropTypes.string,
};
