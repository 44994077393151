import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import amplifyConfig from '../aws-exports';
import appConfig from '../../package.json';

const version = appConfig.version;
const envName = amplifyConfig.aws_cloud_logic_custom[0].endpoint.split('/').pop();

const isPrd = envName === 'production' || process.env.NODE_ENV !== 'development';

const env = isPrd ? '' : envName;

const versionString = `v${version} ${env ? `(${env})` : ''}`;

export default function Version({
  component = 'p',
  variant = 'body1',
  color = 'textSecondary',
  align = 'center',
  noWrap = true,
}) {
  return (
    <Typography component={component} variant={variant} color={color} noWrap={noWrap} align={align}>
      {versionString}
    </Typography>
  );
}

Version.propTypes = {
  component: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  noWrap: PropTypes.string,
};
