import React from 'react';

import UberOrderTable from 'components/UberOrderTable';

export default function UberOrders() {
  return (
    <React.Fragment>
      <UberOrderTable />
    </React.Fragment>);
}
