import i18n from 'i18next';
import { counties } from '@silvergatedelivery/constants';
import { getTranslatedOrderStatus } from 'utilities/translate';

export const getChartDataMappings = (data = {}) => {
  const {
    orders,
    // prevOrders,
    // newOrders,
    // prevNewOrders,
    issues,
    prevIssues,
    newDeliveryStaffs,
    prevNewDeliveryStaffs,
    // newClients,
    // prevNewClients,
    newElders,
    prevNewElders,
  } = data;

  global.logger.debug('orders', orders);
  global.logger.debug('issues', issues);

  const issueOrderIds = [];
  issues.forEach(({ orderId }) => {
    if (orderId && !issueOrderIds.includes(orderId)) {
      issueOrderIds.push(orderId);
    }
  });

  const prevIssueOrderIds = [];
  prevIssues.forEach(({ orderId }) => {
    if (orderId && !prevIssueOrderIds.includes(orderId)) {
      prevIssueOrderIds.push(orderId);
    }
  });

  const lunchOrders = orders.filter(({ mealSlot }) => mealSlot === 'lunch');
  const dinnerOrders = orders.filter(({ mealSlot }) => mealSlot === 'dinner');

  const disadvantagedElders = newElders.filter(({ isDisadvantaged }) => isDisadvantaged);
  const prevDisadvantagedElders = prevNewElders.filter(({ isDisadvantaged }) => isDisadvantaged);
  const otherElders = newElders.filter(({ isDisadvantaged }) => !isDisadvantaged);
  const prevOtherElders = prevNewElders.filter(({ isDisadvantaged }) => !isDisadvantaged);
  const translatedOrderStatus = getTranslatedOrderStatus();
  // process data
  const chartDataMappings = {
    totalOrders: {
      type: 'summary',
      width: 3,
      title: '訂單數',
      value: orders.length,
      // prevValue: prevOrders.length,
    },
    totalOrdersValue: {
      type: 'summary',
      width: 3,
      title: '訂單總金額',
      unit: '$',
      value: orders.reduce((num, { total }) => {
        return num + total;
      }, 0),
      // prevValue: prevOrders.reduce((num, { total }) => {
      //   return num + total;
      // }, 0),
    },
    orderIssueRatio: {
      type: 'summary',
      width: 3,
      title: '訂單事故比率',
      appendix: '%',
      value: Math.round((orders.length > 0 ? issueOrderIds.length / orders.length : 0) * 100),
      // prevValue: Math.round((prevOrders.length > 0 ? prevIssueOrderIds.length / prevOrders.length : 0) * 100),
    },
    totalIssues: {
      type: 'summary',
      width: 3,
      title: '應變中心事故數',
      value: issues.length,
      // prevValue: prevIssues.length,
    },
    // totalNewOrders: {
    //   type: 'summary',
    //   width: 3,
    //   title: '新進訂單',
    //   value: newOrders.length,
    //   prevValue: prevNewOrders.length,
    // },
    totalNewDisadvantagedElders: {
      type: 'summary',
      width: 4,
      title: `新進${i18n.t('送餐對象')}(弱勢)`,
      value: disadvantagedElders.length,
      prevValue: prevDisadvantagedElders.length,
    },
    totalNewOtherElders: {
      type: 'summary',
      width: 4,
      title: `新進${i18n.t('送餐對象')}(自費)`,
      value: otherElders.length,
      prevValue: prevOtherElders.length,
    },
    // totalNewClients: {
    //   type: 'summary',
    //   title: '新進客戶數',
    //   value: newClients.length,
    //   prevValue: prevClients.length,
    // },
    totalNewDeliveryStaffs: {
      type: 'summary',
      width: 4,
      title: `新進${i18n.t('送餐大使')}`,
      value: newDeliveryStaffs.length,
      prevValue: prevNewDeliveryStaffs.length,
    },
    // isDisadvantagedRatio: {
    //   type: 'pie',
    //   title: `送餐對象`,
    //   width: 4,
    //   data: orders.reduce((obj, order) => {
    //     const key = order.elder.isDisadvantaged ? '弱勢' : '自費';
    //     obj[key] = obj[key] || { value: 0 };
    //     obj[key].value++;
    //     return obj;
    //   }, {}),
    // },
    orderStatus: {
      type: 'pie',
      title: `訂單 ${orders.length}`,
      width: 4,
      data: orders.reduce((obj, order) => {
        const { label: key, color } = translatedOrderStatus.find(({ value }) => value === order.status);
        obj[key] = obj[key] || { value: 0, color };
        obj[key].value++;
        return obj;
      }, {}),
    },
    lunchStatus: {
      type: 'pie',
      title: `${i18n.t('午餐')}訂單 ${lunchOrders.length}`,
      width: 4,
      data: lunchOrders.reduce((obj, order) => {
        const { label: key, color } = translatedOrderStatus.find(({ value }) => value === order.status);
        obj[key] = obj[key] || { value: 0, color };
        obj[key].value++;
        return obj;
      }, {}),
    },
    dinnerStatus: {
      type: 'pie',
      title: `${i18n.t('晚餐')}訂單 ${dinnerOrders.length}`,
      width: 4,
      data: dinnerOrders.reduce((obj, order) => {
        const { label: key, color } = translatedOrderStatus.find(({ value }) => value === order.status);
        obj[key] = obj[key] || { value: 0, color };
        obj[key].value++;
        return obj;
      }, {}),
    },
    // issueStatus: {
    //   type: 'pie',
    //   title: '應變中心狀態',
    //   width: 4,
    //   data: issues.reduce((obj, issue) => {
    //     const key = `${issue.status}`;
    //     obj[key] = obj[key] || 0;
    //     obj[key]++;
    //     return obj;
    //   }, {}),
    // },
    orderByCounty: {
      type: 'bar',
      title: '訂單縣市分佈',
      width: 12,
      mode: 'stacked',
      dataKeys: ['弱勢', '自費'],
      data: counties.map((county) => {
        return {
          'name': county,
          '弱勢': orders.filter((item) => item.county === county && item.elder.isDisadvantaged).length,
          '自費': orders.filter((item) => item.county === county && !item.elder.isDisadvantaged).length,
        };
      }),
    },
    lunchByCounty: {
      type: 'bar',
      title: `${i18n.t('午餐')}訂單縣市分佈`,
      width: 12,
      mode: 'stacked',
      dataKeys: ['弱勢', '自費'],
      data: counties.map((county) => {
        return {
          'name': county,
          '弱勢': lunchOrders.filter((item) => item.county === county && item.elder.isDisadvantaged).length,
          '自費': lunchOrders.filter((item) => item.county === county && !item.elder.isDisadvantaged).length,
        };
      }),
    },
    dinnerByCounty: {
      type: 'bar',
      title: `${i18n.t('晚餐')}訂單縣市分佈`,
      width: 12,
      mode: 'stacked',
      dataKeys: ['弱勢', '自費'],
      data: counties.map((county) => {
        return {
          'name': county,
          '弱勢': dinnerOrders.filter((item) => item.county === county && item.elder.isDisadvantaged).length,
          '自費': dinnerOrders.filter((item) => item.county === county && !item.elder.isDisadvantaged).length,
        };
      }),
    },
    newOrderNewDeliveryStaffByCounty: {
      type: 'bar',
      title: `新進${i18n.t('送餐大使')}縣市分佈`,
      width: 12,
      dataKeys: [i18n.t('送餐大使')],
      data: counties.map((county) => {
        const item = {
          'name': county,
          // '訂單': newOrders.filter((item) => item.county === county).length,
        };
        item[i18n.t('送餐大使')] = newDeliveryStaffs.filter((item) => item.county === county).length;
        return item;
      }),
    },
    // orderByDirection: {
    //   type: 'plot',
    //   title: '送餐距離與時間分佈',
    //   width: 12,
    //   data: orders.reduce((data, order) => {
    //     let matched = data.find((x) => x.id === order.county);
    //     if (!matched) {
    //       matched = {
    //         id: order.county,
    //         data: [],
    //       };
    //       data.push(matched);
    //     }
    //     matched.data.push({
    //       x: +((order.direction.distanceInMeters / 1000).toFixed(0)),
    //       y: +((order.direction.durationInSeconds / 60).toFixed(0)),
    //     });

    //     return data;
    //   }, []),
    // },
    issueByCounty: {
      type: 'bar',
      title: '事故縣市分佈',
      width: 12,
      dataKeys: ['數量'],
      data: issues.reduce((data, issue) => {
        let matched = data.find((x) => x.name === issue.county);
        if (!matched) {
          matched = {
            'name': issue.county,
            '數量': 0,
          };
          data.push(matched);
        }
        matched['數量']++;

        return data;
      }, counties.map((county) => {
        return {
          'name': county,
          '數量': 0,
        };
      })),
    },
  };

  return chartDataMappings;
};
