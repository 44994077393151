export default [
  {
    label: '新訂單',
    value: 'NEW',
  },
  {
    label: '已收單',
    value: 'RECEIVED',
  },
  {
    label: '指派送餐員中',
    value: 'WAITING_FOR_TRANSPORT',
  },
  {
    label: '送餐員已指派',
    value: 'ASSIGNED_TO_TRANSPORT',
  },
  {
    label: '送餐員已出發',
    value: 'COURIER_ACCEPTED_DELIVERY',
  },
  {
    label: '取餐中',
    value: 'NEAR_VENDOR',
  },
  {
    label: '已取餐',
    value: 'PICKED_UP',
  },
  {
    label: '送餐中',
    value: 'COURIER_LEFT_VENDOR',
  },
  {
    label: '即將送達',
    value: 'NEAR_CUSTOMER',
  },
  {
    label: '已送達',
    value: 'DELIVERED',
  },
  {
    label: '取消',
    value: 'CANCELLED',
  },
  {
    label: '延後',
    value: 'DELAYED',
  },
];
