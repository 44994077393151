import Colors from './Colors';

export default [
  {
    label: '處理中',
    value: 'pending',
    color: Colors.primary,
  },
  {
    label: '完成',
    value: 'processed',
    color: Colors.dark,
  },
];
