import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import CustomAuthenticator from './CustomAuthenticator';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // minWidth: 480,
  },
  content: {
    padding: 0,
    overflowX: 'hidden',
    // padding: theme.spacing(3),
    // paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CustomDialogTitle = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

const MODES = {
  signUp: {
    name: '註冊',
    icon: <PersonAddIcon />,
  },
  signIn: {
    name: '登入',
    icon: <PersonIcon />,
  },
};

export default function CustomAuthDialog({
  // children,
  title = '銀色大門',
  openOnInit = false,
  onClose,
  hideCloseButton = false,
  fullScreen = false,
  maxWidth = 'xs',
  // withButton = true,
  mode = 'signIn',
  button,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openOnInit);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    setOpen(false);
    onClose && onClose();
  };

  const { icon, name } = MODES[mode];

  return (
    <React.Fragment>
      {button ? button({ onClick: () => setOpen(!open) } ) : <Button
        color="inherit"
        // component={Link}
        // to={'/app?state=signUp'}
        startIcon={icon}
        className={classes.titleButton}
        onClick={() => setOpen(!open)}
      >
        {name}
      </Button>}
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={handleClose}
        // disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <CustomDialogTitle id="form-dialog-titlse" onClose={hideCloseButton ? undefined : handleClose}>
          {title}
        </CustomDialogTitle>
        <DialogContent className={classes.content} dividers>
          <CustomAuthenticator initialAuthState={mode} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

CustomAuthDialog.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  openOnInit: PropTypes.bool,
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  // withButton: PropTypes.bool,
  mode: PropTypes.string,
  button: PropTypes.func,
};
