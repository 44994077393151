import { countiesDistricts } from '@silvergatedelivery/constants';

export const getDistrictsInCounty = (inCounty) => countiesDistricts.filter(({ county }) => county === inCounty)
  .reduce((all, { districts }) => {
    const items = districts.map((district) => (district));
    return [...all, ...items];
  }, []);

export const getZipCode = (inCounty, district) => {
  const result = countiesDistricts.find(({ county, districts }) => county === inCounty && districts.includes(district));
  if (result) {
    return result.zipCode;
  }
};
