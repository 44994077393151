import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { v1 as uuidv1 } from 'uuid';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { getSponsorshipProgram } from 'graphql/queries';
import { createSponsorshipProgram, updateSponsorshipProgram } from 'graphql/mutations';
import schema from './schema.js';
import uiSchema from './uiSchema';
import ImageGalleryUploader from 'components/ImageGalleryUploader';

export default function SponsorshipProgramForm({ ...props }) {
  const [id, setId] = useState();
  const [currentImageS3Keys, setCurrentImageS3Keys] = useState([]);
  const [newImageS3Keys, setNewImageS3Keys] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  const onComplete = async () => {
    if (props.onComplete) {
      const { data: { getSponsorshipProgram: data } } = await request(getSponsorshipProgram, { id });

      props.onComplete(data);
    }
  };

  const createFunc = async (data) => {
    // setIsLoading(true);
    data.imageS3Keys = newImageS3Keys;
    global.logger.debug(data);

    await request(createSponsorshipProgram, { input: data });
    await onComplete();
    // setIsLoading(false);
  };

  const updateFunc = async (data) => {
    // setIsLoading(true);
    data.imageS3Keys = newImageS3Keys;
    global.logger.debug(data);

    await request(updateSponsorshipProgram, { input: data });
    await onComplete();
    // setIsLoading(false);
  };

  useEffect(() => {
    if (props.formData && props.formData.id) {
      props.formData.sponsors = props.formData.sponsors || [];
      setId(props.formData.id);
      setCurrentImageS3Keys(props.formData.imageS3Keys || []);
      setNewImageS3Keys(props.formData.imageS3Keys || []);
    } else {
      setId(uuidv1());
    }
  }, [props.formData]);

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    >
      <Grid container spacing={2} style={{ paddingTop: 16 }}>
        <ImageGalleryUploader
          s3Prefix={`sponsorshipPrograms/${id}/images`}
          s3Keys={currentImageS3Keys}
          onUpdateS3Keys={setNewImageS3Keys}
        />
      </Grid>
    </DataForm>
  );
}

SponsorshipProgramForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
