import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import VerticalTabs from 'components/Tab/VerticalTabs';
import OrderTable from 'components/OrderTable';
import RecurringOrderTable from 'components/RecurringOrderTable';
import ElderCard from 'components/Card/ElderCard';
import ClientCard from 'components/Card/ClientCard';
import { request } from 'utilities/graph';
import { getElder, getElderDeliveryGroupByElder } from 'graphql/queries';
import { Colors } from '@silvergatedelivery/constants';
import DeliveryDiary from 'views/Admin/DeliveryDiary/DeliveryDiary';
import Schedule from 'views/Admin/Schedule/Schedule';
import cache from 'utilities/cache';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    // minHeight: 'calc(100vh - 64px)',
    // height: '100%',
    backgroundColor: Colors.background.light,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  space: {
    height: theme.spacing(2),
  },
  // centerContainer: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export default function ElderDetails({ id: inId, computedMatch, isNested }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [data, setData] = useState();
  const [tabs, setTabs] = useState([]);

  const cachedClientId = cache.get('app:facilityId');
  const isFacilityAdmins = cachedClientId ? true : false;

  useEffect(() => {
    if (id) return;

    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id: urlId } } = computedMatch;
      setId(urlId);
    }
  }, [id, inId, computedMatch]);

  useEffect(() => {
    if (!id) return;

    (async () => {
      const [{ data: { getElder: data } }, { data: { getElderDeliveryGroupByElder: { items: deliveryGroups } } }] = await Promise.all([
        request(getElder, { id }),
        request(getElderDeliveryGroupByElder, { elderId: id }),
      ]);
      setData(data);
      const tabs = [
        {
          label: '客戶資料',
          component: <ClientCard title="客戶資料" id={data.clientId} hideExpand={true} showMap={true} />,
          adminsOnly: true,
        },
        {
          label: '行事曆',
          component: <Schedule
            elderId={id}
            showDeliverStaffSelector={false}
            showLocationSelector={false}
            defaultLocations={[]}
            defaultOrderStatus={[
              'waitingForDeliveryStaff',
              'reMatchingDeliveryStaff',
              'ready',
              'readyForPickup',
              'delivering',
              'delivered',
              'completed',
              'cancelled',
            ]} />,
        },
        {
          label: t('送餐紀錄'),
          component: <OrderTable nested={true} elderId={id} padding={0} />,
        },
        {
          label: t('週期性排單紀錄'),
          component: <RecurringOrderTable nested={true} elderId={id} deliveryGroups={deliveryGroups} padding={0} />,
        },
        {
          label: t('送餐關懷'),
          component: <DeliveryDiary elderId={id} />,
        },
        {
          label: t('關懷收藏'),
          component: <DeliveryDiary elderId={id} showFavorite/>,
        },
        {
          label: t('關懷追蹤'),
          component: <DeliveryDiary elderId={id} showIssue/>,
        },
      ].filter(({ adminsOnly }) => adminsOnly && isFacilityAdmins ? false : true);
      setTabs(tabs);
    })();
  }, [id]);
  global.logger.debug({ id, data, computedMatch });
  if (!id || !data) {
    return null;
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={2} lg={2} xl={3}>
          <ElderCard title="基本資料" data={data} hideExpand={true} showMap={true} />
        </Grid>
        <Grid item xs={12} md={10} lg={10} xl={9}>
          <VerticalTabs
            tabs={tabs}
            padding={0}
            isNested={isNested}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

ElderDetails.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  isNested: PropTypes.bool,
};
