export const getEldersByClientByStatus = /* GraphQL */ `
  query GetEldersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        identificationCardId
        serviceCode
        sortOrder
        noteForDelivery
        noteForMeal
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        govQualifiedCount
        govQualifiedPrice
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        supervisorId
        linkGroupName
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;

export const getEldersByServiceCodeByStatus = /* GraphQL */ `
  query GetEldersByServiceCodeByStatus(
    $serviceCode: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByServiceCodeByStatus(
      serviceCode: $serviceCode
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        identificationCardId
        serviceCode
        sortOrder
        noteForDelivery
        noteForMeal
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        govQualifiedCount
        govQualifiedPrice
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        supervisorId
        linkGroupName
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          username
          name
          facilitySettings {
            dba
          }
        }
      }
      nextToken
    }
  }
`;

export const getEldersByClientByServiceCode = /* GraphQL */ `
  query GetEldersByClientByServiceCode(
    $clientId: ID
    $serviceCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByServiceCode(
      clientId: $clientId
      serviceCode: $serviceCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        identificationCardId
        serviceCode
        sortOrder
        noteForDelivery
        noteForMeal
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        govQualifiedCount
        govQualifiedPrice
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        supervisorId
        linkGroupName
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          username
          name
          facilitySettings {
            dba
          }
        }
      }
      nextToken
    }
  }
`;

export const getOrdersByClientByDate = /* GraphQL */ `
  query GetOrdersByClientByDate(
    $clientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryStaffId
        restaurantId
        clientId
        mealSlot
        date
        deliveryBy
        status
        category
        totalCost
        total
        quantity
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        direction {
          durationInSeconds
        }
        linkGroupElderIds
      }
      nextToken
    }
  }
`;

export const getOrdersByElderByDate = /* GraphQL */ `
  query GetOrdersByElderByDate(
    $elderId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByElderByDate(
      elderId: $elderId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryStaffId
        restaurantId
        clientId
        mealSlot
        date
        deliveryBy
        status
        category
        totalCost
        total
        quantity
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        deliveryStaffFee
        direction {
          distance
        }
      }
      nextToken
    }
  }
`;

export const getOrdersByStatusByDate = /* GraphQL */ `
  query GetOrdersByStatusByDate(
    $status: OrderStatus
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByStatusByDate(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryStaffId
        restaurantId
        clientId
        date
        mealSlot
        deliveryBy
        status
        category
        totalCost
        total
        quantity
        mealItems {
          name
          quantity
          price
          cost
          note
        }
      }
      nextToken
    }
  }
`;

export const getRestaurantsByClientByIsActive = /* GraphQL */ `
  query GetRestaurantsByClientByIsActive(
    $clientId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantsByClientByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        restaurantOwnerId
        isActive
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
      }
      nextToken
    }
  }
`;

export const getPandagoOrdersByProcessStatusByShouldBeSentToPandago = /* GraphQL */ `
  query GetPandagoOrdersByProcessStatusByShouldBeSentToPandago(
    $processStatus: ProcessStatus
    $shouldBeSentToPandagoAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByProcessStatusByShouldBeSentToPandago(
      processStatus: $processStatus
      shouldBeSentToPandagoAt: $shouldBeSentToPandagoAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        # processStatusLastCheckedAt
        # processlogs
        shouldBeSentToPandagoAt
        # pandagoOrderPayload
        note
        orderId
      }
      nextToken
    }
  }
`;

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      clientId
      restaurantId
      elderId
      date
      mealSlot
      deliveryBy
      status
      county
      deliveryStaffId
      deliveryStaffOrderId
      deliveryStaffFee
      elder {
        name
        address {
          county
          district
          street
          zipCode
          lat
          lng
        }
      }
      restaurant {
        name
        address {
          county
          district
          street
          zipCode
          lat
          lng
        }
      }
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
      }
      
    }
  }
`;

export const getUberOrdersByProcessStatusByShouldBeSentToUber = /* GraphQL */ `
  query GetUberOrdersByProcessStatusByShouldBeSentToUber(
    $processStatus: ProcessStatus
    $shouldBeSentToUberAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByProcessStatusByShouldBeSentToUber(
      processStatus: $processStatus
      shouldBeSentToUberAt: $shouldBeSentToUberAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        # processlogs
        shouldBeSentToUberAt
        # uberQuotePayload
        # uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;

export const getPandagoOrdersByOrderByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        orderId
        # status
        # paymentMethod
        # amount
        # description
        # fee
        # estimatedPickupTime
        # estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        # cancelledBy
        # cancelledReason
        # processStatus
        # processStatusMessage
        # processStatusLastCheckedAt
        # processlogs
        # shouldBeSentToPandagoAt
        # pandagoOrderPayload
        # proofOfDeliveryS3Key
        # note
        # orderId
        # elderId
        # restaurantId
        # clientId
        # deliveryStaffId
        # createdAt
        # createdBy
        # updatedAt
        # updatedBy
      }
      nextToken
    }
  }
`;

export const getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove = /* GraphQL */ `
  query GetLalamoveOrdersByProcessStatusByShouldBeSentToLalamove(
    $processStatus: ProcessStatus
    $shouldBeSentToLalamoveAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove(
      processStatus: $processStatus
      shouldBeSentToLalamoveAt: $shouldBeSentToLalamoveAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
