import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import Backdrop from '@material-ui/core/Backdrop';

export default function CashButton({
  onComplete,
  disabled,
  reportLoading,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    reportLoading(true);
    try {
      onComplete({});
    } catch (e) {
      global.logger.error(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        reportLoading(false);
      }, 1000);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button
        variant='contained'
        onClick={handleClick}
        disabled={disabled || isLoading}
        style={{
          backgroundColor: disabled ? '#cecece' : '#00913A',
          width: '100%',
          color: 'white',
        }}
      >
        確認訂單（現金付款）
        <DoneIcon />
        {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100 }}>
          <CircularProgress color="primary" size="1.5rem" />
        </Backdrop>}
      </Button>
    </div>
  );
}

CashButton.propTypes = {
  disabled: PropTypes.bool,
  onComplete: PropTypes.func,
  redirectPath: PropTypes.string,
  reportLoading: PropTypes.func,
};
