import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from './Chip';

export default function TagChip({
  data,
}) {
  const [value, setValue] = useState();

  useEffect(() => {
    if (!data) return;

    if (data.label) {
      if (data.subcategory) {
        setValue(`[${data.subcategory}] ${data.label}`);
      } else {
        setValue(data.label);
      }
    } else {
      // db lookup
    }
  }, [data]);

  if (!value) return null;

  return (
    <Chip
      label={value}
      color={data.color || ''}
    />
  );
}

TagChip.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    subcategory: PropTypes.string,
    color: PropTypes.string,
  }),
  target: PropTypes.string,
};
