import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { request } from 'utilities/graph';
import { getDeliveryStaff } from 'graphql/queries';
import Terms from 'components/Terms';
import moment from 'moment';
import { TIME_ZONE } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  title: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    flex: 1,
  },
  content: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
    flex: 1,
  },
}));

export default function DeliveryStaffMonthlyStatementTable({ deliveryStaffId }) {
  const classes = useStyles();
  const [deliveryStaff, setDeliveryStaff] = useState();

  useEffect(() => {
    (async () => {
      if (deliveryStaffId) {
        const { data: { getDeliveryStaff: data } } = await request(getDeliveryStaff, { id: deliveryStaffId });
        setDeliveryStaff(data);
      }
    })();
  }, [deliveryStaffId]);

  if (!deliveryStaff) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        合約專區
      </Typography>
      <Typography variant="body1" className={classes.content}>
        {`送餐大使 ${deliveryStaff.name} 已於 ${moment(deliveryStaff.createdAt).tz(TIME_ZONE).format('YYYY/MM/DD')} 註冊時同意以下合約`}
      </Typography>
      <Terms filename="銀色大門送餐大使承攬合約.pdf" />
    </div>
  );
}

DeliveryStaffMonthlyStatementTable.propTypes = {
  deliveryStaffId: PropTypes.string,
};
