import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import EditButton from '../components/EditButton';
import DeliveryStaffTransactionForm from './DeliveryStaffTransactionForm';
import DeliveryStaffTransactionUpdateForm from './DeliveryStaffTransactionUpdateForm';

export default function DeliveryStaffTransactionEditButton({ mode = 'edit', ...props }) {
  const form = mode === 'edit' ? DeliveryStaffTransactionUpdateForm : DeliveryStaffTransactionForm;

  return (
    <EditButton
      title={`${i18n.t('送餐大使')}交易紀錄`}
      mode={mode}
      form={form}
      {...props}
    />);
}

DeliveryStaffTransactionEditButton.propTypes = {
  mode: PropTypes.string,
};
