export default [
  {
    label: '長照送餐',
    value: 'silvergate',
  },
  {
    label: '到府醫療',
    value: 'medical',
  },
];
