import i18n from 'i18next';

const getOpeningHoursArraySchema = (title = '') => ({
  'type': 'array',
  'title': title,
  'maxItems': 2,
  'items': {
    'type': 'object',
    'properties': {
      'startTime': {
        'title': '開始供餐時間',
        'type': 'string',
        'format': 'time',
        'default': '10:00:00',
      },
      'endTime': {
        'title': '結束供餐時間',
        'type': 'string',
        'format': 'time',
        'default': '19:00:00',
      },
    },
  },
});

const schema = {
  'type': 'object',
  'required': [
    'isActive', 'name', 'phoneNumber', 'address',
  ],
  'properties': {
    'isActive': {
      'type': 'number',
      'title': '狀態',
      'enum': [1, 0],
      'enumNames': ['使用中', '已停用'],
      'default': 1,
    },
    'isPublic': {
      'type': 'boolean',
      'title': '公開可供客戶自由訂購',
      'default': false,
    },
    'freeDelivery': {
      'type': ['boolean', 'null'],
      'title': '免運費',
      'default': false,
    },
    'name': {
      'type': 'string',
      'title': '名稱',
    },
    'phoneNumber': {
      'type': 'string',
      'title': '電話號碼',
    },
    'restaurantOwnerId': {
      'type': ['string', 'null'],
      'title': `${i18n.t('餐廳')}負責人`,
    },
    'clientId': {
      'type': ['string', 'null'],
      'title': '所屬機構',
    },
    'category': {
      'type': ['string', 'null'],
      'title': '類別',
      'enum': ['送餐', '送物資', '送餐和物資', '藝術', '醫療'],
      'default': '送餐',
    },
    'description': {
      'type': ['string', 'null'],
      'title': '描述',
    },
    'openingHours': {
      'type': ['object', 'null'],
      'title': '營業時間',
      'properties': {
        'monday': getOpeningHoursArraySchema('星期一營業時間'),
        'tuesday': getOpeningHoursArraySchema('星期二營業時間'),
        'wednesday': getOpeningHoursArraySchema('星期三營業時間'),
        'thursday': getOpeningHoursArraySchema('星期四營業時間'),
        'friday': getOpeningHoursArraySchema('星期五營業時間'),
        'saturday': getOpeningHoursArraySchema('星期六營業時間'),
        'sunday': getOpeningHoursArraySchema('星期日營業時間'),
      },
    },
    'address': {
      'title': '地址',
      'type': 'object',
    },
  },
};

export default schema;
