import { utils, write } from 'sheetjs-style';
import { unifyCellStyle } from '../CountyPrsReport/workbooks/helpers';

export default function writeDisability(clientStatements, year, county, includeUndeliveredOrders=false, zip) {
  const yearROC = parseInt(year) - 1911;
  const dates = Array(12).fill('').map((_, i) => {
    const month = i + 1 < 10 ? '0' + (i + 1) : i + 1;
    return {
      monthROC: `${yearROC}${month}`,
      month: `${year}-${month}`,
    };
  });
  const rows = [];
  dates.forEach((date) => {
    const statement = clientStatements.find(({ month }) => date.month === month);
    if (statement) {
      const statementLow = statement.elderStatistics.filter(({ disadvantagedTypes }) => disadvantagedTypes === '低收');
      const statementMidLow = statement.elderStatistics.filter(({ disadvantagedTypes }) =>
        disadvantagedTypes === '中低收' || disadvantagedTypes === '中低2.5倍' || disadvantagedTypes === '中低1.5倍');
        // 嘉義：中低2.5倍、中低1.5倍
        // 苗栗：中低收
      const statementMale = statement.elderStatistics.filter(({ gender }) => gender === 'male');
      const statementFemale = statement.elderStatistics.filter(({ gender }) => gender === 'female');
      rows.push({
        '月份': date.monthROC,
        '低收': statementLow.length,
        '中低收': statementMidLow.length,
        '餐次 低收': statementLow.reduce((prev, { orderCount, incompleteOrderCount=0 }) =>
          includeUndeliveredOrders ? prev + orderCount + incompleteOrderCount : prev + orderCount, 0),
        '餐次 中低收': statementMidLow.reduce((prev, { orderCount, incompleteOrderCount=0 }) =>
          includeUndeliveredOrders ? prev + orderCount + incompleteOrderCount : prev + orderCount, 0),
        '極重度(男)': statementMale.filter(({ disabilityLevel }) => disabilityLevel === '極重度').length,
        '極重度(女)': statementFemale.filter(({ disabilityLevel }) => disabilityLevel === '極重度').length,
        '重度(男)': statementMale.filter(({ disabilityLevel }) => disabilityLevel === '重度').length,
        '重度(女)': statementFemale.filter(({ disabilityLevel }) => disabilityLevel === '重度').length,
        '中度(男)': statementMale.filter(({ disabilityLevel }) => disabilityLevel === '中度').length,
        '中度(女)': statementFemale.filter(({ disabilityLevel }) => disabilityLevel === '中度').length,
        '輕度(男)': statementMale.filter(({ disabilityLevel }) => disabilityLevel === '輕度').length,
        '輕度(女)': statementFemale.filter(({ disabilityLevel }) => disabilityLevel === '輕度').length,
      });
    } else {
      rows.push({
        '月份': date.monthROC,
        '低收': 0,
        '中低收': 0,
        '餐次 低收': 0,
        '餐次 中低收': 0,
        '極重度(男)': 0,
        '極重度(女)': 0,
        '重度(男)': 0,
        '重度(女)': 0,
        '中度(男)': 0,
        '中度(女)': 0,
        '輕度(男)': 0,
        '輕度(女)': 0,
      });
    }
  });

  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 27 } });
  utils.sheet_add_aoa(worksheet, [[`${yearROC}身障者營養餐飲服務成果統計表`]], { origin: 'A1' });
  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 4, c: 0 } });
  utils.sheet_add_aoa(worksheet, [['月份']], { origin: 'A2' });
  worksheet['!merges'].push({ s: { r: 1, c: 1 }, e: { r: 1, c: 4 } });
  utils.sheet_add_aoa(worksheet, [['服務人數']], { origin: 'B2' });
  worksheet['!merges'].push({ s: { r: 2, c: 1 }, e: { r: 4, c: 1 } });
  utils.sheet_add_aoa(worksheet, [['身障津貼\n(2.5)']], { origin: 'B3' });
  worksheet['!merges'].push({ s: { r: 2, c: 2 }, e: { r: 4, c: 2 } });
  utils.sheet_add_aoa(worksheet, [['低收']], { origin: 'C3' });
  worksheet['!merges'].push({ s: { r: 2, c: 3 }, e: { r: 4, c: 3 } });
  utils.sheet_add_aoa(worksheet, [['中低收']], { origin: 'D3' });
  worksheet['!merges'].push({ s: { r: 2, c: 4 }, e: { r: 4, c: 4 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'E3' });
  worksheet['!merges'].push({ s: { r: 1, c: 5 }, e: { r: 1, c: 8 } });
  utils.sheet_add_aoa(worksheet, [['服務人(餐)次']], { origin: 'F2' });
  worksheet['!merges'].push({ s: { r: 2, c: 5 }, e: { r: 4, c: 5 } });
  utils.sheet_add_aoa(worksheet, [['身障津貼\n(2.5)']], { origin: 'F3' });
  worksheet['!merges'].push({ s: { r: 2, c: 6 }, e: { r: 4, c: 6 } });
  utils.sheet_add_aoa(worksheet, [['低收']], { origin: 'G3' });
  worksheet['!merges'].push({ s: { r: 2, c: 7 }, e: { r: 4, c: 7 } });
  utils.sheet_add_aoa(worksheet, [['中低收']], { origin: 'H3' });
  worksheet['!merges'].push({ s: { r: 2, c: 8 }, e: { r: 4, c: 8 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'I3' });
  worksheet['!merges'].push({ s: { r: 1, c: 9 }, e: { r: 2, c: 13 } });
  utils.sheet_add_aoa(worksheet, [['餐費']], { origin: 'J2' });
  worksheet['!merges'].push({ s: { r: 3, c: 9 }, e: { r: 4, c: 9 } });
  utils.sheet_add_aoa(worksheet, [['縣府專案\n2.5*10%']], { origin: 'J4' });
  worksheet['!merges'].push({ s: { r: 3, c: 10 }, e: { r: 3, c: 11 } });
  utils.sheet_add_aoa(worksheet, [['低、中低、2.5*90%']], { origin: 'K4' });
  utils.sheet_add_aoa(worksheet, [['縣府自籌\n3%']], { origin: 'K5' });
  utils.sheet_add_aoa(worksheet, [['中央97%']], { origin: 'L5' });
  worksheet['!merges'].push({ s: { r: 3, c: 12 }, e: { r: 3, c: 13 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'M4' });
  utils.sheet_add_aoa(worksheet, [['縣府']], { origin: 'M5' });
  utils.sheet_add_aoa(worksheet, [['中央']], { origin: 'N5' });
  worksheet['!merges'].push({ s: { r: 1, c: 14 }, e: { r: 2, c: 15 } });
  utils.sheet_add_aoa(worksheet, [['交通費']], { origin: 'O2' });
  worksheet['!merges'].push({ s: { r: 3, c: 14 }, e: { r: 3, c: 15 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'O4' });
  utils.sheet_add_aoa(worksheet, [['單位自籌\n20%']], { origin: 'O5' });
  utils.sheet_add_aoa(worksheet, [['中央80%']], { origin: 'P5' });
  worksheet['!merges'].push({ s: { r: 1, c: 16 }, e: { r: 2, c: 17 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'Q2' });
  worksheet['!merges'].push({ s: { r: 3, c: 16 }, e: { r: 3, c: 17 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'Q4' });
  utils.sheet_add_aoa(worksheet, [['縣府']], { origin: 'Q5' });
  utils.sheet_add_aoa(worksheet, [['中央']], { origin: 'R5' });
  worksheet['!merges'].push({ s: { r: 1, c: 18 }, e: { r: 1, c: 27 } });
  utils.sheet_add_aoa(worksheet, [['身障等級']], { origin: 'S2' });
  worksheet['!merges'].push({ s: { r: 2, c: 18 }, e: { r: 2, c: 19 } });
  utils.sheet_add_aoa(worksheet, [['極重度']], { origin: 'S3' });
  worksheet['!merges'].push({ s: { r: 3, c: 18 }, e: { r: 4, c: 18 } });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'S4' });
  worksheet['!merges'].push({ s: { r: 3, c: 19 }, e: { r: 4, c: 19 } });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'T4' });
  worksheet['!merges'].push({ s: { r: 2, c: 20 }, e: { r: 2, c: 21 } });
  utils.sheet_add_aoa(worksheet, [['重度']], { origin: 'U3' });
  worksheet['!merges'].push({ s: { r: 3, c: 20 }, e: { r: 4, c: 20 } });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'U4' });
  worksheet['!merges'].push({ s: { r: 3, c: 21 }, e: { r: 4, c: 21 } });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'V4' });
  worksheet['!merges'].push({ s: { r: 2, c: 22 }, e: { r: 2, c: 23 } });
  utils.sheet_add_aoa(worksheet, [['中度']], { origin: 'W3' });
  worksheet['!merges'].push({ s: { r: 3, c: 22 }, e: { r: 4, c: 22 } });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'W4' });
  worksheet['!merges'].push({ s: { r: 3, c: 23 }, e: { r: 4, c: 23 } });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'X4' });
  worksheet['!merges'].push({ s: { r: 2, c: 24 }, e: { r: 2, c: 25 } });
  utils.sheet_add_aoa(worksheet, [['輕度']], { origin: 'Y3' });
  worksheet['!merges'].push({ s: { r: 3, c: 24 }, e: { r: 4, c: 24 } });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'Y4' });
  worksheet['!merges'].push({ s: { r: 3, c: 25 }, e: { r: 4, c: 25 } });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'Z4' });
  worksheet['!merges'].push({ s: { r: 2, c: 26 }, e: { r: 2, c: 27 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'AA3' });
  worksheet['!merges'].push({ s: { r: 3, c: 26 }, e: { r: 4, c: 26 } });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'AA4' });
  worksheet['!merges'].push({ s: { r: 3, c: 27 }, e: { r: 4, c: 27 } });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'AB4' });
  unifyCellStyle(worksheet, 5);

  const formatRow = (rowIndex) => {
    Array.from({ length: 10 }, (_, index) => index).forEach((columnIndex) => {
      worksheet['!merges'].push({ s: { r: rowIndex - 1, c: columnIndex }, e: { r: rowIndex, c: columnIndex } });
    });
    [10, 12, 14, 16].forEach((columnIndex) => {
      worksheet['!merges'].push({ s: { r: rowIndex - 1, c: columnIndex }, e: { r: rowIndex - 1, c: columnIndex + 1 } });
    });
    Array.from({ length: 10 }, (_, index) => index).forEach((columnIndex) => {
      worksheet['!merges'].push({ s: { r: rowIndex - 1, c: columnIndex + 18 }, e: { r: rowIndex, c: columnIndex + 18 } });
    });
  };

  let rowIndex = 6;
  const getColumnKey = (column) => {
    const charCode = 'A'.charCodeAt(0);
    if (column >= 26) {
      const shiftedCharCode = charCode + column - 26;
      return `A${String.fromCharCode(shiftedCharCode)}`;
    }
    const shiftedCharCode = charCode + column;
    return String.fromCharCode(shiftedCharCode);
  };
  rows.forEach((row, index) => {
    formatRow(rowIndex);
    worksheet[`A${rowIndex}`] = { t: 's', v: row['月份'] };
    worksheet[`B${rowIndex}`] = { t: 'n', v: 0 };
    worksheet[`C${rowIndex}`] = { t: 'n', v: row['低收'] };
    worksheet[`D${rowIndex}`] = { t: 'n', v: row['中低收'] };
    worksheet[`E${rowIndex}`] = { t: 'n', v: row['低收'] + row['中低收'] };
    worksheet[`F${rowIndex}`] = { t: 'n', v: 0 };
    worksheet[`G${rowIndex}`] = { t: 'n', v: row['餐次 低收'] };
    worksheet[`H${rowIndex}`] = { t: 'n', v: row['餐次 中低收'] };
    worksheet[`I${rowIndex}`] = { t: 'n', v: row['餐次 低收'] + row['餐次 中低收'] };
    worksheet[`J${rowIndex}`] = { t: 'n', v: 0 };
    worksheet[`S${rowIndex}`] = { t: 'n', v: row['極重度(男)'] };
    worksheet[`T${rowIndex}`] = { t: 'n', v: row['極重度(女)'] };
    worksheet[`U${rowIndex}`] = { t: 'n', v: row['重度(男)'] };
    worksheet[`V${rowIndex}`] = { t: 'n', v: row['重度(女)'] };
    worksheet[`W${rowIndex}`] = { t: 'n', v: row['中度(男)'] };
    worksheet[`X${rowIndex}`] = { t: 'n', v: row['中度(女)'] };
    worksheet[`Y${rowIndex}`] = { t: 'n', v: row['輕度(男)'] };
    worksheet[`Z${rowIndex}`] = { t: 'n', v: row['輕度(女)'] };
    worksheet[`AA${rowIndex}`] = { t: 'n', v: row['極重度(男)'] + row['重度(男)'] + row['中度(男)'] + row['輕度(男)'] };
    worksheet[`AB${rowIndex}`] = { t: 'n', v: row['極重度(女)'] + row['重度(女)'] + row['中度(女)'] + row['輕度(女)'] };
    rowIndex += 2;
    if ((index + 1) % 3 === 0) {
      formatRow(rowIndex);
      worksheet[`A${rowIndex}`] = { t: 's', v: '合計' };
      const columnsToSum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
      columnsToSum.forEach((columnIndex) => {
        let sum = 0;
        const rowsOffset = [3, 5, 7];
        rowsOffset.forEach((offset) => {
          const cellAddress = { r: rowIndex - offset, c: columnIndex };
          const cellRef = utils.encode_cell(cellAddress);
          const cell = worksheet[cellRef];
          sum += cell.v;
        });
        worksheet[`${getColumnKey(columnIndex)}${rowIndex}`] = { t: 'n', v: sum };
      });
      rowIndex += 2;
    }
  });
  formatRow(rowIndex);
  // worksheet[`A${rowIndex}`] = { t: 's', v: '總計' }; // TODO: not work, why?
  utils.sheet_add_aoa(worksheet, [['總計']], { origin: `A${rowIndex}` });
  const columnsToSum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
  columnsToSum.forEach((columnIndex) => {
    let sum = 0;
    const rowsToSum = [11, 19, 27, 35];
    rowsToSum.forEach((row) => {
      const cellAddress = { r: row, c: columnIndex };
      const cellRef = utils.encode_cell(cellAddress);
      const cell = worksheet[cellRef];
      sum += cell.v;
    });
    worksheet[`${getColumnKey(columnIndex)}${rowIndex}`] = { t: 'n', v: sum };
  });

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, '身障者年度報表');
  const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

  zip.file(`${county}__身障者年度報表.xlsx`, wbout, { binary: true });
}
