import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  formatNote,
  formatTime,
  formatDatetime,
} from 'utilities/format';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  imgContainer: {
    textAlign: 'center',
  },
  img: {
    minHeight: 150,
    height: 150,
    width: 'auto',
    cursor: 'pointer',
  },
}));

export default function DeliveryStaffOrderDiaryCard({
  deliveryStaffOrder: inDeliveryStaffOrder,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deliveryStaffOrder, setDeliveryStaffOrder] = useState();


  useEffect(() => {
    setDeliveryStaffOrder(inDeliveryStaffOrder);
  }, [inDeliveryStaffOrder]);

  if (!deliveryStaffOrder) return null;

  const {
    deliveryStartedAt,
    deliveryDeliveredAt,
    deliveryStaff,
    diary,
    thumbnail,
    order,
    elderDementiaSurvey,
    deliveryBy,
  } = deliveryStaffOrder;
  const { name } = deliveryStaff;
  global.logger.debug(deliveryStaffOrder);
  const deliveryStaffName = name;
  const elderName = order.elder.name;

  const refreshImage = () => {
    global.logger.debug('refreshImage?', deliveryStaffOrder);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" color="primary">
              {formatDatetime(deliveryBy, { hour: false, minute: false })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.imgContainer}>
              <img src={thumbnail} className={classes.img} onClick={refreshImage}/>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1" color="textPrimary">
              取餐人
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" color="textSecondary">
              {elderName}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant="body1" color="textPrimary">
              送餐大使
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" color="textSecondary">
              {deliveryStaffName}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant="body1" color="textPrimary">
              {t('送餐')}時間
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" color="textSecondary">
              {formatTime(deliveryStartedAt)} - {formatTime(deliveryDeliveredAt)}
            </Typography>
          </Grid>
        </Grid>
        {elderDementiaSurvey && elderDementiaSurvey.items && elderDementiaSurvey.items.map(({ response, dementiaQuestionnaire }, index) => {
          const question = dementiaQuestionnaire.items[0].question;
          if (response === 'completed') {
            return (
              <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column' }} key={`dementia_${index}`}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckBoxIcon color="secondary" />
                  <Typography variant="body2" color="textSecondary" component="p" style={{ marginLeft: 10 }}>失智友善宣導</Typography>
                </div>
                <Typography variant="caption" color="textSecondary" component="p" style={{ marginLeft: 40 }}>{question}</Typography>
              </div>
            );
          }
        })}
        <Typography variant="body1" color="textPrimary" component="p" style={{ marginTop: 10 }}>
          日誌
        </Typography>
        {formatNote(diary || '無')}
      </CardContent>
    </Card>
  );
}

DeliveryStaffOrderDiaryCard.propTypes = {
  deliveryStaffOrder: PropTypes.object,
};
