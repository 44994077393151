import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DataTable from 'components/Table/DataTable';
import { getOrderQuotesByCodeByCreatedAt, listOrderQuotes } from 'graphql/queries';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';

const lastWeek = moment().tz(TIME_ZONE).add(-7, 'days').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function OrderQuoteTable({
  title = '估價單',
  description,
  data,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'restaurantId',
      label: `${t('餐廳')}ID`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'restaurantName',
      label: t('餐廳'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'originalStartAddress',
      label: '原始起點',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'originalEndAddress',
      label: '原始終點',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'code',
      label: 'Code',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'startAddress',
      label: '起點',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'endAddress',
      label: '終點',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distance',
      label: '距離',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'message',
      label: '訊息',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'calculationMethod',
      label: '計價方式',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'totalFee',
      label: '總費用(含平台費與運費加成)',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffFee',
      label: t('送餐費用'),
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'uberEstimatedFee',
      label: 'Uber估價',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'pandagoEstimatedFee',
      label: 'Pandago估價',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'ilinkEstimatedFee',
      label: 'ILink估價',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lalamoveEstimatedFee',
      label: 'Lalamove估價',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'defaultDeliveryStaffFee',
      label: '里程估價',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    expandableRows: true,
    isRowExpandable: () => false,
  };

  useEffect(() => {
    setIndexes([
      {
        name: 'getOrderQuotesByCodeByCreatedAt',
        label: '按狀態/時間查詢',
        partitionKey: 'code',
        sortKey: 'createdAt',
        fields: [{
          label: '狀態',
          key: 'code',
          type: 'select',
          options: [{ value: 'SUCCESS', label: 'SUCCESS' }, { value: 'OUT_OR_RANGE', label: 'OUT_OR_RANGE' }, { value: 'FAILED', label: 'FAILED' }],
        }, {
          label: '估價時間',
          key: 'createdAt',
          type: 'datetime',
        }],
        operation: getOrderQuotesByCodeByCreatedAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listOrderQuotes,
      },
    ]);
    setQueryParams({
      code: 'SUCCESS',
      createdAt: {
        from: lastWeek,
        to: today,
      },
    });
  }, []);

  return (
    <DataTable
      data={data}
      title={title}
      description={description}
      columns={columns}
      options={options}
      defaultIndex={0}
      indexes={indexes}
      queryDefaultParams={queryParams}
      {...props}
    />
  );
}

OrderQuoteTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};
