import i18n from 'i18next';

const boardTranslation = {
  'Add another lane': `新增${i18n.t('送餐群組')}`,
  'Click to add card': 'Click to add card',
  'Delete lane': `刪除${i18n.t('送餐群組')}`,
  'Lane actions': '修改',
  'button': {
    'Add lane': `新增${i18n.t('送餐群組')}`,
    'Add card': 'Add card',
    'Cancel': '取消',
  },
  'placeholder': {
    'title': '名稱',
    'description': '描述',
    'label': 'label',
  },
};

export default boardTranslation;
