import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  value: {
    margin: theme.spacing(1),
  },
}));
export default function SummaryCard({ title, value, prevValue, unit = '', appendix = '', period: inPeriod }) {
  const [period] = inPeriod.split('/');
  const classes = useStyles();

  let p;
  switch (period) {
    case 'year':
      p = '前一年';
      break;
    case 'month':
      p = '前一個月';
      break;
    case 'week':
      p = '前一週';
      break;
    case 'day':
    default:
      p = '前一日';
      break;
  }

  const diff = +(((prevValue ? (value - prevValue) / prevValue : value) * 100).toFixed(0));
  const isSame = diff === 0;
  const isIncreased = diff > 0;
  const desc = isSame ? `與${p}相同` : `與${p}(${unit}${prevValue}${appendix})相比 ${isIncreased?'增加':'減少'} ${Math.abs(diff)}%`;

  return (
    <Paper className={classes.container}>
      <Typography variant="subtitle2" component="p">
        {title}
      </Typography>
      <Typography variant="h4" component="h1" align="center" className={classes.value}>
        {prevValue !== undefined && (isSame ? '' :
          isIncreased ?
            <ArrowDropUpIcon fontSize="small" style={{ color: red[500] }} /> :
            <ArrowDropDownIcon fontSize="small" style={{ color: green[500] }} />)}
        {unit}{value}{appendix}
      </Typography>
      {prevValue !== undefined &&
      <Typography variant="body2" component="p" align="center" color="textSecondary">
        {desc}
      </Typography>}
    </Paper>);
}


SummaryCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  prevValue: PropTypes.number,
  unit: PropTypes.string,
  appendix: PropTypes.string,
  period: PropTypes.string,
};
