import { utils, writeFile, write } from 'xlsx';
import moment from 'moment';
import { getMonthDates, convertDateToZhFormat } from './helpers';
import { chunkArray } from 'utilities/array';

const COUNTY = '新北市';
const SUBSIDY_UNIT = 80;
const SUBSIDY_UNIT_REMOTE = 60; // additional
const REMOTE_DISTRICTS = [
  // 原住民區域
  '烏來區',
  // 偏遠地區
  '石碇區', '坪林區',
  '平溪區', '雙溪區', '貢寮區',
];

export default function writeReport({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const [year, month] = selectedMonth.split('-');
  const yearMonthTitle = `${parseInt(year)-1911}年${month}月`;

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, getWorksheetCover(), `目錄`);

  utils.book_append_sheet(workbook, getWorksheetEldersForRegularDistricts({
    clientName,
    yearMonthTitle,
    elders,
    orders,
    deliveryStaffs,
  }), `附件1_照顧組合服務費用項目清冊`);

  utils.book_append_sheet(workbook, getWorksheetEldersForRemoteDistricts({
    clientName,
    yearMonthTitle,
    elders,
    orders,
  }), `附件1-2_(偏區)請領清冊`);

  utils.book_append_sheet(workbook, getWorksheetDeliveryList({
    clientName,
    yearMonthTitle,
    selectedMonth,
    elders,
    orders,
    deliveryStaffs,
  }), `附件2_志工到府送餐服務個案清冊`);

  utils.book_append_sheet(workbook, getWorksheetStatistics({
    clientName,
    yearMonthTitle,
    selectedMonth,
    elders,
    orders,
  }), `附件3_服務人數月報表`);

  if (process.env.JEST_WORKER_ID) {
    return write(workbook, { type: 'buffer', bookType: 'xlsx' });
  }

  writeFile(workbook, `${clientName}__${COUNTY}核銷報表__${selectedMonth}.xlsx`, { bookSST: true, cellStyles: true });
}

function getWorksheetCover() {
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  // Title
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } });
  utils.sheet_add_aoa(worksheet, [[`新北市政府辦理長期照顧十年計畫2.0營養餐飲服務特約獎補助計畫`]], { origin: 'A1' });

  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 3 } });
  utils.sheet_add_aoa(worksheet, [[`每月紙本核銷應檢附文件`]], { origin: 'A2' });

  utils.sheet_add_aoa(worksheet, [[`編號`, '文件名稱', '備註']], { origin: 'A3' });
  utils.sheet_add_aoa(worksheet, [[`1`, '特約契約書影本（但申報前一月曾有申報紀錄者，免附）']], { origin: 'A4' });
  utils.sheet_add_aoa(worksheet, [[`2`, '營養餐飲服務補助領據']], { origin: 'A5' });
  utils.sheet_add_aoa(worksheet, [[`3`, '單位用印大、小章之長照服務提供者服務費用申報總表']], { origin: 'A6' });
  utils.sheet_add_aoa(worksheet, [[`4`, '照顧組合服務費用項目清冊。']], { origin: 'A7' });
  utils.sheet_add_aoa(worksheet, [[`5`, '到府送餐志工交通費及誤餐費請領清冊(一般區及偏區)']], { origin: 'A8' });
  utils.sheet_add_aoa(worksheet, [[`6`, '志工到府送餐服務個案清冊']], { origin: 'A9' });
  utils.sheet_add_aoa(worksheet, [[`7`, '營養餐飲服務月報表']], { origin: 'A10' });

  worksheet['!merges'].push({ s: { r: 7, c: 2 }, e: { r: 9, c: 2 } });
  utils.sheet_add_aoa(worksheet, [['5至7項除紙本資料外，電子檔請於每月10日前郵寄至承辦人信箱AO3581@ntpc.gov.tw']], { origin: 'C8' });

  worksheet['!cols'] = [
    { wch: 10 }, { wch: 80 }, { wch: 80 },
  ];

  return worksheet;
}

const setDisadvantageHistoryWithEndDate = (inDisadvantagedTypesHistory) => {
  if (!inDisadvantagedTypesHistory || inDisadvantagedTypesHistory.length === 0) {
    return [{
      approvedDate: '2000-01-01',
      endDate: '',
      disadvantagedTypes: '無',
    }];
  }

  const disadvantagedTypesHistory = inDisadvantagedTypesHistory.map((item, index) => {
    let endDate = '';
    const nextItem = inDisadvantagedTypesHistory[index + 1];
    if (nextItem) {
      endDate = moment(nextItem.approvedDate).add(-1, 'days').format('YYYY-MM-DD');
    }

    item.endDate = endDate;

    return item;
  });

  return disadvantagedTypesHistory;
};

function getWorksheetEldersForRegularDistricts({ clientName, yearMonthTitle, elders = [], orders = [], deliveryStaffs = [] }) {
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  // Title
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 19 } });
  utils.sheet_add_aoa(worksheet, [[`照顧組合服務費用項目清冊`]], { origin: 'A1' });

  const getSubsidyRatioByDisadvantageType = (inType) => {
    if (inType.indexOf('中低')) {
      return 0.9;
    }
    return 1;
  };

  const elderRows = [];
  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    // .filter(({
    //   address: {
    //     district,
    //   },
    // }) => {
    //   return !REMOTE_DISTRICTS.includes(district);
    // })
    .forEach(({
      id,
      name,
      identificationCardId,
      disadvantagedTypesHistory: inDisadvantagedTypesHistory,
      supervisorId,
      serviceCode,
    }) => {
      // Write row by disadvantagedTypesHistory
      const disadvantagedTypesHistory = setDisadvantageHistoryWithEndDate(inDisadvantagedTypesHistory);

      disadvantagedTypesHistory.forEach(({ disadvantagedTypes, approvedDate, endDate }) => {
        const planDescription = `${convertDateToZhFormat(approvedDate)}~${endDate ? convertDateToZhFormat(endDate) : '(計畫異動)'}`;
        const deliveryStaffNames = [];
        const servedDates = [];
        const elderOrders = orders
          .filter(({ elderId, date }) => elderId === id && approvedDate <= date && (endDate ? date <= endDate : true));

        elderOrders.forEach(({ deliveryStaffId, date }) => {
          const { name: deliveryStaffName } = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
          if (!deliveryStaffNames.includes(deliveryStaffName)) {
            deliveryStaffNames.push(deliveryStaffName);
          }

          if (!servedDates.includes(date)) {
            servedDates.push(convertDateToZhFormat(date));
          }
        });

        const subsidyRatio = getSubsidyRatioByDisadvantageType(disadvantagedTypes);

        const count = elderOrders.length;
        const subsidySubTotal = count * SUBSIDY_UNIT;
        const subsidyDiscount = (1 - subsidyRatio) * subsidySubTotal;
        const subsidyTotal = subsidySubTotal - subsidyDiscount;

        if (count === 0) return;

        const row = {
          '身分證號': identificationCardId,
          '個案姓名': name,
          '採用計畫': planDescription,
          'CMS\n等級': serviceCode,
          '福利身份別': disadvantagedTypes,
          '服務項目\n類別': 'OT01 營養 餐飲服務',
          '服務日期': chunkArray(servedDates.sort((a, b) => a > b ? 1 : -1), 3).map((g) => g.join(', ')).join(',\n'),
          '給(支)付\n價格': 80,
          '原民區或\n離島支付\n價格': 80,
          '次數': count,
          '申報費用': subsidySubTotal,
          '部分負擔\n比率': `${subsidyRatio * 100}%`,
          '部分負擔\n費用': subsidyDiscount,
          '補助比率': `${subsidyRatio * 100}%`,
          '申請(補助)\n費用': subsidyTotal,
          '原住民或\n離島申請\n(補助)費用': '',
          '實際補助\n金額': subsidyTotal,
          '服務當下\n居住縣市': '新北市',
          '照管專員': supervisorId || '',
          '服務人員': deliveryStaffNames.join(','),
        };

        elderRows.push(row);
      });
    });

  utils.sheet_add_json(worksheet, elderRows, { origin: 'A2', skipHeader: false });


  worksheet['!cols'] = [
    { wch: 12 }, { wch: 10 }, { wch: 20 }, { wch: 10 },
    { wch: 30 }, { wch: 20 }, { wch: 30 },
  ];

  return worksheet;
}

function getWorksheetEldersForRemoteDistricts({ clientName, yearMonthTitle, elders = [], orders = [] }) {
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  // Title
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`新北市政府辦理長期照顧十年計畫2.0營養餐飲服務特約獎補助計畫`]], { origin: 'A1' });

  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`(原住民族及偏遠地區)`]], { origin: 'A2' });

  worksheet['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`新北市政府補助${clientName}到府送餐志工交通費及誤餐費${yearMonthTitle}份請領清冊`]], { origin: 'A3' });

  // Date Header
  worksheet['!merges'].push({ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } });
  utils.sheet_add_aoa(worksheet, [['編號']], { origin: 'A4' });

  worksheet['!merges'].push({ s: { r: 3, c: 1 }, e: { r: 4, c: 1 } });
  utils.sheet_add_aoa(worksheet, [['姓名']], { origin: 'B4' });

  worksheet['!merges'].push({ s: { r: 3, c: 2 }, e: { r: 4, c: 2 } });
  utils.sheet_add_aoa(worksheet, [['區域']], { origin: 'C4' });

  worksheet['!merges'].push({ s: { r: 3, c: 3 }, e: { r: 4, c: 3 } });
  utils.sheet_add_aoa(worksheet, [['身分證']], { origin: 'D4' });

  worksheet['!merges'].push({ s: { r: 3, c: 4 }, e: { r: 4, c: 4 } });
  utils.sheet_add_aoa(worksheet, [['趟次數']], { origin: 'E4' });

  worksheet['!merges'].push({ s: { r: 3, c: 5 }, e: { r: 4, c: 5 } });
  utils.sheet_add_aoa(worksheet, [[`每趟單價${SUBSIDY_UNIT_REMOTE}元`]], { origin: 'F4' });

  utils.sheet_add_aoa(worksheet, [['補助金額']], { origin: 'G4' });
  utils.sheet_add_aoa(worksheet, [[`(趟次數*${SUBSIDY_UNIT_REMOTE}元)`]], { origin: 'G5' });

  worksheet['!merges'].push({ s: { r: 3, c: 7 }, e: { r: 4, c: 7 } });
  utils.sheet_add_aoa(worksheet, [['志工簽章']], { origin: 'H4' });

  worksheet['!merges'].push({ s: { r: 3, c: 8 }, e: { r: 4, c: 8 } });
  utils.sheet_add_aoa(worksheet, [['備註']], { origin: 'I4' });

  // Data
  let totalSubsidy = 0;
  const elderRows = elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .filter(({
      address: {
        district,
      },
    }) => {
      return REMOTE_DISTRICTS.includes(district);
    })
    .map(({
      id,
      name,
      identificationCardId,
      address: {
        district,
      },
    }, index) => {
      const elderOrders = orders.filter(({ elderId }) => elderId === id);

      const count = elderOrders.length;
      const subsidy = SUBSIDY_UNIT_REMOTE * count;
      totalSubsidy += subsidy;

      return {
        編號: index + 1,
        姓名: name,
        區域: district,
        身分證字號: identificationCardId,
        趟次數: count,
        補助單價: SUBSIDY_UNIT_REMOTE,
        補助總額: subsidy,
        志工簽章: '',
        備註: '',
      };
    });

  utils.sheet_add_json(worksheet, elderRows, { origin: 'A6', skipHeader: true });

  // Summary
  const rowOffsetAnchor = 4 + elderRows.length + 1;
  worksheet['!merges'].push({ s: { r: rowOffsetAnchor, c: 0 }, e: { r: rowOffsetAnchor, c: 3 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: `A${rowOffsetAnchor+1}` });
  utils.sheet_add_aoa(worksheet, [[totalSubsidy]], { origin: `F${rowOffsetAnchor+1}` });

  worksheet['!merges'].push({ s: { r: rowOffsetAnchor + 1, c: 0 }, e: { r: rowOffsetAnchor + 1, c: 7 } });
  utils.sheet_add_aoa(worksheet, [
    [`*每送1趟補助${SUBSIDY_UNIT_REMOTE}元，每人每日最高補助新臺幣${SUBSIDY_UNIT_REMOTE*4}元，每人每月最高上限補助新臺幣${SUBSIDY_UNIT_REMOTE*120}元。`],
  ], { origin: `A${rowOffsetAnchor+2}` });
  worksheet['!merges'].push({ s: { r: rowOffsetAnchor + 2, c: 0 }, e: { r: rowOffsetAnchor + 2, c: 7 } });
  utils.sheet_add_aoa(worksheet, [
    [`*原住民族區:烏來區。`]], { origin: `A${rowOffsetAnchor+3}` });
  worksheet['!merges'].push({ s: { r: rowOffsetAnchor + 3, c: 0 }, e: { r: rowOffsetAnchor + 3, c: 7 } });
  utils.sheet_add_aoa(worksheet, [
    [`*偏遠地區：石碇區、坪林區、平溪區、雙溪區、貢寮區。`]], { origin: `A${rowOffsetAnchor+4}` });
  utils.sheet_add_aoa(worksheet, [[`填表人：：`]], { origin: `A${rowOffsetAnchor+5}` });
  utils.sheet_add_aoa(worksheet, [[`主辦會計：`]], { origin: `C${rowOffsetAnchor+5}` });
  utils.sheet_add_aoa(worksheet, [[`單位主管：`]], { origin: `E${rowOffsetAnchor+5}` });

  worksheet['!cols'] = [
    { wch: 20 }, { wch: 20 },
    { wch: 20 }, { wch: 20 },
    { wch: 20 }, { wch: 20 },
    { wch: 20 }, { wch: 20 },
  ];

  return worksheet;
}

function getWorksheetDeliveryList({ clientName, selectedMonth, yearMonthTitle, elders = [], orders = [], deliveryStaffs = [] }) {
  const servedElderIds = [];

  const deliveryStaffGroupData = {};

  const monthDates = getMonthDates(selectedMonth);

  orders.forEach(({ elderId, deliveryStaffId, date: deliveryDate, mealSlot }) => {
    if (!servedElderIds.includes(elderId)) {
      servedElderIds.push(elderId);
    }

    const {
      name: deliveryStaffName,
    } = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
    const {
      name: elderName,
    } = elders.find(({ id }) => id === elderId);

    const data = deliveryStaffGroupData[deliveryStaffId] || {
      deliveryStaffName,
      servedDates: [],
      elders: {},
      totalMealCount: 0,
      totalRunCount: 0,
      statisticsByDates: monthDates.reduce((obj, item) => {
        obj[item.date] = {
          meals: 0,
          count: 0,
          elderIds: [],
        };

        return obj;
      }, {}),
    };

    data.elders[elderName] = data.elders[elderName] || {
      elderName,
      lunchCount: 0,
      dinnerCount: 0,
      dates: monthDates.reduce((obj, item) => {
        obj[item.date] = {
          lunch: 0,
          dinner: 0,
        };

        return obj;
      }, {}),
    };

    if (!data.servedDates.includes(deliveryDate)) {
      data.servedDates.push(deliveryDate);
    }

    data.elders[elderName][`${mealSlot}Count`]++;
    data.elders[elderName].dates[deliveryDate][mealSlot]++;

    data.statisticsByDates[deliveryDate].meals++;
    data.totalMealCount++;
    if (!data.statisticsByDates[deliveryDate].elderIds.includes(elderId)) {
      data.statisticsByDates[deliveryDate].elderIds.push(elderId);
      data.statisticsByDates[deliveryDate].count++;
      data.totalRunCount++;
    }

    // assign back
    deliveryStaffGroupData[deliveryStaffId] = data;
  });

  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  // Title
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`新北市政府辦理長期照顧十年計畫2.0營養餐飲服務特約獎補助計畫`]], { origin: 'A1' });

  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } });
  utils.sheet_add_aoa(worksheet, [[`新北市政府補助${clientName}${yearMonthTitle}份營養餐飲送餐服務 志工服務個案清冊 本月服務個案數共計：${servedElderIds.length}人`]], { origin: 'A2' });


  // Group by Delivery Staff
  let rowOffsetAnchor = 2;
  Object.keys(deliveryStaffGroupData).map((deliveryStaffId, index) => {
    const {
      deliveryStaffName,
      servedDates,
      elders,
      statisticsByDates,
      totalMealCount,
      totalRunCount,
    } = deliveryStaffGroupData[deliveryStaffId];

    utils.sheet_add_aoa(worksheet, [[`${index+1}.志工姓名:${deliveryStaffName} 服務天數:${servedDates.length}天`]], { origin: `A${rowOffsetAnchor+1}` });
    utils.sheet_add_aoa(worksheet, [
      ['編號', '送餐時段', '姓名 / 日期', ...monthDates.map(({ date }) => moment(date).format('MM/DD')), '', '合計'],
    ], { origin: `A${rowOffsetAnchor+2}` });

    let elderRowOffsetAnchor = rowOffsetAnchor + 3;
    Object.keys(elders).map((elderId, elderIndex) => {
      const {
        elderName,
        dates: elderDates,
        lunchCount,
        dinnerCount,
      } = elders[elderId];

      worksheet['!merges'].push({ s: { r: elderRowOffsetAnchor - 1, c: 0 }, e: { r: elderRowOffsetAnchor, c: 0 } });
      worksheet['!merges'].push({ s: { r: elderRowOffsetAnchor - 1, c: 2 }, e: { r: elderRowOffsetAnchor, c: 2 } });
      utils.sheet_add_aoa(worksheet, [
        [`${elderIndex+1}`, '午', elderName, ...Object.keys(elderDates).map((x) => elderDates[x].lunch === 0 ? '' : elderDates[x].lunch), '', lunchCount],
      ], { origin: `A${elderRowOffsetAnchor}` });
      utils.sheet_add_aoa(worksheet, [
        [``, '晚', '', ...Object.keys(elderDates).map((x) => elderDates[x].dinner === 0 ? '' : elderDates[x].dinner), '', dinnerCount],
      ], { origin: `A${elderRowOffsetAnchor +1}` });

      elderRowOffsetAnchor = elderRowOffsetAnchor + 2;
    });

    worksheet['!merges'].push({ s: { r: elderRowOffsetAnchor - 1, c: 0 }, e: { r: elderRowOffsetAnchor - 1, c: 2 } });
    worksheet['!merges'].push({ s: { r: elderRowOffsetAnchor, c: 0 }, e: { r: elderRowOffsetAnchor, c: 2 } });
    utils.sheet_add_aoa(worksheet, [
      ['總計(餐次)', '', '', ...Object.keys(statisticsByDates).map((x) => statisticsByDates[x].meals), '', totalMealCount],
    ], { origin: `A${elderRowOffsetAnchor}` });

    utils.sheet_add_aoa(worksheet, [
      ['總計(趟次)', '', '', ...Object.keys(statisticsByDates).map((x) => statisticsByDates[x].count), '', totalRunCount],
    ], { origin: `A${elderRowOffsetAnchor+1}` });

    rowOffsetAnchor = elderRowOffsetAnchor + 3;
  });

  worksheet['!cols'] = [
    { wch: 10 }, { wch: 10 }, { wch: 10 },
  ];

  return worksheet;
}

const getMappedDisadvantageType = (inType = '') => {
  if (inType.indexOf('原住民') > -1) {
    return '具原住民身份';
  }

  if (inType.indexOf('中低') > -1) {
    return '中低收入';
  }

  if (inType.indexOf('低收') > -1) {
    return '低收入戶';
  }

  return '一般戶';
};

function getWorksheetStatistics({ clientName, yearMonthTitle, elders = [], orders = [] }) {
  const byElderStatistic = {
    total: {
      '項目別': '總計',
      '總計': 0,
      '低收入戶': 0,
      '中低收入': 0,
      '一般戶': 0,
      '具原住民身分': 0,
    },
    male: {
      '項目別': '男',
      '總計': 0,
      '低收入戶': 0,
      '中低收入': 0,
      '一般戶': 0,
      '具原住民身分': 0,
    },
    female: {
      '項目別': '女',
      '總計': 0,
      '低收入戶': 0,
      '中低收入': 0,
      '一般戶': 0,
      '具原住民身分': 0,
    },
  };

  const byMealStatistic = {
    total: {
      '項目別': '總計',
      '總計': 0,
      '低收入戶': 0,
      '中低收入': 0,
      '一般戶': 0,
      '具原住民身分': 0,
    },
    male: {
      '項目別': '男',
      '總計': 0,
      '低收入戶': 0,
      '中低收入': 0,
      '一般戶': 0,
      '具原住民身分': 0,
    },
    female: {
      '項目別': '女',
      '總計': 0,
      '低收入戶': 0,
      '中低收入': 0,
      '一般戶': 0,
      '具原住民身分': 0,
    },
  };

  const servedElderIds = [];

  orders.forEach(({ elderId, date }) => {
    const {
      disadvantagedTypesHistory: inDisadvantagedTypesHistory,
      gender,
    } = elders.find(({ id }) => id === elderId);

    const disadvantagedTypesHistory = setDisadvantageHistoryWithEndDate(inDisadvantagedTypesHistory);
    const { disadvantagedTypes } = disadvantagedTypesHistory
      .find(({ approvedDate, endDate }) => approvedDate <= date && (endDate ? date <= endDate : true)) || {};
    const type = getMappedDisadvantageType(disadvantagedTypes);

    byMealStatistic.total['總計']++;
    byMealStatistic.total[type]++;
    byMealStatistic[gender]['總計']++;
    byMealStatistic[gender][type]++;

    if (!servedElderIds.includes(elderId)) {
      servedElderIds.push(elderId);
      byElderStatistic.total['總計']++;
      byElderStatistic.total[type]++;
      byElderStatistic[gender]['總計']++;
      byElderStatistic[gender][type]++;
    }
  });

  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  // Title
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } });
  utils.sheet_add_aoa(worksheet, [[`新北市政府辦理長期照顧十年計畫2.0營養餐飲服務特約獎補助計畫`]], { origin: 'A1' });

  // 受益人數
  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });
  utils.sheet_add_aoa(worksheet, [[`${clientName}${yearMonthTitle}份服務報表 (受益人數)`]], { origin: 'A2' });

  utils.sheet_add_json(worksheet, Object.keys(byElderStatistic).map((key) => byElderStatistic[key]), { origin: 'A3', skipHeader: false });

  // 服務餐次
  worksheet['!merges'].push({ s: { r: 8, c: 0 }, e: { r: 8, c: 5 } });
  utils.sheet_add_aoa(worksheet, [[`${clientName}${yearMonthTitle}份服務報表 (服務餐次)`]], { origin: 'A9' });

  utils.sheet_add_json(worksheet, Object.keys(byMealStatistic).map((key) => byMealStatistic[key]), { origin: 'A10', skipHeader: false });

  return worksheet;
}
