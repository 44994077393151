import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loading from 'components/Loading';
import {
  getOrdersByIndividualByDate,
} from './queries';
import OrderCard from './OrderCard';
import { asyncListAll } from 'utilities/graph';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';
import TransactionDetailDialog from './TransactionDetailDialog';
import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';

const Order = ({ onGoPage }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { appGroup } = useCache();
  const [showTransactionDetailDialog, setShowTransactionDetailDialog] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const tabLabels = ['進行中', '已完成', '已取消'];

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:clientId');
      if (clientId) {
        const from = moment().tz(TIME_ZONE).add(-60, 'days').format('YYYY-MM-DD');
        const to = moment().tz(TIME_ZONE).add(100, 'days').format('YYYY-MM-DD');
        const orders = await asyncListAll(getOrdersByIndividualByDate, {
          individualId: clientId,
          date: {
            between: [from, to],
          },
        });
        global.logger.debug(orders);
        setOrders(orders.reverse());
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    switch (tabValue) {
      case 1: // 已完成
        setFilteredOrders(orders.filter((order) => order.status === 'completed' ));
        break;
      case 2: // 已取消
        setFilteredOrders(orders.filter((order) => order.status === 'cancelled' ));
        break;
      case 0: // 進行中
      default:
        setFilteredOrders(orders.filter((order) => order.status !== 'cancelled' && order.status !== 'comppleted' ));
        break;
    }
  }, [tabValue, orders]);

  if (!appGroup) {
    return (
      <Grid container spacing={2} style={{ padding: 16 }} justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            請先登入或註冊帳號
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} container justifyContent="center" style={{ paddingTop: 32 }}>
          <CustomAuthDialog
            mode= 'signIn'
            withButton={false}
            button={(props) => (
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '80%',
                  borderRadius: '20px',
                  color: 'white',
                }}
                {...props}
              >
                登入
              </Button>
            )}
          />
          &nbsp;
          <CustomAuthDialog
            mode= 'signUp'
            withButton={false}
            button={(props) => (
              <Button
                variant="text"
                color="primary"
                size="large"
                {...props}
              >
                還不是會員？點此註冊
              </Button>
            )}
          />
        </Grid>
      </Grid>
    );
  }

  if (isLoading) {
    return <Loading fullScreen={false} />;
  }

  const onOrderClick = (order) => {
    setTransactionId(order.transactionId);
    setShowTransactionDetailDialog(true);
  };

  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={1} justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            所有訂單
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab label={tabLabels[0]} />
            <Tab label={tabLabels[1]} />
            <Tab label={tabLabels[2]} />
          </Tabs>
        </Grid>
        {filteredOrders.length === 0 && <>
          <Grid item xs={12}>
            <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold', marginTop: 16 }} >
              尚無{tabLabels[tabValue]}的訂單
            </Typography>
            {tabValue === 0 && <Typography variant='subtitle1' color='textPrimary' align='center'>
              快點擊下方綠色按鈕，探索健康營養的美食！我們會將餐點外送到您的大門口
            </Typography>}
          </Grid>
          {tabValue === 0 && <Grid item xs={12} md={3} style={{ margin: 16, padding: 16 }} container justifyContent='center' alignItems='center'>
            <Button variant='contained'
              onClick={() => {
                onGoPage && onGoPage('meals');
              }}
              style={{
                width: '80%',
                borderRadius: '20px',
                backgroundColor: '#00913A',
                color: 'white',
              }}>
              立即前往訂餐
            </Button>
          </Grid>}
        </>}
        {filteredOrders.map((order, index)=>(
          <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
            <OrderCard order={order} onClick={() => onOrderClick(order)}/>
          </Grid>
        ))}
      </Grid>
      {transactionId && <TransactionDetailDialog
        open={showTransactionDetailDialog}
        transactionId={transactionId}
        fullScreen
        onClose={() => {
          setTransactionId();
          setShowTransactionDetailDialog(false);
        }}
      />}
    </Container>
  );
};

export default Order;

Order.propTypes = {
  onGoPage: PropTypes.func,
};
