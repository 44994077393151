import Colors from './Colors';

export default [
  {
    label: '未開立',
    value: 'pending',
    color: Colors.primary,
  },
  {
    label: '已開立',
    value: 'sent',
    color: Colors.dark,
  },
];
