import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AutoSizeDialog from 'components/AutoSizeDialog';
import ReceiverInput from './ReceiverInput';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButton: {
    width: '80%',
    borderRadius: '20px',
    color: 'white',
  },
}));

export default function ReceiverDialog({
  label,
  formData,
  onUpdate,
  hideCloseButton = false,
  fullScreen = true,
  maxWidth = 'md',
  defaultValue,
}) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [receiver, setReceiver] = useState(formData?.name ? JSON.parse(JSON.stringify(formData)) : defaultValue);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    setShowDialog(false);
    // reset data
    setReceiver(formData?.name ? JSON.parse(JSON.stringify(formData)) : defaultValue);
  };

  const onReceiverChange = (receiver) => {
    setReceiver(receiver);
  };

  const receiverValid = () => {
    if (receiver.name.length === 0 || receiver.phoneNumber.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} direction='row' justifyContent='space-between' style={{ padding: 4 }}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        <Grid item>
          <Typography variant='body1' color='textPrimary' align='left' style={{ margin: 4, whiteSpace: 'pre-line' }}>
            {label || ''}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            style={{ padding: 4 }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
      <AutoSizeDialog
        open={showDialog}
        title='設定取餐人資訊'
        onClose={handleClose}
      >
        <DialogContent className={classes.content} dividers>
          <Grid container>
            <Grid item xs={12}>
              <ReceiverInput
                data={receiver}
                onChange={onReceiverChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Grid xs={12} item container justifyContent='center' alignItems='center'>
              <Button
                variant='contained'
                className={classes.actionButton}
                style={{ backgroundColor: '#00913A' }}
                onClick={() => {
                  onUpdate && onUpdate(receiver);
                  setShowDialog(false);
                }}
                disabled={!receiverValid()}
              >
                確認
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </AutoSizeDialog>
    </React.Fragment>
  );
}

ReceiverDialog.propTypes = {
  label: PropTypes.string,
  formData: PropTypes.object,
  onUpdate: PropTypes.func,
  fullScreen: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  defaultValue: PropTypes.object,
};
