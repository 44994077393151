import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/Table/DataTable';
import {
  listDeliveryStaffs,
  getDeliveryStaffsByCountyByIsActive,
  getDeliveryStaffsByIsActiveByCreatedAt,
} from 'graphql/queries';
import { formatAddress } from 'utilities/format';
import DeliveryStaffDetails from 'components/DeliveryStaffDetails';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import DeliverStaffEditButton from 'forms/DeliverStaffForm/DeliverStaffEditButton';
import UserAvatar from 'components/UserAvatar';
import { counties } from '@silvergatedelivery/constants';
import DeliveryStaffChip from 'components/DeliveryStaffChip';
import { deliveryStaffStatus } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { getClientIdSchema } from 'forms/schemas';

export default function DeliveryStaffTable({
  title = i18n.t('送餐大使'),
  description,
  queryIsActive = 1,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [clientOptions, setClientOptions] = useState();

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns}>
          <DeliveryStaffDetails id={id} isNested={true} />
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        // filterList: [cache.get('app:location')].filter((x) => x),
        filter: true,
        sort: true,
      },
    },
    {
      name: 'clientId',
      label: '機構',
      options: {
        filter: true,
        sort: true,
        customBodyRender(clientId) {
          if (clientOptions) {
            return (clientOptions.find(({ id }) => id === clientId) || {}).name || '';
          }
          return '';
        },
      },
    },
    {
      name: 'isActive',
      label: '狀態',
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <DeliveryStaffChip status={value} />
          );
        },
      },
    },
    {
      name: 'tier',
      label: '層級',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '帳號',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名字',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '頭像',
      options: {
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <UserAvatar
              username={username}
            />
          );
        },
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordBreak: 'break-all' }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: 'identificationCardId',
      label: '身分證字號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'address',
      label: '地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'documents',
      label: '資料檔案',
      options: {
        filter: true,
        sort: true,
        customBodyRender(documents) {
          return !documents || documents.length === 0 ? '未上傳' : documents.length < 6 ? '部分上傳' : '已上傳';
        },
      },
    },
    {
      name: 'sTokenLastMonth',
      label: '上個月SToken',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'totalEarnedSToken',
      label: '總計入SToken',
      type: 'number',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'completedOrderCount',
      label: '總訂單數',
      type: 'number',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'sTokenReportedAt',
      label: '上次SToken紀錄時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        // display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/deliveryStaff/${id}`}
              label={`前往${t('送餐大使')}頁面`}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    setIndexes([
      {
        name: 'getDeliveryStaffsByCountyByIsActive',
        label: '按地區查詢',
        partitionKey: 'county',
        sortKey: 'isActive',
        fields: [{
          label: '縣市',
          key: 'county',
          type: 'select',
          options: counties.map((item) => {
            return { value: item, label: item };
          }),
          size: 6,
        }, {
          label: '使用狀態',
          key: 'isActive',
          type: 'select',
          options: [
            { value: undefined, label: '全部' },
            ...deliveryStaffStatus,
          ],
          size: 6,
        }, {
          label: '姓名',
          key: 'name',
          size: 12,
          comparisonOperator: 'contains',
        }],
        operation: getDeliveryStaffsByCountyByIsActive,
      },
      {
        name: 'getDeliveryStaffsByIsActiveByCreatedAt',
        label: '按狀態查詢',
        partitionKey: 'isActive',
        sortKey: 'createdAt',
        fields: [{
          label: '使用狀態',
          key: 'isActive',
          type: 'select',
          options: deliveryStaffStatus,
        }],
        operation: getDeliveryStaffsByIsActiveByCreatedAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '姓名',
          key: 'name',
          comparisonOperator: 'contains',
        }],
        operation: listDeliveryStaffs,
      },
    ]);
    setQueryParams({
      county: cache.get('app:location') || counties[0],
      isActive: queryIsActive,
    });
  }, []);

  useEffect(() => {
    (async () => {
      const [
        clientOptions,
      ] = await Promise.all([
        getClientIdSchema(null, '所屬機構', true, true, false),
      ]);
      setClientOptions(clientOptions);
    })();
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        editButton={DeliverStaffEditButton}
        defaultIndex={cache.get('app:location') ? 0 : 1}
        {...props}
      />
    </React.Fragment>
  );
}

DeliveryStaffTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  queryIsActive: PropTypes.number,
};
