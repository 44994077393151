/* eslint-disable quotes, comma-dangle */
export default {
  "title": "捐贈項目",
  "type": "object",
  "required": [
    "isActive", "name", "summary",
    "count", "unit", "unitValue",
    "totalValue"
  ],
  "properties": {
    "isActive": {
      "type": "number",
      "title": "狀態",
      "enum": [1, 0],
      "enumNames": ["使用", "停用"],
      "default": 1
    },
    "name": {
      "type": "string",
      "title": "名稱"
    },
    "summary": {
      "type": "string",
      "title": "簡述"
    },
    "description": {
      "type": ["string", "null"],
      "title": "詳細描述"
    },
    "count": {
      "type": "number",
      "title": "數量"
    },
    "unit": {
      "type": "string",
      "title": "單位 (ex: 餐/堂/箱)"
    },
    "unitValue": {
      "type": "number",
      "title": "單位價格"
    },
    "totalValue": {
      "type": "number",
      "title": "總金額"
    },
    "sponsors": {
      "type": "array",
      "title": "贊助廠商",
      "items": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "title": "名稱"
          },
          "description": {
            "type": ["string", "null"],
            "title": "描述"
          },
          "websiteUrl": {
            "type": ["string", "null"],
            "title": "網址"
          },
          "logoUrl": {
            "type": ["string", "null"],
            "title": "LOGO網址"
          }
        }
      }
    }
  }
};
