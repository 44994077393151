import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import CustomTimeline from 'components/CustomTimeline';

export default function IssueTimeline({ issue, comments = [] }) {
  if (!issue) return null;

  const render = (item, index) => {
    if (index === 0) {
      return (<React.Fragment>
        <Typography>
          {issue.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {issue.reportedBy}
        </Typography>
      </React.Fragment>);
    } else {
      return (<React.Fragment>
        <Typography>
          {item.content}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {item.username}
        </Typography>
      </React.Fragment>);
    }
  };

  return (
    <CustomTimeline data={[issue, ...comments]} render={render} />
  );
}

IssueTimeline.propTypes = {
  issue: PropTypes.object,
  comments: PropTypes.array,
};
