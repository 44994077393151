import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import {
  counties, countiesDistricts,
} from '@silvergatedelivery/constants';
import { getDistrictsInCounty, getZipCode } from 'utilities/address';

const AddressSettings = ({ onUpdate, defaultValue = {} }) => {
  const [county, setCounty] = useState();
  const [district, setDistrict] = useState();
  const [districts, setDistricts] = useState();
  const [street, setStreet] = useState();
  const [note, setNote] = useState();

  const onCountyChange = (newCounty) => {
    if (newCounty !== county) {
      setCounty(newCounty);
      const newDistricts = getDistrictsInCounty(newCounty);
      setDistricts(newDistricts);
      setDistrict(newDistricts[0]);
    }
  };

  useEffect(() => {
    if (defaultValue?.county) {
      setCounty(defaultValue.county);
      if (defaultValue.district) {
        setDistrict(defaultValue.district);
      } else {
        setDistrict(getDistrictsInCounty(defaultValue.county)[0]);
      }
      setDistricts(getDistrictsInCounty(defaultValue.county));
    } else {
      setCounty(counties[0]);
      setDistrict(countiesDistricts[0].districts[0]);
      setDistricts(getDistrictsInCounty(counties[0]));
    }
    setStreet(defaultValue?.street || '');
    setNote(defaultValue?.note || '');
  }, []);

  useEffect(() => {
    if (county) {
      const zipCode = getZipCode(county, district);
      onUpdate && onUpdate({ county, district, street, note, zipCode });
    }
  }, [county, district, street, note]);

  if (!county) {
    return <></>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h6' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
          設定外送地址
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ border: '2px solid #00913A', borderRadius: '15px', marginTop: 16 }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={6}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="select-county-label">縣市</InputLabel>
              <Select
                id="select-county"
                value={county}
                onChange={(e) => onCountyChange(e.target.value)}
                label="縣市"
              >
                {counties.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="select-district-label">鄉鎮市區</InputLabel>
              <Select
                id="select-district"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                label="鄉鎮市區"
              >
                {districts.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              placeholder='道路或街名或村里名稱'
              value={street}
              style={{ width: '100%' }}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              placeholder='備註'
              value={note}
              style={{ width: '100%' }}
              onChange={(e) => setNote(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressSettings;

AddressSettings.propTypes = {
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.any,
};
