const orderItems = /* GraphQL */ `
  items {
    id
    clientId
    restaurantId
    elderId
    date
    mealSlot
    deliveryBy
    status
    county
    countyOpenStatus
    isTemporarilyLocked
    category
    transactionId
    deliveryGroupId
    deliveryGroupSortOrder
    isAudited
    nextStatusCheckAt
    issuePriority
    issueSummary
    deliveryStaffId
    deliveryStaffOrderId
    deliveryStaffFee
    tier
    tierExpiredAt
    noteForDelivery
    noteForMeal
    note
    mealItems {
      name
      quantity
      price
      cost
      note
    }
    totalCost
    total
    quantity
    subtotal
    discount
    discountCode
    discountDetails
    extraFee
    platformFee
    paymentMethod
    direction {
      startAddress
      startLocation {
        lat
        lng
      }
      endAddress
      endLocation {
        lat
        lng
      }
      distance
      duration
      distanceInMeters
      durationInSeconds
      overviewPolyline
      bounds {
        northeast {
          lat
          lng
        }
        southwest {
          lat
          lng
        }
      }
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    restaurant {
      name
    }
  }
`;

export const getOrdersByClientByDate = /* GraphQL */ `
  query GetOrdersByClientByDate(
    $clientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${orderItems}
      nextToken
    }
  }
`;

export const getOrdersByIndividualByDate = /* GraphQL */ `
  query GetOrdersByIndividualByDate(
    $individualId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByIndividualByDate(
      individualId: $individualId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ${orderItems}
      nextToken
    }
  }
`;

export const getDeliveryStaffOrdersByOrder = /* GraphQL */ `
  query GetDeliveryStaffOrdersByOrder(
    $orderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByOrder(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          name
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;

export const getOrderEventsByOrderByCreatedAt = /* GraphQL */ `
  query GetOrderEventsByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderEventsByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
