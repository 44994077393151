import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import VerticalTabs from 'components/Tab/VerticalTabs';
import OrderTable from 'components/OrderTable';
import RecurringOrderTable from 'components/RecurringOrderTable';
import RestaurantCard from 'components/Card/RestaurantCard';
import RestaurantMeals from 'views/Admin/RestaurantMeals/RestaurantMeals';
import RestaurantOwnerCard from 'components/Card/RestaurantOwnerCard';

export default function RestaurantDetails({ id: inId, computedMatch, isNested }) {
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    if (!id) return;

    const tabs = [
      {
        label: '基本資料',
        component: <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <RestaurantCard title="基本資料" id={id} hideExpand={true} showMap={true}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <RestaurantOwnerCard title={`${t('餐廳')}負責人`} restaurantId={id} hideExpand={true} showMap={true}/>
          </Grid>
        </Grid>,
      },
      {
        label: '訂單',
        component: <OrderTable nested={true} restaurantId={id} />,
      },
      {
        label: '週期性排單',
        component: <RecurringOrderTable nested={true} restaurantId={id} />,
      },
      {
        label: t('餐點'),
        component: <RestaurantMeals restaurantId={id} />,
      },
    ];
    setTabs(tabs);
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={isNested}
    />
  );
}

RestaurantDetails.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  isNested: PropTypes.bool,
};
