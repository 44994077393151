import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Board, { createTranslate, components as ReactTrelloComponents } from 'react-trello';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import { DebounceInput } from 'react-debounce-input';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { v1 as uuidv1 } from 'uuid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';

import { Colors } from '@silvergatedelivery/constants';

import {
  createDeliveryGroup,
  updateDeliveryGroup,
  deleteDeliveryGroup,
  createElderDeliveryGroup,
  updateElderDeliveryGroup,
  deleteElderDeliveryGroup,
  updateRecurringOrder,
} from 'graphql/mutations';
import {
  getEldersByClientByStatus,
  getDeliveryGroupsByClientByIsActive,
  getElderDeliveryGroupsByClientByStatus,
  getRecurringOrdersByDeliveryGroupByStatus,
} from './queries';
import Marker from 'components/Map/Marker';
import { asyncListAll, request } from 'utilities/graph';
import { formatAddress } from 'utilities/format';
import { sortBy } from 'utilities/sorting';

import './ElderDeliveryGroupBoard.css';
import CustomLaneHeader from './CustomLaneHeader';
import cache from 'utilities/cache';

const convertElderToCard = (elder, elderDeliveryGroup, showAddress, hasDuplicate) => {
  const { id: elderId, name, nickname, address } = elder;
  const { id: elderDeliveryGroupId } = elderDeliveryGroup;
  const cardData = {
    id: elderDeliveryGroupId || elderId,
    title: name + (nickname ? ` (${nickname})` : ''),
    description: showAddress ? formatAddress(address) : undefined,
    metadata: { elder, elderDeliveryGroup },
  };

  if (hasDuplicate) {
    cardData.tags = cardData.tags || [];
    cardData.tags.push({
      bgcolor: 'red',
      color: 'white',
      title: '重複出現',
    });
  }

  if (cardData.tags && cardData.tags.length === 0) {
    delete cardData.tags;
  }

  return cardData;
};

const FIXED_SOURCE_LANE_ID = '__Source_Lane__';

let eventBus = undefined;

const useStyles = makeStyles((theme) => ({
  app: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  centerContent: {
    width: '100%',
    height: '100%',
    transition: 'width 0.3s ease-in-out',
    overflow: 'auto',
  },
  centerContentShrink: {
    width: '70%',
  },
  popup: {
    position: 'absolute',
    top: 0,
    right: '-100%',
    width: '30%',
    height: '100%',
    boxShadow: '-2px 0px 10px rgba(0, 0, 0, 0.2)',
    transition: 'right 0.3s ease-in-out',
    overflow: 'hidden',
  },
  popupActive: {
    right: 0,
  },
}));

export default function ElderDeliveryGroupBoard({ clientId: inClientId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [lanes, setLanes] = useState();
  const [searchText, setSearchText] = useState('');
  const [elders, setElders] = useState([]);
  const [deliveryGroups, setDeliveryGroups] = useState([]);
  const [elderDeliveryGroups, setElderDeliveryGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showDuplicated, setShowDuplicated] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationFocused, setLocationFocused] = useState(undefined);
  const [laneSelected, setLaneSelected] = useState(undefined);
  const [isReady, setIsReady] = useState(false);
  const [sourceLanePosition, setSourceLanePosition] = useState(0);
  // load here for translation purpose
  const { default: boardTranslation } = useMemo(() => require('./boardTranslation.js'), []);
  const customTranslation = useMemo(() => createTranslate(boardTranslation), [boardTranslation]);
  const clientId = inClientId || cache.get('app:facilityId');

  const setEventBus = (handle) => {
    eventBus = handle;
  };

  const onCardDelete = async (cardId, laneId) => {
    if (laneId === FIXED_SOURCE_LANE_ID) {
      return;
    }

    await request(deleteElderDeliveryGroup, { input: { id: cardId } });
    toastr.info(`已成功移除`);
    const cardToDelete = lanes.find(({ id }) => id === laneId).cards.find(({ id }) => id === cardId);
    const newElderDeliveryGroups = Array.from(elderDeliveryGroups);
    const indexToDelete = newElderDeliveryGroups.findIndex(
      (elderDeliveryGroup) => elderDeliveryGroup.id === cardToDelete.metadata.elderDeliveryGroup.id,
    );
    newElderDeliveryGroups.splice(indexToDelete, 1);
    setElderDeliveryGroups(newElderDeliveryGroups);
  };

  const handleAddLane = async ({ id, title }) => {
    setIsLoading(true);
    const username = cache.get('app:username');
    const data = {
      id,
      clientId,
      isActive: 1,
      name: title,
      createdBy: username,
      updatedBy: username,
    };
    const { data: { createDeliveryGroup: newDeliveryGroup } } = await request(createDeliveryGroup, { input: data });
    const newDeliveryGroups = Array.from(deliveryGroups);
    newDeliveryGroups.push(newDeliveryGroup);
    setDeliveryGroups(newDeliveryGroups);
    setIsLoading(false);
  };

  const handleUpdateLane = async (laneId, { title, ...props }) => {
    setIsLoading(true);
    const username = cache.get('app:username');
    const data = {
      id: laneId,
      name: title,
      updatedBy: username,
    };
    await request(updateDeliveryGroup, { input: data });
    const newDeliveryGroups = Array.from(deliveryGroups);
    const newDeliveryGroup = newDeliveryGroups.find((deliveryGroup) => deliveryGroup.id === laneId);
    Object.assign(newDeliveryGroup, data);
    setDeliveryGroups(newDeliveryGroups);
    setIsLoading(false);
  };

  const handleDeleteLane = async (laneId) => {
    setIsLoading(true);
    const matchedElderDeliveryGroupIds = elderDeliveryGroups
      .filter(({ deliveryGroupId }) => deliveryGroupId === laneId)
      .map(({ id }) => id);

    await Promise.all(
      matchedElderDeliveryGroupIds.map((id) => request(deleteElderDeliveryGroup, { input: { id } })),
    );
    await request(deleteDeliveryGroup, { input: { id: laneId } });
    const newDeliveryGroups = Array.from(deliveryGroups);
    const indexToDelete = newDeliveryGroups.findIndex((deliveryGroup) => deliveryGroup.id === laneId);
    newDeliveryGroups.splice(indexToDelete, 1);
    const newElderDeliveryGroups = Array.from(elderDeliveryGroups)
      .filter((elderDeliveryGroup) => !matchedElderDeliveryGroupIds.includes(elderDeliveryGroup.id));

    const recurringOrders = await asyncListAll(getRecurringOrdersByDeliveryGroupByStatus, { deliveryGroupId: laneId, status: { eq: '使用中' } });
    const username = cache.get('app:username');
    await Promise.all(recurringOrders.map(async (recurringOrder) => {
      await request(updateRecurringOrder, { input: {
        ...recurringOrder,
        updatedBy: username,
        updatedAt: moment().toISOString(),
        status: '已停用',
      } });
    }));
    if (indexToDelete < sourceLanePosition) {
      setSourceLanePosition(sourceLanePosition - 1);
    }
    setDeliveryGroups(newDeliveryGroups);
    setElderDeliveryGroups(newElderDeliveryGroups);
    setIsLoading(false);
  };

  const handleLaneDragEnd = async (oldIndex, newIndex) => {
    const newDeliveryGroups = Array.from(deliveryGroups);
    if (oldIndex === sourceLanePosition) {
      setSourceLanePosition(newIndex);
    } else {
      let movedDeliveryGroup = {};
      let newSourceLanePosition = sourceLanePosition;
      if (oldIndex < sourceLanePosition) {
        [movedDeliveryGroup] = newDeliveryGroups.splice(oldIndex, 1);
        newSourceLanePosition -= 1;
      } else {
        [movedDeliveryGroup] = newDeliveryGroups.splice(oldIndex - 1, 1);
      }
      if (newIndex < sourceLanePosition) {
        newDeliveryGroups.splice(newIndex, 0, movedDeliveryGroup);
        newSourceLanePosition += 1;
      } else {
        newDeliveryGroups.splice(newIndex - 1, 0, movedDeliveryGroup);
      }
      setSourceLanePosition(newSourceLanePosition);
    }
    // if oldIndex or newIndex is 0, need to call setDeliveryGroups to move the lane back
    setDeliveryGroups(newDeliveryGroups);
  };

  const onCardMoveAcrossLanes = async (sourceLaneId, targetLaneId, cardId, position) => {
    try {
      setIsLoading(true);

      const username = cache.get('app:username');
      const toCreate = [];
      const toUpdate = [];

      if (targetLaneId === FIXED_SOURCE_LANE_ID) {
        return;
      }

      const sourceLane = lanes.find(({ id }) => id === sourceLaneId);
      const oldIndex = sourceLane.cards.findIndex(({ id }) => id === cardId);
      if (sourceLaneId === targetLaneId && oldIndex === position) {
        return;
      }
      const [currentCard] = sourceLane.cards.splice(oldIndex, 1);

      const targetLane = lanes.find(({ id }) => id === targetLaneId);

      if (sourceLaneId === FIXED_SOURCE_LANE_ID) {
        const copiedCard = JSON.parse(JSON.stringify(currentCard));
        sourceLane.cards
          .splice(oldIndex, 0, copiedCard);
      }

      currentCard.laneId = targetLane.id;

      targetLane.cards
        .splice(position, 0, currentCard);

      targetLane.cards
        .forEach((card, index) => {
          const { id: elderDeliveryGroupId, sortOrder } = card.metadata.elderDeliveryGroup;
          const { id: elderId } = card.metadata.elder;

          if (!elderDeliveryGroupId) {
            const id = uuidv1();
            const elderDeliveryGroupData = {
              id,
              elderId,
              deliveryGroupId: targetLaneId,
              clientId,
              status: '使用中',
              sortOrder: index,
              createdBy: username,
              updatedBy: username,
            };
            toCreate.push(elderDeliveryGroupData);
            card.id = id;
            card.metadata.elderDeliveryGroup = elderDeliveryGroupData;
          } else if (sortOrder !== index || index === position) {
            toUpdate.push({
              id: elderDeliveryGroupId,
              deliveryGroupId: targetLaneId,
              sortOrder: index,
              updatedBy: username,
            });
          }
        });

      global.logger.debug('data', {
        toCreate,
        toUpdate,
      });
      if (sourceLaneId === FIXED_SOURCE_LANE_ID) {
        // to avoid the card disappear in 1st lane when moving from 1st lane to others
        eventBus.publish({ type: 'UPDATE_LANES', lanes });
      }
      await Promise.all(
        toCreate.map((data) => request(createElderDeliveryGroup, { input: data })),
        toUpdate.map((data) => request(updateElderDeliveryGroup, { input: data })),
      );
      const newElderDeliveryGroups = Array.from(elderDeliveryGroups);
      toCreate.forEach((newGroup) => {
        newElderDeliveryGroups.push(newGroup);
      });
      toUpdate.forEach((updateGroup) => {
        Object.assign(
          newElderDeliveryGroups.find((elderDeliveryGroup) => elderDeliveryGroup.id === updateGroup.id),
          updateGroup,
        );
      });
      setElderDeliveryGroups(newElderDeliveryGroups);
      toastr.info(`更新成功`);
    } catch (e) {
      toastr.error(e);
      global.logger.debug(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    // Dont allow to drag back to the source
    if (targetLaneId === FIXED_SOURCE_LANE_ID) {
      return false;
    }

    // dupllicate elder
    const lane = lanes.find(({ id }) => id === targetLaneId);
    if (sourceLaneId !== targetLaneId && lane.cards.find(({ metadata }) => metadata.elder.id === cardDetails.metadata.elder.id)) {
      toastr.warning(`${cardDetails.metadata.elder.name}已經在該群組裡`);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!clientId) return;

    (async () => {
      setIsLoading(true);
      const [
        elders,
        deliveryGroups,
        elderDeliveryGroups,
      ] = await Promise.all([
        asyncListAll(getEldersByClientByStatus, { clientId, status: { eq: '使用中' }, limit: 1000 }),
        asyncListAll(getDeliveryGroupsByClientByIsActive, { clientId, limit: 1000 }),
        asyncListAll(getElderDeliveryGroupsByClientByStatus, { clientId, status: { eq: '使用中' }, limit: 1000 }),
      ]);

      setElders(elders);
      setDeliveryGroups(deliveryGroups.sort(sortBy('name')));
      setElderDeliveryGroups(elderDeliveryGroups);

      setTimeout(() => {
        setIsLoading(false);
        setIsReady(true);
      }, 300);
    })();
  }, [clientId]);

  const handleCardClick = (cardInfo) => {
    if (!showMap) {
      return;
    }
    const { metadata: { elder }, laneId, id } = cardInfo;
    if (laneId !== FIXED_SOURCE_LANE_ID) {
      if (laneId === laneSelected) {
        setLocations([]);
        setLaneSelected(undefined);
        return;
      }
      const laneShowOnMap = lanes.find((lane) => lane.id === laneId);
      if (laneShowOnMap) {
        const newLocations = laneShowOnMap.cards.map(({ metadata: { elder }, id }, index) => ({
          lat: elder.address.lat,
          lng: elder.address.lng,
          name: elder.name,
          id,
          index: index + 1,
        }));
        setLocations(newLocations);
      }
      setLaneSelected(laneId);
    } else {
      if (locationFocused && locationFocused.id === id) {
        setLocationFocused(undefined);
        return;
      }
      setLocationFocused({
        lat: elder.address.lat,
        lng: elder.address.lng,
        name: elder.name,
        id,
      });
    }
  };

  useEffect(() => {
    if (!isReady) return;

    console.log('Re-render lanes');

    const allElders = elders
      .filter((({ name }) => name.toLowerCase().includes(searchText.toLowerCase())))
      .sort(sortBy('name'));
    const firstLane = {
      id: FIXED_SOURCE_LANE_ID,
      title: `${t('送餐對象')}清單`,
      label: `${allElders.length}`,
      cards: allElders.map((elder) => {
        return convertElderToCard(elder, {}, showAddress, false);
      }),
      editLaneTitle: false,
      disallowAddingCard: true,
      hideCardDeleteIcon: true,
      droppable: false,
      style: {
        backgroundColor: Colors.light,
        border: `1px solid ${Colors.light}`,
        width: showAddress ? 250 : 150,
        minWidth: showAddress ? 250 : 150,
        maxHeight: 'calc(100vh - 220px)',
      },
    };
    const duplicateElderIds = [];
    if (showDuplicated) {
      allElders.forEach(({ id }) => {
        const matchedGroups = elderDeliveryGroups.filter(({ elderId }) => elderId === id);
        if (matchedGroups.length > 1) {
          duplicateElderIds.push(id);
        }
      });
    }

    const convertDeliveryGroupToLane = (id, name) => {
      const matchedElderDeliveryGroups = elderDeliveryGroups
        .filter(({ deliveryGroupId }) => deliveryGroupId === id)
        .filter(({ elderId }) => allElders.find(({ id }) => id === elderId))
        .sort(sortBy('sortOrder'));

      return {
        id,
        title: name,
        cards: matchedElderDeliveryGroups.map((elderDeliveryGroup) => {
          const elder = allElders.find(({ id }) => id === elderDeliveryGroup.elderId);
          const hasDuplicate = duplicateElderIds.includes(elder.id);
          return convertElderToCard(elder, elderDeliveryGroup, showAddress, hasDuplicate);
        }),
        disallowAddingCard: true,
        droppable: true,
        style: {
          backgroundColor: showMap && id === laneSelected ? '#739fbf' : '#e3e3e3',
          width: showAddress ? 250 : 150,
          minWidth: showAddress ? 250 : 150,
          maxHeight: 'calc(100vh - 220px)',
        },
      };
    };
    const data = deliveryGroups.map(({ id, name }) => {
      return convertDeliveryGroupToLane(id, name);
    });
    data.splice(sourceLanePosition, 0, firstLane);
    setLanes(data);

    if (laneSelected) {
      const laneShowOnMap = data.find((lane) => lane.id === laneSelected);
      if (laneShowOnMap) {
        const newLocations = laneShowOnMap.cards.map(({ metadata: { elder }, id }, index) => ({
          lat: elder.address.lat,
          lng: elder.address.lng,
          name: elder.name,
          id,
          index: index + 1,
        }));
        setLocations(newLocations);
      } else {
        setLocations([]);
        setLaneSelected(undefined);
      }
    }
  }, [isReady, elders, deliveryGroups, elderDeliveryGroups, searchText, showAddress, laneSelected, showMap, showDuplicated]);

  if (!lanes) return null;

  return (
    <div className={classes.app}>
      <div className={`${classes.centerContent} ${showMap ? classes.centerContentShrink : ''}`}>
        <Grid container>
          <Grid item xs={12} style={{ padding: 8, paddingLeft: 16 }}>
            <FormGroup row>
              <DebounceInput
                debounceTimeout={300}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                element={TextField}
                id={`search-text`}
                label=""
                value={searchText}
                type={'text'}
                placeholder="搜尋"
                variant="outlined"
                margin="dense"
                // disabled={isLoading}
              />
              <div style={{ width: 100 }}>
                {isLoading &&
                <CircularProgress color="primary" size={24} style={{ margin: 12 }} />}
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAddress}
                    name={'showAddress'}
                    value={showAddress}
                    onChange={(e) => {
                      setShowAddress(e.target.checked);
                    }}
                  />
                }
                label={'顯示地址'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showMap}
                    name={'showMap'}
                    value={showMap}
                    onChange={(e) => {
                      setShowMap(e.target.checked);
                    }}
                  />
                }
                label={'顯示地圖'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDuplicated}
                    name={'showDuplicated'}
                    value={showDuplicated}
                    onChange={(e) => {
                      setShowDuplicated(e.target.checked);
                    }}
                  />
                }
                label={'檢查重複設定'}
              />
            </FormGroup>
          </Grid>
          {elders.length > 0 &&
          <Grid item xs={12} style={{ width: 100 }}>
            <Board
              t={customTranslation}
              draggable={true}
              laneDraggable={true}
              cardDraggable={!isLoading}
              editable={true}
              canAddLanes={true}
              hideCardDeleteIcon={false}
              onCardDelete={onCardDelete}
              editLaneTitle={true}
              cardStyle={{
                width: showAddress ? 230 : 130,
                minWidth: showAddress ? 230 : 130,
                margin: 'auto',
                marginBottom: 5,
              }}
              onLaneAdd={handleAddLane}
              onLaneDelete={handleDeleteLane}
              onLaneUpdate={handleUpdateLane}
              handleLaneDragEnd={handleLaneDragEnd}
              handleDragEnd={handleDragEnd}
              onCardMoveAcrossLanes={onCardMoveAcrossLanes}
              data={{ lanes }}
              style={{ backgroundColor: Colors.background.light }}
              eventBusHandle={setEventBus}
              components={{
                AddCardLink: () => <span />,
                LaneHeader: (args) => {
                  // global.logger.debug('lane', args);
                  if (args.id === FIXED_SOURCE_LANE_ID) {
                    return <CustomLaneHeader {...args} />;
                  }
                  let backgroundColor = 'white';
                  if (showMap && args.laneId === laneSelected) {
                    backgroundColor = '#cad9e6';
                  }

                  return <ReactTrelloComponents.LaneHeader
                    {...args}
                    style={{ ...args.style, backgroundColor }}
                    label={`${args.cards.length}`}
                    labelStyle={{ marginRight: 8 }}
                  />;
                },
                Card: (args) => {
                  const onClick = () => {
                    handleCardClick(args);
                  };
                  let backgroundColor = Colors.light2;
                  if (args.laneId === FIXED_SOURCE_LANE_ID) {
                    if (showMap && locationFocused && locationFocused.id === args.id) {
                      backgroundColor = Colors.primaryLight;
                    }
                    return <ReactTrelloComponents.Card
                      {...args}
                      showDeleteButton={false}
                      onClick={onClick}
                      style={{ ...args.style, backgroundColor }}
                    />;
                  } else if (showMap && args.laneId === laneSelected) {
                    backgroundColor = Colors.light2;
                  }

                  return <ReactTrelloComponents.Card
                    {...args}
                    onClick={onClick}
                    style={{ ...args.style, backgroundColor }}
                    title={showMap && args.laneId === laneSelected?`${args.index + 1}.${args.title}` : args.title}
                  />;
                },
              }}
            />
          </Grid>}
        </Grid>
      </div>
      <div className={`${classes.popup} ${showMap ? classes.popupActive : ''}`}>
        <Marker locations={locations} locationFocused={locationFocused} showMap={showMap} />
      </div>
    </div>
  );
}

ElderDeliveryGroupBoard.propTypes = {
  clientId: PropTypes.string,
};
