import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DataTable from 'components/Table/DataTable';
import {
  getAuditTrailsByGroupNameByTimestamp,
  getAuditTrailsByFacilityIdByTimestamp,
  listAuditTrails,
} from 'graphql/queries';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';
import { getClientIdSchema } from 'forms/schemas';
import LineCard from 'views/FacilityAdmin/Dashboard/components/LineCard';

const pastThreeDays = moment().tz(TIME_ZONE).add(-3, 'days').startOf('day').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).endOf('day').format('YYYY-MM-DD');

const LoginChart = ({ loginLogs, params }) => {
  const getDatesBetween = (start, end, format = 'YYYY-MM-DD') => {
    const startDate = moment(start);
    const endDate = moment(end);
    const dates = [];
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
      dates.push(startDate.format(format));
      startDate.add(1, 'days');
    }
    return dates;
  };
  const getWeeksBetween = (start, end, format = 'WW') => {
    const startDate = moment(start);
    const endDate = moment(end);
    const startWeek = startDate.isoWeek();
    const endWeek = endDate.isoWeek();
    const startYear = startDate.isoWeekYear();
    const endYear = endDate.isoWeekYear();

    const weeks = Array.from({ length: (endYear - startYear) * 52 + (endWeek - startWeek + 1) }, (_, i) => {
      const currentWeek = startWeek + i;
      const adjustedWeek = ((currentWeek - 1) % 52) + 1;
      return `${adjustedWeek}`;
    });
    return weeks;
  };

  if (!loginLogs || !params) {
    return <></>;
  }

  let dates = [];
  let weeks = [];
  let weekMode = false;
  if (params?.timestamp?.from && params?.timestamp?.to) {
    const differenceInDays = moment(params.timestamp.to).diff(moment(params.timestamp.from), 'days');
    if (differenceInDays > 32) {
      weekMode = true;
      dates = getDatesBetween(params.timestamp.from, params.timestamp.to);
      weeks = getWeeksBetween(params.timestamp.from, params.timestamp.to);
    } else {
      dates = getDatesBetween(params.timestamp.from, params.timestamp.to);
    }
  }

  let title = '每日登入次數';
  let data;
  if (weekMode) {
    title = '每週登入次數';
    data = [{
      id: '登入次數',
      data: weeks.map((week) => {
        const filteredLoginLogs = loginLogs.filter((item) => moment(item.timestamp).tz(TIME_ZONE).format('WW') === week);
        const lineValue = {
          x: moment().week(week).startOf('week').format('MM-DD'),
          y: filteredLoginLogs.filter((item) => item.action === '登入').length,
        };
        return lineValue;
      }),
    }];
  } else {
    data = [{
      id: '登入次數',
      data: dates.map((date) => {
        const filteredLoginLogs = loginLogs.filter((item) => moment(item.timestamp).tz(TIME_ZONE).format('YYYY-MM-DD') === date);
        const lineValue = {
          x: date.slice(-5),
          y: filteredLoginLogs.filter((item) => item.action === '登入').length,
        };
        return lineValue;
      }),
    }];
  }
  const colors = ({ id }) => {
    return '#00913A';
  };
  return <LineCard title={title} data={data} colors={colors} />;
};

LoginChart.propTypes = {
  loginLogs: PropTypes.array,
  params: PropTypes.any,
};

export default function AuditTrailTable({
  title = '使用紀錄',
  description,
  organizationId: inOrganizationId,
  clientId: inClientId,
  facilityId: inFacilityId,
  ...props
}) {
  const { selectedUserGroupParams } = useCache();

  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [listQueries, setListQueries] = useState();

  const organizationId = inOrganizationId || cache.get('app:organizationId');
  const facilityId = inFacilityId || cache.get('app:facilityId');
  // const clientId = inClientId || cache.get('app:clientId');

  const options = {};

  const columns = [
    // {
    //   name: 'id',
    //   label: '編號',
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: 'timestamp',
      label: '時間',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'groupName',
      label: '權限',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '帳號',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'action',
      label: '操作動作',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },

  ];

  useEffect(() => {
    if (organizationId) {
      const { organizationClientIds } = selectedUserGroupParams;

      setListQueries(organizationClientIds.map((clientId) => {
        return {
          name: getAuditTrailsByFacilityIdByTimestamp,
          params: {
            facilityId: clientId,
          },
        };
      }));

      setIndexes(undefined);

      return;
    }

    if (facilityId) {
      setIndexes([
        {
          name: 'byFacilityIdByTimestamp',
          label: '按機構查詢',
          partitionKey: 'facilityId',
          sortKey: 'timestamp',
          fields: [
            // {
            //   label: '機構編號',
            //   key: 'facilityId',
            // },
            {
              label: `時間`,
              key: 'timestamp',
              type: 'datetime',
            },
          ],
          operation: getAuditTrailsByFacilityIdByTimestamp,
        },
      ]);

      setQueryParams({
        facilityId,
        timestamp: {
          from: pastThreeDays,
          to: today,
        },
      });

      return;
    }

    // Admin
    (async () => {
      const clientOptions = await getClientIdSchema(null, '所屬機構', true);
      setIndexes([
        {
          name: 'getAuditTrailsByGroupNameByTimestamp',
          label: '按權限查詢',
          partitionKey: 'groupName',
          sortKey: 'timestamp',
          fields: [
            {
              label: '權限',
              key: 'groupName',
              type: 'select',
              options: [
                'admin',
                'organization',
                'facility',
                'delivery',
                'client',
              ].map((item) => {
                return { value: item, label: item };
              }),

            },
            {
              label: `時間`,
              key: 'timestamp',
              type: 'datetime',
            },
          ],
          operation: getAuditTrailsByGroupNameByTimestamp,
        },
        {
          name: 'byFacilityIdByTimestamp',
          label: '按機構查詢',
          partitionKey: 'facilityId',
          sortKey: 'timestamp',
          fields: [{
            label: '機構',
            key: 'facilityId',
            type: 'select',
            options: clientOptions.map(({ id, name }) => ({ value: id, label: name })),
          }, {
            label: `時間`,
            key: 'timestamp',
            type: 'datetime',
          }],
          operation: getAuditTrailsByFacilityIdByTimestamp,
        },
        {
          name: 'scan',
          label: '掃描全部資料',
          key: 'timestamp',
          fields: [{
            label: '操作動作',
            key: 'action',
          }, {
            label: `時間`,
            key: 'timestamp',
            type: 'datetime',
          }],
          operation: listAuditTrails,
        },
      ]);

      setQueryParams({
        groupName: 'admin',
        timestamp: {
          from: pastThreeDays,
          to: today,
        },
      });
    })();
  }, [organizationId, facilityId]);

  if (!indexes || !queryParams) return <></>;

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        listQueries={listQueries}
        {...props}
      >
        {(data, params) => <LoginChart loginLogs={data} params={params} />}
      </DataTable>
    </React.Fragment>
  );
}

AuditTrailTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  organizationId: PropTypes.string,
  clientId: PropTypes.string,
  facilityId: PropTypes.string,
};
