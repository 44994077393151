export const getSampleOrder = (props = {}) => {
  const {
    elderId,
    noteForDelivery = '',
    noteForMeal = '',
    note,
    paymentMethod = 'PAID',
    deliveryGroupId,
    deliveryGroupSortOrder,
  } = props;
  return {
    elderId,
    noteForDelivery,
    noteForMeal,
    note,
    paymentMethod,
    deliveryGroupId,
    deliveryGroupSortOrder,
    mealItemName: '',
    mealItemQuantity: 1,
    mealItemPrice: 0,
    mealItemCost: 0,
    mealItemNote: '',
  };
};

export const ORDER_CATEGORY = ['送餐', '送物資', '送餐和物資', '藝術', '醫療'];
