import Colors from './Colors';

export default [
  {
    label: '使用中',
    value: 'active',
    color: Colors.primary,
  },
  {
    label: '已過期',
    value: 'expired',
    color: Colors.dark,
  },
];
