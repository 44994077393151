export default [
  {
    label: 'Uber',
    value: 'uber',
  },
  {
    label: 'Pandago',
    value: 'pandago',
  },
  {
    label: 'I-Link',
    value: 'ilink',
  },
  {
    label: 'Lalamove',
    value: 'lalamove',
  },
  {
    label: 'Lalamove(多點配送)',
    value: 'lalamove(multi-stop)',
  },
];
