/* eslint-disable quotes, comma-dangle */
import {
  membershipStatus,
} from '@silvergatedelivery/constants';

export default {
  "title": "付費會員",
  "type": "object",
  "required": [
    "clientId", "status", "transactionId", "membershipProgramId",
    "expiredAt", "discountRule", "discountPercentage"
  ],
  "properties": {
    "clientId": {
      "type": "string",
      "title": "客戶編號"
    },
    "status": {
      "type": "string",
      "title": "狀態",
      'enum': membershipStatus.map(({ value }) => value),
      'enumNames': membershipStatus.map(({ label }) => label),
      'default': membershipStatus[0].value,
    },
    "transactionId": {
      "type": "string",
      "title": "交易編號"
    },
    "membershipProgramId": {
      "type": "string",
      "title": "會員項目編號"
    },
    "expiredAt": {
      "type": "string",
      "title": "截止於",
      "format": "datetime"
    },
    "discountRule": {
      "type": "string",
      "title": "折扣名稱"
    },
    "discountPercentage": {
      "type": "number",
      "title": "折扣"
    }
  }
};
