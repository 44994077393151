/* eslint-disable quotes, comma-dangle */
export default {
  "title": "會員項目",
  "type": "object",
  "required": [
    "isActive", "name", "description",
    "durationInDays", "discountRule", "discountPercentage",
    "unit", "unitValue"
  ],
  "properties": {
    "isActive": {
      "type": "number",
      "title": "狀態",
      "enum": [1, 0],
      "enumNames": ["使用", "停用"],
      "default": 1
    },
    "name": {
      "type": "string",
      "title": "名稱"
    },
    "description": {
      "type": "string",
      "title": "詳細描述"
    },
    "durationInDays": {
      "type": "number",
      "title": "使用天數"
    },
    "discountRule": {
      "type": "string",
      "title": "折扣描述"
    },
    "discountPercentage": {
      "type": "number",
      "title": "折扣",
      "exclusiveMinimum": 0,
      "exclusiveMaximum": 1
    },
    "unit": {
      "type": "string",
      "title": "單位 (ex: 月/季/年)"
    },
    "unitValue": {
      "type": "number",
      "title": "單位價格"
    }
  }
};
