import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import { utils, writeFile } from 'xlsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { request, asyncListAll } from 'utilities/graph';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import {
  getOrder,
  getPandagoOrdersByProcessStatusByShouldBeSentToPandago,
} from './queries';
import { sortBy } from 'utilities/sorting';
import { formatAddress, formatDatetime } from 'utilities/format';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

/**
熊貓計算方式
起價70+5/每公里

愛鄰計算方式
基本配送一公里70元，超過一公里，一至三公里，
每公里加收10元，3公里以上每一公里加收15元，不足1公里以1公里計算
 */

export default function PandagoReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    const period = `${fromDate}__${toDate}`;

    global.logger.debug({
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const [
      allPandagoOrders,
    ] = await Promise.all([
      asyncListAll(getPandagoOrdersByProcessStatusByShouldBeSentToPandago, {
        processStatus: 'fulfilled',
        shouldBeSentToPandagoAt: {
          between: [fromISOString, toISOString],
        },
        limit: 1000,
      }),
    ]);

    const data = await Promise.all(
      allPandagoOrders.map(async ({
        orderId,
        pandagoOrderId,
        status,
        paymentMethod,
        amount,
        fee,
        cancelledBy,
        cancelledReason,
        driver,
      }) => {
        const { data: { getOrder: order } } = await request(getOrder, { id: orderId });

        if (!order) {
          return {
            '日期': '',
            '餐別': '',
            '編號': pandagoOrderId,
            '餐廳': '',
            '名字': '',
            '狀態': status,
            '銀色大門訂單狀態': '無批配訂單',
            '收費方式': paymentMethod,
            '應收費金額': amount,
            '預估距離': '',
            // '預估車程': '',
            '預估送餐費用': '',
            '實際送餐費用': fee,
            '標記': '',
            '餐廳地址': '',
            '餐廳地址經緯度': '',
            'Google起點經緯度': '',
            '送餐地址': '',
            '送餐經緯度': '',
            'Google終點經緯度': '',
            '送餐員姓名': '',
            '送餐員電話': '',
            '取消者': cancelledBy,
            '取消原因': cancelledReason,
          };
        }

        const {
          date,
          mealSlot,
          status: orderStatus,
          elder: { name: elderName, address: elderAddress },
          restaurant: { name: restaurantName, address: restaurantAddress },
          direction: {
            // startAddress,
            // endAddress,
            distance,
            // duration,
            startLocation,
            endLocation,
            distanceInMeters,
          },
        } = order;

        const {
          name: driverName,
          phoneNumber: driverPhoneNumber,
        } = driver;

        const calculatedFee = 70 + (distanceInMeters > 1000 ? Math.round((distanceInMeters - 1000) / 1000) * 5 : 0);

        const flag = calculatedFee !== fee ? '*' : '';
        return {
          '日期': formatDatetime(date, { year: true, day: true, weekday: false, hour: false, minute: false, second: false }),
          '餐別': mealSlot === 'lunch' ? '午餐' : '晚餐',
          '編號': pandagoOrderId,
          '餐廳': restaurantName,
          '名字': elderName,
          '狀態': status,
          '銀色大門訂單狀態': orderStatus,
          '收費方式': paymentMethod,
          '應收費金額': amount,
          '預估距離': distance,
          // '預估車程': duration,
          '預估送餐費用': calculatedFee,
          '實際送餐費用': fee,
          '標記': flag,
          '餐廳地址': formatAddress(restaurantAddress),
          '餐廳地址經緯度': `${restaurantAddress.lat},${restaurantAddress.lng}`,
          'Google起點經緯度': `${startLocation.lat},${startLocation.lng}`,
          '送餐地址': formatAddress(elderAddress),
          '送餐地址經緯度': `${elderAddress.lat},${elderAddress.lng}`,
          'Google終點經緯度': `${endLocation.lat},${endLocation.lng}`,
          '送餐員姓名': driverName,
          '送餐員電話': driverPhoneNumber,
          '取消者': cancelledBy,
          '取消原因': cancelledReason,
        };
      }),
    );

    global.logger.debug(data);

    const rows = data
      .sort(sortBy('日期'));

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, `Pandago`);
    writeFile(workbook, `Pandago送餐資訊__${selectedMonth}.xlsx`);
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Pandago 月訂單輸出</h3>
      <FormControl style={{ width: 150, marginRight: 16 }}>
        <InputLabel id="selected-month">月份</InputLabel>
        <Select
          labelId="selected-month-label"
          id="selected-month"
          value={selectedMonth}
          onChange={(event) => setSelectedMonth(event.target.value)}
          disabled={isLoading}
        >
          {monthOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}
