import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { processStatus } from '@silvergatedelivery/constants';

import DataTable from 'components/Table/DataTable';
import {
  listSponsorships,
  getSponsorshipsByStatusByCreatedAt,
  getSponsorshipsByClientIdByStatus,
} from 'graphql/queries';
import EditButton from 'forms/SponsorshipForm/SponsorshipEditButton';
import { sortBy } from 'utilities/sorting';
import ProcessStatusChip from 'components/ProcessStatusChip';
import LinkButton from 'components/Table/LinkButton';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import SponsorshipFulfillmentTable from 'components/SponsorshipFulfillmentTable';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'status',
    label: '狀態',
    options: {
      filter: true,
      sort: true,
      customBodyRender(value) {
        return (
          <ProcessStatusChip status={value} />
        );
      },
    },
  },
  {
    name: 'sponsorProgram.name',
    label: '項目',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'client',
    label: '客戶',
    options: {
      filter: false,
      sort: true,
      customBodyRender(client = {}) {
        return (
          <LinkButton
            path={`/client/${client.id}`}
            text={client.name}
            label="前往客戶頁面"
            newTab={true}
          />
        );
      },
    },
  },
  {
    name: 'count',
    label: '數量',
    type: 'number',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unit',
    label: '單位',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unitValue',
    label: '單位價格',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'totalValue',
    label: '總金額',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fulfilledCount',
    label: '已使用的數量',
    type: 'number',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fulfilledValue',
    label: '已使用的金額',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function SponsorshipTable({
  title = '捐贈紀錄',
  description,
  clientId,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <SponsorshipFulfillmentTable
            sponsorshipId={id}
            showQueryGenerator={false}
          />
        </NestedTableContainer>
      );
    },
  };

  useEffect(() => {
    if (clientId) {
      setIndexes([
        {
          name: 'getSponsorshipsByClientIdByStatus',
          label: '按客戶狀態查詢',
          partitionKey: 'clientId',
          sortKey: 'status',
          fields: [{
            label: '狀態',
            key: 'status',
            type: 'select',
            options: processStatus,
          }],
          operation: getSponsorshipsByClientIdByStatus,
        },
      ]);
      setQueryParams({
        clientId,
        status: processStatus[0].value,
      });
    } else {
      setIndexes([
        {
          name: 'getSponsorshipProgramsByIsActiveByCreatedAt',
          label: '按狀態查詢',
          partitionKey: 'status',
          sortKey: 'createdAt',
          fields: [{
            label: '狀態',
            key: 'status',
            type: 'select',
            options: processStatus,
          }],
          operation: getSponsorshipsByStatusByCreatedAt,
        },
        {
          name: 'scan',
          label: '掃描全部資料',
          fields: [],
          operation: listSponsorships,
        },
      ]);
      setQueryParams({
        status: processStatus[0].value,
      });
    }
  }, [clientId]);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={EditButton}
      dataSortFunc={sortBy('status')}
    />
  );
}

SponsorshipTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
};
