import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDatetime, formatDatetimeFromNow } from 'utilities/format';
import InfoCard from '../Card/InfoCard';
import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
import { getTranslatedOrderStatus, getTranslatedMealSlots } from 'utilities/translate';
import { cancellationReasons } from '@silvergatedelivery/constants';

export default function OrderCard({ data: inData, onUpdate, ...props }) {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  let orderStatusString = (getTranslatedOrderStatus().find((item) => item.value === data.status) || {}).label;
  if (data.status === 'cancelled' && data.cancellationReason) {
    const reason = (cancellationReasons.find((item) => item.value === data.cancellationReason) || {}).label || '';
    orderStatusString += ` (${reason})`;
    if (data.cancellationNote) {
      orderStatusString += ` - ${data.cancellationNote}`;
    }
  }
  const mappings = [
    { key: 'id', label: '編號' },
    { key: 'county', label: '縣市' },

    { key: 'deliveryBy', label: '日期時間', format: formatDatetime },
    { key: 'mealSlot', label: '區間', format: (value) => (getTranslatedMealSlots().find((item) => item.value === value) || {}).label },
    { key: 'status', label: '狀態', format: () => (orderStatusString) },
    { key: 'category', label: '類別' },

    { key: 'tier', label: '目前接單層級' },
    { key: 'tierExpiredAt', label: '下次更新接單層級時間', format: formatDatetimeFromNow, expand: true },

    { key: 'noteForMeal', label: `${t('餐點')}備註` },
    { key: 'noteForDelivery', label: `${t('送餐')}備註` },
    { key: 'note', label: '其他備註' },
    { key: 'total', label: `${t('餐點')}總金額` },
    { key: 'deliveryStaffFee', label: t('送餐費用') },

    { key: 'isAudited', label: '需定期檢查訂單', format: (value) => value === 0 ? '是' : '否', expand: false },
    { key: 'nextStatusCheckAt', label: '下次定期檢查訂單時間', format: formatDatetimeFromNow, expand: false },
  ];

  useEffect(() => {
    if (inData) {
      global.logger.debug('inData', inData);
      setData(inData);
    }
  }, [inData]);

  return (
    <InfoCard
      title="訂單資料"
      mappings={mappings}
      data={data}
      link={`/order/${data.id}`}
      editButton={AdminOrderEditButton}
      onUpdate={(data)=>{
        setData(data);
        onUpdate && onUpdate(data);
      }}
      {...props}
    />
  );
}

OrderCard.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
