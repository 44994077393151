import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

import Version from 'components/Version';

export default function Footer() {
  return (
    <Container maxWidth="md" style={{ padding: 16 }}>
      <Grid container>
        <Grid item xs={12} container justifyContent="center">
          <Typography
            variant="body2"
          >
            © {moment().format('YYYY')} 銀色大門 All Rights Reserved&nbsp;
          </Typography>
          <Version variant="body2" color="textPrimary" />
          <Link
            href="/privacy-policy"
            target="_blank"
            rel="noopener"
            variant="body2"
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            隱私權及保護政策
          </Link>
          <Link
            href="/service-terms"
            target="_blank"
            rel="noopener"
            variant="body2"
          >
            會員服務條款
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
