import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import { GOOGLE_KEY } from 'credentials.js';

const mapLayer = 'openstreetmap'; // google or openstreetmap

const commonIconProps = {
  shadowUrl: '/images/marker/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
};

const iconColorList = ['green', 'black', 'blue', 'gold', 'grey', 'orange', 'red', 'violet', 'yellow'];

const iconList = iconColorList.map((color) => ({
  color,
  icon: new L.Icon({ iconUrl: `/images/marker/marker-icon-${color}.png`, ...commonIconProps }),
}));

L.NumberedDivIcon = L.Icon.extend({
  options: {
    iconUrl: '/images/marker/marker-icon-blue.png',
    shadowUrl: '/images/marker/marker-shadow.png',
    number: '',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: 'leaflet-div-icon',
  },

  createIcon: function() {
    const div = document.createElement('div');
    const img = this._createImg(this.options['iconUrl']);
    div.appendChild( img );
    if (this.options['number']) {
      const numdiv = document.createElement('div');
      numdiv.setAttribute( 'class', 'number' );
      numdiv.innerHTML = this.options['number'] || '';
      div.appendChild( numdiv );
    }
    this._setIconStyles(div, 'icon');
    return div;
  },

  createShadow: function() {
    const div = document.createElement('div');
    const imgShadow = this._createImg(this.options['shadowUrl']);
    div.appendChild( imgShadow );
    this._setIconStyles(div, 'shadow');
    return div;
  },
});

const getIcon = (inColor) => (iconList.find(({ color }) => color === inColor) || iconList[0]).icon;

function MapMarker({ locations = [], locationFocused = undefined, showMap = false, onClick }) {
  const [map, setMap] = useState(null);
  const defaultCenter = { lat: 23.4851712, lng: 120.4178814 };

  useEffect(() => {
    if (map) {
      let inputCoordinates = [];
      if (locations && locations.length != 0) {
        inputCoordinates = locations.filter(({ lat, lng }) => ( lat && lng))
          .map(({ lat, lng }) => [lat, lng]);
      }
      if (locationFocused && locationFocused.lat && locationFocused.lng) {
        inputCoordinates.push([locationFocused.lat, locationFocused.lng]);
      }
      if (inputCoordinates.length !== 0) {
        const bounds = L.latLngBounds(inputCoordinates);
        map.fitBounds(bounds);
      }
    }
  }, [locations, locationFocused, map]);

  useEffect(() => {
    if (map && showMap) {
      map.invalidateSize();
    }
  }, [showMap]);

  return (
    <MapContainer
      center={[defaultCenter.lat, defaultCenter.lng]}
      zoom={12}
      style={{ height: '100%', width: '100%' }}
      scrollWheelZoom={true}
      whenCreated={setMap}
    >
      {mapLayer === 'openstreetmap' && (<TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributorsa'
      />)}
      {mapLayer === 'google' && (<ReactLeafletGoogleLayer apiKey={GOOGLE_KEY}/>)}
      {locationFocused && locationFocused.lat && locationFocused.lng && (
        <Marker
          key={'location-focused'}
          position={[locationFocused.lat, locationFocused.lng]}
          icon={getIcon('green')}
          zIndexOffset={100}
        >
          <Popup>{locationFocused.name}</Popup>
        </Marker>
      )}
      {locations && locations.map((location, index) => (
        location.lat && location.lng &&
          <Marker
            key={index}
            position={[location.lat, location.lng]}
            icon={location.color ? getIcon(location.color) :
              new L.NumberedDivIcon({ number: location.index, iconUrl: '/images/marker/marker-icon-blue.png' })}
          >
            <Popup>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {location.name}
                {onClick && (
                  <IconButton
                    onClick={() => onClick(location)}
                    size={'small'}
                  >
                    <SearchIcon fontSize={'inherit'} />
                  </IconButton>
                )}
              </Box>
            </Popup>
          </Marker>
      ))}
    </MapContainer>
  );
}

MapMarker.propTypes = {
  locations: PropTypes.array,
  locationFocused: PropTypes.object,
  showMap: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MapMarker;
