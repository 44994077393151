export default [
  {
    label: '管理員',
    value: 'Admins',
    backendSignIn: true,
  },
  {
    label: '組織負責人',
    value: 'OrgAdmins',
    backendSignIn: true,
  },
  {
    label: '機構管理員',
    value: 'FacilityAdmins',
    backendSignIn: true,
  },
  {
    label: '送餐大使',
    value: 'DeliveryStaffs',
    backendSignIn: false,
  },
  {
    label: '餐廳負責人',
    value: 'RestaurantOwners',
    backendSignIn: false,
  },
  {
    label: '客戶',
    value: 'Clients',
    backendSignIn: true,
  },
];
