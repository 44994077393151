import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';

export default function ILinkOrderLogTable({
  title = '訂單更新紀錄',
  description,
  data,
  ...props
}) {
  const options = {
    rowsPerPageOptions: [5, 10, 20, 50],
    rowsPerPage: 5,
  };

  const columns = [
    {
      name: 'Type_Code',
      label: '狀態代碼',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Type',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Time',
      label: '更新於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        {...props}
      />
    </React.Fragment>
  );
}

ILinkOrderLogTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};
