import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import EditButton from '../components/EditButton';
import ElderForm from './ElderForm';
import { useCache } from 'CacheProvider';

export default function ElderEditButton(props) {
  const { mode, item } = props;
  const { appGroup, selectedUserGroupParams } = useCache();

  if (appGroup === 'OrgAdmins') {
    const { organizationData } = selectedUserGroupParams;
    if (organizationData?.clients?.items) {
      if (mode === 'add') {
        if (!organizationData.clients.items.some(({ elderSetting }) => elderSetting)) {
          return null;
        }
      } else if (item) { // edit mode
        const { elderSetting } = organizationData.clients.items.find(({ clientId }) => clientId === item.clientId) || {};
        if (!elderSetting) {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  return (
    <EditButton
      title={i18n.t('送餐對象')}
      form={ElderForm}
      {...props}
    />);
}

ElderEditButton.propTypes = {
  mode: PropTypes.string,
  item: PropTypes.object,
};
