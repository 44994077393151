// import React from 'react';
import { Colors, orderStatus } from '@silvergatedelivery/constants';
import { getStartEndForDeliveryTime } from 'utilities/format';
import { getTranslatedMealSlots } from 'utilities/translate';

const getOrderStatusProps = (status) => {
  return orderStatus.find(({ value }) => value === status);
};

const getDeliveryScheduleStatusProps = (status) => {
  switch (status) {
    case 'available':
      return { label: '可接單', color: Colors.active };
    case 'cancelled':
      return { label: '取消', color: Colors.light };
    default:
      return { label: status, color: Colors.dark };
  }
};

const getMealName = (mealSlot) => {
  return getTranslatedMealSlots().find(({ value }) => value === mealSlot).label;
};

// event obj
// https://fullcalendar.io/docs/event-object

export const sortOrder = (a, b) => {
  if (a.deliveryBy > b.deliveryBy) return 1;
  if (a.deliveryBy < b.deliveryBy) return -1;
  if (a.deliveryGroupId && b.deliveryGroupId) {
    if (a.deliveryGroupId > b.deliveryGroupId) return 1;
    if (a.deliveryGroupId < b.deliveryGroupId) return -1;
    if (a.deliveryGroupSortOrder > b.deliveryGroupSortOrder) return 1;
    if (a.deliveryGroupSortOrder < b.deliveryGroupSortOrder) return -1;
  }
  return a.elder.name > b.elder.name ? 1 : -1;
};

// // TODO: query based on the current view + status
export const getOrderEvents = (inOrders = []) => {
  const events = inOrders.sort(sortOrder).map((item) => {
    const mealName = getMealName(item.mealSlot);
    const {
      color,
      // label: statusLabel,
    } = getOrderStatusProps(item.status);
    // const title = `[${statusLabel}] ${item.elder.name} (${item.restaurant.name})`;
    const title = `${item.elder.name} (${item.restaurant.name})`;
    // item.mealName = mealName;

    const { start, end } = getStartEndForDeliveryTime(item.deliveryBy);
    return {
      title,
      start,
      end,
      // color,
      backgroundColor: color,
      extendedProps: {
        type: 'order',
        data: item,
        mealName,
        description: '',
      },
    };
  });

  return events;
};

export const getRestaurantScheduleEvents = (inRestaurantSchedules = []) => {
  const events = inRestaurantSchedules.map((item) => {
    const mealName = getMealName(item.mealSlot);
    const { color, label: statusLabel } = getDeliveryScheduleStatusProps(item.status);
    const title = `[餐廳] [${statusLabel}] ${item.restaurant.name}`;
    item.mealName = mealName;

    return {
      title,
      start: item.start,
      end: item.end,
      backgroundColor: color,
      extendedProps: item,
      // display: 'background',
    };
  });

  return events;
};
