import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import UberOrderStatusChip from 'components/UberOrderStatusChip';

export default function UberOrderLogTable({
  title = '訂單更新紀錄',
  description,
  data,
  ...props
}) {
  const options = {
    rowsPerPageOptions: [5, 10, 20, 50],
    rowsPerPage: 5,
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <UberOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'courier',
      label: '送餐員',
      type: 'json',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'cancelationReasonObj',
      label: '取消原因',
      type: 'json',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'undeliverableReason',
      label: '無法送達理由',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'created',
      label: '創立於',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        {...props}
      />
    </React.Fragment>
  );
}

UberOrderLogTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};
