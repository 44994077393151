import React from 'react';

import EditButton from '../components/EditButton';
import Form from './SponsorshipForm';

export default function Button({ ...props }) {
  return (
    <EditButton
      title="捐贈紀錄"
      form={Form}
      {...props}
    />);
}
