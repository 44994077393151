import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InfoCard from './InfoCard';
import { request } from 'utilities/graph';
import { getOrganization } from 'graphql/queries';
import OrganizationEditButton from 'forms/OrganizationForm/OrganizationEditButton';

export default function OrganizationCard({ id, title = '組織資料', data: inData, showMap, ...props }) {
  const [data, setData] = useState({});

  const mappings = [
    { key: 'name', label: '姓名' },
    { key: 'description', label: '描述' },
  ];

  useEffect(() => {
    (async () => {
      if (inData) {
        setData(inData);
      } else
      if (id) {
        const { data: { getOrganization: data } } = await request(getOrganization, { id });
        setData(data);
      }
    })();
  }, [inData, id]);

  return (
    <InfoCard
      title={title}
      mappings={mappings}
      data={data}
      link={data.id ? `/organization/${data.id}` : null}
      editButton={OrganizationEditButton}
      onUpdate={setData}
      {...props}
    />
  );
}

OrganizationCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showMap: PropTypes.bool,
  data: PropTypes.object,
};
