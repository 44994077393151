import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';

import { Colors } from '@silvergatedelivery/constants';
import Loading from 'components/Loading';
import SummaryCard from './components/SummaryCard';
import PieCard from './components/PieCard';
import BarCard from './components/BarCard';
import PlotCard from './components/PlotCard';
import {
  getOrders,
  getIssues,
  getStartEndByPeriodName,
} from './helpers';
import { getChartDataMappings } from './charts';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';
import moment from 'moment';

const periodOptions = [{
  label: '本日',
  value: 'day',
}, {
  label: '本週',
  value: 'week',
}, {
  label: '本月',
  value: 'month',
}, {
  label: '今年',
  value: 'year',
}];

const periodOptions2 = [{
  label: '昨日',
  value: 'day/-1/-1',
  // }, {
  //   label: '過去7日',
  //   value: 'day/-7/0',
}, {
  label: '上週',
  value: 'week/-1/-1',
}, {
  label: '前月',
  value: 'month/-1/-1',
  // }, {
  //   label: '過去3個月',
  //   value: 'month/-3/0',
}, {
  label: '去年',
  value: 'year/-1/-1',
}];

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: Colors.background.light,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonGroup: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function Dashboard({ organizationId: inOrganizationId, clientIds }) {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [chartData, setChartData] = useState();
  const [period, setPeriod] = useState(cache.get('app:dashboard:period') || 'day'); // day, week, month, year
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const { selectedUserGroupParams } = useCache();
  // eslint-disable-next-line no-unused-vars
  const [lastProgressUpdated, setLastProgressUpdated] = useState();
  const progressStatus = useRef({});

  const organizationId = inOrganizationId || cache.get('app:organizationId');

  useEffect(() => {
    if (!organizationId) return;

    (async () => {
      setIsLoading(true);

      cache.set('app:dashboard:period', period);

      const onUpdateLastItem = (item, nextToken) => {
        if (nextToken) {
          progressStatus.current[item.clientId] = item.date;
          setLastProgressUpdated(moment().toISOString());
        } else {
          if (item) {
            progressStatus.current[item.clientId] = '完成';
            setLastProgressUpdated(moment().toISOString());
          }
        }
      };

      progressStatus.current = {};

      const {
        start,
        end,
        // prevStart,
        // prevEnd,
        startFormat,
        endFormat,
      } = getStartEndByPeriodName(period);

      setTitle(`${startFormat} - ${endFormat}`);

      global.logger.debug({ period, start, end });

      const { organizationClientIds } = selectedUserGroupParams;

      const [
        orders,
        issues,
      ] = await Promise.all([
        getOrders(clientIds || organizationClientIds, startFormat, endFormat, onUpdateLastItem),
        getIssues(clientIds || organizationClientIds, start, end),
      ]);

      setData({
        orders,
        // prevOrders,
        issues,
        // prevIssues,
      });

      setIsLoading(false);
    })();
  }, [period, organizationId]);

  useEffect(() => {
    if (!data.orders || !data.issues) return;

    const chartDataMappings = getChartDataMappings(data, period);
    const chartData = Object.keys(chartDataMappings)
      .map((key) => chartDataMappings[key]);

    global.logger.debug({ chartDataMappings, chartData });

    setChartData(chartData);
  }, [data]);

  const getClientName = (id) => {
    const { client } = selectedUserGroupParams.organizationData.clients.items.find(({ client }) => client.id === id);
    return client.name;
  };

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.filterContainer}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="h5" component="h1" style={{ flex: 1, paddingLeft: 8 }}>
                {title}
              </Typography>

              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions2.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </Grid>
          </Paper>
        </Grid>
        {isLoading &&
          <>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
              {progressStatus.current && Object.keys(progressStatus.current).map((key) => {
                return <Typography key={key} variant="body1" color="textPrimary" style={{ flex: 1, textAlign: 'center' }}>
                  {getClientName(key)} 讀取進度：{progressStatus.current[key]}
                </Typography>;
              })}
            </div>
            <Loading fullScreen={false} />
          </>}
        {!isLoading && chartData &&
          <React.Fragment>
            {chartData.map((data, index) => (
              <Grid item xs={12} md={data.width || 2} key={index}>
                {renderChart(data, period)}
              </Grid>
            ))}
          </React.Fragment>}
      </Grid>
    </Container>
  );
}


Dashboard.propTypes = {
  organizationId: PropTypes.string,
  clientIds: PropTypes.array,
};


function renderChart({ type, title, value, prevValue, unit, appendix, data, data2, dataKeys, mode, colors }, period) {
  switch (type) {
    case 'summary':
      return (<SummaryCard title={title} value={value} prevValue={prevValue} unit={unit} appendix={appendix} period={period} />);
    case 'pie':
      return (<PieCard title={title} data={data} data2={data2} />);
    case 'bar':
      return (<BarCard title={title} data={data} dataKeys={dataKeys} mode={mode} colors={colors} />);
    case 'plot':
      return (<PlotCard title={title} data={data} />);
    default:
  }
}
