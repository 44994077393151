import React, { useEffect, useState } from 'react';

import Table from 'components/Table/Table';
import {
  listConfigurations,
} from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import ConfigurationEditButton from 'forms/ConfigurationForm/ConfigurationEditButton';

const title = '設定';
const description = '';

export default function ConfigurationTable() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const options = {};

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'category',
      label: '類別',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'key',
      label: '名稱',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'value',
      label: '數值',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'description',
      label: '描述',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const data = await asyncListAll(listConfigurations);
      setData(data
        .sort(sortBy('key'))
        .sort(sortBy('category')),
      );

      setIsLoading(false);
    })();
  }, [lastUpdatedAt]);

  return (
    <Table
      title={title}
      isLoading={isLoading}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onRefresh={() => setLastUpdatedAt(Date.now())}
      editButton={ConfigurationEditButton}
    />
  );
}
