export const getRestaurantMealsByRestaurantByIsActive = /* GraphQL */ `
  query GetRestaurantMealsByRestaurantByIsActive(
    $restaurantId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantMealsByRestaurantByIsActive(
      restaurantId: $restaurantId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        previewS3Keys
        imageS3Keys
        createdAt
        # createdBy
        updatedAt
        # updatedBy
        tags {
          items {
            tag {
              category
              subcategory
              color
              label
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getRestaurantsByCountyByIsActive = /* GraphQL */ `
  query GetRestaurantsByCountyByIsActive(
    $county: String
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantsByCountyByIsActive(
      county: $county
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        # restaurantOwnerId
        isActive
        isPublic
        county
        # clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        # isAddedToPandago
        # createdAt
        # createdBy
        # updatedAt
        # updatedBy
        # tags {
        #   items {
        #     id
        #     restaurantId
        #     tagId
        #     createdAt
        #     createdBy
        #     updatedAt
        #     updatedBy
        #     restaurant {
        #       id
        #       owner
        #       restaurantOwnerId
        #       isActive
        #       county
        #       clientId
        #       category
        #       name
        #       description
        #       phoneNumber
        #       notes
        #       previewS3Keys
        #       imageS3Keys
        #       isAddedToPandago
        #       createdAt
        #       createdBy
        #       updatedAt
        #       updatedBy
        #     }
        #     tag {
        #       id
        #       category
        #       subcategory
        #       color
        #       label
        #       createdAt
        #       createdBy
        #       updatedAt
        #       updatedBy
        #     }
        #   }
        #   nextToken
        # }
      }
      nextToken
    }
  }
`;
