export const getEldersByClientByStatus = /* GraphQL */ `
  query GetEldersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        noteForDelivery
        noteForMeal
        noteForHealth
      }
      nextToken
    }
  }
`;
