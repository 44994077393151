import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import shortuuid from 'short-uuid';

import DataTable from 'components/Table/DataTable';
import {
  listUberOrders,
  getUberOrdersByStatusByShouldBeSentToUber,
  getUberOrdersByProcessStatusByShouldBeSentToUber,
  getUberOrdersByOrderByCreatedAt,
} from 'graphql/queries';
import Order from 'components/Order/Order';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import {
  // LAUNCH_DATE,
  TIME_ZONE,
  uberOrderStatus,
  processStatus,
} from '@silvergatedelivery/constants';
import ProcessStatusChip from 'components/ProcessStatusChip';
import UberOrderStatusChip from 'components/UberOrderStatusChip';
import UberOrderLogTable from './UberOrderLogTable';
// import ImagePreviewAvatar from 'components/ImagePreviewAvatar';

// const past = moment(LAUNCH_DATE).tz(TIME_ZONE).format('YYYY-MM-DD');
const past = moment().add(-3, 'days').tz(TIME_ZONE).format('YYYY-MM-DD');
const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function UberOrderTable({
  title = 'Uber 訂單',
  description,
  orderId,
  data,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [showQueryGenerator, setShowQueryGenerator] = useState(true);
  const [showNestedOrder, setShowNestedOrder] = useState(true);

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const [id, uberDeliveryId, uuid, orderId, pickupId, processlogs] = rowData; // eslint-disable-line no-unused-vars

      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <UberOrderLogTable data={processlogs.map(JSON.parse).reverse()} />
          {showNestedOrder && <Order id={orderId} />}
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'uberDeliveryId',
      label: 'Uber編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'uuid',
      label: '客服單號',
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'orderId',
      label: '訂單編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },

    {
      name: 'orderId',
      label: '取餐編號',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender(value) {
          return shortuuid().fromUUID(value).slice(-6);
        },
      },
    },
    {
      name: 'processlogs',
      label: '紀錄',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'processlogs',
      label: '送餐員',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(processlogs) {
          if (processlogs && processlogs.length > 0) {
            const lastLog = JSON.parse(processlogs[processlogs.length - 1]);
            if (lastLog && lastLog.courier) {
              const name = lastLog.courier.name || '';
              const phoneNumber = lastLog.courier.phoneNumber || '';
              return `${name}${phoneNumber}`;
            }
          }
          return '';
        },
      },
    },
    {
      name: 'processStatus',
      label: '處理狀況',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <ProcessStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'processStatusMessage',
      label: '處理資訊',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <UberOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'trackingUrl',
      label: '追蹤網頁',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(trackingUrl) {
          if (!trackingUrl) {
            return;
          }
          return (
            <LinkButton
              path={trackingUrl}
              label="追蹤網頁"
            />
          );
        },
      },
    },
    {
      name: 'cancelledReason',
      label: '取消原因',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: '描述',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'fee',
      label: '送餐費用',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'shouldBeSentToUberAt',
      label: '預計送單時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: 'driver',
    //   label: '送餐員',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender({ id, name, phoneNumber }) {
    //       if (id === 'N/A') return '';

    //       return `${name} ${phoneNumber}`;
    //     },
    //   },
    // },
    // {
    //   name: 'uberOrderPayload',
    //   label: 'Payload',
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: false,
    //     customBodyRender(data) {
    //       if (!data) return '';

    //       return <ReactJson
    //         src={JSON.parse(JSON.parse(data))}
    //         name={null}
    //         displayDataTypes={false}
    //       />;
    //     },
    //   },
    // },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderId',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/order/${id}`}
              label="前往訂單頁面"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setShowQueryGenerator(false);
      setShowNestedOrder(false);
      return;
    }
    if (orderId) {
      setShowQueryGenerator(false);
      setShowNestedOrder(false);
      setIndexes([
        {
          name: 'getUberOrdersByOrderByCreatedAt',
          label: '按訂單查詢',
          partitionKey: 'orderId',
          sortKey: 'createdAt',
          fields: [{
            label: '訂單編號',
            key: 'orderId',
          }],
          operation: getUberOrdersByOrderByCreatedAt,
        },
      ]);
      setQueryParams({
        orderId,
        createdAt: {
          from: past,
          to: todayDate,
        },
      });
      return;
    }
    setIndexes([
      {
        name: 'getUberOrdersByProcessStatusByShouldBeSentToUber',
        label: '按處理狀況查詢',
        partitionKey: 'processStatus',
        sortKey: 'shouldBeSentToUberAt',
        fields: [{
          label: '處理狀況',
          key: 'processStatus',
          type: 'select',
          options: processStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToUberAt',
          type: 'datetime',
        }],
        operation: getUberOrdersByProcessStatusByShouldBeSentToUber,
      },
      {
        name: 'getUberOrdersByStatusByShouldBeSentToUber',
        label: '按Uber狀態查詢',
        partitionKey: 'status',
        sortKey: 'shouldBeSentToUberAt',
        fields: [{
          label: '狀態',
          key: 'status',
          type: 'select',
          options: uberOrderStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToUberAt',
          type: 'datetime',
        }],
        operation: getUberOrdersByStatusByShouldBeSentToUber,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '預計送單時間',
          key: 'shouldBeSentToUberAt',
          type: 'datetime',
        }],
        operation: listUberOrders,
      },
    ]);
    setQueryParams({
      processStatus: 'processing',
      shouldBeSentToUberAt: {
        from: past,
        to: todayDate,
      },
    });
  }, [data, orderId]);

  return (
    <React.Fragment>
      <DataTable
        data={data}
        title={title}
        description={description}
        themeProps={{
          cell: {
            maxWidth: undefined,
          },
        }}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        showQueryGenerator={showQueryGenerator}
        {...props}
      />
    </React.Fragment>
  );
}

UberOrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  orderId: PropTypes.string,
  data: PropTypes.array,
};
