export default [
  { value: 'A00', label: '確認叫件' },
  { value: 'A01', label: '派遣時間-指派任務' },
  { value: 'B01', label: '送達取件地' },
  { value: 'B00', label: '取貨' },
  { value: 'C00', label: '轉運' },
  { value: 'D01', label: '到達送件地' },
  { value: 'D00', label: '已配達', description: '最終貨態' },
  { value: '021', label: '未取件 物流士已離開' },
  { value: '019', label: '未配送 無法聯絡消費者' },
  { value: '024', label: '物件短少' },
  { value: '006', label: '空跑' },
  { value: '023', label: '客戶拒收' },
  { value: '003', label: '差額異動' },
  { value: '004', label: '時效異常' },
  { value: '020', label: '配送異常 商品毀損' },
  { value: '018', label: '配送異常 商品遺失' },
  { value: '017', label: '餐點未好' },
  { value: '022', label: '顧客資訊有誤' },
  { value: '026', label: '運能不足' },
  { value: '027', label: '未配送 無法聯絡消費者 物件由全球自行銷毀' },
  { value: '028', label: '顧客資訊有誤 物件由全球自行銷毀' },
  { value: '029', label: '客戶拒收 物件由全球自行銷毀' },
  { value: '030', label: '空跑 訂單取消' },
  { value: '025', label: '退貨', description: '最終貨態' },
  { value: 'E00', label: '取消', description: '最終貨態' },
];
