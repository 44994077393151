import ListAltIcon from '@material-ui/icons/ListAlt';
import MyOrders from './MyOrders/MyOrders';

export default [
  {
    title: '我的訂單',
    icon: ListAltIcon,
    paths: [{ path: '/myOrders', exact: true }],
    component: MyOrders,
  },
];
