import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DataTable from 'components/Table/DataTable';
import {
  listILinkOrders,
  getILinkOrdersByStatusByShouldBeSentToILink,
  getILinkOrdersByProcessStatusByShouldBeSentToILink,
} from 'graphql/queries';
import Order from 'components/Order/Order';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import {
  TIME_ZONE,
  iLinkOrderStatus,
  processStatus,
} from '@silvergatedelivery/constants';
import ProcessStatusChip from 'components/ProcessStatusChip';
import ILinkOrderStatusChip from 'components/ILinkOrderStatusChip';
import ILinkOrderLogTable from './ILinkOrderLogTable';

const past = moment().add(-3, 'days').tz(TIME_ZONE).format('YYYY-MM-DD');
const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function ILinkOrderTable({
  title = 'I-Link 訂單',
  description,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const [orderId, processlogs] = rowData;
      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <ILinkOrderLogTable data={processlogs.map(JSON.parse).filter(({ Type }) => Type).reverse()} />
          <Order id={orderId} />
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'orderId',
      label: '訂單編號',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'processlogs',
      label: '紀錄',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'iLinkOrderId',
      label: 'I-Link 訂單編號',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'processStatus',
      label: '處理狀況',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <ProcessStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'processStatusMessage',
      label: '處理資訊',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <ILinkOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'cancelledReason',
      label: '取消原因',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: '描述',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'fee',
      label: '費用',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'shouldBeSentToILinkAt',
      label: '預計處理時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderId',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/order/${id}`}
              label="前往訂單頁面"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    setIndexes([
      {
        name: 'getILinkOrdersByProcessStatusByShouldBeSentToILink',
        label: '按處理狀況查詢',
        partitionKey: 'processStatus',
        sortKey: 'shouldBeSentToILinkAt',
        fields: [{
          label: '處理狀況',
          key: 'processStatus',
          type: 'select',
          options: processStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToILinkAt',
          type: 'datetime',
        }],
        operation: getILinkOrdersByProcessStatusByShouldBeSentToILink,
      },
      {
        name: 'getILinkOrdersByStatusByShouldBeSentToILink',
        label: '按I-Link狀態查詢',
        partitionKey: 'status',
        sortKey: 'shouldBeSentToILinkAt',
        fields: [{
          label: '狀態',
          key: 'status',
          type: 'select',
          options: iLinkOrderStatus,
        }, {
          label: '預計送單時間',
          key: 'shouldBeSentToILinkAt',
          type: 'datetime',
        }],
        operation: getILinkOrdersByStatusByShouldBeSentToILink,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '預計送單時間',
          key: 'shouldBeSentToILinkAt',
          type: 'datetime',
        }],
        operation: listILinkOrders,
      },
    ]);
    setQueryParams({
      processStatus: 'processing',
      shouldBeSentToILinkAt: {
        from: past,
        to: todayDate,
      },
    });
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        {...props}
      />
    </React.Fragment>
  );
}

ILinkOrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
