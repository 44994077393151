export const getEldersByClientByStatus = /* GraphQL */ `
  query GetEldersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        name
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
      }
      nextToken
    }
  }
`;

export const getDeliveryGroupsByClientByIsActive = /* GraphQL */ `
  query GetDeliveryGroupsByClientByIsActive(
    $clientId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryGroupsByClientByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;

export const getElderDeliveryGroupsByClientByStatus = /* GraphQL */ `
  query GetElderDeliveryGroupsByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDeliveryGroupsByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
      }
      nextToken
    }
  }
`;

export const getRecurringOrdersByDeliveryGroupByStatus = /* GraphQL */ `
  query GetRecurringOrdersByDeliveryGroupByStatus(
    $deliveryGroupId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByDeliveryGroupByStatus(
      deliveryGroupId: $deliveryGroupId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealSlot
        category
        status
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
