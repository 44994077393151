import React from 'react';

import OrderTable from 'components/OrderTable';

export default function Orders() {
  return (
    <React.Fragment>
      <OrderTable />
    </React.Fragment>);
}
