import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { AUTO_UPDATE_INTERVAL_IN_MILLISECONDS } from '@silvergatedelivery/constants';

import { request } from 'utilities/graph';
import {
  getOrdersByStatusByDate,
  getOrdersByCountyByStatus,
  getOrdersByClientByStatus,
} from 'graphql/queries';
import cache from 'utilities/cache';

export default function AbandonedOrderBadge({ children }) {
  const { t } = useTranslation();
  const [badgeContent, setBadgeContent] = useState(0);

  const update = async () => {
    // global.logger.debug('Update issue center badge content');
    const county = cache.get('app:location');
    const clientId = cache.get('app:facilityId');

    const targetStatus = 'reMatchingDeliveryStaff';

    let records = [];
    if (clientId) {
      const params = {
        clientId,
        status: { eq: targetStatus },
        limit: 10,
        sortDirection: 'DESC',
      };
      const { data: { getOrdersByClientByStatus: { items: results } } } = await request(getOrdersByClientByStatus, params);
      records = results;
    } else
    if (county) {
      const params = {
        county,
        status: { eq: targetStatus },
        limit: 10,
        sortDirection: 'DESC',
      };
      const { data: { getOrdersByCountyByStatus: { items: results } } } = await request(getOrdersByCountyByStatus, params);
      records = results;
    } else {
      const params = {
        status: targetStatus,
        limit: 10,
        sortDirection: 'DESC',
      };
      const { data: { getOrdersByStatusByDate: { items: results } } } = await request(getOrdersByStatusByDate, params);
      records = results;
    }

    setBadgeContent(records.length);
  };

  useEffect(() => {
    update();

    const interval = setInterval(update, AUTO_UPDATE_INTERVAL_IN_MILLISECONDS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Tooltip
      title={`尚未有${t('送餐大使')}的訂單`}
      placement="right"
    >
      <Badge
        badgeContent={badgeContent}
        max={9}
        color="secondary"
      >
        {children}
      </Badge>
    </Tooltip>);
}

AbandonedOrderBadge.propTypes = {
  children: PropTypes.element,
};
