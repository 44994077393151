import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataForm from 'forms/DataForm';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getEldersByClientByStatus } from './queries';
import { asyncListAll } from 'utilities/graph';

import schema from './schema';
import uiSchema from './uiSchema';
import cache from 'utilities/cache';

export default function ElderInfo({ formData: inFormData, ...props }) {
  const [elders, setElders] = useState([]);
  const [formData, setFormData] = useState();
  const [selectedElder, setSelectedElder] = useState(null);

  const createFunc = async (data) => {
    global.logger.debug('createFunc data', data);
  };

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:clientId');
      if (!clientId) {
        setFormData(inFormData);
        return;
      }

      const elders = await asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: { eq: '使用中' },
      });

      if (inFormData && inFormData.name) {
        if (inFormData.id) {
          const selectedElderIndex = elders.findIndex(({ id }) => id === inFormData.id);
          elders[selectedElderIndex] = inFormData;
        } else {
          elders.push(inFormData);
        }
        setSelectedElder(inFormData);
        setFormData(inFormData);
      } else if (elders[0]) {
        const {
          id,
          name,
          phoneNumber,
          address,
          noteForDelivery,
          noteForMeal,
          noteForHealth,
        } = elders[0];
        setSelectedElder(elders[0]);
        setFormData({
          id,
          name,
          phoneNumber,
          address,
          noteForDelivery,
          noteForMeal,
          noteForHealth,
        });
      }
      setElders(elders);
    })();
  }, [inFormData]);

  if (!formData) return null;

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      {elders && elders.length !== 0 &&
      <Grid item xs={12} style={{ zIndex: 100 }}>
        <FormControl fullWidth>
          <Autocomplete
            value={selectedElder}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                const existingElder = elders.find(({ name }) => name === newValue);
                if (existingElder) {
                  setSelectedElder(existingElder);
                  const { id, address, name, noteForDelivery, noteForMeal, noteForHealth, phoneNumber } = newValue;
                  setFormData({
                    id, name, address, noteForDelivery, noteForMeal, noteForHealth, phoneNumber,
                  });
                } else {
                  const newElder = { name: newValue };
                  setElders([...elders, newElder]);
                  setSelectedElder(newElder);
                  setFormData(newElder);
                }
              } else if (newValue) {
                if (newValue.inputValue) {
                  const newElder = { name: newValue.inputValue };
                  setElders([...elders, newElder]);
                  setSelectedElder(newElder);
                  setFormData(newElder);
                } else {
                  setSelectedElder(newValue);
                  const { id, address, name, noteForDelivery, noteForMeal, noteForHealth, phoneNumber } = newValue;
                  setFormData({
                    id, name, address, noteForDelivery, noteForMeal, noteForHealth, phoneNumber,
                  });
                }
              }
            }}
            filterOptions={(elders, params) => {
              const filtered = elders.filter(({ name }) =>
                name.toLowerCase().includes(params.inputValue.toLowerCase()),
              );

              if (params.inputValue !== '') {
                if (!filtered.find(({ name }) => name === params.inputValue)) {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `新增收餐者：${params.inputValue}`,
                  });
                }
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            options={elders}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              return option.name;
            }}
            renderOption={(elder) => elder.name}
            freeSolo
            renderInput={(params) => <TextField {...params} label="收餐者姓名" />}
          />
        </FormControl>
      </Grid>}
      <Grid item xs={12} style={{ marginTop: -300, zIndex: 1 }}>
        <DataForm
          schema={schema}
          uiSchema={uiSchema}
          createFunc={createFunc}
          updateFunc={createFunc}
          onComplete={props.onComplete}
          dirty={true}
          addressLiteVersion={true}
          {...props}
          formData={formData}
        >
          {/* <div style={{ backgroundColor: Colors.background.light, padding: 16, color: '#4d4d4d', borderRadius: 10, marginTop: 16, marginBottom: 16 }}>
            {t('運送時間因實際狀況、天氣、車隊派送情形為主。')}<br/>
            {t('運送距離上限：8公里內（以平台計算為主）')}
          </div> */}
        </DataForm>
      </Grid>
    </Grid>
  );
}

ElderInfo.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
