import React from 'react';

import EditButton from '../components/EditButton';
import OrganizationForm from './OrganizationForm';

export default function OrganizationEditButton({ ...props }) {
  return (
    <EditButton
      title="組織"
      form={OrganizationForm}
      {...props}
    />);
}
