import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import Place from 'components/Map/Place';

export default function QueryAddressResultTable({
  title = '地址查詢結果',
  description,
  data,
  ...props
}) {
  const columns = [
    {
      name: 'queryAddressMethod',
      label: '查詢方式',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'originalAddress',
      label: `輸入地址`,
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },

    {
      name: 'address',
      label: `回傳地址`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'coordinates',
      label: `經緯度`,
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lat',
      label: '緯度',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lng',
      label: '經度',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'coordinates',
      label: `經緯度`,
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(coordinates) {
          const [lat, lng] = coordinates.split(',');

          return <Place address ={{ lat, lng }} options={{ height: 120, width: 300 }} />;
        },

      },
    },
  ];

  const options = {
    expandableRows: true,
    isRowExpandable: () => false,
  };

  return (
    <DataTable
      data={data}
      title={title}
      description={description}
      columns={columns}
      options={options}
      defaultIndex={0}
      indexes={[]}
      queryDefaultParams={{}}
      {...props}
    />
  );
}

QueryAddressResultTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};
