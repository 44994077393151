import i18n from 'i18next';
import { orderStatus, mealSlots } from '@silvergatedelivery/constants';

export const getTranslatedOrderStatus = () => {
  if (!i18n.isInitialized) {
    global.logger.error('i18n is not ready');
    return orderStatus;
  }

  return orderStatus.map((status) => {
    const newStatus = Object.assign({}, status);
    if (i18n.exists(newStatus.label)) {
      newStatus.label = i18n.t(newStatus.label);
    }
    return newStatus;
  });
};

export const getTranslatedMealSlots = () => {
  if (!i18n.isInitialized) {
    global.logger.error('i18n is not ready');
    return mealSlots;
  }

  return mealSlots.map((mealSlot) => {
    const newMealSlot = Object.assign({}, mealSlot);
    if (i18n.exists(newMealSlot.label)) {
      newMealSlot.label = i18n.t(newMealSlot.label);
    }
    return newMealSlot;
  });
};
