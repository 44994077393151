import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[300],
  },
}));
export default function RestaurantMealGallery({
  restaurantId,
  restaurantMealId,
  s3Keys = [],
  onUpdateS3Keys,
}) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    async onDrop(newFiles) {
      setIsLoading(true);
      global.logger.debug('onDrop', newFiles);

      if (newFiles.length === 0) return;

      await Promise.all(newFiles.map(async (file) => {
        const s3Key = `restaurants/${restaurantId}/meals/${restaurantMealId}/${file.name}`;
        await Storage.put(s3Key, file, {
          contentType: file.type,
        });

        s3Keys.push(s3Key);
        files.push({
          s3Key,
          url: URL.createObjectURL(file),
        });
      }));

      setFiles([...files]);
      onUpdateS3Keys(s3Keys);
      setIsLoading(false);
    },
    accept: 'image/*',
  });

  const onRemoveFile = async (s3Key) => {
    setIsLoading(true);
    await Storage.remove(s3Key);
    const fileIndex = files.findIndex((file) => file.s3Key === s3Key);
    files.splice(fileIndex, 1);

    const s3KeyIndex = s3Keys.findIndex((key) => key === s3Key);
    s3Keys.splice(s3KeyIndex, 1);

    setFiles([...files]);
    onUpdateS3Keys(s3Keys);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const files = await Promise.all(s3Keys.map(async (s3Key) => {
        return {
          s3Key,
          url: await Storage.get(s3Key),
        };
      }));

      setFiles(files);
    })();
  }, [s3Keys]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <section style={{ border: '1px dotted black', paddingLeft: 16, marginTop: 16 }}>
          <div {...getRootProps()}>
            <input {...getInputProps()} disabled={isLoading} />
            {isLoading && <CircularProgress color="primary" />}
            {!isLoading && <p>點此或拖曳上傳圖片</p>}
          </div>
        </section>
      </Grid>
      {files.map(({ s3Key, url }, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} className={classes.imgContainer}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => onRemoveFile(s3Key)}
            disabled={isLoading}
          >
            <HighlightOffIcon />
          </IconButton>
          <img id="url" src={url} alt="image" width="100%" />
        </Grid>
      ))}
    </Grid>
  );
}

RestaurantMealGallery.propTypes = {
  restaurantId: PropTypes.string,
  restaurantMealId: PropTypes.string,
  s3Keys: PropTypes.array,
  onUpdateS3Keys: PropTypes.func,
};
