import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatAge, formatAddress, formatGender, formatEmergencyContact, formatTags } from 'utilities/format';
import InfoCard from './InfoCard';
import { request } from 'utilities/graph';
import { getElder } from 'graphql/queries';
import ElderEditButton from 'forms/ElderForm/ElderEditButton';

export default function ElderCard({ id, title = `${i18n.t('送餐對象')}資料`, data: inData, showMap = false, ...props }) {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const mappings = [
    { key: 'name', label: '姓名' },
    { key: 'birthday', label: '年齡', format: formatAge },
    { key: 'phoneNumber', label: '電話' },
    { key: 'phoneNumber2', label: '電話2' },
    { key: 'phoneNumber3', label: '電話3' },
    { key: 'address', label: '地址', format: formatAddress, showMap },
    { key: 'tags', label: '標籤', format: formatTags },
    { key: 'noteForHealth', label: '健康備註' },
    { key: 'noteForMeal', label: `${t('餐點')}備註` },
    { key: 'noteForDelivery', label: `${t('送餐')}備註` },
    { key: 'note', label: '其他備註' },
    { key: 'otherSocialServices', label: '其它社會資源協助' },
    { key: 'emergencyContact', label: '緊急聯絡人', format: formatEmergencyContact },
    {
      key: 'isAllowedToEnterHome',
      label: '是否同意進入長輩家中',
      format(value) {
        return value ? '是' : '否';
      },
    },
    { key: 'gender', label: '性別', format: formatGender, expand: true },
    { key: 'relationshipToClient', label: '與客戶關係' },
  ];

  useEffect(() => {
    (async () => {
      if (inData) {
        setData(inData);
      } else
      if (id) {
        const { data: { getElder: data } } = await request(getElder, { id });
        setData(data);
      }
    })();
  }, [inData, id]);

  return (
    <InfoCard
      title={title}
      mappings={mappings}
      data={data}
      link={`/elder/${data.id}`}
      editButton={ElderEditButton}
      onUpdate={setData}
      {...props}
    />
  );
}

ElderCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object,
  showMap: PropTypes.bool,
};
