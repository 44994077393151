import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import { counties } from '@silvergatedelivery/constants';
import { getDeliveryStaffIdSchema } from './schemas';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: '100%',
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
}));

function not(arrayA, arrayB) {
  return arrayA.filter((a) => !arrayB.some((b) => a.id === b.id));
}

function intersection(arrayA, arrayB) {
  return arrayA.filter((a) => arrayB.some((b) => a.id === b.id));
}

export default function DeliveryStaffSelection({ deliveryStaffs: inDeliveryStaffs = [], onChange, description = '' }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [activeCounty, setActiveCounty] = useState('臺北市');
  const [allDeliveryStaffs, setAllDeliveryStaffs] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    (async () => {
      const allDeliveryStaffs = (await getDeliveryStaffIdSchema(null, null, null, false, false, true))
        .map(({ id, name, county }) => ({ id, name, county }));
      const deliveryStaffsSelected = inDeliveryStaffs.map((id) => {
        const deliveryStaff = allDeliveryStaffs.find((deliveryStaff) => deliveryStaff.id === id);
        return { ...deliveryStaff };
      });
      let activeCounty = '臺北市';
      if (deliveryStaffsSelected.length !== 0) {
        setRight(deliveryStaffsSelected);
        activeCounty = deliveryStaffsSelected[0].county;
      } else {
        setRight([]);
      }
      setAllDeliveryStaffs(allDeliveryStaffs);
      setActiveCounty(activeCounty);
      const countyDeliveryStaffs = allDeliveryStaffs.filter(({ county }) => county === activeCounty);
      setLeft(not(countyDeliveryStaffs, deliveryStaffsSelected));
    })();
  }, []);

  const onUpdateLocation = (county) => {
    const countyDeliveryStaffs = allDeliveryStaffs.filter((deliveryStaff) => deliveryStaff.county === county);
    setLeft(not(countyDeliveryStaffs, right));
  };

  const handleToggleDeliveryStaff = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getOutDeliveryStaffs = (newRight) => newRight.map(({ id }) => id);

  const handleAllRight = () => {
    const newRight = right.concat(left);
    setRight(newRight);
    setLeft([]);
    setChecked([]);
    onChange(getOutDeliveryStaffs(newRight));
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    setRight(newRight);
    setLeft(not(left, leftChecked));
    setChecked([]);
    onChange(getOutDeliveryStaffs(newRight));
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked.filter(({ county }) => county === activeCounty));
    setLeft(newLeft);
    const newRight = not(right, rightChecked);
    setRight(newRight);
    setChecked([]);
    onChange(getOutDeliveryStaffs(newRight));
  };

  const handleAllLeft = () => {
    const newLeft = left.concat(right.filter(({ county }) => county === activeCounty));
    setLeft(newLeft);
    setRight([]);
    setChecked([]);
    onChange([]);
  };

  const customList = (items) => {
    return (
      <Paper className={classes.paper}>
        <List dense component='div' role='list'>
          {items.sort((a, b) => a.label > b.label)
            .map((value) => {
              const display = value.name;
              const labelId = `transfer-list-item-${display}-label`;

              return (
                <ListItem key={display} role='listitem' button onClick={handleToggleDeliveryStaff(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={display} />
                </ListItem>
              );
            })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      className={classes.root}
    >
      <Grid container item xs={12} spacing={2} >
        <Grid item xs={6}>
          <Autocomplete
            name='location'
            options={counties}
            value={activeCounty}
            onChange={(event, newItem) => {
              if (newItem) {
                setActiveCounty(newItem);
                onUpdateLocation(newItem);
              }
            }}
            renderInput={(params) => <TextField {...params} label='縣市'/>}
          />
        </Grid>
      </Grid>
      {description !== '' &&
        <Grid item xs={12}>
          <Typography variant='body2'>
            {description}
          </Typography>
        </Grid>}
      <Grid item xs={5}>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label='move all right'
          >
            <DoubleArrowIcon />
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            <KeyboardArrowRightIcon />
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            <KeyboardArrowRightIcon className={classes.rotateIcon}/>
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label='move all left'
          >
            <DoubleArrowIcon className={classes.rotateIcon}/>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>{customList(right, true)}</Grid>
    </Grid>
  );
}

DeliveryStaffSelection.propTypes = {
  deliveryStaffs: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
};
