import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import LinkButton from 'components/Table/LinkButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Storage } from 'aws-amplify';

// import Badge from '@material-ui/core/Badge';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import Clock from 'react-live-clock';

import {
  Link,
  // useHistory,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Version from 'components/Version';
import UserAvatar from 'components/UserAvatar';
import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
// import AdminOrderUploadButton from 'forms/AdminOrderForm/AdminOrderUploadButton';
import {
  Colors,
  counties,
  DRAWER_WIDTH,
  TIME_ZONE,
  NAME,
  SHORT_NAME,
  // cognitoGroups,
} from '@silvergatedelivery/constants';
import { getClientsByTypeByCounty, listOrganizations, getOrganization } from 'graphql/queries';
import { asyncListAll, request } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import { routes as publicRoutes } from 'views/Public/routes';
import cache from 'utilities/cache';
import useCustomAuth from 'hooks/useCustomAuth';
import { useCache } from 'CacheProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1, 0, 2),
    padding: theme.spacing(0, 2),
    height: 64,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // justifyContent: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  // custom
  unstyledHyperlink: {
    textDecoration: 'none',
    color: 'white',
  },
  unstyledHyperlinkPrimary: {
    textDecoration: 'none',
    color: Colors.primary,
    fontWeight: 'bold',
  },
  flexbox: {
    flexGrow: 1,
  },
  select: {
    'color': 'white',
    'fontSize': 14,
    '&:after': {
      borderBottomColor: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  imgContainer: {
    display: 'flex',
    maxHeight: 100,
    minHeight: 64,
    justifyContent: 'center',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}));

export default function CustomAppBar({ user, routes, open, onUpdate }) {
  const classes = useStyles();
  // const history = useHistory();
  const matcheMediaQuery = useMediaQuery('(min-width:600px)');

  const [name, setName] = useState();
  const [shortName, setShortName] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [location, setLocation] = useState(cache.get('app:location') || '全國');
  const [organizationAnchorEl, setOrganizationAnchorEl] = useState(null);
  const [facilityAnchorEl, setFacilityAnchorEl] = useState(null);
  const [locationAnchorEl, setLocationAnchorEl] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [isClient, setIsClient] = useState(false);
  const [isFacilityAdmin, setIsFacilityAdmin] = useState(false);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDrawerSubmenu, setOpenDrawerSubmenu] = useState([]);
  const { signOut } = useCustomAuth();
  const { appGroup, selectedUserGroupParams, userGroupOptions, logoS3Keys, setLogoS3Keys } = useCache();
  const [clientLogo, setClientLogo] = useState();

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  let customer;
  if (window.location.hostname.toLowerCase() === 'pingtung.silvergatedelivery.com') {
    customer = 'pingtung';
  }

  let visitorMode = false;
  if (appGroup === 'OrgAdmins') {
    const { organizationData } = selectedUserGroupParams;
    if (organizationData?.clients?.items) {
      if (organizationData?.clients?.items.every(({ visitorMode }) => visitorMode)) {
        // if items is empty, visitorMode will be true
        visitorMode = true;
      }
    }
  }

  const username = cache.get('app:username') || '';
  const userName = cache.get('app:name') || '';
  const organizationId = cache.get('app:organizationId');
  const facilityId = cache.get('app:facilityId');

  function handleToggleMenu() {
    setOpenMenu((prevOpen) => !prevOpen);
  }

  function handleCloseMenu(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  }

  const handleDrawerOpen = () => {
    onUpdate(true);
  };

  const handleDrawerClose = () => {
    onUpdate(false);
  };

  const onFilterOrganization = (e) => {
    setFilteredOrganizations(organizations.filter(({ name }) => name.includes(e.target.value)));
  };

  const onFilterFacility = (e) => {
    setFilteredFacilities(facilities.filter(({ name }) => name.includes(e.target.value)));
  };

  useEffect(() => {
    if (prevOpen && prevOpen.current === true && openMenu === false) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  async function handleSignOut(event) {
    handleCloseMenu(event);
    await signOut();
  }

  useEffect(() => {
    if (facilityId || organizationId) {
      setName(userName);
      setShortName(userName);
    } else {
      if (customer === 'pingtung') {
        setName('屏東縣政府｜長照送餐管理系統');
        setShortName('屏東縣政府');
      } else {
        setName(NAME);
        setShortName(SHORT_NAME);
      }
    }
  }, [organizationId, facilityId, userName, user, lastUpdatedAt]);

  useEffect(() => {
    (async () => {
      if (!user) return;
      if (appGroup === 'Clients') {
        return;
      }

      const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

      if (userGroups.includes('Clients') && appGroup === 'Clients') {
        setIsClient(true);
      }

      if (!userGroups || (!userGroups.includes('Admins') && !userGroups.includes('OrgAdmins') && !userGroups.includes('FacilityAdmins'))) {
        setIsAdmin(false);
        return;
      }

      if (userGroups.includes('FacilityAdmins')) {
        setIsFacilityAdmin(true);
      }

      if (userGroups.includes('OrgAdmins')) {
        setIsOrgAdmin(true);
      }

      if (userGroups.includes('Admins')) {
        setIsAdmin(true);
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      if (!user) return;
      const isLoggedIn = await Auth.currentAuthenticatedUser({ bypassCache: true });

      if (!isLoggedIn) return;

      if (userGroupOptions.length === 0) return;

      let organizations = [];
      let facilities = [];
      if (isAdmin) {
        ([
          organizations,
          facilities,
        ] = await Promise.all([
          asyncListAll(listOrganizations),
          asyncListAll(getClientsByTypeByCounty, {
            type: 'facility',
            filter: {
              isActive: { eq: 1 },
            },
          }),
        ]));
      } else {
        if (isOrgAdmin) {
          userGroupOptions.forEach((userGroupOption) => {
            if (userGroupOption.userGroupName === 'OrgAdmins' && userGroupOption.isActive === 1) {
              organizations.push(userGroupOption.organizationData);
            }
          });
        }
        if (isFacilityAdmin) {
          userGroupOptions.forEach((userGroupOption) => {
            if (userGroupOption.userGroupName === 'FacilityAdmins' && userGroupOption.isActive === 1) {
              facilities.push(userGroupOption.clientData);
            }
          });
        }
      }
      if (organizations.length > 0) {
        setOrganizations(organizations.sort(sortBy('name')));
        setFilteredOrganizations(organizations.sort(sortBy('name')));
      }
      if (facilities.length > 0) {
        setFacilities(facilities.sort(sortBy('name')));
        setFilteredFacilities(facilities.sort(sortBy('name')));
      }

      if (appGroup === 'FacilityAdmins') {
        const facility = facilities.find(({ id }) => id === facilityId);
        if (facility && facility.facilitySettings && facility.facilitySettings.logoS3Keys && facility.facilitySettings.logoS3Keys[0]) {
          setLogoS3Keys(facility.facilitySettings.logoS3Keys);
        }
      }
    })();
  }, [user, isAdmin, isOrgAdmin, isFacilityAdmin, userGroupOptions]);

  useEffect(() => {
    if (!logoS3Keys && !clientLogo) return;

    (async () => {
      if (logoS3Keys && logoS3Keys[0]) {
        const logo = await Storage.get(logoS3Keys[0]);
        setClientLogo(logo);
      } else {
        setClientLogo();
      }
    })();
  }, [logoS3Keys]);

  global.logger.debug({
    isAdmin,
    isOrgAdmin,
    isFacilityAdmin,
    isClient,
    facilities,
    organizations,
    organizationId,
  });

  const renderAdminButton = () => {
    if (appGroup === 'Admins') {
      return null;
    }
    return (
      <>
        <Button
          color="inherit"
          aria-controls="location-menu"
          aria-haspopup="true"
          onClick={() => {
            selectedUserGroupParams.userGroupName = 'Admins';
            cache.set('app:selectedUserGroupParams', selectedUserGroupParams);
            window.location.href = '/';
          }}
          disabled={appGroup === 'Admins'}
        >
          切換管理員模式
        </Button>
      </>
    );
  };

  const renderOrganizationButton = () => {
    if (organizations.length <= 0 || (organizations.length === 1 && (appGroup === 'OrgAdmins'))) {
      return null;
    }
    return (
      <>
        <Button
          color="inherit"
          aria-controls="location-menu"
          aria-haspopup="true"
          onClick={(event) => setOrganizationAnchorEl(event.currentTarget)}
          className={classes.titleButton}
        >
          切換組織模式
        </Button>
        <Menu
          id="organization-menu"
          anchorEl={organizationAnchorEl}
          keepMounted
          open={Boolean(organizationAnchorEl)}
          onClose={() => setOrganizationAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TextField
            fullWidth
            onChange={onFilterOrganization}
            style={{ minWidth: 480, padding: 6 }}
            variant='outlined'
            autoFocus
            onKeyDown={(event) => {
              if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.stopPropagation();
              }
            }}
          />
          {filteredOrganizations.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                onClick={async () => {
                  selectedUserGroupParams.userGroupName = 'OrgAdmins';
                  // 使用getOrganization避免因為listOrganizations讓裡面的client少了facilitySettings那一層的資料
                  const { data: { getOrganization: orgData } } = await request(getOrganization, { id: item.id });
                  selectedUserGroupParams.organizationId = item.id;
                  selectedUserGroupParams.organizationData = orgData;
                  selectedUserGroupParams.organizationClientIds = item.clients.items.map(({ clientId }) => clientId);
                  cache.set('app:selectedUserGroupParams', selectedUserGroupParams);
                  window.location.href = '/';
                }}
                disabled={item.id === organizationId}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  };

  const renderFacilityButton = () => {
    if (facilities.length <= 0 || (facilities.length === 1 && (appGroup === 'FacilityAdmins'))) {
      return null;
    }
    return (
      <>
        <Button
          color="inherit"
          aria-controls="location-menu"
          aria-haspopup="true"
          onClick={(event) => setFacilityAnchorEl(event.currentTarget)}
          className={classes.titleButton}
        >
          切換機構模式
        </Button>
        <Menu
          id="organization-menu"
          anchorEl={facilityAnchorEl}
          keepMounted
          open={Boolean(facilityAnchorEl)}
          onClose={() => setFacilityAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TextField
            fullWidth
            onChange={onFilterFacility}
            style={{ minWidth: 480, padding: 6 }}
            variant='outlined'
            autoFocus
            onKeyDown={(event) => {
              if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.stopPropagation();
              }
            }}
          />
          {filteredFacilities.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                onClick={() => {
                  selectedUserGroupParams.userGroupName = 'FacilityAdmins';
                  selectedUserGroupParams.clientId = item.id;
                  selectedUserGroupParams.clientData = item;
                  cache.set('app:selectedUserGroupParams', selectedUserGroupParams);
                  window.location.href = '/';
                }}
                disabled={item.id === facilityId}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  };

  const renderUserDrawerMenu = () => {
    const filteredRoutes = routes.filter((x) => !x.hideFromMenu);
    const subMenu = {};
    let folderIndex = 0;
    filteredRoutes.forEach((route, index) => {
      if (route.folder) {
        folderIndex = index;
      } else if (route.nested) {
        if (!subMenu[`folder${folderIndex}`]) {
          subMenu[`folder${folderIndex}`] = [];
        }
        subMenu[`folder${folderIndex}`].push(route);
      }
    });

    return (
      <List>
        {filteredRoutes.filter((x) => !x.hideFromMenu).map((route, index) => {
          if (route.folder) {
            return (
              <div key={index}>
                <ListItem
                  key={index}
                  button
                  component={'div'}
                  divider={route.divider && !openDrawerSubmenu.includes(index) ? true : false}
                  onClick={()=>{
                    let newOpenSubmenu = [...openDrawerSubmenu];
                    if (newOpenSubmenu.includes(index)) {
                      newOpenSubmenu = newOpenSubmenu.filter((menuIndex) => menuIndex !== index);
                    } else {
                      newOpenSubmenu.push(index);
                    }
                    setOpenDrawerSubmenu(newOpenSubmenu);
                  }}
                >
                  <ListItemIcon style={{ minWidth: 36 }}>
                    {route.icon ? <route.icon /> : null}
                  </ListItemIcon>
                  <ListItemText primary={route.title} />
                  {openDrawerSubmenu.includes(index) ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </ListItem>
                {subMenu[`folder${index}`] &&
                  <Collapse in={openDrawerSubmenu.includes(index)}>
                    <List disablePadding>
                      {subMenu[`folder${index}`].map((route) => (
                        <ListItem
                          className={classes.nested}
                          key={route.title}
                          button
                          component={Link}
                          divider={route.divider ? true : false}
                          selected={window.location.pathname.startsWith(route.link || route.path)}
                          to={route.link || route.path}
                          onClick={()=>{
                            setLastUpdatedAt(Date.now());
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 36 }}>
                            {route.icon ? <route.icon /> : null}
                          </ListItemIcon>
                          <ListItemText primary={route.title} />
                          {route.badge && <route.badge />}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                }
              </div>
            );
          } else if (!route.nested) {
            return (
              <ListItem
                key={index}
                button
                component={Link}
                divider={route.divider ? true : false}
                selected={window.location.pathname.startsWith(route.link || route.path)}
                to={route.link || route.path}
                onClick={()=>{
                  setLastUpdatedAt(Date.now());
                }}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  {route.icon ? <route.icon /> : null}
                </ListItemIcon>
                <ListItemText primary={route.title} />
                {route.badge && <route.badge />}
              </ListItem>
            );
          }
        })}
        {(!isFacilityAdmin && !isOrgAdmin && !isAdmin) &&
        publicRoutes.filter((x) => x.showOnHeader).map((route, index) => (
          <ListItem
            button
            component={Link}
            to={route.link || route.path}
            onClick={open ? handleDrawerClose: handleDrawerOpen}
            key={index}>
            <ListItemIcon style={{ minWidth: 36 }}>
              {route.icon ? <route.icon /> : null}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={handleSignOut} key='signOut'>
          <ListItemIcon style={{ minWidth: 36 }}><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={'登出'} />
        </ListItem>
        <div className={classes.flexbox} />
        <ListItem key='version'>
          <ListItemText>
            <Version />
          </ListItemText>
        </ListItem>
      </List>
    );
  };

  return (
    <Box displayPrint="none">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={1}
      >
        <Toolbar style={{ height: 64 }}>
          {user ?
            <IconButton
              color="inherit"
              aria-label="toggle drawer"
              onClick={open ? handleDrawerClose: handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              {open ? <VerticalSplitIcon /> : <MenuIcon />}
            </IconButton>:
            <Hidden mdUp={true}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>}
          {!open &&
            <React.Fragment>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ marginRight: 16 }}>
                <Link to="/" className={classes.unstyledHyperlink} data-test-id="title">
                  {visitorMode ? `${name}(訪客模式)`: name}
                </Link>
              </Typography>
            </React.Fragment>
          }
          {user && (isFacilityAdmin || isAdmin) && (appGroup !== 'OrgAdmins') &&
            <React.Fragment>
              <AdminOrderEditButton
                mode="create"
                type="button"
                // color="inherit"
              />
            </React.Fragment>
          }
          {/* {user && (isFacilityAdmin || isAdmin) && (appGroup !== 'OrgAdmins') &&
            <React.Fragment>
              <AdminOrderUploadButton
                mode="create"
                type="button"
              />
            </React.Fragment>
          } */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {(!isFacilityAdmin && !isOrgAdmin && !isAdmin) &&
              publicRoutes.filter((x) => x.showOnHeader).map((route, index) => (
                <Button
                  size='medium'
                  variant='text'
                  color='inherit'
                  component={Link}
                  to={route.link || route.path}
                  // onClick={() => {
                  //   // setLastUpdatedAt(Date.now());
                  //   // history.push((route.link || route.path));
                  // }}
                  key={index}
                >
                  {route.icon && <route.icon fontSize="small" />}
                  &nbsp;
                  {route.title}
                </Button>
              ))}
          </Box>
          <div className={classes.flexbox} />

          {(isFacilityAdmin || isOrgAdmin || isAdmin) &&
          <Clock
            format={'HH:mm:ss'}
            ticking={true}
            timezone={TIME_ZONE}
            style={{
              fontFamily: 'monospace, serif, sans-serif',
              marginRight: 16,
            }}
          />}

          {user ?
            <React.Fragment>
              {isAdmin && renderAdminButton()}
              {(isAdmin || isOrgAdmin) && renderOrganizationButton()}
              {(isAdmin || isFacilityAdmin) && renderFacilityButton()}
              {(isFacilityAdmin || isAdmin) &&
              <Button
                color="inherit"
                aria-controls="location-menu"
                aria-haspopup="true"
                onClick={!facilityId ? (event) => setLocationAnchorEl(event.currentTarget) : undefined}
                className={classes.titleButton}
              >
                {location}
              </Button>}
              <Menu
                id="location-menu"
                anchorEl={locationAnchorEl}
                keepMounted
                open={Boolean(locationAnchorEl)}
                onClose={() => setLocationAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem
                  key={'allCounties'}
                  disabled={location === '全國'}
                  onClick={() => {
                    localStorage.removeItem('app:location');
                    setLocation('全國');
                    setLocationAnchorEl(null);
                    window.location.reload();
                  }}
                >
                  全國
                </MenuItem>
                {counties.map((item)=>(
                  <MenuItem
                    key={item}
                    disabled={location === item}
                    onClick={() => {
                      cache.set('app:location', item);
                      setLocation(item);
                      setLocationAnchorEl(null);
                      window.location.reload();
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Menu>

              {!facilityId &&
              <Button
                ref={anchorRef}
                color="inherit"
                aria-controls={open ? 'user-menu' : undefined}
                aria-haspopup="true"
                onClick={handleToggleMenu}
                // startIcon={<PersonIcon />}
                startIcon={<UserAvatar username={username} name={userName} />}
                className={classes.titleButton}
              >
                {/* {userName} */}
              </Button>}
            </React.Fragment>:
            <Box sx={{ marginRight: '40px' }}>
              <Hidden smDown={true}>
                {customer === 'pingtung' && <LinkButton
                  path={'https://lin.ee/sh4REUE'}
                  text='聯繫銀色大門'
                  label='聯繫銀色大門'
                  icon={<img src="/images/handshake.png" alt="partner" style={{ height: '15px' }}/>}
                  buttonStyle={{ color: 'white ', borderColor: 'white', borderRadius: '20px' }}
                  newTab
                />
                }
                {!customer && <LinkButton
                  path={'https://www.silvergateforelders.com/saas'}
                  text='合作夥伴計畫'
                  label='前往合作夥伴計畫'
                  icon={<img src="/images/handshake.png" alt="partner" style={{ height: '15px' }}/>}
                  buttonStyle={{ color: 'white ', borderColor: 'white', borderRadius: '20px' }}
                  newTab
                />}
              </Hidden>
            </Box>
          }
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            disablePortal
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList id="user-menu" autoFocusItem={openMenu}>
                  <MenuItem key={'username'} disabled={true}>
                    {username}
                  </MenuItem>
                  <MenuItem key={'version'} disabled={true}>
                    <Version />
                  </MenuItem>
                  {/* <MenuItem disabled={true}>{userCognitoGroupLabel}</MenuItem> */}
                  <MenuItem key={'signOut'} onClick={handleSignOut}>登出</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </Toolbar>
      </AppBar>

      {/* Side panel */}
      <Drawer
        className={classes.drawer}
        variant={matcheMediaQuery && user ? 'persistent' : null}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={() => onUpdate(false)}
      >
        {clientLogo ?
          <div className={classes.imgContainer}>
            <Link to="/" className={classes.unstyledHyperlinkPrimary} data-test-id="logo">
              <img src={clientLogo} className={classes.img} />
            </Link>
          </div> :
          <div className={classes.drawerHeader}>
            <Typography component="h1" variant="h6" noWrap>
              <Link to="/" className={classes.unstyledHyperlinkPrimary} data-test-id="title">
                {shortName}
              </Link>
            </Typography>
            {visitorMode &&
            <Typography component="h2" noWrap color='primary'>
              訪客模式
            </Typography>}
          </div>
        }
        <Divider />
        {user ? renderUserDrawerMenu() :
          <List>
            {/* <ListItem button onClick={()=>history.push('/application')}>
              <ListItemIcon><PersonAddIcon /></ListItemIcon>
              <ListItemText primary={'申請加入'} />
            </ListItem> */}
            {(!isFacilityAdmin && !isOrgAdmin && !isAdmin) &&
            publicRoutes.filter((x) => x.showOnHeader).map((route, index) => (
              <ListItem
                button
                component={Link}
                to={route.link || route.path}
                onClick={open ? handleDrawerClose: handleDrawerOpen}
                // onClick={() => {
                //   // setLastUpdatedAt(Date.now());
                //   // history.push((route.link || route.path));
                // }}
                key={index}>
                <ListItemIcon style={{ minWidth: 36 }}>
                  {route.icon ? <route.icon /> : null}
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
            <Divider />
            <ListItem
              button
              component={Link}
              to={{ pathname: 'https://www.silvergateforelders.com/saas' }}
              onClick={open ? handleDrawerClose: handleDrawerOpen}
              key={'saas'}
              target={'_blank'}
            >
              <ListItemIcon style={{ minWidth: 36 }}>
                {<img src="/images/handshake_gray.png" alt="partner" style={{ height: '15px' }}/>}
              </ListItemIcon>
              <ListItemText primary={'合作夥伴計畫'} />
            </ListItem>
          </List>}
      </Drawer>
    </Box>
  );
}

CustomAppBar.propTypes = {
  user: PropTypes.object,
  routes: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};
