import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import {
  TIME_ZONE,
  deliveryStaffTransactionStatus,
  deliveryStaffTransactionTypes,
} from '@silvergatedelivery/constants';

import DataTable from 'components/Table/DataTable';
// import OrderDialog from 'components/Order/OrderDialog';
import {
  listDeliveryStaffTransactions,
  getDeliveryStaffTransactionByStatusByCreatedAt,
} from 'graphql/queries';
import LinkButton from 'components/Table/LinkButton';
import DeliveryStaffTransactionEditButton from 'forms/DeliveryStaffTransactionForm/DeliveryStaffTransactionEditButton';
import CustomStatusChip from 'components/CustomStatusChip';

const last3Month = moment().tz(TIME_ZONE).add(-90, 'days').format('YYYY-MM-DD');
// const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');
const nextWeek = moment().tz(TIME_ZONE).add(7, 'days').format('YYYY-MM-DD');

const defaultIndexes = [
  {
    name: 'getDeliveryStaffTransactionByStatusByCreatedAt',
    label: '按狀態查詢',
    partitionKey: 'status',
    sortKey: 'createdAt',
    fields: [{
      label: '狀態',
      key: 'status',
      type: 'select',
      options: [...deliveryStaffTransactionStatus],
    }, {
      label: '創建時間',
      key: 'createdAt',
      type: 'datetime',
    }],
    operation: getDeliveryStaffTransactionByStatusByCreatedAt,
  },
  {
    name: 'scan',
    label: '掃描全部資料',
    fields: [{
      label: '創建時間',
      key: 'createdAt',
      type: 'datetime',
    }],
    operation: listDeliveryStaffTransactions,
  },
];

export default function STokenTable({
  title = 'S Token 交易紀錄',
  description,
  clientId,
  elderId,
  restaurantId,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  const columns = [
    {
      name: 'id',
      label: '編號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffId',
      label: `${t('送餐大使')}編號`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <CustomStatusChip value={value} mappings={deliveryStaffTransactionStatus} />
          );
        },
      },
    },
    {
      name: 'type',
      label: '類別',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return deliveryStaffTransactionTypes.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'deliveryStaff.name',
      label: t('送餐大使'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaff.bankAccount',
      label: '銀行帳戶',
      options: {
        filter: false,
        sort: false,
        customBodyRender(bankAccount) {
          return `${bankAccount.name || ''} ${bankAccount.number || ''}`;
        },

      },
    },
    {
      name: 'amount',
      label: '數量',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'summary',
      label: '簡述',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'note',
      label: '備註',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffId',
      label: `${t('送餐大使')}頁面`,
      options: {
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/deliveryStaff/${id}`}
              label={`前往${t('送餐大使')}頁面`}
              newTab={true}
            />
          );
        },
      },
    },
    // {
    //   name: 'orderId',
    //   label: ' ',
    //   options: {
    //     display: true,
    //     filter: false,
    //     sort: false,
    //     customBodyRender(id) {
    //       if (!id) return '';
    //       return (
    //         <OrderDialog id={id} />
    //       );
    //     },
    //   },
    // },
    // {
    //   name: 'orderId',
    //   label: ' ',
    //   options: {
    //     display: true,
    //     filter: false,
    //     sort: false,
    //     customBodyRender(id) {
    //       return (
    //         <LinkButton
    //           path={`/order/${id}`}
    //           label="前往訂單頁面"
    //         />
    //       );
    //     },
    //   },
    // },
  ];

  useEffect(() => {
    setIndexes(defaultIndexes);
    setQueryParams({
      status: 'pending',
      createdAt: {
        from: last3Month,
        to: nextWeek,
      },
    });
  }, []);

  if (!indexes || !queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        options={options}
        // queryFunc={queryFunc}
        editButton={DeliveryStaffTransactionEditButton}
        {...props}
      />
    </React.Fragment>);
}

STokenTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
  elderId: PropTypes.string,
  restaurantId: PropTypes.string,
};
