import cache from 'utilities/cache';
import { request, asyncListAll } from 'utilities/graph';
import {
  getClientsByUsernameByType,
  getOrganization,
  getClientOrganizationByOrganization,
  getUser,
} from 'graphql/queries';
import { registerClient, registerFacilityAdmin } from 'graphql/mutations';
import { cognitoGroups } from '@silvergatedelivery/constants';

export const handleNewRegisteredFacilityAdmin = async (username, name, email, userGroups) => {
  const isNewRegisteredFacilityAdmin = cache.get('app:isNewRegisteredFacilityAdmin') === 'yes';

  if (!isNewRegisteredFacilityAdmin || userGroups.includes('FacilityAdmins')) {
    return undefined;
  }

  const registerResult = await request(registerFacilityAdmin, {
    input: {
      username,
      name,
      addressCounty: cache.get('app:location') || '臺北市',
      addressZipCode: '',
      addressDistrict: '',
      addressStreet: '',
      phoneNumber: cache.get('app:phoneNumber') || ' ',
      email,
      contact: cache.get('app:contact') || ' ',
    },
  });

  global.logger.debug('registerFacilityAdmin', registerResult);

  return 'FacilityAdmins';
};

export const handleMissingClient = async (username, name, email, userGroups) => {
  if (userGroups.includes('Clients')) {
    return undefined;
  }

  const res = await request(registerClient, {
    input: {
      username,
      name,
      email,
      addressCounty: cache.get('app:location') || '臺北市',
      addressZipCode: ' ',
      addressDistrict: ' ',
      addressStreet: ' ',
      phoneNumber: cache.get('app:phoneNumber') || ' ',
    },
  });
  global.logger.debug(res);

  return 'Clients';
};

export const getUserOrgAssigedFacilities = async (user, clientModeOnly = true) => {
  if (!user.organizationId || !user.facilityIds || user.facilityIds.length === 0) {
    return [];
  }
  const clientOrganization = await asyncListAll(getClientOrganizationByOrganization, { organizationId: user.organizationId });
  const facilities = user.facilityIds.reduce((accumulator, currentValue) => {
    const { client, clientMode } = clientOrganization.find(({ clientId }) => clientId === currentValue) || {};
    if (client && (!clientModeOnly || (clientModeOnly && clientMode))) {
      accumulator.push(client);
    }
    return accumulator;
  }, []);
  return facilities;
};

export const getUserRelatedFacilities = async (user) => {
  const [
    userOwnedFacilites,
    orgAssignedFacilities,
  ] = await Promise.all([
    asyncListAll(getClientsByUsernameByType, {
      username: user.username,
      type: {
        eq: 'facility',
      },
    }),
    getUserOrgAssigedFacilities(user),
  ]);

  const facilities = userOwnedFacilites;
  orgAssignedFacilities.forEach((item) => {
    if (!facilities.find(({ id }) => id === item.id)) {
      facilities.push(item);
    }
  });

  return facilities;
};

export const getUserOrgClients = async (user) => {
  if (!user.organizationId) return [];

  const clientOrganization = await asyncListAll(getClientOrganizationByOrganization, { organizationId: user.organizationId });
  const clients = clientOrganization.reduce((accumulator, currentValue) => {
    if (currentValue.client && currentValue.client.type === 'facility') {
      accumulator.push(currentValue.client);
    }
    return accumulator;
  }, []);

  return clients;
};

export const getUserOrg = async (user) => {
  if (!user.organizationId) return undefined;

  const { data: { getOrganization: organizationData } } = await request(getOrganization, { id: user.organizationId });

  return organizationData;
};

export const getUserGroupOptions = async (user) => {
  const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];

  const { username, attributes } = user;
  const { name, email } = attributes;

  cache.set('app:username', username);
  cache.set('app:userName', name);
  cache.set('app:name', name);
  cache.set('app:email', email);

  global.logger.debug({ user });

  // TODO: Move this part to "checkUser"
  const [
    newUserGroupNameForFacilityAdmin,
    newUserGroupNameForClient,
  ] = await Promise.all([
    handleNewRegisteredFacilityAdmin(username, name, email, userGroups),
    handleMissingClient(username, name, email, userGroups),
  ]);

  if (newUserGroupNameForFacilityAdmin) {
    // workaround error: Not Authorized to access facilitySettings on type Client
    // 當facility註冊後，registerFacilityAdmin會create client同時create facilitSettings 和修改 group
    // 但是底下 getUserRelatedFacilities 裡面的 getClientsByUsernameByType 會回 error
    // 這個地方reload可以避免這個error
    window.location.reload();
  }

  if (newUserGroupNameForFacilityAdmin) userGroups.push(newUserGroupNameForFacilityAdmin);
  if (newUserGroupNameForClient) userGroups.push(newUserGroupNameForClient);

  global.logger.debug({ userGroups });

  const { data: { getUser: existingUser } } = await request(getUser, { username });

  // TODO: Move this part to "checkUser"
  const [
    individualClients,
    facilities, // user可以進入機構模式的機構, 包含私有機構與組織內機構
    organizationData,
  ] = await Promise.all([
    asyncListAll(getClientsByUsernameByType, {
      username: user.username,
      type: {
        eq: 'individual',
      },
    }),
    getUserRelatedFacilities(existingUser),
    getUserOrg(existingUser),
  ]);

  if (organizationData && organizationData.cleints && organizationData.client.items) {
    organizationData.clients.items.forEach((client) => {
      if (client.visitorMode) {
        client.clientMode = false;
        client.elderSetting = false;
      }
    });
  }

  const defaultUserGroupOptions = cognitoGroups.filter(({ value, backendSignIn }) =>
    userGroups.includes(value) && backendSignIn);
  const userGroupOptions = [];
  defaultUserGroupOptions.forEach((item) => {
    if (item.value === 'OrgAdmins' && organizationData) {
      // 選擇這個option會進入"組織"模式
      userGroupOptions.push({
        label: `${item.label}：${organizationData.name}`,
        userGroupName: item.value,
        organizationId: organizationData.id,
        organizationData: {
          ...organizationData,
          clients: {
            // 組織裡可以被user看到的機構
            items: organizationData.clients.items.filter(({ clientId }) => existingUser.facilityIds.includes(clientId)),
          },
        },
        // 組織裡可以被user看到的機構
        organizationClientIds: organizationData.clients.items.filter(
          ({ clientId }) => existingUser.facilityIds.includes(clientId)).map(({ clientId }) => clientId),
        isActive: 1,
      });
    } else if (item.value === 'FacilityAdmins') {
      // 選擇這個option會進入"機構"模式
      facilities.forEach((facility) => {
        userGroupOptions.push({
          label: `${item.label}：${facility.name}`,
          userGroupName: item.value,
          clientId: facility.id,
          clientData: facility,
          isActive: facility.isActive,
        });
      });
    } else if (item.value === 'Clients') {
      // 選擇這個option會進入"客戶"模式
      individualClients.forEach((client) => {
        userGroupOptions.push({
          label: `${item.label}：${client.name}`,
          userGroupName: item.value,
          clientId: client.id,
          clientData: client,
          isActive: client.isActive,
        });
      });
    } else {
      // 選擇這個option會進入"管理者"模式
      userGroupOptions.push({
        label: item.label,
        userGroupName: item.value,
        isActive: 1,
      });
    }
  });
  return userGroupOptions;
};
