import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DeliveryStaffOrderPhoto from 'components/DeliveryStaffOrderPhoto';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { formatNote } from 'utilities/format';
import InfoCard from 'components/Card/InfoCard';
import { deliveryResult } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  img: {
    height: 200,
    padding: 15,
  },
}));

export default function PublicDeliveryStaffOrderCard({ data = {} }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { deliveryResult: inDeliveryResult } = data;
  const result = (deliveryResult.find(({ value }) => value === inDeliveryResult) || {}).label;
  const checklists = [
    { key: 'checkListIsComplete', label: `${t('送餐')}完成${result ? '(' + result + ')' : ''}` },
    { key: 'checkListReminder', label: t('生活提醒(氣候、保暖、補充水份)') },
    { key: 'checkListChat', label: t('跟長輩聊天關心(身體、生活)') },
  ];

  return (
    <InfoCard title={'送餐紀錄'} mappings={[]} data={{}}>
      <Card className={classes.root}>
        <Grid container justifyContent="start">
          {
            (data.photoS3Keys || []).map((s3Key)=>(
              <Grid item xs={12} md={4} key={s3Key} className={classes.img}>
                <DeliveryStaffOrderPhoto s3Key={s3Key} />
              </Grid>
            ))
          }
        </Grid>
        <CardContent>
          {checklists.map(({ label, key })=>(
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }} key={key}>
              {data[key] ? <CheckBoxIcon color="secondary" /> :
                <CheckBoxOutlineBlankIcon />}
              <Typography variant="body2" color="textSecondary" component="p" style={{ marginLeft: 10 }}>{label}</Typography>
            </div>
          ))}
          <Typography variant="body" color="textPrimary" component="p" style={{ marginTop: 10 }}>
            日誌
          </Typography>
          {formatNote(data.diary || '無')}
        </CardContent>
      </Card>
    </InfoCard>
  );
}

PublicDeliveryStaffOrderCard.propTypes = {
  data: PropTypes.object,
};
