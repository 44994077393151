import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import cache from 'utilities/cache';
import {
  getDeliveryStaffIdSchema,
} from 'forms/schemas';

export default function DeliveryStaffSelect({ onUpdate }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const clientId = cache.get('app:facilityId');
      const deliveryStaffIds = await getDeliveryStaffIdSchema(null, null, clientId, true, true);
      setDeliveryStaffOptions(deliveryStaffIds.enum.map((id, index) => ({
        id,
        name: deliveryStaffIds.enumNames[index],
      })));
      setIsLoading(false);
    })();
  }, []);

  return <Autocomplete
    id={'deliveryStaffSelect'}
    sx={{ width: 500 }}
    options={deliveryStaffOptions}
    loading={isLoading}
    onChange={(event, value)=>{
      onUpdate && onUpdate(value);
    }}
    getOptionLabel={(option) => option.name}
    renderInput={(params) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {isLoading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={t('送餐大使')}
        variant="standard"
      />
    )}
  />;
}

DeliveryStaffSelect.propTypes = {
  onUpdate: PropTypes.func,
};

