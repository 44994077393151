import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Auth } from 'aws-amplify';
import Version from 'components/Version';
import UserAvatar from 'components/UserAvatar';
import { SHORT_NAME } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import useCustomAuth from 'hooks/useCustomAuth';
import { useCache } from 'CacheProvider';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';

const useStyles = makeStyles((theme) => ({
  flexbox: {
    flexGrow: 1,
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  loginButton: {
    borderRadius: '20px',
  },
}));

export default function IndividualClientAppBar({ user, onUpdate }) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const { signOut } = useCustomAuth();
  const { userGroupOptions } = useCache();

  const anchorRef = useRef(null);
  const prevOpen = useRef(false);

  const username = cache.get('app:username') || '';
  const userName = cache.get('app:name') || '';

  function handleToggleMenu() {
    setOpenMenu((prevOpen) => !prevOpen);
  }

  function handleCloseMenu(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  }

  useEffect(() => {
    if (prevOpen && prevOpen.current === true && openMenu === false) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  async function handleSignOut(event) {
    handleCloseMenu(event);
    await signOut();
  }

  useEffect(() => {
    (async () => {
      if (!user) return;
      const isLoggedIn = await Auth.currentAuthenticatedUser({ bypassCache: true });

      if (!isLoggedIn) return;

      if (userGroupOptions.length === 0) return;
    })();
  }, [user, userGroupOptions]);

  return (
    <Box displayPrint="none">
      <AppBar
        position="fixed"
        elevation={0}
      >
        <Toolbar style={{ height: 64, backgroundColor: 'white' }}>
          <React.Fragment>
            <Typography variant='h6' align='left' style={{ fontWeight: 'bold', color: '#00913A' }} >
              {SHORT_NAME}
            </Typography>
          </React.Fragment>
          <div className={classes.flexbox} />
          {user ?
            <Button
              ref={anchorRef}
              color="inherit"
              aria-haspopup="true"
              onClick={handleToggleMenu}
              startIcon={<UserAvatar username={username} name={userName} />}
            /> :
            <CustomAuthDialog
              mode= 'signIn'
              withButton={false}
              button={(props) => (
                <Button
                  startIcon={<PersonOutlineOutlinedIcon />}
                  variant='outlined'
                  className={classes.loginButton}
                  {...props}
                >
                  註冊/登入
                </Button>
              )}
            />
          }
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            disablePortal
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList id="user-menu" autoFocusItem={openMenu}>
                  <MenuItem key={'username'} disabled={true}>
                    {username}
                  </MenuItem>
                  <MenuItem key={'version'} disabled={true}>
                    <Version />
                  </MenuItem>
                  <MenuItem key={'signOut'} onClick={handleSignOut}>登出</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

IndividualClientAppBar.propTypes = {
  user: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};
