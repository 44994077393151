import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { asyncListAll } from 'utilities/graph';
import { getEldersByClientByLinkGroupName } from 'graphql/queries';

export default function LinkGroupElderSelect({
  onUpdate,
  defaultValue,
  clientId,
  linkGroupName,
  excludedElderId,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [elderOptions, setElderOptions] = useState([]);
  const [linkGroupElderIds, setLinkGroupElderIds] = useState(defaultValue || []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const elders = await asyncListAll(getEldersByClientByLinkGroupName, {
        clientId,
        linkGroupName: { eq: linkGroupName },
      });
      let filteredElders = elders;
      if (excludedElderId) {
        filteredElders = elders.filter(({ id }) => id !== excludedElderId);
      }
      setElderOptions(filteredElders.map((elder) => ({
        id: elder.id,
        name: elder.name,
      })));
      setIsLoading(false);
    })();
  }, []);

  return (
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item xs={10}>
        <Autocomplete
          multiple
          loading={isLoading}
          options={elderOptions.map(({ id }) => id)}
          value={linkGroupElderIds}
          getOptionLabel={(option) => {
            const elder = elderOptions.find(({ id }) => id === option) || {};
            return elder.name;
          }}
          filterSelectedOptions
          onChange={(event, newValue) => {
            setLinkGroupElderIds(newValue);
            onUpdate && onUpdate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('送餐對象')}
              placeholder=""
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        {isLoading && <CircularProgress color="inherit" size={20} />}
      </Grid>
    </Grid>);
}

LinkGroupElderSelect.propTypes = {
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.array,
  clientId: PropTypes.string,
  linkGroupName: PropTypes.string,
  excludedElderId: PropTypes.string,
};
