import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { updateFacilitySettings, createFacilitySettings, updateClient } from 'graphql/mutations';
import ImageGalleryUploader from 'components/ImageGalleryUploader';
import { useCache } from 'CacheProvider';
import moment from 'moment-timezone';
import cache from 'utilities/cache';
import uiSchema from './uiSchema';

export default function FacilitySettingsForm({ formData: inFormData, ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const [currentImageS3Keys, setCurrentImageS3Keys] = useState([]);
  const [newImageS3Keys, setNewImageS3Keys] = useState([]);
  const { appGroup, setLogoS3Keys } = useCache();
  const [formData, setFormData] = useState();
  const facilityMode = appGroup === 'FacilityAdmins';

  useEffect(() => {
    if (inFormData && inFormData.facilitySettings && inFormData.facilitySettings.logoS3Keys && inFormData.facilitySettings.logoS3Keys[0]) {
      setCurrentImageS3Keys(inFormData.facilitySettings.logoS3Keys);
      setNewImageS3Keys(inFormData.facilitySettings.logoS3Keys);
    }
    if (inFormData) {
      if (inFormData.facilitySettings) {
        setFormData(inFormData);
      } else {
        setFormData({
          ...inFormData,
          facilitySettings: { // 避免rjsf跳出格式檢查錯誤
            lunchStartTime: null,
            lunchEndTime: null,
            dinnerStartTime: null,
            dinnerEndTime: null,
            deliverySetting: null,
            receiptInfo: null,
          },
        });
      }
    }
  }, [inFormData]);

  const updateFunc = async (data) => {
    data.facilitySettings.logoS3Keys = newImageS3Keys;
    if (facilityMode) {
      setLogoS3Keys([...newImageS3Keys]);
    }
    if (data.facilitySettings.id) {
      await request(updateFacilitySettings, { input: data.facilitySettings });
    } else {
      const now = moment().toISOString();
      const userName = cache.get('app:userName');
      Object.assign(data.facilitySettings, {
        createdAt: now,
        createdBy: userName,
        updatedAt: now,
        updatedBy: userName,
      });
      const { data: { createFacilitySettings: { id: facilitySettingsId } } } =
        await request(createFacilitySettings, { input: data.facilitySettings });
      await request(updateClient, { input: {
        id: data.id,
        type: data.type,
        isActive: data.isActive,
        county: data.county,
        name: data.name,
        address: data.address,
        email: data.email,
        usersLimit: data.usersLimit,
        eldersLimit: data.eldersLimit,
        createdAt: data.createdAt,
        createdBy: data.createdBy,
        updatedAt: now,
        updatedBy: userName,
        facilitySettingsId: facilitySettingsId,
      } });
    }
  };

  if (!formData) {
    return <></>;
  }

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={() => {}}
      updateFunc={updateFunc}
      formData={formData}
      dirty={true}
      {...props}
    >
      <Typography variant="h5">
        商標圖示
      </Typography>
      <Typography variant="body2" color='textSecondary'>
        顯示在左側功能清單上方
      </Typography>
      <Divider />
      <ImageGalleryUploader
        s3Prefix={`facilitySettings/${inFormData.id}/logo`}
        s3Keys={currentImageS3Keys}
        onUpdateS3Keys={setNewImageS3Keys}
      />
    </DataForm>
  );
}

FacilitySettingsForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
