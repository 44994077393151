import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DataTable from 'components/Table/DataTable';
import {
  getWebhookEventsByVendorByCreatedAt,
  listWebhookEvents,
} from 'graphql/queries';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'vendor',
    label: '外送夥伴與支付平台',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'headers',
    label: 'headers',
    type: 'json',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'body',
    label: 'body',
    type: 'json',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
];

const pastThreeDays = moment().tz(TIME_ZONE).add(-3, 'days').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function WebhookEventTable({
  title = 'Webhooks',
  description,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {
    expandableRows: true,
    isRowExpandable: () => false,
  };

  useEffect(() => {
    setIndexes([
      {
        name: 'getWebhookEventsByVendorByCreatedAt',
        label: '按外送夥伴與支付平台/時間查詢',
        partitionKey: 'vendor',
        sortKey: 'createdAt',
        fields: [{
          label: '外送夥伴與支付平台',
          key: 'vendor',
          type: 'select',
          options: [
            { value: 'uber', label: 'Uber' },
            { value: 'pandago', label: 'Pandago' },
            { value: 'lalamove', label: 'Lalamove' },
            { value: 'newebpay', label: 'NewebPay' },
          ],
        }, {
          label: '時間',
          key: 'createdAt',
          type: 'datetime',
        }],
        operation: getWebhookEventsByVendorByCreatedAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listWebhookEvents,
      },
    ]);
    setQueryParams({
      vendor: 'uber',
      createdAt: {
        from: pastThreeDays,
        to: today,
      },
    });
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      themeProps={{
        cell: {
          maxWidth: undefined,
        },
      }}
      columns={columns}
      options={options}
      defaultIndex={0}
      indexes={indexes}
      queryDefaultParams={queryParams}
    />
  );
}

WebhookEventTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
