import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function PaymentInput({ data, onChange }) {
  const [payment, setPayment] = useState(JSON.parse(JSON.stringify(data)));

  const updatePayment = (updatedPayment) => {
    setPayment(updatedPayment);
    onChange && onChange(updatedPayment);
  };

  return (
    <Grid container spacing={0} style={{ paddingTop: 16 }}>
      <Grid item xs={12} container justifyContent="flex-start">
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="payment-type--radio-buttons-group-label"
            name="payment-type--radio-buttons-group"
            value={payment.type}
            onChange={(e) => updatePayment({ type: e.target.value })}
          >
            <FormControlLabel value="linepay" control={<Radio />} label="LINE Pay" style={{ width: '100%' }}/>
            <FormControlLabel value="newebpay" control={<Radio />} label="信用卡或網路ATM（藍新金流）" style={{ width: '100%' }}/>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

PaymentInput.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};
