import React from 'react';

import LandingPage from 'views/Public/LandingPage';
import FacilityAuthenticator from './FacilityAuthenticator';

export default function FacilitySignUp() {
  return (
    <LandingPage
      Authenticator={FacilityAuthenticator}
      initialAuthState='signUp'
    />
  );
}
