import React from 'react';

import LalamoveOrderTable from 'components/LalamoveOrderTable';

export default function LalamoveOrders() {
  return (
    <React.Fragment>
      <LalamoveOrderTable />
    </React.Fragment>);
}
