import React, { useEffect } from 'react';
import useCustomAuth from 'hooks/useCustomAuth';
import cache from 'utilities/cache';

export default function Reset() {
  const { signOut } = useCustomAuth();

  useEffect(() => {
    cache.reset();
    signOut();
  }, []);

  return (
    <div>系統重啟中，請稍候...</div>
  );
}
