import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AutoSizeDialog from 'components/AutoSizeDialog';
import { WEEKDAYS_LONG } from '@silvergatedelivery/constants';

const dayMapping = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday',
];

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}));

export default function OpeningHoursDialog({ restaurant }) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const onClose = () => {
    setShowDialog(false);
  };

  const { name, holidays, openingHours } = restaurant || {};

  if (!openingHours || !holidays) {
    return <></>;
  }

  if (!dayMapping.some((day) => openingHours[day].length !== 0)) {
    return <></>;
  }
  const renderOpeningHours = (day) => {
    if (openingHours[day].length === 0) {
      return '休息';
    }
    const formatTime = (timeString) => timeString.split(':').slice(0, 2).join(':');
    return openingHours[day].map(({ startTime, endTime }) =>
      `${formatTime(startTime)} - ${formatTime(endTime)}`).join('、');
  };

  const renderHoliday = (day) => {
    let allHolidays = [];
    holidays.forEach(({ closedDays }) => {
      allHolidays = allHolidays.concat(closedDays);
    });

    return allHolidays.join('、');
  };

  return (
    <React.Fragment>
      <Button
        onClick={() => setShowDialog(true)}
      >
        營業時間
        <KeyboardArrowRightIcon />
      </Button>
      <AutoSizeDialog
        open={showDialog}
        title={'營業時間'}
        onClose={() => onClose()}
      >
        <DialogContent className={classes.content} dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: 10 }}>
              <Typography variant="h6">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ backgroundColor: '#F1F5EC', borderRadius: '10px', padding: 10 }}>
                {dayMapping.map((day, index) => {
                  return <Grid container item xs={12} key={day} alignItems='center' spacing={2}>
                    <Grid item><Typography variant="body1">
                      {`${WEEKDAYS_LONG[index]}`}
                    </Typography></Grid>
                    <Grid item><Typography variant="body2">
                      {renderOpeningHours(day)}
                    </Typography></Grid>
                  </Grid>;
                })}
                {holidays.length !== 0 &&
                <Grid container item xs={12} alignItems='center' spacing={2} style={{ marginTop: 10 }}>
                  <Grid item><Typography variant="body1">
                    近期公休日
                  </Typography></Grid>
                  <Grid item><Typography variant="body2">
                    {renderHoliday()}
                  </Typography></Grid>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </AutoSizeDialog>
    </React.Fragment>
  );
}

OpeningHoursDialog.propTypes = {
  restaurant: PropTypes.object.isRequired,
};
