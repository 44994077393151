import React from 'react';

import EditButton from '../components/EditButton';
import Form from './ClientAppBannerForm';

export default function ClientAppBannerEditButton({ ...props }) {
  return (
    <EditButton
      title="橫幅"
      form={Form}
      {...props}
    />);
}
