/* eslint-disable react/prop-types */
import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutoSelectWidget = (props) => {
  const {
    schema,
    id,
    options,
    label,
    required,
    disabled,
    readonly,
    value: inValue,
    autofocus,
    onChange,
    rawErrors = [],
  } = props;
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState();
  const {
    allowCustomInput = false,
  } = options;
  const enumOptions = schema.referenceOptions.map((option) => ({ label: option, value: option }));

  React.useEffect(() => {
    if (typeof inValue !== 'undefined') {
      const matched = enumOptions.find((option) => option.value === inValue);
      if (matched) {
        setValue(matched.label);
        setInputValue(matched.label);
      } else if (allowCustomInput) {
        setValue('');
        setInputValue(inValue);
      }
    }
  }, [inValue, enumOptions]);

  const renderInput = (params) => {
    return (<TextField
      {...params}
      label={label || schema.title}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      error={rawErrors.length > 0 || (errorMsg ? true : false)}
      required={required}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={errorMsg || params.helperText}
    />);
  };

  return (
    <Autocomplete
      id={`${id}_${Math.random()}`}
      options={enumOptions.map(({ label }) => label)}
      required={required}
      freeSolo
      value={value}
      onChange={(event, newItem) => {
        if (newItem) {
          const matched = enumOptions.find(({ label }) => label === newItem);
          if (matched) {
            onChange(matched.value);
            setErrorMsg();
          } else {
            if (allowCustomInput) {
              onChange(newItem === null ? undefined : newItem);
            } else {
              setErrorMsg('沒有符合的資料');
            }
          }
        } else if (allowCustomInput) {
          onChange(newItem === null ? undefined : newItem); // 不要return null, 因為null有機會對應到'無'，會造成reset時跳出'無'
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'reset') return;

        setInputValue(newInputValue);
        if (newInputValue) {
          const matched = enumOptions.find(({ label }) => label === newInputValue);
          if (matched) {
            onChange(matched.value);
            setErrorMsg();
          } else {
            if (allowCustomInput) {
              onChange(newInputValue);
            } else {
              setErrorMsg('沒有符合的資料');
            }
          }
        }
      }}
      // onBlur={_onBlur}
      // onFocus={_onFocus}
      renderInput={renderInput}
    />
  );
};

export default AutoSelectWidget;
