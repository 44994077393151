import { API, graphqlOperation } from '@aws-amplify/api';
import to from 'await-to-js';
import { toastr } from 'react-redux-toastr';

import { apiErrorCodes } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';

// const THRESHOLD = 500;

const __DEV__ = process.env.NODE_ENV === 'development';

// const DEV_LOGGING = true;

export async function request(query, params, authMode) {
  // const startedAt = Date.now();
  const options = graphqlOperation(query, params);
  // https://github.com/aws-amplify/amplify-js/blob/master/packages/api/src/types/index.ts#L75
  const username = cache.get('app:username');
  options.authMode = authMode ? authMode : (username ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY');

  // if (__DEV__ && DEV_LOGGING) {
  //   global.logger.debug(options);
  // }

  const [err, res] = await to(API.graphql(options));

  // if (__DEV__ && DEV_LOGGING) {
  //   // global.logger.debug(JSON.stringify(res, null, 2));

  //   const time = Date.now() - startedAt;
  //   const name = `${query.split('(')[0].replace(/ +/g, ' ').replace(/\n+/g, '')}`;
  //   global.logger.info(`API:${name} ${time} ms ${time>THRESHOLD?'***':''}`);
  // }

  if (err) {
    global.logger.error(err);

    const error = (err.errors ? (apiErrorCodes[err.errors[0].errorType] || err.errors[0].message) : err);
    const msg = typeof error === 'string' ? error : error.message;
    toastr.error(msg);

    if (__DEV__) {
      global.logger.debug(query);
      global.logger.debug(JSON.stringify(params || {}, null, 2));
    }
    throw err;
  }

  return res;
}

export async function asyncListAll(operation, input = {}, allItems = [], authMode) {
  const res = await request(operation, {
    limit: 100,
    ...input,
  }, authMode);

  const { items, nextToken } = res.data[Object.keys(res.data)[0]];
  allItems = [...allItems, ...items];

  if (nextToken) {
    return asyncListAll(operation, { ...input, nextToken }, allItems);
  }

  return allItems;
}

export async function asyncListAllAndUpdateLastItem(operation, input = {}, onUpdateLastItem, allItems = [], authMode) {
  const res = await request(operation, {
    limit: 100,
    ...input,
  }, authMode);

  const { items, nextToken } = res.data[Object.keys(res.data)[0]];
  allItems = [...allItems, ...items];

  onUpdateLastItem && onUpdateLastItem(items.length !== 0 ? items[items.length -1] : undefined, nextToken);

  if (nextToken) {
    return asyncListAllAndUpdateLastItem(operation, { ...input, nextToken }, onUpdateLastItem, allItems);
  }

  return allItems;
}
