/* eslint-disable quotes, comma-dangle */
export default {
  "title": "用戶權限",
  "type": "object",
  "required": [
    "username", "isActive"
  ],
  "properties": {
    "username": {
      "type": "string",
      "title": "ID"
    },
    "email": {
      'type': ['string', 'null'],
      "title": "Email"
    },
    "ownedFacilityName": {
      "type": "string",
      "title": "私有機構",
    },
    "isActive": {
      "type": "number",
      "title": "狀態",
      "enum": [0, 1],
      "enumNames": ["已停用", "使用中"]
    },
    // "isClient": {
    //   'type': "boolean",
    //   "title": "客戶 Clients",
    //   "default": false,
    // },
    // "clientId": {
    //   'type': ['string', 'null'],
    //   "title": "Client ID"
    // },
    // "isRestaurantOwner": {
    //   'type': "boolean",
    //   "title": "餐廳負責人 RestaurantOwners",
    //   "default": false,
    // },
    // "restaurantOwnerId": {
    //   'type': ['string', 'null'],
    //   "title": "Restaurant Owner ID"
    // },
    // "isDeliveryStaff": {
    //   'type': "boolean",
    //   "title": "送餐大使 DeliveryStaffs",
    //   "default": false,
    // },
    // "deliveryStaffId": {
    //   'type': ['string', 'null'],
    //   "title": "Delivery Staff ID"
    // },
    "isAdmin": {
      'type': "boolean",
      "title": "系統管理員 Admins",
      "default": false,
    },
    "isFacilityAdmin": {
      'type': "boolean",
      "title": "機構管理員 FacilityAdmins",
      "default": false,
    },
    // "adminId": {
    //   'type': ['string', 'null'],
    //   "title": "Admin ID",
    // },
    // "isOrgAdmin": {
    //   'type': "boolean",
    //   "title": "組織負責人 OrgAdmins",
    //   "default": false,
    // },
    "organizationId": {
      'type': ['string', 'null'],
      "title": "選擇組織"
    },
  }
};
