export default [
  {
    label: '尚未送單',
    value: 'pending',
  },
  {
    label: '媒合附近的司機',
    value: 'ASSIGNING_DRIVER',
  },
  {
    label: '已媒合到司機，將盡快去取件',
    value: 'ON_GOING',
  },
  {
    label: '司機已取件', // 司機已在附近
    value: 'PICKED_UP',
  },
  {
    label: '司機已抵達, 完成訂單',
    value: 'COMPLETED',
  },
  {
    label: '取消',
    value: 'CANCELED',
  },
  {
    label: '訂單取消',
    value: 'REJECTED',
  },
  {
    label: '訂單已過期',
    value: 'REJECTED',
  },
];

// ASSIGNING_DRIVER Trying to match the delivery with a driver.
// ON_GOING A driver has accepted the order.
// PICKED_UP The driver has picked up the order.
// COMPLETED The order has been delivered successfully and transaction has concluded.
// CANCELED User has canceled the order.
// REJECTED The order was matched and rejected twice by two drivers in a row, see Order Flow.
// EXPIRED The order expired as no drivers accepted the order
