import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import shortuuid from 'short-uuid';

import { formatTime, formatDatetime } from 'utilities/format';

export default function OrderCard({ order = {}, onClick }) {
  const { deliveryBy, createdAt, mealSlot, restaurant } = order;

  const mealItemsString = order.mealItems.map(({ name, quantity }) =>`${name} x ${quantity}`).join('、');

  const data = [
    {
      label: '外送日期',
      value: formatDatetime(deliveryBy, { hour: false, minute: false }),
      hightlight: true,
    },
    {
      label: '外送時間',
      value: `${mealSlot === 'lunch' ? '午餐' : '晚餐'} ${formatTime(deliveryBy)} - ${formatTime(moment(deliveryBy).add('60', 'minutes'))}`,
    },
    {
      label: '餐廳',
      value: restaurant.name,
    },
    {
      label: '餐點',
      value: mealItemsString,
    },
    {
      label: '訂單編號',
      value: shortuuid().fromUUID(order.id).slice(-6),
    },
    {
      label: '下單時間',
      value: formatDatetime(createdAt),
    },
  ];

  return (
    <Card style={{ padding: 16 }} onClick={onClick}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {data.map(({ label, value, hightlight=false }) => {
            return <Grid container key={label}>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2" color={hightlight ? 'primary': 'textPrimary'}>
                  {value}
                </Typography>
              </Grid>
            </Grid>;
          })}
        </Grid>
      </Grid>
    </Card>
  );
}

OrderCard.propTypes = {
  order: PropTypes.object,
  onClick: PropTypes.func,
};
