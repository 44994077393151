import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';

import DayPickerInput from 'react-day-picker/DayPickerInput';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 150,
    marginRight: 24,
    border: '1px solid #d1d3d8',
  },
}));

export default function DateRangeSelector({
  startDate,
  endDate,
  onUpdate,
}) {
  const classes = useStyles();

  const [isDirty, setIsDirty] = useState(false);
  const [desireStartDate, setDesireStartDate] = useState();
  const [desireEndDate, setDesireEndDate] = useState();

  const handleUpdate = () => {
    if (onUpdate) {
      onUpdate({
        startDate: desireStartDate,
        endDate: desireEndDate,
      });
      setIsDirty(false);
    }
  };

  useEffect(() => {
    setDesireStartDate(startDate);
    setDesireEndDate(endDate);
  }, [startDate, endDate]);

  if (!startDate || !endDate) return null;

  return (<div>
    <DayPickerInput
      showOutsideDays={true}
      value={startDate}
      formatDate={(v) => moment(v).format('YYYY-MM-DD')}
      onDayChange={(day) => {
        setDesireStartDate(moment(day).toISOString());
        setIsDirty(true);
      }}
      dayPickerProps={{
        disabledDays: {
          after: new Date(desireEndDate),
        },
      }}
      inputProps={{
        className: classes.input,
      }}
    />
    <DayPickerInput
      showOutsideDays={true}
      value={endDate}
      formatDate={(v) => moment(v).format('YYYY-MM-DD')}
      onDayChange={(day) => {
        setDesireEndDate(moment(day).toISOString());
        setIsDirty(true);
      }}
      dayPickerProps={{
        disabledDays: {
          before: new Date(desireStartDate),
        },
      }}
      inputProps={{
        className: classes.input,
      }}
    />
    <Button variant="contained" color="primary" onClick={handleUpdate} disabled={!isDirty}>
      更新
    </Button>
  </div>);
}


DateRangeSelector.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onUpdate: PropTypes.func,
};
