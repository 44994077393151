export default [
  {
    label: '待審核',
    value: 0,
    color: '#ff0318',
  },
  {
    label: '使用中',
    value: 1,
    color: '#00913A',
  },
  {
    label: '已停用',
    value: 2,
    color: '#363636',
  },
];
