import React from 'react';

import EditButton from '../components/EditButton';
import DementiaQuestionnaireForm from './DementiaQuestionnaireForm';

export default function DementiaQuestionnaireEditButton({ ...props }) {
  return (
    <EditButton
      title="失智友善問卷"
      form={DementiaQuestionnaireForm}
      {...props}
    />);
}
