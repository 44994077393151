import { utils, write } from 'sheetjs-style';

export default function writeYearly(clientStatements, county, includeUndeliveredOrders=false, zip) {
  const row = {
    '地區': county,
    '服務人數': 0,
    '自費戶': 0,
    '弱勢戶': 0,
    '服務餐次': 0,
    '自費戶服務餐次': 0,
    '弱勢戶服務餐次': 0,
    '餐費': 0,
    '大使人數': 0,
    '大使車馬費': 0,
    '外送平台使用件數': 0,
  };

  const uniqueElderIdsSet = new Set();
  const uniqueDeliveryStaffIdsSet = new Set();
  const uniqueElderIdWithServiceCode = new Set();
  const uniqueElderIdWithOutServiceCode = new Set();
  clientStatements.sort((a, b) => a.month.localeCompare(b.month) * -1).forEach((statement) => {
    row['服務餐次'] += includeUndeliveredOrders ? statement.completedOrderCount + (statement.incompleteOrderCount || 0): statement.completedOrderCount;
    row['餐費'] += includeUndeliveredOrders ? statement.total + (statement.incompleteTotal || 0): statement.total;
    row['大使車馬費'] += statement.deliveryStaffFee;
    row['外送平台使用件數'] += statement.deliveryPlatformCount;
    statement.elderStatistics.forEach(({ id, serviceCode, orderCount, incompleteOrderCount }) => {
      uniqueElderIdsSet.add(id);
      if (!uniqueElderIdWithServiceCode.has(id) && !uniqueElderIdWithOutServiceCode.has(id)) {
        if (serviceCode === 'OT01' || serviceCode === '脆家') {
          uniqueElderIdWithServiceCode.add(id);
        } else {
          uniqueElderIdWithOutServiceCode.add(id);
        }
      }
      if (serviceCode === 'OT01' || serviceCode === '脆家') {
        row['弱勢戶服務餐次'] += includeUndeliveredOrders ? orderCount + (incompleteOrderCount || 0) : orderCount;
      } else {
        row['自費戶服務餐次'] += includeUndeliveredOrders ? orderCount + (incompleteOrderCount || 0) : orderCount;
      }
    });
    statement.deliveryStaffIds.forEach((id) => uniqueDeliveryStaffIdsSet.add(id));
  });
  row['服務人數'] = uniqueElderIdsSet.size;
  row['自費戶'] = uniqueElderIdWithOutServiceCode.size;
  row['弱勢戶'] = uniqueElderIdWithServiceCode.size;
  row['大使人數'] = uniqueDeliveryStaffIdsSet.size;

  const worksheet = utils.json_to_sheet([row]);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, `${county} 年度報表`);
  const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

  zip.file(`${county}__年度報表.xlsx`, wbout, { binary: true });
}
