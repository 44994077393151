import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import JSZip from 'jszip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { request, asyncListAll } from 'utilities/graph';
import { download } from 'utilities/file';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import {
  getOrdersByClientByDate,
  getOrdersByStatusByDate,
} from './queries';
import { sortBy } from 'utilities/sorting';
import cache from 'utilities/cache';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { unifyCellStyle } from './CountyPrsReport/workbooks/helpers';
import { utils, write } from 'sheetjs-style';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

export default function RestaurantReport({ clientOptions: inClientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState();
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    const period = `${fromDate}__${toDate}`;
    const client = clientOptions.find(({ id }) => id === selectedClientId);

    global.logger.debug({
      selectedClientId,
      client,
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const deliveryStaffIds = [];
    const restaurantIds = [];

    let allOrders;
    if (client.id === 'N/A') {
      let includedOrderStatus = ['delivered', 'completed'];
      if (includeUndeliveredOrders) {
        includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
      }
      const allOrdersByStatus = await Promise.all(includedOrderStatus.map((status) =>
        asyncListAll(getOrdersByStatusByDate, {
          status,
          date: {
            between: [fromDate, toDate],
          },
          limit: 1000,
        }),
      ));
      allOrders = [].concat(...allOrdersByStatus);
    } else {
      ([
        allOrders,
      ] = await Promise.all([
        asyncListAll(getOrdersByClientByDate, {
          clientId: client.id,
          date: {
            between: [fromDate, toDate],
          },
          limit: 1000,
        }),
      ]));
    }

    allOrders.forEach(({ deliveryStaffId, restaurantId }) => {
      if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
        deliveryStaffIds.push(deliveryStaffId);
      }
      if (restaurantId && !restaurantIds.includes(restaurantId)) {
        restaurantIds.push(restaurantId);
      }
    });

    const [deliveryStaffs, restaurants] = await Promise.all([
      Promise.all(deliveryStaffIds.map(async (id) => {
        const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
          query GetDeliveryStaff($id: ID!) {
            getDeliveryStaff(id: $id) {
              id
              name
              identificationCardId
            }
          }
        `, { id });
        return data;
      })),
      Promise.all(restaurantIds.map(async (id) => {
        const { data: { getRestaurant: data } } = await request( /* GraphQL */ `
          query GetRestaurant($id: ID!) {
            getRestaurant(id: $id) {
              id
              name
              address {
                country
                county
                district
                street
                zipCode
                lat
                lng
                note
              }
            }
          }
        `, { id });
        return data;
      })),
    ]);

    const zip = new JSZip();
    const mappings = {};
    const mappingsByRestaurant = {};
    const mealStatistic = {};
    let includedOrderStatus = ['delivered', 'completed'];
    if (includeUndeliveredOrders) {
      includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
    }
    restaurants.forEach((restaurant) => {
      let orders = allOrders
        .filter(({ restaurantId }) => restaurantId === restaurant.id)
        .filter(({ status }) => includedOrderStatus.includes(status));
      if (excludeNonFoodDeliveryOrders) {
        orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
      }

      orders.forEach((order) => {
        const {
          date,
          mealSlot,
          clientId,
          deliveryStaffId,
          restaurantId,
          mealItems,
        } = order;
        const client = clientOptions.find(({ id }) => id === clientId) || { id: '無', name: '無' };
        const deliveryStaff = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
        const key = `${date}__${clientId}__${restaurantId}__${deliveryStaffId}`;

        mappings[key] = mappings[key] || {
          '日期': date,
          '機構': client.name,
          '餐廳': restaurant.name,
          '送餐大使': deliveryStaff ? deliveryStaff.name : '',
          '午餐餐點數量': 0,
          '晚餐餐點數量': 0,
          '總進價': 0,
        };
        mappingsByRestaurant[restaurantId] = mappingsByRestaurant[restaurantId] || {
          '餐廳': restaurant.name,
          '午餐餐點數量': 0,
          '晚餐餐點數量': 0,
          '餐點份數': '',
          '總進價': 0,
        };
        mealStatistic[restaurantId] = mealStatistic[restaurantId] || {};

        const mealItemCount = mealItems.reduce((sum, item) => {
          return sum + item.quantity;
        }, 0);
        const cost = mealItems.reduce((sum, item) => {
          return sum + item.cost * item.quantity;
        }, 0);

        if (mealSlot === 'lunch') {
          mappings[key]['午餐餐點數量'] += mealItemCount;
          mappingsByRestaurant[restaurantId]['午餐餐點數量'] += mealItemCount;
        } else {
          mappings[key]['晚餐餐點數量'] += mealItemCount;
          mappingsByRestaurant[restaurantId]['晚餐餐點數量'] += mealItemCount;
        }
        mealItems.forEach(({ name, quantity, cost }) => {
          const key =`${name}(${cost})`;
          if (!mealStatistic[restaurantId][key]) {
            mealStatistic[restaurantId][key] = 0;
          }
          mealStatistic[restaurantId][key] += quantity;
        });
        mappingsByRestaurant[restaurantId]['總進價'] += cost;
        mappings[key]['總進價'] += cost;
      });
    });

    Object.keys(mappingsByRestaurant).forEach((restaurantId) => {
      const mealStatisticString = [];
      Object.keys(mealStatistic[restaurantId]).forEach((mealName) => {
        const mealCount = mealStatistic[restaurantId][mealName];
        mealStatisticString.push(`${mealName} * ${mealCount}`);
      });
      mappingsByRestaurant[restaurantId]['餐點份數'] = mealStatisticString.join('\n');
    });
    const workbook = utils.book_new();

    const restaurantMappings = {};

    Object.keys(mappings).forEach((key) => {
      const restaurantName = mappings[key]['餐廳'];
      restaurantMappings[restaurantName] = restaurantMappings[restaurantName] || [];
      restaurantMappings[restaurantName].push(mappings[key]);
    });

    const rowsByRestaurant = Object.keys(mappingsByRestaurant)
      .map((key) => mappingsByRestaurant[key])
      .sort(sortBy('餐廳'));

    const worksheet = utils.json_to_sheet(rowsByRestaurant);
    worksheet['!cols'] = [
      { wch: 30 }, { wch: 8 },
      { wch: 8 }, { wch: 50 },
      { wch: 8 },
    ];
    unifyCellStyle(worksheet, rowsByRestaurant.length + 1);
    utils.book_append_sheet(workbook, worksheet, `餐廳製餐數`);

    const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    zip.file(`@餐廳製餐數.xlsx`, wbout, { binary: true });

    Object.keys(restaurantMappings)
      .forEach((restaurantName) => {
        const rows = restaurantMappings[restaurantName].sort(sortBy('日期'));

        const normalizedName = `${restaurantName.replace(/[^\u4e00-\u9fa5]/g, '')}`;
        const workbook = utils.book_new();
        const worksheet = utils.json_to_sheet(rows);
        utils.book_append_sheet(workbook, worksheet, normalizedName);

        const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        zip.file(`${normalizedName}.xlsx`, wbout, { binary: true });
      });

    const zipContent = zip.generate({ type: 'blob' });
    download(`${client.name}__餐廳製餐數__${selectedMonth}.zip`, zipContent);
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const isFacilityAdmin = cache.get('app:facilityId');

    const clientOptions = JSON.parse(JSON.stringify(inClientOptions));
    if (!isFacilityAdmin && inClientOptions.length > 0 && inClientOptions[0].id !== 'N/A') {
      clientOptions.unshift({
        id: 'N/A',
        name: '全部機構',
      });
    }

    setClientOptions(clientOptions);
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
    }
  }, [inClientOptions]);

  return (
    <div>
      <h3>餐廳製餐數報表輸出(按機構)</h3>
      <FormControl style={{ minWidth: 360, marginRight: 16 }}>
        <AutoSelect
          id="selected-restaurant"
          options={clientOptions.map(({ name }) => name)}
          values={clientOptions.map(({ id }) => id)}
          required={true}
          value={selectedClientId}
          label="機構"
          onChange={(value) => setSelectedClientId(value)}
        />
      </FormControl>
      <FormControl style={{ width: 150, marginRight: 16 }}>
        <InputLabel id="selected-month">月份</InputLabel>
        <Select
          labelId="selected-month-label"
          id="selected-month"
          value={selectedMonth}
          onChange={(event) => setSelectedMonth(event.target.value)}
          disabled={isLoading}
        >
          {monthOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={includeUndeliveredOrders}
            name={'includeUndeliveredOrders'}
            value={includeUndeliveredOrders}
            disabled={isLoading}
            onChange={(e) => {
              setIncludeUndeliveredOrders(e.target.checked);
            }}
          />
        }
        label={'包含未送達的訂單'}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={excludeNonFoodDeliveryOrders}
            name={'excludeNonFoodDeliveryOrders'}
            value={excludeNonFoodDeliveryOrders}
            disabled={isLoading}
            onChange={(e) => {
              setExcludeNonFoodDeliveryOrders(e.target.checked);
            }}
          />
        }
        label={'排除非送餐的訂單'}
      />
      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={!selectedClientId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}

RestaurantReport.propTypes = {
  clientOptions: PropTypes.array,
};
