import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { asyncListAll } from 'utilities/graph';
import {
  getIssueCommentsByIssueByCreatedAt,
} from 'graphql/queries';

import IssueTimeline from './IssueTimeline';
import { issueStatus as allIssueStatus } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    width: 400,
  },
  timeline: {
    flex: 1,
  },
}));

export default function Issue({ issue, onUpdateComment, onUpdateIssueStatus, timelineOnly=false }) {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [issueStatus, setIssueStatus] = useState(issue.status);

  useEffect(() => {
    if (!issue || !issue.id) return;
    (async () => {
      const data = await asyncListAll(getIssueCommentsByIssueByCreatedAt, { issueId: issue.id });
      setComments(data);
    })();
  }, [issue]);

  if (!issue) return null;

  return (
    <Grid container>
      <Grid item className={classes.actionContainer}>
        <div className={classes.timeline}>
          <IssueTimeline issue={issue} comments={comments} />
        </div>
        {!timelineOnly && <Grid container direction="column" spacing={2} >
          <Grid item >
            <FormControl >
              <InputLabel>狀態</InputLabel>
              <Select
                value={issueStatus}
                onChange={(event) => {
                  setIssueStatus(event.target.value);
                  onUpdateIssueStatus(event.target.value);
                }}
              >
                {allIssueStatus.map(({ label, value }) => (
                  <MenuItem key={label} value={value} >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <TextField
            label="處理筆記"
            multiline
            minRows={2}
            variant="outlined"
            fullWidth={true}
            value={commentText}
            onChange={(event)=> {
              setCommentText(event.target.value);
              onUpdateComment(event.target.value);
            }}
            style={{ marginBottom: 16 }}
          />
        </Grid>}
      </Grid>
    </Grid>
  );
}

Issue.propTypes = {
  issue: PropTypes.object,
  onUpdateComment: PropTypes.func,
  onUpdateIssueStatus: PropTypes.func,
  timelineOnly: PropTypes.bool,
};
