export const getOrdersByClientByDate = /* GraphQL */ `
  query GetOrdersByClientByDate(
    $clientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        date
        mealSlot
        deliveryBy
        status
        county
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        createdAt
      }
      nextToken
    }
  }
`;
