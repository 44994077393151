import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { GOOGLE_KEY } from 'credentials.js';
import { formatAddress } from 'utilities/format';

const useStyles = makeStyles((theme) => ({
  iframe: {
    border: 0,
  },
}));

export default function Place({ address, options = {} }) {
  const classes = useStyles();
  const [url, setUrl] = useState();

  const { width, height } = Object.assign({
    width: '100%',
    height: 200,
  }, options);

  useEffect(() => {
    const q = (address && address.lat && address.lng) ? `${address.lat},${address.lng}` :
      formatAddress(address, { ignoreNote: true, includeZipCode: false });

    if (!address || !q || q === ' ') {
      setUrl();
    } else {
      const queryParams = [
        `key=${GOOGLE_KEY}`,
        'language=zh-TW',
        'region=tw',
        `q=${q}`,
      ].join('&');

      const url = `https://www.google.com/maps/embed/v1/place?${queryParams}`;
      setUrl(url);
    }
  }, [address]);

  if (!url) {
    return (
      <div style={{ width, height, textAlign: 'center' }}>
        <img
          width="auto"
          height="100%"
          src="/images/app-functions.webp" />
      </div>);
  }

  return (
    <iframe
      title="place"
      width={width}
      height={height}
      className={classes.iframe}
      src={url}
      allowFullScreen />
  );
}

Place.propTypes = {
  options: PropTypes.object,
  address: PropTypes.object,
};
