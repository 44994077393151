export default {
  '餐廳': {
    '食材': {
      color: '#007510',
    },
    '牙口': {
      color: '#f09400',
    },
    '疾病': {
      color: '#cc0074',
    },
    '葷素': {
      color: '#1172bf',
    },
    '特殊項目': {
      color: '#363636',
    },
  },
  '餐點': {
    '營養': {
      color: '#007510',
    },
    '食材': {
      color: '#cc0074',
    },
  },
  '送餐對象': {
    '飲食': {
      color: '#1172bf',
    },
    '健康': {
      color: '#de0012',
    },
    '居住': {
      color: '#007510',
    },
    '其他': {
      color: '#363636',
    },
  },
};
