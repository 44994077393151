import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Countdown from 'react-countdown';

import { asyncListAll } from 'utilities/graph';
import {
  listIssues,
  listAdmins,
  getIssuesByCountyByStatus,
  getIssuesByStatusByCreatedAt,
} from 'graphql/queries';
import Issue from './components/Issue';
import {
  Colors,
  counties,
  issueStatus,
  AUTO_UPDATE_INTERVAL_IN_MILLISECONDS,
} from '@silvergatedelivery/constants';
import { sortBy } from 'utilities/sorting';
import { formatDatetimeFromNow } from 'utilities/format';
import cache from 'utilities/cache';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  listContainer: {
    position: 'relative',
    overflow: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 64px)',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    // overflow: 'auto',
    // maxHeight: 'calc(100vh - 200px)',
  },
  chip: {
    border: '1px solid #cecece',
    borderRadius: 3,
    padding: 1,
    display: 'inline-block',
    marginRight: 6,
    marginBottom: 6,
  },
  issueContainer: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 64px)',
  },
  selected: {
    borderLeft: `6px solid ${Colors.primary}`,
    paddingLeft: 10,
  },
  selectChips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectChip: {
    margin: 2,
  },
  filters: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
}));

export default function IssueCenter() {
  const classes = useStyles();
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState();
  const [admins, setAdmins] = useState([]);
  const [query, setQuery] = useState({
    county: [cache.get('app:location')].filter((x) => x),
    status: ['pending', 'processing'],
  });
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadAdmins, setHasLoadAdmins] = useState(false);
  // const [sortMethods, setSortMethods] = useState({
  //   type: 0,
  //   summary: 0,
  //   createdAt: 0,
  //   updatedAt: 0,
  //   status: 0,
  // });

  const renderChipValue = (selected) => (
    <div className={classes.selectChips}>
      {selected.map((value) => {
        const matchedStatus = issueStatus.find((item)=>item.value === value);
        const label = matchedStatus ? matchedStatus.label : value;
        const backgroundColor = matchedStatus ? matchedStatus.color : undefined;
        const color = backgroundColor ? '#fff' : undefined;

        return (<Chip key={value}
          label={label}
          className={classes.selectChip}
          style={{ color, backgroundColor }}
        />);
      })}
    </div>
  );

  const updateQuery = (key, value) => {
    query[key] = value;
    setQuery({ ...query });
  };

  useEffect(() => {
    if (hasLoadAdmins) return;
    (async () => {
      setAdmins(await asyncListAll(listAdmins));
      setHasLoadAdmins(true);
    })();
  }, [hasLoadAdmins]);

  useEffect(() => {
    if (!query) return;
    (async () => {
      try {
        setIsLoading(true);
        const { county, status } = query;

        let data = [];
        if (county.length > 0) {
          await Promise.all(county.map(async (countyItem) => {
            if (status.length > 0) {
              await Promise.all(status.map(async (statusItem) => {
                const params = {
                  county: countyItem,
                  status: { eq: statusItem },
                };
                const records = await asyncListAll(getIssuesByCountyByStatus, params);
                data = [...data, ...records];
              }));
            } else {
              const params = {
                county: countyItem,
              };
              const records = await asyncListAll(getIssuesByCountyByStatus, params);
              data = [...data, ...records];
            }
          }));
        } else
        if (status.length > 0) {
          await Promise.all(status.map(async (item) => {
            const records = await asyncListAll(getIssuesByStatusByCreatedAt, { status: item });
            data = [...data, ...records];
          }));
        } else {
          data = await asyncListAll(listIssues);
        }

        data = data.sort(sortBy('updatedAt', true));

        setIssues(data);
        setSelectedIssue(data[0]);
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [query, lastUpdatedAt]);

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} md={2} className={classes.listContainer}>
        <Grid container className={classes.filters} direction="column">
          <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: -4, marginBottom: 4 }}>
            距離下次自動更新：
            {!isLoading &&
            <Countdown
              date={lastUpdatedAt + AUTO_UPDATE_INTERVAL_IN_MILLISECONDS}
              daysInHours={true}
              onComplete={()=>setLastUpdatedAt(Date.now())}
            />}
            <IconButton size="small" disabled={isLoading} onClick={()=>setLastUpdatedAt(Date.now())}>
              <RefreshIcon />
            </IconButton>
          </Typography>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>縣市</InputLabel>
            <Select
              multiple
              value={query.county}
              onChange={(event) => updateQuery('county', event.target.value)}
              input={<Input id="select-multiple-counties" />}
              renderValue={renderChipValue}
              disabled={isLoading}
            >
              {counties.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={query.county.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>狀態</InputLabel>
            <Select
              multiple
              value={query.status}
              onChange={(event) => updateQuery('status', event.target.value)}
              input={<Input id="select-multiple-issueStatus" />}
              renderValue={renderChipValue}
              disabled={isLoading}
            >
              {issueStatus.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={query.status.indexOf(item.value) > -1} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <List className={classes.list}>
          {issues.map((issue, index)=>(
            <ListItem
              key={index}
              button
              divider
              onClick={()=>{
                global.logger.debug(issue);
                setSelectedIssue(issue);
              }}
              disabled={isLoading}
              className={selectedIssue && selectedIssue.id === issue.id ? classes.selected : ''}
            >
              <Grid container direction="column">
                <Typography variant="body1" noWrap={true}>
                  {issue.summary}
                </Typography>
                <Typography variant="body2" color="textSecondary" noWrap={true}>
                  {issue.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                  <IssueChips issue={issue} />
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                  更新於 {formatDatetimeFromNow(issue.updatedAt)}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                  通報於 {formatDatetimeFromNow(issue.createdAt)}
                </Typography>
              </Grid>
              {/* <ListItemText
                primary={issue.summary}
                secondary={<IssueChips issue={issue} />}
                secondaryTypographyProps={{ align: 'left', style: { marginTop: 8 } }}
              /> */}
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item md={10} className={classes.issueContainer}>
        <Issue
          issue={selectedIssue}
          admins={admins}
          onUpdate={() => {
            setIssues([...issues]);
          }}
        />
      </Grid>
    </Grid>
  );
}

function IssueChips({ issue }) {
  const classes = useStyles();
  const { label: statusLabel, color: statusColor } = issueStatus.find(({ value }) => value === issue.status);
  return (
    <React.Fragment>
      <span className={classes.chip} style={{ color: statusColor, borderColor: statusColor }}>{statusLabel}</span>
      <span className={classes.chip}>{issue.priority}</span>
      <span className={classes.chip}>{issue.county}</span>
      <span className={classes.chip}>{issue.type}</span>
      {issue.assignee && <span className={classes.chip}>{issue.assignee}</span>}
    </React.Fragment>);
}

IssueChips.propTypes = {
  issue: PropTypes.object.isRequired,
};
