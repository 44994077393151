const schema = {
  'title': '基本資料',
  'type': 'object',
  'required': [
    'name',
  ],
  'properties': {
    'name': {
      'type': 'string',
      'title': '名稱',
    },
    'description': {
      'type': ['string', 'null'],
      'title': '描述',
    },
    'clients': {
      'type': 'array',
      'title': '機構與權限',
      'items': {
        'type': 'object',
        'required': [
          'clientId',
        ],
        'properties': {
          'clientId': {
            'type': 'string',
            'title': '機構',
          },
          'visitorMode': {
            'type': 'boolean',
            'title': '訪客模式',
            'default': true,
          },
          'clientMode': {
            'type': 'boolean',
            'title': '可切換至機構模式',
            'default': false,
          },
          'elderSetting': {
            'type': 'boolean',
            'title': '組織模式下可編輯送餐對象',
            'default': false,
          },
        },
      },
    },
  },
};

export default schema;
