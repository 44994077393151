import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import {
  listMembershipPrograms,
  getMembershipProgramsByIsActiveByCreatedAt,
} from 'graphql/queries';
import EditButton from 'forms/MembershipProgramForm/MembershipProgramFormEditButton';
import { sortBy } from 'utilities/sorting';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'isActive',
    label: '狀態',
    type: 'checkbox',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'name',
    label: '名稱',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'description',
    label: '詳細描述',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'discountRule',
    label: '折扣描述',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'discountPercentage',
    label: '折扣',
    type: 'number',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'durationInDays',
    label: '使用天數',
    type: 'number',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unitValue',
    label: '單位價格',
    type: 'currency',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'unit',
    label: '單位',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function MembershipProgramTable({
  title = '會員項目',
  description,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  useEffect(() => {
    setIndexes([
      {
        name: 'getMembershipProgramsByIsActiveByCreatedAt',
        label: '按狀態查詢',
        partitionKey: 'isActive',
        sortKey: 'createdAt',
        fields: [{
          label: '狀態',
          key: 'isActive',
          type: 'select',
          options: [
            { value: 1, label: '使用中' },
            { value: 0, label: '停用中' },
          ],
        }],
        operation: getMembershipProgramsByIsActiveByCreatedAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listMembershipPrograms,
      },
    ]);
    setQueryParams({
      isActive: 1,
    });
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={EditButton}
      dataSortFunc={sortBy('name')}
    />
  );
}

MembershipProgramTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
