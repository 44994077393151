export default [
  {
    label: '指派送餐員中',
    value: 'pending',
  },
  {
    label: '取餐中',
    value: 'pickup',
  },
  {
    label: '已取餐',
    value: 'pickup_complete',
  },
  {
    label: '送餐中',
    value: 'dropoff',
  },
  {
    label: '已送達',
    value: 'delivered',
  },
  {
    label: '取消',
    value: 'canceled',
  },
  {
    label: '訂單取消，退還物件',
    value: 'returned',
  },
];
