import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const TIME_OPTIONS_LUNCH = [
  '不需要午餐',
  '10:00-11:00',
  '11:00-12:00',
  '12:00-13:00',
  '13:00-14:00',
];

const TIME_OPTIONS_DINNER = [
  '不需要晚餐',
  '16:00-17:00',
  '17:00-18:00',
  '18:00-19:00',
];

const TimeSettings = ({ onUpdate, defaultValue }) => {
  const [lunch, setLunch] = useState(defaultValue?.lunch || TIME_OPTIONS_LUNCH[0]);
  const [dinner, setDinner] = useState(defaultValue?.dinner || TIME_OPTIONS_DINNER[0]);

  useEffect(() => {
    if (lunch && dinner) {
      onUpdate && onUpdate({ lunch, dinner });
    }
  }, [lunch, dinner]);

  if (!lunch || !dinner) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
          選擇午餐送達時間
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="date-label">
            午餐時間
          </InputLabel>
          <Select
            fullWidth
            value={lunch}
            label={'午餐時間'}
            onChange={(e) => {
              const { value } = e.target;
              setLunch(value);
            }}
          >
            {TIME_OPTIONS_LUNCH.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
          選擇晚餐送達時間
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="date-label">
            晚餐時間
          </InputLabel>
          <Select
            fullWidth
            value={dinner}
            label={'晚餐時間'}
            onChange={(e) => {
              const { value } = e.target;
              setDinner(value);
            }}
          >
            {TIME_OPTIONS_DINNER.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TimeSettings;

TimeSettings.propTypes = {
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.any,
};
