import React from 'react';
import PropTypes from 'prop-types';

import EditButton from '../components/EditButton';
// import AdminCreateOrderForm from './AdminCreateOrderForm';
import AdminUpdateOrderForm from './AdminUpdateOrderForm';
// import AdminCreateBulkOrderForm from './AdminCreateBulkOrderForm';
import AdminCreateBulkOrderForm from '../AdminBulkOrderForm/AdminCreateBulkOrderForm';

export default function AdminOrderEditButton({ mode = 'edit', ...props }) {
  const title = mode === 'edit' ? '修改訂單' : '訂單';
  const form = mode === 'edit' ? AdminUpdateOrderForm : AdminCreateBulkOrderForm;
  return (
    <EditButton
      title={title}
      mode={mode}
      form={form}
      fullScreen={mode === 'edit' ? false : true}
      exitConfirm={true}
      {...props}
    />);
}

AdminOrderEditButton.propTypes = {
  mode: PropTypes.string,
};
