import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import {
  getOrder,
  getClient,
  getDeliveryStaff,
  getDeliveryStaffOrder,
  getDeliveryStaffOrdersByOrder,
  getOrderEventsByOrderByCreatedAt,
  getPandagoOrdersByOrderByCreatedAt,
  getUberOrdersByOrderByCreatedAt,
} from 'graphql/queries';
import { request, asyncListAll } from 'utilities/graph';
import { Colors } from '@silvergatedelivery/constants';

import OrderCard from './OrderCard';
import ClientCard from '../Card/ClientCard';
import ElderCard from '../Card/ElderCard';
import RestaurantCard from '../Card/RestaurantCard';
import DeliveryStaffOrderCard from './DeliveryStaffOrderCard';
import DeliveryStaffCard from '../Card/DeliveryStaffCard';
import OrderDetails from './OrderDetails';
import OrderEventCard from './OrderEventCard';
import OrderStatusProgress from './OrderStatusProgress';
import DeliveryStaffOrdersCard from './DeliveryStaffOrdersCard';
import PandagoOrderTable from 'components/PandagoOrderTable';
import UberOrderTable from 'components/UberOrderTable';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    // minHeight: 'calc(100vh - 64px)',
    // height: '100%',
    backgroundColor: Colors.background.light,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  space: {
    height: theme.spacing(2),
  },
  // centerContainer: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export default function Order({ id, onUpdate }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [client, setClient] = useState({});
  const [deliveryStaffOrder, setDeliveryStaffOrder] = useState({});
  const [deliveryStaffOrders, setDeliveryStaffOrders] = useState([]);
  const [deliveryStaff, setDeliveryStaff] = useState({});
  const [orderEvents, setOrderEvents] = useState([]);
  const [uberOrders, setUberOrders] = useState([]);
  const [pandagoOrders, setPandagoOrders] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());

  useEffect(() => {
    if (!id) return;
    (async () => {
      const [
        { data: { getOrder: order } },
      ] = await Promise.all([
        request(getOrder, { id }),
      ]);

      setData(order);

      const [
        { data: { getClient: client } },
      ] = await Promise.all([
        request(getClient, { id: order.individualId || order.clientId }),
      ]);

      setClient(client);

      global.logger.debug(order);

      if (order.deliveryStaffId) {
        const [
          { data: { getDeliveryStaff: staff } },
          { data: { getDeliveryStaffOrder: staffOrder } },
        ] = await Promise.all([
          request(getDeliveryStaff, { id: order.deliveryStaffId }),
          request(getDeliveryStaffOrder, { id: order.deliveryStaffOrderId }),
        ]);

        setDeliveryStaffOrder(staffOrder || {});
        setDeliveryStaff(staff || {});
      }

      const [
        { data: { getDeliveryStaffOrdersByOrder: { items: staffOrders } } },
        orderEvents,
        pandagoOrders,
        uberOrders,
      ] = await Promise.all([
        request(getDeliveryStaffOrdersByOrder, { orderId: order.id }),
        asyncListAll(getOrderEventsByOrderByCreatedAt, { orderId: order.id, sortDirection: 'ASC' }),
        asyncListAll(getPandagoOrdersByOrderByCreatedAt, { orderId: order.id }),
        asyncListAll(getUberOrdersByOrderByCreatedAt, { orderId: order.id }),
      ]);

      setDeliveryStaffOrders(staffOrders);
      setOrderEvents(orderEvents);
      setPandagoOrders(pandagoOrders);
      setUberOrders(uberOrders);
    })();
  }, [id, lastUpdatedAt]);

  if (!data) return null;

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container>
        <OrderStatusProgress
          status={data.status}
          onClick={() => setLastUpdatedAt(Date.now())}
        />
        {/* TODO: order actions */}
      </Grid>
      <div className={classes.space} />
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <OrderCard
            data={data}
            onUpdate={() => {
              setLastUpdatedAt(Date.now());
              if (onUpdate) {
                onUpdate();
              }
            }}
          />
          <div className={classes.space} />
          <ClientCard data={client} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {/* Map, Events */}
          <OrderDetails
            order={data}
            deliveryStaffOrder={deliveryStaffOrder}
          />
          <div className={classes.space} />
          {data.deliveryStaffOrderId && (
            <React.Fragment>
              <DeliveryStaffOrderCard data={deliveryStaffOrder} />
              <div className={classes.space} />
            </React.Fragment>
          )}
          <OrderEventCard events={orderEvents} />
          <div className={classes.space} />
          <DeliveryStaffOrdersCard data={deliveryStaffOrders} />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ElderCard data={data.elder} />
          <div className={classes.space} />
          <RestaurantCard data={data.restaurant} />
          {data.deliveryStaffId &&
            <React.Fragment>
              <div className={classes.space} />
              <DeliveryStaffCard data={deliveryStaff} />
            </React.Fragment>}
        </Grid>
      </Grid>
      {uberOrders.length > 0 &&
      <Grid container>
        <Grid item xs={12}>
          <UberOrderTable data={uberOrders}/>
        </Grid>
      </Grid>}
      {pandagoOrders.length > 0 &&
      <Grid container>
        <Grid item xs={12}>
          <PandagoOrderTable data={pandagoOrders}/>
        </Grid>
      </Grid>}

    </Container>
  );
}

Order.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  onUpdate: PropTypes.func,
};
