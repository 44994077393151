import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import {
  formatEmergencyContact,
  formatAddress,
  formatGender,
  formatBankAccount,
} from 'utilities/format';
import InfoCard from './InfoCard';
import { request } from 'utilities/graph';
import { getDeliveryStaff } from 'graphql/queries';
import DeliverStaffEditButton from 'forms/DeliverStaffForm/DeliverStaffEditButton';
import UserAvatar from 'components/UserAvatar';
import { useCache } from 'CacheProvider';

export default function DeliveryStaffCard({ id, title = i18n.t('送餐大使'), data: inData, showMap, ...props }) {
  const [data, setData] = useState({});
  const { appGroup } = useCache();
  const notAdminMode = appGroup !== 'Admins';

  const mappings = [
    { key: 'name', label: '姓名' },
    { key: 'phoneNumber', label: '電話' },
    { key: 'email', label: 'Email' },
    { key: 'address', label: '地址', format: formatAddress, showMap },
    { key: 'tier', label: '接單層級', hide: notAdminMode },
    { key: 'emergencyContact', label: '緊急聯絡人', format: formatEmergencyContact },

    { key: 'male', label: '性別', format: formatGender, expand: true },
    { key: 'jobOccupation', label: '職稱', expand: true },
    { key: 'identificationCardId', label: '身分證字號', expand: true },
    { key: 'bankAccount', label: '銀行帳戶', format: formatBankAccount, expand: true, hide: notAdminMode },
    { key: 'whyDoYouWantToJoin', label: '為何想加入銀色大門', expand: true, hide: notAdminMode },
    { key: 'howDoYouKnowUs', label: '如何得知銀色大門', expand: true, hide: notAdminMode },
    { key: 'experience', label: '經驗', expand: true },
    { key: 'selfInfroduction', label: '自介', expand: true },
  ];

  useEffect(() => {
    (async () => {
      if (inData) {
        setData(inData);
      } else
      if (id) {
        const { data: { getDeliveryStaff: data } } = await request(getDeliveryStaff, { id });
        setData(data);
      }
    })();
  }, [id, inData]);

  if (!data) return null;

  return (
    <InfoCard
      title={title}
      mappings={mappings}
      data={data}
      link={data.id ? `/deliveryStaff/${data.id}` : null}
      editButton={notAdminMode ? undefined : DeliverStaffEditButton}
      onUpdate={setData}
      {...props}>
      <div style={{ position: 'absolute', right: 16, top: 64 }}>
        <UserAvatar username={data.username} />
      </div>
    </InfoCard>
  );
}

DeliveryStaffCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showMap: PropTypes.bool,
  data: PropTypes.object,
};
