import { Route } from 'react-router-dom';

// Icon Search
// https://v4.mui.com/components/material-icons/#material-icons
import LockIcon from '@material-ui/icons/Lock';

import ProtectedRoute from 'components/ProtectedRoute';

import Tests from 'views/Admin/Tests/Tests';
import AppUsers from 'views/Admin/AppUsers/AppUsers';

import adminRoutes from 'views/Admin/routes';
import orgAdminRoutes from 'views/OrgAdmin/routes';
import facilityAdminRoutes from 'views/FacilityAdmin/routes';
import clientRoutes from 'views/Client/routes';
import publicRoutes from 'views/Public/routes';

export const general = publicRoutes.map((item) => {
  item.route = item.route || Route;
  item.pathPrefix = null;
  return item;
});

export const client = clientRoutes.map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['Clients'];
  item.pathPrefix = 'client';
  return item;
});

export const deliveryStaff = [].map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['DeliveryStaffs'];
  item.pathPrefix = 'delivery';
  return item;
});

export const facilityAdmin = facilityAdminRoutes.map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['FacilityAdmins'];
  item.pathPrefix = 'facility';
  return item;
});

export const orgAdmin = orgAdminRoutes.map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['OrgAdmins'];
  item.pathPrefix = 'organization';
  return item;
});

export const admin = adminRoutes.map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['Admins'];
  item.pathPrefix = 'admin';
  return item;
});

const localTest = [
  {
    title: '測試用頁面',
    icon: LockIcon,
    paths: [
      { path: '/tests', exact: true },
    ],
    component: Tests,
  },
  {
    title: '軟體用戶',
    icon: LockIcon,
    paths: [{ path: '/appUsers', exact: true }],
    component: AppUsers,
  },
].filter(() => process.env.NODE_ENV === 'development').map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['Admins'];
  item.pathPrefix = 'local';
  return item;
});

export const appRoutes = [
  ...general, ...admin, ...orgAdmin, ...facilityAdmin, ...deliveryStaff, ...client, ...localTest,
].reduce((all, item) => {
  item.paths.forEach(({ path, link, exact = true }) => {
    all.push(Object.assign({
      path: item.pathPrefix ? `/${item.pathPrefix}${path}` : path,
      link,
      exact,
    }, item));
  });
  return all;
}, []);
