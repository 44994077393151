import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import VerticalTabs from 'components/Tab/VerticalTabs';
import FacilityClientTable from 'components/FacilityClientTable';
// import OrderTable from 'components/OrderTable';
import OrganizationCard from 'components/Card/OrganizationCard';
// import DeliveryDiary from 'views/Admin/DeliveryDiary/DeliveryDiary';
// import DeliveryStaffTable from 'views/OrgAdmin/components/DeliveryStaffTable';
import UserTable from 'components/UserTable';
// import RestaurantTable from 'components/RestaurantTable';
import Dashboard from 'views/OrgAdmin/Dashboard/Dashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  space: {
    height: theme.spacing(2),
  },
  // centerContainer: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export default function OrganizationDetails({ id: inId, clientIds, computedMatch, isNested }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    if (!id) return;

    const tabs = [
      {
        label: t('總覽'),
        component: <Dashboard organizationId={id} clientIds={clientIds} />,
      },
      {
        label: t('機構'),
        component: <FacilityClientTable title="機構" organizationId={id} />,
      },
      // {
      //   label: t('客戶'),
      //   component: <ClientTable title="客戶" nested={true} organizationId={id} padding={0} type="individual" />,
      // },
      // {
      //   label: t('送餐對象'),
      //   component: <ElderTable nested={true} organizationId={id} padding={0} />,
      // },
      // {
      //   label: '訂單',
      //   component: <OrderTable nested={true} clientId={id} padding={0} />,
      // },
      // {
      //   label: t('送餐關懷'),
      //   component: <DeliveryDiary organizationId={id} />,
      // },
      // {
      //   label: t('送餐大使'),
      //   component: <DeliveryStaffTable nested={true} organizationId={id} padding={0} />,
      // },
      // {
      //   label: t('餐廳'),
      //   component: <RestaurantTable nested={true} clientId={id} padding={0} />,
      // },
      {
        label: '使用者',
        component: <UserTable nested={true} organizationId={id} padding={0} />,
      },
    ];
    setTabs(tabs);
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={2} lg={2} xl={3}>
          <OrganizationCard title="基本資料" id={id} hideExpand={true} showMap={true} />
        </Grid>
        <Grid item xs={12} md={10} lg={10} xl={9}>
          <VerticalTabs
            tabs={tabs}
            padding={0}
            isNested={isNested}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

OrganizationDetails.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  isNested: PropTypes.bool,
  clientIds: PropTypes.array,
};
