import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  getOrdersByClientByDate, getOrdersByIndividualByDate,
} from './queries';
import OrderCard from './OrderCard';
import { asyncListAll } from 'utilities/graph';
import cache from 'utilities/cache';
import DateRangeSelector from 'components/DateRangeSelector';
import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { sortBy } from 'utilities/sorting';

export default function MyOrders() {
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    const lastWeek = moment().tz(TIME_ZONE).add(-7, 'days').format('YYYY-MM-DD');
    const nextWeek = moment().tz(TIME_ZONE).add(7, 'days').format('YYYY-MM-DD');
    setStartDate(lastWeek);
    setEndDate(nextWeek);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      (async () => {
        const clientId = cache.get('app:clientId');
        let orders = await asyncListAll(getOrdersByIndividualByDate, {
          individualId: clientId,
          date: {
            between: [startDate, endDate],
          },
        });

        const oldOrders = await asyncListAll(getOrdersByClientByDate, {
          clientId,
          date: {
            between: [startDate, endDate],
          },
        });

        orders = orders.concat(oldOrders).sort(sortBy('deliveryBy'));

        global.logger.debug(orders);
        setOrders(orders.reverse());
      })();
    }
  }, [startDate, endDate]);

  const setCustomPeriod = ({ startDate, endDate }) => {
    setStartDate(moment(startDate).tz(TIME_ZONE).format('YYYY-MM-DD'));
    setEndDate(moment(endDate).tz(TIME_ZONE).format('YYYY-MM-DD'));
  };

  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" style={{ flex: 1, paddingLeft: 8 }}>
            <DateRangeSelector
              startDate={startDate}
              endDate={endDate}
              onUpdate={setCustomPeriod}
            />
          </Typography>
        </Grid>
        {orders.map((order, index)=>(
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <OrderCard order={order} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

MyOrders.propTypes = {};
