import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '1px solid rgba(255,255,255,0.3)',
  },
}));

export default function ImagePreviewAvatar({ imageS3Keys = [], alt = '無' }) {
  const classes = useStyles();

  const [uri, setUri] = useState('');
  const [s3Key, setS3Key] = useState();

  useEffect(() => {
    (async () => {
      setUri(await Storage.get(s3Key));
    })();
  }, [s3Key]);

  useEffect(() => {
    global.logger.debug('imageS3Keys', imageS3Keys);
    if (Array.isArray(imageS3Keys) && imageS3Keys.length > 0) {
      setS3Key(imageS3Keys[0]);
    }
  }, [imageS3Keys]);

  return (
    <Avatar
      alt={alt}
      src={uri}
      variant="square"
      className={classes.avatar}
    />
  );
}

ImagePreviewAvatar.propTypes = {
  imageS3Keys: PropTypes.array,
  alt: PropTypes.string,
};
