import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { makeStyles } from '@material-ui/core/styles';
import TagChip from 'components/TagChip';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import AutoSizeDialog from 'components/AutoSizeDialog';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}));

export default function MealItemDialog({ restaurant, meal, onClose, quantity: inQuantity = 1, open }) {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(inQuantity);

  const {
    name,
    imageUrl,
    price,
    tags,
    description,
  } = meal;
  const restaurantName = restaurant.name;

  const handleAddMealItem = async () => {
    global.logger.debug('handleAddMealItem', meal);

    onClose({
      meal,
      quantity,
    });
  };

  return (
    <AutoSizeDialog
      open={open}
      title={'餐點資訊'}
      onClose={() => onClose()}
    >
      <DialogContent className={classes.content} dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="center">
            <img
              src={imageUrl || '/images/meal.webp'}
              width="auto"
              height="200px"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/images/meal.webp';
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='space-between'>
              <Typography gutterBottom variant="h5">
                {name}
              </Typography>
              <Typography gutterBottom variant="h5">
                ${price}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: 10 }}>
            <Typography gutterBottom variant="body1">
              {restaurantName}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }}>
            {tags.items.map(({ tag }, index)=>(
              <TagChip
                key={index}
                tag={tag}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ backgroundColor: '#F1F5EC', borderRadius: '10px', padding: 10 }}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  餐點簡介
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="body1">
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={3}>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    setQuantity(quantity - 1 >= 0 ? quantity - 1 : 0);
                  }}
                >
                  <RemoveCircleIcon style={{ fontSize: 40 }}/>
                </IconButton>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <FormControl fullWidth>
                  <InputLabel id="quantity-label">
                    數量
                  </InputLabel>
                  <Select
                    fullWidth
                    value={quantity}
                    onChange={(e) => {
                      const { value } = e.target;
                      setQuantity(value);
                    }}
                  >
                    {Array.from(Array(16).keys()).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    setQuantity(quantity + 1 <= 15 ? quantity + 1 : 15);
                  }}
                >
                  <AddCircleIcon style={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddMealItem}
              size="large"
            >
              {quantity === 0 ? '移出購物車' : `加入購物車`}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </AutoSizeDialog>
  );
}

MealItemDialog.propTypes = {
  restaurant: PropTypes.object.isRequired,
  meal: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  open: PropTypes.bool,
};
