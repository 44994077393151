import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';

const useStyles = makeStyles({
  card: {
    height: '100%',
    paddingBottom: 10,
  },
  mediaContainer: {
    position: 'relative',
    marginLeft: 10,
  },
  media: {
    height: 150,
    opacity: 0.1,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

export default function OrderDetail({ order }) {
  const [imgSrc, setImgSrc] = useState();
  const classes = useStyles();
  const { dateTime: { date = '', time = '' } = {}, mealItems, quotation } = order || {};

  useEffect(() => {
    const meal = mealItems && mealItems[0];
    if (meal?.imageS3Keys[0]) {
      (async () => {
        const newImgSrc = await Storage.get(meal.imageS3Keys[0]);
        setImgSrc(newImgSrc);
      })();
    } else {
      setImgSrc('/images/meal.webp');
    }
  }, [mealItems]);

  const meal = mealItems && mealItems[0]; // TODO: 處理多餐點
  const { name, price, restaurant } = meal || {};

  return (
    <Card className={classes.card}>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6" color="textPrimary" style={{ marginLeft: 10 }}>
            {restaurant.name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.mediaContainer} >
            <CardMedia
              className={classes.media}
              image={imgSrc}
              title="照片"
              component="img"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/images/meal.webp';
              }}
            />
            <div className={classes.imageContainer}>
              <img className={classes.img}
                src={imgSrc}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/meal.webp';
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 10 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={8}>
              <Typography gutterBottom variant="body1" color="textPrimary">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="body1" color="textPrimary" >
                ${price}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={8}>
              <Typography gutterBottom variant="body1" color="textPrimary">
                運費
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="body1" color="textPrimary" >
                ${quotation?.deliveryStaffFee === undefined ? '計算中' : quotation?.deliveryStaffFee }
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Typography gutterBottom variant="body2" color="textPrimary">
              {`外送日期：${date}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="body2" color="textPrimary" >
              {`外送時間：${time}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="body2" color="textPrimary" >
              {`餐點備註：${order.noteForMeal || '無'}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

OrderDetail.propTypes = {
  order: PropTypes.object,
};
