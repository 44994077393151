import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  counties, countiesDistricts,
} from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { getDistrictsInCounty, getZipCode } from 'utilities/address';
import { formatAddress } from 'utilities/format';
import { request } from 'utilities/graph';
import { queryAddress } from 'graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const Location = ({ onGoPage }) => {
  const [county, setCounty] = useState(counties[0]);
  const [district, setDistrict] = useState(countiesDistricts[0].districts[0]);
  const [districts, setDistricts] = useState(getDistrictsInCounty(counties[0]));
  const [street, setStreet] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const params = {
      input: {
        address: formatAddress({ county, district, street }),
      },
    };
    let lat;
    let lng;
    try {
      const {
        data: {
          queryAddress: {
            success, data,
          },
        },
      } = await request(queryAddress, params);
      if (!success) {
        setErrorMsg('無法定位地址，請重新確認輸入的地址');
        setIsLoading(false);
        return;
      } else {
        lat = data.lat;
        lng = data.lng;
        setErrorMsg();
      }
    } catch (e) {
      console.log(e);
      setErrorMsg('無法定位地址，請重新確認輸入的地址');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    const zipCode = getZipCode(county, district);
    cache.set('public:menu2-address', { county, district, street, zipCode, lat, lng });
    onGoPage && onGoPage('meals');
  };

  const onCountyChange = (newCounty) => {
    if (newCounty !== county) {
      setCounty(newCounty);
      const newDistricts = getDistrictsInCounty(newCounty);
      setDistricts(newDistricts);
      setDistrict(newDistricts[0]);
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid item xs={12}>
        <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
          營養豐盛的餐點就在大門口
        </Typography>
        <Typography variant='subtitle1' color='textPrimary' align='center'>
          無論日子，不分城鄉
        </Typography>
        <Typography variant='subtitle1' color='textPrimary' align='center'>
          長者在哪裡，餐點就送到哪裡
        </Typography>
        <Typography variant='subtitle1' color='textPrimary' align='center'>
          快來輸入地址訂餐吧！
        </Typography>
      </Grid>
      <Grid item container xs={12} justifyContent='center'>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ padding: 16, border: '2px solid #00913A', borderRadius: '15px' }}>
            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="select-county-label">縣市</InputLabel>
                <Select
                  id="select-county"
                  value={county}
                  onChange={(e) => onCountyChange(e.target.value)}
                  label="縣市"
                >
                  {counties.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="select-district-label">鄉鎮市區</InputLabel>
                <Select
                  id="select-district"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  label="鄉鎮市區"
                >
                  {districts.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                placeholder='道路或街名或村里名稱'
                value={street}
                style={{ width: '100%' }}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} container justifyContent='center' alignItems='center'>
              <Button variant='contained' type='submit'
                disabled={isLoading}
                style={{
                  width: '80%',
                  borderRadius: '20px',
                  backgroundColor: isLoading ? '#cecece' : '#00913A',
                  color: 'white',
                }}>
                立即前往訂餐
                {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100, borderRadius: '20px' }}>
                  <CircularProgress color="primary" size="1.5rem" />
                </Backdrop>}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {errorMsg && <Grid item xs={12}>
        <Typography variant='body1' color='error' align='center' style={{ fontWeight: 'bold' }} >
          {errorMsg}
        </Typography>
      </Grid>}
    </Grid>
  );
};

export default Location;

Location.propTypes = {
  onGoPage: PropTypes.func,
};
