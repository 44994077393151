export const getOrdersByElderByCreatedAt = /* GraphQL */ `
  query GetOrdersByElderByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByElderByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        orderGroupId
      }
      nextToken
    }
  }
`;
export const getElderDeliveryGroupByElder = /* GraphQL */ `
  query GetElderDeliveryGroupByElder(
    $elderId: ID
    $deliveryGroupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDeliveryGroupByElder(
      elderId: $elderId
      deliveryGroupId: $deliveryGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetILinkOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByElderByCreatedAt = /* GraphQL */ `
  query GetIssuesByElderByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByElderByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetUberOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetLalamoveOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByElderByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByElderByDate(
    $elderId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByElderByDate(
      elderId: $elderId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        isPublished
        publishedUrl
        blogId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
