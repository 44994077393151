import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import {
  listMemberships,
  getMembershipByStatusByExpiredAt,
  getMembershipByClientIdByStatus,
} from 'graphql/queries';
import EditButton from 'forms/MembershipForm/MembershipFormEditButton';
import { sortBy } from 'utilities/sorting';
import LinkButton from 'components/Table/LinkButton';
import CustomStatusChip from 'components/CustomStatusChip';
import {
  membershipStatus,
} from '@silvergatedelivery/constants';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'status',
    label: '狀態',
    options: {
      filter: true,
      sort: true,
      customBodyRender(value) {
        return (
          <CustomStatusChip value={value} mappings={membershipStatus} />
        );
      },
    },
  },
  {
    name: 'client',
    label: '客戶',
    options: {
      filter: false,
      sort: true,
      customBodyRender(client = {}) {
        return (
          <LinkButton
            path={`/client/${client.id}`}
            text={client.name}
            label="前往客戶頁面"
            newTab={true}
          />
        );
      },
    },
  },
  {
    name: 'createdAt',
    label: '開始於',
    type: 'datetime',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'expiredAt',
    label: '截止於',
    type: 'datetime',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'discountRule',
    label: '折扣描述',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'discountPercentage',
    label: '折扣',
    type: 'number',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function MembershipTable({
  title = '付費會員',
  description,
  clientId,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  useEffect(() => {
    if (clientId) {
      setIndexes([
        {
          name: 'getMembershipByClientIdByStatus',
          label: '按客戶查詢',
          partitionKey: 'clientId',
          sortKey: 'tatus',
          fields: [{
            label: '客戶ID',
            key: 'clientId',
          }],
          operation: getMembershipByClientIdByStatus,
        },
      ]);
      setQueryParams({
        clientId,
      });
      return;
    }
    setIndexes([
      {
        name: 'getMembershipByStatusByExpiredAt',
        label: '按狀態查詢',
        partitionKey: 'status',
        sortKey: 'expiredAt',
        fields: [{
          label: '狀態',
          key: 'status',
          type: 'select',
          options: [
            { value: 'active', label: '使用中' },
            { value: 'expired', label: '已過期' },
          ],
        }],
        operation: getMembershipByStatusByExpiredAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listMemberships,
      },
    ]);
    setQueryParams({
      status: 'active',
    });
  }, [clientId]);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={EditButton}
      dataSortFunc={sortBy('expiredAt', true)}
    />
  );
}

MembershipTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
};
