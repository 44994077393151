export default [
  {
    label: '未處理',
    value: 'pending',
    color: '#850087',
    step: 0,
  },
  {
    label: '處理中',
    value: 'processing',
    color: '#2189DC',
    step: 1,
  },
  {
    label: '已解決',
    value: 'resolved',
    color: '#cecece',
    step: 2,
  },
];
