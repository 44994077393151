export default [
  {
    label: '長輩生病',
    value: 'elderSick',
  },
  {
    label: '長輩外出',
    value: 'elderGoOut',
  },
  {
    label: '長輩請假',
    value: 'elderLeave',
  },
  {
    label: '長輩過世',
    value: 'elderPassedAway',
  },
  {
    label: '停用服務',
    value: 'serviceStop',
  },
  {
    label: '設定錯誤',
    value: 'settingError',
  },
  {
    label: '社區聚餐',
    value: 'communityMeal',
  },
  {
    label: '其他',
    value: 'others',
  },
];
