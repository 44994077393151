import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import VerticalTabs from 'components/Tab/VerticalTabs';
import ElderTable from 'components/ElderTable';
import OrderTable from 'components/OrderTable';
import RecurringOrderTable from 'components/RecurringOrderTable';
import ClientCard from 'components/Card/ClientCard';
import DeliveryDiary from 'views/Admin/DeliveryDiary/DeliveryDiary';
import DeliveryStaffTable from 'views/FacilityAdmin/components/DeliveryStaffTable';
import UserTable from 'components/UserTable';
import ElderDeliveryGroupBoard from 'components/DeliveryGroup/ElderDeliveryGroupBoard';
import SponsorshipTable from 'components/SponsorshipTable';
import NotificationTable from 'components/NotificationTable';
import RestaurantTable from 'components/RestaurantTable';
import MembershipTable from 'components/MembershipTable';
import Dashboard from 'views/FacilityAdmin/Dashboard/Dashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  space: {
    height: theme.spacing(2),
  },
  // centerContainer: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export default function ClientDetails({ id: inId, computedMatch, isNested }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    if (!id) return;

    const tabs = [
      // {
      //   label: '基本資料',
      //   component: <ClientCard title="基本資料" id={id} hideExpand={true} showMap={true} />,
      // },
      {
        label: t('總覽'),
        component: <Dashboard clientId={id} />,
      },
      {
        label: t('送餐對象'),
        component: <ElderTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: t('送餐群組'),
        component: <ElderDeliveryGroupBoard clientId={id} padding={0} />,
      },
      {
        label: '訂單',
        component: <OrderTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: '週期性排單',
        component: <RecurringOrderTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: t('送餐關懷'),
        component: <DeliveryDiary clientId={id} />,
      },
      {
        label: t('送餐大使'),
        component: <DeliveryStaffTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: t('餐廳'),
        component: <RestaurantTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: '使用者',
        component: <UserTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: '捐贈紀錄',
        component: <SponsorshipTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: '通知紀錄',
        component: <NotificationTable nested={true} clientId={id} padding={0} />,
      },
      {
        label: '會員紀錄',
        component: <MembershipTable nested={true} clientId={id} padding={0} />,
      },
    ];
    setTabs(tabs);
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={2} lg={2} xl={3}>
          <ClientCard title="基本資料" id={id} hideExpand={true} showMap={true} />
        </Grid>
        <Grid item xs={12} md={10} lg={10} xl={9}>
          <VerticalTabs
            tabs={tabs}
            padding={0}
            isNested={isNested}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

ClientDetails.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  isNested: PropTypes.bool,
};
