import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';

import { formatDatetime } from 'utilities/format';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  itemContainer: {
    minHeight: 50,
    padding: 0,
  },
  listSubHeader: {
    fontWeight: 700,
    borderBottom: '1px solid #E5E5E5',
  },
  datetimeContainer: {
    maxWidth: 120,
  },
}));

export default function CustomTimeline({
  data = [],
  sortKey = 'createdAt',
  render,
}) {
  const classes = useStyles();

  let currentDate;
  const updatedData = data.map((x) => {
    const format = formatDatetime(x[sortKey], { year: false, weekday: false });
    const [date, time] = format.split(' ');

    if (date === currentDate) {
      x.displayDatetime = time;
    } else {
      currentDate = date;
      x.displayDatetime = format;
    }

    return x;
  });

  return (
    <Timeline className={classes.container}>
      {updatedData.sort((a, b) => a[sortKey] > b[sortKey] ? 1 : -1).map((item, index)=>(
        <TimelineItem key={index} className={classes.itemContainer}>
          <TimelineOppositeContent className={classes.datetimeContainer}>
            <Typography color="textSecondary" variant="body2">
              {item.displayDatetime}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={index === data.length -1 ? 'primary' : undefined} />
            {(index+1 < data.length) && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            {render(item, index)}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

CustomTimeline.propTypes = {
  data: PropTypes.array,
  sortKey: PropTypes.string,
  render: PropTypes.func,
};
