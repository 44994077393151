import React from 'react';
import i18n from 'i18next';

// Icon Search
// https://v4.mui.com/components/material-icons/#material-icons
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DashboardIcon from '@material-ui/icons/Dashboard';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ErrorIcon from '@material-ui/icons/Error';
import TodayIcon from '@material-ui/icons/Today';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StarsIcon from '@material-ui/icons/Stars';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import WorkIcon from '@material-ui/icons/Work';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import OrganizationTable from 'components/OrganizationTable';
import OrganizationDetails from 'components/OrganizationDetails';
import IndividualClientTable from 'components/IndividualClientTable';
import FacilityClientTable from 'components/FacilityClientTable';
import ElderTable from 'components/ElderTable';
import DeliveryStaffTable from 'components/DeliveryStaffTable';
import DeliveryStaffOrderTable from 'components/DeliveryStaffOrderTable';
import Schedule from 'views/Admin/Schedule/Schedule';
import Orders from 'views/Admin/Orders/Orders';
import UberOrders from 'views/Admin/UberOrders/UberOrders';
import PandagoOrders from 'views/Admin/PandagoOrders/PandagoOrders';
import ILinkOrders from 'views/Admin/ILinkOrders/ILinkOrders';
import LalamoveOrders from 'views/Admin/LalamoveOrders/LalamoveOrders';
import OrderTable from 'components/OrderTable';
import Order from 'views/Admin/Order/Order';
import RestaurantTable from 'components/RestaurantTable';
import ClientDetails from 'components/ClientDetails';
import ElderDetails from 'components/ElderDetails';
import RestaurantDetails from 'components/RestaurantDetails';
import RestaurantMeals from 'views/Admin/RestaurantMeals/RestaurantMeals';
import DeliveryStaffDetails from 'components/DeliveryStaffDetails';
import Dashboard from 'views/Admin/Dashboard/Dashboard';
import IssueCenter from 'views/Admin/IssueCenter/IssueCenter';
import Users from 'views/Admin/Users/Users';
import Reports from 'views/Admin/Reports/Reports';
import System from 'views/Admin/System/System';
import TodayOrders from 'views/Admin/TodayOrders/TodayOrders';
import DeliveryDiary from 'views/Admin/DeliveryDiary/DeliveryDiary';
import LandingPage from 'views/Public/LandingPage';
import IssueCenterBadge from 'components/IssueCenterBadge';
import DeliveryStaffBadge from 'components/DeliveryStaffBadge';
import SToken from 'views/Admin/SToken/SToken';
import STokenBadge from 'components/STokenBadge';
import OrderBadge from 'components/OrderBadge';
import AbandonedOrderBadge from 'components/AbandonedOrderBadge';
import TagTable from 'components/TagTable';
import RestaurantOwnerTable from 'components/RestaurantOwnerTable';
import RestaurantOwnerDetails from 'components/RestaurantOwnerDetails';
import Transactions from 'views/Admin/Transactions/Transactions';
import Invoices from 'views/Admin/Invoices/Invoices';
import SponsorshipPrograms from 'views/Admin/Sponsorship/SponsorshipPrograms';
import Sponsorships from 'views/Admin/Sponsorship/Sponsorships';
import MembershipPrograms from 'views/Admin/Membership/MembershipPrograms';
import Memberships from 'views/Admin/Membership/Memberships';
import ClientAppBannerTable from 'components/ClientAppBannerTable';
import OrderQuoteTable from 'components/OrderQuoteTable';
import ApiDocs from 'views/Admin/ApiDocs/ApiDocs';
import WebhookEventTable from 'components/WebhookEventTable';
import GetOrderQuote from 'components/GetOrderQuote';
import QueryAddress from 'components/QueryAddress';
import EventTable from 'components/EventTable';
import DementiaQuestionnaireTable from 'components/Project/Dementia/DementiaQuestionnaireTable';
import DementiaPreventionTable from 'components/Project/Dementia/DementiaPreventionTable';
import AuditTrailTable from 'components/AuditTrailTable';

export default [
  {
    title: i18n.t('總覽'),
    icon: DashboardIcon,
    paths: [{ path: '/dashboard', exact: true }],
    component: Dashboard,
  },
  {
    title: i18n.t('今日訂單'),
    icon: TodayIcon,
    paths: [{ path: '/todayOrders', exact: true }],
    component: TodayOrders,
  },
  {
    title: '訂單',
    icon: ListAltIcon,
    paths: [{ path: '/orders', exact: true }],
    component: Orders,
  },
  {
    title: '訂單頁面',
    icon: ListAltIcon,
    paths: [{ path: '/order/:id', exact: true }],
    component: Order,
    hideFromMenu: true,
  },
  {
    title: i18n.t('行程表'),
    icon: EventNoteIcon,
    paths: [{ path: '/schedule', exact: true }],
    component: Schedule,
  },
  {
    title: i18n.t('送餐關懷'),
    icon: FavoriteBorderIcon,
    paths: [{ path: '/deliveryDiary', exact: true }],
    component: DeliveryDiary,
    divider: true,
  },
  {
    title: 'Uber',
    icon: SportsMotorsportsIcon,
    paths: [{ path: '/uberOrders', exact: true }],
    component: UberOrders,
  },
  {
    title: 'Pandago',
    icon: SportsMotorsportsIcon,
    paths: [{ path: '/pandagoOrders', exact: true }],
    component: PandagoOrders,
  },
  {
    title: 'I-Link',
    icon: SportsMotorsportsIcon,
    paths: [{ path: '/iLinkOrders', exact: true }],
    component: ILinkOrders,
  },
  {
    title: 'Lalamove',
    icon: SportsMotorsportsIcon,
    paths: [{ path: '/LalamoveOrders', exact: true }],
    component: LalamoveOrders,
    divider: true,
  },
  {
    title: i18n.t('送餐大使'),
    icon: MotorcycleIcon,
    paths: [{ path: '/deliveryStaffs', exact: true }],
    component: DeliveryStaffTable,
  },
  {
    title: `${i18n.t('送餐大使')}頁面`,
    paths: [{ path: '/deliveryStaff/:id', exact: true }],
    component: DeliveryStaffDetails,
    hideFromMenu: true,
  },
  {
    title: i18n.t('送餐對象'),
    icon: PersonPinIcon,
    paths: [{ path: '/elders', exact: true }],
    component: ElderTable,
  },
  {
    title: i18n.t('送餐紀錄'),
    icon: ArtTrackIcon,
    paths: [{ path: '/deliveryStaffOrders', exact: true }],
    component: DeliveryStaffOrderTable,
    divider: true,
  },
  {
    title: '客戶 - 組織',
    icon: EventSeatIcon,
    paths: [{ path: '/organizations', exact: true }],
    component: () => <OrganizationTable />,
  },
  {
    title: '組織頁面',
    paths: [{ path: '/organization/:id', exact: true }],
    component: OrganizationDetails,
    hideFromMenu: true,
  },
  {
    title: '客戶 - 機構',
    icon: GroupIcon,
    paths: [{ path: '/facilities', exact: true }],
    component: () => <FacilityClientTable title="客戶 - 機構" />,
  },
  {
    title: '客戶 - 個人',
    icon: PersonIcon,
    paths: [{ path: '/clients', exact: true }],
    component: () => <IndividualClientTable title="客戶 - 個人" type="individual" />,
    divider: true,
  },
  {
    title: '客戶頁面',
    paths: [{ path: '/client/:id', exact: true }],
    component: ClientDetails,
    hideFromMenu: true,
  },
  {
    title: `${i18n.t('送餐對象')}頁面`,
    paths: [{ path: '/elder/:id', exact: true }],
    component: ElderDetails,
    hideFromMenu: true,
  },
  {
    title: i18n.t('餐廳'),
    icon: RestaurantIcon,
    paths: [{ path: '/restaurants', exact: true }],
    component: RestaurantTable,
  },
  {
    title: `${i18n.t('餐廳')}負責人`,
    icon: AssignmentIndIcon,
    paths: [{ path: '/restaurantOwners', exact: true }],
    component: RestaurantOwnerTable,
  },
  {
    title: `${i18n.t('餐廳')}負責人頁面`,
    paths: [{ path: '/restaurantOwner/:id', exact: true }],
    component: RestaurantOwnerDetails,
    hideFromMenu: true,
  },
  {
    title: i18n.t('餐點'),
    icon: EmojiFoodBeverageIcon,
    paths: [{ path: '/restaurantMeals', exact: true }],
    component: RestaurantMeals,
    divider: true,
  },
  {
    title: `${i18n.t('餐廳')}頁面`,
    paths: [{ path: '/restaurant/:id', exact: true }],
    component: RestaurantDetails,
    hideFromMenu: true,
  },
  {
    title: '應變中心',
    icon: ErrorIcon,
    paths: [{ path: '/issueCenter', exact: true }],
    component: IssueCenter,
    badge: IssueCenterBadge,
  },
  {
    title: '棄單',
    icon: SmsFailedIcon,
    paths: [{ path: '/abandonedOrders', exact: true }],
    component: () => <OrderTable queryStatus={'reMatchingDeliveryStaff'} defaultIndex={2} />,
    badge: AbandonedOrderBadge,
  },
  {
    title: 'S Token',
    icon: ListAltIcon,
    paths: [{ path: '/sToken', exact: true }],
    component: SToken,
    badge: STokenBadge,
  },
  {
    title: '交易紀錄',
    icon: AccountBalanceIcon,
    paths: [{ path: '/transactions', exact: true }],
    component: Transactions,
  },
  {
    title: '電子發票',
    icon: ReceiptIcon,
    paths: [{ path: '/invoices', exact: true }],
    component: Invoices,
    divider: true,
  },
  {
    title: '捐贈項目',
    icon: StarsIcon,
    paths: [{ path: '/sponsorshipPrograms', exact: true }],
    component: SponsorshipPrograms,
  },
  {
    title: '捐贈紀錄',
    icon: FeaturedPlayListIcon,
    paths: [{ path: '/sponsorships', exact: true }],
    component: Sponsorships,
  },
  {
    title: '失智防治專案',
    icon: WorkIcon,
    paths: [{ path: '/', exact: true }],
    component: LandingPage,
    divider: true,
    folder: true,
  },
  {
    title: '失智防治成效',
    icon: AssignmentTurnedInIcon,
    paths: [{ path: '/project/dementiaPrevention', exact: true }],
    component: DementiaPreventionTable,
    nested: true,
  },
  {
    title: '失智友善問卷',
    icon: QuestionAnswerIcon,
    paths: [{ path: '/project/dementiaQuestionnaire', exact: true }],
    component: DementiaQuestionnaireTable,
    nested: true,
    divider: true,
  },
  {
    title: '會員項目',
    icon: StarsIcon,
    paths: [{ path: '/membershipPrograms', exact: true }],
    component: MembershipPrograms,
  },
  {
    title: '付費會員',
    icon: FeaturedPlayListIcon,
    paths: [{ path: '/memberships', exact: true }],
    component: Memberships,
    divider: true,
  },
  {
    title: '新申請大使',
    icon: CheckCircleOutlineIcon,
    paths: [{ path: '/waitingForApprovalDeliveryStaffs', exact: true }],
    component: () => <DeliveryStaffTable queryIsActive={0} />,
    badge: DeliveryStaffBadge,
  },
  {
    title: '新訂單',
    icon: ListAltIcon,
    paths: [{ path: '/newOrders', exact: true }],
    component: () => <OrderTable queryStatus={'waitingForDeliveryStaff'} defaultIndex={2} />,
    badge: OrderBadge,
    divider: true,
  },
  {
    title: '估價單',
    icon: PlaylistAddIcon,
    paths: [{ path: '/orderQuotes', exact: true }],
    component: OrderQuoteTable,
  },
  {
    title: '估價',
    icon: AddShoppingCartIcon,
    paths: [{ path: '/getOrderQuote', exact: true }],
    component: GetOrderQuote,
  },
  {
    title: '地址查詢',
    icon: NotListedLocationIcon,
    paths: [{ path: '/queryAddress', exact: true }],
    component: QueryAddress,
  },
  {
    title: '標籤管理',
    icon: LocalOfferIcon,
    paths: [{ path: '/tags', exact: true }],
    component: TagTable,
  },
  {
    title: 'Banner管理',
    icon: ViewCarouselIcon,
    paths: [{ path: '/clientAppBanners', exact: true }],
    component: ClientAppBannerTable,
  },
  {
    title: '用戶',
    icon: LockIcon,
    paths: [{ path: '/users', exact: true }],
    component: Users,
  },
  {
    title: '報表',
    icon: AssessmentIcon,
    paths: [{ path: '/reports', exact: true }],
    component: Reports,
  },
  {
    title: '系統記錄',
    icon: RemoveFromQueueIcon,
    paths: [{ path: '/events', exact: true }],
    component: EventTable,
  },
  {
    title: '使用紀錄',
    icon: RemoveFromQueueIcon,
    paths: [{ path: '/auditTrails', exact: true }],
    component: AuditTrailTable,
  },
  {
    title: '系統',
    icon: SettingsIcon,
    paths: [{ path: '/system', exact: true }],
    component: System,
  },
  {
    title: 'APIs',
    icon: SettingsIcon,
    paths: [{ path: '/api-docs', exact: true }],
    component: ApiDocs,
  },
  {
    title: 'Webhooks',
    icon: SettingsIcon,
    paths: [{ path: '/webhooks', exact: true }],
    component: WebhookEventTable,
  },

  // {
  //   title: '系統紀錄',
  //   icon: EventNote,
  //   paths: [
  //     { path: '/events', exact: true },
  //   ],
  //   component: Events,
  // },
];
