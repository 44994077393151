import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import { Colors, counties } from '@silvergatedelivery/constants';

import SummaryCard from './components/SummaryCard';
import PieCard from './components/PieCard';
import BarCard from './components/BarCard';
import PlotCard from './components/PlotCard';
import {
  getOrders,
  // getNewOrders,
  getIssues,
  getNewDeliveryStaffs,
  // getNewClients,
  getNewElders,
  getStartEndByPeriodName,
} from './helpers';
import { getChartDataMappings } from './charts';
import cache from 'utilities/cache';

const periodOptions = [{
  label: '本日',
  value: 'day',
}, {
  label: '本週',
  value: 'week',
}, {
  label: '本月',
  value: 'month',
}, {
  label: '今年',
  value: 'year',
}];

const periodOptions2 = [{
  label: '昨日',
  value: 'day/-1/-1',
}, {
  label: '上週',
  value: 'week/-1/-1',
}, {
  label: '前月',
  value: 'month/-1/-1',
}, {
  label: '去年',
  value: 'year/-1/-1',
}];

const monthOptions = Array(12).fill('').map((x, index) => {
  const offset = index + 1;
  return {
    label: moment().add(-offset, 'months').format('YYYY-MM'),
    value: `month/-${offset}/-${offset}`,
  };
});

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: Colors.background.light,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonGroup: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [chartData, setChartData] = useState();
  const [location, setLocation] = useState(cache.get('app:location') || '');
  const [monthSelected, setMonthSelected] = useState('');
  const [period, setPeriod] = useState(cache.get('app:admin:dashboard:period') || 'day'); // day, week, month, year
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [lastProgressUpdated, setLastProgressUpdated] = useState();
  const progressStatus = useRef({});

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      cache.set('app:admin:dashboard:period', period);

      const onUpdateLastItem = (item, nextToken) => {
        if (nextToken) {
          progressStatus.current[item.county] = item.date;
          setLastProgressUpdated(moment().toISOString());
        } else {
          if (item) {
            progressStatus.current[item.county] = '完成';
            setLastProgressUpdated(moment().toISOString());
          }
        }
      };

      progressStatus.current = {};

      const {
        start,
        end,
        prevStart,
        prevEnd,
        startFormat,
        endFormat,
        // prevStartFormat,
        // prevEndFormat,
      } = getStartEndByPeriodName(period);

      setTitle(`${startFormat} - ${endFormat}`);

      global.logger.debug({ location, period, start, end, prevStart, prevEnd });

      const [
        orders,
        // prevOrders,
        // newOrders, prevNewOrders,
        issues, prevIssues,
        newDeliveryStaffs, prevNewDeliveryStaffs,
        // newClients, prevNewClients,
        newElders, prevNewElders,
      ] = await Promise.all([
        getOrders(location, startFormat, endFormat, onUpdateLastItem),
        // getOrders(location, prevStartFormat, prevEndFormat),
        // getNewOrders(location, start, end),
        // getNewOrders(location, prevStart, prevEnd),
        getIssues(location, start, end),
        getIssues(location, prevStart, prevEnd),
        getNewDeliveryStaffs(location, start, end),
        getNewDeliveryStaffs(location, prevStart, prevEnd),
        // getNewClients(location, start, end),
        // getNewClients(location, prevStart, prevEnd),
        getNewElders(location, start, end),
        getNewElders(location, prevStart, prevEnd),
      ]);

      setData({
        orders,
        // prevOrders,
        // newOrders,
        // prevNewOrders,
        issues,
        prevIssues,
        newDeliveryStaffs,
        prevNewDeliveryStaffs,
        // newClients,
        // prevNewClients,
        newElders,
        prevNewElders,

      });

      setIsLoading(false);
    })();
  }, [location, period]);

  useEffect(() => {
    if (!data.orders || !data.issues) return;

    const chartDataMappings = getChartDataMappings(data);
    const chartData = Object.keys(chartDataMappings)
      .filter((key) => {
        return !location || (location && !key.endsWith('ByCounty'));
      })
      .map((key) => chartDataMappings[key]);

    setChartData(chartData);
  }, [data, location]);

  if (isLoading || !chartData) {
    return (
      <Grid container alignItems="center" direction='column'>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
          {progressStatus.current && Object.keys(progressStatus.current).map((key) => {
            return <Typography key={key} variant="body1" color="textPrimary" style={{ flex: 1, textAlign: 'center' }}>
              {key} 讀取進度：{progressStatus.current[key]}
            </Typography>;
          })}
        </div>
        <CircularProgress color="primary" />
      </Grid>);
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.filterContainer}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="h5" component="h1" style={{ flex: 1, paddingLeft: 8 }}>
                {title}
              </Typography>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={()=> {
                        setPeriod(item.value);
                        setMonthSelected('');
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions2.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => {
                        setPeriod(item.value);
                        setMonthSelected('');
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <FormControl className={classes.formControl}>
                <Select
                  value={monthSelected}
                  displayEmpty
                  onChange={(event)=> {
                    setPeriod(event.target.value || 'day');
                    setMonthSelected(event.target.value);
                  }}
                >
                  <MenuItem value="">選擇月份</MenuItem>
                  {monthOptions.map(({ label, value })=>(
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  value={location}
                  displayEmpty
                  onChange={(event)=>setLocation(event.target.value)}
                >
                  <MenuItem value="">全國</MenuItem>
                  {counties.map((county)=>(
                    <MenuItem key={county} value={county}>{county}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        {chartData.map((data, index) => (
          <Grid item xs={12} md={data.width || 2} key={index}>
            {renderChart(data, period)}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

function renderChart({ type, title, value, prevValue, unit, appendix, data, data2, dataKeys, mode }, period) {
  switch (type) {
    case 'summary':
      return (<SummaryCard title={title} value={value} prevValue={prevValue} unit={unit} appendix={appendix} period={period} />);
    case 'pie':
      return (<PieCard title={title} data={data} data2={data2} />);
    case 'bar':
      return (<BarCard title={title} data={data} dataKeys={dataKeys} mode={mode} />);
    case 'plot':
      return (<PlotCard title={title} data={data} />);
    default:
  }
}
