import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import { issueStatus } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const filteredIssueStatus = issueStatus
  .filter(({ step }) => step !== void 0)
  .sort((a, b) => a.step > b.step ? 1 : -1);

const steps = filteredIssueStatus.map(({ label }) => label);

export default function IssueStatusProgress({ status, onChange }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const updateStep = (index) => {
    setActiveStep(index);
    onChange && onChange(filteredIssueStatus[index].value);
  };

  useEffect(() => {
    const completed = filteredIssueStatus.map(({ step }) => {
      return 0 <= step && step <= activeStep;
    });
    setCompleted(completed);
  }, [activeStep]);

  useEffect(() => {
    if (status) {
      const matched = filteredIssueStatus.find(({ value }) => value === status);
      if (matched) {
        setActiveStep(matched.step);
      }
    }
  }, [status]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} nonLinear>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={()=>updateStep(index)} completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

IssueStatusProgress.propTypes = {
  status: PropTypes.string,
  onChange: PropTypes.func,
};
