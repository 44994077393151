import CheckboxesWithSelectAll from 'forms/components/CheckboxesWithSelectAll';

const uiSchema = {
  'status': {
    'ui:width': '50%',
  },
  'category': {
    'ui:width': '50%',
  },
  'oneOf': {
    'ui:width': '50%',
  },
  'deliveryOption': {
    'ui:width': '50%',
    'ui:widget': 'radio',
    'ui:options': {
      'inline': true,
    },
  },
  'deliveryGroupId': {
    'ui:width': '100%',
  },
  'elderId': {
    'ui:width': '100%',
  },
  'deliveryStaffId': {
    'ui:width': '50%',
  },
  'restaurantId': {
    'ui:width': '50%',
  },
  'repeatOn': {
    'ui:widget': CheckboxesWithSelectAll,
    'ui:options': {
      'inline': true,
    },
  },
  'deliveryBy': {
    'ui:width': '33.33%',
  },
  'startOn': {
    'ui:width': '33.33%',
  },
  'endOn': {
    'ui:width': '33.33%',
  },
};

export default uiSchema;
