import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table/Table';
import {
  getNotificationsByDeliveryStaffIdByCreatedAt,
  getNotificationsByClientIdByCreatedAt,
  getNotificationsByRestaurantOwnerIdByCreatedAt,
  listNotifications,
} from 'graphql/queries';
import { request } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const title = '通知紀錄';
const description = '';

export default function NotificationTable({
  deliveryStaffId,
  restaurantOwnerId,
  clientId,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const options = {};

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: '類別',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'statusMsg',
      label: '狀態紀錄',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'subject',
      label: '標題',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'text',
      label: '內容',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'shouldBeSentAt',
      label: '預計送出時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'sentAt',
      label: '送出於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        let records;
        if (deliveryStaffId) {
          const { data: { getNotificationsByDeliveryStaffIdByCreatedAt: { items } } } = await request(getNotificationsByDeliveryStaffIdByCreatedAt, {
            deliveryStaffId,
            sortDirection: 'DESC',
            limit: 100,
          });
          records = items;
        } else
        if (restaurantOwnerId) {
          const { data: { getNotificationsByRestaurantOwnerIdByCreatedAt: { items } } } = await request(getNotificationsByRestaurantOwnerIdByCreatedAt, {
            restaurantOwnerId,
            sortDirection: 'DESC',
            limit: 100,
          });
          records = items;
        } else
        if (clientId) {
          const { data: { getNotificationsByClientIdByCreatedAt: { items } } } = await request(getNotificationsByClientIdByCreatedAt, {
            clientId,
            sortDirection: 'DESC',
            limit: 100,
          });
          records = items;
        } else {
          const { data: { listEvents: { items } } } = await request(listNotifications, { limit: 100 });
          records = items;
        }

        setData(records.sort(sortBy('createdAt', true)));
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [deliveryStaffId, restaurantOwnerId, lastUpdatedAt]);

  return (
    <Table
      title={title}
      isLoading={isLoading}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onRefresh={() => setLastUpdatedAt(Date.now())}
    />
  );
}

NotificationTable.propTypes = {
  deliveryStaffId: PropTypes.string,
  restaurantOwnerId: PropTypes.string,
  clientId: PropTypes.string,
};
