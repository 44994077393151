import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

import { asyncListAll } from 'utilities/graph';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import {
  getOrdersByClientByDate,
  getDeliveryStaffsByClientIdByIsActive,
  getEldersByClient,
} from 'graphql/queries';
import { sortBy } from 'utilities/sorting';
import ScheduleCalendar from 'components/Schedule/ScheduleCalendar';
import ScheduleBoard from 'views/ScheduleBoard';
import OrderStatusChip from 'components/OrderStatusChip';
import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
import cache from 'utilities/cache';
import { getTranslatedOrderStatus, getTranslatedMealSlots } from 'utilities/translate';
import Loading from 'components/Loading';
import { cancellationReasons } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  content: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const DATE_FORMAT = 'YYYY-MM-DD';

const getDates = (from, to) => {
  const dates = [moment(to).tz(TIME_ZONE).format(DATE_FORMAT)];

  while (dates[0] > from) {
    const prev = moment(dates[0]).tz(TIME_ZONE).add(-1, 'day').format(DATE_FORMAT);
    dates.unshift(prev);
  }
  return dates;
};

const getDisplayMode = () => cache.get('app:facility:schedule:displayMode') || 'calendar';
const getTargetOrderStatus = () => cache.get('app:facility:schedule:targetOrderStatus') || [
  'waitingForDeliveryStaff',
  'reMatchingDeliveryStaff',
  'ready',
  'readyForPickup',
  'delivering',
];
const getTargetDeliveryStaffIds = () => cache.get('app:facility:schedule:targetDeliveryStaffIds') || [];
const getTargetElderIds = () => cache.get('app:facility:schedule:targetElderIds') || [];
const getTargetMealSlots = () => cache.get('app:facility:schedule:targetMealSlots') || ['lunch', 'dinner'];

export default function Schedule() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(getDisplayMode);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [targetOrderStatus, setTargetOrderStatus] = useState(getTargetOrderStatus);
  const [targetDeliveryStaffIds, setTargetDeliveryStaffIds] = useState(getTargetDeliveryStaffIds);
  const [targetElderIds, setTargetElderIds] = useState(getTargetElderIds);
  const [targetMealSlots, setTargetMealSlots] = useState(getTargetMealSlots);

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState([]);
  const [elderOptions, setElderOptions] = useState([]);
  const [dateInfo, setDateInfo] = useState();
  const orderMappings = useRef({});
  const [ordersInDates, setOrdersInDates] = useState([]);

  const clientId = cache.get('app:facilityId');

  const onDateChange = (newDateInfo) => {
    const newFromDate = moment(newDateInfo.startStr).tz(TIME_ZONE).format(DATE_FORMAT);
    const newToDate = moment(newDateInfo.endStr).tz(TIME_ZONE).format(DATE_FORMAT);

    const { fromDate, toDate } = dateInfo || {};

    if (newFromDate !== fromDate || newToDate !== toDate) {
      setDateInfo({
        fromDate: newFromDate,
        toDate: newToDate,
        dates: getDates(newFromDate, newToDate),
      });
    }
  };

  useEffect(() => {
    if (!dateInfo) return;

    (async () => {
      setIsLoading(true);
      let orders = [];
      const { dates } = dateInfo;

      const queryPromsies = [];
      const asyncListAllWrapper = async (queryName, params) => {
        const key = JSON.stringify(params);

        if (orderMappings.current[key]) {
          // global.logger.debug(`${key} exists, use cache data`);
          return orderMappings.current[key];
        }

        const data = await asyncListAll(queryName, params);
        orderMappings.current[key] = data;

        return data;
      };

      dates.forEach((date) => {
        const params = {
          clientId,
          date: {
            eq: date,
          },
        };
        queryPromsies.push(asyncListAllWrapper(getOrdersByClientByDate, params));
      });

      const results = await Promise.all(queryPromsies);
      results.forEach((items) => {
        orders = [...orders, ...items];
      });

      setOrdersInDates(orders);

      setIsLoading(false);
    })();
  }, [lastUpdatedAt, clientId, dateInfo]);

  useEffect(() => {
    if (ordersInDates.length === 0) {
      return;
    }

    const inTargetOrderStatus = (status, cancellationReason) => {
      return targetOrderStatus.some((statusAndReason) => {
        const [targetStatus, reason] = statusAndReason.split('-');
        if (status === 'cancelled' && targetStatus === 'cancelled') {
          return (!reason && !cancellationReason) || (cancellationReason === reason);
        }
        return targetStatus === status;
      });
    };

    (async () => {
      setFilteredOrders(ordersInDates.filter((item) => {
        let shouldRender = true;
        if (targetOrderStatus.length > 0 && !inTargetOrderStatus(item.status, item.cancellationReason)) {
          shouldRender = false;
        }
        if (targetMealSlots.length > 0 && !targetMealSlots.includes(item.mealSlot)) {
          shouldRender = false;
        }
        if (targetDeliveryStaffIds.length > 0 && !targetDeliveryStaffIds.includes(item.deliveryStaffId)) {
          shouldRender = false;
        }

        if (targetElderIds.length > 0 && !targetElderIds.includes(item.elderId)) {
          shouldRender = false;
        }

        return shouldRender;
      }));
    })();
  }, [ordersInDates, targetOrderStatus, targetMealSlots, targetElderIds, targetDeliveryStaffIds]);


  useEffect(() => {
    (async () => {
      const [deliveryStaffs, elders] = await Promise.all([
        asyncListAll(getDeliveryStaffsByClientIdByIsActive, {
          clientId,
          isActive: { eq: 1 },
        }),
        asyncListAll(getEldersByClient, { clientId }),
      ]);

      setDeliveryStaffOptions([
        { label: '尚無指派', value: null },
        ...deliveryStaffs.map(({ id, name }) => {
          return {
            label: name,
            value: id,
          };
        }).sort(sortBy('label'))]);
      const targetDeliveryStaffIdsInCache = cache.get('app:facility:schedule:targetDeliveryStaffIds') || [];
      const newTargetDeliveryStaffIds = targetDeliveryStaffIdsInCache.filter(
        (deliveryStaffId) => deliveryStaffs.some(({ id }) => id === deliveryStaffId));
      setTargetDeliveryStaffIds(newTargetDeliveryStaffIds);
      cache.set('app:facility:schedule:targetDeliveryStaffIds', newTargetDeliveryStaffIds);
      setElderOptions([
        ...elders.map(({ id, name }) => {
          return {
            label: name,
            value: id,
          };
        }).sort(sortBy('label'))]);
      const targetElderIdsInCache = cache.get('app:facility:schedule:targetElderIds') || [];
      const newTargetElderIds = targetElderIdsInCache.filter(
        (elderId) => elders.some(({ id }) => id === elderId));
      setTargetElderIds(newTargetElderIds);
      cache.set('app:facility:schedule:targetElderIds', newTargetElderIds);
    })();
  }, [clientId]);

  const renderStatusChipValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value) => {
        const [status, reason] = value.split('-');
        return (<OrderStatusChip status={status} reason={reason} key={value} />);
      })}
    </div>
  );
  const translatedMealSlots = getTranslatedMealSlots();
  const renderMealSlotValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value, index) => {
        return (<Chip
          key={index}
          label={translatedMealSlots.find((item)=>item.value === value).label}
        />);
      })}
    </div>
  );

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid container item md={2} className={classes.content} direction="column">
          <FormControl className={classes.formControl}>
            <ButtonGroup color="primary">
              <Button
                variant={mode === 'calendar' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilteredOrders([]);
                  setMode('calendar');
                  cache.set('app:facility:schedule:displayMode', 'calendar');
                }}
              >
                日曆模式
              </Button>
              <Button
                variant={mode === 'board' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilteredOrders([]);
                  setMode('board');
                  cache.set('app:facility:schedule:displayMode', 'board');
                }}
              >
                看板模式
              </Button>
            </ButtonGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-orderStatus-label">狀態</InputLabel>
            <Select
              labelId="mutiple-orderStatus-label"
              id="mutiple-orderStatus"
              multiple
              value={targetOrderStatus}
              onChange={(event) => {
                setTargetOrderStatus(event.target.value);
                cache.set('app:facility:schedule:targetOrderStatus', event.target.value);
              }}
              input={<Input id="select-multiple-orderStatus" />}
              renderValue={renderStatusChipValue}
              disabled={isLoading}
            >
              {getTranslatedOrderStatus().map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={targetOrderStatus.indexOf(item.value) > -1} />
                  {item.label}
                </MenuItem>
              ))}
              {cancellationReasons.map(({ label, value }) => {
                const cancellationReasonItem = `cancelled-${value}`;
                return (
                  <MenuItem key={cancellationReasonItem} value={cancellationReasonItem}>
                    <Checkbox checked={targetOrderStatus.indexOf(cancellationReasonItem) > -1} />
                    取消 - {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-mealSlot-label">{`${t('午餐')}/${t('晚餐')}`}</InputLabel>
            <Select
              labelId="mutiple-mealSlot-label"
              id="mutiple-mealSlot"
              multiple
              value={targetMealSlots}
              onChange={(event) => {
                setTargetMealSlots(event.target.value);
                cache.set('app:facility:schedule:targetMealSlots', event.target.value);
              }}
              input={<Input id="select-multiple-mealSlot" />}
              renderValue={renderMealSlotValue}
              disabled={isLoading}
            >
              {translatedMealSlots.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={targetMealSlots.indexOf(item.value) > -1} />
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
              multiple
              options={deliveryStaffOptions.map(({ value }) => value)}
              value={targetDeliveryStaffIds}
              getOptionLabel={(option) => {
                const deliveryStaff = deliveryStaffOptions.find(({ value }) => value === option) || {};
                return deliveryStaff.label;
              }}
              filterSelectedOptions
              onChange={(event, newValue) => {
                setTargetDeliveryStaffIds(newValue);
                cache.set('app:facility:schedule:targetDeliveryStaffIds', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('送餐大使')}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
              multiple
              options={elderOptions.map(({ value }) => value)}
              value={targetElderIds}
              getOptionLabel={(option) => {
                const elder = elderOptions.find(({ value }) => value === option) || {};
                return elder.label;
              }}
              filterSelectedOptions
              onChange={(event, newValue) => {
                setTargetElderIds(newValue);
                cache.set('app:facility:schedule:targetElderIds', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('送餐對象')}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <Button
            onClick={() => {
              orderMappings.current = {};
              setLastUpdatedAt(Date.now());
            }}
            disabled={isLoading}
          >
            <RefreshIcon size={20} />
            重新整理
          </Button>
          <AdminOrderEditButton
            mode="create"
            type="button"
            onUpdate={() => {
              orderMappings.current = {};
              setLastUpdatedAt(Date.now());
            }}
            disabled={isLoading}
          />
          {isLoading && <Loading fullScreen={false} />}
        </Grid>
        {mode === 'calendar' ?
          <Grid item xs={12} md={10} className={classes.content}>
            <ScheduleCalendar
              orders={filteredOrders}
              onDateChange={onDateChange}
            />
          </Grid> :
          <Grid item xs={12} md={10}>
            <ScheduleBoard
              orders={filteredOrders}
              onDateChange={onDateChange}
              deliveryStaffOptions={deliveryStaffOptions}
            />
          </Grid>}
      </Grid>
    </div>
  );
}
