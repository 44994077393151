import React, { useState, useEffect, Fragment } from 'react';
import { Hub } from 'aws-amplify';
import WinboxReact from 'winbox-react';

import RestaurantDetails from 'components/RestaurantDetails';

const winboxOptions = {
  x: 'center',
  y: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export default function Winbox() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(null);

  useEffect(() => {
    const winboxListener = ({ payload: { event, data } }) => {
      global.logger.debug(event, data);
      switch (event) {
        case 'restaurant':
          setTitle('餐廳');
          setContent(<RestaurantDetails id={data.id} />);
          break;
        default:
      }
      setShow(true);
    };
    Hub.listen('winbox', winboxListener);

    return () => {
      Hub.remove('winbox', winboxListener);
    };
  }, []);

  return (
    <Fragment>
      {show && content &&
        <WinboxReact
          title={title}
          onClose={() => setShow(false)}
          className="winbox-container"
          {...winboxOptions}
        >
          {content}
        </WinboxReact>}
    </Fragment>
  );
}
