export default [
  {
    label: '個案在家',
    value: 'atHome',
  },
  {
    label: '個案未遇，餐點放置定點',
    value: 'leftThere',
  },
  {
    label: '個案未遇，餐點親友代領',
    value: 'receivedByRelative',
  },
];
