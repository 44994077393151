// import React from 'react';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import SearchIcon from '@material-ui/icons/Search';
// import MotorcycleIcon from '@material-ui/icons/Motorcycle';
// import HomeIcon from '@material-ui/icons/Home';
// import BlockIcon from '@material-ui/icons/Block';
// import AssignmentIcon from '@material-ui/icons/Assignment';

import Colors from './Colors';

export default [
  {
    label: '大使配對中',
    value: 'waitingForDeliveryStaff',
    color: '#cac428',
    colorText: 'yellow',
    step: 0,
    // icon: <SearchIcon />,
  },
  {
    label: '重新配對大使中',
    value: 'reMatchingDeliveryStaff',
    color: '#ff0318',
    colorText: 'red',
  },
  {
    label: '可以出餐',
    value: 'ready',
    color: '#00913A',
    colorText: 'green',
    step: 1,
    // icon: <AssignmentIcon />,
  },
  {
    label: '可以取餐',
    value: 'readyForPickup',
    color: '#cb8427',
    colorText: 'orange',
    step: 2,
    // icon: <AssignmentIcon />,
  },
  {
    label: '大使送餐中',
    value: 'delivering',
    color: '#2189DC',
    colorText: 'blue',
    step: 3,
    // icon: <MotorcycleIcon />,
  },
  {
    label: '餐點已送達',
    value: 'delivered',
    color: '#7300ff',
    colorText: 'violet',
    step: 4,
    // icon: <HomeIcon />,
  },
  {
    label: '完成 (送餐日誌)',
    value: 'completed',
    color: Colors.dark,
    colorText: 'black',
    step: 5,
    // icon: <CheckCircleIcon />,
  },
  {
    label: '取消',
    value: 'cancelled',
    color: '#cecece',
    colorText: 'grey',
    // icon: <BlockIcon />,
  },
];
