import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import { countiesDistricts, counties } from '@silvergatedelivery/constants';
import { compareObjects } from 'utilities/sorting';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 230,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
}));

function not(arrayA, arrayB) {
  return arrayA.filter((a) => !arrayB.some((b) => compareObjects(a, b)));
}

function intersection(arrayA, arrayB) {
  return arrayA.filter((a) => arrayB.some((b) => compareObjects(a, b)));
}

const getDistrictsInCounty = (inCounty) => countiesDistricts.filter(({ county }) => county === inCounty)
  .reduce((all, { zipCode, county, districts }) => {
    const items = districts.map((district) => ({ zipCode, county, district }));
    return [...all, ...items];
  }, []);

export default function AreaSelection({ areas = [], onChange, description = '' }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(not(getDistrictsInCounty(areas[0] ? areas[0].county : '臺北市'), areas));
  const [right, setRight] = useState(areas);
  const [activeCounty, setActiveCounty] = useState(areas[0] ? areas[0].county : '臺北市');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(()=> {
    setLeft(not(getDistrictsInCounty(activeCounty), areas));
  }, [activeCounty]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    const newRight = right.concat(left);
    setRight(newRight);
    setLeft([]);
    setChecked([]);
    onChange(newRight);
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    setRight(newRight);
    setLeft(not(left, leftChecked));
    setChecked([]);
    onChange(newRight);
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked.filter(({ county }) => county === activeCounty));
    setLeft(newLeft);
    const newRight = not(right, rightChecked);
    setRight(newRight);
    setChecked([]);
    onChange(newRight);
  };

  const handleAllLeft = () => {
    const newLeft = left.concat(right.filter(({ county }) => county === activeCounty));
    setLeft(newLeft);
    setRight([]);
    setChecked([]);
    onChange([]);
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.sort((a, b) => parseInt(a.zipCode, 10) - parseInt(b.zipCode, 10))
          .map((value) => {
            const display = `${value.zipCode} ${value.county}${value.district}`;
            const labelId = `transfer-list-item-${display}-label`;

            return (
              <ListItem key={display} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={display} />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      // justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Autocomplete
            name='location'
            options={counties}
            value={activeCounty}
            onChange={(event, newItem) => {
              if (newItem) {
                setActiveCounty(newItem);
              }
            }}
            renderInput={(params) => <TextField {...params} label="縣市"/>}
          />
        </Grid>
      </Grid>
      {description !== '' &&
        <Grid item xs={12}>
          <Typography variant="body2">
            {description}
          </Typography>
        </Grid>}
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            <DoubleArrowIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <KeyboardArrowRightIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <KeyboardArrowRightIcon className={classes.rotateIcon}/>
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            <DoubleArrowIcon className={classes.rotateIcon}/>
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}

AreaSelection.propTypes = {
  areas: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
};
