import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import { listOrganizations } from 'graphql/queries';
import OrganizationDetails from 'components/OrganizationDetails';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import OrganizationEditButton from 'forms/OrganizationForm/OrganizationEditButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

export default function OrganizationTable({
  title = '組織',
  description,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const columns = [
    { // fixed at 1st, don't move it
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    { // fixed at 2nd, don't move it
      name: 'clients',
      label: '機構編號',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
        customBodyRender(clients) {
          const { items } = clients;
          if (items && items.length !== 0) {
            return items.map(( { client }) => client.id);
          }
          return [];
        },
      },
    },
    {
      name: 'name',
      label: '名稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'description',
      label: '描述',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'clients.items',
      label: '機構',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(items) {
          if (items && items.length !== 0) {
            return <>
              {
                items.map(( { id, client, visitorMode, clientMode, elderSetting }) => {
                  return <div style={{ display: 'flex', alignItems: 'center' }} key={id}>
                    <Tooltip title={'訪客模式'}>
                      {visitorMode ? <CheckBoxIcon color="secondary" /> :
                        <CheckBoxOutlineBlankIcon />}
                    </Tooltip>
                    <Tooltip title={'可切換至機構模式'}>
                      {clientMode ? <CheckBoxIcon color="secondary" /> :
                        <CheckBoxOutlineBlankIcon />}
                    </Tooltip>
                    <Tooltip title={'組織模式下可編輯送餐對象'}>
                      {elderSetting ? <CheckBoxIcon color="secondary" /> :
                        <CheckBoxOutlineBlankIcon />}
                    </Tooltip>
                    <Typography variant="body2" component="p" style={{ marginLeft: 10 }}>{client.name}</Typography>
                  </div>;
                })
              }
            </>;
          }
          return '';
        },
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/organization/${id}`}
              label="前往組織頁面"
            />
          );
        },
      },
    },
  ];

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const id = rowData[0];
      const clientIds = rowData[1];
      return (
        <NestedTableContainer columns={columns}>
          <OrganizationDetails id={id} clientIds={clientIds} isNested={true} />
        </NestedTableContainer>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const facliityIndex = columns.findIndex(({ name }) => name === 'clients.items');
      const newData = JSON.parse(JSON.stringify(data));
      if (facliityIndex) {
        newData.forEach(({ data }) => {
          if (data[facliityIndex]) {
            const facilityNames = data[facliityIndex].map(({ client = {} }) => client.name || '');
            data[facliityIndex] = facilityNames;
          }
        });
      }
      return '\uFEFF' + buildHead(columns) + buildBody(newData);
    },
  };

  useEffect(() => {
    setIndexes([
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '名稱',
          key: 'name',
          comparisonOperator: 'contains',
        }],
        operation: listOrganizations,
      },
    ]);
    setQueryParams({});
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={OrganizationEditButton}
    />
  );
}

OrganizationTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
