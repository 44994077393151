import React from 'react';

import EditButton from '../components/EditButton';
import Form from './MembershipProgramForm';

export default function MembershipProgramFormEditButton({ ...props }) {
  return (
    <EditButton
      title="會員項目"
      form={Form}
      {...props}
    />);
}
