import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { request } from 'utilities/graph';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  adminUpdateOrder,
} from 'graphql/mutations';
import { Semaphore } from 'async-mutex';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import { toastr } from 'react-redux-toastr';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { cancellationReasons } from '@silvergatedelivery/constants';

export default function UpdateOrderToCancelledButton({
  onUpdate,
  ordersSelected,
}) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cancellationReason, setCancellationReason] = useState();
  const [cancellationNote, setCancellationNote] = useState();

  const handleClose = () => {
    setOpen(false);
    setCancellationReason();
    setCancellationNote();
  };

  const handleSubmit = async () => {
    let orderProcessed = 0;
    const toUpdateData = [];
    const ordersUncancelled = ordersSelected.current.filter((order) => !['completed', 'delivered', 'cancelled'].includes(order.status));

    setIsLoading(true);

    const s = new Semaphore(10);
    const orderGrouped = {};
    ordersUncancelled.forEach((order) => {
      const groupId = order.orderGroupId || order.id;
      orderGrouped[groupId] = orderGrouped[groupId] || [];
      orderGrouped[groupId].push(order);
    });

    await Promise.all(Object.keys(orderGrouped).map((groupId) =>
      s.runExclusive(async () => {
        const data = orderGrouped[groupId].map((order) => (
          {
            id: order.id,
            restaurantId: order.restaurantId,
            deliveryBy: order.deliveryBy,
            status: 'cancelled',
            tier: order.tier,
            cancellationReason,
            cancellationNote,
          }
        ));
        try {
          await request(adminUpdateOrder, { input: { orders: data } });
          toUpdateData.push(...data);
        } catch (e) {
          if (e.errors && e.errors[0] && e.errors[0].message) {
            toastr.error(e.errors[0].message);
          }
        }
        orderProcessed += 1;
        setProgress(orderProcessed * 100 / ordersUncancelled.length);
      }),
    ));
    onUpdate && onUpdate(toUpdateData);

    setIsLoading(false);
    setOpen(false);
    setCancellationReason();
    setCancellationNote();
  };

  const onSetOrdersAsCancelled = async () => {
    const ordersUncancelled = ordersSelected.current.filter((order) => !['completed', 'delivered', 'cancelled'].includes(order.status))
      .sort((a, b) => a.date > b.date ? 1 : -1);
    if (ordersUncancelled.length !== 0) {
      let orderDuration = `修改訂單的送餐日期範圍：${ordersUncancelled[0].date} 至 ${ordersUncancelled[ordersUncancelled.length - 1].date}`;
      if (ordersUncancelled[0].date === ordersUncancelled[ordersUncancelled.length - 1].date) {
        orderDuration = `修改訂單的送餐日期：${ordersUncancelled[0].date}`;
      }
      if (window.confirm(`共${ordersUncancelled.length}筆可取消的訂單, 確定要修改這${ordersUncancelled.length}筆訂單?\n${orderDuration}`)) {
        setOpen(true);
      }
    } else {
      window.alert(`選擇的訂單中沒有可取消的訂單`);
    }
  };

  return (
    <>
      <Button
        style={ { marginLeft: 10 } }
        variant="contained"
        color="primary"
        onClick={ async () => {
          await onSetOrdersAsCancelled();
        }}
      >
        標示為訂單已取消
      </Button>
      <Dialog
        maxWidth='sm'
        fullWidth
        open={open}
      >
        <DialogTitle id="form-dialog-title">
          取消訂單
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutoSelect
                id={'cancellationReason'}
                options={cancellationReasons.map(({ label }) => label)}
                values={cancellationReasons.map(({ value }) => value)}
                required
                value={cancellationReason}
                label={'訂單取消原因'}
                disabled={isLoading}
                onChange={(value) => setCancellationReason(value)}
                freeSolo={false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="cancellationNote"
                label="訂單取消備註"
                type="text"
                value={cancellationNote}
                fullWidth
                disabled={isLoading}
                onChange={(e) => setCancellationNote(e.target.value) }
              />
            </Grid>
          </Grid>
          {isLoading &&
          <div style={{ marginTop: 30 }}>
            <LinearProgressWithLabel value={progress}/>
          </div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isLoading || !cancellationReason}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

UpdateOrderToCancelledButton.propTypes = {
  onUpdate: PropTypes.func,
  ordersSelected: PropTypes.object,
};
