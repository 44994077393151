import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/Table/DataTable';
import Loading from 'components/Loading';
import {
  // getRecurringOrdersByStatus,
  getRecurringOrdersByClientByStatus,
  getRecurringOrdersByElderByStatus,
  getRecurringOrdersByDeliveryGroupByStatus,
  getRecurringOrdersByDeliveryStaffByStatus,
  getRecurringOrdersByRestaurantByStatus,
} from 'graphql/queries';
import AdminRecurringOrderButtonFunc from 'forms/AdminRecurringOrderForm/AdminRecurringOrderButtonFunc';
import AdminRecurringOrderPreviewFunc from 'forms/AdminRecurringOrderForm/AdminRecurringOrderPreviewFunc';
import cache from 'utilities/cache';
import { getTranslatedMealSlots } from 'utilities/translate';
import { WEEKDAYS_SHORT } from '@silvergatedelivery/constants';
import moment from 'moment-timezone';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import OrderTable from 'components/OrderTable';
import { useCache } from 'CacheProvider';
import { asyncListAll } from 'utilities/graph';
import { getDeliveryStaffIdSchema } from 'forms/schemas';

export default function RecurringOrderTable({
  title = '週期性排單',
  description,
  clientId: inClientId,
  elderId,
  deliveryGroups,
  restaurantId,
  deliveryStaffId,
  queryStatus,
  defaultIndex = 0,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const { appGroup } = useCache();
  const ordersSelected = useRef([]);
  const notAdminMode = appGroup !== 'Admins';
  const clientName = cache.get('app:name');
  const clientId = inClientId || cache.get('app:facilityId');
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState();

  useEffect(() => {
    (async () => {
      const deliveryStaffOptions = await getDeliveryStaffIdSchema(null, null, clientId, true, true);
      setDeliveryStaffOptions(deliveryStaffOptions);
    })();
  }, []);

  const defaultIndexes = [
    {
      name: 'getRecurringOrdersByClientByStatus',
      label: '按狀態查詢',
      partitionKey: 'clientId',
      sortKey: 'status',
      fields: [{
        label: '機構',
        key: 'clientId',
        type: 'select',
        options: [{ value: clientId, label: clientName }],
      }, {
        label: '狀態',
        key: 'status',
        type: 'select',
        options: ['使用中', '已停用'].map((item) => {
          return { value: item, label: item };
        }),
      }],
      operation: getRecurringOrdersByClientByStatus,
    },
  ];

  const translatedMealSlots = getTranslatedMealSlots();
  const columns = [
    {
      name: 'id',
      label: '編號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'client.name',
      label: '客戶',
      options: {
        filter: false,
        sort: true,
        display: notAdminMode ? 'excluded' : true,
      },
    },
    {
      name: 'category',
      label: '類別',
      options: {
        filter: true,
        sort: true,
        display: notAdminMode ? 'excluded' : true,
      },
    },
    {
      name: 'deliveryGroupId',
      label: `${t('送餐群組')}編號`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryGroup.name',
      label: `${t('送餐群組')}`,
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elderId',
      label: `${t('送餐對象')}編號`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elder.name',
      label: t('送餐對象'),
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffId',
      label: t('送餐大使'),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender(deliveryStaffId) {
          if (deliveryStaffOptions) {
            const index = deliveryStaffOptions.enum.findIndex((id) => id === deliveryStaffId);
            if (index !== -1) {
              return deliveryStaffOptions.enumNames[index];
            }
          }
          return '';
        },
      },
    },
    {
      name: 'mealSlot',
      label: '時段',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return translatedMealSlots.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'deliveryBy',
      label: '送餐時間',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(value) {
          return moment(value, 'HH:mm:ss').format('HH:mm');
        },
      },
    },
    {
      name: 'startOn',
      label: '開始日期',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'endOn',
      label: '結束日期',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'repeatOn',
      label: '送餐週期',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (repeatOn) => {
          return repeatOn.sort((a, b) => {
            if (a === 0) return 1; // move 0 to the last possition
            if (b === 0) return -1;
            return a - b;
          }).map((day) => `${WEEKDAYS_SHORT[day]}`).join('、');
        },
      },
    },
    {
      name: 'restaurant.name',
      label: t('餐廳'),
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mealItems',
      label: t('餐點'),
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (mealItems) => {
          return mealItems.map(({ name, quantity }) => `${name} x${quantity}`).join(', ');
        },
      },
    },
    {
      name: 'lastExecutedAt',
      label: '上次排單時間',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lastExecutedStatus',
      label: '上次排單結果',
      type: 'text',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (status) => {
          if (status === 'success') {
            return '排單成功';
          }
          if (status === 'failed') {
            return '排單失敗';
          }
          return '';
        },
      },
    },
    {
      name: 'lastExecutedStatusMessage',
      label: '上次排單訊息',
      type: 'text',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    if (deliveryStaffId) {
      const indexes = [
        {
          name: 'getRecurringOrdersByDeliveryStaffByStatus',
          label: `按${t('送餐大使')}查詢`,
          partitionKey: 'deliveryStaffId',
          sortKey: 'status',
          fields: [{
            label: `${t('送餐大使')}ID`,
            key: 'deliveryStaffId',
            type: 'select',
            options: [{ value: deliveryStaffId, label: deliveryStaffId }],
            disabled: true,
          }, {
            label: '狀態',
            key: 'status',
            type: 'select',
            options: ['使用中', '已停用'].map((item) => {
              return { value: item, label: item };
            }),
          }],
          operation: getRecurringOrdersByDeliveryStaffByStatus,
        },
      ];
      if (clientId) {
        indexes[0].fields.push( {
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: clientId, label: clientId }],
          disabled: true,
        });
      }
      setIndexes(indexes);
      setQueryParams({
        deliveryStaffId,
        status: '使用中',
        clientId,
      });
    } else if (restaurantId) {
      const indexes = [
        {
          name: 'getRecurringOrdersByRestaurantByStatus',
          label: `按${t('餐廳')}查詢`,
          partitionKey: 'restaurantId',
          sortKey: 'status',
          fields: [{
            label: `${t('餐廳')} ID`,
            key: 'restaurantId',
            type: 'select',
            options: [{ value: restaurantId, label: restaurantId }],
            disabled: true,
          }, {
            label: '狀態',
            key: 'status',
            type: 'select',
            options: ['使用中', '已停用'].map((item) => {
              return { value: item, label: item };
            }),
          }],
          operation: getRecurringOrdersByRestaurantByStatus,
        },
      ];
      if (clientId) {
        indexes[0].fields.push( {
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: clientId, label: clientId }],
          disabled: true,
        });
      }
      setIndexes(indexes);
      setQueryParams({
        restaurantId,
        status: '使用中',
        clientId,
      });
    } else if (elderId) {
      setIndexes([]);
      setQueryParams({});
      setData([]);
      (async () => {
        setIsLoading(true);
        try {
          const recurringOrdersByElder = await asyncListAll(getRecurringOrdersByElderByStatus, { elderId });
          let recurringOrdersByDeliveryGroups = [];
          if (deliveryGroups && deliveryGroups.length !== 0) {
            console.log(deliveryGroups);
            recurringOrdersByDeliveryGroups = await Promise.all(deliveryGroups.map(({ deliveryGroup: { id: deliveryGroupId } }) =>
              asyncListAll(getRecurringOrdersByDeliveryGroupByStatus, { deliveryGroupId })));
          }
          console.log([...recurringOrdersByElder, ...recurringOrdersByDeliveryGroups.flat()]);
          setData([...recurringOrdersByElder, ...recurringOrdersByDeliveryGroups.flat()]);
        } catch (e) {
          console.error(e);
        }
        setIsLoading(false);
      })();
    } else if (clientId) {
      setIndexes([
        {
          name: 'getRecurringOrdersByClientByStatus',
          label: '按狀態查詢',
          partitionKey: 'clientId',
          sortKey: 'status',
          fields: [{
            label: '機構',
            key: 'clientId',
            type: 'select',
            options: [{ value: clientId, label: clientName || clientId }],
          }, {
            label: '狀態',
            key: 'status',
            type: 'select',
            options: ['使用中', '已停用'].map((item) => {
              return { value: item, label: item };
            }),
          }],
          operation: getRecurringOrdersByClientByStatus,
        },
      ]);
      setQueryParams({
        clientId,
        status: '使用中',
      });
    } else {
      setIndexes(defaultIndexes);
      setQueryParams({
        clientId,
        status: '使用中',
      });
    }
  }, [elderId, clientId, restaurantId, queryStatus]);

  const onDataSelectionChange = (dataSelected) => {
    ordersSelected.current = dataSelected;
  };

  if (!indexes || !queryParams) return null;

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      return (
        <NestedTableContainer columns={columns}>
          <OrderTable recurringOrderId={rowData[0]} />
        </NestedTableContainer>
      );
    },
  };

  if (isLoading) {
    return (<Loading fullScreen={false} />);
  }

  return (
    <React.Fragment>
      <DataTable
        data={data}
        defaultIndex={defaultIndex}
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        options={options}
        editButton={notAdminMode ? AdminRecurringOrderButtonFunc : undefined}
        customButton={notAdminMode ? AdminRecurringOrderPreviewFunc : undefined}
        onDataSelectionChange={onDataSelectionChange}
        {...props}
      />
    </React.Fragment>);
}

RecurringOrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
  elderId: PropTypes.string,
  deliveryGroups: PropTypes.array,
  deliveryStaffId: PropTypes.string,
  restaurantId: PropTypes.string,
  defaultIndex: PropTypes.number,
  queryStatus: PropTypes.string,
};
