import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FormDialog from 'components/FormDialog';
import TagChip from 'components/TagChip';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';

export default function MealItemDialog({ restaurant, meal, onClose, quantity: inQuantity = 1 }) {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(inQuantity);
  const [totalPrice, setTotalPrice] = useState(meal.price);

  const {
    name,
    imageUrls,
    price,
    tags,
    description,
  } = meal;
  const restaurantName = restaurant.name;

  const tagKeys = tags.items.map(({ tag: { category, subcategory, label, color = '' } }) => {
    return `${category}__${subcategory}__${label}__${color}`;
  });

  const handleAddMealItem = async () => {
    global.logger.debug('handleAddMealItem', meal);

    onClose({
      meal,
      quantity,
    });
  };

  return (
    <FormDialog
      title={name}
      openOnInit={true}
      fullScreen={true}
      maxWidth={'xs'}
      onClose={() => onClose()}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center" spacing={2}>
          {imageUrls && imageUrls[0] ? imageUrls.map((imageUrl) => (
            <Grid item key={imageUrl}>
              <Link href={imageUrl} target="_blank" rel="noopener">
                <img
                  src={imageUrl || '/images/meal.webp'}
                  width="auto"
                  height="200px"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/meal.webp';
                  }}
                />
              </Link>
            </Grid>
          )) :
            <Grid item>
              <img
                src={'/images/meal.webp'}
                width="auto"
                height="200px"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/meal.webp';
                }}
              />
            </Grid>
          }
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h5">
            {name}
          </Typography>
          <Typography gutterBottom variant="h5" color="textSecondary" >
            ${price}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="餐點簡介"
            type="text"
            readOnly
            value={description}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('餐廳')}
            type="text"
            readOnly
            value={restaurantName}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="tag-label">
              餐點標籤
            </InputLabel>
            <Select
              defaultValue={tagKeys}
              value={tagKeys}
              readOnly
              fullWidth
              multiple
              IconComponent={() => null}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map((item, index) => {
                    const [
                      category,
                      subcategory,
                      label,
                      color,
                    ] = item.split('__');
                    const tag = {
                      category,
                      subcategory,
                      label,
                      color,
                    };
                    return (
                      <TagChip
                        key={index}
                        tag={tag}
                      />
                    );
                  })}
                </div>
              )}
            >
              {tagKeys.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="quantity-label">
              數量
            </InputLabel>
            <Select
              fullWidth
              value={quantity}
              onChange={(e) => {
                const { value } = e.target;
                setQuantity(value);
                setTotalPrice(parseInt(value) * parseFloat(price));
              }}
            >
              {Array.from(Array(16).keys()).map((item) => (
                <MenuItem key={item} value={item}>
                  {item === 0 ? '移出購物車' : item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMealItem}
            size="large"
          >
            {quantity === 0 ? '移出購物車' : `加入購物車 +$${totalPrice}`}
          </Button>
        </Grid>


      </Grid>
    </FormDialog>
  );
}

MealItemDialog.propTypes = {
  restaurant: PropTypes.object.isRequired,
  meal: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  quantity: PropTypes.number,
};
