import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useHistory } from 'react-router-dom';

import { formatAddress } from 'utilities/format';
// import Directions from 'components/Map/Directions';
import cache from 'utilities/cache';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';
// import CreditCard from 'components/Payment/CreditCard';
import LinePayButton from 'components/Payment/LinePayButton';
import NewebPayButton from 'components/Payment/NewebPayButton';
import CashButton from 'components/Payment/CashButton';
import { request, asyncListAll } from 'utilities/graph';
import { quoteOrder, createElder, updateElder, createElderTag, clientCreateOrder, individualClientCreateOrder } from 'graphql/mutations';
import { getEldersByClientByStatus, getUser, getRestaurant, getRestaurantHolidayByRestaurantByYear } from 'graphql/queries';
import { Colors } from '@silvergatedelivery/constants';
import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';
import moment from 'moment';
import InvoiceInput from './InvoiceInput';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { compareObjects } from 'utilities/sorting';

const ENABLE_PAYMENT = false; // TODO: put in client setting?

const DATE_OPTIONS = Array(14).fill('').map((i, index) => {
  return moment().add(index + 1, 'days').format('YYYY/MM/DD');
});

const TIME_OPTIONS_LUNCH = [
  '10:00-11:00',
  '11:00-12:00',
  '12:00-13:00',
  '13:00-14:00',
];

const TIME_OPTIONS_DINNER = [
  '16:00-17:00',
  '17:00-18:00',
  '18:00-19:00',
];

const dayMapping = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday',
];

export default function CompleteOrder({ onStepBack }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeCancelInvoice, setAgreeCancelInvoice] = useState(false);
  const [invoice, setInvoice] = useState({
    type: 'individual',
    email: '',
    carrier: '',
    title: '',
    taxIdNumber: '',
  });
  const [date, setDate] = useState(DATE_OPTIONS[0]);
  const [timeSlot, setTimeSlot] = useState(TIME_OPTIONS_LUNCH[0]);
  const [restaurant, setRestaurant] = useState();
  const [restaurantHolidays, setRestaurantHolidays] = useState();

  const getRestaurantAndHolidays = async (restaurantId) => {
    const start = moment().tz(TIME_ZONE).year();
    const end = start + 1;
    const promises = [
      await asyncListAll(getRestaurantHolidayByRestaurantByYear, {
        restaurantId,
        year: {
          between: [start, end],
        },
      }),
      await request(getRestaurant, { id: restaurantId }),
    ];
    const [holidays, { data: { getRestaurant: restaurant } }] = await Promise.all(promises);
    setRestaurant(restaurant);
    setRestaurantHolidays(holidays);
  };

  const restaurantClosed = (dateToCheck) => {
    const dateItem = moment(dateToCheck).tz(TIME_ZONE);
    const date = dateItem.format('YYYY-MM-DD');
    const day = dateItem.day();

    if (restaurantHolidays && restaurantHolidays.length !== 0) {
      const year = dateItem.year();
      const holidays = restaurantHolidays.find(
        (restaurantHoliday) => restaurantHoliday.year === year,
      );
      if (holidays && holidays.closedDays.length !== 0) {
        if (holidays.closedDays.includes(date)) {
          return true;
        }
      }
    }
    if (restaurant && restaurant.openingHours) {
      const { openingHours } = restaurant;
      if (Object.keys(openingHours).every((key) => openingHours[key].length === 0)) {
        return false;
      }
      if (openingHours[dayMapping[day]].length === 0) {
        return true;
      }
    }
    return false;
  };

  const updateOrder = (toUpdateProps = {}) => {
    // global.logger.debug('updateOrder', toUpdateProps);

    const updatedOrder = Object.assign(order, toUpdateProps);

    setOrder({ ...updatedOrder });
    cache.set('public:menu-order', updatedOrder);

    global.logger.debug('order', updatedOrder);
  };

  const updateOrderDate = (newDate) => {
    const toUpdateProps = {
      date: newDate,
      deliveryBy: moment(`${newDate} ${timeSlot.split('-')[0]}`).tz(TIME_ZONE).toISOString(),
    };
    updateOrder(toUpdateProps);
    setDate(newDate);
  };

  const updateOrderTimeSlot = (newTimeSlot) => {
    const toUpdateProps = {
      timeSlot: newTimeSlot,
      deliveryBy: moment(`${date} ${newTimeSlot.split('-')[0]}`).tz(TIME_ZONE).toISOString(),
    };
    updateOrder(toUpdateProps);
    setTimeSlot(newTimeSlot);
  };

  const handleComplete = async (transaction) => {
    setIsLoading(true);
    global.logger.debug({ transaction, order, invoice });

    const username = cache.get('app:username');
    let clientId = cache.get('app:clientId');
    if (!clientId) {
      const { data: { getUser: existingUser } } = await request(getUser, { username });
      clientId = existingUser.clientId;
    }

    const { id: transactionId } = transaction;

    const {
      orderQuoteId,
      restaurantId,
      noteForDelivery,
      noteForMeal,
      noteForHealth,
      note,
      address,
      elderName,
      elderPhoneNumber,
      elderHealthTags,
      deliveryBy,
      mealItems,
      elderId: selectedElderId,
      mealPrice,
    } = order;

    let elderId = selectedElderId;
    let needCreateElder = false;
    let needUpdateElder = false;
    if (elderId) {
      const existingElders = await asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: { eq: '使用中' },
      });
      const matched = existingElders.find(({ id }) => id === elderId);
      if (matched) {
        if (!compareObjects(matched.address, address)) {
          needCreateElder = true;
        } else if (matched.name !== elderName) {
          needCreateElder = true;
        } else if (matched.noteForDelivery !== noteForDelivery ||
          matched.noteForMeal !== noteForMeal ||
          matched.noteForHealth !== noteForHealth ||
          matched.phoneNumber !== elderPhoneNumber
        ) {
          needUpdateElder = true;
        }
      } else {
        // 剛好被停用？
        needUpdateElder = true;
      }
    } else {
      needCreateElder = true;
    }
    if (needUpdateElder) {
      await request(updateElder, {
        input: {
          id: elderId,
          updatedBy: username,
          phoneNumber: elderPhoneNumber,
          noteForDelivery,
          noteForMeal,
          noteForHealth,
        },
      }, 'AMAZON_COGNITO_USER_POOLS');
    } else if (needCreateElder) {
      const { data: { createElder: { id } } } = await request(createElder, {
        input: {
          status: '使用中',
          clientId,
          county: address.county,
          createdBy: username,
          updatedBy: username,
          username: 'N/A',
          name: elderName,
          phoneNumber: elderPhoneNumber,
          address,
          noteForDelivery,
          noteForMeal,
          noteForHealth,
          note,
          isAllowedToEnterHome: 0,
          isDisadvantaged: 0,
        },
      }, 'AMAZON_COGNITO_USER_POOLS');

      elderId = id;

      // Update elder tags
      global.logger.debug('elderHealthTags', elderHealthTags);
      await Promise.all((elderHealthTags || []).map(async (tagItem) => {
        if (tagItem && tagItem.id) {
          const joinData = {
            tagId: tagItem.id,
            elderId,
            createdBy: username,
            updatedBy: username,
          };

          await request(createElderTag, { input: joinData });
        }
      }));
    }

    const getInvoiceSetting = () => {
      if (!ENABLE_PAYMENT) {
        return;
      }
      const { type, email, carrier, title, taxIdNumber } = invoice;
      return {
        type,
        email,
        carrier: (type === 'individual')? carrier : '',
        title: type === 'enterprise' ? title : '',
        taxIdNumber: type === 'enterprise' ? taxIdNumber : '',
      };
    };

    if (ENABLE_PAYMENT) {
      const input = {
        orders: [{
          orderQuoteId,
          transactionId,
          clientId,
          restaurantId,
          elderId,
          category: '送餐',
          deliveryDatetimes: [deliveryBy],
          noteForDelivery,
          noteForMeal,
          note,
          total: mealPrice,
          mealItems: mealItems.map((mealItem) => ({
            name: mealItem.name,
            price: mealItem.price,
            quantity: mealItem.quantity,
          })),
          invoiceSetting: getInvoiceSetting(),
          source: 'web',
        }],
      };
      const res = await request(clientCreateOrder, { input });
      global.logger.debug(res);
    } else {
      const input = {
        orders: [{
          orderQuoteId,
          restaurantId,
          elderId,
          category: '送餐',
          deliveryDatetimes: [deliveryBy],
          noteForDelivery,
          noteForMeal,
          note,
          total: mealPrice,
          mealItems: mealItems.map((mealItem) => ({
            name: mealItem.name,
            price: mealItem.price,
            quantity: mealItem.quantity,
          })),
        }],
        invoiceSetting: getInvoiceSetting(),
        transactionId: transactionId,
        individualId: clientId,
        facilityId: restaurant.clientId, // TODO
        source: 'web',
      };
      const res = await request(individualClientCreateOrder, { input });
      global.logger.debug(res);
    }

    cache.purge('public:menu-');
    history.push(`/client/myOrders`);
    setIsLoading(false);
  };

  const isLoggedIn = cache.get('app:username') ? true : false;
  useEffect(() => {
    if (isLoggedIn) {
      const email = cache.get('app:email');
      if (invoice.email === '') {
        setInvoice({
          ...invoice,
          email,
        });
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!order || isLoading || !restaurant) return;

    const { restaurantId, address } = order;

    if (order.errorMessage &&
      order.quoteParams &&
      order.quoteParams.addressCounty === address.county &&
      order.quoteParams.addressZipCode === address.zipCode &&
      order.quoteParams.addressDistrict === address.district &&
      order.quoteParams.addressStreet === address.street &&
      order.quoteParams.restaurantId === restaurantId
    ) {
      return;
    }

    if (order.distance) return;

    (async () => {
      setIsLoading(true);

      const params = {
        input: {
          clientId: restaurant.clientId, // TODO
          restaurantId,
          addressCounty: address.county,
          addressZipCode: address.zipCode,
          addressDistrict: address.district,
          addressStreet: address.street,
        },
      };
      const {
        data: {
          quoteOrder: {
            data: {
              orderQuoteId,
              code,
              distance,
              deliveryStaffFee,
              startAddress,
              endAddress,
              message,
            },
          },
        },
      } = await request(quoteOrder, params);

      if (code === 'SUCCESS') {
        updateOrder({
          orderQuoteId,
          distance,
          deliveryStaffFee,
          startAddress,
          endAddress,
          subTotal: order.mealPrice + deliveryStaffFee,
          errorMessage: '',
          quoteParams: {},
        });
      } else {
        global.logger.debug({ params, message });
        updateOrder({
          errorMessage: '送餐地址有誤或距離過遠，請更新後重試。',
          quoteParams: params,
        });
      }

      setIsLoading(false);
    })();
  }, [order, restaurant]);

  useEffect(() => {
    (async () => {
      const {
        id: elderId,
        name: elderName,
        phoneNumber: elderPhoneNumber,
        address,
        noteForDelivery,
        noteForMeal,
        noteForHealth,
      } = cache.get('public:menu-elder');
      const {
        restaurantId,
        mealItems,
        restaurantName,
      } = cache.get('public:menu-meals');
      const exitingOrder = cache.get('public:menu-order') || {};

      if (exitingOrder.restaurantId !== restaurantId ||
        exitingOrder.address.lat !== address.lat ||
        exitingOrder.address.lng !== address.lng) {
        exitingOrder.distance = undefined;
        exitingOrder.deliveryStaffFee = 0;
      }
      const mealPrice = mealItems.reduce((pre, { price }) => pre + price, 0);
      const order = {
        distance: undefined,
        deliveryFee: 0,
        startAddress: '',
        endAddress: '',
        date,
        timeSlot,
        deliveryBy: moment(`${date} ${timeSlot.split('-')[0]}`).tz(TIME_ZONE).toISOString(),
        ...exitingOrder,
        elderId,
        elderName,
        elderPhoneNumber,
        noteForDelivery,
        noteForMeal,
        noteForHealth,
        restaurantId,
        restaurantName,
        address,
        mealItems,
        mealPrice,
        subTotal: mealPrice + (exitingOrder.deliveryStaffFee || 0),
        errorMessage: '',
      };

      if (!order.elderName) {
        const clientId = cache.get('app:clientId');
        if (clientId) {
          const elders = await asyncListAll(getEldersByClientByStatus, {
            clientId,
            status: { eq: '使用中' },
          });
          if (elders.length > 0) {
            order.elderName = elders[0].name;
            order.elderPhoneNumber = elders[0].phoneNumber;
          }
        }
      }

      setOrder(order);
      if (exitingOrder.date) {
        setDate(exitingOrder.date);
      }
      if (exitingOrder.timeSlot) {
        setTimeSlot(exitingOrder.timeSlot);
      }

      const exitingInvoice = cache.get('public:menu-invoice');
      if (exitingInvoice) {
        setInvoice({ invoice, ...exitingInvoice });
      }
      await getRestaurantAndHolidays(restaurantId);
    })();
  }, []);

  const onInvoiceChange = (invoice) => {
    setInvoice(invoice);
    cache.set('public:menu-invoice', invoice);
  };

  if (!order) return null;

  // global.logger.debug('order', order);

  const invoiceValid = () => {
    if (invoice.type === 'individual') {
      if (invoice.email.length === 0) {
        return false;
      }
    } else if (invoice.type === 'enterprise') {
      if (invoice.email.length === 0 || invoice.title.length === 0 || invoice.taxIdNumber.length === 0) {
        return false;
      }
    }
    return true;
  };

  const timeInSlot = (startTime, endTime, toCheck) => {
    const toMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };
    return toMinutes(toCheck) >= toMinutes(startTime) && toMinutes(toCheck) <= toMinutes(endTime);
  };

  const getTimeSlotOptions = (defaultTimeSlotOptions) => {
    let filteredOptions = defaultTimeSlotOptions;
    if (!restaurant || !restaurant.openingHours ||
      Object.keys(restaurant.openingHours).every((key) => restaurant.openingHours[key].length === 0)) {
      return filteredOptions;
    }
    const day = moment(date).tz(TIME_ZONE).day();

    if (restaurant.openingHours[dayMapping[day]].length === 0) {
      return [];
    } else {
      filteredOptions = defaultTimeSlotOptions.filter((option) => {
        const [slotStart, slotEnd] = option.split('-');
        if (restaurant.openingHours[dayMapping[day]].some((openingHours)=> {
          if (timeInSlot(openingHours.startTime, openingHours.endTime, slotStart) ||
            timeInSlot(openingHours.startTime, openingHours.endTime, slotEnd)) {
            return true;
          }
          return false;
        })) {
          return true;
        }
        return false;
      });
    }

    return filteredOptions;
  };

  const lunchTimeSlotOptions = getTimeSlotOptions(TIME_OPTIONS_LUNCH);
  const dinnerTimeSlotOptions = getTimeSlotOptions(TIME_OPTIONS_DINNER);

  const restaurantClosedOnDateSelected = restaurantClosed(date);
  const restaurantClosedAtTimeSlotSelected = !lunchTimeSlotOptions.includes(timeSlot) && !dinnerTimeSlotOptions.includes(timeSlot);

  const formDateInvalid = () => {
    if (ENABLE_PAYMENT) {
      return isLoading || !order.elderName || !order.elderPhoneNumber || !agreeTerms ||
      !agreeCancelInvoice || !invoiceValid() || restaurantClosedOnDateSelected || restaurantClosedAtTimeSlotSelected;
    }
    return isLoading || !order.elderName || !order.elderPhoneNumber || restaurantClosedOnDateSelected || restaurantClosedAtTimeSlotSelected;
  };

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      {/* {order.startAddress && order.endAddress && <Grid container justifyContent="center">
        <Directions startAddress={order.startAddress} endAddress={order.endAddress} />
      </Grid>} */}
      <Grid item xs={12}>
        <Typography variant="h5" color="textPrimary" >
          訂購內容
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="body1" color="textPrimary" >
          送餐地址
        </Typography>
        <Typography variant="body1" color="textPrimary" >
          {formatAddress(order.address, { ignoreCountry: true, includeZipCode: false, ignoreNote: true })}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="body1" color="textPrimary" >
          {t('餐廳')}
        </Typography>
        <Typography variant="body1" color="textPrimary" >
          {order.restaurantName}
        </Typography>
      </Grid>
      <Grid item xs={12} container style={{ borderBottom: '1px solid #e3e3e3' }}>
        {order.mealItems.map(({ name, price, quantity }) => (
          <Grid item xs={12} container justifyContent="space-between" key={name}>
            <Typography variant="body1" color="textSecondary" >
              {name} x {quantity}
            </Typography>
            <Typography variant="body1" color="textSecondary" >
              ${price}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="body1" color="textSecondary" >
          商品小計
        </Typography>
        <Typography variant="body1" color="textSecondary" >
          ${order.mealPrice}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="body1" color="textSecondary" >
          {t('配送費用')}
        </Typography>
        {order.distance ? <Typography variant="body1" color="textSecondary" >
          {`$${order.deliveryStaffFee}`}
        </Typography> : <Typography variant="body1" color="error" >
          {'計算中'}
        </Typography>}
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="body1" color="textPrimary" >
          訂單總金額
        </Typography>
        <Typography variant="body1" color="textPrimary" >
          ${order.subTotal}
        </Typography>
      </Grid>
      {order.errorMessage &&
      <Grid item xs={12} container justifyContent="center">
        <span style={{ color: Colors.raised }}>
          {order.errorMessage}
        </span>
      </Grid>}
      {!order.errorMessage &&
      <>
        <Grid item xs={12} style={{ paddingTop: 32 }}>
          <Typography variant="h5" color="textPrimary" >
            送餐時間
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={restaurantClosedOnDateSelected}>
            <InputLabel id="date-label">
              日期
            </InputLabel>
            <Select
              fullWidth
              value={date}
              onChange={(e) => {
                updateOrderDate(e.target.value);
              }}
              autoFocus
            >
              <MenuItem disabled>{moment().format('YYYY/MM/DD')}</MenuItem>
              {DATE_OPTIONS.map((item) => {
                const disabled = restaurantClosed(item);
                return (
                  <MenuItem disabled={disabled} key={item} value={item}>
                    {disabled ? `${item} (未營業)` : item}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{restaurantClosedOnDateSelected && '請選擇餐廳營業日期'}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={restaurantClosedAtTimeSlotSelected}>
            <InputLabel id="date-label">
              時段
            </InputLabel>
            <Select
              fullWidth
              value={timeSlot}
              onChange={(e) => {
                updateOrderTimeSlot(e.target.value);
              }}
            >
              <MenuItem disabled>午餐</MenuItem>
              {lunchTimeSlotOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem disabled>晚餐</MenuItem>
              {dinnerTimeSlotOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{restaurantClosedAtTimeSlotSelected && '請選擇餐廳營業時段'}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 32 }}>
          <Typography variant="h5" color="textPrimary" >
            送餐資料
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="收餐者姓名"
            type="text"
            value={order.elderName}
            onChange={(e) => {
              updateOrder({ elderName: e.target.value });
              const cachedElder = cache.get('public:menu-elder');
              cache.set('public:menu-elder', { ...cachedElder, name: e.target.value });
            }}
            fullWidth
            required
            error={!order.elderName}
            helperText={!order.elderName ? '必填' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('收餐者電話')}
            type="text"
            value={order.elderPhoneNumber}
            onChange={(e) => {
              updateOrder({ elderPhoneNumber: e.target.value });
              const cachedElder = cache.get('public:menu-elder');
              cache.set('public:menu-elder', { ...cachedElder, phoneNumber: e.target.value });
            }}
            fullWidth
            required
            error={!order.elderPhoneNumber}
            helperText={!order.elderPhoneNumber ? '必填' : ''}
          />
        </Grid>
        {ENABLE_PAYMENT &&<Grid item xs={12}>
          <DataJoinEditorInput
            title={'長輩是否有下列慢性病'}
            mode={'送餐對象-健康'}
            joinData={[]}
            defaultValues={[]}
            onChange={(newTags) => updateOrder({ elderHealthTags: newTags })}
            onUpdateOptions={()=>{}}
            disabled={isLoading}
            showHelperText={false}
            freeSolo={false}
            // variant="outlined"
            fullWidth
            loadOnOpen={true}
          />
        </Grid>}
        {ENABLE_PAYMENT &&<Grid item xs={12}>
          <TextField
            label="長輩其他健康備註"
            type="text"
            value={order.noteForHealth}
            onChange={(e) => {
              updateOrder({ noteForHealth: e.target.value });
              const cachedElder = cache.get('public:menu-elder');
              cache.set('public:menu-elder', { ...cachedElder, noteForHealth: e.target.value });
            }}
            fullWidth
            multiline
          />
        </Grid>}
        {ENABLE_PAYMENT &&<Grid item xs={12}>
          <TextField
            label="長輩飲食禁忌（是否有不能吃的食物）"
            type="text"
            value={order.noteForMeal}
            onChange={(e) => {
              updateOrder({ noteForMeal: e.target.value });
              const cachedElder = cache.get('public:menu-elder');
              cache.set('public:menu-elder', { ...cachedElder, noteForMeal: e.target.value });
            }}
            fullWidth
            multiline
          />
        </Grid>}
        <Grid item xs={12}>
          <TextField
            label={`${t('送餐')}備註`}
            type="text"
            value={order.noteForDelivery}
            onChange={(e) => {
              updateOrder({ noteForDelivery: e.target.value });
              const cachedElder = cache.get('public:menu-elder');
              cache.set('public:menu-elder', { ...cachedElder, noteForDelivery: e.target.value });
            }}
            fullWidth
            multiline
          />
        </Grid>
        {isLoggedIn && ENABLE_PAYMENT && (
          <>
            <Grid item xs={12} container justifyContent="flex-start" style={{ paddingTop: 32 }}>
              <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" >
                  發票資料
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InvoiceInput
                  data={invoice}
                  onChange={onInvoiceChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start" style={{ paddingTop: 16 }}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreeCancelInvoice"
                      checked={agreeCancelInvoice}
                      onChange={(e) => {
                        setAgreeCancelInvoice(e.target.checked);
                      }}
                    />}
                  label={
                    <div style={{ fontSize: 14 }}>
                      我同意辦理退貨時由銀色大門代為處理電子發票以加速退貨退款作業
                    </div>}
                />
              </Grid>
              {!agreeCancelInvoice && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="error" >
                    請您同意辦理退貨時由銀色大門代為處理電子發票以加速退貨退款作業
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreeTerms"
                      checked={agreeTerms}
                      onChange={(e) => {
                        setAgreeTerms(e.target.checked);
                      }}
                    />}
                  label={
                    <div style={{ fontSize: 14 }}>
                      我同意
                      <Link
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener"
                        variant="body2"
                        underline="always"
                      >
                        隱私權及保護政策
                      </Link>
                      與
                      <Link
                        href="/return-policy"
                        target="_blank"
                        rel="noopener"
                        variant="body2"
                        underline="always"
                      >
                        退貨退款政策
                      </Link>
                    </div>}
                />
              </Grid>
              {!agreeTerms && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="error" >
                    請您同意私權及保護政策與退貨退款政策
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start" style={{ paddingTop: 32 }}>
              <Typography variant="h5" color="textPrimary" >
                付款方式
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={12} container justifyContent="center">
                <LinePayButton
                  disabled={formDateInvalid()}
                  order={{
                    subTotal: order.subTotal,
                    amount: order.subTotal,
                    discount: 0,
                    discountRule: undefined,
                    description: '網頁訂單',
                    category: '送餐',
                    items: [...order.mealItems.map(({ name, quantity, unitValue, price }) => ({
                      name,
                      qty: quantity,
                      unit: '份',
                      unitValue,
                      subTotal: price,
                    })),
                    {
                      name: '送餐費用',
                      qty: 1,
                      unit: '次',
                      unitValue: order.deliveryStaffFee,
                      subTotal: order.deliveryStaffFee,
                    }],
                  }}
                  redirectPath={'/menu'}
                  onComplete={handleComplete}
                  reportLoading={(value) => {
                    setIsLoading(value);
                  }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center" style={{ paddingTop: 16 }}>
                <NewebPayButton
                  disabled={formDateInvalid()}
                  order={{
                    subTotal: order.subTotal,
                    amount: order.subTotal,
                    discount: 0,
                    discountRule: undefined,
                    description: '網頁訂單',
                    category: '送餐',
                    items: [...order.mealItems.map(({ name, quantity, unitValue, price }) => ({
                      name,
                      qty: quantity,
                      unit: '份',
                      unitValue,
                      subTotal: price,
                    })),
                    {
                      name: '送餐費用',
                      qty: 1,
                      unit: '次',
                      unitValue: order.deliveryStaffFee,
                      subTotal: order.deliveryStaffFee,
                    }],
                  }}
                  redirectPath={'/menu'}
                  clientBackPath={'/menu'}
                  email={invoice.email}
                  onComplete={handleComplete}
                  reportLoading={(value) => {
                    setIsLoading(value);
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12} container justifyContent="space-evenly" style={{ paddingTop: 16 }}>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            onClick={() => onStepBack()}
            disabled={isLoading}
          >
            <NavigateBeforeIcon />
            重新選擇餐點
          </Button>
          <CashButton
            disabled={formDateInvalid() || !isLoggedIn}
            onComplete={handleComplete}
            reportLoading={(value) => {
              setIsLoading(value);
            }}
            hidden
          />
        </Grid>
        {!isLoggedIn && (
          <Grid item xs={12} container justifyContent="center" style={{ paddingTop: 32 }}>
            <CustomAuthDialog
              mode= 'signIn'
              withButton={false}
              button={(props) => (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  {...props}
                >
                  登入
                </Button>
              )}
            />
            &nbsp;
            <CustomAuthDialog
              mode= 'signUp'
              withButton={false}
              button={(props) => (
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  {...props}
                >
                  還不是會員？點此註冊
                </Button>
              )}
            />
          </Grid>
        )}
      </>
      }
    </Grid>
  );
}

CompleteOrder.propTypes = {
  formData: PropTypes.object,
  onComplete: PropTypes.func,
  onStepBack: PropTypes.func,
};
