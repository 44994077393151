export default [
  {
    label: '付費',
    value: 'payment',
  },
  {
    label: '返款',
    value: 'refund',
  },
  {
    label: '調整',
    value: 'credit',
  },
];
