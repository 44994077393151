import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ReactJson from 'react-json-view';

import DataForm from 'forms/DataForm';
import { getOrderQuote } from 'graphql/queries';
import { quoteOrder } from 'graphql/mutations';
import { request } from 'utilities/graph';
import OrderQuoteTable from '../OrderQuoteTable';

import schema from './schema';
import uiSchema from './uiSchema';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function OrderQuote() {
  const classes = useStyles();

  const [quoteData, setQuoteData] = useState(null);

  const getQuote = async ({ restaurantId, address = {} }) => {
    const params = {
      input: {
        restaurantId,
        addressCounty: address.county,
        addressZipCode: address.zipCode,
        addressDistrict: address.district,
        addressStreet: address.street,
      },
    };
    const {
      data: {
        quoteOrder: {
          data: {
            orderQuoteId,
          },
        },
      },
    } = await request(quoteOrder, params);

    const {
      data: {
        getOrderQuote: data,
      },
    } = await request(getOrderQuote, { id: orderQuoteId });

    setQuoteData(data);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.container}>
            <DataForm
              schema={schema}
              uiSchema={uiSchema}
              createFunc={getQuote}
              updateFunc={getQuote}
              // onComplete={getQuote}
              dirty={true}
            >
            </DataForm>
          </Paper>
        </Grid>

        {quoteData &&
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <OrderQuoteTable
            title={'估價結果'}
            data={[quoteData]}
            padding={0}
            showQueryGenerator={false}
            indexes={[]}
            queryDefaultParams={{}}
          />
        </Grid>}

        {quoteData &&
        <Grid item xs={12}>
          <Paper className={classes.container}>
            <ReactJson
              src={quoteData}
              name={null}
              displayDataTypes={false}
            />
          </Paper>
        </Grid>}
      </Grid>
    </Container>
  );
}

OrderQuote.propTypes = {};
