import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import DataForm from 'forms/DataForm';
import { request, asyncListAll } from 'utilities/graph';
import {
  getClientOrganizationByOrganization,
} from 'graphql/queries';
import {
  adminUpdateUserGroup,
} from 'graphql/mutations';
import schema from './schema.js';
import uiSchema from './uiSchema';

export default function UserForm({ formData: inFormData, ...props }) {
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [formData, setFormData] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const updateFacilityOptions = async (organizationId, facilityIds = []) => {
    if (!organizationId) {
      setFacilityOptions([]);
    }
    const clientOrganization = await asyncListAll(getClientOrganizationByOrganization, {
      organizationId,
    });
    const newFacilityOptions = clientOrganization.reduce((accumulator, currentValue) => {
      if (currentValue.client && currentValue.client.type === 'facility') {
        accumulator.push({
          id: currentValue.client.id,
          name: currentValue.client.name,
          isSelected: facilityIds.includes(currentValue.client.id),
        });
      }
      return accumulator;
    }, []);
    setFacilityOptions(newFacilityOptions);
  };

  useEffect(() => {
    (async () => {
      setOrganizationId(inFormData.organizationId);
      if (inFormData.organizationId) {
        await updateFacilityOptions(inFormData.organizationId, inFormData.facilityIds);
      }
      setFormData({
        ...inFormData,
        ownedFacilityName: (inFormData.ownedFacilityName || []).join(', '),
      });
    })();
  }, [inFormData]);

  const updateFunc = async (data) => {
    setIsLoading(true);
    const {
      username,
      isActive,
      organizationId,
      deliveryStaffId,
      restaurantOwnerId,
      clientId,
      isAdmin = false,
      isClient = false,
      isRestaurantOwner = false,
      isDeliveryStaff = false,
      ownedFacilityName,
      isFacilityAdmin: newIsFacilityAdmin = false,
    } = data;

    const facilityIds = facilityOptions.filter(({ isSelected }) => isSelected).map(({ id }) => id);
    const isOrgAdmin = !!organizationId && organizationId !== 'N/A';
    const isFacilityAdmin = facilityIds.length !== 0 || (ownedFacilityName && ownedFacilityName.length !== 0) || newIsFacilityAdmin;
    await request(adminUpdateUserGroup, {
      input: {
        username,
        isActive,
        organizationId,
        facilityIds,
        deliveryStaffId,
        restaurantOwnerId,
        clientId,
        isAdmin,
        isOrgAdmin,
        isFacilityAdmin,
        isClient,
        isRestaurantOwner,
        isDeliveryStaff,
      },
    });

    setIsLoading(false);

    return {
      ...data,
      facilityIds,
      isOrgAdmin,
      isFacilityAdmin,
    };
  };

  const onChange = async (data) => {
    const {
      organizationId: newOrganizationId,
    } = data;

    setDirty(true);
    if (!newOrganizationId) {
      setFacilityOptions([]);
      return;
    }

    if (newOrganizationId !== organizationId) {
      updateFacilityOptions(newOrganizationId);
      setOrganizationId(newOrganizationId);
    }
  };

  if (!formData) {
    return null;
  }

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={updateFunc}
      updateFunc={updateFunc}
      onChange={onChange}
      formData={formData}
      dirty={dirty}
      {...props}
    >
      { facilityOptions.length > 0 &&
        <div style={{ paddingBottom: 32 }}>
          選擇機構：
          {
            facilityOptions.map((facility, index) => (
              <FormControlLabel
                key={facility.id}
                control={
                  <Checkbox
                    name={facility.name}
                    checked={facility.isSelected}
                    value={facility.isSelected}
                    onChange={(e) => {
                      const newFacilityOptions = JSON.parse(JSON.stringify(facilityOptions));
                      const facilityChanged = newFacilityOptions.find(({ id }) => id === facility.id);
                      facilityChanged.isSelected = (e.target.checked);
                      setFacilityOptions(newFacilityOptions);
                      setDirty(true);
                    }}
                    disabled={isLoading}
                  />}
                label={facility.name}
              />
            ))
          }
        </div>
      }
    </DataForm>
  );
}

UserForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
