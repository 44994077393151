import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { resetFormCache } from 'forms/formCache';
import { request } from 'utilities/graph';
import { getClientsByShortId } from 'graphql/queries';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import RestaurantSelection from '../RestaurantSelection';
import DeliveryStaffSelection from '../DeliveryStaffSelection';
import { createClient, updateClient, createFacilitySettings } from 'graphql/mutations';
import moment from 'moment';
import cache from 'utilities/cache';
import uiSchema from './uiSchema';

export default function FacilityClientForm({ formData: inFormData, ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const [orderableRestaurants, setOrderableRestaurants] = useState([]);
  const [assignableDeliveryStaffs, setAssignableDeliveryStaffs] = useState([]);

  useEffect(() => {
    if (inFormData?.orderableRestaurants) {
      setOrderableRestaurants(inFormData.orderableRestaurants);
    }
    if (inFormData?.assignableDeliveryStaffs) {
      setAssignableDeliveryStaffs(inFormData.assignableDeliveryStaffs);
    }
  }, [inFormData]);

  const getClientShortId = async () => {
    let shortId;
    while (!shortId) {
      const tempShortId = (Math.floor(Math.random() * 900000) + 100000).toString();
      const { data: { getClientsByShortId: { items } } } = await request(getClientsByShortId, { shortId: tempShortId });
      if (items.length === 0) {
        shortId = tempShortId;
      }
    }
    return shortId;
  };

  const createFunc = async (data) => {
    data.county = data.county || data.address.county;
    data.shortId = await getClientShortId();
    data.orderableRestaurants = orderableRestaurants;
    data.assignableDeliveryStaffs = assignableDeliveryStaffs;
    const now = moment().toISOString();
    const username = cache.get('app:username');
    const { data: { createFacilitySettings: facilitySettings } } = await request(createFacilitySettings, { input: {
      createdAt: now,
      createdBy: username,
      updatedAt: now,
      updatedBy: username,
    } });
    data.facilitySettingsId = facilitySettings.id;
    // backward compatibility
    data.useDedicatedDeliveryStaffs = data.featureConfiguration.useDedicatedDeliveryStaffs || false;
    await request(createClient, { input: data });
    resetFormCache('allFacilities');
    resetFormCache('allClients');
  };

  const updateFunc = async (data) => {
    if (!data.shortId) {
      data.shortId = await getClientShortId();
    }
    data.county = data.county || data.address.county;
    data.orderableRestaurants = orderableRestaurants;
    data.assignableDeliveryStaffs = assignableDeliveryStaffs;
    if (!data.featureConfiguration) {
      data.featureConfiguration = {
        skipRegisterSteps: [],
        skipDocumentsUpload: false,
        hideSToken: false,
        supportNumber: '',
        useDedicatedDeliveryStaffs: false,
        dementiaQuestionnaire: false,
        reportTypes: [],
      };
    }
    // backward compatibility
    data.useDedicatedDeliveryStaffs = data.featureConfiguration.useDedicatedDeliveryStaffs || false;

    const newData = JSON.parse(JSON.stringify(data));
    delete newData.facilitySettings;

    await request(updateClient, { input: newData });
    resetFormCache('allFacilities');
    resetFormCache('allClients');
  };

  if (!inFormData) {
    return <></>;
  }

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      formData={inFormData}
      dirty={true}
      {...props}
    >
      <Typography variant="h5" style={{ marginTop: 16 }}>
        合作餐廳
      </Typography>
      <Divider />
      <Grid container spacing={2} style={{ paddingTop: 16 }}>
        <RestaurantSelection
          restaurants={orderableRestaurants}
          onChange={setOrderableRestaurants}
          description='請選擇縣市與鄉鎮市區，然後點選餐廳與向右按鈕移動到右方框格裏'
        />
      </Grid>
      <Typography variant="h5" style={{ marginTop: 16 }}>
        合作送餐大使
      </Typography>
      <Divider />
      <Grid container spacing={2} style={{ paddingTop: 16 }}>
        <DeliveryStaffSelection
          deliveryStaffs={assignableDeliveryStaffs}
          onChange={setAssignableDeliveryStaffs}
          description='請選擇縣市，然後點選送餐大使與向右按鈕移動到右方框格裏'
        />
      </Grid>
    </DataForm>
  );
}

FacilityClientForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
