import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Board from 'react-trello';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { sortOrder } from 'components/Schedule/helpers';
import { Colors } from '@silvergatedelivery/constants';
import { formatTime } from 'utilities/format';
import OrderDialog from 'components/Order/OrderDialog';
import LinkButton from 'components/Table/LinkButton';
import { orderStatus } from '@silvergatedelivery/constants';

const CARD_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  card: {
    width: CARD_WIDTH,
    backgroundColor: Colors.highlight,
    color: Colors.dark,
    padding: 8,
    marginBottom: 12,
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
  },
  cardDescription: {
    fontSize: 16,
    lineHeight: '24px',
  },
  cardIssueDescription: {
    fontSize: 14,
    color: Colors.raised,
  },
}));

function Card({ title, metadata: order, deliveryStaffName='' }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { color } = orderStatus.find(({ value }) => value === order.status);
  return (
    <Grid container className={classes.card} spacing={1}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <span className={classes.cardTitle} style={{ backgroundColor: color }}>
          {title}
        </span>
        <div>
          <OrderDialog
            id={order.id}
            mode="icon"
          />
          <div style={{ display: 'inline-block', width: 8 }} />
          <LinkButton
            path={`/order/${order.id}`}
            label="前往訂單頁面"
            newTab={true}
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.cardDescription}>
        {t('餐廳')}：{order.restaurant.name} <br/>
        大使：{deliveryStaffName}
      </Grid>
    </Grid>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metadata: PropTypes.any,
  deliveryStaffName: PropTypes.string,
};

export default function BoardView({
  orders = [],
  dates = [],
  deliveryStaffOptions = [],
}) {
  const classes = useStyles();
  const [lanes, setLanes] = useState();

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const data = [
      ...dates.map((date) => {
        const matchedOrders = orders
          .filter((order) => order.date === date)
          .sort(sortOrder);

        return {
          id: date,
          title: moment(date).format('MM-DD dddd'),
          label: `${matchedOrders.length}`,
          cards: matchedOrders.map((order) => {
            let deliveryStaffName;
            if (order.deliveryStaffId) {
              deliveryStaffName = (deliveryStaffOptions.find(({ value }) => value === order.deliveryStaffId) || {}).label;
            }
            return {
              id: order.id,
              title: `${formatTime(order.deliveryBy)} ${order.elder.name}`,
              description: `${order.restaurant.name}`,
              metadata: order,
              deliveryStaffName,
            };
          }),
          editLaneTitle: false,
          disallowAddingCard: true,
          hideCardDeleteIcon: true,
          droppable: false,
          style: {
            color: '#fff',
            backgroundColor: today === date ? '#4169E1' : '#708090',
            minWidth: CARD_WIDTH + 10,
            width: CARD_WIDTH + 10,
            height: 'calc(100vh - 220px)',
          },
        };
      }),
    ];
    setLanes(data);
  }, [orders]);

  if (!lanes) return null;

  return (
    <Grid container className={classes.root}>
      {orders.length > 0 &&
      <Grid item xs={12} style={{ width: 100 }} container alignItems="flex-start">
        <Board
          id="today-order-board-view"
          draggable={true}
          laneDraggable={true}
          cardDraggable={false}
          editable={false}
          canAddLanes={false}
          hideCardDeleteIcon={true}
          editLaneTitle={false}
          data={{ lanes }}
          style={{
            backgroundColor: Colors.background.light,
          }}
          components={{
            Card: (args) => {
              return <Card {...args} />;
            },
          }}
        />
      </Grid>}
    </Grid>);
}

BoardView.propTypes = {
  orders: PropTypes.array,
  dates: PropTypes.array,
  deliveryStaffOptions: PropTypes.array,
};
