import React from 'react';
import PropTypes from 'prop-types';

import AdminRecurringOrderEditButton from './AdminRecurringOrderEditButton';

export default function AdminRecurringOrderButtonFunc({ mode = 'edit', ...props }) {
  const title = mode === 'edit' ? '修改週期性排單' : '新增週期性排單';

  return (
    <AdminRecurringOrderEditButton
      title={title}
      mode={mode}
      exitConfirm={true}
      {...props}
    />);
}

AdminRecurringOrderButtonFunc.propTypes = {
  mode: PropTypes.string,
};
