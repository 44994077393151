import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { getMembership } from 'graphql/queries';
import { createMembership, updateMembership } from 'graphql/mutations';
import schema from './schema.js';
import uiSchema from './uiSchema';

export default function MembershipForm({ ...props }) {
  const [id, setId] = useState();

  const onComplete = async () => {
    if (props.onComplete) {
      const { data: { getMembership: data } } = await request(getMembership, { id });

      props.onComplete(data);
    }
  };

  const createFunc = async (data) => {
    await request(createMembership, { input: data });
    await onComplete();
  };

  const updateFunc = async (data) => {
    await request(updateMembership, { input: data });
    await onComplete();
  };

  useEffect(() => {
    if (props.formData && props.formData.id) {
      setId(props.formData.id);
    } else {
      setId(uuidv1());
    }
  }, [props.formData]);

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    >
    </DataForm>
  );
}

MembershipForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
