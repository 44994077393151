const openingHoursUiSchema = {
  'items': {
    'startTime': {
      'ui:width': '50%',
    },
    'endTime': {
      'ui:width': '50%',
    },
    'ui:title': '',
  },
  'ui:options': {
    'orderable': false,
  },
};

const uiSchema = {
  'isActive': {
    'ui:width': '40%',
  },
  'isPublic': {
    'ui:width': '30%',
  },
  'freeDelivery': {
    'ui:width': '30%',
  },
  'restaurantOwnerId': {
    'ui:width': '33%',
  },
  'clientId': {
    'ui:width': '33%',
  },
  'category': {
    'ui:width': '33%',
  },
  'name': {
    'ui:width': '50%',
  },
  'phoneNumber': {
    'ui:width': '50%',
  },
  'description': {
    'ui:width': '100%',
  },
  'openingHours': {
    'monday': openingHoursUiSchema,
    'tuesday': openingHoursUiSchema,
    'wednesday': openingHoursUiSchema,
    'thursday': openingHoursUiSchema,
    'friday': openingHoursUiSchema,
    'saturday': openingHoursUiSchema,
    'sunday': openingHoursUiSchema,
  },
};

export default uiSchema;
