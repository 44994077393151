import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MealDateSelector from 'components/Date/MealDateSelector';

const DateSettings = ({ onUpdate, defaultValue }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h6' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
          您想訂購哪天的餐點？
        </Typography>
        <Typography variant='subtitle1' color='textPrimary'>
          為了維持優良的餐點品質，訂餐皆採預約制，謝謝您的體諒與配合！
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ border: '2px solid #00913A', borderRadius: '15px', marginTop: 16 }}>
        <MealDateSelector
          defaultSelectedDays={defaultValue.days}
          unlockDates={false}
          excludeToday={true}
          openingHours={{}}
          holidays={[]}
          onChange={(items)=>{
            onUpdate({ days: items });
          }}
          dateOnly
        />
      </Grid>
    </Grid>
  );
};

export default DateSettings;

DateSettings.propTypes = {
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.any,
};
