
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { createDementiaQuestionnaire, updateDementiaQuestionnaire } from 'graphql/mutations';
import uiSchema from './uiSchema';

export default function DementiaQuestionnaireForm({ ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const createFunc = async (data) => {
    await request(createDementiaQuestionnaire, { input: data });
  };

  const updateFunc = async (data) => {
    await request(updateDementiaQuestionnaire, { input: data });
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

DementiaQuestionnaireForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
