/* eslint-disable quotes, comma-dangle */
import {
  processStatus,
} from '@silvergatedelivery/constants';

export default {
  "title": "捐贈紀錄",
  "type": "object",
  "required": [
    "status", "clientId",
    "count", "unit", "unitValue",
    "totalValue"
  ],
  "properties": {
    "status": {
      "type": "string",
      "title": "狀態",
      'enum': processStatus.map(({ value }) => value),
      'enumNames': processStatus.map(({ label }) => label),
      'default': processStatus[0].value,
    },
    "clientId": {
      "type": "string",
      "title": "客戶"
    },
    // "username": {
    //   "type": ["string", "null"],
    //   "title": "客戶帳號"
    // },
    // "transactionId": {
    //   "type": ["string", "null"],
    //   "title": "交易紀錄編號"
    // },
    "sponsorProgramId": {
      "type": ["string", "null"],
      "title": "捐贈項目"
    },
    "count": {
      "type": "number",
      "title": "數量",
      "default": 1
    },
    "unit": {
      "type": "string",
      "title": "單位 (ex: 餐/堂/箱)",
      "default": "元"
    },
    "unitValue": {
      "type": "number",
      "title": "單位價格",
      "default": 100
    },
    "totalValue": {
      "type": "number",
      "title": "總金額",
      "default": 100
    },
    "note": {
      "type": ["string", "null"],
      "title": "備註"
    }
  }
};
