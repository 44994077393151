import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import shortuuid from 'short-uuid';

import { formatTime, formatDatetime, formatNote } from 'utilities/format';
import UserAvatar from 'components/UserAvatar';

import {
  getDeliveryStaffOrdersByOrder,
  getOrderEventsByOrderByCreatedAt,
} from './queries';
import { sortBy } from 'utilities/sorting';
import { asyncListAll } from 'utilities/graph';
import OrderEventCard from 'components/Order/OrderEventCard';
import DeliveryStaffOrderPhoto from 'components/DeliveryStaffOrderPhoto';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
}));

export default function OrderCard({ order = {} }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deliveryStaffOrder, setDeliveryStaffOrder] = useState();
  const [orderEvents, setOrderEvents] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [mealsTotal, setMealsTotal] = useState(0);

  const { deliveryBy, deliveryStaffFee, extraFee = 0, platformFee = 0 } = order;

  useEffect(() => {
    (async () => {
      const [
        deliveryStaffOrders,
        orderEvents,
      ] = await Promise.all([
        asyncListAll(getDeliveryStaffOrdersByOrder, { orderId: order.id }),
        asyncListAll(getOrderEventsByOrderByCreatedAt, { orderId: order.id }),
      ]);
      global.logger.debug({ deliveryStaffOrders, orderEvents });
      setDeliveryStaffOrder(deliveryStaffOrders.sort(sortBy('createdAt', true))[0]);
      setOrderEvents(orderEvents);
      // order 裡面沒有子女應付送餐費用, 先用全部費用減去餐費計算
      setMealsTotal(order.mealItems.reduce((pre, { price }) => {
        return pre + price;
      }, 0));
    })();
  }, [order, lastUpdatedAt]);

  return (
    <Card style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} onClick={()=>setLastUpdatedAt(Date.now())}>
          <Typography variant="body1" color="primary" className={classes.bold}>
            {formatDatetime(deliveryBy, { hour: false, minute: false })}
          </Typography>
          <Typography variant="h5" color="primary" className={classes.bold}>
            {formatTime(deliveryBy)} - {formatTime(moment(deliveryBy).add('59', 'minutes'))}
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            {`${t('餐點')}送達時段`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <OrderEventCard
            events={orderEvents}
            timelineOnly={true}
            hideDetails={true}
            hideSource={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Typography variant="body1" align="left" className={classes.bold}>
              訂購內容
            </Typography>
            <Typography variant="body2" align="right" color="textSecondary">
              編號 {shortuuid().fromUUID(order.id).slice(-6)}
            </Typography>
          </Grid>
          {order.mealItems.map(({ name, quantity, price }, index)=>(
            <Grid container justifyContent="space-between" key={index}>
              <Typography variant="body2" align="left" color="textSecondary">
                {name} x{quantity}
              </Typography>
              <Typography variant="body2" align="right" color="textSecondary">
                ${price}
              </Typography>
            </Grid>
          ))}
          <Grid container justifyContent="space-between">
            <Typography variant="body2" align="left" color="textSecondary">
              送餐費用
            </Typography>
            <Typography variant="body2" align="right" color="textSecondary">
              ${deliveryStaffFee + extraFee + platformFee}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" style={{ borderTop: '1px solid #cececece' }}>
            <Typography variant="body2" align="right" color="textPrimary" className={classes.bold}>
              訂單總金額： ${mealsTotal + deliveryStaffFee + extraFee + platformFee}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="body1" color="textPrimary" className={classes.bold}>
            {t('送餐大使')}
          </Typography>
          {deliveryStaffOrder ?
            <UserAvatar
              username={deliveryStaffOrder.deliveryStaff.username}
              name={deliveryStaffOrder.deliveryStaff.name}
            />:
            <Typography component="p" variant="body2" color="textSecondary">
              未指派
            </Typography>}
        </Grid>
        {deliveryStaffOrder && deliveryStaffOrder.status === 'completed' && deliveryStaffOrder.photoS3Keys &&
        <Grid item xs={12}>
          <Typography component="p" variant="body1" color="textPrimary" className={classes.bold}>
            {t('送餐關懷')}
          </Typography>
          <div style={{ height: 150 }}>
            <DeliveryStaffOrderPhoto s3Key={deliveryStaffOrder.photoS3Keys[0]} />
          </div>
          <Typography component="p" variant="body2" color="textSecondary">
            {formatNote(deliveryStaffOrder.diary || '無')}
          </Typography>
        </Grid>}
      </Grid>
    </Card>
  );
}

OrderCard.propTypes = {
  order: PropTypes.object,
};
