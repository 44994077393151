import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddressDateTimeSettingsDialog from './AddressDateTimeSettingsDialog';

const AddressDateTimeSettings = ({ onUpdate }) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <IconButton
        style={{ color: 'white' }}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        <EditIcon />
      </IconButton>
      <AddressDateTimeSettingsDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
          onUpdate && onUpdate();
        }}
      />
    </>
  );
};

export default AddressDateTimeSettings;

AddressDateTimeSettings.propTypes = {
  onUpdate: PropTypes.func,
};
