import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  TIME_ZONE,
  invoiceStatus,
  invoiceTypes,
} from '@silvergatedelivery/constants';

import DataTable from 'components/Table/DataTable';
import {
  listInvoices,
  getInvoicesByStatusByCreatedAt,
} from 'graphql/queries';
import LinkButton from 'components/Table/LinkButton';
import CustomStatusChip from 'components/CustomStatusChip';

const today = moment().tz(TIME_ZONE).format('YYYY-MM-DD');
global.logger.debug({
  invoiceStatus,
  invoiceTypes,
});

const defaultIndexes = [
  {
    name: 'getInvoicesByStatusByCreatedAt',
    label: '按狀態查詢',
    partitionKey: 'status',
    sortKey: 'createdAt',
    fields: [{
      label: '狀態',
      key: 'status',
      type: 'select',
      options: [...invoiceStatus],
    }, {
      label: '創建時間',
      key: 'createdAt',
      type: 'datetime',
    }],
    operation: getInvoicesByStatusByCreatedAt,
  },
  {
    name: 'scan',
    label: '掃描全部資料',
    fields: [{
      label: '創建時間',
      key: 'createdAt',
      type: 'datetime',
    }],
    operation: listInvoices,
  },
];

export default function InvoiceTable({
  title = '電子發票',
  description,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  const columns = [
    {
      name: 'id',
      label: '編號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '用戶名稱',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <CustomStatusChip value={value} mappings={invoiceStatus} />
          );
        },
      },
    },
    {
      name: 'code',
      label: '發票號碼',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'note',
      label: '狀態描述',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'type',
      label: '類型',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return invoiceTypes.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'carrier',
      label: '載具',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'title',
      label: '抬頭',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'taxIdNumber',
      label: '統編',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Transaction',
      label: '項目',
      options: {
        filter: false,
        sort: false,
        customBodyRender(Transaction) {
          return (<React.Fragment>
            {Transaction.items.map((item, index)=>(
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                {item.name} {item.unitValue}/{item.unit} * {item.qty} = ${item.subTotal}
              </div>
            ))}
          </React.Fragment>);
        },

      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'clientId',
      label: '客戶頁面',
      options: {
        filter: false,
        sort: false,
        customBodyRender(id) {
          if (!id) return null;
          return (
            <LinkButton
              path={`/client/${id}`}
              label="客戶頁面"
              newTab={true}
            />
          );
        },
      },
    },
    {
      name: 'orderId',
      label: '訂單頁面',
      options: {
        filter: false,
        sort: false,
        customBodyRender(id) {
          if (!id) return null;
          return (
            <LinkButton
              path={`/order/${id}`}
              label="訂單頁面"
              newTab={true}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    setIndexes(defaultIndexes);
    setQueryParams({
      status: 'pending',
      createdAt: {
        from: today,
        to: today,
      },
    });
  }, []);

  if (!indexes || !queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        options={options}
        // queryFunc={queryFunc}
        // editButton={DeliveryStaffTransactionEditButton}
        {...props}
      />
    </React.Fragment>);
}

InvoiceTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
