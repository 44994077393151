/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAdmin = /* GraphQL */ `
  query GetAdmin($username: String!) {
    getAdmin(username: $username) {
      username
      name
      email
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $username: String
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdmins(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        name
        email
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getAuditTrail = /* GraphQL */ `
  query GetAuditTrail($id: ID!) {
    getAuditTrail(id: $id) {
      id
      timestamp
      username
      email
      name
      groupName
      action
      note
      organizationId
      facilityId
      clientId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listAuditTrails = /* GraphQL */ `
  query ListAuditTrails(
    $filter: ModelAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditTrails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        username
        email
        name
        groupName
        action
        note
        organizationId
        facilityId
        clientId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getCard = /* GraphQL */ `
  query GetCard($id: ID!) {
    getCard(id: $id) {
      id
      username
      lastFour
      expDate
      type
      clientId
      nickname
      phoneNumber
      name
      email
      zipCode
      address
      tpId
      tpKey
      tpToken
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listCards = /* GraphQL */ `
  query ListCards(
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        lastFour
        expDate
        type
        clientId
        nickname
        phoneNumber
        name
        email
        zipCode
        address
        tpId
        tpKey
        tpToken
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      shortId
      type
      isActive
      county
      username
      name
      dba
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      phoneNumber2
      phoneNumber3
      email
      contact
      ageGroup
      birthday
      gender
      jobOccupation
      howDoYouKnowUs
      referredBy
      organizationId
      usersLimit
      eldersLimit
      note
      translation
      useDedicatedDeliveryStaffs
      featureConfiguration {
        skipRegisterSteps
        skipDocumentsUpload
        hideSToken
        supportNumber
        useDedicatedDeliveryStaffs
        dementiaQuestionnaire
        reportTypes
        supportedPartners
      }
      basicFee
      basicDistanceInKM
      extraFeeInKM
      extraFee
      platformFee
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      facilitySettingsId
      facilitySettings {
        id
        dba
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        logoS3Keys
        deliverySetting {
          canStartDeliveryBefore
          canStartDeliveryAfter
          canCompleteOrderAfter
          canModifyDiaryAfter
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      orderableRestaurants {
        id
        isPublic
      }
      assignableDeliveryStaffs
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientMonthlyStatement = /* GraphQL */ `
  query GetClientMonthlyStatement($id: ID!) {
    getClientMonthlyStatement(id: $id) {
      id
      clientName
      clientId
      county
      month
      completedOrderCount
      incompleteOrderCount
      lunchCount
      incompleteLunchCount
      dinnerCount
      incompleteDinnerCount
      orderCountByIdentities {
        disadvantagedTypes
        serviceCode
        gender
        orderCount
        lunchCount
        dinnerCount
      }
      totalCost
      incompleteTotalCost
      total
      incompleteTotal
      elderStatistics {
        id
        gender
        county
        district
        disadvantagedTypes
        serviceCode
        disabilityLevel
        orderCount
        incompleteOrderCount
        lunchCount
        incompleteLunchCount
        dinnerCount
        incompleteDinnerCount
      }
      deliveryStaffIds
      deliveryStaffFee
      deliveryPlatformCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClientMonthlyStatements = /* GraphQL */ `
  query ListClientMonthlyStatements(
    $filter: ModelClientMonthlyStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientMonthlyStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientName
        clientId
        county
        month
        completedOrderCount
        incompleteOrderCount
        lunchCount
        incompleteLunchCount
        dinnerCount
        incompleteDinnerCount
        orderCountByIdentities {
          disadvantagedTypes
          serviceCode
          gender
          orderCount
          lunchCount
          dinnerCount
        }
        totalCost
        incompleteTotalCost
        total
        incompleteTotal
        elderStatistics {
          id
          gender
          county
          district
          disadvantagedTypes
          serviceCode
          disabilityLevel
          orderCount
          incompleteOrderCount
          lunchCount
          incompleteLunchCount
          dinnerCount
          incompleteDinnerCount
        }
        deliveryStaffIds
        deliveryStaffFee
        deliveryPlatformCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientOrganization = /* GraphQL */ `
  query GetClientOrganization($id: ID!) {
    getClientOrganization(id: $id) {
      id
      organizationId
      clientId
      visitorMode
      clientMode
      elderSetting
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClientOrganizations = /* GraphQL */ `
  query ListClientOrganizations(
    $filter: ModelClientOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        clientId
        visitorMode
        clientMode
        elderSetting
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientUser = /* GraphQL */ `
  query GetClientUser($clientId: ID!, $username: String!) {
    getClientUser(clientId: $clientId, username: $username) {
      clientId
      username
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClientUsers = /* GraphQL */ `
  query ListClientUsers(
    $clientId: ID
    $username: ModelStringKeyConditionInput
    $filter: ModelClientUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClientUsers(
      clientId: $clientId
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientId
        username
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($id: ID!) {
    getConfiguration(id: $id) {
      id
      status
      category
      key
      value
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        category
        key
        value
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDataMigration = /* GraphQL */ `
  query GetDataMigration($name: String!) {
    getDataMigration(name: $name) {
      name
      notes
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDataMigrations = /* GraphQL */ `
  query ListDataMigrations(
    $name: String
    $filter: ModelDataMigrationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataMigrations(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        notes
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeleteMyAccountRequest = /* GraphQL */ `
  query GetDeleteMyAccountRequest($id: ID!) {
    getDeleteMyAccountRequest(id: $id) {
      id
      username
      appName
      requestedAt
      status
      reason
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeleteMyAccountRequests = /* GraphQL */ `
  query ListDeleteMyAccountRequests(
    $filter: ModelDeleteMyAccountRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeleteMyAccountRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        appName
        requestedAt
        status
        reason
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryGroup = /* GraphQL */ `
  query GetDeliveryGroup($id: ID!) {
    getDeliveryGroup(id: $id) {
      id
      clientId
      isActive
      name
      description
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeliveryGroups = /* GraphQL */ `
  query ListDeliveryGroups(
    $filter: ModelDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaff = /* GraphQL */ `
  query GetDeliveryStaff($id: ID!) {
    getDeliveryStaff(id: $id) {
      id
      username
      isActive
      county
      clientId
      name
      nickname
      identificationCardId
      birthday
      gender
      phoneNumber
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      jobOccupation
      bankAccount {
        name
        number
      }
      whyDoYouWantToJoin
      experience
      selfInfroduction
      howDoYouKnowUs
      deliveryArea {
        county
        district
        street
        zipCode
        lat
        lng
        rangeInKilometer
      }
      documents {
        name
        s3Key
        uploadedAt
        note
      }
      interestedCounties
      tier
      balance
      totalEarnedSToken
      sTokenLastMonth
      completedOrderCount
      sTokenReportedAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeliveryStaffs = /* GraphQL */ `
  query ListDeliveryStaffs(
    $filter: ModelDeliveryStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffMonthlyStatement = /* GraphQL */ `
  query GetDeliveryStaffMonthlyStatement($id: ID!) {
    getDeliveryStaffMonthlyStatement(id: $id) {
      id
      username
      deliveryStaffId
      month
      earnedSToken
      completedOrderCount
      completedOrderDistanceInMeters
      completedOrderDurationInSeconds
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeliveryStaffMonthlyStatements = /* GraphQL */ `
  query ListDeliveryStaffMonthlyStatements(
    $filter: ModelDeliveryStaffMonthlyStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryStaffMonthlyStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        month
        earnedSToken
        completedOrderCount
        completedOrderDistanceInMeters
        completedOrderDurationInSeconds
        incompleteOrderCount
        abandonedOrderCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrder = /* GraphQL */ `
  query GetDeliveryStaffOrder($id: ID!) {
    getDeliveryStaffOrder(id: $id) {
      id
      deliveryStaffId
      orderId
      clientId
      elderId
      status
      county
      category
      deliveryBy
      mealSlot
      distanceInKilometer
      timeInMilliseconds
      deliveryAcceptedAt
      deliveryStartedAt
      deliveryDeliveredAt
      deliveryCompletedAt
      deliveryAbandonedAt
      deliveryAbandonedReason
      deliveryPosition {
        accuracy
        altitude
        altitudeAccuracy
        heading
        latitude
        longitude
        speed
      }
      deliveryPositionUpdatedAt
      deliveryStaffFee
      diary
      checkListIsComplete
      deliveryResult
      checkListReminder
      checkListTransfer
      checkListChat
      photoS3Keys
      notes
      favorite
      hasIssue
      isPublished
      publishedUrl
      blogId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elderDementiaSurvey {
        items {
          id
          elderId
          clientId
          deliveryStaffOrderId
          questionId
          response
          date
          createdAt
          createdBy
          updatedAt
          updatedBy
          dementiaQuestionnaire {
            items {
              id
              isActive
              category
              type
              question
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        nextToken
      }
      issue {
        items {
          id
          priority
          status
          reportedBy
          county
          type
          summary
          description
          note
          assignee
          orderId
          elderId
          clientId
          deliveryStaffId
          deliveryStaffOrderId
          expirationUnixTime
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeliveryStaffOrders = /* GraphQL */ `
  query ListDeliveryStaffOrders(
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryStaffOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffStatement = /* GraphQL */ `
  query GetDeliveryStaffStatement($id: ID!) {
    getDeliveryStaffStatement(id: $id) {
      id
      username
      deliveryStaffId
      type
      date
      balance
      totalEarnedSToken
      totalCompletedOrderCount
      earnedSToken
      completedOrderCount
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeliveryStaffStatements = /* GraphQL */ `
  query ListDeliveryStaffStatements(
    $filter: ModelDeliveryStaffStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryStaffStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        type
        date
        balance
        totalEarnedSToken
        totalCompletedOrderCount
        earnedSToken
        completedOrderCount
        incompleteOrderCount
        abandonedOrderCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffTransaction = /* GraphQL */ `
  query GetDeliveryStaffTransaction($id: ID!) {
    getDeliveryStaffTransaction(id: $id) {
      id
      username
      deliveryStaffId
      status
      type
      amount
      summary
      note
      orderId
      deliveryStaffOrderId
      deliveryStaffMonthlyStatmentId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDeliveryStaffTransactions = /* GraphQL */ `
  query ListDeliveryStaffTransactions(
    $filter: ModelDeliveryStaffTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryStaffTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        status
        type
        amount
        summary
        note
        orderId
        deliveryStaffOrderId
        deliveryStaffMonthlyStatmentId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDementiaQuestionnaire = /* GraphQL */ `
  query GetDementiaQuestionnaire($id: ID!) {
    getDementiaQuestionnaire(id: $id) {
      id
      isActive
      category
      type
      question
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDementiaQuestionnaires = /* GraphQL */ `
  query ListDementiaQuestionnaires(
    $filter: ModelDementiaQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDementiaQuestionnaires(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        category
        type
        question
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDirectionCache = /* GraphQL */ `
  query GetDirectionCache($id: ID!) {
    getDirectionCache(id: $id) {
      id
      startAddress
      endAddress
      startCoordinates
      endCoordinates
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listDirectionCaches = /* GraphQL */ `
  query ListDirectionCaches(
    $filter: ModelDirectionCacheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectionCaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startAddress
        endAddress
        startCoordinates
        endCoordinates
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getElder = /* GraphQL */ `
  query GetElder($id: ID!) {
    getElder(id: $id) {
      id
      username
      status
      clientId
      county
      deliveryGroupId
      sortOrder
      name
      nickname
      birthday
      gender
      phoneNumber
      phoneNumber2
      phoneNumber3
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      identificationCardId
      serviceCode
      noteForDelivery
      noteForMeal
      noteForHealth
      note
      noteOther
      preferDeliveryTimes
      isAllowedToEnterHome
      otherSocialServices
      isDisadvantaged
      relationshipToClient
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      govQualifiedCount
      govQualifiedPrice
      supervisorId
      linkGroupName
      tags {
        items {
          id
          elderId
          tagId
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
      outOfPocket
      feeCollectMethod
      disadvantagedTypes
      disadvantagedTypesHistory {
        approvedDate
        disadvantagedTypes
      }
      disabilityLevel
      cmsLevel
      caseNumber
      lunchRepeatOn
      dinnerRepeatOn
      fixedDeliveryStaffFee
      onTimeDelivery
      dementiaQuestionnaire
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listElders = /* GraphQL */ `
  query ListElders(
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getElderDeliveryGroup = /* GraphQL */ `
  query GetElderDeliveryGroup($id: ID!) {
    getElderDeliveryGroup(id: $id) {
      id
      elderId
      deliveryGroupId
      clientId
      status
      sortOrder
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listElderDeliveryGroups = /* GraphQL */ `
  query ListElderDeliveryGroups(
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElderDeliveryGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getElderDementiaSurvey = /* GraphQL */ `
  query GetElderDementiaSurvey($id: ID!) {
    getElderDementiaSurvey(id: $id) {
      id
      elderId
      clientId
      deliveryStaffOrderId
      questionId
      response
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
      dementiaQuestionnaire {
        items {
          id
          isActive
          category
          type
          question
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`;
export const listElderDementiaSurveys = /* GraphQL */ `
  query ListElderDementiaSurveys(
    $filter: ModelElderDementiaSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElderDementiaSurveys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        clientId
        deliveryStaffOrderId
        questionId
        response
        date
        createdAt
        createdBy
        updatedAt
        updatedBy
        dementiaQuestionnaire {
          items {
            id
            isActive
            category
            type
            question
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      }
      nextToken
    }
  }
`;
export const getElderTag = /* GraphQL */ `
  query GetElderTag($id: ID!) {
    getElderTag(id: $id) {
      id
      elderId
      tagId
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listElderTags = /* GraphQL */ `
  query ListElderTags(
    $filter: ModelElderTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElderTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        elderId
        tagId
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tag {
          id
          category
          subcategory
          color
          label
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($key: String!, $timestamp: AWSDateTime!) {
    getEvent(key: $key, timestamp: $timestamp) {
      key
      timestamp
      tableName
      clientId
      eventId
      eventName
      diff
      oldData
      newData
      note
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $key: String
    $timestamp: ModelStringKeyConditionInput
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      key: $key
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        timestamp
        tableName
        clientId
        eventId
        eventName
        diff
        oldData
        newData
        note
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getFacilityIndividual = /* GraphQL */ `
  query GetFacilityIndividual($id: ID!) {
    getFacilityIndividual(id: $id) {
      id
      individualId
      facilityId
      individualClient {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listFacilityIndividuals = /* GraphQL */ `
  query ListFacilityIndividuals(
    $filter: ModelFacilityIndividualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacilityIndividuals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        individualId
        facilityId
        individualClient {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getFacilitySettings = /* GraphQL */ `
  query GetFacilitySettings($id: ID!) {
    getFacilitySettings(id: $id) {
      id
      dba
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      logoS3Keys
      deliverySetting {
        canStartDeliveryBefore
        canStartDeliveryAfter
        canCompleteOrderAfter
        canModifyDiaryAfter
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listFacilitySettingss = /* GraphQL */ `
  query ListFacilitySettingss(
    $filter: ModelFacilitySettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacilitySettingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dba
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        logoS3Keys
        deliverySetting {
          canStartDeliveryBefore
          canStartDeliveryAfter
          canCompleteOrderAfter
          canModifyDiaryAfter
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrder = /* GraphQL */ `
  query GetILinkOrder($id: ID!) {
    getILinkOrder(id: $id) {
      id
      iLinkQuoteId
      iLinkOrderId
      iLinkCancelCode
      status
      description
      fee
      feeMessage
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToILinkAt
      iLinkQuotePayload
      iLinkOrderPayload
      orderId
      elderId
      restaurantId
      clientId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listILinkOrders = /* GraphQL */ `
  query ListILinkOrders(
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listILinkOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      username
      transactionId
      orderId
      status
      clientId
      deliveryStaffId
      restaurantOwnerId
      type
      email
      carrier
      title
      taxIdNumber
      code
      note
      Transaction {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sentAt
      sentBy
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        transactionId
        orderId
        status
        clientId
        deliveryStaffId
        restaurantOwnerId
        type
        email
        carrier
        title
        taxIdNumber
        code
        note
        Transaction {
          id
          username
          clientId
          deliveryStaffId
          restaurantOwnerId
          orderId
          orders {
            items {
              id
              clientId
              individualId
              restaurantId
              elderId
              date
              mealSlot
              deliveryBy
              status
              county
              countyOpenStatus
              isTemporarilyLocked
              category
              transactionId
              deliveryGroupId
              deliveryGroupSortOrder
              source
              orderQuoteId
              isAudited
              nextStatusCheckAt
              issuePriority
              issueSummary
              deliveryStaffId
              deliveryStaffOrderId
              deliveryStaffFee
              deliveryStaffFeeCalculationMethod
              tier
              tierExpiredAt
              noteForDelivery
              noteForMeal
              note
              totalCost
              total
              quantity
              subtotal
              discount
              discountCode
              discountDetails
              extraFee
              platformFee
              paymentMethod
              createdAt
              createdBy
              updatedAt
              updatedBy
              orderGroupId
              referenceId
              linkGroupElderIds
              cancellationReason
              cancellationNote
              directionServiceProvider
            }
            nextToken
          }
          status
          statusMessage
          type
          description
          note
          category
          items {
            name
            qty
            unit
            unitValue
            subTotal
          }
          serviceProvider
          subTotal
          discount
          discountRule
          amount
          cardId
          cardType
          cardLastFour
          tpMerchantId
          tpBankTxId
          tpRecTradeId
          linePayTransactionId
          linePayPaymentAccessToken
          linePayPaymentUrlWeb
          linePayPaymentUrlApp
          linePayInfo
          redirectUrl
          newebPayResult
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sentAt
        sentBy
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssue = /* GraphQL */ `
  query GetIssue($id: ID!) {
    getIssue(id: $id) {
      id
      priority
      status
      reportedBy
      county
      type
      summary
      description
      note
      assignee
      orderId
      elderId
      clientId
      deliveryStaffId
      deliveryStaffOrderId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listIssues = /* GraphQL */ `
  query ListIssues(
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssueComment = /* GraphQL */ `
  query GetIssueComment($id: ID!) {
    getIssueComment(id: $id) {
      id
      issueId
      content
      username
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listIssueComments = /* GraphQL */ `
  query ListIssueComments(
    $filter: ModelIssueCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssueComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        issueId
        content
        username
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrder = /* GraphQL */ `
  query GetLalamoveOrder($id: ID!) {
    getLalamoveOrder(id: $id) {
      id
      lalamoveDeliveryId
      quotationId
      priceBreakdown {
        base
        extraMileage
        surcharge
        totalExcludePriorityFee
        total
        currency
        priorityFee
      }
      priorityFee
      driverId
      shareLink
      status
      distance {
        value
        unit
      }
      fee
      driver {
        driverId
        name
        phone
        plateNumber
      }
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToLalamoveAt
      lalamoveQuotePayload
      lalamoveOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listLalamoveOrders = /* GraphQL */ `
  query ListLalamoveOrders(
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLalamoveOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      clientId
      status
      membershipProgramId
      expiredAt
      discountRule
      discountPercentage
      extendedAt
      transactionIds
      logs
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        status
        membershipProgramId
        expiredAt
        discountRule
        discountPercentage
        extendedAt
        transactionIds
        logs
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getMembershipProgram = /* GraphQL */ `
  query GetMembershipProgram($id: ID!) {
    getMembershipProgram(id: $id) {
      id
      isActive
      name
      description
      durationInDays
      discountRule
      discountPercentage
      unit
      unitValue
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listMembershipPrograms = /* GraphQL */ `
  query ListMembershipPrograms(
    $filter: ModelMembershipProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        name
        description
        durationInDays
        discountRule
        discountPercentage
        unit
        unitValue
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      username
      deliveryStaffId
      adminId
      clientId
      restaurantOwnerId
      type
      status
      statusMsg
      subject
      text
      dataJsonString
      link
      email
      pushToken
      phoneNumber
      shouldBeSentAt
      sentAt
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      clientId
      individualId
      restaurantId
      elderId
      date
      mealSlot
      deliveryBy
      status
      county
      countyOpenStatus
      isTemporarilyLocked
      category
      transactionId
      deliveryGroupId
      deliveryGroupSortOrder
      source
      orderQuoteId
      isAudited
      nextStatusCheckAt
      issuePriority
      issueSummary
      deliveryStaffId
      deliveryStaffOrderId
      deliveryStaffFee
      deliveryStaffFeeCalculationMethod
      tier
      tierExpiredAt
      noteForDelivery
      noteForMeal
      note
      mealItems {
        name
        quantity
        price
        cost
        note
      }
      totalCost
      total
      quantity
      subtotal
      discount
      discountCode
      discountDetails
      extraFee
      platformFee
      paymentMethod
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      orderGroupId
      referenceId
      linkGroupElderIds
      cancellationReason
      cancellationNote
      directionServiceProvider
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrderEvent = /* GraphQL */ `
  query GetOrderEvent($id: ID!) {
    getOrderEvent(id: $id) {
      id
      orderId
      category
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listOrderEvents = /* GraphQL */ `
  query ListOrderEvents(
    $filter: ModelOrderEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        category
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getOrderQuote = /* GraphQL */ `
  query GetOrderQuote($id: ID!) {
    getOrderQuote(id: $id) {
      id
      restaurantId
      restaurantName
      originalStartAddress
      originalEndAddress
      code
      message
      distance
      startAddress
      endAddress
      calculationMethod
      totalFee
      deliveryStaffFee
      extraFee
      platformFee
      fixedDeliveryStaffFee
      defaultDeliveryStaffFee
      pandagoEstimatedFee
      uberEstimatedFee
      ilinkEstimatedFee
      lalamoveEstimatedFee
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      queryAddressMethod
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const listOrderQuotes = /* GraphQL */ `
  query ListOrderQuotes(
    $filter: ModelOrderQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        restaurantId
        restaurantName
        originalStartAddress
        originalEndAddress
        code
        message
        distance
        startAddress
        endAddress
        calculationMethod
        totalFee
        deliveryStaffFee
        extraFee
        platformFee
        fixedDeliveryStaffFee
        defaultDeliveryStaffFee
        pandagoEstimatedFee
        uberEstimatedFee
        ilinkEstimatedFee
        lalamoveEstimatedFee
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        queryAddressMethod
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      clients {
        items {
          id
          organizationId
          clientId
          visitorMode
          clientMode
          elderSetting
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        clients {
          items {
            id
            organizationId
            clientId
            visitorMode
            clientMode
            elderSetting
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrder = /* GraphQL */ `
  query GetPandagoOrder($id: ID!) {
    getPandagoOrder(id: $id) {
      id
      pandagoOrderId
      status
      paymentMethod
      amount
      description
      fee
      estimatedPickupTime
      estimatedDeliveryTime
      driver {
        id
        name
        phoneNumber
      }
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToPandagoAt
      pandagoOrderPayload
      proofOfDeliveryS3Key
      trackingLink
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPandagoOrders = /* GraphQL */ `
  query ListPandagoOrders(
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPandagoOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRecurringOrder = /* GraphQL */ `
  query GetRecurringOrder($id: ID!) {
    getRecurringOrder(id: $id) {
      id
      clientId
      elderId
      deliveryGroupId
      deliveryStaffId
      restaurantId
      deliveryBy
      mealItems {
        name
        quantity
        price
        cost
        note
      }
      mealSlot
      category
      status
      repeatOn
      startOn
      endOn
      lastExecutedAt
      lastExecutedStatus
      lastExecutedStatusMessage
      createdAt
      createdBy
      updatedAt
      updatedBy
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listRecurringOrders = /* GraphQL */ `
  query ListRecurringOrders(
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrderLog = /* GraphQL */ `
  query GetRecurringOrderLog($id: ID!) {
    getRecurringOrderLog(id: $id) {
      id
      orderIds
      recurringOrderId
      deliveryBy
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listRecurringOrderLogs = /* GraphQL */ `
  query ListRecurringOrderLogs(
    $filter: ModelRecurringOrderLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringOrderLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderIds
        recurringOrderId
        deliveryBy
        date
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRestaurantOwner = /* GraphQL */ `
  query GetRestaurantOwner($id: ID!) {
    getRestaurantOwner(id: $id) {
      id
      isActive
      county
      username
      name
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      email
      howDoYouKnowUs
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listRestaurantOwners = /* GraphQL */ `
  query ListRestaurantOwners(
    $filter: ModelRestaurantOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurantOwners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        county
        username
        name
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        email
        howDoYouKnowUs
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorship = /* GraphQL */ `
  query GetSponsorship($id: ID!) {
    getSponsorship(id: $id) {
      id
      clientId
      username
      transactionId
      sponsorProgramId
      status
      count
      unit
      unitValue
      totalValue
      fulfilledCount
      fulfilledValue
      fulfilledAt
      note
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sponsorProgram {
        id
        isActive
        name
        summary
        description
        count
        unit
        unitValue
        totalValue
        previewS3Keys
        imageS3Keys
        sponsors {
          name
          description
          websiteUrl
          logoUrl
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      fulfillments {
        items {
          id
          username
          clientId
          sponsorshipId
          status
          unit
          unitValue
          fulfilledAt
          orderId
          elderId
          restaurantId
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listSponsorships = /* GraphQL */ `
  query ListSponsorships(
    $filter: ModelSponsorshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        username
        transactionId
        sponsorProgramId
        status
        count
        unit
        unitValue
        totalValue
        fulfilledCount
        fulfilledValue
        fulfilledAt
        note
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sponsorProgram {
          id
          isActive
          name
          summary
          description
          count
          unit
          unitValue
          totalValue
          previewS3Keys
          imageS3Keys
          sponsors {
            name
            description
            websiteUrl
            logoUrl
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        fulfillments {
          items {
            id
            username
            clientId
            sponsorshipId
            status
            unit
            unitValue
            fulfilledAt
            orderId
            elderId
            restaurantId
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipFulfillment = /* GraphQL */ `
  query GetSponsorshipFulfillment($id: ID!) {
    getSponsorshipFulfillment(id: $id) {
      id
      username
      clientId
      sponsorshipId
      status
      unit
      unitValue
      fulfilledAt
      orderId
      elderId
      restaurantId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listSponsorshipFulfillments = /* GraphQL */ `
  query ListSponsorshipFulfillments(
    $filter: ModelSponsorshipFulfillmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorshipFulfillments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        clientId
        sponsorshipId
        status
        unit
        unitValue
        fulfilledAt
        orderId
        elderId
        restaurantId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipProgram = /* GraphQL */ `
  query GetSponsorshipProgram($id: ID!) {
    getSponsorshipProgram(id: $id) {
      id
      isActive
      name
      summary
      description
      count
      unit
      unitValue
      totalValue
      previewS3Keys
      imageS3Keys
      sponsors {
        name
        description
        websiteUrl
        logoUrl
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listSponsorshipPrograms = /* GraphQL */ `
  query ListSponsorshipPrograms(
    $filter: ModelSponsorshipProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorshipPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        name
        summary
        description
        count
        unit
        unitValue
        totalValue
        previewS3Keys
        imageS3Keys
        sponsors {
          name
          description
          websiteUrl
          logoUrl
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      username
      clientId
      deliveryStaffId
      restaurantOwnerId
      orderId
      orders {
        items {
          id
          clientId
          individualId
          restaurantId
          elderId
          date
          mealSlot
          deliveryBy
          status
          county
          countyOpenStatus
          isTemporarilyLocked
          category
          transactionId
          deliveryGroupId
          deliveryGroupSortOrder
          source
          orderQuoteId
          isAudited
          nextStatusCheckAt
          issuePriority
          issueSummary
          deliveryStaffId
          deliveryStaffOrderId
          deliveryStaffFee
          deliveryStaffFeeCalculationMethod
          tier
          tierExpiredAt
          noteForDelivery
          noteForMeal
          note
          mealItems {
            name
            quantity
            price
            cost
            note
          }
          totalCost
          total
          quantity
          subtotal
          discount
          discountCode
          discountDetails
          extraFee
          platformFee
          paymentMethod
          direction {
            startAddress
            startLocation {
              lat
              lng
            }
            endAddress
            endLocation {
              lat
              lng
            }
            distance
            duration
            distanceInMeters
            durationInSeconds
            overviewPolyline
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          orderGroupId
          referenceId
          linkGroupElderIds
          cancellationReason
          cancellationNote
          directionServiceProvider
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
        }
        nextToken
      }
      status
      statusMessage
      type
      description
      note
      category
      items {
        name
        qty
        unit
        unitValue
        subTotal
      }
      serviceProvider
      subTotal
      discount
      discountRule
      amount
      cardId
      cardType
      cardLastFour
      tpMerchantId
      tpBankTxId
      tpRecTradeId
      linePayTransactionId
      linePayPaymentAccessToken
      linePayPaymentUrlWeb
      linePayPaymentUrlApp
      linePayInfo
      redirectUrl
      newebPayResult
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrder = /* GraphQL */ `
  query GetUberOrder($id: ID!) {
    getUberOrder(id: $id) {
      id
      uberDeliveryId
      quoteId
      status
      complete
      kind
      uuid
      liveMode
      manifestReference
      fee
      courier {
        name
        rating
        vehicleType
        vehicleMake
        vehicleModel
        vehicleColor
        phoneNumber
        imgHref
        location {
          lat
          lng
        }
      }
      trackingUrl
      undeliverableAction
      undeliverableReason
      cancelledReason
      deliverableAction
      courierNotes
      amount
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToUberAt
      uberQuotePayload
      uberOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listUberOrders = /* GraphQL */ `
  query ListUberOrders(
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUberOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUploadedOrder = /* GraphQL */ `
  query GetUploadedOrder($id: ID!) {
    getUploadedOrder(id: $id) {
      id
      clientId
      restaurantId
      orderFileS3Key
      fileName
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listUploadedOrders = /* GraphQL */ `
  query ListUploadedOrders(
    $filter: ModelUploadedOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploadedOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        restaurantId
        orderFileS3Key
        fileName
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($username: String!) {
    getUser(username: $username) {
      username
      isActive
      email
      notificationToken
      notificationTokenDeliveryStaff
      notificationTokenRestaurantOwner
      notificationTokenClient
      notificationTokenAdmin
      device {
        brand
        manufacturer
        modelName
        osName
        osVersion
        osBuildId
        appVersion
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      groups {
        items {
          username
          group
          adminId
          deliveryStaffId
          restaurantOwnerId
          clientId
          organizationId
          facilityIds
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $username: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        isActive
        email
        notificationToken
        notificationTokenDeliveryStaff
        notificationTokenRestaurantOwner
        notificationTokenClient
        notificationTokenAdmin
        device {
          brand
          manufacturer
          modelName
          osName
          osVersion
          osBuildId
          appVersion
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        adminId
        deliveryStaffId
        restaurantOwnerId
        clientId
        organizationId
        facilityIds
        groups {
          items {
            username
            group
            adminId
            deliveryStaffId
            restaurantOwnerId
            clientId
            organizationId
            facilityIds
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserGroup = /* GraphQL */ `
  query GetUserGroup($username: String!, $group: String!) {
    getUserGroup(username: $username, group: $group) {
      username
      group
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $username: String
    $group: ModelStringKeyConditionInput
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserGroups(
      username: $username
      group: $group
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        group
        adminId
        deliveryStaffId
        restaurantOwnerId
        clientId
        organizationId
        facilityIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getWebhookEvent = /* GraphQL */ `
  query GetWebhookEvent($id: ID!) {
    getWebhookEvent(id: $id) {
      id
      vendor
      headers
      body
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listWebhookEvents = /* GraphQL */ `
  query ListWebhookEvents(
    $filter: ModelWebhookEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebhookEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendor
        headers
        body
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getAuditTrailsByUsernameByTimestamp = /* GraphQL */ `
  query GetAuditTrailsByUsernameByTimestamp(
    $username: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditTrailsByUsernameByTimestamp(
      username: $username
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        username
        email
        name
        groupName
        action
        note
        organizationId
        facilityId
        clientId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getAuditTrailsByGroupNameByTimestamp = /* GraphQL */ `
  query GetAuditTrailsByGroupNameByTimestamp(
    $groupName: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditTrailsByGroupNameByTimestamp(
      groupName: $groupName
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        username
        email
        name
        groupName
        action
        note
        organizationId
        facilityId
        clientId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getAuditTrailsByOrganizationIdByTimestamp = /* GraphQL */ `
  query GetAuditTrailsByOrganizationIdByTimestamp(
    $organizationId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditTrailsByOrganizationIdByTimestamp(
      organizationId: $organizationId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        username
        email
        name
        groupName
        action
        note
        organizationId
        facilityId
        clientId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getAuditTrailsByFacilityIdByTimestamp = /* GraphQL */ `
  query GetAuditTrailsByFacilityIdByTimestamp(
    $facilityId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditTrailsByFacilityIdByTimestamp(
      facilityId: $facilityId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        username
        email
        name
        groupName
        action
        note
        organizationId
        facilityId
        clientId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getAuditTrailsByClientIdByTimestamp = /* GraphQL */ `
  query GetAuditTrailsByClientIdByTimestamp(
    $clientId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditTrailsByClientIdByTimestamp(
      clientId: $clientId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        username
        email
        name
        groupName
        action
        note
        organizationId
        facilityId
        clientId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getCardsByUsernameByCreatedAt = /* GraphQL */ `
  query GetCardsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCardsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        lastFour
        expDate
        type
        clientId
        nickname
        phoneNumber
        name
        email
        zipCode
        address
        tpId
        tpKey
        tpToken
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getCardsByClientIdByCreatedAt = /* GraphQL */ `
  query GetCardsByClientIdByCreatedAt(
    $clientId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCardsByClientIdByCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        lastFour
        expDate
        type
        clientId
        nickname
        phoneNumber
        name
        email
        zipCode
        address
        tpId
        tpKey
        tpToken
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientsByCountyByCreatedAt = /* GraphQL */ `
  query GetClientsByCountyByCreatedAt(
    $county: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientsByCountyByCreatedAt(
      county: $county
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientsByTypeByCounty = /* GraphQL */ `
  query GetClientsByTypeByCounty(
    $type: ClientType
    $county: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientsByTypeByCounty(
      type: $type
      county: $county
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientsByUsernameByType = /* GraphQL */ `
  query GetClientsByUsernameByType(
    $username: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientsByUsernameByType(
      username: $username
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientsByOrganizationByType = /* GraphQL */ `
  query GetClientsByOrganizationByType(
    $organizationId: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientsByOrganizationByType(
      organizationId: $organizationId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientsByShortId = /* GraphQL */ `
  query GetClientsByShortId(
    $shortId: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientsByShortId(
      shortId: $shortId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientMonthlyStatementsByClientIdByMonth = /* GraphQL */ `
  query GetClientMonthlyStatementsByClientIdByMonth(
    $clientId: ID
    $month: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientMonthlyStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientMonthlyStatementsByClientIdByMonth(
      clientId: $clientId
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientName
        clientId
        county
        month
        completedOrderCount
        incompleteOrderCount
        lunchCount
        incompleteLunchCount
        dinnerCount
        incompleteDinnerCount
        orderCountByIdentities {
          disadvantagedTypes
          serviceCode
          gender
          orderCount
          lunchCount
          dinnerCount
        }
        totalCost
        incompleteTotalCost
        total
        incompleteTotal
        elderStatistics {
          id
          gender
          county
          district
          disadvantagedTypes
          serviceCode
          disabilityLevel
          orderCount
          incompleteOrderCount
          lunchCount
          incompleteLunchCount
          dinnerCount
          incompleteDinnerCount
        }
        deliveryStaffIds
        deliveryStaffFee
        deliveryPlatformCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientOrganizationByOrganization = /* GraphQL */ `
  query GetClientOrganizationByOrganization(
    $organizationId: ID
    $clientId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientOrganizationByOrganization(
      organizationId: $organizationId
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        clientId
        visitorMode
        clientMode
        elderSetting
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientOrganizationByClient = /* GraphQL */ `
  query GetClientOrganizationByClient(
    $clientId: ID
    $organizationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientOrganizationByClient(
      clientId: $clientId
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        clientId
        visitorMode
        clientMode
        elderSetting
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getConfigurationByCategoryByStatus = /* GraphQL */ `
  query GetConfigurationByCategoryByStatus(
    $category: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getConfigurationByCategoryByStatus(
      category: $category
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        category
        key
        value
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getConfigurationByCategoryByKey = /* GraphQL */ `
  query GetConfigurationByCategoryByKey(
    $category: String
    $key: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getConfigurationByCategoryByKey(
      category: $category
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        category
        key
        value
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const listDeleteMyAccountRequestByUsername = /* GraphQL */ `
  query ListDeleteMyAccountRequestByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelDeleteMyAccountRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeleteMyAccountRequestByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        appName
        requestedAt
        status
        reason
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryGroupsByClientByIsActive = /* GraphQL */ `
  query GetDeliveryGroupsByClientByIsActive(
    $clientId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryGroupsByClientByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffsByCountyByCreatedAt = /* GraphQL */ `
  query GetDeliveryStaffsByCountyByCreatedAt(
    $county: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffsByCountyByCreatedAt(
      county: $county
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffsByCountyByIsActive = /* GraphQL */ `
  query GetDeliveryStaffsByCountyByIsActive(
    $county: String
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffsByCountyByIsActive(
      county: $county
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffsByIsActiveByCreatedAt = /* GraphQL */ `
  query GetDeliveryStaffsByIsActiveByCreatedAt(
    $isActive: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffsByIsActiveByCreatedAt(
      isActive: $isActive
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffsByClientIdByIsActive = /* GraphQL */ `
  query GetDeliveryStaffsByClientIdByIsActive(
    $clientId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffsByClientIdByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffsByUsername = /* GraphQL */ `
  query GetDeliveryStaffsByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffMonthlyStatementsByDeliveryStaffIdByMonth = /* GraphQL */ `
  query GetDeliveryStaffMonthlyStatementsByDeliveryStaffIdByMonth(
    $deliveryStaffId: ID
    $month: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffMonthlyStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffMonthlyStatementsByDeliveryStaffIdByMonth(
      deliveryStaffId: $deliveryStaffId
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        month
        earnedSToken
        completedOrderCount
        completedOrderDistanceInMeters
        completedOrderDurationInSeconds
        incompleteOrderCount
        abandonedOrderCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByStaffByStatus = /* GraphQL */ `
  query GetDeliveryStaffOrdersByStaffByStatus(
    $deliveryStaffId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByStaffByStatus(
      deliveryStaffId: $deliveryStaffId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByStaffByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByStaffByDate(
    $deliveryStaffId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByStaffByDate(
      deliveryStaffId: $deliveryStaffId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByStatusByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByStatusByDate(
    $status: DeliveryStaffOrderStatus
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByStatusByDate(
      status: $status
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByCountyByDeliveryBy = /* GraphQL */ `
  query GetDeliveryStaffOrdersByCountyByDeliveryBy(
    $county: String
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByCountyByDeliveryBy(
      county: $county
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByOrder = /* GraphQL */ `
  query GetDeliveryStaffOrdersByOrder(
    $orderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByOrder(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByElderByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByElderByDate(
    $elderId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByElderByDate(
      elderId: $elderId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffOrdersByClientByDate = /* GraphQL */ `
  query GetDeliveryStaffOrdersByClientByDate(
    $clientId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffOrdersByClientByDate(
      clientId: $clientId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryStaffId
        orderId
        clientId
        elderId
        status
        county
        category
        deliveryBy
        mealSlot
        distanceInKilometer
        timeInMilliseconds
        deliveryAcceptedAt
        deliveryStartedAt
        deliveryDeliveredAt
        deliveryCompletedAt
        deliveryAbandonedAt
        deliveryAbandonedReason
        deliveryPosition {
          accuracy
          altitude
          altitudeAccuracy
          heading
          latitude
          longitude
          speed
        }
        deliveryPositionUpdatedAt
        deliveryStaffFee
        diary
        checkListIsComplete
        deliveryResult
        checkListReminder
        checkListTransfer
        checkListChat
        photoS3Keys
        notes
        favorite
        hasIssue
        isPublished
        publishedUrl
        blogId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elderDementiaSurvey {
          items {
            id
            elderId
            clientId
            deliveryStaffOrderId
            questionId
            response
            date
            createdAt
            createdBy
            updatedAt
            updatedBy
            dementiaQuestionnaire {
              nextToken
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        issue {
          items {
            id
            priority
            status
            reportedBy
            county
            type
            summary
            description
            note
            assignee
            orderId
            elderId
            clientId
            deliveryStaffId
            deliveryStaffOrderId
            expirationUnixTime
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffStatementsByDeliveryStaffIdByDate = /* GraphQL */ `
  query GetDeliveryStaffStatementsByDeliveryStaffIdByDate(
    $deliveryStaffId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffStatementsByDeliveryStaffIdByDate(
      deliveryStaffId: $deliveryStaffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        type
        date
        balance
        totalEarnedSToken
        totalCompletedOrderCount
        earnedSToken
        completedOrderCount
        incompleteOrderCount
        abandonedOrderCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffStatementsByTypeByDate = /* GraphQL */ `
  query GetDeliveryStaffStatementsByTypeByDate(
    $type: DeliveryStaffStatementType
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffStatementsByTypeByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        type
        date
        balance
        totalEarnedSToken
        totalCompletedOrderCount
        earnedSToken
        completedOrderCount
        incompleteOrderCount
        abandonedOrderCount
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffTransactionByDeliveryStaffIdByCreatedAt = /* GraphQL */ `
  query GetDeliveryStaffTransactionByDeliveryStaffIdByCreatedAt(
    $deliveryStaffId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffTransactionByDeliveryStaffIdByCreatedAt(
      deliveryStaffId: $deliveryStaffId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        status
        type
        amount
        summary
        note
        orderId
        deliveryStaffOrderId
        deliveryStaffMonthlyStatmentId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffTransactionByStatusByCreatedAt = /* GraphQL */ `
  query GetDeliveryStaffTransactionByStatusByCreatedAt(
    $status: DeliveryStaffTransactionStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffTransactionByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        status
        type
        amount
        summary
        note
        orderId
        deliveryStaffOrderId
        deliveryStaffMonthlyStatmentId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDeliveryStaffTransactionBytypeByCreatedAt = /* GraphQL */ `
  query GetDeliveryStaffTransactionBytypeByCreatedAt(
    $type: DeliveryStaffTransactionType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryStaffTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryStaffTransactionBytypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        status
        type
        amount
        summary
        note
        orderId
        deliveryStaffOrderId
        deliveryStaffMonthlyStatmentId
        deliveryStaff {
          id
          username
          isActive
          county
          clientId
          name
          nickname
          identificationCardId
          birthday
          gender
          phoneNumber
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          jobOccupation
          bankAccount {
            name
            number
          }
          whyDoYouWantToJoin
          experience
          selfInfroduction
          howDoYouKnowUs
          deliveryArea {
            county
            district
            street
            zipCode
            lat
            lng
            rangeInKilometer
          }
          documents {
            name
            s3Key
            uploadedAt
            note
          }
          interestedCounties
          tier
          balance
          totalEarnedSToken
          sTokenLastMonth
          completedOrderCount
          sTokenReportedAt
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDementiaQuestionnaireByCategoryByCreatedAt = /* GraphQL */ `
  query GetDementiaQuestionnaireByCategoryByCreatedAt(
    $category: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDementiaQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDementiaQuestionnaireByCategoryByCreatedAt(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        category
        type
        question
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDementiaQuestionnaireByIsActiveByCreatedAt = /* GraphQL */ `
  query GetDementiaQuestionnaireByIsActiveByCreatedAt(
    $isActive: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDementiaQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDementiaQuestionnaireByIsActiveByCreatedAt(
      isActive: $isActive
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        category
        type
        question
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getDirectionCachesByStartCoordinatesByEndCoordinates = /* GraphQL */ `
  query GetDirectionCachesByStartCoordinatesByEndCoordinates(
    $startCoordinates: String
    $endCoordinates: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDirectionCacheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDirectionCachesByStartCoordinatesByEndCoordinates(
      startCoordinates: $startCoordinates
      endCoordinates: $endCoordinates
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startAddress
        endAddress
        startCoordinates
        endCoordinates
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getEldersByClient = /* GraphQL */ `
  query GetEldersByClient(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClient(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByClientByStatus = /* GraphQL */ `
  query GetEldersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByClientByName = /* GraphQL */ `
  query GetEldersByClientByName(
    $clientId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByName(
      clientId: $clientId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByClientByLinkGroupName = /* GraphQL */ `
  query GetEldersByClientByLinkGroupName(
    $clientId: ID
    $linkGroupName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByLinkGroupName(
      clientId: $clientId
      linkGroupName: $linkGroupName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByClientByServiceCode = /* GraphQL */ `
  query GetEldersByClientByServiceCode(
    $clientId: ID
    $serviceCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByServiceCode(
      clientId: $clientId
      serviceCode: $serviceCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByDeliveryGroup = /* GraphQL */ `
  query GetEldersByDeliveryGroup(
    $deliveryGroupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByDeliveryGroup(
      deliveryGroupId: $deliveryGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByCountyByCreatedAt = /* GraphQL */ `
  query GetEldersByCountyByCreatedAt(
    $county: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByCountyByCreatedAt(
      county: $county
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByCountyByStatus = /* GraphQL */ `
  query GetEldersByCountyByStatus(
    $county: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByCountyByStatus(
      county: $county
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getEldersByServiceCodeByStatus = /* GraphQL */ `
  query GetEldersByServiceCodeByStatus(
    $serviceCode: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByServiceCodeByStatus(
      serviceCode: $serviceCode
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getElderDeliveryGroupByDeliveryGroupId = /* GraphQL */ `
  query GetElderDeliveryGroupByDeliveryGroupId(
    $deliveryGroupId: ID
    $elderId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDeliveryGroupByDeliveryGroupId(
      deliveryGroupId: $deliveryGroupId
      elderId: $elderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getElderDeliveryGroupByElder = /* GraphQL */ `
  query GetElderDeliveryGroupByElder(
    $elderId: ID
    $deliveryGroupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDeliveryGroupByElder(
      elderId: $elderId
      deliveryGroupId: $deliveryGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getElderDeliveryGroupsByClientByStatus = /* GraphQL */ `
  query GetElderDeliveryGroupsByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDeliveryGroupsByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getElderDementiaSurveyByElderByDate = /* GraphQL */ `
  query GetElderDementiaSurveyByElderByDate(
    $elderId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDementiaSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDementiaSurveyByElderByDate(
      elderId: $elderId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        clientId
        deliveryStaffOrderId
        questionId
        response
        date
        createdAt
        createdBy
        updatedAt
        updatedBy
        dementiaQuestionnaire {
          items {
            id
            isActive
            category
            type
            question
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      }
      nextToken
    }
  }
`;
export const getElderDementiaSurveyByClientByDate = /* GraphQL */ `
  query GetElderDementiaSurveyByClientByDate(
    $clientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDementiaSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDementiaSurveyByClientByDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        clientId
        deliveryStaffOrderId
        questionId
        response
        date
        createdAt
        createdBy
        updatedAt
        updatedBy
        dementiaQuestionnaire {
          items {
            id
            isActive
            category
            type
            question
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      }
      nextToken
    }
  }
`;
export const getElderDementiaSurveyByDeliveryStaffOrder = /* GraphQL */ `
  query GetElderDementiaSurveyByDeliveryStaffOrder(
    $deliveryStaffOrderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelElderDementiaSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDementiaSurveyByDeliveryStaffOrder(
      deliveryStaffOrderId: $deliveryStaffOrderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        clientId
        deliveryStaffOrderId
        questionId
        response
        date
        createdAt
        createdBy
        updatedAt
        updatedBy
        dementiaQuestionnaire {
          items {
            id
            isActive
            category
            type
            question
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      }
      nextToken
    }
  }
`;
export const getEventsByClientByTimestamp = /* GraphQL */ `
  query GetEventsByClientByTimestamp(
    $clientId: ID
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventsByClientByTimestamp(
      clientId: $clientId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        key
        timestamp
        tableName
        clientId
        eventId
        eventName
        diff
        oldData
        newData
        note
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getEventsByTableByTimestamp = /* GraphQL */ `
  query GetEventsByTableByTimestamp(
    $tableName: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventsByTableByTimestamp(
      tableName: $tableName
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        key
        timestamp
        tableName
        clientId
        eventId
        eventName
        diff
        oldData
        newData
        note
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getFacilityIndividualByFacilityByCreatedAt = /* GraphQL */ `
  query GetFacilityIndividualByFacilityByCreatedAt(
    $facilityId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFacilityIndividualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFacilityIndividualByFacilityByCreatedAt(
      facilityId: $facilityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        individualId
        facilityId
        individualClient {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getFacilityIndividualByFacilityByIndividual = /* GraphQL */ `
  query GetFacilityIndividualByFacilityByIndividual(
    $facilityId: ID
    $individualId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFacilityIndividualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFacilityIndividualByFacilityByIndividual(
      facilityId: $facilityId
      individualId: $individualId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        individualId
        facilityId
        individualClient {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByStatusByCreatedAt = /* GraphQL */ `
  query GetILinkOrdersByStatusByCreatedAt(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByStatusByShouldBeSentToILink = /* GraphQL */ `
  query GetILinkOrdersByStatusByShouldBeSentToILink(
    $status: String
    $shouldBeSentToILinkAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByStatusByShouldBeSentToILink(
      status: $status
      shouldBeSentToILinkAt: $shouldBeSentToILinkAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetILinkOrdersByProcessStatusByCreatedAt(
    $processStatus: ProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByProcessStatusByShouldBeSentToILink = /* GraphQL */ `
  query GetILinkOrdersByProcessStatusByShouldBeSentToILink(
    $processStatus: ProcessStatus
    $shouldBeSentToILinkAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByProcessStatusByShouldBeSentToILink(
      processStatus: $processStatus
      shouldBeSentToILinkAt: $shouldBeSentToILinkAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByOrderByCreatedAt = /* GraphQL */ `
  query GetILinkOrdersByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByILinkOrderByCreatedAt = /* GraphQL */ `
  query GetILinkOrdersByILinkOrderByCreatedAt(
    $iLinkOrderId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByILinkOrderByCreatedAt(
      iLinkOrderId: $iLinkOrderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getILinkOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetILinkOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelILinkOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getILinkOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iLinkQuoteId
        iLinkOrderId
        iLinkCancelCode
        status
        description
        fee
        feeMessage
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToILinkAt
        iLinkQuotePayload
        iLinkOrderPayload
        orderId
        elderId
        restaurantId
        clientId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getInvoicesByUsernameByCreatedAt = /* GraphQL */ `
  query GetInvoicesByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvoicesByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        transactionId
        orderId
        status
        clientId
        deliveryStaffId
        restaurantOwnerId
        type
        email
        carrier
        title
        taxIdNumber
        code
        note
        Transaction {
          id
          username
          clientId
          deliveryStaffId
          restaurantOwnerId
          orderId
          orders {
            items {
              id
              clientId
              individualId
              restaurantId
              elderId
              date
              mealSlot
              deliveryBy
              status
              county
              countyOpenStatus
              isTemporarilyLocked
              category
              transactionId
              deliveryGroupId
              deliveryGroupSortOrder
              source
              orderQuoteId
              isAudited
              nextStatusCheckAt
              issuePriority
              issueSummary
              deliveryStaffId
              deliveryStaffOrderId
              deliveryStaffFee
              deliveryStaffFeeCalculationMethod
              tier
              tierExpiredAt
              noteForDelivery
              noteForMeal
              note
              totalCost
              total
              quantity
              subtotal
              discount
              discountCode
              discountDetails
              extraFee
              platformFee
              paymentMethod
              createdAt
              createdBy
              updatedAt
              updatedBy
              orderGroupId
              referenceId
              linkGroupElderIds
              cancellationReason
              cancellationNote
              directionServiceProvider
            }
            nextToken
          }
          status
          statusMessage
          type
          description
          note
          category
          items {
            name
            qty
            unit
            unitValue
            subTotal
          }
          serviceProvider
          subTotal
          discount
          discountRule
          amount
          cardId
          cardType
          cardLastFour
          tpMerchantId
          tpBankTxId
          tpRecTradeId
          linePayTransactionId
          linePayPaymentAccessToken
          linePayPaymentUrlWeb
          linePayPaymentUrlApp
          linePayInfo
          redirectUrl
          newebPayResult
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sentAt
        sentBy
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getInvoicesByStatusByCreatedAt = /* GraphQL */ `
  query GetInvoicesByStatusByCreatedAt(
    $status: InvoiceStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvoicesByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        transactionId
        orderId
        status
        clientId
        deliveryStaffId
        restaurantOwnerId
        type
        email
        carrier
        title
        taxIdNumber
        code
        note
        Transaction {
          id
          username
          clientId
          deliveryStaffId
          restaurantOwnerId
          orderId
          orders {
            items {
              id
              clientId
              individualId
              restaurantId
              elderId
              date
              mealSlot
              deliveryBy
              status
              county
              countyOpenStatus
              isTemporarilyLocked
              category
              transactionId
              deliveryGroupId
              deliveryGroupSortOrder
              source
              orderQuoteId
              isAudited
              nextStatusCheckAt
              issuePriority
              issueSummary
              deliveryStaffId
              deliveryStaffOrderId
              deliveryStaffFee
              deliveryStaffFeeCalculationMethod
              tier
              tierExpiredAt
              noteForDelivery
              noteForMeal
              note
              totalCost
              total
              quantity
              subtotal
              discount
              discountCode
              discountDetails
              extraFee
              platformFee
              paymentMethod
              createdAt
              createdBy
              updatedAt
              updatedBy
              orderGroupId
              referenceId
              linkGroupElderIds
              cancellationReason
              cancellationNote
              directionServiceProvider
            }
            nextToken
          }
          status
          statusMessage
          type
          description
          note
          category
          items {
            name
            qty
            unit
            unitValue
            subTotal
          }
          serviceProvider
          subTotal
          discount
          discountRule
          amount
          cardId
          cardType
          cardLastFour
          tpMerchantId
          tpBankTxId
          tpRecTradeId
          linePayTransactionId
          linePayPaymentAccessToken
          linePayPaymentUrlWeb
          linePayPaymentUrlApp
          linePayInfo
          redirectUrl
          newebPayResult
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sentAt
        sentBy
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByCountyByCreatedAt = /* GraphQL */ `
  query GetIssuesByCountyByCreatedAt(
    $county: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByCountyByCreatedAt(
      county: $county
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByCountyByStatus = /* GraphQL */ `
  query GetIssuesByCountyByStatus(
    $county: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByCountyByStatus(
      county: $county
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByOrderByCreatedAt = /* GraphQL */ `
  query GetIssuesByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByElderByCreatedAt = /* GraphQL */ `
  query GetIssuesByElderByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByElderByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByAssigneeByCreatedAt = /* GraphQL */ `
  query GetIssuesByAssigneeByCreatedAt(
    $assignee: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByAssigneeByCreatedAt(
      assignee: $assignee
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByStatusByCreatedAt = /* GraphQL */ `
  query GetIssuesByStatusByCreatedAt(
    $status: IssueStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByPriorityByCreatedAt = /* GraphQL */ `
  query GetIssuesByPriorityByCreatedAt(
    $priority: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByPriorityByCreatedAt(
      priority: $priority
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByClientByCreatedAt = /* GraphQL */ `
  query GetIssuesByClientByCreatedAt(
    $clientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByClientByCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByClientByStatus = /* GraphQL */ `
  query GetIssuesByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssuesByDeliveryStaffOrder = /* GraphQL */ `
  query GetIssuesByDeliveryStaffOrder(
    $deliveryStaffOrderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssuesByDeliveryStaffOrder(
      deliveryStaffOrderId: $deliveryStaffOrderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        status
        reportedBy
        county
        type
        summary
        description
        note
        assignee
        orderId
        elderId
        clientId
        deliveryStaffId
        deliveryStaffOrderId
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIssueCommentsByIssueByCreatedAt = /* GraphQL */ `
  query GetIssueCommentsByIssueByCreatedAt(
    $issueId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIssueCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIssueCommentsByIssueByCreatedAt(
      issueId: $issueId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        issueId
        content
        username
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByStatusByCreatedAt = /* GraphQL */ `
  query GetLalamoveOrdersByStatusByCreatedAt(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByStatusByShouldBeSentToLalamove = /* GraphQL */ `
  query GetLalamoveOrdersByStatusByShouldBeSentToLalamove(
    $status: String
    $shouldBeSentToLalamoveAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByStatusByShouldBeSentToLalamove(
      status: $status
      shouldBeSentToLalamoveAt: $shouldBeSentToLalamoveAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetLalamoveOrdersByProcessStatusByCreatedAt(
    $processStatus: ProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove = /* GraphQL */ `
  query GetLalamoveOrdersByProcessStatusByShouldBeSentToLalamove(
    $processStatus: ProcessStatus
    $shouldBeSentToLalamoveAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove(
      processStatus: $processStatus
      shouldBeSentToLalamoveAt: $shouldBeSentToLalamoveAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByOrderByCreatedAt = /* GraphQL */ `
  query GetLalamoveOrdersByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByLalamoveIdrByCreatedAt = /* GraphQL */ `
  query GetLalamoveOrdersByLalamoveIdrByCreatedAt(
    $lalamoveDeliveryId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByLalamoveIdrByCreatedAt(
      lalamoveDeliveryId: $lalamoveDeliveryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getLalamoveOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetLalamoveOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLalamoveOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLalamoveOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lalamoveDeliveryId
        quotationId
        priceBreakdown {
          base
          extraMileage
          surcharge
          totalExcludePriorityFee
          total
          currency
          priorityFee
        }
        priorityFee
        driverId
        shareLink
        status
        distance {
          value
          unit
        }
        fee
        driver {
          driverId
          name
          phone
          plateNumber
        }
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToLalamoveAt
        lalamoveQuotePayload
        lalamoveOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getMembershipByClientIdByStatus = /* GraphQL */ `
  query GetMembershipByClientIdByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMembershipByClientIdByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        status
        membershipProgramId
        expiredAt
        discountRule
        discountPercentage
        extendedAt
        transactionIds
        logs
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getMembershipByStatusByExpiredAt = /* GraphQL */ `
  query GetMembershipByStatusByExpiredAt(
    $status: MembershipStatus
    $expiredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMembershipByStatusByExpiredAt(
      status: $status
      expiredAt: $expiredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        status
        membershipProgramId
        expiredAt
        discountRule
        discountPercentage
        extendedAt
        transactionIds
        logs
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getMembershipProgramsByIsActiveByCreatedAt = /* GraphQL */ `
  query GetMembershipProgramsByIsActiveByCreatedAt(
    $isActive: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMembershipProgramsByIsActiveByCreatedAt(
      isActive: $isActive
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        name
        description
        durationInDays
        discountRule
        discountPercentage
        unit
        unitValue
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotificationsByUserByCreatedAt = /* GraphQL */ `
  query GetNotificationsByUserByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByUserByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotificationsByDeliveryStaffIdByCreatedAt = /* GraphQL */ `
  query GetNotificationsByDeliveryStaffIdByCreatedAt(
    $deliveryStaffId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByDeliveryStaffIdByCreatedAt(
      deliveryStaffId: $deliveryStaffId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotificationsByAdminIdByCreatedAt = /* GraphQL */ `
  query GetNotificationsByAdminIdByCreatedAt(
    $adminId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByAdminIdByCreatedAt(
      adminId: $adminId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotificationsByClientIdByCreatedAt = /* GraphQL */ `
  query GetNotificationsByClientIdByCreatedAt(
    $clientId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByClientIdByCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotificationsByRestaurantOwnerIdByCreatedAt = /* GraphQL */ `
  query GetNotificationsByRestaurantOwnerIdByCreatedAt(
    $restaurantOwnerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByRestaurantOwnerIdByCreatedAt(
      restaurantOwnerId: $restaurantOwnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNotificationsByStatusByShouldBeSentAt = /* GraphQL */ `
  query GetNotificationsByStatusByShouldBeSentAt(
    $status: NotificationStatus
    $shouldBeSentAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByStatusByShouldBeSentAt(
      status: $status
      shouldBeSentAt: $shouldBeSentAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        deliveryStaffId
        adminId
        clientId
        restaurantOwnerId
        type
        status
        statusMsg
        subject
        text
        dataJsonString
        link
        email
        pushToken
        phoneNumber
        shouldBeSentAt
        sentAt
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getOrdersByStatusByDate = /* GraphQL */ `
  query GetOrdersByStatusByDate(
    $status: OrderStatus
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByStatusByDate(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByStatusByDeliveryBy = /* GraphQL */ `
  query GetOrdersByStatusByDeliveryBy(
    $status: OrderStatus
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByStatusByDeliveryBy(
      status: $status
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByCountyOpenStatusByDeliveryBy = /* GraphQL */ `
  query GetOrdersByCountyOpenStatusByDeliveryBy(
    $countyOpenStatus: String
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCountyOpenStatusByDeliveryBy(
      countyOpenStatus: $countyOpenStatus
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByCountyByStatus = /* GraphQL */ `
  query GetOrdersByCountyByStatus(
    $county: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCountyByStatus(
      county: $county
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByCountyByDate = /* GraphQL */ `
  query GetOrdersByCountyByDate(
    $county: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCountyByDate(
      county: $county
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByCountyBySource = /* GraphQL */ `
  query GetOrdersByCountyBySource(
    $county: String
    $source: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByCountyBySource(
      county: $county
      source: $source
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByClientByCreatedAt = /* GraphQL */ `
  query GetOrdersByClientByCreatedAt(
    $clientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByClientByDate = /* GraphQL */ `
  query GetOrdersByClientByDate(
    $clientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByClientByStatus = /* GraphQL */ `
  query GetOrdersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByRestaurantByCreatedAt = /* GraphQL */ `
  query GetOrdersByRestaurantByCreatedAt(
    $restaurantId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByRestaurantByCreatedAt(
      restaurantId: $restaurantId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByRestaurantByDeliveryBy = /* GraphQL */ `
  query GetOrdersByRestaurantByDeliveryBy(
    $restaurantId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByRestaurantByDeliveryBy(
      restaurantId: $restaurantId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByRestaurantByDate = /* GraphQL */ `
  query GetOrdersByRestaurantByDate(
    $restaurantId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByRestaurantByDate(
      restaurantId: $restaurantId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByElderByCreatedAt = /* GraphQL */ `
  query GetOrdersByElderByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByElderByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByElderByDate = /* GraphQL */ `
  query GetOrdersByElderByDate(
    $elderId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByElderByDate(
      elderId: $elderId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByDeliveryStaffByDeliveryBy = /* GraphQL */ `
  query GetOrdersByDeliveryStaffByDeliveryBy(
    $deliveryStaffId: ID
    $deliveryBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByDeliveryStaffByDeliveryBy(
      deliveryStaffId: $deliveryStaffId
      deliveryBy: $deliveryBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByDeliveryStaffByDate = /* GraphQL */ `
  query GetOrdersByDeliveryStaffByDate(
    $deliveryStaffId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByDeliveryStaffByDate(
      deliveryStaffId: $deliveryStaffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByIsAuditedByStatusCheckAt = /* GraphQL */ `
  query GetOrdersByIsAuditedByStatusCheckAt(
    $isAudited: Int
    $nextStatusCheckAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByIsAuditedByStatusCheckAt(
      isAudited: $isAudited
      nextStatusCheckAt: $nextStatusCheckAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByIndividualByDate = /* GraphQL */ `
  query GetOrdersByIndividualByDate(
    $individualId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByIndividualByDate(
      individualId: $individualId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrdersByIndividualByStatus = /* GraphQL */ `
  query GetOrdersByIndividualByStatus(
    $individualId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByIndividualByStatus(
      individualId: $individualId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        individualId
        restaurantId
        elderId
        date
        mealSlot
        deliveryBy
        status
        county
        countyOpenStatus
        isTemporarilyLocked
        category
        transactionId
        deliveryGroupId
        deliveryGroupSortOrder
        source
        orderQuoteId
        isAudited
        nextStatusCheckAt
        issuePriority
        issueSummary
        deliveryStaffId
        deliveryStaffOrderId
        deliveryStaffFee
        deliveryStaffFeeCalculationMethod
        tier
        tierExpiredAt
        noteForDelivery
        noteForMeal
        note
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        totalCost
        total
        quantity
        subtotal
        discount
        discountCode
        discountDetails
        extraFee
        platformFee
        paymentMethod
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        orderGroupId
        referenceId
        linkGroupElderIds
        cancellationReason
        cancellationNote
        directionServiceProvider
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrderEventsByOrderByCreatedAt = /* GraphQL */ `
  query GetOrderEventsByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderEventsByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        category
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getOrderQuotesByCodeByCreatedAt = /* GraphQL */ `
  query GetOrderQuotesByCodeByCreatedAt(
    $code: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderQuotesByCodeByCreatedAt(
      code: $code
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        restaurantName
        originalStartAddress
        originalEndAddress
        code
        message
        distance
        startAddress
        endAddress
        calculationMethod
        totalFee
        deliveryStaffFee
        extraFee
        platformFee
        fixedDeliveryStaffFee
        defaultDeliveryStaffFee
        pandagoEstimatedFee
        uberEstimatedFee
        ilinkEstimatedFee
        lalamoveEstimatedFee
        direction {
          startAddress
          startLocation {
            lat
            lng
          }
          endAddress
          endLocation {
            lat
            lng
          }
          distance
          duration
          distanceInMeters
          durationInSeconds
          overviewPolyline
          bounds {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        queryAddressMethod
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByStatusByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByStatusByCreatedAt(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByStatusByShouldBeSentToPandago = /* GraphQL */ `
  query GetPandagoOrdersByStatusByShouldBeSentToPandago(
    $status: String
    $shouldBeSentToPandagoAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByStatusByShouldBeSentToPandago(
      status: $status
      shouldBeSentToPandagoAt: $shouldBeSentToPandagoAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByProcessStatusByCreatedAt(
    $processStatus: ProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByProcessStatusByShouldBeSentToPandago = /* GraphQL */ `
  query GetPandagoOrdersByProcessStatusByShouldBeSentToPandago(
    $processStatus: ProcessStatus
    $shouldBeSentToPandagoAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByProcessStatusByShouldBeSentToPandago(
      processStatus: $processStatus
      shouldBeSentToPandagoAt: $shouldBeSentToPandagoAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByOrderByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByPandagoOrderByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByPandagoOrderByCreatedAt(
    $pandagoOrderId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByPandagoOrderByCreatedAt(
      pandagoOrderId: $pandagoOrderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPandagoOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetPandagoOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPandagoOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPandagoOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pandagoOrderId
        status
        paymentMethod
        amount
        description
        fee
        estimatedPickupTime
        estimatedDeliveryTime
        driver {
          id
          name
          phoneNumber
        }
        cancelledBy
        cancelledReason
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToPandagoAt
        pandagoOrderPayload
        proofOfDeliveryS3Key
        trackingLink
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRecurringOrdersByStatus = /* GraphQL */ `
  query GetRecurringOrdersByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrdersByClientByStatus = /* GraphQL */ `
  query GetRecurringOrdersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrdersByElderByStatus = /* GraphQL */ `
  query GetRecurringOrdersByElderByStatus(
    $elderId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByElderByStatus(
      elderId: $elderId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrdersByDeliveryGroupByStatus = /* GraphQL */ `
  query GetRecurringOrdersByDeliveryGroupByStatus(
    $deliveryGroupId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByDeliveryGroupByStatus(
      deliveryGroupId: $deliveryGroupId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrdersByDeliveryStaffByStatus = /* GraphQL */ `
  query GetRecurringOrdersByDeliveryStaffByStatus(
    $deliveryStaffId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByDeliveryStaffByStatus(
      deliveryStaffId: $deliveryStaffId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrdersByRestaurantByStatus = /* GraphQL */ `
  query GetRecurringOrdersByRestaurantByStatus(
    $restaurantId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrdersByRestaurantByStatus(
      restaurantId: $restaurantId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        elderId
        deliveryGroupId
        deliveryStaffId
        restaurantId
        deliveryBy
        mealItems {
          name
          quantity
          price
          cost
          note
        }
        mealSlot
        category
        status
        repeatOn
        startOn
        endOn
        lastExecutedAt
        lastExecutedStatus
        lastExecutedStatusMessage
        createdAt
        createdBy
        updatedAt
        updatedBy
        elder {
          id
          username
          status
          clientId
          county
          deliveryGroupId
          sortOrder
          name
          nickname
          birthday
          gender
          phoneNumber
          phoneNumber2
          phoneNumber3
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          email
          identificationCardId
          serviceCode
          noteForDelivery
          noteForMeal
          noteForHealth
          note
          noteOther
          preferDeliveryTimes
          isAllowedToEnterHome
          otherSocialServices
          isDisadvantaged
          relationshipToClient
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
          govQualifiedCount
          govQualifiedPrice
          supervisorId
          linkGroupName
          tags {
            items {
              id
              elderId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          outOfPocket
          feeCollectMethod
          disadvantagedTypes
          disadvantagedTypesHistory {
            approvedDate
            disadvantagedTypes
          }
          disabilityLevel
          cmsLevel
          caseNumber
          lunchRepeatOn
          dinnerRepeatOn
          fixedDeliveryStaffFee
          onTimeDelivery
          dementiaQuestionnaire
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        deliveryGroup {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringOrderLogByRecurringOrderIdByDate = /* GraphQL */ `
  query GetRecurringOrderLogByRecurringOrderIdByDate(
    $recurringOrderId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringOrderLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRecurringOrderLogByRecurringOrderIdByDate(
      recurringOrderId: $recurringOrderId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderIds
        recurringOrderId
        deliveryBy
        date
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRestaurantOwnersByCountyByCreatedAt = /* GraphQL */ `
  query GetRestaurantOwnersByCountyByCreatedAt(
    $county: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantOwnersByCountyByCreatedAt(
      county: $county
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        county
        username
        name
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        email
        howDoYouKnowUs
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipsByUsernameByCreatedAt = /* GraphQL */ `
  query GetSponsorshipsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSponsorshipsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        username
        transactionId
        sponsorProgramId
        status
        count
        unit
        unitValue
        totalValue
        fulfilledCount
        fulfilledValue
        fulfilledAt
        note
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sponsorProgram {
          id
          isActive
          name
          summary
          description
          count
          unit
          unitValue
          totalValue
          previewS3Keys
          imageS3Keys
          sponsors {
            name
            description
            websiteUrl
            logoUrl
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        fulfillments {
          items {
            id
            username
            clientId
            sponsorshipId
            status
            unit
            unitValue
            fulfilledAt
            orderId
            elderId
            restaurantId
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipsByClientIdByStatus = /* GraphQL */ `
  query GetSponsorshipsByClientIdByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSponsorshipsByClientIdByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        username
        transactionId
        sponsorProgramId
        status
        count
        unit
        unitValue
        totalValue
        fulfilledCount
        fulfilledValue
        fulfilledAt
        note
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sponsorProgram {
          id
          isActive
          name
          summary
          description
          count
          unit
          unitValue
          totalValue
          previewS3Keys
          imageS3Keys
          sponsors {
            name
            description
            websiteUrl
            logoUrl
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        fulfillments {
          items {
            id
            username
            clientId
            sponsorshipId
            status
            unit
            unitValue
            fulfilledAt
            orderId
            elderId
            restaurantId
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipsByStatusByCreatedAt = /* GraphQL */ `
  query GetSponsorshipsByStatusByCreatedAt(
    $status: ProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSponsorshipsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        username
        transactionId
        sponsorProgramId
        status
        count
        unit
        unitValue
        totalValue
        fulfilledCount
        fulfilledValue
        fulfilledAt
        note
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        sponsorProgram {
          id
          isActive
          name
          summary
          description
          count
          unit
          unitValue
          totalValue
          previewS3Keys
          imageS3Keys
          sponsors {
            name
            description
            websiteUrl
            logoUrl
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        fulfillments {
          items {
            id
            username
            clientId
            sponsorshipId
            status
            unit
            unitValue
            fulfilledAt
            orderId
            elderId
            restaurantId
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipFulfillmentsBySponsorshipIdByStatus = /* GraphQL */ `
  query GetSponsorshipFulfillmentsBySponsorshipIdByStatus(
    $sponsorshipId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorshipFulfillmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSponsorshipFulfillmentsBySponsorshipIdByStatus(
      sponsorshipId: $sponsorshipId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        clientId
        sponsorshipId
        status
        unit
        unitValue
        fulfilledAt
        orderId
        elderId
        restaurantId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSponsorshipProgramsByIsActiveByCreatedAt = /* GraphQL */ `
  query GetSponsorshipProgramsByIsActiveByCreatedAt(
    $isActive: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorshipProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSponsorshipProgramsByIsActiveByCreatedAt(
      isActive: $isActive
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        name
        summary
        description
        count
        unit
        unitValue
        totalValue
        previewS3Keys
        imageS3Keys
        sponsors {
          name
          description
          websiteUrl
          logoUrl
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getTransactionsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTransactionsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getTransactionsByStatusByCreatedAt = /* GraphQL */ `
  query GetTransactionsByStatusByCreatedAt(
    $status: TransactionStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getTransactionsByClientByCreatedAt = /* GraphQL */ `
  query GetTransactionsByClientByCreatedAt(
    $clientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByClientByCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByStatusByCreatedAt = /* GraphQL */ `
  query GetUberOrdersByStatusByCreatedAt(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByStatusByShouldBeSentToUber = /* GraphQL */ `
  query GetUberOrdersByStatusByShouldBeSentToUber(
    $status: String
    $shouldBeSentToUberAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByStatusByShouldBeSentToUber(
      status: $status
      shouldBeSentToUberAt: $shouldBeSentToUberAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetUberOrdersByProcessStatusByCreatedAt(
    $processStatus: ProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByProcessStatusByShouldBeSentToUber = /* GraphQL */ `
  query GetUberOrdersByProcessStatusByShouldBeSentToUber(
    $processStatus: ProcessStatus
    $shouldBeSentToUberAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByProcessStatusByShouldBeSentToUber(
      processStatus: $processStatus
      shouldBeSentToUberAt: $shouldBeSentToUberAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByOrderByCreatedAt = /* GraphQL */ `
  query GetUberOrdersByOrderByCreatedAt(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByOrderByCreatedAt(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByUberIdrByCreatedAt = /* GraphQL */ `
  query GetUberOrdersByUberIdrByCreatedAt(
    $uberDeliveryId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByUberIdrByCreatedAt(
      uberDeliveryId: $uberDeliveryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUberOrdersByElderIdByCreatedAt = /* GraphQL */ `
  query GetUberOrdersByElderIdByCreatedAt(
    $elderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUberOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUberOrdersByElderIdByCreatedAt(
      elderId: $elderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uberDeliveryId
        quoteId
        status
        complete
        kind
        uuid
        liveMode
        manifestReference
        fee
        courier {
          name
          rating
          vehicleType
          vehicleMake
          vehicleModel
          vehicleColor
          phoneNumber
          imgHref
          location {
            lat
            lng
          }
        }
        trackingUrl
        undeliverableAction
        undeliverableReason
        cancelledReason
        deliverableAction
        courierNotes
        amount
        description
        processStatus
        processStatusMessage
        processStatusLastCheckedAt
        processlogs
        shouldBeSentToUberAt
        uberQuotePayload
        uberOrderPayload
        note
        orderId
        elderId
        restaurantId
        clientId
        deliveryStaffId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUploadedOrdersByClientByCreatedAt = /* GraphQL */ `
  query GetUploadedOrdersByClientByCreatedAt(
    $clientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadedOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUploadedOrdersByClientByCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        restaurantId
        orderFileS3Key
        fileName
        orderIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getUsersByClientIdByIsActive = /* GraphQL */ `
  query GetUsersByClientIdByIsActive(
    $clientId: String
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByClientIdByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        isActive
        email
        notificationToken
        notificationTokenDeliveryStaff
        notificationTokenRestaurantOwner
        notificationTokenClient
        notificationTokenAdmin
        device {
          brand
          manufacturer
          modelName
          osName
          osVersion
          osBuildId
          appVersion
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        adminId
        deliveryStaffId
        restaurantOwnerId
        clientId
        organizationId
        facilityIds
        groups {
          items {
            username
            group
            adminId
            deliveryStaffId
            restaurantOwnerId
            clientId
            organizationId
            facilityIds
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUsersByOrganizationIdByIsActive = /* GraphQL */ `
  query GetUsersByOrganizationIdByIsActive(
    $organizationId: String
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByOrganizationIdByIsActive(
      organizationId: $organizationId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        isActive
        email
        notificationToken
        notificationTokenDeliveryStaff
        notificationTokenRestaurantOwner
        notificationTokenClient
        notificationTokenAdmin
        device {
          brand
          manufacturer
          modelName
          osName
          osVersion
          osBuildId
          appVersion
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        adminId
        deliveryStaffId
        restaurantOwnerId
        clientId
        organizationId
        facilityIds
        groups {
          items {
            username
            group
            adminId
            deliveryStaffId
            restaurantOwnerId
            clientId
            organizationId
            facilityIds
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserGroupsByGroup = /* GraphQL */ `
  query GetUserGroupsByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserGroupsByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        group
        adminId
        deliveryStaffId
        restaurantOwnerId
        clientId
        organizationId
        facilityIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getWebhookEventsByVendorByCreatedAt = /* GraphQL */ `
  query GetWebhookEventsByVendorByCreatedAt(
    $vendor: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebhookEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWebhookEventsByVendorByCreatedAt(
      vendor: $vendor
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vendor
        headers
        body
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      status
      title
      content
      url
      photoS3Keys
      county
      deliveryStaffId
      deliveryStaffOrderId
      checkListIsComplete
      checkListReminder
      checkListTransfer
      checkListChat
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        title
        content
        url
        photoS3Keys
        county
        deliveryStaffId
        deliveryStaffOrderId
        checkListIsComplete
        checkListReminder
        checkListTransfer
        checkListChat
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getBlogsByStatusByCreatedAt = /* GraphQL */ `
  query GetBlogsByStatusByCreatedAt(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBlogsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        title
        content
        url
        photoS3Keys
        county
        deliveryStaffId
        deliveryStaffOrderId
        checkListIsComplete
        checkListReminder
        checkListTransfer
        checkListChat
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientAppBanner = /* GraphQL */ `
  query GetClientAppBanner($id: ID!) {
    getClientAppBanner(id: $id) {
      id
      isActive
      title
      content
      url
      imageUrl
      sortOrder
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClientAppBanners = /* GraphQL */ `
  query ListClientAppBanners(
    $filter: ModelClientAppBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientAppBanners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        title
        content
        url
        imageUrl
        sortOrder
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getClientAppBannersByIsActiveByCreatedAt = /* GraphQL */ `
  query GetClientAppBannersByIsActiveByCreatedAt(
    $isActive: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientAppBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientAppBannersByIsActiveByCreatedAt(
      isActive: $isActive
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        title
        content
        url
        imageUrl
        sortOrder
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      isActive
      name
      description
      url
      logoUrl
      bannerS3Key
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        description
        url
        logoUrl
        bannerS3Key
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRestaurant = /* GraphQL */ `
  query GetRestaurant($id: ID!) {
    getRestaurant(id: $id) {
      id
      owner
      restaurantOwnerId
      isActive
      isPublic
      freeDelivery
      county
      clientId
      category
      name
      description
      phoneNumber
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      notes
      previewS3Keys
      imageS3Keys
      isAddedToPandago
      openingHours {
        sunday {
          startTime
          endTime
        }
        monday {
          startTime
          endTime
        }
        tuesday {
          startTime
          endTime
        }
        wednesday {
          startTime
          endTime
        }
        thursday {
          startTime
          endTime
        }
        friday {
          startTime
          endTime
        }
        saturday {
          startTime
          endTime
        }
      }
      serviceAreas {
        county
        district
        zipCode
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const listRestaurants = /* GraphQL */ `
  query ListRestaurants(
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantsByCountyByIsActive = /* GraphQL */ `
  query GetRestaurantsByCountyByIsActive(
    $county: String
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantsByCountyByIsActive(
      county: $county
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantsByClientByIsActive = /* GraphQL */ `
  query GetRestaurantsByClientByIsActive(
    $clientId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantsByClientByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantsByOwnerByIsActive = /* GraphQL */ `
  query GetRestaurantsByOwnerByIsActive(
    $owner: String
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantsByOwnerByIsActive(
      owner: $owner
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantsByRestaurantOwnerIdByIsActive = /* GraphQL */ `
  query GetRestaurantsByRestaurantOwnerIdByIsActive(
    $restaurantOwnerId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantsByRestaurantOwnerIdByIsActive(
      restaurantOwnerId: $restaurantOwnerId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantHoliday = /* GraphQL */ `
  query GetRestaurantHoliday($id: ID!) {
    getRestaurantHoliday(id: $id) {
      id
      restaurantId
      year
      closedDays
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listRestaurantHolidays = /* GraphQL */ `
  query ListRestaurantHolidays(
    $filter: ModelRestaurantHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurantHolidays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        year
        closedDays
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRestaurantHolidayByRestaurantByYear = /* GraphQL */ `
  query GetRestaurantHolidayByRestaurantByYear(
    $restaurantId: ID
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantHolidayByRestaurantByYear(
      restaurantId: $restaurantId
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        year
        closedDays
        expirationUnixTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getRestaurantMeal = /* GraphQL */ `
  query GetRestaurantMeal($id: ID!) {
    getRestaurantMeal(id: $id) {
      id
      restaurantId
      isActive
      isPublic
      name
      description
      price
      cost
      previewS3Keys
      imageS3Keys
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantMealId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurantMeal {
            id
            restaurantId
            isActive
            isPublic
            name
            description
            price
            cost
            previewS3Keys
            imageS3Keys
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const listRestaurantMeals = /* GraphQL */ `
  query ListRestaurantMeals(
    $filter: ModelRestaurantMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurantMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        previewS3Keys
        imageS3Keys
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantMealId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurantMeal {
              id
              restaurantId
              isActive
              isPublic
              name
              description
              price
              cost
              previewS3Keys
              imageS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantMealsByRestaurantByIsActive = /* GraphQL */ `
  query GetRestaurantMealsByRestaurantByIsActive(
    $restaurantId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantMealsByRestaurantByIsActive(
      restaurantId: $restaurantId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        previewS3Keys
        imageS3Keys
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantMealId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurantMeal {
              id
              restaurantId
              isActive
              isPublic
              name
              description
              price
              cost
              previewS3Keys
              imageS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantMealTag = /* GraphQL */ `
  query GetRestaurantMealTag($id: ID!) {
    getRestaurantMealTag(id: $id) {
      id
      restaurantMealId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurantMeal {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        previewS3Keys
        imageS3Keys
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantMealId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurantMeal {
              id
              restaurantId
              isActive
              isPublic
              name
              description
              price
              cost
              previewS3Keys
              imageS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listRestaurantMealTags = /* GraphQL */ `
  query ListRestaurantMealTags(
    $filter: ModelRestaurantMealTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurantMealTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantMealId
        tagId
        createdAt
        createdBy
        updatedAt
        updatedBy
        restaurantMeal {
          id
          restaurantId
          isActive
          isPublic
          name
          description
          price
          cost
          previewS3Keys
          imageS3Keys
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantMealId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
        tag {
          id
          category
          subcategory
          color
          label
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantSchedule = /* GraphQL */ `
  query GetRestaurantSchedule($id: ID!) {
    getRestaurantSchedule(id: $id) {
      id
      restaurantId
      date
      mealSlot
      start
      end
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listRestaurantSchedules = /* GraphQL */ `
  query ListRestaurantSchedules(
    $filter: ModelRestaurantScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurantSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        date
        mealSlot
        start
        end
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantSchedulesByStatusByDate = /* GraphQL */ `
  query GetRestaurantSchedulesByStatusByDate(
    $status: DeliveryScheduleStatus
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantSchedulesByStatusByDate(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        date
        mealSlot
        start
        end
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getRestaurantTag = /* GraphQL */ `
  query GetRestaurantTag($id: ID!) {
    getRestaurantTag(id: $id) {
      id
      restaurantId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listRestaurantTags = /* GraphQL */ `
  query ListRestaurantTags(
    $filter: ModelRestaurantTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurantTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        restaurantId
        tagId
        createdAt
        createdBy
        updatedAt
        updatedBy
        restaurant {
          id
          owner
          restaurantOwnerId
          isActive
          isPublic
          freeDelivery
          county
          clientId
          category
          name
          description
          phoneNumber
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          notes
          previewS3Keys
          imageS3Keys
          isAddedToPandago
          openingHours {
            sunday {
              startTime
              endTime
            }
            monday {
              startTime
              endTime
            }
            tuesday {
              startTime
              endTime
            }
            wednesday {
              startTime
              endTime
            }
            thursday {
              startTime
              endTime
            }
            friday {
              startTime
              endTime
            }
            saturday {
              startTime
              endTime
            }
          }
          serviceAreas {
            county
            district
            zipCode
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tags {
            items {
              id
              restaurantId
              tagId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
        }
        tag {
          id
          category
          subcategory
          color
          label
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      category
      subcategory
      color
      label
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getTagsByCategoryBySubcategory = /* GraphQL */ `
  query GetTagsByCategoryBySubcategory(
    $category: String
    $subcategory: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTagsByCategoryBySubcategory(
      category: $category
      subcategory: $subcategory
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
