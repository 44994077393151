import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
  },
}));

export default function Loading({ fullScreen = true }) {
  const classes = useStyles();

  if (fullScreen) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid item xs={12} align="center">
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
  );
}

Loading.propTypes = {
  fullScreen: PropTypes.bool,
};
