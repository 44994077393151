export default [
  {
    label: '待處理',
    value: 'pending',
    color: '#ff0318',
  },
  {
    label: '處理中',
    value: 'processing',
    color: '#00913A',
  },
  {
    label: '已完成',
    value: 'fulfilled',
    color: '#000000',
  },
  {
    label: '取消',
    value: 'cancelled',
    color: '#363636',
  },
];
