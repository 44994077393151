import React from 'react';

import EditButton from '../components/EditButton';
import IndividualClientForm from './IndividualClientForm';

export default function IndividualClientEditButton({ ...props }) {
  return (
    <EditButton
      title="個人"
      form={IndividualClientForm}
      {...props}
    />);
}
