import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { createRestaurantOwner, updateRestaurantOwner } from 'graphql/mutations';
import schema from './schema';
import uiSchema from './uiSchema';

export default function RestaurantOwnerForm({ ...props }) {
  const createFunc = async (data) => {
    data.county = data.address.county;

    await request(createRestaurantOwner, { input: data });
  };

  const updateFunc = async (data) => {
    data.county = data.address.county;

    await request(updateRestaurantOwner, { input: data });
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

RestaurantOwnerForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
