import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import {
  counties,
  deliveryStaffOrderStatus,
  TIME_ZONE,
} from '@silvergatedelivery/constants';

import DataTable from 'components/Table/DataTable';
import { asyncListAll } from 'utilities/graph';
import {
  listDeliveryStaffOrders,
  getDeliveryStaffOrdersByStaffByDate,
  getDeliveryStaffOrdersByCountyByDeliveryBy,
  getDeliveryStaffOrdersByStatusByDate,
} from 'graphql/queries';
import DeliverStaffForm from 'forms/DeliverStaffForm/DeliverStaffForm';
import OrderDialog from 'components/Order/OrderDialog';
import DeliveryStaffOrderStatusChip from './DeliveryStaffOrderStatusChip';
import cache from 'utilities/cache';
import { getTranslatedMealSlots } from 'utilities/translate';
import { deliveryResult } from '@silvergatedelivery/constants';

const lastWeek = moment().tz(TIME_ZONE).add(-7, 'days').format('YYYY-MM-DD');
const nextWeek = moment().tz(TIME_ZONE).add(7, 'days').format('YYYY-MM-DD');

export default function DeliveryStaffOrderTable({
  title = i18n.t('送餐紀錄'),
  description,
  deliveryStaffId,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [queryFunc, setQueryFunc] = useState();

  const options = {};
  const translatedMealSlots = getTranslatedMealSlots();
  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffId',
      label: `${t('送餐大使')}編號`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaff.name',
      label: t('送餐大使'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderId',
      label: '訂單編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: `${t('送餐紀錄')}狀態`,
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <DeliveryStaffOrderStatusChip status={value} />
          );
        },
      },
    },
    {
      name: 'mealSlot',
      label: '時段',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return translatedMealSlots.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'deliveryBy',
      label: `${t('送餐')}時間`,
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffFee',
      label: '費用',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'checkListIsComplete',
      label: `${t('送餐')}完成`,
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'deliveryResult',
      label: `${t('送餐')}結果`,
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (deliveryResult.find((item) => item.value === value) || {}).label || '';
        },
      },
    },
    {
      name: 'checkListReminder',
      label: t('生活提醒'),
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'checkListTransfer',
      label: t('轉贈愛心物資'),
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'checkListChat',
      label: t('跟長輩聊天關心'),
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'diary',
      label: t('送餐日誌'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryPosition',
      label: 'GPS位置',
      options: {
        filter: false,
        sort: true,
        customBodyRender(value) {
          const { latitude, longitude } = value || {};
          return (!latitude) ? '' : `${latitude}, ${longitude}`;
        },
      },
    },
    {
      name: 'deliveryPositionUpdatedAt',
      label: 'GPS更新時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderId',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(orderId) {
          return (
            <OrderDialog id={orderId} />
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (deliveryStaffId) {
      setQueryFunc(async () => {
        if (deliveryStaffId) {
          return asyncListAll(getDeliveryStaffOrdersByStaffByDate, { deliveryStaffId });
        }

        return asyncListAll(listDeliveryStaffOrders);
      });
      return;
    }

    setIndexes([
      {
        name: 'getDeliveryStaffOrdersByCountyByDeliveryBy',
        label: `按地區/${t('送餐')}時間查詢`,
        partitionKey: 'county',
        sortKey: 'deliveryBy',
        fields: [{
          label: '縣市',
          key: 'county',
          type: 'select',
          options: counties.map((item) => {
            return { value: item, label: item };
          }),
        }, {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        }],
        operation: getDeliveryStaffOrdersByCountyByDeliveryBy,
      },
      {
        name: 'getDeliveryStaffOrdersByStatusByDate',
        label: `按狀態/${t('送餐')}時間查詢`,
        partitionKey: 'status',
        sortKey: 'deliveryBy',
        fields: [{
          label: '使用狀態',
          key: 'status',
          type: 'select',
          options: deliveryStaffOrderStatus,
        }, {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        }],
        operation: getDeliveryStaffOrdersByStatusByDate,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listDeliveryStaffOrders,
      },
    ]);
    setQueryParams({
      county: cache.get('app:location') || counties[0],
      deliveryBy: {
        from: lastWeek,
        to: nextWeek,
      },
      status: deliveryStaffOrderStatus[0].value,

    });
  }, [deliveryStaffId]);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        queryFunc={queryFunc}
        form={DeliverStaffForm}
        defaultIndex={cache.get('app:location') ? 0 : 1}
        {...props}
      />
    </React.Fragment>
  );
}

DeliveryStaffOrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  deliveryStaffId: PropTypes.string,
};
