import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Countdown from 'react-countdown';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DebounceInput } from 'react-debounce-input';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Marker from 'components/Map/Marker';
import { orderStatus } from '@silvergatedelivery/constants';

import { asyncListAll, request } from 'utilities/graph';
import {
  getDeliveryStaff,
  getOrdersByCountyByDate,
  getOrdersByClientByDate,
} from './queries';
import {
  getDeliveryGroupsByClientByIsActive,
} from 'graphql/queries';
import {
  TIME_ZONE,
  AUTO_UPDATE_INTERVAL_IN_MILLISECONDS,
  counties,
} from '@silvergatedelivery/constants';
import { sortBy } from 'utilities/sorting';
import BoardView from './BoardView';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
  },
  app: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  centerContent: {
    width: '100%',
    height: '100%',
    transition: 'width 0.3s ease-in-out',
    overflow: 'auto',
  },
  centerContentShrink: {
    width: '70%',
  },
  popup: {
    position: 'absolute',
    top: 0,
    right: '-100%',
    width: '30%',
    height: '100%',
    boxShadow: '-2px 0px 10px rgba(0, 0, 0, 0.2)',
    transition: 'right 0.3s ease-in-out',
    overflow: 'hidden',
  },
  popupActive: {
    right: 0,
  },
}));

const matchText = (a = '', b = '') => {
  return `${a}`.toLowerCase().includes(`${b}`.toLowerCase());
};

export default function TodayOrders() {
  const classes = useStyles();
  const searchRef = useRef(null);
  const { t } = useTranslation();
  const allDeliveryGroups = {
    name: `所有${t('送餐群組')}`,
    id: 'all',
  };
  const mealSlots = [
    {
      label: `顯示${t('午餐')}和${t('晚餐')}`,
      value: 'all',
    },
    {
      label: `顯示${t('午餐')}`,
      value: 'lunch',
    },
    {
      label: `顯示${t('晚餐')}`,
      value: 'dinner',
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [onlyShowIssuedOrders, setOnlyShowIssuedOrders] = useState(false);
  const [mealSlotSelected, setMealSlotSelected] = useState('all');
  const [deliveryGroups, setDeliveryGroups] = useState([allDeliveryGroups]);
  const [deliveryGroupSelected, setDeliveryGroupSelected] = useState('all');
  const [showMap, setShowMap] = useState(false);
  const [locations, setLocations] = useState([]);
  const { selectedUserGroupParams } = useCache();

  const organizationId = cache.get('app:organizationId');
  const clientId = cache.get('app:facilityId');

  useEffect(() => {
    if (clientId) {
      (async () => {
        setIsLoading(true);
        const newDeliveryGroups = await asyncListAll(getDeliveryGroupsByClientByIsActive, { clientId });
        setDeliveryGroups([
          allDeliveryGroups,
          ...newDeliveryGroups.sort((a, b) => a.name > b.name ? 1 : -1),
        ]);
        setIsLoading(false);
      })();
    }
  }, [clientId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const location = cache.get('app:location');

      let orders = [];

      if (organizationId) {
        const { organizationClientIds } = selectedUserGroupParams;
        await Promise.all(organizationClientIds.map(async (clientId) => {
          const data = await asyncListAll(getOrdersByClientByDate, {
            clientId,
            date: {
              eq: moment().tz(TIME_ZONE).format('YYYY-MM-DD'),
            },
          });

          orders = [...orders, ...data];
        }));
      } else
      if (clientId) {
        orders = await asyncListAll(getOrdersByClientByDate, {
          clientId,
          date: {
            eq: moment().tz(TIME_ZONE).format('YYYY-MM-DD'),
          },
        });
      } else
      if (location) {
        orders = await asyncListAll(getOrdersByCountyByDate, {
          county: location,
          date: {
            eq: moment().tz(TIME_ZONE).format('YYYY-MM-DD'),
          },
        });
      } else {
        await Promise.all(counties.map(async (county) => {
          const countyOrders = await asyncListAll(getOrdersByCountyByDate, {
            county,
            date: {
              eq: moment().tz(TIME_ZONE).format('YYYY-MM-DD'),
            },
          });
          orders = [...orders, ...countyOrders];
        }));
        // orders = await asyncListAll(listOrders, {
        //   filter: {
        //     deliveryBy: {
        //       between: [
        //         moment().tz(TIME_ZONE).startOf(period).toISOString(),
        //         moment().tz(TIME_ZONE).endOf(period).toISOString(),
        //       ],
        //     },
        //   },
        // });
      }

      const deliveryStaffIds = orders.reduce((allIds, { deliveryStaffId }) => {
        if (deliveryStaffId && !allIds.includes(deliveryStaffId)) {
          allIds.push(deliveryStaffId);
        }
        return allIds;
      }, []);

      const deliveryStaffs = await Promise.all(deliveryStaffIds.map(async (id) => {
        const { data: { getDeliveryStaff: deliveryStafff } } = await request(getDeliveryStaff, { id });
        return deliveryStafff;
      }));

      orders.forEach((order) => {
        if (order.deliveryStaffId) {
          const matched = deliveryStaffs.find(({ id }) => id === order.deliveryStaffId);
          if (matched) {
            order.deliveryStaff = matched;
          }
        }
      });

      setOrders(orders.sort(sortBy('deliveryBy')).sort(sortBy('status')));
      setIsLoading(false);
    })();
  }, [lastUpdatedAt]);

  useEffect(() => {
    const filteredOrders = orders
      .filter(({ mealSlot }) => mealSlotSelected === 'all' || mealSlotSelected === mealSlot)
      .filter(({ deliveryGroupId }) => deliveryGroupSelected === 'all' || deliveryGroupSelected === deliveryGroupId)
      .filter((({ elder, restaurant, client, deliveryStaff = {} }) => {
        return matchText(elder.name, searchText) ||
          matchText(restaurant.name, searchText) ||
          matchText(client.name, searchText) ||
          matchText((deliveryStaff ? deliveryStaff.name : ''), searchText);
      }))
      .filter(({ issuePriority, isAudited }) => {
        return onlyShowIssuedOrders ? (isAudited === 1 && issuePriority > 0) : true;
      })
      .sort(sortBy('deliveryBy'));
    let newLocations = [];
    orderStatus.slice().reverse().forEach(({ value, colorText }) => {
      const locations = filteredOrders.filter(({ status }) => status === value).map(({ elder, id }) => ({
        lat: elder.address.lat,
        lng: elder.address.lng,
        name: elder.name,
        id,
        color: colorText,
      }));
      newLocations = newLocations.concat(locations);
    });
    setFilteredOrders(filteredOrders);
    setLocations(newLocations);
  }, [orders, searchText, onlyShowIssuedOrders, mealSlotSelected, deliveryGroupSelected]);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} container spacing={2} style={{ height: 76 }}>
        <Grid item xs={2}>
          <FormGroup row>
            <DebounceInput
              debounceTimeout={500}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              element={TextField}
              id={`search-text`}
              label=""
              value={searchText}
              type={'text'}
              placeholder="搜尋"
              variant="outlined"
              margin="dense"
              fullWidth
              style={{ marginLeft: 8 }}
              // disabled={isLoading}
              inputRef={searchRef}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowIssuedOrders}
                value={onlyShowIssuedOrders}
                onChange={(e) => {
                  setOnlyShowIssuedOrders(e.target.checked);
                }}
                name={'onlyShowIssuedOrders'}
                color="primary"
                disabled={isLoading}
              />
            }
            label="只顯示有異常的訂單"
          />
        </Grid>
        <Grid item xs={1} container alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={showMap}
                name={'showMap'}
                value={showMap}
                onChange={(e) => {
                  setShowMap(e.target.checked);
                }}
              />
            }
            label={'顯示地圖'}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <Select
            id={'meal-slot-select'}
            value={mealSlotSelected}
            label="Meal Slot"
            disabled={isLoading}
            fullWidth
            onChange={(e) => {
              setMealSlotSelected(e.target.value);
            }}
          >
            {mealSlots.map((meal, index) => (
              <MenuItem key={`meal-slot-${index}`} value={meal.value}>{meal.label}</MenuItem>
            ))}
          </Select>
        </Grid>
        {clientId &&
        <Grid item xs={2} container alignItems="center">
          <Select
            id={'delivery-group-select'}
            value={deliveryGroupSelected}
            label="Delivery Group"
            disabled={isLoading}
            fullWidth
            onChange={(e) => {
              setDeliveryGroupSelected(e.target.value);
            }}
          >
            {deliveryGroups.map((deliveryGroup, index) => (
              <MenuItem key={`delivery-group-${index}`} value={deliveryGroup.id}>{`顯示${deliveryGroup.name}`}</MenuItem>
            ))}
          </Select>
        </Grid>}
        <Grid item xs={clientId ? 3 : 5} container direction="row" justifyContent="flex-end" alignItems="center">
          <span>距離下次自動更新：</span>
          <Countdown
            date={lastUpdatedAt + AUTO_UPDATE_INTERVAL_IN_MILLISECONDS}
            daysInHours={true}
            onComplete={() => setLastUpdatedAt(Date.now())}
          />
          {isLoading ?
            <CircularProgress color="primary" size={24} style={{ marginLeft: 8 }} /> :
            <IconButton size="small" disabled={isLoading} onClick={()=>setLastUpdatedAt(Date.now())} style={{ marginLeft: 8 }}>
              <RefreshIcon />
            </IconButton>}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: 'calc(100vh - 172px)' }}>
        <div className={classes.app}>
          <div className={`${classes.centerContent} ${showMap ? classes.centerContentShrink : ''}`}>
            <BoardView orders={filteredOrders}/>
          </div>
          <div className={`${classes.popup} ${showMap ? classes.popupActive : ''}`}>
            <Marker
              locations={locations}
              showMap={showMap}
              onClick={(location = {}) => {
                if (location.name) {
                  setSearchText(location.name);
                  const input = searchRef.current.querySelector('input');
                  if (input) {
                    input.focus();
                  }
                }
              }}
            />
          </div>
        </div>
      </Grid>
    </Grid>);
}

TodayOrders.propTypes = {
  height: PropTypes.string,
};
