import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import { listDementiaQuestionnaires } from 'graphql/queries';
import DementiaQuestionnaireEditButton from 'forms/DementiaQuestionnaireForm/DementiaQuestionnaireEditButton';


export default function DementiaQuestionnaireTable({
  title = '客戶',
  description,
  type: inClientType = 'individual',
  organizationId: inOrganizationId,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isActive',
      label: '使用中',
      type: 'checkbox',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category',
      label: '題目類別',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },

    {
      name: 'type',
      label: '答題類型',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'question',
      label: '題目',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    setIndexes([
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '狀態',
          key: 'isActive',
          type: 'select',
          options: [
            {
              label: '已停用',
              value: 0,
            },
            {
              label: '使用中',
              value: 1,
            },
          ],
        }],
        operation: listDementiaQuestionnaires,
      },
    ]);
    setQueryParams({ isActive: 1 });
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={DementiaQuestionnaireEditButton}
    />
  );
}

DementiaQuestionnaireTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  organizationId: PropTypes.string,
};
