import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

export default function InvoiceInput({ data, onChange }) {
  const [invoice, setInvoice] = useState(JSON.parse(JSON.stringify(data)));

  const updateInvoice = (toUpdateProps = {}) => {
    const updatedInvoice = Object.assign(invoice, toUpdateProps);
    setInvoice({ ...updatedInvoice });
    onChange && onChange(updatedInvoice);
  };

  return (
    <Grid container spacing={0} style={{ paddingTop: 16 }}>
      <Grid item xs={12} container justifyContent="flex-start">
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="invoice-type--radio-buttons-group-label"
            name="invoice-type--radio-buttons-group"
            value={invoice.type}
            onChange={(e) => updateInvoice({ type: e.target.value })}
          >
            <FormControlLabel value="individual" control={<Radio />} label="個人" style={{ width: '100%' }}/>
            {invoice.type === 'individual' && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label='載具'
                    type="text"
                    value={invoice.carrier}
                    onChange={(e) => updateInvoice({ carrier: e.target.value })}
                    fullWidth
                    placeholder='請輸入載具（需加上"/")'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label={'信箱*'}
                    type="text"
                    value={invoice.email}
                    onChange={(e) => updateInvoice({ email: e.target.value })}
                    fullWidth
                    error={invoice.email.length === 0}
                    helperText={ invoice.email.length === 0 ? '必填' : '' }
                    placeholder='請輸入聯絡信箱'
                  />
                </Grid>
              </Grid>
            )}

            <FormControlLabel value="enterprise" control={<Radio />} label="企業" style={{ width: '100%' }}/>
            {invoice.type === 'enterprise' && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label='抬頭*'
                    type="text"
                    value={invoice.title}
                    onChange={(e) => updateInvoice({ title: e.target.value })}
                    fullWidth
                    error={invoice.title.length === 0}
                    helperText={ invoice.title.length === 0 ? '必填' : '' }
                    placeholder='請輸入抬頭'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label='統編*'
                    type="text"
                    value={invoice.taxIdNumber}
                    onChange={(e) => updateInvoice({ taxIdNumber: e.target.value })}
                    fullWidth
                    error={invoice.taxIdNumber.length === 0}
                    helperText={ invoice.taxIdNumber.length === 0 ? '必填' : '' }
                    placeholder='請輸入統編'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label={'信箱*'}
                    type="text"
                    value={invoice.email}
                    onChange={(e) => updateInvoice({ email: e.target.value })}
                    fullWidth
                    error={invoice.email.length === 0}
                    helperText={ invoice.email.length === 0 ? '必填' : '' }
                    placeholder='請輸入聯絡信箱'
                  />
                </Grid>
              </Grid>
            )}

            <FormControlLabel value="donation" control={<Radio />} label="捐贈給社團法人銀色大門老人福利組織" />
            {invoice.type === 'donation' && (
              <Grid item xs={12}>
                <Typography variant='body1' style={{ marginLeft: 20 }}>非常感謝您的捐贈</Typography>
              </Grid>
            )}

          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

InvoiceInput.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};
