import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { createClientAppBanner, updateClientAppBanner } from 'graphql/mutations';
import schema from './schema';
import uiSchema from './uiSchema';
import { deliveryStaffStatus } from '@silvergatedelivery/constants';

Object.assign(schema.properties.isActive, {
  type: 'number',
  enum: deliveryStaffStatus.map(({ value }) => value),
  enumNames: deliveryStaffStatus.map(({ label }) => label),
  default: 1,
});

export default function ClientAppBannerForm({ ...props }) {
  const createFunc = async (data) => {
    await request(createClientAppBanner, { input: data });
  };

  const updateFunc = async (data) => {
    await request(updateClientAppBanner, { input: data });
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

ClientAppBannerForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
