export default [
  {
    label: '使用中',
    value: '使用中',
  },
  {
    label: '已停用',
    value: '已停用',
  },
];
