import React from 'react';
import PropTypes from 'prop-types';

import AdminRecurringOrderPreviewButton from './AdminRecurringOrderPreviewButton';

export default function AdminRecurringOrderPreviewFunc({ data, ...props }) {
  return (
    <AdminRecurringOrderPreviewButton
      data={data}
      {...props}
    />);
}

AdminRecurringOrderPreviewFunc.propTypes = {
  data: PropTypes.array,
};
