import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AutoSizeDialog from 'components/AutoSizeDialog';
import { request } from 'utilities/graph';
import { updateClient } from 'graphql/mutations';
import { useCache } from 'CacheProvider';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButton: {
    width: '80%',
    borderRadius: '20px',
    color: 'white',
  },
}));

export default function InformationDialog({
  hideCloseButton = false,
  fullScreen = true,
  maxWidth = 'md',
}) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [client, setClient] = useState();
  const { userGroupOptions } = useCache();

  useEffect(() => {
    let clientOption;
    if (userGroupOptions?.length > 0) {
      clientOption = userGroupOptions.find(({ userGroupName }) => userGroupName === 'Clients');
      setClient(JSON.parse(JSON.stringify(clientOption.clientData)));
    }
  }, []);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    setShowDialog(false);
  };

  const updateInformation = (toUpdateProps = {}) => {
    const updatedClient = Object.assign(client, toUpdateProps);
    setClient({ ...updatedClient });
  };

  const submitClient = async () => {
    const data = {
      id: client.id,
      type: client.type,
      isActive: client.isActive,
      county: client.county,
      name: client.name,
      address: client.address,
      phoneNumber: client.phoneNumber,
      email: client.email,
      usersLimit: client.usersLimit,
      eldersLimit: client.eldersLimit,
      useDedicatedDeliveryStaffs: client.useDedicatedDeliveryStaffs,
    };
    // update cache
    // if (userGroupOptions?.length > 0) {
    //   const newUserGroupOptions = JSON.parse(JSON.stringify(userGroupOptions));
    //   const clientOption = newUserGroupOptions.find(({ userGroupName }) => userGroupName === 'Clients');
    //   clientOption.clientData = JSON.parse(JSON.stringify(client));
    //   // setUserGroupOptions 會讓menu.js把畫面切回首頁
    //   // setUserGroupOptions(newUserGroupOptions);
    // }

    await request(updateClient, { input: data });
  };

  if (!client) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Grid container
        onClick={() => {
          setShowDialog(true);
        }}
        spacing={2}
      >
        <Grid item xs={10}>
          <Typography variant='h6' color='textPrimary' style={{ marginLeft: 10 }}>
            個人資料
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KeyboardArrowRightIcon style={{ marginTop: 2 }} />
        </Grid>
      </Grid>
      <AutoSizeDialog
        open={showDialog}
        title='個人資料'
        onClose={handleClose}
      >
        <DialogContent className={classes.content} dividers>
          <Grid container>
            <Grid item xs={12}>
              <FormControl>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      label='姓名*'
                      type="text"
                      value={client.name}
                      onChange={(e) => updateInformation({ name: e.target.value })}
                      fullWidth
                      error={client.name.length === 0}
                      helperText={ client.name?.length === 0 ? '必填' : '' }
                      placeholder='請填寫姓名'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      label='電子信箱*'
                      type="text"
                      value={client.email}
                      onChange={(e) => updateInformation({ email: e.target.value })}
                      fullWidth
                      error={client.email.length === 0}
                      helperText={ client.email?.length === 0 ? '必填' : '' }
                      placeholder='請填寫電子信箱'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      label='電話'
                      type="text"
                      value={client.phoneNumber}
                      onChange={(e) => updateInformation({ phoneNumber: e.target.value })}
                      fullWidth
                      placeholder='請填電話'
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Grid xs={12} item container justifyContent='center' alignItems='center'>
              <Button
                variant='contained'
                className={classes.actionButton}
                style={{ backgroundColor: '#00913A' }}
                onClick={async () => {
                  await submitClient();
                  setShowDialog(false);
                }}
              >
                確認
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </AutoSizeDialog>
    </React.Fragment>
  );
}

InformationDialog.propTypes = {
  fullScreen: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
};
