import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import { listTags } from 'graphql/queries';
import TagEditButton from 'forms/TagForm/TagEditButton';
import { sortBy } from 'utilities/sorting';

const columns = [
  {
    name: 'id',
    label: '編號',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'category',
    label: '類別',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'subcategory',
    label: '子類別',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'label',
    label: '名稱',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'color',
    label: '顏色',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: '創立於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: '更新於',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
];

export default function TagTable({
  title = '標籤',
  description,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  useEffect(() => {
    setIndexes([
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listTags,
      },
    ]);
    setQueryParams({});
  }, []);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      editButton={TagEditButton}
      dataSortFunc={sortBy('category')}
    />
  );
}

TagTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
