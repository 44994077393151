import React from 'react';

import EditButton from '../components/EditButton';
import TagForm from './TagForm';

export default function TagEditButton({ ...props }) {
  return (
    <EditButton
      title="標籤"
      form={TagForm}
      {...props}
    />);
}
