import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  Autocomplete,
  TextField,
} from '@aws-amplify/ui-react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import querystring from 'query-string';
import { Auth, Hub, I18n } from 'aws-amplify';
import { dict } from 'i18n/Amplify';
import { makeStyles } from '@material-ui/core/styles';

import {
  // Colors,
  // SHORT_NAME,
  // authErrorCodes,
  counties,
} from '@silvergatedelivery/constants';
import cache from 'utilities/cache';

const useStyles = makeStyles((theme) => ({
  authenticator: {
    '& .amplify-tabs': {
      display: 'none',
    },
  },
}));

export default function FacilityAuthenticator({
  children = <React.Fragment />,
  initialAuthState: inAuthState,
  currentAuthState,
}) {
  const classes = useStyles();
  const [authState, setAuthState] = useState();

  useEffect(() => {
    const facilityDic = JSON.parse(JSON.stringify(dict));
    facilityDic.zh['Create Account'] = '申請機構帳戶';
    facilityDic.zh['Enter your Name'] = '機構名稱';
    I18n.putVocabularies(facilityDic);
  }, []);

  const components = {
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();
        Hub.dispatch('app', { event: 'signUp_view', data: { currentAuthState } });

        return (
          <React.Fragment>
            <Authenticator.SignUp.FormFields />
            <TextField
              label="聯絡人姓名"
              type="text"
              placeholder="聯絡人姓名"
              name="contact"
              onChange={(e) => {
                cache.set('app:contact', e.currentTarget.value || '');
              }}
            />
            <Autocomplete
              label="所在縣市"
              name='location'
              options={counties.map((item)=>{
                return {
                  id: item,
                  label: item,
                };
              })}
              placeholder="所在縣市"
              onSelect={(option)=>{
                cache.set('app:location', option.id);
              }}
            />

            <CheckboxField
              errorMessage={validationErrors.acknowledgement}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label={
                <div style={{ fontSize: 14 }}>
                  我同意
                  <Link
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    variant="body2"
                    underline="always"
                  >
                    隱私權及保護政策
                  </Link>
                  與
                  <Link
                    href="/service-terms"
                    target="_blank"
                    rel="noopener"
                    variant="body2"
                    underline="always"
                  >
                    會員服務條款
                  </Link>
                </div>}
            />
          </React.Fragment>
        );
      },
    },
  };

  const services = {
    async validateCustomSignUp(formData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: '您必須同意隱私權及保護政策與會員服務條款',
        };
      }
    },
    async handleSignUp(formData) {
      const {
        username,
        password,
        attributes: {
          name,
          phone_number: phoneNumber,
        },
      } = formData;
      cache.set('app:phoneNumber', phoneNumber);

      const email = username.toLowerCase();
      const signUpResult = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      global.logger.debug('signUp', signUpResult);

      cache.set('app:isNewRegisteredFacilityAdmin', 'yes');

      return signUpResult;
    },
  };

  const formFields = {
    signUp: {
      username: {
        label: '電子信箱',
        isRequired: true,
        order: 1,
      },
      password: {
        label: '密碼',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: '確認密碼',
        order: 3,
      },
      name: {
        label: '機構名稱',
        isRequired: true,
        order: 4,
      },
      phone_number: {
        label: '機構電話',
        isRequired: true,
        order: 5,
        dialCodeList: ['+886'],
      },
    },
  };

  useEffect(() => {
    setAuthState(null);

    setTimeout(() => {
      const { state } = querystring.parse(window.location.search);
      setAuthState(state || inAuthState || 'signUp');
    });
  }, [inAuthState]);

  if (!authState) return null;

  return (
    <Grid container spacing={2} justifyContent="center" style={{ height: '100%', width: '100%' }}>
      <Grid item container xs={12} alignItems="flex-end">
        <Typography variant="h6" color="textPrimary" >
          申請機構帳戶
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Authenticator
          initialState={authState}
          services={services}
          components={components}
          formFields={formFields}
          signUpAttributes={['username', 'password', 'name', 'phone_number']}
          className={classes.authenticator}
        >
          {() => children}
        </Authenticator>
      </Grid>
    </Grid>
  );
}

FacilityAuthenticator.propTypes = {
  children: PropTypes.any,
  initialAuthState: PropTypes.string,
  currentAuthState: PropTypes.string,
};
