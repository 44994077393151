import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import { DebounceInput } from 'react-debounce-input';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import { sortBy } from 'utilities/sorting';
import BoardView from './BoardView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
  },
  app: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  centerContent: {
    width: '100%',
    height: '100%',
    transition: 'width 0.3s ease-in-out',
    overflow: 'auto',
  },
}));

const matchText = (a = '', b = '') => {
  return `${a}`.toLowerCase().includes(`${b}`.toLowerCase());
};

const today = moment();
const weekDates = Array.from({ length: 7 }, (_, i) =>
  today.clone().add(i, 'days').format('YYYY-MM-DD'),
);

export default function ScheduleBoard({ orders, onDateChange, deliveryStaffOptions=[] }) {
  const classes = useStyles();
  const searchRef = useRef(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const dates = useRef(weekDates);
  const displayDays = useRef(7);

  useEffect(() => {
    onDateChange({
      startStr: moment(dates.current[0]).tz(TIME_ZONE).toISOString(),
      endStr: moment(dates.current[dates.current.length - 1]).tz(TIME_ZONE).toISOString(),
    });
  }, []);

  useEffect(() => {
    const filteredOrders = orders
      .filter((({ elder, restaurant, client, deliveryStaff = {} }) => {
        return matchText(elder.name, searchText) ||
          matchText(restaurant.name, searchText) ||
          matchText(client.name, searchText) ||
          matchText((deliveryStaff ? deliveryStaff.name : ''), searchText);
      }))
      .sort(sortBy('deliveryBy'));
    setFilteredOrders(filteredOrders);
  }, [orders, searchText]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} container spacing={2} style={{ height: 76 }}>
        <Grid item xs={2}>
          <FormGroup row>
            <DebounceInput
              debounceTimeout={500}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              element={TextField}
              id={`search-text`}
              label=""
              value={searchText}
              type={'text'}
              placeholder="搜尋"
              variant="outlined"
              margin="dense"
              fullWidth
              style={{ marginLeft: 8 }}
              // disabled={isLoading}
              inputRef={searchRef}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={5} container alignItems="center">
          <ButtonGroup color="primary">
            <Button
              variant='outlined'
              onClick={() => {
                const base = moment(dates.current[0]).add(-3, 'days');
                const weekDates = Array.from({ length: displayDays.current }, (_, i) =>
                  base.clone().add(i, 'days').format('YYYY-MM-DD'),
                );
                onDateChange({
                  startStr: moment(weekDates[0]).tz(TIME_ZONE).toISOString(),
                  endStr: moment(weekDates[weekDates.length - 1]).tz(TIME_ZONE).toISOString(),
                });
                dates.current = weekDates;
              }}
            >
              往前3天
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                const base = moment(dates.current[0]).add(3, 'days');
                const weekDates = Array.from({ length: displayDays.current }, (_, i) =>
                  base.clone().add(i, 'days').format('YYYY-MM-DD'),
                );
                onDateChange({
                  startStr: moment(weekDates[0]).tz(TIME_ZONE).toISOString(),
                  endStr: moment(weekDates[weekDates.length - 1]).tz(TIME_ZONE).toISOString(),
                });
                dates.current = weekDates;
              }}
            >
              往後3天
            </Button>
            <Button
              variant={displayDays.current === 7 ? 'contained' : 'outlined'}
              onClick={() => {
                displayDays.current = 7;
                const base = moment(dates.current[0]);
                const weekDates = Array.from({ length: displayDays.current }, (_, i) =>
                  base.clone().add(i, 'days').format('YYYY-MM-DD'),
                );
                onDateChange({
                  startStr: moment(weekDates[0]).tz(TIME_ZONE).toISOString(),
                  endStr: moment(weekDates[weekDates.length - 1]).tz(TIME_ZONE).toISOString(),
                });
                dates.current = weekDates;
              }}
            >
              顯示7天
            </Button>
            <Button
              variant={displayDays.current === 14 ? 'contained' : 'outlined'}
              onClick={() => {
                displayDays.current = 14;
                const base = moment(dates.current[0]);
                const weekDates = Array.from({ length: displayDays.current }, (_, i) =>
                  base.clone().add(i, 'days').format('YYYY-MM-DD'),
                );
                onDateChange({
                  startStr: moment(weekDates[0]).tz(TIME_ZONE).toISOString(),
                  endStr: moment(weekDates[weekDates.length - 1]).tz(TIME_ZONE).toISOString(),
                });
                dates.current = weekDates;
              }}
            >
              顯示14天
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        { filteredOrders && filteredOrders.length !== 0 &&
        <div className={classes.app}>
          <div className={classes.centerContent}>
            <BoardView
              orders={filteredOrders}
              dates={dates.current}
              deliveryStaffOptions={deliveryStaffOptions}
            />
          </div>
        </div>}
      </Grid>
    </Grid>);
}

ScheduleBoard.propTypes = {
  orders: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  deliveryStaffOptions: PropTypes.array,
};
