import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
// import { Hub } from 'aws-amplify';

import { request } from 'utilities/graph';
import {
  getIssuesByClientByStatus,
  getIssuesByStatusByCreatedAt,
  getIssuesByCountyByStatus,
} from 'graphql/queries';
import { AUTO_UPDATE_INTERVAL_IN_MILLISECONDS } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';

export default function IssueCenterBadge({ children }) {
  const [badgeContent, setBadgeContent] = useState(0);

  const update = async () => {
    // global.logger.debug('Update issue center badge content');
    const county = cache.get('app:location');
    const clientId = cache.get('app:facilityId');

    let records = [];
    if (clientId) {
      const params = {
        clientId,
        status: { eq: 'pending' },
        limit: 10,
        sortDirection: 'DESC',
      };
      const { data: { getIssuesByClientByStatus: { items: results } } } = await request(getIssuesByClientByStatus, params);
      records = results;
    } else
    if (county) {
      const params = {
        county,
        status: { eq: 'pending' },
        limit: 10,
        sortDirection: 'DESC',
      };
      const { data: { getIssuesByCountyByStatus: { items: results } } } = await request(getIssuesByCountyByStatus, params);
      records = results;
    } else {
      const params = {
        status: 'pending',
        limit: 10,
        sortDirection: 'DESC',
      };
      const { data: { getIssuesByStatusByCreatedAt: { items: results } } } = await request(getIssuesByStatusByCreatedAt, params);
      records = results;
    }

    setBadgeContent(records.length);
  };

  useEffect(() => {
    update();

    const interval = setInterval(update, AUTO_UPDATE_INTERVAL_IN_MILLISECONDS);

    return () => {
      // Hub.remove('app', appListener);
      clearInterval(interval);
    };
  }, []);

  return (
    <Tooltip
      title={`尚未處理的問題回報`}
      placement="right"
    >
      <Badge
        badgeContent={badgeContent}
        max={9}
        color="secondary"
      >
        {children}
      </Badge>
    </Tooltip>);
}

IssueCenterBadge.propTypes = {
  children: PropTypes.element,
};
