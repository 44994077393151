import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckboxesWithSelectAll = (props) => {
  const { options, value = [], onChange, label } = props;
  const allOptions = options.enumOptions.map((option) => option.value);

  const handleSelectAll = () => {
    if (value.length === allOptions.length) {
      onChange([]);
    } else {
      onChange(allOptions);
    }
  };

  const handleChange = (optionValue) => {
    const newValue = value.includes(optionValue) ?
      value.filter((v) => v !== optionValue) :
      [...value, optionValue];
    onChange(newValue);
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" color='textSecondary'>
          {label}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="agreeCancelInvoice"
              checked={value.length === allOptions.length}
              onChange={handleSelectAll}
            />}
          label={
            <Typography variant="body1">
              全選
            </Typography>
          }
          style={{ marginLeft: 10 }}
        />
      </Box>
      {options.enumOptions.map((option) => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              name="agreeCancelInvoice"
              checked={value.includes(option.value)}
              onChange={() => handleChange(option.value)}
            />}
          label={
            <Typography variant="body1">
              {option.label}
            </Typography>
          }
        />
      ))}
    </div>
  );
};

CheckboxesWithSelectAll.propTypes = {
  options: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default CheckboxesWithSelectAll;
