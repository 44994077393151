import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { GOOGLE_KEY } from 'credentials.js';
import { formatAddress } from 'utilities/format';

const useStyles = makeStyles((theme) => ({
  iframe: {
    border: 0,
  },
}));

export default function Directions({ startAddress, endAddress, options = {} }) {
  const classes = useStyles();
  const [url, setUrl] = useState();

  const { width, height } = Object.assign({
    width: '100%',
    height: 360,
  }, options);

  useEffect(() => {
    if (!startAddress || !endAddress) return;

    const origin = startAddress.lat && startAddress.lng ?
      `${startAddress.lat},${startAddress.lng}` : formatAddress(startAddress, { ignoreNote: true, includeZipCode: false });
    const destination = endAddress.lat && startAddress.lng ?
      `${endAddress.lat},${endAddress.lng}` : formatAddress(endAddress, { ignoreNote: true, includeZipCode: false });

    global.logger.debug({ origin, destination });

    const queryParams = [
      `key=${GOOGLE_KEY}`,
      'language=zh-TW',
      'region=tw',
      `origin=${origin}`,
      `destination=${destination}`,
      `mode=driving`,
      `avoid=tolls|highways|ferries`,
    ].join('&');
    const url = `https://www.google.com/maps/embed/v1/directions?${queryParams}`;
    global.logger.debug(url);
    setUrl(url);
  }, [startAddress, endAddress]);

  if (!url) {
    return (
      <div style={{ width, height }}>
        <img src="/images/no-url-map.png" height={height} width={width} />
      </div>);
  }

  return (
    <iframe
      title="directions"
      width={width}
      height={height}
      className={classes.iframe}
      src={url}
      allowFullScreen />
  );
}

Directions.propTypes = {
  options: PropTypes.object,
  startAddress: PropTypes.any,
  endAddress: PropTypes.any,
};
