import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth, Hub } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import CustomAuthenticator from 'components/Auth/CustomAuthenticator';
import to from 'await-to-js';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  img: {
    maxWidth: '100%',
    maxHeight: `calc(100vh - 116px)`,
  },
}));

export default function LandingPage({ iosLink, androidLink, Authenticator, initialAuthState = 'signIn' }) {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentAuthState, setCurrentAuthState] = useState('signIn');
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  let customer;
  if (window.location.hostname.toLowerCase() === 'pingtung.silvergatedelivery.com') {
    customer = 'pingtung';
  }

  const authListener = ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn_view':
        if (data.currentAuthState !== 'signIn') {
          setCurrentAuthState('signIn');
        }
        break;
      case 'signUp_view':
        if (data.currentAuthState !== 'signUp') {
          setCurrentAuthState('signUp');
        }
        break;
      default:
    }
  };

  useEffect(() => {
    (async () => {
      const [, cognitoUser] = await to(Auth.currentAuthenticatedUser({ bypassCache: true }));
      if (cognitoUser) {
        setIsLoggedIn(true);
      }
    })();
    Hub.listen('app', authListener);
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let titleImage = '/images/title.png';
  if (customer === 'pingtung') {
    titleImage = '/images/title_pingtung.png';
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }} className={classes.content}>
      <Hidden smDown={true}>
        <Box sx={{ display: 'flex', flexGrow: 3, alignItems: 'center', justifyContent: 'center' }}>
          <Box sx = {{ maxWidth: '1500px' }}>
            <img src="/images/architecture.png" alt="Architecture" className={classes.img}/>
          </Box>
        </Box>
      </Hidden>
      <Box sx={{ display: 'flex', flexGrow: 2, justifyContent: { sm: 'center', md: 'start' }, overflow: 'auto' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: '500px' }, alignItems: 'center', justifyContent: 'space-between' }}>
          {(currentAuthState === 'signIn' || innerHeight > 1050) && (
            <Box sx={{ display: 'flex', flexGrow: isLoggedIn ? 1 : 0, alignItems: 'center' }}>
              <img src={titleImage} alt="Title" className={classes.img}/>
            </Box>
          )}
          {!isLoggedIn &&
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              {Authenticator ?
                <Authenticator initialAuthState={initialAuthState} currentAuthState={currentAuthState} /> :
                <CustomAuthenticator initialAuthState={initialAuthState} currentAuthState={currentAuthState} />
              }
            </Box>
          }
          {(currentAuthState === 'signIn' || innerHeight > 1100) && (
            <img src="/images/connection.png" alt="Connection" className={classes.img}/>
          )}
        </Box>
      </Box>
    </Box>
  );
}

LandingPage.propTypes = {
  iosLink: PropTypes.string,
  androidLink: PropTypes.string,
  Authenticator: PropTypes.func,
  initialAuthState: PropTypes.string,
};
