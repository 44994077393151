import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import InfoCard from '../Card/InfoCard';
import CustomTimeline from 'components/CustomTimeline';
import {
  deliveryStaffOrderStatus,
} from '@silvergatedelivery/constants';

export default function DeliveryStaffOrdersCard({ data = [], ...props }) {
  const render = (item) => (<React.Fragment>
    <Typography>
      {deliveryStaffOrderStatus.find(({ value })=>value === item.status).label} {item.deliveryStaff.name}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {item.updatedBy}
    </Typography>
  </React.Fragment>);

  return (
    <InfoCard title="接單紀錄" mappings={[]} data={{}} {...props}>
      <CustomTimeline
        data={data}
        render={render}
        sortKey="updatedAt"
      />
    </InfoCard>
  );
}

DeliveryStaffOrdersCard.propTypes = {
  data: PropTypes.array,
};
