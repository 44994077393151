import Colors from './Colors';

export default [
  {
    label: '接受訂單',
    value: 'accepted',
    color: Colors.active,
  },
  {
    label: '送單中',
    value: 'delivering',
    color: Colors.active,
  },
  {
    label: '已送達',
    value: 'delivered',
    color: Colors.active,
  },
  {
    label: '已送達(超時)',
    value: 'deliveredButNotOnTime',
    color: Colors.active,
  },
  {
    label: '完成(填寫送餐日誌)',
    value: 'completed',
    color: Colors.primary,
  },
  {
    label: '系統取消',
    value: 'cancelled',
    color: Colors.dark,
  },
  {
    label: '因故無法完成',
    value: 'abandoned',
    color: Colors.dark,
  },
];
