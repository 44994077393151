import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CustomDialogTitle = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default function AutoSizeDialog({
  children,
  open=false,
  title = '新增資料',
  onClose,
  hideCloseButton = false,
  maxWidth = 'md',
  exitConfirm = false,
}) {
  const theme = useTheme();

  // 當螢幕寬度小於 sm 時，設置為全螢幕
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    if (exitConfirm && !window.confirm('確定要關閉嗎？')) {
      return;
    }
    onClose && onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        aria-labelledby="auto-size-dialog-title"
        PaperProps={{
          style: !fullScreen ? { height: '700px' } : {},
        }}
      >
        <CustomDialogTitle id="form-dialog-titlse" onClose={hideCloseButton ? undefined : handleClose}>
          {title}
        </CustomDialogTitle>
        {children}
      </Dialog>
    </React.Fragment>
  );
}

AutoSizeDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  exitConfirm: PropTypes.bool,
};
