import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddressDateTimeSettings from '../AddressDateTimeSettings';
import { formatAddress } from 'utilities/format';
import { Storage } from 'aws-amplify';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import moment from 'moment';
import { TIME_ZONE } from '@silvergatedelivery/constants';

import {
  // counties,
  countiesGeoJson,
} from '@silvergatedelivery/constants';

import { asyncListAll } from 'utilities/graph';
import { getRestaurantsByClientByIsActive, getRestaurantHolidayByRestaurantByYear } from 'graphql/queries';
import {
  getRestaurantsByCountyByIsActive,
  getRestaurantMealsByRestaurantByIsActive,
} from './queries';
// import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';
import Loading from 'components/Loading';
import { sortBy } from 'utilities/sorting';
import MealItemDialog from './MealItemDialog';
import cache from 'utilities/cache';
import RestaurantMeals from './RestaurantMeals';
import { compareObjects } from 'utilities/sorting';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#00913A',
    width: '100%',
    marginBottom: 10,
  },
  body: {
    maxHeight: 'calc(100% - 62px)',
    padding: 16,
    overflow: 'auto',
  },
}));

const addNeighbors = (county) => {
  const uniqueNeighbors = new Set();

  const { neighbors } = countiesGeoJson.features.find((x) => x.properties.name === county);
  neighbors.forEach((neighbor) => uniqueNeighbors.add(neighbor));
  uniqueNeighbors.add(county);

  return [...uniqueNeighbors];
};

const getDateTimeLabel = () => {
  const { days } = cache.get('public:menu2-date') || {};
  const time = cache.get('public:menu2-time');
  if (days?.length > 0 && time) {
    if (time.lunch !== '不需要午餐' && time.dinner !== '不需要晚餐') {
      return `預定${days.length}天的午餐與晚餐`;
    } else if (time.lunch !== '不需要午餐') {
      return `預定${days.length}天的午餐`;
    } else if (time.dinner !== '不需要晚餐') {
      return `預定${days.length}天的晚餐`;
    }
  }
};

export default function RestaurantMealList() {
  const classes = useStyles();
  const [restaurants, setRestaurants] = useState([]);
  const [meals, setMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedMeal, setSelectedMeal] = useState();
  const [selectedRestaurant, setSelectedRestaurant] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [address, setAddress] = useState(cache.get('public:menu2-address'));
  const [addedMeals, setAddedMeals] = useState(cache.get('public:menu2-meals') || []); // TODO: 處理餐廳和餐點不一致的問題
  const [dateTimeLabel, setDateTimeLabel] = useState(getDateTimeLabel());

  const handleFilter = (key) => (values) => {
    global.logger.debug({ key, values });

    const newFilters = {
      ...filters,
      [key]: Array.isArray(values) ? values.map(({ label }) => label) : values,
    };
    setFilters(newFilters);
  };

  const handleAddMeal = (data) => {
    if (!data) {
      return;
    }
    const { meal, quantity } = data;
    const existingAddedMealIndex = addedMeals.findIndex((addedMeal) => addedMeal.meal.id === meal.id);
    if (existingAddedMealIndex !== -1) {
      const newAddedMeals = JSON.parse(JSON.stringify(addedMeals));
      if (quantity === 0) {
        newAddedMeals.splice(existingAddedMealIndex, 1);
      } else {
        newAddedMeals[existingAddedMealIndex].quantity = quantity;
      }
      setAddedMeals(newAddedMeals);
      cache.remove('public:menu2-orders');
      cache.set('public:menu2-meals', newAddedMeals);
    } else {
      setAddedMeals([...addedMeals, data]);
      cache.remove('public:menu2-orders');
      cache.set('public:menu2-meals', [...addedMeals, data]); // TODO: 用cache會不會有偷改的風險?
    }
  };

  const onMealClick = (meal, restaurant) => {
    global.logger.debug('handleAddMeal', meal, restaurant);
    let openMealItemDialog = false;
    if (addedMeals.length !== 0) {
      const existingAddedMeal = addedMeals.find((addedMeal) => addedMeal.meal.id === meal.id);
      openMealItemDialog = true;
      if (existingAddedMeal) {
        setSelectedQuantity(existingAddedMeal.quantity);
      } else {
        setSelectedQuantity(1);
      }
      // }
    } else {
      openMealItemDialog = true;
      setSelectedQuantity(1);
    }

    if (openMealItemDialog) {
      setSelectedMeal(meal);
      setSelectedRestaurant(restaurant);
    }
  };

  useEffect(() => {
    if (!address) {
      setRestaurants([]);
      setMeals([]);
      return;
    }

    (async () => {
      setIsLoading(true);
      let restaurants = [];
      let meals = [];
      // const { address: elderAddress } = cache.get('public:menu2-elder') || {};
      const locationsAndNeighbors = addNeighbors(address.county);
      const targetClientId = cache.get('public:menu2-target-client-id');
      if (targetClientId) {
        restaurants = await asyncListAll(getRestaurantsByClientByIsActive, {
          clientId: targetClientId,
          isActive: {
            eq: 1,
          },
          filter: {
            isPublic: {
              eq: true,
            },
          },
        });
      } else {
        await Promise.all(locationsAndNeighbors.map(async (county) => {
          const countyRestaurants = await asyncListAll(getRestaurantsByCountyByIsActive, {
            county,
            isActive: {
              eq: 1,
            },
            filter: {
              isPublic: {
                eq: true,
              },
            },
          });
          restaurants = [...restaurants, ...countyRestaurants];
        }));
      }

      const elderAddress = cache.get('public:menu2-address');
      const nearbyRestaurants = restaurants.filter((restaurant) => {
        if (restaurant.serviceAreas && restaurant.serviceAreas.length !== 0) {
          if (restaurant.serviceAreas.some((area) => compareObjects(area, {
            county: elderAddress.county,
            district: elderAddress.district,
            zipCode: elderAddress.zipCode,
          }))) {
            return true;
          }
          return false;
        }
        const from = point([restaurant.address.lat, restaurant.address.lng]);
        const to = point([elderAddress.lat, elderAddress.lng]);
        const distanceInKm = distance(from, to);
        return distanceInKm <= 8; // 顯示方圓8KM之內的餐廳
      });

      const start = moment().tz(TIME_ZONE).year();
      const end = start + 1;
      await Promise.all(nearbyRestaurants.map(async (restaurant) => {
        const restaurantId = restaurant.id;
        const [restaurantMeals, restaurantHolidays] = await Promise.all([
          asyncListAll(getRestaurantMealsByRestaurantByIsActive, {
            restaurantId,
            isActive: {
              eq: 1,
            },
            filter: {
              isPublic: {
                eq: true,
              },
            },
          }),
          asyncListAll(getRestaurantHolidayByRestaurantByYear, {
            restaurantId,
            year: {
              between: [start, end],
            },
          }),
        ]);
        await Promise.all(restaurantMeals.map(async (item) => {
          if (item.imageS3Keys && item.imageS3Keys[0]) {
            item.imageUrl = await Storage.get(item.imageS3Keys[0]);
          }
          item.tagsString = item.tags.items.map((item) => item.tag.label).join(', ');
          item.restaurant = {
            address: restaurant.address,
            name: restaurant.name,
            openingHours: restaurant.openingHours,
            holidays: restaurantHolidays,
          };
        }));
        restaurant.meals = restaurantMeals.sort(sortBy('updatedAt', true));
        restaurant.holidays = restaurantHolidays;
        meals = [...meals, ...restaurantMeals];
      }));

      setRestaurants(nearbyRestaurants);
      setMeals(meals.sort(sortBy('updatedAt', true)));

      setIsLoading(false);
    })();
  }, [address]);

  global.logger.debug('restaurants & meals', { restaurants, meals });

  const onAddressDateTimeSettingsUpdated = () => {
    setAddress(cache.get('public:menu2-address'));
    setDateTimeLabel(getDateTimeLabel());
  };

  return (
    <div style={{ height: '100%', maxHeight: '100%' }}>
      <Grid container direction='row' justifyContent='space-between' className={classes.header}>
        <Grid item xs={10}>
          <Grid container direction='row' alignItems='center'>
            <AccessTimeIcon fontSize='small' style={{ color: 'white', marginLeft: 5 }}/>
            <Typography variant='subtitle1' align='left' style={{ color: 'white', marginLeft: 5 }}>
              {dateTimeLabel || '請設定日期與時間'}
            </Typography>
          </Grid>
          <Grid container alignItems='center'>
            <RoomOutlinedIcon fontSize='small' style={{ color: 'white', marginLeft: 5 }}/>
            <Typography noWrap variant='subtitle1' align='left' style={{
              color: 'white',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '90%',
              marginLeft: 5,
            }}>
              {address ? formatAddress(address) : '請設定外送地址'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2} container justifyContent='flex-end'>
          <AddressDateTimeSettings onUpdate={onAddressDateTimeSettingsUpdated}/>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.body}>
        {/* <Grid item xs={12} >
          <Typography variant='h6' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
            今天想來點什麼？ (filter)
          </Typography>
        </Grid> */}
        {meals.length !== 0 && <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              id='mealList_searchByText'
              label={'搜尋餐點名稱'}
              variant='outlined'
              value={filters.text}
              onChange={(e)=>{
                handleFilter('text')(e.target.value.toLowerCase());
              }}
              fullWidth
              disabled={isLoading}
            />
          </Grid>
        </Grid>}
        {/* <Grid item>
          <Typography variant='subtitle1' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
            精選推薦專區？
          </Typography>
          <Typography variant='subtitle1' color='textPrimary' align='left' style={{ fontWeight: 'bold' }} >
            牙齒不好免擔心？
          </Typography>
        </Grid> */}
        <Grid item container spacing={2}>
          {selectedMeal &&
            <MealItemDialog
              open={!!selectedMeal}
              restaurant={selectedRestaurant}
              meal={selectedMeal}
              quantity={selectedQuantity}
              onClose={(data) => {
                handleAddMeal(data);
                setSelectedMeal();
                setSelectedRestaurant();
                setSelectedQuantity(1);
              }}
            />
          }
          {isLoading ? <Loading fullScreen={false} /> : restaurants.map((restaurant) => {
            return <RestaurantMeals
              key={restaurant.id}
              restaurant={restaurant}
              addedMeals={addedMeals?.filter(({ meal }) => meal.restaurantId === restaurant.id) || undefined}
              onMealClick={onMealClick}
              filters={filters}
            />;
          })}
          {!isLoading && meals.length === 0 &&
            <Grid
              container
              item xs={12}
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={0}
            >
              <p>
                目前尚未開放此區域或是已超出配送範圍，請加入銀色大門詢問客服人員申請。<br/>
                Line：@oqm7360u <br/>
                <br/>
                <Button
                  variant='outlined'
                  color='primary'
                  href={'https://lin.ee/8wpxrBL'}
                  target='_blank'
                >
                  官方Line好友連結
                </Button>
              </p>
            </Grid>}
        </Grid>
      </Grid>
    </div>
  );
}
