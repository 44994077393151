import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import StepButton from '@material-ui/core/StepButton';
// import Grid from '@material-ui/core/Grid';

import CategorySelect from './CategorySelect';
import ElderInfo from './ElderInfo';
import RestaurantMealList from './RestaurantMealList';
// import DateSelect from './DateSelect';
import CompleteOrder from './CompleteOrder';
import cache from 'utilities/cache';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    // padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    borderRadius: 0,
  },
}));

export default function HorizontalLinearStepper() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [hasInit, setHasInit] = useState(false);

  function getSteps() {
    return [
      {
        key: 'category',
        label: '首頁',
        component: CategorySelect,
      },
      {
        key: 'elder',
        label: '地址',
        component: ElderInfo,
      },
      {
        key: 'meals',
        label: t('餐點'),
        component: RestaurantMealList,
      },
      {
        key: 'order',
        label: '完成訂單',
        component: CompleteOrder,
      },
    ];
  }

  // const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };

  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(activeStep);
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  useEffect(() => {
    if (hasInit) return;

    let activeStep = 0;

    steps.forEach((item, index) => {
      const cacheKey = `public:menu-${item.key}`;
      const isActivatedStep = cache.get(cacheKey) ? true : false;
      if (isActivatedStep) {
        activeStep = index;
      }
    });

    if (activeStep > 0) {
      const nextStep = activeStep + 1 < steps.length ? activeStep + 1 : activeStep;
      setActiveStep(nextStep);
    }
    setHasInit(true);
  }, [steps, hasInit]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeStep]);

  return (
    <Container maxWidth={'md'} className={classes.root} data-test-id="menu-container">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(({ label }, index) => {
          const stepProps = {};
          {/* const labelProps = {}; */}
          return (
            <Step key={label} {...stepProps}>
              {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
              <StepButton>
                {label}
              </StepButton>

            </Step>
          );
        })}
      </Stepper>
      <Paper className={classes.paper} data-id="menu-paper-container">
        {steps.map((item, index) => {
          if (activeStep !== index) return null;

          const cacheKey = `public:menu-${item.key}`;
          const formData = cache.get(cacheKey) || {};

          return (<div key={index} data-id="menu-component-container">
            <item.component
              formData={formData}
              onComplete={(data) => {
                if (activeStep === index) {
                  cache.set(`public:menu-${item.key}`, data);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
              }}
              onStepBack={(data) => {
                if (activeStep === index) {
                  if (data) {
                    cache.set(`public:menu-${item.key}`, data);
                  }
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }
              }}
            />
          </div>);
        })}
      </Paper>
    </Container>
  );
}
