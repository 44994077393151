import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { Storage } from 'aws-amplify';
import { v1 as uuidv1 } from 'uuid';
// import { tag as allTags } from '@silvergatedelivery/constants';

import { request } from 'utilities/graph';
import {
  getRestaurantMeal,
} from 'graphql/queries';
import {
  createRestaurantMeal,
  updateRestaurantMeal,
  createRestaurantMealTag,
  deleteRestaurantMealTag,
} from 'graphql/mutations';
import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';
import RestaurantMealGallery from './RestaurantMealGallery';
import cache from 'utilities/cache';

const defaultData = {
  name: '',
  description: '',
  price: 0,
  cost: 0,
};

export default function RestaurantMealDialog({
  mode = 'add',
  open: inOpen = false,
  restaurantId,
  buttonStyle = {},
  mealItem,
  buttonSize = 'medium',
  onUpdate,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(inOpen);
  const [data, setData] = useState(defaultData);
  const [newTags, setNewTags] = useState([]);
  const [restaurantMealId, setRestaurantMealId] = useState();
  const [currentImageS3Keys, setCurrentImageS3Keys] = useState([]);
  const [newImageS3Keys, setNewImageS3Keys] = useState([]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const username = cache.get('app:username');

    const {
      name,
      description,
      price,
      cost,
      isPublic,
    } = data;

    const mealData = {
      id: restaurantMealId,
      restaurantId,
      isActive: 1,
      isPublic,
      name,
      description,
      price,
      cost,
      previewS3Keys: [],
      imageS3Keys: newImageS3Keys,
      createdBy: username,
      updatedBy: username,
    };

    let savedData;
    if (mode === 'add') {
      const { data: { createRestaurantMeal: result } } = await request(createRestaurantMeal, { input: mealData });
      savedData = result;
    } else {
      const { data: { updateRestaurantMeal: result } } = await request(updateRestaurantMeal, { input: mealData });
      savedData = result;
    }

    global.logger.debug({ mealItem, newTags });

    const toCreateJoinData = [];
    const toRemoveJoinData = [];

    mealItem = mealItem || { tags: { items: [] } };
    mealItem.tags.items.forEach(({ id, tagId }) => {
      const matched = newTags.find(({ id }) => id === tagId);
      if (!matched) {
        toRemoveJoinData.push({ id });
      }
    });

    newTags.forEach(({ id }) => {
      const matched = mealItem.tags.items.find(({ tagId }) => id === tagId);
      if (!matched) {
        toCreateJoinData.push({
          restaurantMealId: savedData.id,
          tagId: id,
          createdBy: username,
          updatedBy: username,
        });
      }
    });

    global.logger.debug('toCreateJoinData', toCreateJoinData);
    global.logger.debug('toRemoveJoinData', toRemoveJoinData);

    await Promise.all([
      ...toCreateJoinData.map((item) => {
        return request(createRestaurantMealTag, { input: item });
      }),
      ...toRemoveJoinData.map((item) => {
        return request(deleteRestaurantMealTag, { input: item });
      }),
    ]);

    const { data: { getRestaurantMeal: returnData } } = await request(getRestaurantMeal, { id: savedData.id });

    returnData.imageUrl = returnData.imageS3Keys[0] ? await Storage.get(returnData.imageS3Keys[0]) : mealItem.imageUrl;

    onUpdate(returnData);

    setIsLoading(false);
    handleClose();
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const reset = () => {
    setNewTags([]);
    setRestaurantMealId(undefined);
    setCurrentImageS3Keys([]);
    setNewImageS3Keys([]);
  };

  const title = mode === 'add' ? `新增${t('餐點')}` : `更新${t('餐點')}`;

  useEffect(() => {
    if (!open) return;

    reset();
    if (mealItem) {
      setData(mealItem);
      setNewTags(mealItem.tags.items.map(({ tag }) => tag));
      setRestaurantMealId(mealItem.id);
      setCurrentImageS3Keys(mealItem.imageS3Keys || []);
      setNewImageS3Keys(mealItem.imageS3Keys || []);
    } else {
      setData(defaultData);
      setRestaurantMealId(uuidv1());
    }
  }, [mealItem, open]);

  return (
    <Fragment>
      <Tooltip title={title} style={buttonStyle}>
        <IconButton
          data-testid={mode}
          aria-label={mode}
          onClick={() => setOpen(true)}
          size={buttonSize}
        >
          {mode === 'add' ? <AddIcon fontSize={'inherit'} /> : <EditIcon fontSize={'inherit'} />}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('餐點')}
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.isPublic}
                name={'isPublic'}
                onChange={(e) => {
                  setData({ ...data, isPublic: !data.isPublic });
                } }
              />
            }
            label={<Typography variant="body2" color="textSecondary" component="p">
              {'公開可供客戶自由訂購'}
            </Typography>}
          />

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名稱"
            type="text"
            value={data.name}
            fullWidth
            required
            onChange={(e)=>setData({ ...data, name: e.target.value }) }
          />
          <TextField
            margin="dense"
            id="description"
            label="描述"
            type="text"
            value={data.description}
            fullWidth
            required
            onChange={(e)=>setData({ ...data, description: e.target.value }) }
          />
          <TextField
            margin="dense"
            id="price"
            label="單價"
            type="number"
            value={data.price}
            fullWidth
            required
            onChange={(e)=>setData({ ...data, price: e.target.value }) }
            onWheel={(e) => e.currentTarget.blur()}
          />
          <TextField
            margin="dense"
            id="cost"
            label="進價"
            type="number"
            value={data.cost}
            fullWidth
            required
            onChange={(e)=>setData({ ...data, cost: e.target.value }) }
            onWheel={(e) => e.currentTarget.blur()}
          />
          <DataJoinEditorInput
            title={'營養標籤'}
            mode={'餐點-營養'}
            joinData={mealItem ? mealItem.tags.items : []}
            defaultValues={mealItem ? mealItem.tags.items.map(({ tag }) => tag.label) : []}
            onChange={setNewTags}
            onUpdateOptions={()=>{}}
            disabled={isLoading}
            showHelperText={false}
          />
          {/* {
            Object.keys(allTags['餐點']).map((key)=>(
              <DataJoinEditorInput
                key={key}
                title={`${key}標籤`}
                mode={`餐點-${key}`}
                joinData={mealItem ? mealItem.tags.items.filter(({ tag }) => tag.subcategory === key) : []}
                defaultValues={mealItem ? mealItem.tags.items.filter(({ tag }) => tag.subcategory === key).map(({ tag }) => tag.label) : []}
                onChange={setNewTags}
                onUpdateOptions={() => {}}
                disabled={isLoading}
                showHelperText={false}
              />
            ))
          } */}
          {open &&
            <RestaurantMealGallery
              restaurantId={restaurantId}
              restaurantMealId={restaurantMealId}
              s3Keys={currentImageS3Keys}
              onUpdateS3Keys={setNewImageS3Keys}
            />}
          {/* {open && <DropZone setFiles={setFiles} />} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isLoading || !data.name || !data.description}>
            儲存
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

RestaurantMealDialog.propTypes = {
  mode: PropTypes.string,
  open: PropTypes.bool,
  restaurantId: PropTypes.string,
  buttonStyle: PropTypes.object,
  mealItem: PropTypes.object,
  buttonSize: PropTypes.string,
  onUpdate: PropTypes.func,
};
