import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  deliveryStaffTransactionStatus,
  deliveryStaffTransactionTypes,
} from '@silvergatedelivery/constants';

import Table from 'components/Table/Table';
import { getDeliveryStaffTransactionByDeliveryStaffIdByCreatedAt, listDeliveryStaffTransactions } from 'graphql/queries';
import { request } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import OrderDialog from 'components/Order/OrderDialog';
// import DeliveryStaffTransactionEditButton from 'forms/DeliveryStaffTransactionForm/DeliveryStaffTransactionEditButton';

const title = '交易紀錄';
const description = '';

export default function DeliveryStaffTransactionTable({ deliveryStaffId }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const options = {};

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: '類別',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return deliveryStaffTransactionTypes.find((item) => item.value === value).label;
        },

      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        filter: false,
        sort: true,
        customBodyRender(value) {
          return deliveryStaffTransactionStatus.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'amount',
      label: 'S Token',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'summary',
      label: '簡述',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'note',
      label: '備註',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'orderId',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(orderId) {
          if (!orderId) return;
          return (
            <OrderDialog id={orderId} />
          );
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        // TODO: use the data table with query generator
        let records;
        if (deliveryStaffId) {
          const {
            data: {
              getDeliveryStaffTransactionByDeliveryStaffIdByCreatedAt: { items },
            },
          } = await request(getDeliveryStaffTransactionByDeliveryStaffIdByCreatedAt, {
            deliveryStaffId,
            sortDirection: 'DESC',
            limit: 100,
          });
          records = items;
        } else {
          const { data: { listDeliveryStaffTransactions: { items } } } = await request(listDeliveryStaffTransactions, { limit: 100 });
          records = items;
        }

        setData(records.sort(sortBy('createdAt', true)));
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [deliveryStaffId, lastUpdatedAt]);

  return (
    <Table
      title={title}
      isLoading={isLoading}
      description={description}
      data={data}
      columns={columns}
      options={options}
      // editButton={DeliveryStaffTransactionEditButton}
      onRefresh={() => setLastUpdatedAt(Date.now())}
    />
  );
}

DeliveryStaffTransactionTable.propTypes = {
  deliveryStaffId: PropTypes.string,
};
