/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://oep3w7vmtzcfvkm2rsh2ccs7ji.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ljnkhukn2jcm5pi3mj3jx4bylm",
    "aws_cloud_logic_custom": [
        {
            "name": "webhook",
            "endpoint": "https://084udafmo7.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:06557d1d-323e-4b23-b2a7-bbdfa672a9c2",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_3Iau0wJur",
    "aws_user_pools_web_client_id": "41f3nrevsdv3cvsnem0pfc58kf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "silvergate639f5cfdeddb42f78ca0c4a2d0471c8f192221-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
