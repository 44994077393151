import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import CustomTimeline from 'components/CustomTimeline';
// import { getOrderEventsByOrderByCreatedAt } from 'graphql/queries';
// import { asyncListAll } from 'utilities/graph';
import InfoCard from '../Card/InfoCard';

// const mockupEvents = [
//   {
//     createdAt: '2020-09-28T00:00:00.000Z',
//     content: '客戶：下單',
//   },
//   {
//     createdAt: '2020-09-29T00:00:00.000Z',
//     content: '系統：降低訂單層級至2',
//   },
//   {
//     createdAt: '2020-09-30T00:00:00.000Z',
//     content: '系統：降低訂單層級至3',
//   },
//   {
//     createdAt: '2020-10-01T01:00:00.000Z',
//     content: '大使：接單',
//   },
//   {
//     createdAt: '2020-10-01T03:00:00.000Z',
//     content: '餐廳：可以取餐',
//   },
//   {
//     createdAt: '2020-10-01T03:30:00.000Z',
//     content: '大使：送餐中',
//   },
//   {
//     createdAt: '2020-10-01T04:30:00.000Z',
//     content: '大使：已送達',
//   },
//   {
//     createdAt: '2020-10-01T06:00:00.000Z',
//     content: '大使：關懷日誌',
//   },
// ];

export default function OrderEventCard({
  events = [],
  timelineOnly = false,
  hideDetails = false,
  hideSource = false,
}) {
  global.logger.debug('events', events);
  const render = (item = {}) => (<React.Fragment>
    <Typography variant="body2">
      {item.content}
    </Typography>
    {!hideSource && <Typography variant="body2" color="textSecondary">
      {item.createdBy}
    </Typography>}
  </React.Fragment>);

  const Timeline = () => <CustomTimeline
    data={events
      .filter(({ content }) => !content.startsWith(`{\"`)) /* eslint-disable-line no-useless-escape */
      .map((event) => {
        if (hideDetails) {
          event.content = event.content.split(' 送餐大使(')[0];
        }
        return event;
      })
    }
    render={render}
  />;

  if (timelineOnly) {
    return (<Timeline />);
  }

  return (
    <InfoCard title="歷史紀錄" mappings={[]} data={{}}>
      <>
        <Timeline />
      </>
    </InfoCard>
  );
}

OrderEventCard.propTypes = {
  events: PropTypes.array,
  timelineOnly: PropTypes.bool,
  hideDetails: PropTypes.bool,
  hideSource: PropTypes.bool,
};
