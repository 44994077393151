import ExcelJS from 'exceljs';
import { buildColumnsArray } from '../CountyPrsReport/workbooks/helpers';

const range = 'A:BZ';
const columnName = buildColumnsArray(range);

export default async function writeMonthlyStatistics(clientName, clientStatements, year, county, includeUndeliveredOrders=false, zip) {
  const yearROC = parseInt(year) - 1911;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${yearROC}年`);

  const fontName = 'Calibri';

  const disadvantagedTypeList = [];
  clientStatements.forEach(({ elderStatistics }) => {
    elderStatistics.forEach(({ disadvantagedTypes }) => {
      if (!disadvantagedTypeList.includes(disadvantagedTypes)) {
        disadvantagedTypeList.push(disadvantagedTypes);
      }
    });
  });
  if (disadvantagedTypeList.length === 0) {
    return;
  }
  worksheet.getCell('A1').value = '單位';
  worksheet.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell('A1').font = { name: fontName };
  worksheet.mergeCells(`B1:${columnName[disadvantagedTypeList.length * 5 - 1]}1`);
  worksheet.getCell('B1').value = clientName;
  worksheet.getCell('B1').alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell('B1').font = { name: fontName };
  worksheet.getRow(1).height = 17.25;

  disadvantagedTypeList.forEach((type, index) => {
    const colIndex = index * 5;
    const cols = [0, 1, 2, 3, 4].map((offset) => columnName[colIndex + offset]);
    worksheet.mergeCells(`${cols[0]}2:${cols[0]}3`);
    worksheet.getCell(`${cols[0]}2`).value = type;
    worksheet.getCell(`${cols[0]}2`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[0]}2`).font = { name: fontName };
    worksheet.mergeCells(`${cols[1]}2:${cols[2]}2`);
    worksheet.getCell(`${cols[1]}2`).value = '人數';
    worksheet.getCell(`${cols[1]}2`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[1]}2`).font = { name: fontName };
    worksheet.getCell(`${cols[1]}3`).value = '男';
    worksheet.getCell(`${cols[1]}3`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[1]}3`).font = { name: fontName };
    worksheet.getCell(`${cols[2]}3`).value = '女';
    worksheet.getCell(`${cols[2]}3`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[2]}3`).font = { name: fontName };
    worksheet.mergeCells(`${cols[3]}2:${cols[4]}2`);
    worksheet.getCell(`${cols[3]}2`).value = '人次';
    worksheet.getCell(`${cols[3]}2`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[3]}2`).font = { name: fontName };
    worksheet.getCell(`${cols[3]}3`).value = '男';
    worksheet.getCell(`${cols[3]}3`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[3]}3`).font = { name: fontName };
    worksheet.getCell(`${cols[4]}3`).value = '女';
    worksheet.getCell(`${cols[4]}3`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${cols[4]}3`).font = { name: fontName };
  });
  worksheet.getRow(2).height = 17.25;
  worksheet.getRow(3).height = 17.25;

  const dates = Array(12).fill('').map((_, i) => {
    const month = i + 1 < 10 ? '0' + (i + 1) : i + 1;
    return {
      label: `${i + 1}月`,
      month: `${year}-${month}`,
    };
  });
  const rows = [];
  dates.forEach((date) => {
    const statement = clientStatements.find(({ month }) => date.month === month);
    if (statement) {
      const row = {};
      row['月份'] = date.label;
      disadvantagedTypeList.forEach((type) => {
        const statementMale = statement.elderStatistics.filter(
          ({ disadvantagedTypes, gender }) => disadvantagedTypes === type && gender === 'male');
        const statementFemale = statement.elderStatistics.filter(
          ({ disadvantagedTypes, gender }) => disadvantagedTypes === type && gender === 'female');
        row[`人數 ${type} 男`] = statementMale.length;
        row[`人數 ${type} 女`] = statementFemale.length;
        row[`人次 ${type} 男`] = statementMale.reduce((prev, { orderCount, incompleteOrderCount=0 }) =>
          includeUndeliveredOrders ? prev + orderCount + incompleteOrderCount : prev + orderCount, 0);
        row[`人次 ${type} 女`] = statementFemale.reduce((prev, { orderCount, incompleteOrderCount=0 }) =>
          includeUndeliveredOrders ? prev + orderCount + incompleteOrderCount : prev + orderCount, 0);
      });
      rows.push(row);
    }
  });

  let rowIndex = 4;
  rows.forEach((row) => {
    disadvantagedTypeList.forEach((type, index) => {
      const colIndex = index * 5;
      const cols = [0, 1, 2, 3, 4].map((offset) => columnName[colIndex + offset]);
      worksheet.getCell(`${cols[0]}${rowIndex}`).value = row['月份'];
      worksheet.getCell(`${cols[0]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${cols[0]}${rowIndex}`).font = { name: fontName };

      worksheet.getCell(`${cols[1]}${rowIndex}`).value = row[`人數 ${type} 男`];
      worksheet.getCell(`${cols[1]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${cols[1]}${rowIndex}`).font = { name: fontName };

      worksheet.getCell(`${cols[2]}${rowIndex}`).value = row[`人數 ${type} 女`];
      worksheet.getCell(`${cols[2]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${cols[2]}${rowIndex}`).font = { name: fontName };

      worksheet.getCell(`${cols[3]}${rowIndex}`).value = row[`人次 ${type} 男`];
      worksheet.getCell(`${cols[3]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${cols[3]}${rowIndex}`).font = { name: fontName };

      worksheet.getCell(`${cols[4]}${rowIndex}`).value = row[`人次 ${type} 女`];
      worksheet.getCell(`${cols[4]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${cols[4]}${rowIndex}`).font = { name: fontName };
    });
    worksheet.getRow(rowIndex).height = 17.25;
    rowIndex += 1;
  });

  const bufferDataByDistrict = await workbook.xlsx.writeBuffer();

  zip.file(`${county}__統計數據.xlsx`, bufferDataByDistrict, { binary: true });
}
