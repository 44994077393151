import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { getDeliveryStaff } from 'graphql/queries';
import { createDeliveryStaffTransaction } from 'graphql/mutations';
import schema from './DeliveryStaffTransactionForm.schema.js';
import uiSchema from './uiSchema';

export default function DeliveryStaffTransactionForm({ ...props }) {
  const createFunc = async (data) => {
    global.logger.debug(data);
    const { data: { getDeliveryStaff: { username } } } = await request(getDeliveryStaff, { id: data.deliveryStaffId });
    data.username = username;

    await request(createDeliveryStaffTransaction, { input: data });
  };

  const updateFunc = async () => {};

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

DeliveryStaffTransactionForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
