import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { request } from 'utilities/graph';
import { toastr } from 'react-redux-toastr';
import {
  quoteMultiStopOrder, placeMultiStopOrder,
} from 'graphql/mutations';
import { formatAddress } from 'utilities/format';

export default function PlaceLalamoveMultiStopButton({
  onUpdate,
  ordersSelected,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [quoteOrderResult, setQuoteOrderResult] = useState();

  const handleSubmit = async () => {
    const orderIds = ordersSelected.current.map(({ id }) => id);
    setIsLoading(true);
    try {
      const { data: { placeMultiStopOrder: { message, errors, data } } } = await request(placeMultiStopOrder, { input: { orderIds } });
      if (message === 'success') {
        console.log(data);
        onUpdate && onUpdate(data);
      } else {
        toastr.error(errors[0]);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
    handleClose();
  };

  const onPlaceLalamoveOrder = (onUpdate) => {
    const orders = ordersSelected.current;
    if (orders.length <= 1) {
      toastr.error('請選擇一筆以上的訂單');
      return;
    }

    if (orders.some((order) => order.restaurantId !== orders[0].restaurantId)) {
      toastr.error('訂單的餐廳不一致');
      return;
    }

    if (orders.some((order) => order.date !== orders[0].date || order.mealSlot !== orders[0].mealSlot)) {
      toastr.error('訂單的送餐時間不一致');
      return;
    }

    if (orders.some((order) => order.deliveryStaffId && order.status !== 'cancelled')) {
      toastr.error('有訂單已經指定送餐大使');
      return;
    }

    if (orders.some((order) => !['waitingForDeliveryStaff', 'reMatchingDeliveryStaff', 'cancelled'].includes(order.status))) {
      toastr.error('訂單狀態必須是配對大使中或已經取消');
      return;
    }

    if (orders.length !== 0) {
      const restaurantId = orders[0].restaurantId;
      const elderIds = orders.map(({ elderId }) => elderId);
      setIsLoading(true);
      request(quoteMultiStopOrder, { input: { restaurantId, elderIds } })
        .then((quoteResult) => {
          setQuoteOrderResult(quoteResult.data.quoteMultiStopOrder.data);
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
        });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setQuoteOrderResult();
  };

  const convertMetersToKilometers = (meters) => {
    const kilometers = meters / 1000;
    const roundedKm = Math.round(kilometers * 10) / 10;
    return `${roundedKm}公里`;
  };

  return (
    <Fragment>
      <Button
        style={ { marginLeft: 10 } }
        variant="contained"
        color="primary"
        onClick={async () => {
          await onPlaceLalamoveOrder(onUpdate);
        }}
      >
        使用Lalamove多點配送
      </Button>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Lalamove 多點配送
        </DialogTitle>
        <DialogContent>
          {!quoteOrderResult && (
            <Grid container spacing={2} style={{ padding: 16 }}>
              <Grid item xs={12} container direction="row">
                <Typography variant="h6" color="textPrimary" >
                  估價中
                </Typography>
                <CircularProgress color="primary" size={24} style={{ marginLeft: 8 }} />
              </Grid>
            </Grid>
          )}
          {quoteOrderResult && quoteOrderResult.code !== 'SUCCESS' && (
            <Grid container spacing={2} style={{ padding: 16 }}>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary" >
                  估價失敗
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <Typography variant="body1" color="secondary" >
                  {quoteOrderResult.message}
                </Typography>
              </Grid>
            </Grid>
          )}
          {quoteOrderResult && quoteOrderResult.code === 'SUCCESS' && (
            <Grid container spacing={2} style={{ padding: 16 }}>
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary" >
                  估價結果
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="space-between">
                <Typography variant="body1" color="textPrimary" >
                  配送費用
                </Typography>
                <Typography variant="body1" color="textPrimary" >
                  {`$${quoteOrderResult.deliveryStaffFee}`}
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="space-between">
                <Typography variant="body1" color="textPrimary" >
                  配送距離
                </Typography>
                <Typography variant="body1" color="textPrimary" >
                  {convertMetersToKilometers(quoteOrderResult.distance)}
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <Typography variant="body1" color="textPrimary" >
                  配送順序
                </Typography>
              </Grid>
              <Grid item xs={12} container style={{ marginLeft: 20 }}>
                <Grid item xs={12} container justifyContent="space-between">
                  <Typography variant="body2" color="textPrimary" >
                    {`${ordersSelected.current[0].restaurant.name}(餐廳)`}
                  </Typography>
                  <Typography variant="body2" color="textPrimary" >
                    {formatAddress(ordersSelected.current[0].restaurant.address)}
                  </Typography>
                </Grid>
                {quoteOrderResult.deliveryQueue.map((elderId) => {
                  const { elder } = ordersSelected.current.find((e) => e.elderId === elderId);
                  return (
                    <Grid item xs={12} container key={elder.id} justifyContent="space-between">
                      <Typography variant="body2" color="textPrimary" >
                        {elder.name}
                      </Typography>
                      <Typography variant="body2" color="textPrimary" >
                        {formatAddress(elder.address, { ignoreNote: true })}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={isLoading || !(quoteOrderResult && quoteOrderResult.code === 'SUCCESS')}
          >
            確定配送
            {isLoading && (quoteOrderResult && quoteOrderResult.code === 'SUCCESS') &&
              <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

PlaceLalamoveMultiStopButton.propTypes = {
  onUpdate: PropTypes.func,
  ordersSelected: PropTypes.object,
};
