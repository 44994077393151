import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { formatDatetime } from 'utilities/format';
import InfoCard from '../Card/InfoCard';
import {
  deliveryStaffOrderStatus,
} from '@silvergatedelivery/constants';
import DeliveryStaffOrderPhoto from 'components/DeliveryStaffOrderPhoto';
import { deliveryResult } from '@silvergatedelivery/constants';

export default function DeliveryStaffOrderCard({ data = {} }) {
  const { t } = useTranslation();
  const { deliveryResult: inDeliveryResult } = data || {};
  const result = (deliveryResult.find(({ value }) => value === inDeliveryResult) || {}).label;

  const mappings = [
    {
      key: 'status',
      label: `${t('送餐')}狀態`,
      format: (value) => {
        return value ? deliveryStaffOrderStatus.find((item) => item.value === value).label : null;
      },
    },
    { key: 'deliveryPositionUpdatedAt', label: '目前位置最後更新於', format: (value) => formatDatetime(value, { second: true }) },
    { key: 'deliveryPosition', label: '目前位置', format: (position) => position ? `${position.latitude || ''}, ${position.longitude || ''}` : '' },
    { key: 'checkListIsComplete', label: `${t('送餐')}完成${result ? '(' + result + ')' : ''}` },
    { key: 'checkListReminder', label: t('生活提醒(氣候、保暖、補充水份)') },
    { key: 'checkListChat', label: t('跟長輩聊天關心(身體、生活)') },
    { key: 'diary', label: '關懷日誌' },
  ];

  return (
    <InfoCard title={`${i18n.t('送餐大使')}更新`} mappings={mappings} data={data}>
      <Grid container>
        {
          (data.photoS3Keys || []).map((s3Key)=>(
            <Grid item xs={12} md={4} key={s3Key} style={{ maxHeight: 150 }}>
              <DeliveryStaffOrderPhoto s3Key={s3Key} />
            </Grid>
          ))
        }
      </Grid>
    </InfoCard>
  );
}

DeliveryStaffOrderCard.propTypes = {
  data: PropTypes.object,
};
