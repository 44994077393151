// import streamSaver from 'streamsaver';
import { saveAs } from 'file-saver';

export const download = (filename, blob) => {
  // // const uInt8 = new TextEncoder().encode('StreamSaver is awesome');
  // const fileStream = streamSaver.createWriteStream(filename, {});
  // const writer = fileStream.getWriter();
  // writer.write(content);
  // writer.close();
  saveAs(blob, filename);
};
