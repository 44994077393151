import i18n from 'i18next';
import { getTranslatedOrderStatus } from 'utilities/translate';
import { getStartEndByPeriodName, getDatesBetween } from './helpers';

export const getChartDataMappings = (data = {}, inPeriod) => {
  const {
    orders,
    // prevOrders,
    issues,
    // prevIssues,
  } = data;

  const translatedOrderStatus = getTranslatedOrderStatus();
  const [period] = inPeriod.split('/');

  const issueOrderIds = [];
  issues.forEach(({ orderId }) => {
    if (orderId && !issueOrderIds.includes(orderId)) {
      issueOrderIds.push(orderId);
    }
  });

  // const prevIssueOrderIds = [];
  // prevIssues.forEach(({ orderId }) => {
  //   if (orderId && !prevIssueOrderIds.includes(orderId)) {
  //     prevIssueOrderIds.push(orderId);
  //   }
  // });

  const lunchOrders = orders.filter(({ mealSlot }) => mealSlot === 'lunch');
  const dinnerOrders = orders.filter(({ mealSlot }) => mealSlot === 'dinner');

  // process data
  const chartDataMappings = {
    totalOrders: {
      type: 'summary',
      width: 3,
      title: '訂單數',
      value: orders.length,
      // prevValue: prevOrders.length,
    },
    totalOrdersValue: {
      type: 'summary',
      width: 3,
      title: '訂單總金額',
      unit: '$',
      value: orders.reduce((num, { total }) => {
        return num + total;
      }, 0),
      // prevValue: prevOrders.reduce((num, { total }) => {
      //   return num + total;
      // }, 0),
    },
    orderIssueRatio: {
      type: 'summary',
      width: 3,
      title: '訂單事故比率',
      appendix: '%',
      value: Math.round((orders.length > 0 ? issueOrderIds.length / orders.length : 0) * 100),
      // prevValue: Math.round((prevOrders.length > 0 ? prevIssueOrderIds.length / prevOrders.length : 0) * 100),
    },
    totalIssues: {
      type: 'summary',
      width: 3,
      title: '應變中心事故數',
      value: issues.length,
      // prevValue: prevIssues.length,
    },
    orderStatus: {
      type: 'pie',
      title: `訂單 ${orders.length}`,
      width: 4,
      data: orders.reduce((obj, order) => {
        const { label: key, color } = translatedOrderStatus.find(({ value }) => value === order.status);
        obj[key] = obj[key] || { value: 0, color };
        obj[key].value++;
        return obj;
      }, {}),
    },
    lunchStatus: {
      type: 'pie',
      title: `${i18n.t('午餐')}訂單 ${lunchOrders.length}`,
      width: 4,
      data: lunchOrders.reduce((obj, order) => {
        const { label: key, color } = translatedOrderStatus.find(({ value }) => value === order.status);
        obj[key] = obj[key] || { value: 0, color };
        obj[key].value++;
        return obj;
      }, {}),
    },
    dinnerStatus: {
      type: 'pie',
      title: `${i18n.t('晚餐')}訂單 ${dinnerOrders.length}`,
      width: 4,
      data: dinnerOrders.reduce((obj, order) => {
        const { label: key, color } = translatedOrderStatus.find(({ value }) => value === order.status);
        obj[key] = obj[key] || { value: 0, color };
        obj[key].value++;
        return obj;
      }, {}),
    },
  };


  if (period === 'week' || period === 'month') {
    const { start, end } = getStartEndByPeriodName(inPeriod);
    const dates = getDatesBetween(start, end);
    chartDataMappings.orderByDate = {
      type: 'bar',
      title: '每日訂單數',
      width: 12,
      mode: 'stacked',
      dataKeys: translatedOrderStatus.map(({ label }) => label).reverse(),
      data: dates.map((date) => {
        const filteredOrders = orders.filter((item) => item.date === date);
        const barValue = { name: date.slice(-5) };
        translatedOrderStatus.forEach(({ label, value, color }) => {
          barValue[label] = filteredOrders.filter((item) => item.status === value).length;
        });
        return barValue;
      }),
      colors: ({ id }) => {
        const { color } = translatedOrderStatus.find(({ label }) => label === id);
        return color;
      },
    };
  }

  return chartDataMappings;
};
