import i18n from 'i18next';

const schema = {
  'title': '基本資料',
  'type': 'object',
  'required': [
    'isActive', 'type', 'usersLimit', 'eldersLimit',
    'name', 'phoneNumber', 'email', 'county', 'translation',
  ],
  'properties': {
    'isActive': {
      'type': 'number',
      'title': '狀態',
      'enum': [0, 1, 2],
      'enumNames': ['待審核', '使用中', '已停用'],
      'default': 1,
    },
    'type': {
      'type': 'string',
      'title': '類別',
      'enum': ['individual'],
      'enumNames': ['個人'],
      'default': 'individual',
    },
    'county': {
      'type': 'string',
      'title': '所在縣市',
    },
    'name': {
      'type': 'string',
      'title': '姓名/名稱',
    },
    'username': {
      'type': ['string', 'null'],
      'title': '登入帳號',
    },
    'translation': {
      'type': ['string', 'null'],
      'title': '介面用詞',
      'enum': ['silvergate', 'medical'],
      'enumNames': ['長照送餐', '到府醫療'],
      'default': 'silvergate',
    },
    'phoneNumber': {
      'type': 'string',
      'title': '電話號碼',
    },
    'phoneNumber2': {
      'type': ['string', 'null'],
      'title': '電話號碼2',
    },
    'phoneNumber3': {
      'type': ['string', 'null'],
      'title': '電話號碼3',
    },
    'email': {
      'type': 'string',
      'title': 'Email',
      'format': 'email',
    },
    'gender': {
      'type': ['string', 'null'],
      'title': '性別',
      'enum': ['male', 'female', 'other', null],
      'enumNames': ['男', '女', '其他', '不回答'],
    },
    'usersLimit': {
      'type': 'number',
      'title': '可登入帳號上限',
      'default': 1,
    },
    'eldersLimit': {
      'type': 'number',
      'title': `可擁有${i18n.t('送餐對象')}上限`,
      'default': 1,
    },
    'birthday': {
      'type': ['string', 'null'],
      'title': '生日',
      'format': 'date',
    },
    'ageGroup': {
      'type': ['string', 'null'],
      'title': '年齡範圍',
      'enum': ['19-24', '25-30', '31-40', '41-50', '51-60', '61-64', '65+', null],
      'enumNames': ['19-24', '25-30', '31-40', '41-50', '51-60', '61-64', '65+', '不回答'],
    },
    'jobOccupation': {
      'type': ['string', 'null'],
      'title': '職稱',
    },
    'howDoYouKnowUs': {
      'type': ['string', 'null'],
      'title': '如何得知銀色大門？',
    },
    'address': {
      'title': '地址',
      'type': 'object',
    },
  },
};

export default schema;
