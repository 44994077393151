/* eslint-disable quotes, comma-dangle */
import {
  tag,
} from '@silvergatedelivery/constants';

export default {
  "title": "標籤",
  "type": "object",
  "required": [
    "category", "subcategory", "label"
  ],
  "properties": {
    "category": {
      "type": "string",
      "title": "類別",
      "enum": Object.keys(tag)
    },
    "label": {
      "type": "string",
      "title": "名稱"
    },
    "color": {
      "type": ["string", "null"],
      "title": "顏色"
    }
  },
  "dependencies": {
    "category": {
      "oneOf": Object.keys(tag).map((category) => {
        return {
          "properties": {
            "category": {
              "enum": [category]
            },
            "subcategory": {
              "type": "string",
              "title": "子類別",
              "enum": Object.keys(tag[category])
            },
          }
        };
      })
    }
  }
};
