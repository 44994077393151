import React from 'react';
import PropTypes from 'prop-types';
import EditButton from '../components/EditButton';
import Form from './UserForm';

export default function UserFormEditButton({ ...props }) {
  if (props.mode === 'add') {
    return null;
  }
  return (
    <EditButton
      title="用戶權限"
      form={Form}
      {...props}
    />);
}

UserFormEditButton.propTypes = {
  mode: PropTypes.string,
};
