import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Hub } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import {
  SHORT_NAME,
  authErrorCodes,
} from '@silvergatedelivery/constants';
import { Helmet } from 'react-helmet-async';
import CustomAuthenticator from 'components/Auth/CustomAuthenticator';
import { useCache } from 'CacheProvider';
import '@aws-amplify/ui-react/styles.css';
import './i18n/Amplify';
import './Amplify.css';
import 'react-day-picker/lib/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    'flexGrow': 1,
    'overflow-x': 'auto',
  },
}));

const authListener = ({ payload: { event, data } }) => {
  // global.logger.debug(event, data);
  let errorMessage;
  switch (event) {
    case 'signUp_failure':
    case 'signIn_failure':
      errorMessage = authErrorCodes[data.code] || data.code;
      break;
    default:
  }

  if (errorMessage) {
    toastr.error(errorMessage);
  }
};

function App({ user }) {
  const classes = useStyles();
  // load routes here for translation
  const { appRoutes } = require('./routes');
  const [filteredRoutes, setFilteredRoutes] = React.useState(appRoutes);
  const { appGroup } = useCache();

  React.useEffect(() => {
    if (!user || !user.signInUserSession || !user.attributes) return;

    let userGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

    if (!userGroups) return;

    if ((userGroups.includes('Admins') || userGroups.includes('OrgAdmins')) && appGroup === 'FacilityAdmins') {
      userGroups = ['FacilityAdmins'];
    }
    if (userGroups.includes('Admins') && appGroup === 'OrgAdmins') {
      userGroups = ['OrgAdmins'];
    }

    const filteredRoutes = appRoutes.filter(({ roles }) => {
      return (roles) ? userGroups && userGroups.some((group) => roles.includes(group)) : true;
    });

    setFilteredRoutes(filteredRoutes);

    // Hub.dispatch('auth', { event: 'user', data: user });
  }, [user]);

  React.useEffect(() => {
    Hub.listen('auth', authListener);
  }, []);

  return (<div className={classes.root} data-test-id="app-container" id="app-container">
    <Switch>
      {filteredRoutes.map((item)=>(
        <item.route
          key={item.path}
          exact={item.exact}
          path={item.path}
          roles={item.roles}
          user={user}
          render={(props) => (
            <React.Fragment>
              <Helmet>
                <title data-react-helmet="true">{`${SHORT_NAME} | ${item.title}`}</title>
                <meta name="description" content={`${SHORT_NAME} | ${item.title}`} data-react-helmet="true"/>
                <meta property="og:site_name" content={`${SHORT_NAME} | ${item.title}`} data-react-helmet="true" />
                <meta property="og:title" content={`${SHORT_NAME} | ${item.title}`} data-react-helmet="true" />
                <meta property="og:description" content={`${SHORT_NAME} | ${item.title}`} data-react-helmet="true" />
                <meta property="og:image" content={`${SHORT_NAME} | ${item.title}`} data-react-helmet="true" />
              </Helmet>
              <item.component {...props} />
            </React.Fragment>
          )}/>
      ))}
      <Redirect to={filteredRoutes[0] ? filteredRoutes[0].path : '/'} />
    </Switch>
  </div>);
}

App.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  user: PropTypes.any,
  signOut: PropTypes.func,
};

export default function AuthenticatedApp({ user }) {
  return (
    <CustomAuthenticator>
      <App user={user} />
    </CustomAuthenticator>
  );
}

AuthenticatedApp.propTypes = {
  user: PropTypes.any,
};
