import React from 'react';

import EditButton from '../components/EditButton';
import ConfigurationForm from './ConfigurationForm';

export default function ConfigurationEditButton({ ...props }) {
  return (
    <EditButton
      title="設定"
      form={ConfigurationForm}
      {...props}
    />);
}
