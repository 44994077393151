import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import { utils, writeFile } from 'xlsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { request, asyncListAll } from 'utilities/graph';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import {
  getOrder,
  getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove,
} from './queries';
import { sortBy } from 'utilities/sorting';
import { formatAddress, formatDatetime } from 'utilities/format';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

export default function LalamoveReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    const period = `${fromDate}__${toDate}`;

    global.logger.debug({
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const [
      allLalamoveOrders,
    ] = await Promise.all([
      asyncListAll(getLalamoveOrdersByProcessStatusByShouldBeSentToLalamove, {
        processStatus: 'fulfilled',
        shouldBeSentToLalamoveAt: {
          between: [fromISOString, toISOString],
        },
        limit: 1000,
      }),
    ]);

    console.log(allLalamoveOrders);
    const data = await Promise.all(
      allLalamoveOrders.map(async ({
        orderId,
        orderIds,
        lalamoveDeliveryId,
        status,
        fee,
        driver,
        distance: lalamoveDistance,
        priceBreakdown,
      }) => {
        let orders;
        if (orderIds && orderIds.length !== 0) {
          const results = await Promise.all(orderIds.map((id) => request(getOrder, { id })));
          console.log(results);
          orders = results.map((result) => result.data.getOrder);
        } else {
          const { data: { getOrder: order } } = await request(getOrder, { id: orderId });
          orders = [order];
        }
        console.log(orders);
        const {
          base = '',
          extraMileage = '',
        } = priceBreakdown || {};

        if (!orders || orders.length === 0) {
          return {
            '日期': '',
            '編號': lalamoveDeliveryId,
            '餐廳': '',
            '名字': '',
            '狀態': status,
            '銀色大門訂單狀態': '無批配訂單',
            '預估距離': '',
            'lalamove預估距離': '',
            '預估送餐費用': '',
            '實際送餐費用': fee,
            '基本費用': base,
            '額外里程費用': extraMileage,
            '標記': '',
            '餐廳地址': '',
            '餐廳地址經緯度': '',
            'Google起點經緯度': '',
            '送餐地址': '',
            '送餐地址經緯度': '',
            'Google終點經緯度': '',
            '送餐員姓名': '',
            '送餐員電話': '',
          };
        }

        const {
          date,
          mealSlot,
          status: orderStatus,
          restaurant: { name: restaurantName, address: restaurantAddress },
          direction: {
            distance,
            startLocation,
            // distanceInMeters,
          },
        } = orders[0];

        const elderName = orders.map(({ elder: { name } }) => name).join('、\n');
        const elderAddressList = orders.map(({ elder: { address } }) => address);
        const endLocationList = orders.map(({ direction: { endLocation } }) => endLocation);
        const {
          name: driverName = '',
          phone: driverPhoneNumber = '',
        } = driver || {};

        // const calculatedFee = 75 +
        //   ((distanceInMeters < 1000) ? 0 :
        //     (1000 <= distanceInMeters && distanceInMeters < 3000) ? 10 : 10 + (Math.ceil((distanceInMeters - 3000) / 1000) * 15));

        // const flag = calculatedFee !== fee ? '*' : '';
        // TODO
        const calculatedFee = '';
        const flag = '';

        return {
          '日期': formatDatetime(date, { year: true, day: true, weekday: false, hour: false, minute: false, second: false }),
          '餐別': mealSlot === 'lunch' ? '午餐' : '晚餐',
          '編號': lalamoveDeliveryId,
          '餐廳': restaurantName,
          '名字': elderName,
          '狀態': status,
          '銀色大門訂單狀態': orderStatus,
          '預估距離': distance,
          'lalamove預估距離': lalamoveDistance ? `${lalamoveDistance.value} ${lalamoveDistance.unit}`: '',
          '預估送餐費用': calculatedFee,
          '實際送餐費用': fee,
          '基本費用': base,
          '額外里程費用': extraMileage,
          '標記': flag,
          '餐廳地址': formatAddress(restaurantAddress),
          '餐廳地址經緯度': `${restaurantAddress.lat},${restaurantAddress.lng}`,
          'Google起點經緯度': `${startLocation.lat},${startLocation.lng}`,
          '送餐地址': elderAddressList.map((address) => formatAddress(address)).join('、\n'),
          '送餐地址經緯度': elderAddressList.map((address) => `${address.lat},${address.lng}`).join('、\n'),
          'Google終點經緯度': endLocationList.map((location) => `${location.lat},${location.lng}`).join('、\n'),
          '送餐員姓名': driverName,
          '送餐員電話': driverPhoneNumber,
        };
      }),
    );

    global.logger.debug(data);

    const rows = data
      .sort(sortBy('日期'));

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, `Lalamove`);
    writeFile(workbook, `Lalamove送餐資訊__${selectedMonth}.xlsx`);
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Lalamove 月訂單輸出</h3>
      <FormControl style={{ width: 150, marginRight: 16 }}>
        <InputLabel id="selected-month">月份</InputLabel>
        <Select
          labelId="selected-month-label"
          id="selected-month"
          value={selectedMonth}
          onChange={(event) => setSelectedMonth(event.target.value)}
          disabled={isLoading}
        >
          {monthOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}
