import { formatGender, formatAge } from 'utilities/format';
import JSZip from 'jszip';
import { getMonthDates, buildColumnsArray } from './helpers';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';

const COUNTY = '桃園市';
const mealCentralGovSubsidyLow = 100;
const mealCentralGovSubsidyMidLow = 90;

const weekdays = ['日', '一', '二', '三', '四', '五', '六'];

export default async function writeTaoyuan({
  elders: allElders,
  orders: allOrders,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const eldersGroupByDistrict = elders.reduce((acc, elder) => {
    const key = elder.address.district;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(elder);
    return acc;
  }, {});

  const workbook = new ExcelJS.Workbook();
  const zip = new JSZip();

  await Object.keys(eldersGroupByDistrict).sort().reduce(async (chain, district) => {
    await chain;
    const elders = eldersGroupByDistrict[district];
    const worksheet = workbook.addWorksheet(district);
    const result = getWorksheetDistrict(worksheet, elders, orders, selectedMonth, district);
    if (!result) {
      workbook.removeWorksheet(worksheet.id);
    }
  }, Promise.resolve());

  try {
    if (workbook && workbook.worksheets && workbook.worksheets.length > 0) {
      const bufferDataByDistrict = await workbook.xlsx.writeBuffer();
      if (process.env.JEST_WORKER_ID) {
        return bufferDataByDistrict;
      }
      zip.file(`${clientName}__${selectedMonth}訂餐數統計.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedMonth}訂餐數統計.zip`, zipContent);
  }
}

function getWorksheetDistrict(worksheet, elders = [], orders = [], selectedMonth, district) {
  const elderRows = [];
  const dates = getMonthDates(selectedMonth, 'e');
  let elderIndex = 1;
  const rate = {
    '中低收': mealCentralGovSubsidyMidLow,
    '低收': mealCentralGovSubsidyLow,
    '一般': 0,
  };

  const totalRow = {
    餐數小計: [0, 0],
    餐數合計: 0,
    餐費小計: 0,
    服務費小計: 0,
    合計: 0,
  };

  const totalLunchOrderCountList = [];
  const totalDinnerOrderCountList = [];

  elders.forEach((elder) => {
    const {
      id,
      name: elderName,
      disadvantagedTypesSlot,
      gender,
      birthday,
      govQualifiedCount,
    } = elder;
    const elderOrders = orders.filter(({ elderId }) => elderId === id);

    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      disadvantagedTypesSlot.forEach(({ start, end, type }) => {
        const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
        if (filteredOrders.length === 0) {
          return;
        }
        if (type === '長照中低收') {
          type = '中低收';
        }
        let lunchCount = 0;
        let dinnerCount = 0;

        const lunchOrderCountList = dates.map(({ date }, index) => {
          const orderInDate = filteredOrders.filter((order) => order.date === date && order.mealSlot === 'lunch');
          const orderCount = orderInDate.reduce((acc, { mealItems }) => {
            if (!mealItems) {
              return 0;
            }
            const mealCount = mealItems.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.quantity;
            }, 0);
            return acc + mealCount;
          }, 0);
          lunchCount += orderCount;
          if (totalLunchOrderCountList[index] === undefined) {
            totalLunchOrderCountList[index] = 0;
          }
          totalLunchOrderCountList[index] += orderCount;
          return orderCount;
        });
        const dinnerOrderCountList = dates.map(({ date }, index) => {
          const orderInDate = filteredOrders.filter((order) => order.date === date && order.mealSlot === 'dinner');
          const orderCount = orderInDate.reduce((acc, { mealItems }) => {
            if (!mealItems) {
              return 0;
            }
            const mealCount = mealItems.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.quantity;
            }, 0);
            return acc + mealCount;
          }, 0);
          dinnerCount += orderCount;
          if (totalDinnerOrderCountList[index] === undefined) {
            totalDinnerOrderCountList[index] = 0;
          }
          totalDinnerOrderCountList[index] += orderCount;
          return orderCount;
        });
        const ageBase = moment(selectedMonth).tz(TIME_ZONE).endOf('month').valueOf();
        elderRows.push({
          編號: elderIndex,
          地區: district,
          姓名: elderName,
          性別: formatGender(gender),
          福利別: `${formatAge(birthday, ageBase) >= 65 ? '老' : '身'}:${type}`,
          補助金額: rate[type],
          核定餐次: govQualifiedCount,
          餐別: ['午餐', '晚餐'],
          lunchOrders: lunchOrderCountList,
          dinnerOrders: dinnerOrderCountList,
          餐數小計: [lunchCount, dinnerCount],
          餐數合計: lunchCount + dinnerCount,
          餐費小計: (lunchCount + dinnerCount) * rate[type],
          服務費小計: (lunchCount + dinnerCount) * 38,
          合計: (lunchCount + dinnerCount) * rate[type] + (lunchCount + dinnerCount) * 38,
        });
        totalRow['餐數小計'][0] += lunchCount;
        totalRow['餐數小計'][1] += dinnerCount;
        totalRow['餐數合計'] += lunchCount + dinnerCount,
        totalRow['餐費小計'] += (lunchCount + dinnerCount) * rate[type],
        totalRow['服務費小計'] += (lunchCount + dinnerCount) * 38;
        totalRow['合計'] += (lunchCount + dinnerCount) * rate[type] + (lunchCount + dinnerCount) * 38;
        elderIndex += 1;
      });
    }
  });

  const elderRowsCount = elderRows.length;

  if (elderRowsCount === 0) {
    return;
  }

  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);

  let rowIndex = 1;
  const fontName = 'Calibri';

  const columnHeader1 = ['編號', '地區', '姓名', '性別', '福利別', '補助金額', '核定餐次', '餐別'];
  const columnWidth1 = [2.67, 6.33, 9.17, 3.67, 13.67, 10.17, 10.17, 7.83];
  const columnHeader2 = ['餐數小計', '餐數合計', '餐費小計', ['服務費', '小計'], '合計'];
  const columnWidth2 = [10.17, 9.33, 9.33, 7.33, 6.67];

  columnHeader1.forEach((headerName, index) => {
    const column = columnArray[index];
    worksheet.mergeCells(`${column}${rowIndex}:${column}${rowIndex + 1}`);
    worksheet.getCell(`${column}${rowIndex}`).value = headerName === '編號' ? '' : headerName;
    worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getColumn(column).width = columnWidth1[index];
  });

  dates.forEach(({ label }, index) => {
    const column = columnArray[index + columnHeader1.length];
    worksheet.getCell(`${column}${rowIndex}`).value = index + 1;
    worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${column}${rowIndex + 1}`).value = weekdays[label];
    worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
    worksheet.getColumn(column).width = 3.5;
  });

  columnHeader2.forEach((headerName, index) => {
    const column = columnArray[index + columnHeader1.length + dates.length];
    if (Array.isArray(headerName)) {
      worksheet.getCell(`${column}${rowIndex}`).value = headerName[0];
      worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${column}${rowIndex + 1}`).value = headerName[1];
      worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
    } else {
      worksheet.mergeCells(`${column}${rowIndex}:${column}${rowIndex + 1}`);
      worksheet.getCell(`${column}${rowIndex}`).value = headerName;
      worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
    }
    worksheet.getColumn(column).width = columnWidth2[index];
  });
  rowIndex += 2;

  elderRows.sort((a, b) => a['福利別'] > b['福利別'] ? 1 : -1).forEach((row, elderIndex) => {
    columnHeader1.forEach((headerName, index) => {
      const column = columnArray[index];
      const oneRowHeaderName = Array.isArray(headerName) ? headerName.join() : headerName;
      if (Array.isArray(row[oneRowHeaderName])) {
        worksheet.getCell(`${column}${rowIndex}`).value = row[oneRowHeaderName][0];
        worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
        worksheet.getCell(`${column}${rowIndex + 1}`).value = row[oneRowHeaderName][1];
        worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
      } else {
        worksheet.mergeCells(`${column}${rowIndex}:${column}${rowIndex + 1}`);
        worksheet.getCell(`${column}${rowIndex}`).value = oneRowHeaderName === '編號' ? elderIndex + 1 : row[oneRowHeaderName];
        worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
      }
    });

    dates.forEach((date, index) => {
      const column = columnArray[index + columnHeader1.length];
      worksheet.getCell(`${column}${rowIndex}`).value = row.lunchOrders[index];
      worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      if (row.lunchOrders[index] === 0) {
        worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName, color: { argb: 'FF9C0006' } };
        worksheet.getCell(`${column}${rowIndex}`).fill =
          { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFC7CE' }, bgColor: { argb: 'FFFFC7CE' } };
      } else {
        worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
      }
      worksheet.getCell(`${column}${rowIndex + 1}`).value = row.dinnerOrders[index];
      worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      if (row.dinnerOrders[index] === 0) {
        worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName, color: { argb: 'FF9C0006' } };
        worksheet.getCell(`${column}${rowIndex + 1}`).fill =
          { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFC7CE' }, bgColor: { argb: 'FFFFC7CE' } };
      } else {
        worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
      }
    });

    columnHeader2.forEach((headerName, index) => {
      const column = columnArray[index + columnHeader1.length + dates.length];
      const oneRowHeaderName = Array.isArray(headerName) ? headerName.join('') : headerName;
      if (Array.isArray(row[headerName])) {
        worksheet.getCell(`${column}${rowIndex}`).value = row[oneRowHeaderName][0];
        worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
        worksheet.getCell(`${column}${rowIndex + 1}`).value = row[oneRowHeaderName][1];
        worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
      } else {
        worksheet.mergeCells(`${column}${rowIndex}:${column}${rowIndex + 1}`);
        worksheet.getCell(`${column}${rowIndex}`).value = row[oneRowHeaderName];
        worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
      }
    });

    rowIndex += 2;
  });

  const column = columnArray[columnHeader1.length - 1];
  worksheet.mergeCells(`A${rowIndex}:${column}${rowIndex + 1}`);
  worksheet.getCell(`A${rowIndex}`).value = '合計';
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };

  dates.forEach((date, index) => {
    const column = columnArray[index + columnHeader1.length];
    worksheet.getCell(`${column}${rowIndex}`).value = totalLunchOrderCountList[index];
    worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    if (totalLunchOrderCountList[index] === 0) {
      worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName, color: { argb: 'FF9C0006' } };
      worksheet.getCell(`${column}${rowIndex}`).fill =
        { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFC7CE' }, bgColor: { argb: 'FFFFC7CE' } };
    } else {
      worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
    }
    worksheet.getCell(`${column}${rowIndex + 1}`).value = totalDinnerOrderCountList[index];
    worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
    if (totalDinnerOrderCountList[index] === 0) {
      worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName, color: { argb: 'FF9C0006' } };
      worksheet.getCell(`${column}${rowIndex + 1}`).fill =
        { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFC7CE' }, bgColor: { argb: 'FFFFC7CE' } };
    } else {
      worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
    }
  });
  columnHeader2.forEach((headerName, index) => {
    const column = columnArray[index + columnHeader1.length + dates.length];
    const oneRowHeaderName = Array.isArray(headerName) ? headerName.join('') : headerName;
    if (Array.isArray(totalRow[headerName])) {
      worksheet.getCell(`${column}${rowIndex}`).value = totalRow[oneRowHeaderName][0];
      worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${column}${rowIndex + 1}`).value = totalRow[oneRowHeaderName][1];
      worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
    } else {
      worksheet.mergeCells(`${column}${rowIndex}:${column}${rowIndex + 1}`);
      worksheet.getCell(`${column}${rowIndex}`).value = totalRow[oneRowHeaderName];
      worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
    }
  });

  return worksheet;
}
