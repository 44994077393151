// import cache from 'utilities/cache';
export default {
  set(key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },
  get(key) {
    const data = localStorage.getItem(key);

    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  purge(prefix = '') {
    const neverPurgeKeys = ['app:table', 'app:facility:schedule'];
    const keys = Object.keys(localStorage);
    const toDelete = keys.filter((x) => {
      if (prefix) {
        return x.startsWith(prefix);
      } else {
        if (neverPurgeKeys.some((neverPurgeKey) => x.startsWith(neverPurgeKey))) {
          return false;
        }
        return true;
      }
    });
    toDelete.forEach((key) => {
      localStorage.removeItem(key);
    });
  },
  reset() {
    localStorage.clear();
  },
};
