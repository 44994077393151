import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InformationDialog from './InformationDialog';
// import ResetPasswordDialog from './ResetPasswordDialog';
// import AddressDialog from './AddressDialog';
import { useCache } from 'CacheProvider';
import CustomAuthDialog from 'components/Auth/CustomAuthDialog';

const useStyles = makeStyles((theme) => ({
  settingTitle: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  settingBlock: {
    marginBottom: '20px',
  },
}));

const Member = ({ onGoPage }) => {
  const classes = useStyles();
  const { appGroup } = useCache();

  const memberSettings = [
    {
      key: 'information',
      editComponent: InformationDialog,
    },
    // {
    //   key: 'resetPassword',
    //   editComponent: ResetPasswordDialog,
    // },
  ];

  // const orderSettings = [
  //   {
  //     key: 'address',
  //     editComponent: AddressDialog,
  //   },
  // ];

  // const support = [
  // ];

  if (!appGroup) {
    return (
      <Grid container spacing={2} style={{ padding: 16 }} justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
            請先登入或註冊帳號
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} container justifyContent="center" style={{ paddingTop: 32 }}>
          <CustomAuthDialog
            mode= 'signIn'
            withButton={false}
            button={(props) => (
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '80%',
                  borderRadius: '20px',
                  color: 'white',
                }}
                {...props}
              >
                登入
              </Button>
            )}
          />
          &nbsp;
          <CustomAuthDialog
            mode= 'signUp'
            withButton={false}
            button={(props) => (
              <Button
                variant="text"
                color="primary"
                size="large"
                {...props}
              >
                還不是會員？點此註冊
              </Button>
            )}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid item xs={12}>
        <Typography variant='h5' color='textPrimary' align='center' style={{ fontWeight: 'bold' }} >
          會員中心
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5' color='textPrimary' style={{ fontWeight: 'bold', marginBottom: 10 }} >
          會員設定
        </Typography>
      </Grid>
      {
        memberSettings.map((item) => {
          const { key } = item;
          return (
            <div key={key} style={{ width: '100%' }}>
              <Grid item xs={12} className={classes.settingBlock}>
                <item.editComponent />
              </Grid>
            </div>);
        })
      }
      {/* <Grid item xs={12}>
        <Typography variant='h5' color='textPrimary' style={{ fontWeight: 'bold', marginBottom: 10 }} >
          訂餐資訊
        </Typography>
      </Grid>
      {
        orderSettings.map((item) => {
          const { key } = item;
          return (
            <div key={key} style={{ width: '100%' }}>
              <Grid item xs={12} className={classes.settingBlock}>
                <item.editComponent />
              </Grid>
            </div>);
        })
      }
      <Grid item xs={12}>
        <Typography variant='h5' color='textPrimary' style={{ fontWeight: 'bold', marginBottom: 10 }} >
          支援
        </Typography>
      </Grid>
      {
        support.map((item) => {
          const { key } = item;
          return (
            <div key={key} style={{ width: '100%' }}>
              <Grid item xs={12} className={classes.settingBlock}>
                <item.editComponent />
              </Grid>
            </div>);
        })
      } */}
    </Grid>
  );
};

export default Member;

Member.propTypes = {
  onGoPage: PropTypes.func,
};
