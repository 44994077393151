import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { tag as tagList } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    flex: 1,
  },
  actionContainer: {
    padding: theme.spacing(2),
  },
  chip: {
    height: 22,
    margin: 2,
    fontWeight: 'bold',
  },
}));

export default function TagChip({ tag }) {
  const classes = useStyles();

  const matched = tagList[tag.category][tag.subcategory];
  // const backgroundColor = tag.color ? tag.color : (matched ? matched.color : undefined);
  const backgroundColor = (matched ? matched.color : undefined);
  const color = backgroundColor ? '#fff' : undefined;

  return (<Chip
    label={tag.label}
    className={classes.chip}
    style={{ color, backgroundColor }}
  />);
}

TagChip.propTypes = {
  tag: PropTypes.object,
};
