import { Semaphore } from 'async-mutex';
import {
  updateDeliveryStaffOrder,
  updateElderDeliveryGroup,
  updateILinkOrder,
  updateIssue,
  updateOrder,
  updatePandagoOrder,
  updateUberOrder,
  updateLalamoveOrder,
} from 'graphql/mutations';
import {
  getDeliveryStaffOrdersByElderByDate,
  getElderDeliveryGroupByElder,
  getILinkOrdersByElderIdByCreatedAt,
  getIssuesByElderByCreatedAt,
  getOrdersByElderByCreatedAt,
  getPandagoOrdersByElderIdByCreatedAt,
  getUberOrdersByElderIdByCreatedAt,
  getLalamoveOrdersByElderIdByCreatedAt,
} from './queries';
import { asyncListAll, request } from 'utilities/graph';

import cache from 'utilities/cache';

const methodList = [
  {
    getFunc: getDeliveryStaffOrdersByElderByDate,
    updateFunc: updateDeliveryStaffOrder,
  },
  {
    getFunc: getElderDeliveryGroupByElder,
    updateFunc: updateElderDeliveryGroup,
  },
  {
    getFunc: getILinkOrdersByElderIdByCreatedAt,
    updateFunc: updateILinkOrder,
  },
  {
    getFunc: getIssuesByElderByCreatedAt,
    updateFunc: updateIssue,
  },
  {
    getFunc: getOrdersByElderByCreatedAt,
    updateFunc: updateOrder,
  },
  {
    getFunc: getPandagoOrdersByElderIdByCreatedAt,
    updateFunc: updatePandagoOrder,
  },
  {
    getFunc: getUberOrdersByElderIdByCreatedAt,
    updateFunc: updateUberOrder,
  },
  {
    getFunc: getLalamoveOrdersByElderIdByCreatedAt,
    updateFunc: updateLalamoveOrder,
  },
];

export const changeClientId = async (elderId, clientId) => {
  const username = cache.get('app:username');
  const now = new Date().toISOString();

  const updatePromises = methodList.map(async ({ getFunc, updateFunc }) => {
    const results = await asyncListAll(getFunc, { elderId });
    const toUpdateRecords = [];

    results.forEach((item) => {
      if (item.clientId !== clientId) {
        item.clientId = clientId;
        item.updatedAt = now;
        item.updatedBy = username;
        toUpdateRecords.push(item);
      }
    });
    const s = new Semaphore(10);
    return Promise.all(toUpdateRecords.map((item) =>
      s.runExclusive(async () => {
        try {
          await request(updateFunc, { input: item });
        } catch (e) {
          console.log(e);
        }
      }),
    ));
  });
  await Promise.all(updatePromises);
};
