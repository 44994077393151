export default [
  {
    label: '系統後台',
    value: 'admin',
  },
  {
    label: '手機訂單',
    value: 'app',
  },
  {
    label: '網頁訂單',
    value: 'web',
  },
];
