import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';

import FormDialog from 'components/FormDialog';
import AdminRecurringOrderPreviewTable from './AdminRecurringOrderPreviewTable';

export default function AdminRecurringOrderPreviewButton({ data }) {
  const [open, setOpen] = useState(false);

  const handleButtonClick = (event) => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Tooltip title={'排單預覽'}>
        <IconButton
          size={'small'}
          onClick={handleButtonClick}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {open &&
        <FormDialog
          title={'週期性排單預覽'}
          openOnInit={true}
          onClose={() => {
            setOpen(false);
          }}
          maxWidth='xl'
        >
          <AdminRecurringOrderPreviewTable
            data={data}
          />
        </FormDialog>}
    </React.Fragment>
  );
}

AdminRecurringOrderPreviewButton.propTypes = {
  data: PropTypes.array,
};
