import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { countiesDistricts, counties } from '@silvergatedelivery/constants';
import { getRestaurantIdSchema } from './schemas';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: '100%',
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
}));

function not(arrayA, arrayB) {
  return arrayA.filter((a) => !arrayB.some((b) => a.id === b.id));
}

function intersection(arrayA, arrayB) {
  return arrayA.filter((a) => arrayB.some((b) => a.id === b.id));
}

const getDistrictsInCounty = (inCounty) => countiesDistricts.filter(({ county }) => county === inCounty)
  .reduce((all, { districts }) => {
    const items = districts.map((district) => district);
    return [...all, ...items];
  }, []);

export default function RestaurantSelection({ restaurants: inRestaurants = [], onChange, description = '' }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [activeCounty, setActiveCounty] = useState('臺北市');
  const [districts, setDistricts] = useState(getDistrictsInCounty('臺北市'));
  const [activeDistrict, setActiveDistrict] = useState(getDistrictsInCounty('臺北市')[0]);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [publicRestaurantIds, setPublicRestaurantIds] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    (async () => {
      const allRestaurants = (await getRestaurantIdSchema(null, null, null, true)).map(({ id, label, address }) => (
        { id, label, address }));
      const restaurantsSelected = inRestaurants.map(({ id: inId }) => {
        const restaurant = allRestaurants.find(({ id }) => id === inId);
        return { ...restaurant };
      });
      const publicRestaurantIds = inRestaurants.filter(({ isPublic }) => isPublic).map(({ id }) => id);
      let activeCounty = '臺北市';
      let activeDistrict = getDistrictsInCounty(activeCounty)[0];
      if (restaurantsSelected.length !== 0) {
        setRight(restaurantsSelected);
        activeCounty = restaurantsSelected[0].address.county;
        activeDistrict = restaurantsSelected[0].address.district;
      } else {
        setRight([]);
      }
      setPublicRestaurantIds(publicRestaurantIds);
      setAllRestaurants(allRestaurants);
      setActiveCounty(activeCounty);
      setActiveDistrict(activeDistrict);
      setDistricts(getDistrictsInCounty(activeCounty));
      const districtRestaurants = allRestaurants.filter(({ address }) => address.county === activeCounty && address.district === activeDistrict);
      setLeft(not(districtRestaurants, restaurantsSelected));
    })();
  }, []);

  const onUpdateLocation = (county, district) => {
    const districtRestaurants = allRestaurants.filter(({ address }) => address.county === county && address.district === district);
    setLeft(not(districtRestaurants, right));
  };

  const handleToggleRestaurant = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getOutRestaurants = (newRight, newPublicRestaurantIds=publicRestaurantIds) => newRight.map(({ id }) => ({
    id,
    isPublic: newPublicRestaurantIds.includes(id),
  }));

  const handleToggleIsPublic = (event, { id }) => {
    event.stopPropagation();
    const currentIndex = publicRestaurantIds.indexOf(id);
    const newPublicRestaurantIds = [...publicRestaurantIds];

    if (currentIndex === -1) {
      newPublicRestaurantIds.push(id);
    } else {
      newPublicRestaurantIds.splice(currentIndex, 1);
    }
    setPublicRestaurantIds(newPublicRestaurantIds);
    onChange(getOutRestaurants(right, newPublicRestaurantIds));
  };

  const handleAllRight = () => {
    const newRight = right.concat(left);
    setRight(newRight);
    setLeft([]);
    setChecked([]);
    onChange(getOutRestaurants(newRight));
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    setRight(newRight);
    setLeft(not(left, leftChecked));
    setChecked([]);
    onChange(getOutRestaurants(newRight));
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked.filter(
      ({ address: { county, district } }) => county === activeCounty && district === activeDistrict));
    setLeft(newLeft);
    const newRight = not(right, rightChecked);
    setRight(newRight);
    setChecked([]);
    onChange(getOutRestaurants(newRight));
  };

  const handleAllLeft = () => {
    const newLeft = left.concat(right.filter(
      ({ address: { county, district } }) => county === activeCounty && district === activeDistrict));
    setLeft(newLeft);
    setRight([]);
    setChecked([]);
    onChange([]);
  };

  const customList = (items, showPublic = false) => {
    return (
      <Paper className={classes.paper}>
        <List dense component='div' role='list'>
          {items.sort((a, b) => a.label > b.label)
            .map((value) => {
              const display = value.label;
              const labelId = `transfer-list-item-${display}-label`;
              const isPublic = publicRestaurantIds.some((id) => id === value.id);

              return (
                <ListItem key={display} role='listitem' button onClick={handleToggleRestaurant(value)}>
                  <Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
                    <Box display='flex' alignItems={'center'} direction={'row'}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={display} />
                    </Box>
                    {showPublic &&
                    <Box display='flex' alignItems={'center'} direction={'row'}>
                      <IconButton onClick={(event) => handleToggleIsPublic(event, value)}>
                        {isPublic ? <CheckCircle style={{ color: '#00913A' }}/> : <RadioButtonUnchecked />}
                      </IconButton>
                      <ListItemText id={labelId} primary={'公開'} onClick={(event) => handleToggleIsPublic(event, value)}/>
                    </Box>}
                  </Box>
                </ListItem>
              );
            })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      className={classes.root}
    >
      <Grid container item xs={12} spacing={2} >
        <Grid item xs={6}>
          <Autocomplete
            name='location'
            options={counties}
            value={activeCounty}
            onChange={(event, newItem) => {
              if (newItem) {
                setActiveCounty(newItem);
                const districtsInCounty = getDistrictsInCounty(newItem);
                setDistricts(districtsInCounty);
                setActiveDistrict(districtsInCounty[0]);
                onUpdateLocation(newItem, districtsInCounty[0]);
              }
            }}
            renderInput={(params) => <TextField {...params} label='縣市'/>}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name='district'
            options={districts}
            value={activeDistrict}
            onChange={(event, newItem) => {
              if (newItem) {
                setActiveDistrict(newItem);
                onUpdateLocation(activeCounty, newItem);
              }
            }}
            renderInput={(params) => <TextField {...params} label='鄉鎮市區'/>}
          />
        </Grid>
      </Grid>
      {description !== '' &&
        <Grid item xs={12}>
          <Typography variant='body2'>
            {description}
          </Typography>
        </Grid>}
      <Grid item xs={5}>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label='move all right'
          >
            <DoubleArrowIcon />
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            <KeyboardArrowRightIcon />
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            <KeyboardArrowRightIcon className={classes.rotateIcon}/>
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label='move all left'
          >
            <DoubleArrowIcon className={classes.rotateIcon}/>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>{customList(right, true)}</Grid>
    </Grid>
  );
}

RestaurantSelection.propTypes = {
  restaurants: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
};
