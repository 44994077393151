import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '1px solid rgba(255,255,255,0.3)',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
}));

export default function DeliveryStaffOrderPhoto({ s3Key }) {
  const classes = useStyles();

  const [uri, setUri] = useState();

  useEffect(() => {
    (async () => {
      setUri(await Storage.get(s3Key));
    })();
  }, [s3Key]);

  return (
    <Avatar
      alt={'照片'}
      src={uri}
      variant="square"
      className={classes.avatar}
      onClick={() => window.open(uri, '_blank')}
    />
  );
}

DeliveryStaffOrderPhoto.propTypes = {
  s3Key: PropTypes.string.isRequired,
};
