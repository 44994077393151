import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Loading from 'components/Loading';
import useCustomAuth from 'hooks/useCustomAuth';
import { useCache } from 'CacheProvider';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // minWidth: 480,
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function UserGroupSelector({ user, onSelectedUserGroup }) {
  const classes = useStyles();
  const [value, setValue] = useState('0');
  const { signOut } = useCustomAuth();
  const { userGroupOptions } = useCache();

  const handleChange = (event) => {
    setValue(`${event.target.value}`);
  };

  const handleSubmit = async (inValue) => {
    if (inValue === '-1') { // no group is avaliable
      onSelectedUserGroup(undefined);
      await signOut();
      return;
    }
    const {
      userGroupName,
      clientId,
      clientData,
      organizationId,
      organizationData,
      organizationClientIds,
    } = userGroupOptions[inValue || value];

    onSelectedUserGroup({
      userGroupName,
      clientId,
      clientData,
      organizationId,
      organizationData,
      organizationClientIds,
    });
  };

  useEffect(() => {
    (async () => {
      if (!user) return;

      const firstActiveGroup = userGroupOptions.findIndex((option) => option.isActive === 1);
      setValue(firstActiveGroup.toString());

      if (userGroupOptions.length === 1 && firstActiveGroup !== -1) {
        const {
          userGroupName,
          clientId,
          clientData,
          organizationId,
          organizationData,
          organizationClientIds,
        } = userGroupOptions[0];

        onSelectedUserGroup({
          userGroupName,
          clientId,
          clientData,
          organizationId,
          organizationData,
          organizationClientIds,
        });
      }
    })();
  }, [user, userGroupOptions]);

  if (userGroupOptions.length === 0) {
    return (<Loading />);
  }

  console.log('userGroupOptions', userGroupOptions);

  return (
    <React.Fragment>
      <Dialog
        open={true}
        fullScreen={true}
        fullWidth={false}
        maxWidth={'md'}
        onClose={()=>{}}
        // disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="usergroup-selector"
        className={classes.dialog}
      >
        <DialogContent className={classes.content}>
          <Grid container spacing={2} >
            <Grid item xs={12} container justifyContent="center">
              <FormControl component="fieldset">
                <FormLabel component="legend">選擇登入方式</FormLabel>
                <RadioGroup aria-label="user-group" name="userGroup" value={value} onChange={handleChange}>
                  {userGroupOptions.map(({ label, isActive }, index) => {
                    let userGroupStatus = '';
                    if (isActive === 2) {
                      userGroupStatus = '(帳號停用中，請聯繫客服。)';
                    } else if (isActive === 0) {
                      userGroupStatus = '(帳號審核中)';
                    }
                    return (
                      <FormControlLabel
                        key={index}
                        value={`${index}`}
                        control={<Radio />}
                        label={`${label} ${userGroupStatus}`}
                        disabled={isActive !== 1}
                      />);
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={()=> handleSubmit(value)}
                disabled={userGroupOptions.length === 0}
              >
                確認
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

UserGroupSelector.propTypes = {
  user: PropTypes.object,
  onSelectedUserGroup: PropTypes.func,
};
