import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import JSZip from 'jszip';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import cache from 'utilities/cache';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { download } from 'utilities/file';
import { request } from 'utilities/graph';
import writeYearly from './writeYearly';
import writeDisability from './writeDisability';
import writeMonthlyStatistics from './writeMonthlyStatistics';
import { counties } from '@silvergatedelivery/constants';

import { getClientMonthlyStatementsByClientIdByMonth } from 'graphql/queries';

const yearOptions = Array(3).fill('').map((x, index) => {
  return moment().add(-index, 'years').format('YYYY');
});

export default function YearlyReport({ clientOptions: inClientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedYear, setSelectedYear] = useState(yearOptions[0]);
  const [selectedClientId, setSelectedClientId] = useState();
  const [selectedCounty, setSelectedCounty] = useState(counties[0]);
  const [clientOptions, setClientOptions] = useState([]);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);


  const updateCounty = (client) => {
    if (client && client.county) {
      const targetCounty = counties.find((county) => county === client.county);
      if (targetCounty) {
        setSelectedCounty(targetCounty);
      }
    }
  };

  useEffect(() => {
    const isFacilityAdmin = cache.get('app:facilityId');

    const clientOptions = JSON.parse(JSON.stringify(inClientOptions));
    if (!isFacilityAdmin && inClientOptions.length > 0 && inClientOptions[0].id !== 'N/A') {
      // clientOptions.unshift({
      //   id: 'N/A',
      //   name: '全部機構',
      // });
    }

    setClientOptions(clientOptions);
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
      updateCounty(clientOptions[0]);
    }
  }, [inClientOptions]);


  const collectData = async () => {
    const months = Array(12).fill('').map((_, i) => {
      const month = i + 1 < 10 ? '0' + (i + 1) : i + 1;
      return `${selectedYear}-${month}`;
    });
    const {
      name,
      facilitySettings: { dba },
    } = clientOptions.find(({ id }) => id === selectedClientId) || {};

    const clientName = dba || name || '';

    global.logger.debug({
      clientName,
      selectedYear,
      monthes: months,
    });

    const clientStatements = [].concat(...await Promise.all(months.map(async (month) => {
      const { data: { getClientMonthlyStatementsByClientIdByMonth: { items } } } = await request(getClientMonthlyStatementsByClientIdByMonth, {
        clientId: selectedClientId,
        month: {
          eq: month,
        },
      });
      return items;
    })));

    const statementsFilterdByCounty = clientStatements.filter(({ county }) => county === selectedCounty);
    const zip = new JSZip();

    writeYearly(statementsFilterdByCounty, selectedCounty, includeUndeliveredOrders, zip);
    writeDisability(statementsFilterdByCounty, selectedYear, selectedCounty, includeUndeliveredOrders, zip);
    await writeMonthlyStatistics(clientName, statementsFilterdByCounty, selectedYear, selectedCounty, includeUndeliveredOrders, zip);

    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedCounty}__${selectedYear}.zip`, zipContent);
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>年度報表</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          <AutoSelect
            id="selected-restaurant"
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label="機構"
            onChange={(value) => {
              if (value) {
                setSelectedClientId(value);
                const client = clientOptions.find(({ id }) => id === value);
                updateCounty(client);
              }
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-year">年份</InputLabel>
          <Select
            labelId="selected-year-label"
            id="selected-year"
            value={selectedYear}
            onChange={(event) => setSelectedYear(event.target.value)}
            disabled={isLoading}
          >
            {yearOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-county">縣市</InputLabel>
          <Select
            labelId="selected-county-label"
            id="selected-county"
            value={selectedCounty}
            onChange={(event) => setSelectedCounty(event.target.value)}
            disabled={isLoading}
          >
            {counties.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
      </div>
      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}

YearlyReport.propTypes = {
  clientOptions: PropTypes.array,
};
