import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import cache from 'utilities/cache';
import { formatAddress } from 'utilities/format';
import { request } from 'utilities/graph';
import { queryAddress } from 'graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import AutoSizeDialog from 'components/AutoSizeDialog';
import AddressSettings from './AddressSettings';
import DateSettings from './DateSettings';
import TimeSettings from './TimeSettings';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButton: {
    width: '80%',
    borderRadius: '20px',
    // backgroundColor: '#00913A',
    color: 'white',
  },
}));

export default function AddressDateTimeSettingsDialog({
  onClose,
  open,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepData, setActiveStepData] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const addressValidator = async (address) => {
    setIsLoading(true);
    const params = {
      input: {
        address: formatAddress(address, { includeZipCode: false, ignoreNote: true }),
      },
    };
    try {
      const {
        data: {
          queryAddress: {
            success, data,
          },
        },
      } = await request(queryAddress, params);
      if (!success) {
        setErrorMsg('無法定位地址，請重新確認輸入的地址');
        setIsLoading(false);
        return false;
      } else {
        address.lat = data.lat;
        address.lng = data.lng;
        setErrorMsg();
      }
    } catch (e) {
      console.log(e);
      setErrorMsg('無法定位地址，請重新確認輸入的地址');
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);

    return true;
  };

  const dateValidator = (date) => {
    if (!date?.days || date?.days.length === 0) {
      setErrorMsg('請選擇送餐日期');
      return false;
    }
    setErrorMsg();
    return true;
  };

  const timeValidator = (time) => {
    if (!time || (time.lunch === '不需要午餐' && time.dinner === '不需要晚餐')) {
      setErrorMsg('請選擇送餐時間');
      return false;
    }
    setErrorMsg();
    return true;
  };

  const steps = [
    {
      key: 'address',
      label: '地址',
      component: AddressSettings,
      validator: addressValidator,
    },
    {
      key: 'date',
      label: '日期',
      component: DateSettings,
      validator: dateValidator,
    },
    {
      key: 'time',
      label: '時間',
      component: TimeSettings,
      validator: timeValidator,
    },
  ];

  const getStepData = (stepIndex) => cache.get(`public:menu2-${steps[stepIndex].key}`) || {};

  useEffect(() => {
    setActiveStepData(getStepData(0));
    setActiveStep(0);
  }, []);

  const handleClose = async (e, reason) => {
    if (reason === 'backdropClick') return;
    setActiveStepData(getStepData(0));
    setActiveStep(0);
    setErrorMsg();
    onClose && onClose();
  };

  if (!activeStepData) {
    return <></>;
  }

  return (
    <React.Fragment>
      <AutoSizeDialog
        open={open}
        title='設定送餐條件'
        onClose={handleClose}
      >
        <DialogContent className={classes.content} dividers>
          <Stepper activeStep={activeStep} >
            {steps.map(({ label }, index) => {
              const stepProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepButton >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {steps.map((item, index) => {
            if (activeStep !== index) return null;

            return (<div key={index} data-id="menu-component-container">
              <item.component
                defaultValue={activeStepData}
                onUpdate={(data) => {
                  setActiveStepData(data);
                }}
              />
              {errorMsg &&
                <Typography variant='body1' color='error' align='center' style={{ fontWeight: 'bold' }} >
                  {errorMsg}
                </Typography>
              }
            </div>);
          })}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent='flex-end' alignItems='center'>
            {activeStep !== 0 &&
            <Grid xs={6} item container justifyContent='center' alignItems='center'>
              <Button
                variant='contained'
                className={classes.actionButton}
                style={{ backgroundColor: '#00913A' }}
                onClick={async () => {
                  let dataValid = true;
                  if (steps[activeStep].validator) {
                    dataValid = await steps[activeStep].validator(activeStepData);
                  }
                  if (dataValid) {
                    cache.remove('public:menu2-orders');
                    cache.set(`public:menu2-${steps[activeStep].key}`, activeStepData);
                    setActiveStepData(getStepData(activeStep - 1));
                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  }
                }}
              >
                上一步
              </Button>
            </Grid>}
            {activeStep !== steps.length - 1 &&
            <Grid xs={6} item container justifyContent='center' alignItems='center'>
              <Button
                variant='contained'
                className={classes.actionButton}
                style={{ backgroundColor: '#00913A' }}
                disabled={isLoading}
                onClick={async () => {
                  let dataValid = true;
                  if (steps[activeStep].validator) {
                    dataValid = await steps[activeStep].validator(activeStepData);
                  }
                  if (dataValid) {
                    cache.remove('public:menu2-orders');
                    cache.set(`public:menu2-${steps[activeStep].key}`, activeStepData);
                    setActiveStepData(getStepData(activeStep + 1));
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                }}
              >
                下一步
                {isLoading && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 100, borderRadius: '20px' }}>
                  <CircularProgress color="primary" size="1.5rem" />
                </Backdrop>}
              </Button>
            </Grid>}
            {activeStep === steps.length - 1 &&
            <Grid xs={6} item container justifyContent='center' alignItems='center'>
              <Button
                variant='contained'
                className={classes.actionButton}
                style={{ backgroundColor: '#00913A' }}
                onClick={async () => {
                  let dataValid = true;
                  if (steps[activeStep].validator) {
                    dataValid = await steps[activeStep].validator(activeStepData);
                  }
                  if (dataValid) {
                    cache.remove('public:menu2-orders');
                    cache.set(`public:menu2-${steps[activeStep].key}`, activeStepData);
                    handleClose();
                  }
                }}
              >
                開始訂餐
              </Button>
            </Grid>}
          </Grid>
        </DialogActions>
      </AutoSizeDialog>
    </React.Fragment>
  );
}

AddressDateTimeSettingsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
