import cache from 'utilities/cache';
import { Auth } from 'aws-amplify';

import { request } from 'utilities/graph';
import { createAuditTrail } from 'graphql/mutations';

import appConfig from '../../package.json';

const useCustomAuth = () => {
  const recordEvent = async (action = '') => {
    try {
      const username = cache.get('app:username');
      const email = cache.get('app:email');
      const name = cache.get('app:name') || username;
      const groupName = cache.get('app:pathPrefix');
      const facilityId = cache.get('app:facilityId') || 'N/A';
      const organizationId = cache.get('app:organizationId') || 'N/A';
      const clientId = cache.get('app:clientId') || 'N/A';

      if (!username) return;

      const data = {
        timestamp: new Date().toISOString(),
        username,
        email,
        name,
        groupName,
        action,
        facilityId,
        organizationId,
        clientId,
        expirationUnixTime: Math.round(Date.now() / 1000) + 2 * 365 * 86400, // 2 years
        createdBy: username,
        updatedBy: username,
      };

      await request(createAuditTrail, {
        input: data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = async () => {
    try {
      await recordEvent('登出');

      cache.purge();
      cache.set('app:version', appConfig.version);
      await Auth.signOut();
    } finally {
      window.open('/', '_self');
    }
  };

  return {
    signOut,
    recordEvent,
  };
};

export default useCustomAuth;
