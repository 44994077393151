import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import { utils, write } from 'xlsx';
import JSZip from 'jszip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { request, asyncListAll } from 'utilities/graph';
import { download } from 'utilities/file';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import {
  getRestaurantsByClientByIsActive,
  getRestaurantsByCountyByIsActive,
  listRestaurants,
  getOrdersByRestaurantByDate,
  getOrdersByStatusByDate,
} from 'graphql/queries';

import { sortBy } from 'utilities/sorting';
import cache from 'utilities/cache';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

export default function RestaurantReport({ clientOptions }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState();
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    const period = `${fromDate}__${toDate}`;
    // TODO: limit the clientId if applied

    const restaurant = restaurantOptions.find(({ id }) => id === selectedRestaurantId);
    const facilityId = cache.get('app:facilityId');

    global.logger.debug({
      selectedRestaurantId,
      restaurant,
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const deliveryStaffIds = [];
    let allOrders;
    if (selectedRestaurantId === 'N/A') {
      let includedOrderStatus = ['delivered', 'completed'];
      if (includeUndeliveredOrders) {
        includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
      }
      const allOrdersByStatus = await Promise.all(includedOrderStatus.map((status) =>
        asyncListAll(getOrdersByStatusByDate, {
          status,
          date: {
            between: [fromDate, toDate],
          },
          limit: 1000,
        }),
      ));
      allOrders = [].concat(...allOrdersByStatus);
    } else {
      ([
        allOrders,
      ] = await Promise.all([
        asyncListAll(getOrdersByRestaurantByDate, {
          restaurantId: selectedRestaurantId,
          date: {
            between: [fromDate, toDate],
          },
          limit: 1000,
        }),
      ]));
    }
    let includedOrderStatus = ['delivered', 'completed'];
    if (includeUndeliveredOrders) {
      includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
    }
    let orders = allOrders
      .filter((x) => (facilityId) ? x.clientId === facilityId : true)
      .filter(({ status }) => includedOrderStatus.includes(status));
    if (excludeNonFoodDeliveryOrders) {
      orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
    }

    orders
      .forEach(({ deliveryStaffId }) => {
        if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
          deliveryStaffIds.push(deliveryStaffId);
        }
      });

    const [deliveryStaffs] = await Promise.all([
      Promise.all(deliveryStaffIds.map(async (id) => {
        const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
          query GetDeliveryStaff($id: ID!) {
            getDeliveryStaff(id: $id) {
              id
              name
              identificationCardId
            }
          }
        `, { id });
        return data;
      })),
    ]);

    const zip = new JSZip();
    const mappings = {};

    orders.forEach((order) => {
      const {
        date,
        mealSlot,
        clientId,
        deliveryStaffId,
        restaurantId,
        mealItems,
      } = order;
      const client = clientOptions.find(({ id }) => id === clientId) || { id: '無', name: '無' };
      const deliveryStaff = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
      const key = `${date}__${clientId}__${restaurantId}__${deliveryStaffId}`;

      mappings[key] = mappings[key] || {
        '日期': date,
        '機構': client.name,
        '餐廳': restaurant.name,
        '送餐大使': deliveryStaff ? deliveryStaff.name : '',
        '午餐餐點數量': 0,
        '晚餐餐點數量': 0,
        '總進價': 0,
      };

      const mealItemCount = mealItems.reduce((sum, item) => {
        return sum + item.quantity;
      }, 0);
      const cost = mealItems.reduce((sum, item) => {
        return sum + item.cost * item.quantity;
      }, 0);
      if (mealSlot === 'lunch') {
        mappings[key]['午餐餐點數量'] += mealItemCount;
      } else {
        mappings[key]['晚餐餐點數量'] += mealItemCount;
      }
      mappings[key]['總進價'] += cost;
    });

    const workbook = utils.book_new();

    const clientMappings = {};

    const rows = Object.keys(mappings)
      .map((key) => {
        const clientName = mappings[key]['機構'];
        clientMappings[clientName] = clientMappings[clientName] || [];
        clientMappings[clientName].push(mappings[key]);

        return mappings[key];
      })
      .sort(sortBy('日期'))
      .sort(sortBy('機構'));

    const worksheet = utils.json_to_sheet(rows);
    utils.book_append_sheet(workbook, worksheet, `餐廳製餐數`);

    const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    zip.file(`@餐廳製餐數.xlsx`, wbout, { binary: true });

    Object.keys(clientMappings)
      .forEach((clientName) => {
        const rows = clientMappings[clientName].sort(sortBy('日期'));

        const normalizedName = `${clientName.replace(/[^\u4e00-\u9fa5]/g, '')}`;
        const workbook = utils.book_new();
        const worksheet = utils.json_to_sheet(rows);
        utils.book_append_sheet(workbook, worksheet, normalizedName);

        const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        zip.file(`${normalizedName}.xlsx`, wbout, { binary: true });
      });

    const zipContent = zip.generate({ type: 'blob' });
    download(`${restaurant.name}__機構製餐數__${selectedMonth}.zip`, zipContent);
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const facilityId = cache.get('app:facilityId');
      const county = cache.get('app:location');
      const isFacilityAdmin = facilityId ? true : false;

      let restaurantOptions = [];

      if (facilityId) {
        restaurantOptions = await asyncListAll(getRestaurantsByClientByIsActive, {
          clientId: facilityId,
        });
      } else
      if (county) {
        restaurantOptions = await asyncListAll(getRestaurantsByCountyByIsActive, {
          county,
        });
      } else {
        restaurantOptions = await asyncListAll(listRestaurants, {});
      }

      restaurantOptions.sort(sortBy('name'));
      if (!isFacilityAdmin) {
        restaurantOptions.unshift({
          id: 'N/A',
          name: '全部餐廳',
        });
      }

      setRestaurantOptions(restaurantOptions);
    })();
  }, []);

  return (
    <div>
      <h3>餐廳製餐數報表輸出</h3>
      <FormControl style={{ minWidth: 360, marginRight: 16 }}>
        <AutoSelect
          id="selected-restaurant"
          options={restaurantOptions.map(({ name }) => name)}
          values={restaurantOptions.map(({ id }) => id)}
          required={true}
          value={selectedRestaurantId}
          label="餐廳"
          onChange={(value) => setSelectedRestaurantId(value)}
        />
      </FormControl>
      <FormControl style={{ width: 150, marginRight: 16 }}>
        <InputLabel id="selected-month">月份</InputLabel>
        <Select
          labelId="selected-month-label"
          id="selected-month"
          value={selectedMonth}
          onChange={(event) => setSelectedMonth(event.target.value)}
          disabled={isLoading}
        >
          {monthOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={includeUndeliveredOrders}
            name={'includeUndeliveredOrders'}
            value={includeUndeliveredOrders}
            disabled={isLoading}
            onChange={(e) => {
              setIncludeUndeliveredOrders(e.target.checked);
            }}
          />
        }
        label={'包含未送達的訂單'}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={excludeNonFoodDeliveryOrders}
            name={'excludeNonFoodDeliveryOrders'}
            value={excludeNonFoodDeliveryOrders}
            disabled={isLoading}
            onChange={(e) => {
              setExcludeNonFoodDeliveryOrders(e.target.checked);
            }}
          />
        }
        label={'排除非送餐的訂單'}
      />
      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={!selectedRestaurantId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}

RestaurantReport.propTypes = {
  clientOptions: PropTypes.array,
};
