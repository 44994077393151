export default [
  {
    label: '訂單',
    value: 'order',
  },
  {
    label: '月結算',
    value: 'monthlyStatement',
  },
  {
    label: '調整',
    value: 'adjustment',
  },
  {
    label: '轉入銀行帳戶',
    value: 'sell',
  },
  {
    label: '兌換成服務時數',
    value: 'socialCredits',
  },
];
