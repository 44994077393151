import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { request } from 'utilities/graph';
import {
  adminUpdateOrder,
} from 'graphql/mutations';
import {
  getOrder,
} from 'graphql/queries';
import LinkGroupElderSelect from './LinkGroupElderSelect';
import { Semaphore } from 'async-mutex';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import { toastr } from 'react-redux-toastr';
import { Typography } from '@material-ui/core';

export default function UpdateOrderLinkGroupEldersButton({
  onUpdate,
  ordersSelected,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [linkGroupElderIds, setLinkGroupElderIds] = useState([]);
  const [progress, setProgress] = useState(0);
  const mealCount = ordersSelected.current[0] ? ordersSelected.current[0].mealItems[0].quantity : 0;

  const handleSubmit = async () => {
    setIsLoading(true);
    const orders = ordersSelected.current;

    let orderProcessed = 0;
    const toUpdateData = [];
    const s = new Semaphore(10);

    const orderElderId = linkGroupElderIds.shift();

    await Promise.all(orders.map((order) =>
      s.runExclusive(async () => {
        const data = {
          id: order.id,
          restaurantId: order.restaurantId,
          elderId: orderElderId,
          deliveryBy: order.deliveryBy,
          status: order.status,
          tier: order.tier,
          linkGroupElderIds,
        };
        try {
          await request(adminUpdateOrder, { input: { orders: [data] } });
          const { data: { getOrder: orderUpdated } } = await request(getOrder, { id: order.id });
          toUpdateData.push(orderUpdated);
        } catch (e) {
          console.log(e);
          if (e.errors && e.errors[0] && e.errors[0].message) {
            toastr.error(e.errors[0].message);
          }
        }
        orderProcessed += 1;
        setProgress(orderProcessed * 100 / orders.length);
      }),
    ));
    onUpdate && onUpdate(toUpdateData);

    setIsLoading(false);
    handleClose();
  };

  const onUpdateOrder = async () => {
    const orders = ordersSelected.current;

    if (orders.some(({ orderGroupId }) => orderGroupId)) {
      toastr.error('無法變更多點配送訂單，請先取消該訂單');
      return;
    }
    if (orders.some(({ elder }) => !elder.linkGroupName)) {
      toastr.error('訂單的送餐對象沒有設定家庭代碼');
      return;
    }
    if (orders.some(({ elderId }) => elderId !== orders[0].elderId)) {
      toastr.error('訂單的送餐對象不一致');
      return;
    }

    let sameLinkGroup = false;
    if (orders[0].elder.linkGroupName &&
        orders[0].mealItems && orders[0].mealItems.length === 1 &&
        orders[0].mealItems[0].quantity > 1) {
      sameLinkGroup = orders.every((order) => {
        return order.elder.linkGroupName === orders[0].elder.linkGroupName &&
          order.mealItems.length === 1 &&
          order.mealItems[0].quantity === orders[0].mealItems[0].quantity;
      });
    } else {
      toastr.error('需要增加訂單餐點數量');
      return;
    }
    if (!sameLinkGroup) {
      toastr.error('訂單的家庭代碼或餐點數量不一致');
      return;
    }

    if (orders.length !== 0) {
      const sortedOrders = orders.sort((a, b) => a.date > b.date ? 1 : -1);
      let orderDuration = `修改訂單的送餐日期範圍：${sortedOrders[0].date} 至 ${sortedOrders[sortedOrders.length - 1].date}`;
      if (sortedOrders[0].date === sortedOrders[sortedOrders.length - 1].date) {
        orderDuration = `修改訂單的送餐日期：${sortedOrders[0].date}`;
      }
      if (window.confirm(`確定要修改這${orders.length}筆訂單?\n${orderDuration}`)) {
        setOpen(true);
      }
    } else {
      toastr.error('選擇的訂單中沒有未送達的訂單');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLinkGroupElderIds([]);
  };

  const onLinkGroupElderUpdate = ((newLinkGroupElderIds) => {
    setLinkGroupElderIds(newLinkGroupElderIds);
  });

  return (
    <Fragment>
      <Button
        style={ { marginLeft: 10 } }
        variant="contained"
        color="primary"
        onClick={async () => {
          await onUpdateOrder();
        }}
      >
        {'修改家庭代碼餐點分配'}
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!isLoading ? '修改家庭代碼餐點分配' : '更新訂單中'}
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          <Typography>{ordersSelected.current[0] ? ordersSelected.current[0].elder.linkGroupName : ''}</Typography>
          <Typography style={{ marginTop: 10, marginBottom: 10 }}>
            {`需分配的餐點數量: ${mealCount}`}
          </Typography>
          <Typography style={{ marginTop: 10, marginBottom: 10 }}>
            訂單將設定在第一位送餐對象
          </Typography>
          {!isLoading ?
            <LinkGroupElderSelect
              onUpdate={onLinkGroupElderUpdate}
              defaultValue={[ordersSelected.current[0]?.elderId, ...(ordersSelected.current[0]?.linkGroupElderIds || [])]}
              clientId={ordersSelected.current[0]?.elder.clientId}
              linkGroupName={ordersSelected.current[0]?.elder.linkGroupName}
            /> :
            <LinearProgressWithLabel value={progress}/>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained"
            disabled={isLoading || (linkGroupElderIds.length !== mealCount)}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

UpdateOrderLinkGroupEldersButton.propTypes = {
  onUpdate: PropTypes.func,
  ordersSelected: PropTypes.object,
};
