import React from 'react';

export default function ApiDocs() {
  return (
    <iframe
      title="directions"
      width={'100%'}
      height={'100%'}
      src={'/api-docs/'}
      allowFullScreen />
  );
}
