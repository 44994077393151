import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Board from 'react-trello';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { sortOrder } from 'components/Schedule/helpers';
import { Colors } from '@silvergatedelivery/constants';
import { formatTime } from 'utilities/format';
import OrderDialog from 'components/Order/OrderDialog';
import LinkButton from 'components/Table/LinkButton';
import { getTranslatedOrderStatus } from 'utilities/translate';
import { useCache } from 'CacheProvider';

import './BoardView.css';

const CARD_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  card: {
    width: CARD_WIDTH,
    backgroundColor: Colors.highlight,
    color: Colors.dark,
    padding: 8,
    marginBottom: 12,
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  cardDescription: {
    fontSize: 16,
    lineHeight: '24px',
  },
  cardIssueDescription: {
    fontSize: 14,
    color: Colors.raised,
  },
}));

function Card({ title, metadata: order, visitorMode = false }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { appGroup } = useCache();
  const orgMode = appGroup === 'OrgAdmins';
  const facilityMode = appGroup === 'FacilityAdmins';

  let deliveryStaffName = '';
  if (order.deliveryStaff) {
    deliveryStaffName = visitorMode ? (order.deliveryStaff.name[0] + `姓${t('送餐大使')}`) : order.deliveryStaff.name;
  }

  return (
    <Grid container className={classes.card} spacing={1}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <span className={classes.cardTitle}>
          {title}
        </span>
        {!orgMode &&
        <div>
          <OrderDialog
            id={order.id}
            mode="icon"
          />
          <div style={{ display: 'inline-block', width: 8 }} />
          <LinkButton
            path={`/order/${order.id}`}
            label="前往訂單頁面"
            newTab={true}
          />
        </div>}
      </Grid>
      <Grid item xs={12} className={classes.cardDescription}>
        {t('餐廳')}：{order.restaurant.name} <br/>
        {!facilityMode && <>機構：{order.client.name} <br/></>}
        {order.deliveryStaff ? `大使：${deliveryStaffName}` : ''}
      </Grid>
      <Grid item xs={12} className={classes.cardIssueDescription}>
        {order.isAudited === 1 ? order.issueSummary : ''}
      </Grid>
    </Grid>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metadata: PropTypes.any,
  visitorMode: PropTypes.bool,
};

export default function BoardView({
  orders = [],
}) {
  const classes = useStyles();
  const [lanes, setLanes] = useState();
  const { appGroup, selectedUserGroupParams } = useCache();
  const orgMode = appGroup === 'OrgAdmins';

  useEffect(() => {
    const data = [
      ...getTranslatedOrderStatus().map(({ label, value, color }) => {
        const matchedOrders = orders
          .filter(({ status }) => status === value)
          .sort(sortOrder);

        return {
          id: value,
          title: label,
          label: `${matchedOrders.length}`,
          cards: matchedOrders.map((order) => {
            let elderName = order.elder.name;
            let visitorMode = false;
            const { organizationData } = selectedUserGroupParams;
            if (orgMode && organizationData?.clients?.items) {
              const clientParams = organizationData?.clients?.items.find(({ clientId }) => clientId === order.clientId);
              if (clientParams && clientParams.visitorMode) {
                elderName = (order.elder.name[0] + '姓長輩');
                visitorMode = true;
              }
            }
            return {
              id: order.id,
              title: `${formatTime(order.deliveryBy)} ${elderName}`,
              description: `${order.restaurant.name}`,
              metadata: order,
              visitorMode: visitorMode,
            };
          }),
          editLaneTitle: false,
          disallowAddingCard: true,
          hideCardDeleteIcon: true,
          droppable: false,
          style: {
            color: '#fff',
            backgroundColor: color,
            minWidth: CARD_WIDTH + 10,
            width: CARD_WIDTH + 10,
          },
        };
      }),
    ];
    setLanes(data);
  }, [orders]);

  if (!lanes) return null;

  return (
    <Grid container className={classes.root}>
      {orders.length > 0 &&
      <Grid item xs={12} style={{ width: 100 }} container alignItems="flex-start">
        <Board
          id="today-order-board-view"
          draggable={false}
          laneDraggable={false}
          cardDraggable={false}
          editable={false}
          canAddLanes={false}
          hideCardDeleteIcon={true}
          editLaneTitle={false}
          data={{ lanes }}
          style={{
            backgroundColor: Colors.background.light,
          }}
          components={{
            Card: (args) => {
              return <Card {...args} />;
            },
          }}
        />
      </Grid>}
    </Grid>);
}

BoardView.propTypes = {
  orders: PropTypes.array,
};
