import React from 'react';
import i18n from 'i18next';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import TodayIcon from '@material-ui/icons/Today';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
// import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

import FacilityClientTable from 'components/FacilityClientTable';
import ElderTable from 'components/ElderTable';
import ElderDetails from 'components/ElderDetails';
import RestaurantDetails from 'components/RestaurantDetails';
import Order from 'views/Admin/Order/Order';
import TodayOrders from 'views/Admin/TodayOrders/TodayOrders';
import Dashboard from 'views/OrgAdmin/Dashboard/Dashboard';

// custom
import DeliveryStaffTable from './components/DeliveryStaffTable';
import DeliveryStaffDetails from './components/DeliveryStaffDetails';
import DeliveryDiary from 'views/OrgAdmin/DeliveryDiary/DeliveryDiary';
import cache from 'utilities/cache';
import Users from 'views/Admin/Users/Users';

export default [
  {
    title: i18n.t('總覽'),
    icon: DashboardIcon,
    paths: [{ path: '/dashboard', exact: true }],
    component: Dashboard,
  },
  {
    title: i18n.t('今日訂單'),
    icon: TodayIcon,
    paths: [{ path: '/todayOrders', exact: true }],
    component: TodayOrders,
  },
  {
    title: '機構',
    icon: GroupIcon,
    paths: [{ path: '/facilities', exact: true }],
    component: () => <FacilityClientTable title="機構" />,
  },
  // 先不開放客戶
  // {
  //   title: '客戶',
  //   icon: PersonIcon,
  //   paths: [{ path: '/clients', exact: true }],
  //   component: () => <ClientTable title="客戶" type="individual" />,
  // },
  {
    title: i18n.t('送餐大使'),
    icon: MotorcycleIcon,
    paths: [{ path: '/deliveryStaffs', exact: true }],
    component: DeliveryStaffTable,
  },
  {
    title: `${i18n.t('送餐大使')}頁面`,
    paths: [{ path: '/deliveryStaff/:id', exact: true }],
    component: DeliveryStaffDetails,
    hideFromMenu: true,
  },
  {
    title: i18n.t('送餐對象'),
    icon: PeopleIcon,
    paths: [{ path: '/elders', exact: true }],
    component: ElderTable,
  },
  {
    title: `${i18n.t('送餐對象')}頁面`,
    paths: [{ path: '/elder/:id', exact: true }],
    component: ElderDetails,
    hideFromMenu: true,
  },
  {
    title: `${i18n.t('餐廳')}頁面`,
    paths: [{ path: '/restaurant/:id', exact: true }],
    component: RestaurantDetails,
    hideFromMenu: true,
  },
  {
    title: '訂單頁面',
    icon: ListAltIcon,
    paths: [{ path: '/order/:id', exact: true }],
    component: Order,
    hideFromMenu: true,
  },
  {
    title: i18n.t('送餐關懷'),
    icon: FavoriteBorderIcon,
    paths: [{ path: '/deliveryDiary', exact: true }],
    component(...args) {
      return <DeliveryDiary {...args} organizationId={cache.get('app:organizationId')} />;
    },
  },
  {
    title: '用戶',
    icon: LockIcon,
    paths: [{ path: '/users', exact: true }],
    component(...args) {
      return <Users {...args} organizationId={cache.get('app:organizationId')} />;
    },
  },
];
