import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { getTranslatedOrderStatus } from 'utilities/translate';
import { cancellationReasons } from '@silvergatedelivery/constants';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    flex: 1,
  },
  actionContainer: {
    padding: theme.spacing(2),
  },
  chip: {
    height: 22,
    margin: 2,
    fontWeight: 'bold',
  },
}));

export default function OrderStatusChip({ status, reason }) {
  const classes = useStyles();

  const matchedOrderStatus = getTranslatedOrderStatus().find((item) => item.value === status);
  let label = matchedOrderStatus ? matchedOrderStatus.label : 'N/A';
  if (reason) {
    const reasonLabel = (cancellationReasons.find(({ value }) => reason === value) || {}).label;
    if (reasonLabel) {
      label += ` - ${reasonLabel}`;
    }
  }
  const backgroundColor = matchedOrderStatus ? matchedOrderStatus.color : undefined;
  const color = backgroundColor ? '#fff' : undefined;

  return (<Chip
    label={label}
    className={classes.chip}
    style={{ color, backgroundColor }}
  />);
}

OrderStatusChip.propTypes = {
  status: PropTypes.string,
  reason: PropTypes.string,
};
